import React from "react";

import { addPlatform, deletePlatform } from "util/pouchActions";

import PouchInput from "components/PouchInput";
import AddIcon from "icons/AddIcon";
import DeleteIcon from "icons/DeleteIcon";
import { Meet, Platform } from "types";
import size from "lodash/size";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import get from "lodash/get";

const Platforms = ({ meet }: { meet: Meet }) => {
  const deletePlatformInternal = (platform: Platform) => {
    if (size(meet.platforms) <= 1) {
      alert("You must have one platform");
    } else {
      const message = `Are you sure you want to delete platform ${platform.name}?`;
      if (window.confirm(message)) {
        deletePlatform(platform, meet._id);
      }
    }
  };

  return (
    <div className="platforms">
      <table>
        <thead>
          <tr>
            <th>Platform name</th>
            <th style={{ width: "22%" }}>ID</th>
            <th style={{ width: "6%" }}>
              <button onClick={() => addPlatform(meet)}>
                <AddIcon />
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {map(sortBy(meet.platforms, "name"), (platform) => {
            return (
              <tr key={platform._id}>
                <td>
                  <PouchInput
                    style={{ width: "100%" }}
                    type="text"
                    meetId={meet._id}
                    documentId={platform._id}
                    name="name"
                    value={get(platform, "name")}
                  />
                </td>
                <td>{platform._id}</td>
                <td>
                  <button onClick={() => deletePlatformInternal(platform)}>
                    <DeleteIcon />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Platforms;
