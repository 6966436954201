import React from "react";
import map from "lodash/map";
import get from "lodash/get";

import {
  getLifterAwardsWeightClassDoc,
  getDivisionDoc,
} from "util/lifterHelper";

const WeightClassCell = ({ data, column, meet, style }) => {
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    return (
      <div style={style} className="table-cell-inner">
        {map(lifter.divisions, (lifterDivision, index) => {
          if (!lifterDivision.divisionId) {
            return null;
          }

          const division = getDivisionDoc(lifterDivision.divisionId, meet);
          if (!division) {
            return null;
          }

          if (division.hideOnBoard) {
            return null;
          }

          const weightClassDoc = getLifterAwardsWeightClassDoc(
            meet,
            lifter,
            lifterDivision
          );

          return (
            <div key={index} className="ellipsis cell-row">
              {get(weightClassDoc, "name")}
            </div>
          );
        })}
      </div>
    );
  }
};

export default WeightClassCell;
