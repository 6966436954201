import React from 'react';

import { getSubtotal } from 'util/lifterHelper';

const TotalCell = ({data, column, meet, style}) => {
  const lifter = data.lifter;
  if(data.row === 'header') {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else if(data.row === 'title') {
    return (
      <div style={style} className="table-cell-inner" />
    );
  } else {
    return (
      <div style={{...style, fontSize: style.fontSize*1.3}} className="table-cell-inner">
        { getSubtotal(lifter, meet) }
      </div>
    );
  }
};

export default TotalCell;
