import React from "react";
import {
  getGenderOptions,
  getRawOrEquippedOptions,
  getScoreByOptions,
  getDivisionCodeOptions,
} from "util/options";
import { getMeetUnits } from "util/meetHelper";
import SortableTable from "components/SortableTable";
import DefaultInputCell from "components/cells/DefaultInputCell";
import LiftsCell from "./cells/LiftsCell";
import ButtonCell from "./cells/ButtonCell";
import AddWeightClassButtonCell from "./cells/AddWeightClassButtonCell";
import WeightClassCell from "./cells/WeightClassCell";
import CheckboxCell from "./cells/CheckboxCell";
import get from "lodash/get";
import size from "lodash/size";
import toLower from "lodash/toLower";
import { MediaSize, Meet } from "types";
import { Column } from "components/table/Table";

const getCellLines = (index: number, divisions: any) => {
  const rows = size(get(divisions, [index, "weightClasses"], 1));
  if (rows === 0) {
    return 1;
  }

  if (rows < 4) {
    return 4;
  }

  return rows * 1.085;
};

const DivisionsTable = ({
  divisions,
  meet,
  media,
}: {
  divisions: any;
  meet: Meet;
  media: MediaSize;
}) => {
  const getAllColumns = (): Column[] => {
    return [
      {
        key: "",
        label: "",
        width: 45,
        type: "button",
        renderer: ButtonCell,
      },
      {
        key: "name",
        label: "Name",
        width: 360,
        type: "text",
        sortable: true,
        sortFunction: (row: any) => toLower(row.name),
        sortDirection: "asc",
        sortOrder: 1,
        renderer: DefaultInputCell,
      },
      {
        key: "gender",
        label: "Gender",
        width: 120,
        type: "select",
        options: getGenderOptions,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 2,
        renderer: DefaultInputCell,
      },
      {
        key: "rawOrEquipped",
        label: "R/E",
        width: 140,
        type: "select",
        options: getRawOrEquippedOptions,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 3,
        renderer: DefaultInputCell,
      },
      {
        key: "lifts",
        label: "Lifts",
        width: 145,
        renderer: LiftsCell,
      },
      {
        key: "scoreBy",
        label: "Score By",
        width: 240,
        type: "select",
        options: getScoreByOptions,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 4,
        renderer: DefaultInputCell,
      },
      {
        key: "addWeightClass",
        label: "",
        width: 45,
        renderer: AddWeightClassButtonCell,
      },
      {
        key: "name",
        label: "Weight Class Name",
        type: "text",
        width: 200,
        renderer: WeightClassCell,
      },
      {
        key: "maxWeight",
        label: `Max Weight (${getMeetUnits(meet)})`,
        type: "number",
        width: 120,
        renderer: WeightClassCell,
      },
      {
        key: "usaplDivisionCode",
        label: "Division Code",
        width: 160,
        type: "select",
        options: getDivisionCodeOptions,
        renderer: DefaultInputCell,
      },
      {
        key: "hideOnBoard",
        label: "Hide on Board",
        width: 160,
        renderer: CheckboxCell,
      },
    ];
  };

  return (
    <SortableTable
      data={divisions}
      columns={getAllColumns()}
      getCellLines={getCellLines}
      meet={meet}
      numberOfFixedLeftColumns={2}
      media={media}
    />
  );
};

export default DivisionsTable;
