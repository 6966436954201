import React from "react";
import PouchInput from "components/PouchInput";
import SortableHeaderCell from "components/SortableHeaderCell";
import { Column } from "components/table/Table";
import { Meet } from "types";
import get from "lodash/get";
import { getCanadaProvinceOptions, getStateOptions } from "util/options";
import PouchSelect from "components/PouchSelect";

const StateCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: Column;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return <SortableHeaderCell style={style} column={column} />;
  } else {
    if (!lifter.country || lifter.country === "US") {
      return (
        <div style={style} className="table-cell-inner">
          <PouchSelect
            meet={meet}
            document={lifter}
            name={column.key}
            value={get(lifter, column.key)}
            options={getStateOptions}
          />
        </div>
      );
    }

    if (lifter.country && lifter.country === "CA") {
      return (
        <div style={style} className="table-cell-inner">
          <PouchSelect
            meet={meet}
            document={lifter}
            name={column.key}
            value={get(lifter, column.key)}
            options={getCanadaProvinceOptions}
          />
        </div>
      );
    }

    return (
      <div style={style} className="table-cell-inner">
        <PouchInput
          type="text"
          meetId={meet._id}
          documentId={lifter._id}
          name={column.key}
          value={get(lifter, column.key)}
        />
      </div>
    );
  }
};

export default StateCell;
