import React from "react";
import Select from "components/select/Select";
import classNames from "classnames";
import { toastr } from "react-redux-toastr";
import DateInput from "components/dateInput/DateInput";
import { errorReporter } from "util/errorReporter";
import { getUnitOptions } from "util/options";
import { fetchWrapper } from "util/api";
import { getDateFormat, getBenchLabel } from "util/meetHelper";
import "./VirtualMeetSubmitIndex.scss";
import { toKg, toLbs } from "util/conversions";
import { useMeet } from "util/useMeet";
import toNumber from "lodash/toNumber";
import round from "lodash/round";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import each from "lodash/each";
import capitalize from "lodash/capitalize";

type VirtualMeetSubmitIndexState = {
  complete: boolean;
  processing: boolean;
  apiError: string;
  memberNumber: string;
  birthDate: string;
  lifterId: string;
  attempt: string;
  units: "KG" | "LBS";
  weight: string;
  videoUrl: string;
  errors: Record<string, string>;
};

const VirtualMeetSubmitIndex = () => {
  const meet = useMeet();

  const getInitialState = (): VirtualMeetSubmitIndexState => {
    return {
      complete: false,
      processing: false,
      apiError: "",
      memberNumber: "",
      birthDate: "",
      lifterId: "",
      attempt: "",
      units: meet.units ?? "KG",
      weight: "",
      videoUrl: "",
      errors: {},
    };
  };

  const [state, setState] = React.useState(getInitialState());

  const onClickReset = () => {
    setState(getInitialState());
  };

  const onClickSubmitAnother = () => {
    setState((prev) => ({
      ...prev,
      complete: false,
      attempt: "",
      weight: "",
      videoUrl: "",
    }));
  };

  const handleChange = (name: string, value: any) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const onSubmitLookup = () => {
    setState((prev) => ({ ...prev, processing: true }));
    window.scrollTo(0, 0);

    const body = {
      memberNumber: state.memberNumber,
      birthDate: state.birthDate,
    };

    fetchWrapper(`/api/meets/${meet._id}/lifter_lookup`, "POST", body)
      .then((response) => {
        if (response.ok) {
          setState((prev) => ({
            ...prev,
            processing: false,
            apiError: "",
            lifterId: response.lifter_id,
          }));
        } else {
          setState((prev) => ({
            ...prev,
            processing: false,
            apiError: response.error,
          }));
        }
      })
      .catch((error) => {
        errorReporter({ error });
        setState((prev) => ({ ...prev, processing: false, apiError: error }));
      });
  };

  const onSubmitAttempt = () => {
    setState((prev) => ({ ...prev, processing: true }));
    window.scrollTo(0, 0);

    let weight = toNumber(state.weight);
    if (meet.units === "KG" && state.units === "LBS") {
      weight = round(toKg(weight), 2);
    } else if (meet.units === "LBS" && state.units === "KG") {
      weight = round(toLbs(weight), 2);
    }

    const body = {
      memberNumber: state.memberNumber,
      birthDate: state.birthDate,
      attempt: state.attempt,
      weight,
      videoUrl: state.videoUrl,
    };

    fetchWrapper(
      `/api/meets/${meet._id}/virtual_meet_submit_attempt`,
      "POST",
      body
    )
      .then((response) => {
        if (response.ok) {
          setState((prev) => ({
            ...prev,
            processing: false,
            complete: true,
            apiError: "",
          }));
        } else {
          setState((prev) => ({
            ...prev,
            processing: false,
            apiError: response.error,
          }));
        }
      })
      .catch((error) => {
        errorReporter({ error });
        setState((prev) => ({ ...prev, processing: false, apiError: error }));
      });
  };

  const isLookupFormValid = () => {
    const errors: Record<string, string> = {};
    if (!state.memberNumber) {
      errors.memberNumber = "memberNumber is required";
    }

    if (!state.birthDate) {
      errors.birthDate = "birthDate is required";
    }

    setState((prev) => ({ ...prev, errors }));
    if (isEmpty(errors)) {
      return true;
    }

    toastr.error("Please fix issues", "");
    return false;
  };

  const isAttemptFormValid = () => {
    const errors: Record<string, string> = {};
    if (!state.memberNumber) {
      errors.memberNumber = "memberNumber is required";
    }

    if (!state.birthDate) {
      errors.birthDate = "birthDate is required";
    }

    if (!state.attempt) {
      errors.attempt = "attempt is required";
    }

    if (!state.weight) {
      errors.weight = "weight is required";
    }

    if (!state.videoUrl) {
      errors.videoUrl = "videoUrl is required";
    }

    setState((prev) => ({ ...prev, errors }));
    if (isEmpty(errors)) {
      return true;
    }

    toastr.error("Please fix issues", "");
    return false;
  };

  const renderMeetInfo = () => {
    return (
      <div className="meet-info">
        <div>Submit virtual meet attempt for:</div>
        <div>{meet.name}</div>
      </div>
    );
  };

  const attemptOptions = () => {
    if (!state.lifterId) {
      return [];
    }

    const lifter = get(meet, ["lifters", state.lifterId]);

    const attempts: any = [{ label: "Select...", value: "" }];

    const lifts = ["squat", "bench", "dead"];
    const attemptNumbers = ["1", "2", "3"];
    each(lifts, (lift) => {
      each(attemptNumbers, (attemptNumber) => {
        const isSubmitted = get(lifter, [
          "lifts",
          lift,
          attemptNumber,
          "videoUrl",
        ]);
        const isDisabled = get(lifter, [
          "lifts",
          lift,
          attemptNumber,
          "result",
        ]);
        const label = `${capitalize(
          lift === "bench" ? getBenchLabel(meet) : lift
        )} ${attemptNumber}${
          isSubmitted && !isDisabled ? " - Already Submitted" : ""
        }${isDisabled ? " - Already Scored" : ""}`;
        attempts.push({
          label,
          value: `${lift}${attemptNumber}`,
          disabled: isDisabled,
        });
      });
    });

    return attempts;
  };

  if (!meet.virtualMeet) {
    window.scrollTo(0, 0);
    return (
      <div className="virtual-meet-submit-index">
        <div className="message">This is not a virtual meet.</div>
      </div>
    );
  }

  if (state.complete) {
    window.scrollTo(0, 0);
    return (
      <div className="virtual-meet-submit-index">
        <div className="message">Attempt accepted</div>
        <button className="submit-button" onClick={onClickSubmitAnother}>
          SUBMIT ANOTHER
        </button>
        <button className="submit-button" onClick={onClickReset}>
          RESET FORM
        </button>
      </div>
    );
  }

  return (
    <div className="virtual-meet-submit-index">
      {state.processing && (
        <div>
          <div className="processing-overlay" />
          <div className="processing-spinner">
            <div className="loader" />
          </div>
        </div>
      )}
      {state.apiError && (
        <div className="api-error">
          <div>There was an error processing your request.</div>
          <div>{state.apiError}</div>
        </div>
      )}

      {renderMeetInfo()}

      <div className="entry-row">
        <label>Membership Number *</label>
        <div className="input-wrapper">
          <input
            type="text"
            className={classNames({ error: state.errors.memberNumber })}
            value={state.memberNumber}
            onChange={(e) => handleChange("memberNumber", e.target.value)}
          />
          <div className="error-message">{state.errors.memberNumber}</div>
        </div>
      </div>
      <div className="entry-row">
        <label>Birth Date *</label>
        <div className="input-wrapper">
          <DateInput
            dateFormat={getDateFormat(meet)}
            className={classNames({ error: state.errors.birthDate })}
            value={state.birthDate}
            onChange={(value) => handleChange("birthDate", value)}
            placeholder={getDateFormat(meet)}
          />
          <div className="error-message">{state.errors.birthDate}</div>
        </div>
      </div>
      {state.lifterId && (
        <div>
          <div className="lifter-name">
            {get(meet, ["lifters", state.lifterId, "name"])}
          </div>
          <div className="entry-row">
            <label>Attempt *</label>
            <div className="input-wrapper">
              <Select
                name="attempt"
                className={classNames({ error: state.errors.attempt })}
                value={state.attempt}
                options={attemptOptions()}
                onChange={(value) => handleChange("attempt", value)}
                clearable={false}
              />
              <div className="error-message">{state.errors.attempt}</div>
            </div>
          </div>
          <div className="entry-row">
            <label>Units *</label>
            <div className="input-wrapper">
              <Select
                name="units"
                className={classNames({ error: state.errors.units })}
                value={state.units}
                options={getUnitOptions()}
                onChange={(value) => handleChange("units", value)}
                clearable={false}
              />
              <div className="error-message">{state.errors.attempt}</div>
            </div>
          </div>
          <div className="entry-row">
            <label>Weight ({state.units})*</label>
            <div className="input-wrapper">
              <input
                type="number"
                className={classNames({ error: state.errors.weight })}
                value={state.weight}
                onChange={(e) => handleChange("weight", e.target.value)}
              />
              <div className="error-message">{state.errors.weight}</div>
            </div>
          </div>
          <div className="entry-row">
            <label>Video URL *</label>
            <div className="input-wrapper">
              <input
                type="text"
                className={classNames({ error: state.errors.videoUrl })}
                value={state.videoUrl}
                onChange={(e) => handleChange("videoUrl", e.target.value)}
              />
              <div className="error-message">{state.errors.videoUrl}</div>
            </div>
          </div>
          <button
            className="submit-button"
            onClick={() => isAttemptFormValid() && onSubmitAttempt()}
          >
            SUBMIT
          </button>
        </div>
      )}
      {!state.lifterId && (
        <div>
          <button
            className="submit-button"
            onClick={() => isLookupFormValid() && onSubmitLookup()}
          >
            LOOKUP
          </button>
        </div>
      )}

      {state.apiError && (
        <div className="api-error">
          <div>There was an error processing your request.</div>
          <div>{state.apiError}</div>
        </div>
      )}
    </div>
  );
};

export default VirtualMeetSubmitIndex;
