import React from "react";

type CellErrorBoundaryProps = { children: React.ReactNode };

class CellErrorBoundary extends React.Component<
  CellErrorBoundaryProps,
  { hasError: boolean }
> {
  constructor(props: CellErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, info: any) {
    console.log(error, info);
    // Display fallback UI
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <div className="table-cell-inner">ERROR</div>;
    }
    return this.props.children;
  }
}

export default CellErrorBoundary;
