import React from "react";
import classNames from "classnames";

import { getTotal } from "util/lifterHelper";
import { Meet } from "types";
import round from "lodash/round";

const TotalCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: any;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  const division = lifter.division;

  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else if (lifter.row === "title") {
    return <div style={style} className="table-cell-inner" />;
  } else {
    return (
      <div
        style={style}
        className={classNames("table-cell-inner", {
          "scored-column": division.scoreBy === "TOTAL",
        })}
      >
        {round(getTotal(lifter, division._id, meet), 2)}
      </div>
    );
  }
};

export default TotalCell;
