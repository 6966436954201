import React from "react";
import { useSelector } from "react-redux";
import compact from "lodash/compact";
import FileIcon from "icons/FileIcon";
import { getResultsTableData } from "util/getDivisionResultsData";
import ExportResultsModal from "./ExportResultsModal";
import Table, { Column } from "components/table/Table";
import DefaultTextCell from "components/cells/DefaultTextCell";
import NameCell from "./cells/NameCell";
import TeamCell from "./cells/TeamCell";
import PlaceCell from "./cells/PlaceCell";
import AttemptCell from "./cells/AttemptCell";
import ScoreCell from "./cells/ScoreCell";
import CheckIcon from "icons/CheckIcon";
import { getBenchLabel } from "util/meetHelper";
import { Division, FedConfig, Meet, ReduxState, WeightClass } from "types";
import { useFedConfig } from "util/useFedConfig";
import { useMediaSize } from "util/useMediaSize";

const getAllColumns = ({
  meet,
  federationConfig,
}: {
  meet: Meet;
  federationConfig: FedConfig;
}): Column[] => {
  const showFourthAttempts = !!federationConfig?.fourthAttempts;

  // prettier-ignore
  return compact([
    {key: 'name', label: 'Name', width: 175, renderer: NameCell},
    {key: 'team', label: 'Team', width: 115, renderer: TeamCell},
    {key: 'place', label: 'Place', width: 55, renderer: PlaceCell},
    {key: 'bodyWeight', label: 'Body Wgt', width: 75, renderer: DefaultTextCell},
    {key: 'weight', label: 'Squat 1', width: 70, lift: 'squat', attemptNumber: '1', renderer: AttemptCell},
    {key: 'weight', label: 'Squat 2', width: 70, lift: 'squat', attemptNumber: '2', renderer: AttemptCell},
    {key: 'weight', label: 'Squat 3', width: 70, lift: 'squat', attemptNumber: '3', renderer: AttemptCell},
    showFourthAttempts ? {key: 'weight', label: 'Squat 4', width: 70, lift: 'squat', attemptNumber: '4', renderer: AttemptCell} : undefined,
    {key: 'weight', label: `${getBenchLabel(meet)} 1`, width: 70, lift: 'bench', attemptNumber: '1', renderer: AttemptCell},
    {key: 'weight', label: `${getBenchLabel(meet)} 2`, width: 70, lift: 'bench', attemptNumber: '2', renderer: AttemptCell},
    {key: 'weight', label: `${getBenchLabel(meet)} 3`, width: 70, lift: 'bench', attemptNumber: '3', renderer: AttemptCell},
    showFourthAttempts ? {key: 'weight', label: `${getBenchLabel(meet)} 4`, width: 70, lift: 'bench', attemptNumber: '4', renderer: AttemptCell} : undefined,
    {key: 'weight', label: 'Dead 1', width: 70, lift: 'dead', attemptNumber: '1', renderer: AttemptCell},
    {key: 'weight', label: 'Dead 2', width: 70, lift: 'dead', attemptNumber: '2', renderer: AttemptCell},
    {key: 'weight', label: 'Dead 3', width: 70, lift: 'dead', attemptNumber: '3', renderer: AttemptCell},
    showFourthAttempts ? {key: 'weight', label: 'Dead 4', width: 70, lift: 'dead', attemptNumber: '4', renderer: AttemptCell} : undefined,
    {key: 'score', label: "Score", width: 110, renderer: ScoreCell}
  ]);
};

const getCellLines = () => {
  return 0.85;
};

const ResultsTable = ({
  meet,
  divisions,
  showButtons,
  openDonateModal,
}: {
  meet: Meet;
  showButtons: boolean;
  openDonateModal?: () => void;
  divisions: (Omit<Division, "weightClasses"> & {
    weightClasses: WeightClass[];
  })[];
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const [forecasted, setForecasted] = React.useState(false);
  const federationConfig = useFedConfig();
  const media = useMediaSize();

  const blockExport = (exportFunction: () => void) => {
    if (forecasted) {
      alert("Can't export results in forecasted mode");
    } else {
      exportFunction();
    }
  };

  const openExportResultsModal = () => {
    setShowModal(true);
  };

  const closeExportResultsModal = () => {
    setShowModal(false);
  };

  const columns = getAllColumns({ meet, federationConfig });

  const data = useSelector((state: ReduxState) =>
    getResultsTableData(state, {
      divisions,
      forecasted,
      match: {
        params: { meetId: meet._id },
      },
    })
  );

  return (
    <div style={{ height: "100%" }}>
      {showButtons && (
        <div className="util-row">
          <button
            className="export-button"
            onClick={() => blockExport(() => openExportResultsModal())}
          >
            <FileIcon /> &nbsp; Export
          </button>
          <button
            className="forecasted"
            onClick={() => setForecasted((prev) => !prev)}
          >
            Forecasted&nbsp;{forecasted && <CheckIcon />}
          </button>
        </div>
      )}
      <div className="table-wrapper">
        <Table
          data={data}
          numberOfFixedLeftColumns={3}
          columns={columns}
          getCellLines={getCellLines}
          meet={meet}
          scrollToAlignment="center"
          media={media}
          padBottom
        />
      </div>
      <ExportResultsModal
        meet={meet}
        isOpen={showModal}
        onRequestClose={closeExportResultsModal}
        data={data}
        openDonateModal={openDonateModal}
      />
    </div>
  );
};

export default ResultsTable;
