import React from "react";
import SortableHeaderCell from "components/SortableHeaderCell";
import get from "lodash/get";

const DefaultCell = ({
  data,
  column,
  style,
}: {
  data: any;
  column: any;
  style: any;
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return <SortableHeaderCell style={style} column={column} />;
  } else {
    return (
      <div style={style} className="table-cell-inner">
        {get(lifter, column.key)}
      </div>
    );
  }
};

export default DefaultCell;
