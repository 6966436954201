import React from "react";
import Modal from "react-modal";

const InfoModal = ({
  isOpen,
  onRequestClose,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
}) => {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      contentLabel="Generate Divisions"
      onRequestClose={onRequestClose}
      className={{
        base: "info-modal",
        afterOpen: "info-modal-after-open",
        beforeClose: "info-modal-before-close",
      }}
      overlayClassName={{
        base: "info-modal-overlay",
        afterOpen: "info-modal-overlay-after-open",
        beforeClose: "info-modal-overlay-before-close",
      }}
    >
      <div className="content">
        <h2>
          Automatic records detection is a new experimental feature in
          LiftingCast. Use at your own risk. How this feature works is likely to
          change.
        </h2>
        <ul>
          <li>
            Unlike importing lifters and divisions, records imported here will
            replace all existing imported records.
          </li>
          <li>
            Start by downloading the template by clicking the "Download
            Template" button in the top right. This will export a CSV file with
            every possible record for your federation.
          </li>
          <li>
            Fill in all records that are relevant to your event. Consider asking
            your federation to add an export option in this format to the
            records database website.
          </li>
          <li>
            Select the updated records file by clicking the "Select Records
            file" button in the top left.
          </li>
          <li>
            See a preview of records you are going to import. Look for errors to
            verify the format is correct.
          </li>
          <li>Click "Confirm Import of Records" button.</li>
          <li>
            LiftingCast will import only the records relevant to this meet.
            Records for divisions that no one is entered in will be discarded.
            This is necessary to limit the size of the data set. Because we
            discard non-relevant records you will have to reupload the records
            file if you add lifters or if their divisions change. LiftingCast
            will keep all weight classes so you don't have to worry about
            lifters changing weight class.
          </li>
          <li>
            Records are now enabled for this meet. You can see your original
            imported records by clicking the "Records" link the meet navigation.
          </li>
          <li>
            Attempts that are recording breaking attempts will have a "R" next
            the the attempt weight. You can go to the lifter's details page by
            clicking the lifter's name to see more info about exactly which
            records could be broken.
          </li>
          <li>
            The attempt menu on the run page will also show what records could
            be broken for that attempt.
          </li>
          <li>
            If a records is broken LiftingCast will keep track of the updated
            record. Further record breaking attempts will have to break the new
            record.
          </li>
          <li>
            Currently only NATIONAL and WORLD records are supported. In the
            future support may be added for state or other regional records.
          </li>
        </ul>
      </div>

      <div className="button-row">
        <button onClick={onRequestClose} style={{ marginRight: 12 }}>
          Close
        </button>
      </div>
    </Modal>
  );
};

export default InfoModal;
