import React from "react";
import { EligibleRecordWithWeight } from "types";

const FormattedEligibleRecord = ({
  record,
}: {
  record: EligibleRecordWithWeight;
}) => {
  const {
    location,
    competitionType,
    lift,
    gender,
    equipmentLevel,
    tested,
    divisionCode,
    weightClass,
    recordWeight,
    updatedRecordWeight,
  } = record;
  // TODO: maybe look at meet and only show tested / untested if the fed has both.
  // TODO: use abbreviations for gender and equippment
  return (
    <span>
      {location} : {competitionType} : {lift} : {gender} : {equipmentLevel} :{" "}
      {tested === "U" ? "Untested :" : ""}
      {divisionCode} : w{weightClass} ={" "}
      <span
        style={{
          textDecoration: updatedRecordWeight ? "line-through" : undefined,
        }}
      >
        {recordWeight}
      </span>
      {!!updatedRecordWeight && <span> {updatedRecordWeight}</span>}
    </span>
  );
};

export default FormattedEligibleRecord;
