import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './Coach.scss';
import HamburgerIcon from 'icons/HamburgerIcon';
import GearIcon from 'icons/GearIcon';
import ThumbsUpIcon from 'icons/ThumbsUpIcon';
import ThumbsDownIcon from 'icons/ThumbsDownIcon';

class Question extends Component {
  render() {
    return (
      <div className="question">
        <div className="question-title">
          {this.props.title}
        </div>
        <div className="question-answer">
          {this.props.children}
        </div>
      </div>
    );
  }
}

class Coach extends Component {
  render() {
    return (
      <div className="coach">
        <div><Link to="/">Go Home</Link></div>
        <Question title="Coach Instructions">
          LiftingCast offers a coach access to several views of live data from the meet. Some of these views may be on screens
          at the venue. But the real power of LiftingCast comes from the ability of a coach to use their own device.
          You can use any device with a web browser such as a desktop computer, tablet, or mobile phone. Chrome browser is preferred.<br/>
          <br/>
          Find the meet on the home page <a href="https://liftingcast.com">https://liftingcast.com</a> and click the meet name.<br/>
          <br/>
          Navigate to other pages by clicking the menu icon <HamburgerIcon/> in the top left corner of the meet page.
        </Question>
        <Question title="Results">
          Results is the default page you land on when clicking the meet name from the homepage. This contains a list of all
          categories awards are being given for.<br/>
          <br/>
          The left three columns are locked in place. You side scroll the right most columns if the whole table doesn't fit on your device.<br/>
          <br/>
          By default lifters are listed in order of their current placing. Since all
          lifters have a total of 0 until they make one of each lift this list won't be of much use at the beginning of the meet.
          To see forecasted placings click on the "Forecasted" button. This will order the placings under the assumptions that
          all inputed attempts that have not been attempted yet are successful. Forecased setting can be very useful at the end
          of the meet to see what weight your lifter needs to lift to beat another lifter. Try compairing forecasted and regular
          totals to see how placings could change.<br/>
          <br/>
          You can also download the current state of results by clicking the "Export Awards Results" button. This will allow
          you to open the results in Excel or Google Docs to do a more in depth analysis.<br/>
          <br/>
          Click on a lifters name to go to the Lifter Details page.<br/>
          <br/>
          Click on a team name to go to the Team Results page.
        </Question>
        <Question title="Board">
          The scoreboard is the often up on screens at the venue. You can also load it up on your mobile device and customize
          what data is shown. The left column is locked in place. You side scroll the right most columns if the whole table doesn't fit on your device.<br/>
          <br/>
          Click the gear icon <GearIcon/> in the top right of the screen to bring up a menu that will allow you do
          add or remove columns on the scoreboard table. The "Place" and "Forecased Place" columns can be very helpful to you as a coach.<br/>
          <br/>
          If your lifter has entered more than one division the lifter will only be listed once. The additional divisions will be listed
          directly under the first division.<br/>
          <br/>
          Click the "Auto Scroll" button to automatically keep the current flight in view.<br/>
          <br/>
          The scoreboard can be used the see lifting order, but keep in mind that the order is based on the current attempt/lift number
          and could change when the flight restarts.<br/>
          <br/>
          Click on a lifters name to go to the Lifter Details page.<br/>
          Click on a team name to go to the Team Results page. You will have to show the team column first.
        </Question>
        <Question title="Display">
          The display page is often shown next to the platform. It contains information about the current lifter and a table that
          shows lifting order. It doesn't work well on mobile and is meant for a TV or monitor.<br/>
          <br/>
          The table on this page is similar to the scoreboard table, except each row represents a single attempt. If a lifter is doing
          a full power meet they will show up in the table 9 times.
        </Question>
        <Question title="Roster">
          The roster shows a breakdown of all lifters by Session - Platform - Flight. Lifters within a flight are ordered by lot number.<br/>
          <br/>
          Click on a lifters name to go to the Lifter Details page.
        </Question>
        <Question title="Team Details">
          This page is similar to the Results page but only shows lifters on your team.
        </Question>
        <Question title="Lifter Details">
          This is a very important page for coaches. It contains several sections of data that are speciic to your lifter.<br/>
          <br/>
          The top section contains basic info on the lifter like their name, lot, coefficients, and rack heights.<br/>
          <br/>
          Order section will show the complete lifting order for your lifter's platform. With this you can see how many attempts out your lifter is.<br/>
          <br/>
          Attempts section contains the results good <ThumbsUpIcon className="good" /> or bad <ThumbsDownIcon className="bad" /> for each of your lifter's attempts.
          If the meet is using LiftingCast's built in Ref Lights then you will also be able to see how each ref decided and what if any cards were given.<br/>
          <br/>
          Results section is the same as the main results page but only shows divisions / weightclasses your lifter is competing in.<br/>
          <br/>
          Eligible Records section shows American records for your lifter's age division and open division for lifters age 14+. Check with the meet director to see
          if you can set American Records at the meet.
        </Question>
      </div>
    );
  }
}

export default Coach;
