import find from "lodash/find";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import React from "react";
import { Meet } from "types";
import { useMeet } from "util/useMeet";

const RegistrationChecker = ({ meet }: { meet: Meet }) => {
  const warnings = [];

  let problemsFound = false;

  if (!get(meet, "entryConfig.maxEntries")) {
    warnings.push("Is missing max entries.");
  }

  if (!get(meet, "entryConfig.fullPowerliftingCost")) {
    warnings.push(
      "Is missing full powerlifting cost. It is needed even for push/pull and bench only meets."
    );
  }

  if (!get(meet, "entryConfig.stripePublicKey")) {
    warnings.push(
      "Is missing stripe public key. Use a test key from Stripe for testing."
    );
  }

  if (!get(meet, "restricted.stripeSecretKey")) {
    warnings.push(
      "Is missing stripe secret key. Use a test key from Stripe for testing."
    );
  }

  if (isEmpty(meet.divisions)) {
    warnings.push(
      "Is missing at least one division. You must create divisions to use the online entry."
    );
  }

  if (!isEmpty(warnings)) {
    problemsFound = true;
  }

  return (
    <div>
      Registration
      <hr />
      <ul>
        {map(warnings, (warning) => {
          return <li key={warning}>{warning}</li>;
        })}
      </ul>
      {!problemsFound && <div>No Problems Found</div>}
    </div>
  );
};

const DivisionChecker = ({ meet }: { meet: Meet }) => {
  let problemsFound = false;

  return (
    <div>
      Divisions
      <hr />
      <ul>
        {map(meet.divisions, (division) => {
          const warnings = [];

          if (!division.gender) {
            warnings.push("Is not assigned a gender.");
          }

          if (!division.rawOrEquipped) {
            warnings.push("Is not assigned an equipment level.");
          }

          if (isEmpty(division.weightClasses)) {
            warnings.push("Must have at least one weight class");
          }

          if (
            !!find(division.weightClasses, (weightClass) => !weightClass.name)
          ) {
            warnings.push("Is missing one or more weight class names.");
          }

          if (
            !!find(
              division.weightClasses,
              (weightClass) => !weightClass.maxWeight
            )
          ) {
            warnings.push("Is missing one or more max weights.");
          }

          if (isEmpty(warnings)) {
            return null;
          }

          problemsFound = true;

          return (
            <li key={division._id}>
              <label>{division.name || "BLANK"}:</label>
              <ul>
                {map(warnings, (warning) => {
                  return <li key={warning}>{warning}</li>;
                })}
              </ul>
            </li>
          );
        })}
      </ul>
      {!problemsFound && <div>No Problems Found</div>}
    </div>
  );
};

const LifterChecker = ({ meet }: { meet: Meet }) => {
  let problemsFound = false;

  return (
    <div>
      Lifters
      <hr />
      <ul>
        {map(meet.lifters, (lifter) => {
          const warnings = [];

          if (
            !!find(
              lifter.divisions,
              (lifterDivision) => !lifterDivision.rawOrEquipped
            )
          ) {
            warnings.push("Is missing one or more equipment levels.");
          }

          if (
            !!find(
              lifter.divisions,
              (lifterDivision) => !lifterDivision.divisionId
            )
          ) {
            warnings.push("Is missing one or more division names.");
          }

          if (
            !!find(
              lifter.divisions,
              (lifterDivision) => !lifterDivision.declaredAwardsWeightClassId
            )
          ) {
            warnings.push(
              "Is missing one or more declared weight class names."
            );
          }

          if (!lifter.session) {
            warnings.push("Is not assigned to a session.");
          }

          if (!lifter.platformId) {
            warnings.push("Is not assigned to a platform.");
          }

          if (!lifter.flight) {
            warnings.push("Is not assigned to a flight.");
          }

          if (!lifter.lot) {
            warnings.push("Missing lot number.");
          }

          if (!lifter.birthDate) {
            warnings.push("Missing birth date.");
          }

          if (isEmpty(warnings)) {
            return null;
          }

          problemsFound = true;

          return (
            <li key={lifter._id}>
              <label>{lifter.name || "BLANK"}:</label>
              <ul>
                {map(warnings, (warning) => {
                  return <li key={warning}>{warning}</li>;
                })}
              </ul>
            </li>
          );
        })}
      </ul>
      {!problemsFound && <div>No Problems Found</div>}
    </div>
  );
};

const SetupChecker = () => {
  const meet = useMeet();
  if (!meet) {
    return null;
  }

  const emptyDivisions = isEmpty(meet.divisions);
  const emptyLifters = isEmpty(meet.lifters);

  return (
    <div className="setup-checker">
      <br />
      {emptyDivisions && <div>You haven't created any divisions.</div>}
      {emptyLifters && <div>You haven't created any lifters.</div>}
      {(emptyLifters || emptyLifters) && <hr />}
      <RegistrationChecker meet={meet} />
      <br />
      <br />
      <DivisionChecker meet={meet} />
      <br />
      <br />
      <LifterChecker meet={meet} />
      <br />
      <br />
    </div>
  );
};

export default SetupChecker;
