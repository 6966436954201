import round from "lodash/round";
import React from "react";
import { Meet } from "types";
import { getScoreByLabel } from "util/getScoreByLabel";
import { getScore } from "util/lifterHelper";

const ScoreCell = ({
  data,
  meet,
  style,
}: {
  data: any;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  const division = lifter.division;
  const forecasted = lifter.forecasted;

  if (lifter.row === "header") {
    return (
      <div className="table-cell-inner" style={style}>
        {forecasted && "Forecasted"}
      </div>
    );
  } else if (lifter.row === "title") {
    let label: React.ReactNode = division
      ? getScoreByLabel(division.scoreBy)
      : "Error";

    if (forecasted) {
      label = (
        <span>
          Forecasted
          <br />
          {label}
        </span>
      );
    }

    return (
      <div style={style} className="table-cell-inner">
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          {label}
        </div>
      </div>
    );
  } else {
    const score = getScore(lifter, division, meet, forecasted);
    return (
      <div style={style} className="table-cell-inner">
        {typeof score === "number" ? round(score, 3) : score}
      </div>
    );
  }
};

export default ScoreCell;
