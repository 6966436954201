import React, { Component } from "react";
import first from "lodash/first";
import get from "lodash/get";
import { set } from "object-path-immutable";

import DisplayComponent from "./DisplayComponent";
import { getPlace } from "util/lifterHelper";

const getGetOrdinal = (n) => {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

class PositionChange extends Component {
  renderPositionChange = (currentPlace, possiblePlace) => {
    if (!currentPlace && !possiblePlace) {
      return null;
    } else if (currentPlace === possiblePlace) {
      return `If successful will stay in ${getGetOrdinal(possiblePlace)} place`;
    } else if (currentPlace && possiblePlace) {
      return `If successful will move from ${getGetOrdinal(
        currentPlace
      )} to ${getGetOrdinal(possiblePlace)} place`;
    }

    return null;
  };

  render() {
    const { meet, currentAttempt, currentLifter, id } = this.props;

    let currentPlace = 10;
    let possiblePlace = 1;

    if (currentAttempt && currentLifter) {
      currentPlace = getPlace(
        currentLifter,
        first(currentLifter.divisions),
        meet
      );

      // construct version of meet where the current attempt is sucessful.
      const futureMeet = set(
        meet,
        [
          "lifters",
          currentLifter._id,
          "lifts",
          currentAttempt.liftName,
          currentAttempt.attemptNumber,
          "result",
        ],
        "good"
      );

      possiblePlace = getPlace(
        currentLifter,
        first(currentLifter.divisions),
        futureMeet
      );
    }

    return (
      <DisplayComponent id={id} style={{ top: 170, left: 0 }}>
        {({ sizeMultiplier }) => {
          const style = {
            padding: sizeMultiplier * 12,
            fontSize: sizeMultiplier * 24,
            width: sizeMultiplier * 600,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            whiteSpace: "nowrap",
          };

          const currentAttemptNumber = get(currentAttempt, "attemptNumber");

          return (
            <div style={style}>
              {(currentAttemptNumber === "3" || !currentAttemptNumber) &&
                this.renderPositionChange(currentPlace, possiblePlace)}
            </div>
          );
        }}
      </DisplayComponent>
    );
  }
}

export default PositionChange;
