import React from "react";
import compact from "lodash/compact";
import get from "lodash/get";
import findIndex from "lodash/findIndex";
import Table, { Column } from "components/table/Table";
import DefaultTextCell from "components/cells/DefaultTextCell";
import NameCell from "./cells/NameCell";
import AttemptCell from "./cells/AttemptCell";
import RackHeightCell from "./cells/RackHeightCell";
import { isCurrentLifter } from "util/lifterHelper";
import { getBenchLabel } from "util/meetHelper";
import { Attempt, FedConfig, MediaSize, Meet, Platform } from "types";

const RunTable = ({
  meet,
  federationConfig,
  selectNextAttempt,
  sortedLifters,
  platform,
  currentAttempt,
  scrollToCurrentAttempt,
  media,
}: {
  meet: Meet;
  federationConfig: FedConfig;
  selectNextAttempt: any;
  sortedLifters: any[];
  platform: Platform;
  currentAttempt: Attempt;
  scrollToCurrentAttempt: boolean;
  media: MediaSize;
}) => {
  const getAllColumns = (): Column[] => {
    const showFourthAttempts = !!federationConfig?.fourthAttempts;

    // prettier-ignore
    return compact([
      {key: 'name', label: 'Name', width: 170, renderer: NameCell},
      {key: 'lot', label: 'Lot', width: 60, renderer: DefaultTextCell},
      {key: 'session', label: 'Session', width: 70, renderer: DefaultTextCell},
      {key: 'flight', label: 'Flight', width: 70, renderer: DefaultTextCell},
      {key: 'weight', label: 'Squat 1', width: 120, lift: 'squat', attemptNumber: '1', selectNextAttempt, renderer: AttemptCell},
      {key: 'weight', label: 'Squat 2', width: 120, lift: 'squat', attemptNumber: '2', selectNextAttempt, renderer: AttemptCell},
      {key: 'weight', label: 'Squat 3', width: 120, lift: 'squat', attemptNumber: '3', selectNextAttempt, renderer: AttemptCell},
      showFourthAttempts ? {key: 'weight', label: 'Squat 4', width: 120, lift: 'squat', attemptNumber: '4', selectNextAttempt, renderer: AttemptCell} : undefined,
      {key: 'squatRackHeight', liftName: 'squat', label: 'Squat Rack Height', width: 140, type: 'text', renderer: RackHeightCell},
      {key: 'weight', label: `${getBenchLabel(meet)} 1`, width: 120, lift: 'bench', attemptNumber: '1', selectNextAttempt, renderer: AttemptCell},
      {key: 'weight', label: `${getBenchLabel(meet)} 2`, width: 120, lift: 'bench', attemptNumber: '2', selectNextAttempt, renderer: AttemptCell},
      {key: 'weight', label: `${getBenchLabel(meet)} 3`, width: 120, lift: 'bench', attemptNumber: '3', selectNextAttempt, renderer: AttemptCell},
      showFourthAttempts ? {key: 'weight', label: `${getBenchLabel(meet)} 4`, width: 120, lift: 'bench', attemptNumber: '4', selectNextAttempt, renderer: AttemptCell} : undefined,
      {key: 'benchRackHeight', liftName: 'bench', label: `${getBenchLabel(meet)} Rack Height`, width: 140, type: 'text', renderer: RackHeightCell},
      {key: 'weight', label: 'Dead 1', width: 120, lift: 'dead', attemptNumber: '1', selectNextAttempt, renderer: AttemptCell},
      {key: 'weight', label: 'Dead 2', width: 120, lift: 'dead', attemptNumber: '2', selectNextAttempt, renderer: AttemptCell},
      {key: 'weight', label: 'Dead 3', width: 120, lift: 'dead', attemptNumber: '3', selectNextAttempt, renderer: AttemptCell},
      showFourthAttempts ? {key: 'weight', label: 'Dead 4', width: 120, lift: 'dead', attemptNumber: '4', selectNextAttempt, renderer: AttemptCell} : undefined
    ]);
  };

  const getCellLines = (index: number) => {
    const lifter = get(sortedLifters, index, {});
    if (lifter.row === "title") {
      return 0.5;
    }

    if (index === 0) {
      return 1;
    }

    if (isCurrentLifter(lifter, meet, platform._id)) {
      return 3;
    }

    return 1;
  };

  const columns = getAllColumns();

  let scrollToRow;
  if (scrollToCurrentAttempt && currentAttempt) {
    scrollToRow = findIndex(sortedLifters, (lifter) => {
      return currentAttempt.lifterId === lifter._id;
    });
  }

  return (
    <Table
      data={sortedLifters}
      columns={columns}
      getCellLines={getCellLines}
      meet={meet}
      media={media}
      scrollToRow={scrollToRow}
      scrollToAlignment="center"
      padBottom
      numberOfFixedLeftColumns={1}
    />
  );
};

export default RunTable;
