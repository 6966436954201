import React from "react";
import map from "lodash/map";
import get from "lodash/get";
import { Column } from "components/table/Table";
import { Meet } from "types";
import PouchSelect from "components/PouchSelect";
import SortableHeaderCell from "components/SortableHeaderCell";

const DivisionCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: Column;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return <SortableHeaderCell style={style} column={column} />;
  } else {
    return (
      <div style={style} className="table-cell-inner">
        {map(lifter.divisions, (division, index: number) => {
          if (!column.options) {
            return null;
          }
          return (
            <div key={index} className="inner-row">
              <PouchSelect
                key={index}
                index={index}
                meet={meet}
                document={lifter}
                name={["divisions", index, column.key]}
                value={get(lifter, ["divisions", index, column.key])}
                options={column.options}
                clearable
              />
            </div>
          );
        })}
      </div>
    );
  }
};

export default DivisionCell;
