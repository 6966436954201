import React from "react";
import classNames from "classnames";
import { set } from "object-path-immutable";
import Lights from "components/lights/Lights";
import NextIcon from "icons/NextIcon";
import {
  getDivisionDoc,
  getBestLift,
  getPlace,
  getSubtotal,
  getLifterAwardsWeightClassDoc,
} from "util/lifterHelper";
import { getTotal } from "../../../util/lifterHelper";
import get from "lodash/get";
import { AttemptNumber, LiftName, Lifter, RefPosition } from "types";
import { useMeet } from "util/useMeet";
import { useCurrentAttempt } from "util/useCurrentAttempt";
import { usePlatform } from "util/usePlatform";
import { useCurrentLifter } from "util/useCurrentLifter";
import capitalize from "lodash/capitalize";
import size from "lodash/size";

const getGetOrdinal = (n: number) => {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return (
    <span>
      {n}
      <sup>{s[(v - 20) % 10] || s[v] || s[0]}</sup>
    </span>
  );
};

const AttemptCell = ({
  currentLifter,
  liftName,
  attemptNumber,
  currentAttemptNumber,
}: {
  currentLifter: Lifter | undefined;
  liftName: LiftName | undefined;
  attemptNumber: AttemptNumber;
  currentAttemptNumber: AttemptNumber | undefined;
}) => {
  let weight: string | number = "";
  let result = "";
  if (liftName) {
    const attempt = get(currentLifter, ["lifts", liftName, attemptNumber]);
    weight = get(attempt, "weight", "");
    result = get(attempt, "result", "");
  }
  return (
    <div
      className={classNames("platform-weight-cell", result, {
        "current-attempt": currentAttemptNumber === attemptNumber,
      })}
    >
      {weight}
    </div>
  );
};

const Platform3 = ({ platformId }: { platformId: string }) => {
  const meet = useMeet();
  const currentAttempt = useCurrentAttempt(platformId);
  const platform = usePlatform(platformId);
  const currentLifter = useCurrentLifter(platformId);

  const getDecision = (position: RefPosition) => {
    return get(meet, ["platforms", platform._id, "refs", position, "decision"]);
  };

  const allDecisionsMade = () => {
    return getDecision("left") && getDecision("head") && getDecision("right");
  };

  if (!platform || !meet) {
    return null;
  }

  const lifterName = get(
    currentLifter,
    "name",
    "------------------ -------------------"
  );

  const liftName = get(currentAttempt, "liftName");
  let liftDisplayName = capitalize(liftName);
  if (liftName === "dead") {
    liftDisplayName = "Deadlift";
  }
  const currentAttemptNumber = get(currentAttempt, "attemptNumber");
  let weightClassName = "---";
  let divisionName = "------------- ---------------- ----- ----";

  let subtotal: number | string = "000";
  let total = 0;

  let bestSquat: number | null = null;
  let bestBench: number | null = null;
  let bestDead: number | null = null;

  let totalDivisionCurrentPlace: number | null = 0;
  let totalDivisionForecastedPlace: number | null = 0;
  let totalLifterDivision;
  let divisionDoc;
  let fullMeetForecastedPlace: number | null = 1;

  if (currentLifter && currentAttempt) {
    bestSquat = get(getBestLift("squat", currentLifter), "weight", null);
    bestBench = get(getBestLift("bench", currentLifter), "weight", null);
    bestDead = get(getBestLift("dead", currentLifter), "weight", null);

    totalLifterDivision = get(currentLifter, "divisions.0");
    divisionDoc = getDivisionDoc(totalLifterDivision.divisionId, meet);
    if (divisionDoc) {
      const weightClassDoc = getLifterAwardsWeightClassDoc(
        meet,
        currentLifter,
        totalLifterDivision
      );
      weightClassName = get(weightClassDoc, "name", "");
      divisionName = get(divisionDoc, "name", "");

      if (weightClassName && size(divisionDoc.weightClasses) > 1) {
        divisionName = `${divisionName} - ${weightClassName}`;
      }
    }

    if (currentAttempt.liftName && currentAttempt.attemptNumber) {
      // construct version of meet where the current attempt is sucessful.
      const futureMeet = set(
        meet,
        [
          "lifters",
          currentLifter._id,
          "lifts",
          currentAttempt.liftName,
          currentAttempt.attemptNumber,
          "result",
        ],
        "good"
      );

      totalDivisionCurrentPlace = getPlace(
        currentLifter,
        totalLifterDivision,
        meet
      );
      totalDivisionForecastedPlace = getPlace(
        currentLifter,
        totalLifterDivision,
        futureMeet
      );
      fullMeetForecastedPlace = getPlace(
        currentLifter,
        totalLifterDivision,
        meet,
        true
      );

      subtotal = getSubtotal(currentLifter, meet);
      if (totalLifterDivision.divisionId) {
        total = getTotal(currentLifter, totalLifterDivision.divisionId, meet);
      }
    }
  }

  return (
    <div className="platform3">
      <div
        className={classNames("platform-lights", {
          "platform-lights-active": allDecisionsMade() || !currentLifter,
        })}
      >
        <div className="platform-lights-wrapper">
          <Lights
            meet={meet}
            platformId={get(platform, "_id")}
            style={{ height: 70 }}
          />
        </div>
      </div>
      <div className="platform-table">
        <div
          className={classNames("platform-current-name", {
            "long-name": lifterName.length > 21,
          })}
        >
          <div>
            {lifterName}
            <div
              className={classNames("current-division", {
                "long-name": divisionName.length > 39,
              })}
            >
              {divisionName}
            </div>
          </div>
        </div>

        <div className="platform-lift-name">{liftDisplayName}</div>
        <AttemptCell
          currentLifter={currentLifter}
          liftName={liftName}
          attemptNumber={"1"}
          currentAttemptNumber={currentAttemptNumber}
        />
        <AttemptCell
          currentLifter={currentLifter}
          liftName={liftName}
          attemptNumber={"2"}
          currentAttemptNumber={currentAttemptNumber}
        />
        <AttemptCell
          currentLifter={currentLifter}
          liftName={liftName}
          attemptNumber={"3"}
          currentAttemptNumber={currentAttemptNumber}
        />

        <div className="platform-best-lifts">
          <div
            className={classNames("platform-best-lift", {
              "platform-best-lift-missing": bestSquat === null,
            })}
          >
            <span className="platform-best-lift-label">S</span> {bestSquat}
          </div>
          <div
            className={classNames("platform-best-lift", {
              "platform-best-lift-missing": bestBench === null,
            })}
          >
            <span className="platform-best-lift-label">B</span> {bestBench}
          </div>
          <div
            className={classNames("platform-best-lift", {
              "platform-best-lift-missing": bestDead === null,
            })}
          >
            <span className="platform-best-lift-label">D</span> {bestDead}
          </div>
        </div>
        <div className="platform-placements">
          <div className="platform-placement">
            <div className="platform-placement-name">
              {subtotal && !total && <span>Sub-Total</span>}
              {!!total && <span>Total</span>}
            </div>
            <div className="platform-placement-change">
              {subtotal && !total && <span>{subtotal}</span>}
              {!!total && <span>{total}</span>}
            </div>
          </div>
          <div className="platform-placement">
            <div className="platform-placement-name">
              {!!totalDivisionCurrentPlace && !!totalDivisionForecastedPlace
                ? "Placement"
                : "Forecasted"}
            </div>
            <div className="platform-placement-change">
              {!!totalDivisionCurrentPlace && !!totalDivisionForecastedPlace ? (
                <div>
                  {getGetOrdinal(totalDivisionCurrentPlace)} <NextIcon />{" "}
                  {getGetOrdinal(totalDivisionForecastedPlace)}
                </div>
              ) : fullMeetForecastedPlace ? (
                getGetOrdinal(fullMeetForecastedPlace)
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Platform3;
