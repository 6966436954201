import React from "react";
import { exportLifters } from "util/exportData";
import { parseLifters } from "util/importData";
import { addLifter } from "util/pouchActions";

import Import from "../Import";
import ImportLiftersTable from "./ImportLiftersTable";
import { useMediaSize } from "util/useMediaSize";
import { useMeet } from "util/useMeet";

const ImportLifters = ({ onFinishBulkCreate }: { onFinishBulkCreate: any }) => {
  const media = useMediaSize();
  const meet = useMeet();

  return (
    <Import
      label={"Lifters"}
      meet={meet}
      addItem={addLifter}
      parseItems={parseLifters}
      exportItems={exportLifters}
      itemsTable={ImportLiftersTable}
      media={media}
      onFinishBulkCreate={onFinishBulkCreate}
    />
  );
};

export default ImportLifters;
