import React from "react";
import "./CheckInSheet.scss";
import CheckIcon from "icons/CheckIcon";
import { getRealAge } from "util/lifterHelper";
import { getSessionOptions } from "util/options";
import { useMeet } from "util/useMeet";
import sortBy from "lodash/sortBy";
import map from "lodash/map";
import filter from "lodash/filter";
import size from "lodash/size";
import find from "lodash/find";
import get from "lodash/get";

const CheckInSheet = () => {
  const meet = useMeet();
  const lifters = sortBy(meet.lifters, ["name"]);
  const meetItems = meet.entryConfig.items;

  return (
    <div className="check-in-sheet">
      {map(getSessionOptions(), (session) => {
        const sessionLifters = filter(lifters, { session: session.value });
        const sessionCount = size(sessionLifters);
        if (!sessionCount) {
          return null;
        }

        return (
          <div key={session.value} className="session-pages">
            <div className="meet-name">
              {meet.date} - {meet.name} - Session {session.value} - CHECK IN
            </div>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Member Number</th>
                  <th>
                    Current Member <CheckIcon />
                  </th>
                  <th>
                    Photo ID <CheckIcon />
                  </th>
                  <th>State</th>
                  <th>Birth Date</th>
                  <th>Age</th>
                  <th>
                    Liability <CheckIcon />
                  </th>
                  <th>T-shirt</th>
                </tr>
              </thead>
              <tbody>
                {map(sessionLifters, (lifter) => {
                  return (
                    <tr key={lifter._id}>
                      <td>{lifter.name}</td>
                      <td>{lifter.restricted?.memberNumber}</td>
                      <td></td>
                      <td></td>
                      <td>{lifter.state}</td>
                      <td>{lifter.birthDate}</td>
                      <td>{getRealAge(lifter, meet)}</td>
                      <td></td>
                      <td>
                        {map(lifter.restricted?.items, (itemId, index) => {
                          const item = find(meetItems, { id: itemId });
                          return (
                            <span key={index}>
                              {get(item, "name")}
                              {size(lifter.restricted?.items) !== index + 1 &&
                                ", "}
                            </span>
                          );
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
};

export default CheckInSheet;
