import React from "react";
import {
  getBarAndCollarsWeightOptions,
  getClockTimerLengthOptions,
} from "util/options";
import { updateAttributeOnDocument } from "util/pouchActions";
import PouchSelect from "components/PouchSelect";
import FixedHeightWrapper from "app/meets/FixedHeightWrapper";
import Lights from "components/lights/Lights";
import Clock from "components/clock/Clock";
import AttemptsCount from "components/AttemptsCount";
import RunTable from "./RunTable";
import { selectNextAttempt } from "util/selectNextAttempt";
import CheckIcon from "icons/CheckIcon";
import ClockIcon from "icons/ClockIcon";
import ResetIcon from "icons/ResetIcon";
import "./RunIndex.scss";
import { usePlatform } from "util/usePlatform";
import { useMeet } from "util/useMeet";
import { LiftingOrder, Meet, Platform } from "types";
import isEmpty from "lodash/isEmpty";
import { useMediaSize } from "util/useMediaSize";
import { useCurrentAttempt } from "util/useCurrentAttempt";
import get from "lodash/get";
import { useFedConfig } from "util/useFedConfig";
import { useKnownLiftingOrder } from "util/useKnownLiftingOrder";
import { useCompleteLiftingOrder } from "util/useCompleteLiftingOrder";
import { useScoreBoardTableData } from "util/useScoreBoardTableData";

const RunIndex = () => {
  const meet = useMeet();
  const platform = usePlatform();
  const media = useMediaSize();
  const currentAttempt = useCurrentAttempt();
  const federationConfig = useFedConfig();
  const knownLiftingOrder = useKnownLiftingOrder();
  const completeLiftingOrder = useCompleteLiftingOrder();
  const tableData = useScoreBoardTableData();

  const [scrollToCurrentAttempt, setScrollToCurrentAttempt] =
    React.useState(false);

  const toggleScrollToCurrentAttempt = () => {
    setScrollToCurrentAttempt((prev) => !prev);
  };

  const selectNextAttemptInternal = (liftingOrder: LiftingOrder) => {
    selectNextAttempt(liftingOrder, meet, platform);
  };

  const startClock = (platform: Platform, meet: Meet) => {
    updateAttributeOnDocument(meet._id, platform._id, "clockState", "started");
  };

  const resetClock = (platform: Platform, meet: Meet) => {
    updateAttributeOnDocument(meet._id, platform._id, "clockState", "initial");
  };

  const toggleAttemptChangeInProgress = () => {
    updateAttributeOnDocument(
      meet._id,
      platform._id,
      "attemptChangeInProgress",
      !platform.attemptChangeInProgress
    );
  };

  return (
    <FixedHeightWrapper>
      <div className="run-index">
        <div className="util-row">
          {!platform.currentAttemptId && !isEmpty(knownLiftingOrder) && (
            <button
              className="select-first-button"
              onClick={() => selectNextAttemptInternal(knownLiftingOrder)}
            >
              Select First Lifter
            </button>
          )}
          <div>
            <Clock platform={platform} />
          </div>
          {platform.currentAttemptId && (
            <Lights
              meet={meet}
              platformId={platform._id}
              showChecks
              style={{ height: 35 }}
            />
          )}
          <button
            className="auto-scroll-button"
            style={{ minWidth: "120px", marginRight: "8px" }}
            onClick={toggleScrollToCurrentAttempt}
          >
            Auto Scroll &nbsp;
            {scrollToCurrentAttempt && <CheckIcon />}
          </button>
          <button
            className="auto-scroll-button"
            style={{ minWidth: "160px" }}
            onClick={toggleAttemptChangeInProgress}
          >
            Attempt Change &nbsp;
            {!!platform.attemptChangeInProgress && <CheckIcon />}
          </button>
          <div className="bar-and-collars-weight">
            <div>Bar &amp; Collars Weight:</div>
            <PouchSelect
              meet={meet}
              document={platform}
              name="barAndCollarsWeight"
              value={get(platform, "barAndCollarsWeight")}
              options={getBarAndCollarsWeightOptions}
              clearable={false}
            />
          </div>
          <div className="custom-timer-length">
            <div>Timer Length:</div>
            <PouchSelect
              meet={meet}
              document={platform}
              name="clockTimerLength"
              value={get(platform, "clockTimerLength")}
              options={getClockTimerLengthOptions}
              clearable={false}
              disabled={platform.clockState !== "initial"}
              allowCreate
              allowCreateOptionLabel="Custom"
              allowCreatePrompt="Enter number of minutes"
              allowCreateTransform={(numberOfMinutes) => {
                const minutes = Number(numberOfMinutes);
                if (String(minutes) === numberOfMinutes) {
                  return minutes * 60000;
                }
                return get(platform, "clockTimerLength") ?? 60000;
              }}
            />
            <button
              onClick={() => startClock(platform, meet)}
              disabled={platform.clockState === "started"}
            >
              <ClockIcon />
            </button>
            <button
              onClick={() => resetClock(platform, meet)}
              disabled={platform.clockState === "initial"}
            >
              <ResetIcon />
            </button>
            <AttemptsCount completeLiftingOrder={completeLiftingOrder} />
          </div>
        </div>
        <div className="table-wrapper">
          <RunTable
            meet={meet}
            sortedLifters={tableData}
            selectNextAttempt={selectNextAttemptInternal}
            platform={platform}
            scrollToCurrentAttempt={scrollToCurrentAttempt}
            currentAttempt={currentAttempt}
            media={media}
            federationConfig={federationConfig}
          />
        </div>
      </div>
    </FixedHeightWrapper>
  );
};

export default RunIndex;
