import get from "lodash/get";
import { getRealAge, getYearAge } from "./lifterHelper";
import {
  usaplBaseDivisions,
  usaplStandardDivisionAge,
} from "./federations/usapl";
import { apaBaseDivisions } from "./federations/apa";
import { getIpfAge, ipfBaseDivisions } from "./federations/ipf";
import { ussfBaseDivisions } from "./federations/ussf";
import { uspaBaseDivisions } from "./federations/uspa";
import { cpuBaseDivisions } from "./federations/cpu";
import { otherBaseDivisions } from "./federations/other";
import { ssfBaseDivisions } from "./federations/ssf";
import { wppBaseDivisions } from "./federations/wpp";
import { mmpBaseDivisions } from "./federations/mmp";
import { rpsBaseDivisions } from "./federations/rps";
import { oneHundredRawBaseDivisions } from "./federations/oneHundredRaw";
import { ipaBaseDivisions } from "./federations/ipa";
import { wrpfBaseDivisions } from "./federations/wrpf";
import { Division, FederationDivisionsConfig, Lifter, Meet } from "types";
import { powerliftingAmericaBaseDivisions } from "./federations/powerliftingAmerica";

export const competitionCode = function (division: Division | undefined) {
  if (!division) {
    return "";
  }
  let compEvents = "";
  const isSquatting = division.lifts.squat;
  const isBenching = division.lifts.bench;
  const isDeadlifting = division.lifts.dead;
  if (isSquatting && isBenching && isDeadlifting) {
    compEvents = "PL";
  } else if (isBenching && isDeadlifting) {
    compEvents = "PP";
  } else if (isBenching) {
    compEvents = "BP";
  } else if (isDeadlifting) {
    compEvents = "DL";
  } else if (isSquatting) {
    compEvents = "SQ";
  }

  return compEvents;
};

export const getBaseDivisions = (meet: Meet): FederationDivisionsConfig => {
  const baseDivisions = {
    APA: apaBaseDivisions,
    USAPL: usaplBaseDivisions,
    CPU: cpuBaseDivisions,
    IPF: ipfBaseDivisions,
    POWERLIFTING_AMERICA: powerliftingAmericaBaseDivisions,
    USSF: ussfBaseDivisions,
    USPA: uspaBaseDivisions,
    OTHER: otherBaseDivisions,
    SSF: ssfBaseDivisions,
    WPP: wppBaseDivisions,
    MMP: mmpBaseDivisions,
    IPA: ipaBaseDivisions,
    RPS: rpsBaseDivisions,
    "100RAW": oneHundredRawBaseDivisions,
    WRPF: wrpfBaseDivisions,
    PLU: wrpfBaseDivisions,
  };

  if (!meet.federation) {
    return otherBaseDivisions;
  }

  return get(baseDivisions, meet.federation, usaplBaseDivisions);
};

export const standardDivisionAge = function (lifter: Lifter, meet: Meet) {
  if (meet.federation === "USAPL") {
    return usaplStandardDivisionAge(lifter, meet);
  } else if (meet.federation === "IPF") {
    return getIpfAge(lifter, meet);
  } else if (meet.federation === "USSF") {
    return getRealAge(lifter, meet);
  } else if (meet.federation === "WPP") {
    return getYearAge(lifter, meet);
  }

  return getRealAge(lifter, meet);
};
