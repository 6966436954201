import React from "react";
import UAParser from "ua-parser-js";
import { toastr } from "react-redux-toastr";

const warnOldBrowser = () => {
  try {
    const parser = new UAParser();
    const result = parser.getResult();
    const browserName = result.browser.name;
    const browserMajorVersion = Number(result.browser.version?.split(".")?.[0]);
    if (
      (browserName === "Safari" || browserName === "Mobile Safari") &&
      browserMajorVersion < 13
    ) {
      // alert('Your version of Safari is not supported. Please update to at least Safari version 13 to use this site.');
      console.error(
        "Your version of Safari is not supported. Please update to at least Safari version 13 to use this site."
      );
      toastr.error(
        "Your version of Safari is not supported. Please update to at least Safari version 13 to use this site.",
        "",
        { timeOut: 0 }
      );
    }
  } catch (e) {
    console.error("Failed to parse UA");
  }
};

export const useWarnOnOldBrowsers = () => {
  React.useEffect(() => {
    warnOldBrowser();
  }, []);
};
