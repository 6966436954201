import React from "react";
import classNames from "classnames";
import { getBodyWeightAndAgeAdjustedTotal } from "util/lifterHelper";
import { Column } from "components/table/Table";
import { Meet } from "types";
import round from "lodash/round";

const BodyWeightAndAgeAdjustedTotalCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: Column;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  const division = lifter.division;

  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else if (lifter.row === "title") {
    return <div style={style} className="table-cell-inner" />;
  } else {
    return (
      <div
        style={style}
        className={classNames("table-cell-inner", {
          "scored-column": division.scoreBy === "BODY_WEIGHT_AND_AGE_POINTS",
        })}
      >
        {round(getBodyWeightAndAgeAdjustedTotal(lifter, division._id, meet), 2)}
      </div>
    );
  }
};

export default BodyWeightAndAgeAdjustedTotalCell;
