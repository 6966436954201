import round from "lodash/round";
import React from "react";
import { Meet } from "types";
import { getScoreByLabel } from "util/getScoreByLabel";
import { getScore } from "util/lifterHelper";

const ScoreCell = ({
  data,
  meet,
  style,
}: {
  data: any;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  const division = lifter.division;
  const forecasted = lifter.forecasted;

  if (lifter.row === "header") {
    let label: React.ReactNode = division
      ? getScoreByLabel(division.scoreBy)
      : "Error";

    if (forecasted) {
      label = (
        <span>
          Forecasted
          <br />
          {label}
        </span>
      );
    }
    return (
      <div className="table-cell-inner" style={style}>
        {label}
      </div>
    );
  } else {
    const score = getScore(lifter, division, meet, forecasted);
    if (!score) {
      return null;
    }
    return (
      <div style={style} className="table-cell-inner">
        {round(score, 3)}
      </div>
    );
  }
};

export default ScoreCell;
