import React from "react";
import {
  ErrorCode,
  FileError,
  FileRejection,
  useDropzone,
} from "react-dropzone";
import "./FileUpload.scss";
import isEmpty from "lodash/isEmpty";
import first from "lodash/first";

type FileUploadProps = {
  buttonText: React.ReactNode;
  onChange: (data: string) => void;
  accept: "csv" | "json";
};

const FileUpload = ({ buttonText, onChange, accept }: FileUploadProps) => {
  const onDrop = (acceptedFiles: File[]) => {
    if (!isEmpty(acceptedFiles)) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const stringData = reader.result;
        if (typeof stringData === "string") {
          onChange(stringData);
        } else {
          alert("Something went wrong.");
        }
      };
      reader.readAsText(acceptedFiles[0]);
    }
  };

  const validator = (file: File & { path?: string }): FileError | null => {
    if (accept === "json" && file.type.includes("json")) {
      return null;
    }

    if (accept === "csv" && file.type.includes("csv")) {
      return null;
    }

    // https://github.com/react-dropzone/react-dropzone/issues/276
    // handle case where windows doesn't give you the correct mime type.
    if (
      accept === "csv" &&
      (!file.type || file.type.includes("application/vnd.ms-excel"))
    ) {
      if (!file.path) {
        // not much we can do so hope for the best.
        return null;
      }
      if (file.path.endsWith(".csv")) {
        return null;
      }
    }

    return {
      message: "Invalid file type",
      code: ErrorCode.FileInvalidType,
    };
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: undefined,
    onDrop,
    noDrag: true,
    multiple: false,
    maxSize: 1024 * 1024 * 75,
    validator,
    onError: (error) => {
      console.log("onError", error);
      alert("Something went wrong.");
    },
    onDropRejected: (files: FileRejection[]) => {
      const file = first(files);
      if (file) {
        if (file.errors.find((e) => e.code === "file-too-large")) {
          alert("File is too large. Max size is 35MB");
        }
        if (file.errors.find((e) => e.code === "file-invalid-type")) {
          alert(`Invalid file type. Only .${accept} files accepted`);
        }
      } else {
        alert("Invalid file.");
      }
    },
  });

  return (
    <div className="file-upload">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <div>{buttonText}</div>
      </div>
    </div>
  );
};

export default FileUpload;
