import React, { Component } from "react";
import "./Clock.scss";
import { Platform } from "types";

const DEFAULT_TIMER_LENGTH = 60000;
type ClockProps = {
  platform: Platform;
  style?: React.CSSProperties;
};

class Clock extends Component<ClockProps, { time: number }> {
  intervalId: NodeJS.Timer | undefined = undefined;
  constructor(props: ClockProps) {
    super(props);

    this.state = {
      time: this.getTimerLength(props),
    };
  }

  componentDidMount() {
    this.startClock();
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  getTimerLength = (props: ClockProps) => {
    return props.platform.clockTimerLength || DEFAULT_TIMER_LENGTH;
  };

  startClock = () => {
    this.intervalId = setInterval(this.decrementClock, 100);
  };

  decrementClock = () => {
    if (this.props.platform.clockStartTime) {
      const newTime =
        this.getTimerLength(this.props) -
        (Date.now() - this.props.platform.clockStartTime);
      if (newTime <= 0) {
        this.setState({ time: 0 });
      } else {
        this.setState({ time: newTime });
      }
    } else {
      this.setState({ time: this.getTimerLength(this.props) });
    }
  };

  formatTimer = (d: number) => {
    const ms = Math.floor((d % 1000) / 100);
    d /= 1000;
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);
    const hours = h > 0 ? h : "";
    let minutes: string | number = m;
    if (h > 0 && m < 10) {
      minutes = `0${minutes}`;
    }
    const seconds = s > 0 ? (s >= 10 ? s : "0" + s) : "00";

    if (h > 0) {
      return `${hours}:${minutes}:${seconds}`;
    }

    return `${minutes}:${seconds}.${ms}`;
  };

  render() {
    const { time } = this.state;
    const { platform } = this.props;

    const clockStyle = {
      fontSize: 20,
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 6,
      paddingBottom: 6,
      ...this.props.style,
    };

    const timeUnknown =
      platform.clockState === "started" && !platform.clockStartTime;

    return (
      <div style={clockStyle} className="clock">
        {timeUnknown && "-:--.-"}
        {!timeUnknown && this.formatTimer(time)}
      </div>
    );
  }
}

export default Clock;
