import React, { Component } from "react";
import Modal from "react-modal";

import CloseIcon from "icons/CloseIcon";
import "./Drawer.scss";

type DrawerProps = { trigger: React.ReactNode; children: React.ReactNode };

class Drawer extends Component<DrawerProps, { isOpen: boolean }> {
  constructor(props: DrawerProps) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  openMenu = (e: React.MouseEvent) => {
    this.setState({ isOpen: true });
    e.stopPropagation();
  };

  closeMenu = (e: React.MouseEvent) => {
    this.setState({ isOpen: false });
    if (e) {
      e.stopPropagation();
    }
  };

  render() {
    const { children, trigger } = this.props;

    return (
      <div className="drawer">
        <button onClick={this.openMenu}>{trigger}</button>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.isOpen}
          closeTimeoutMS={400}
          onRequestClose={this.closeMenu}
          className={{
            base: "drawer-modal",
            afterOpen: "drawer-modal-after-open",
            beforeClose: "drawer-modal-before-close",
          }}
          overlayClassName={{
            base: "drawer-modal-overlay",
            afterOpen: "drawer-modal-overlay-after-open",
            beforeClose: "drawer-modal-overlay-before-close",
          }}
          contentLabel="drawer"
        >
          <div className="close-button" onClick={this.closeMenu}>
            <CloseIcon />
          </div>
          <div className="content">{children}</div>
        </Modal>
      </div>
    );
  }
}

export default Drawer;
