import React from "react";
import Lights from "components/lights/Lights";
import { isLbsMeet, isKgMeet, getMeetUnits } from "util/meetHelper";
import { toKg, toLbs } from "util/conversions";
import {
  getDivisionDoc,
  getLifterAwardsWeightClassDoc,
} from "util/lifterHelper";
import { useCurrentAttempt } from "util/useCurrentAttempt";
import { usePlatform } from "util/usePlatform";
import { useCurrentLifter } from "util/useCurrentLifter";
import { useMeet } from "util/useMeet";
import get from "lodash/get";
import size from "lodash/size";
import capitalize from "lodash/capitalize";

const Platform1 = ({ platformId }: { platformId: string }) => {
  const meet = useMeet();
  const currentAttempt = useCurrentAttempt(platformId);
  const platform = usePlatform(platformId);
  const currentLifter = useCurrentLifter(platformId);
  if (!platform || !meet) {
    return null;
  }

  const lifterName = get(currentLifter, "name", "-------- ------------");
  const teamName = currentLifter
    ? get(currentLifter, "team", "")
    : "-----------";
  let liftName = get(currentAttempt, "liftName", "Lift");
  if (liftName === "bench" && meet.federation === "USSF") {
    liftName = "press";
  }
  const attemptNumber = get(currentAttempt, "attemptNumber", "0");
  const currentWeight = get(currentAttempt, "weight", 0);

  let divisionName = "---------------";
  let weightClassName = "---";

  if (currentLifter) {
    const firstLifterDivision = get(currentLifter, "divisions.0", null);
    if (firstLifterDivision) {
      const divisionDoc = getDivisionDoc(firstLifterDivision.divisionId, meet);
      if (firstLifterDivision.divisionId && divisionDoc) {
        divisionName = get(divisionDoc, "name", "---------------");
        // don't display weightclass name if there is only one for this division
        const weightClassDoc = getLifterAwardsWeightClassDoc(
          meet,
          currentLifter,
          firstLifterDivision
        );
        weightClassName =
          size(divisionDoc.weightClasses) > 1
            ? get(weightClassDoc, "name", "---")
            : "";
      }
    }
  }

  return (
    <div className="platform1">
      <div className="platform-name">{platform.name}</div>
      <Lights
        meet={meet}
        platformId={get(platform, "_id")}
        style={{ height: 50 }}
      />
      <div className="column">
        <div>{lifterName}</div>
        <div>{teamName}</div>
      </div>
      <div className="column">
        <div>
          {capitalize(liftName)}-{attemptNumber}: {currentWeight || "000"}{" "}
          {getMeetUnits(meet)}
          <span>
            {isLbsMeet(meet) && (
              <span> ({toKg(currentWeight).toFixed(2)} kg)</span>
            )}
            {isKgMeet(meet) && (
              <span> ({toLbs(currentWeight).toFixed(2)} lbs)</span>
            )}
          </span>
        </div>
        <div className="divisions">
          <div>
            {divisionName} {weightClassName}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Platform1;
