import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { getScoreBoardTableData } from "selectors";
import { ReduxState } from "types";

export const useScoreBoardTableData = () => {
  const { meetId, platformId } = useParams<{
    meetId: string;
    platformId: string;
  }>();

  const props = React.useMemo(() => {
    return { match: { params: { meetId, platformId } } };
  }, [meetId, platformId]);

  const tableData = useSelector((state: ReduxState) =>
    getScoreBoardTableData(state, props)
  );

  return tableData;
};
