import React from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import Select from "components/select/Select";
import DateInput from "components/dateInput/DateInput";
import { addMeet } from "util/pouchActions";
import {
  getUnitOptions,
  getFederationOptions,
  getDateFormatOptions,
} from "util/options";
import "./NewMeetModal.scss";
import isEmpty from "lodash/isEmpty";

type NewMeetModalState = {
  contactEmail: string;
  meetName: string;
  meetDate: string;
  units: string;
  federation: string;
  dateFormat: string;
  errors: Record<string, string>;
};

const getInitialState = (): NewMeetModalState => {
  return {
    contactEmail: "",
    meetName: "",
    meetDate: "",
    units: "KG",
    federation: "USAPL",
    dateFormat: "MM/DD/YYYY",
    errors: {},
  };
};

const NewMeetModal = ({
  onRequestClose,
  isOpen,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
}) => {
  const [state, setState] = React.useState<NewMeetModalState>(
    getInitialState()
  );

  const handleChange = (name: string, value: any) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const createMeet = () => {
    const errors: Record<string, string> = {};
    const { meetName, meetDate, contactEmail, units, federation, dateFormat } =
      state;

    if (!meetName) {
      errors.meetName = "Meet Name is required.";
    }

    if (!meetDate) {
      errors.meetDate = "Meet Date is required.";
    }

    if (!contactEmail) {
      errors.contactEmail = "Contact Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(contactEmail)) {
      errors.contactEmail = "Contact Email is not valid.";
    }

    if (isEmpty(errors)) {
      const meetId = addMeet({
        name: meetName,
        date: meetDate,
        contactEmail: contactEmail,
        units: units,
        federation: federation,
        dateFormat: dateFormat,
      });

      try {
        // @ts-expect-error
        if (typeof gtag !== "undefined") {
          // eslint-disable-next-line no-undef
          // @ts-expect-error
          gtag("event", "create_meet", {
            meetId,
            name: meetName,
            date: meetDate,
            contactEmail: contactEmail,
            units: units,
            federation: federation,
            dateFormat: dateFormat,
          });
        }
      } catch (e) {
        console.log(e);
      }

      setState(getInitialState());
      onRequestClose();
    } else {
      setState((prev) => ({ ...prev, errors }));
    }
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      contentLabel="New Meet"
      onRequestClose={onRequestClose}
      className={{
        base: "new-meet-modal",
        afterOpen: "new-meet-modal-after-open",
        beforeClose: "new-meet-modal-before-close",
      }}
      overlayClassName={{
        base: "new-meet-modal-overlay",
        afterOpen: "new-meet-modal-overlay-after-open",
        beforeClose: "new-meet-modal-overlay-before-close",
      }}
    >
      <div className="content">
        <div className="title">Create New Meet</div>
        <div className="info">
          <p>
            This will create a new meet stored locally in your browser. You can
            share your meet with others by uploading from the setup page.
          </p>
          <p>
            Please read the <Link to="/about">FAQ</Link> and{" "}
            <Link to="/instructions">instructions</Link>. This app is currently
            under development and things may have changed since you last used
            the app.
          </p>
        </div>
        <div className="row">
          <label>Meet Name *</label>
          <div className="input-wrapper">
            <input
              type="text"
              value={state.meetName}
              onChange={(e) => handleChange("meetName", e.target.value)}
            />
            <div className="error-message">{state.errors.meetName}</div>
          </div>
        </div>
        <div className="row">
          <label>Date Format *</label>
          <div className="input-wrapper">
            <Select
              name="dateFormat"
              value={state.dateFormat}
              options={getDateFormatOptions()}
              onChange={(value) => handleChange("dateFormat", value)}
              clearable={false}
            />
            <div className="error-message">{state.errors.dateFormat}</div>
          </div>
        </div>
        <div className="row">
          <label>Meet Date *</label>
          <div className="input-wrapper">
            <DateInput
              dateFormat={state.dateFormat}
              value={state.meetDate}
              onChange={(value) => handleChange("meetDate", value)}
              placeholder={state.dateFormat}
            />
            <div className="error-message">{state.errors.meetDate}</div>
          </div>
        </div>
        <div className="row">
          <label>Units *</label>
          <div className="input-wrapper">
            <Select
              name="units"
              value={state.units}
              options={getUnitOptions()}
              onChange={(value) => handleChange("units", value)}
              clearable={false}
            />
            <div className="error-message">{state.errors.units}</div>
          </div>
        </div>
        <div className="row">
          <label>Federation *</label>
          <div className="input-wrapper">
            <Select
              name="federation"
              value={state.federation}
              options={getFederationOptions()}
              onChange={(value) => handleChange("federation", value)}
              clearable={false}
            />
            <div className="error-message">{state.errors.federation}</div>
          </div>
        </div>
        <div className="row">
          <label>Contact Email *</label>
          <div className="input-wrapper">
            <input
              type="text"
              value={state.contactEmail}
              onChange={(e) => handleChange("contactEmail", e.target.value)}
            />
            <div className="error-message">{state.errors.contactEmail}</div>
          </div>
        </div>
      </div>
      <div className="button-row">
        <button onClick={onRequestClose} style={{ marginRight: 12 }}>
          Cancel
        </button>
        <button onClick={createMeet}>Create Meet</button>
      </div>
    </Modal>
  );
};

export default NewMeetModal;
