export const errorReporter = ({
  message,
  error,
}: {
  message?: string;
  error?: Error;
}) => {
  try {
    console.error(message, error);
    // @ts-ignore
    if (window.Rollbar) {
      // @ts-ignore
      window.Rollbar.error(message, error);
    }
  } catch (e) {
    console.error(e);
  }
};
