import each from "lodash/each";
import times from "lodash/times";
import maxBy from "lodash/maxBy";
import pullAt from "lodash/pullAt";
import filter from "lodash/filter";
import findIndex from "lodash/findIndex";
import get from "lodash/get";

import { Meet, Plate, Platform } from "types";

export const getEmptyPlatesKg = function () {
  return {
    plate_50: {
      pairsCount: 0,
      weight: 50,
      color: "#5CB85C",
    },
    plate_25: {
      pairsCount: 12,
      weight: 25,
      color: "#C25140",
    },
    plate_20: {
      pairsCount: 1,
      weight: 20,
      color: "#00008B",
    },
    plate_15: {
      pairsCount: 1,
      weight: 15,
      color: "#CCCC00",
    },
    plate_10: {
      pairsCount: 1,
      weight: 10,
      color: "#000000",
    },
    plate_5: {
      pairsCount: 1,
      weight: 5,
      color: "#000000",
    },
    plate_2_5: {
      pairsCount: 1,
      weight: 2.5,
      color: "#000000",
    },
    plate_2: {
      pairsCount: 0,
      weight: 2,
      color: "#000000",
    },
    plate_1_5: {
      pairsCount: 0,
      weight: 1.5,
      color: "#000000",
    },
    plate_1_25: {
      pairsCount: 1,
      weight: 1.25,
      color: "#000000",
    },
    plate_1: {
      pairsCount: 0,
      weight: 1,
      color: "#000000",
    },
    plate_0_5: {
      pairsCount: 2,
      weight: 0.5,
      color: "#000000",
    },
    plate_0_25: {
      pairsCount: 1,
      weight: 0.25,
      color: "#000000",
    },
  };
};

export const getEmptyPlatesLbs = function () {
  return {
    plate_100: {
      pairsCount: 0,
      weight: 100,
      color: "#5CB85C",
    },
    plate_55: {
      pairsCount: 0,
      weight: 55,
      color: "#C25140",
    },
    plate_45: {
      pairsCount: 12,
      weight: 45,
      color: "#00008B",
    },
    plate_35: {
      pairsCount: 1,
      weight: 35,
      color: "#CCCC00",
    },
    plate_25: {
      pairsCount: 1,
      weight: 25,
      color: "#5CB85C",
    },
    plate_10: {
      pairsCount: 2,
      weight: 10,
      color: "#FFFFFF",
    },
    plate_5: {
      pairsCount: 1,
      weight: 5,
      color: "#000000",
    },
    plate_2_5: {
      pairsCount: 1,
      weight: 2.5,
      color: "#000000",
    },
    plate_1_25: {
      pairsCount: 0,
      weight: 1.25,
      color: "#000000",
    },
    plate_0_5: {
      pairsCount: 0,
      weight: 0.5,
      color: "#000000",
    },
    plate_0_25: {
      pairsCount: 0,
      weight: 0.25,
      color: "#000000",
    },
  };
};

export const getAvailablePlates = function (plateConfig: Meet["plates"]) {
  const availablePlates: Plate[] = [];
  each(plateConfig, function (plate) {
    const platePairs = plate.pairsCount;
    times(platePairs, function () {
      availablePlates.push(plate);
    });
  });
  return availablePlates;
};

const calculatePlates = function (
  weight: number,
  availablePlates: Plate[]
): Plate[] {
  if (!weight) {
    return [];
  }

  // all remainingplates that are less than or equal to weight needed.
  const usablePlates = filter(availablePlates, (plate) => {
    return plate.weight <= weight;
  });

  const largestUsablePlate = maxBy(usablePlates, "weight");

  if (largestUsablePlate) {
    pullAt(
      usablePlates,
      findIndex(usablePlates, { weight: largestUsablePlate.weight })
    );
    const remainingWeight = weight - largestUsablePlate.weight;
    return [largestUsablePlate].concat(
      calculatePlates(remainingWeight, usablePlates)
    );
  } else {
    throw new Error("Cannot calculate correct combination of plates");
  }
};

export const getPlates = function (
  weight: number,
  availablePlates: Plate[],
  platform: Platform
) {
  const barAndCollarsWeight = Number(get(platform, "barAndCollarsWeight", 25));
  const halfRemainingWeight = (weight - barAndCollarsWeight) / 2;

  return calculatePlates(halfRemainingWeight, availablePlates);
};
