import React from "react";
import { Link } from "react-router-dom";
import { createSelector } from "reselect";

import {
  getTotal,
  getLiftersInDivisionAndWeightClass,
  sortLiftersByPlace,
} from "./lifterHelper";
import each from "lodash/each";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import concat from "lodash/concat";
import {
  Division,
  Lifter,
  Meet,
  ReactRouterWithRouterProps,
  ReduxState,
  WeightClass,
} from "types";
import { getCurrentMeet, getMeetLifters } from "selectors";
import { sortByLifts } from "./sortByLifts";

export const getDivisionResultsData = function (
  meet: Meet,
  lifters: Record<string, Lifter>,
  division: Division,
  weightClass: WeightClass,
  forecasted?: boolean
) {
  const dataArray = [];
  let place = 1;
  dataArray.push({
    row: "title",
    name: (
      <span>
        <Link
          to={`/meets/${get(meet, "_id")}/division/${get(
            division,
            "_id"
          )}/weightClass/${get(weightClass, "_id")}`}
        >
          {division.name}
          <br />
          {weightClass.name}
        </Link>
      </span>
    ),
    weightClass,
    division,
    forecasted,
  });

  const divisionLifters = getLiftersInDivisionAndWeightClass({
    meet,
    division,
    weightClass,
  });

  if (isEmpty(divisionLifters)) {
    return [];
  }

  const sortedLifters = sortLiftersByPlace({
    lifters: divisionLifters,
    meet,
    division,
    forecasted,
  });

  each(sortedLifters, (lifter) => {
    // lifters without a total do not get a place
    const total = getTotal(lifter, division._id, meet, forecasted);
    const displayPlace = total ? place : "";

    dataArray.push({
      ...lifter,
      division,
      weightClass,
      place: displayPlace,
      forecasted,
      lifterId: lifter._id,
    });
    place += 1;
  });
  dataArray.push(null);

  return dataArray;
};

const getResultsTableDivisions = (
  state: ReduxState,
  props: ReactRouterWithRouterProps
) => get(props, "divisions");
const getResultsTableForecasted = (
  state: ReduxState,
  props: ReactRouterWithRouterProps
) => get(props, "forecasted", false);

export const getResultsTableData = createSelector(
  [
    getMeetLifters,
    getResultsTableDivisions,
    getCurrentMeet,
    getResultsTableForecasted,
  ],
  (lifters, divisions, meet, forecasted) => {
    let dataArray: any = [{ row: "header", forecasted }];

    const sortedDivisions = sortBy(divisions, [
      "gender",
      "rawOrEquipped",
      sortByLifts,
      "name",
    ]);
    each(sortedDivisions, (division) => {
      const sortedWeightClasses = sortBy(division.weightClasses, "maxWeight");
      each(sortedWeightClasses, (weightClass) => {
        const divisionResults = getDivisionResultsData(
          meet as Meet,
          lifters,
          division,
          weightClass,
          forecasted
        );
        dataArray = concat(dataArray, divisionResults);
      });
    });

    return dataArray;
  }
);
