import get from "lodash/get";
import toString from "lodash/toString";
import toNumber from "lodash/toNumber";
import {
  PUT_MEET,
  REMOVE_MEET,
  PUT_RESTRICTED_MEET,
  PUT_ENTRY_CONFIG,
  PUT_PLATFORM,
  REMOVE_PLATFORM,
  SET_CURRENT_MEET_ID,
  PUT_LIFTER,
  PUT_RESTRICTED_LIFTER,
  REMOVE_LIFTER,
  PUT_ATTEMPT,
  START_ATTEMPT_TIMER,
  PUT_DIVISION,
  REMOVE_DIVISION,
  PUT_WEIGHT_CLASS,
  REMOVE_WEIGHT_CLASS,
  PUT_REF,
  MEET_IS_SYNCING,
  MEET_IS_NOT_SYNCING,
  MEET_IS_ACTIVELY_SYNCING,
  MEET_IS_NOT_ACTIVELY_SYNCING,
  MEET_IS_LOADING,
  MEET_IS_NOT_LOADING,
  MEET_IS_LOGGED_IN,
  MEET_IS_LOGGED_OUT,
  MEET_IS_LOCAL,
  MEET_IS_ONLINE,
  SET_SCREEN_SIZE,
  MEET_NOT_FOUND,
  PUT_RECORDS,
  UPDATE_CALCULATED_DATA,
} from "./actionTypes";
import { Attempt } from "types";

export const putMeet = ({
  meet,
  isInitialLoad,
}: {
  meet: any;
  isInitialLoad: boolean;
}) => {
  return { type: PUT_MEET, meet, isInitialLoad };
};

export const removeMeet = (meetId: string) => {
  return { type: REMOVE_MEET, meetId };
};

export const putRestrictedMeet = ({
  restrictedMeet,
  meetId,
  isInitialLoad,
}: {
  restrictedMeet: any;
  meetId: string;
  isInitialLoad: boolean;
}) => {
  return { type: PUT_RESTRICTED_MEET, restrictedMeet, meetId, isInitialLoad };
};

export const putEntryConfig = ({
  entryConfig,
  meetId,
  isInitialLoad,
}: {
  entryConfig: any;
  meetId: string;
  isInitialLoad: boolean;
}) => {
  return { type: PUT_ENTRY_CONFIG, entryConfig, meetId, isInitialLoad };
};

export const putRecords = ({
  records,
  meetId,
  isInitialLoad,
}: {
  records: any;
  meetId: string;
  isInitialLoad: boolean;
}) => {
  return { type: PUT_RECORDS, records, meetId, isInitialLoad };
};

export const updateCalculatedData = ({ meetId }: { meetId: string }) => {
  return { type: UPDATE_CALCULATED_DATA, meetId };
};

export const putPlatform = ({
  platform,
  meetId,
  isInitialLoad,
}: {
  platform: any;
  meetId: string;
  isInitialLoad: boolean;
}) => {
  // if this attempt is marked and the currentstore version is not then dispatch attempt timer action
  return (dispatch: any, getState: any) => {
    const state = getState();
    const oldPlatform = get(state, [
      "meets",
      meetId,
      "platforms",
      platform._id,
    ]);
    const oldClockState = get(oldPlatform, "clockState");
    const newClockState = get(platform, "clockState");

    if (
      oldPlatform &&
      oldClockState !== "started" &&
      newClockState === "started"
    ) {
      platform.clockStartTime = Date.now();
    } else if (oldClockState !== "initial" && newClockState === "initial") {
      platform.clockStartTime = null;
    } else if (oldClockState && newClockState) {
      platform.clockStartTime = oldPlatform.clockStartTime;
    }
    dispatch({ type: PUT_PLATFORM, platform, meetId, isInitialLoad });
  };
};

export const removePlatform = ({
  platform,
  meetId,
  isInitialLoad,
}: {
  platform: any;
  meetId: string;
  isInitialLoad: boolean;
}) => {
  return { type: REMOVE_PLATFORM, platform, meetId, isInitialLoad };
};

export const setCurrentMeetId = (meetId: string) => {
  return { type: SET_CURRENT_MEET_ID, meetId };
};

export const putLifter = ({
  lifter,
  meetId,
  isInitialLoad,
}: {
  lifter: any;
  meetId: string;
  isInitialLoad: boolean;
}) => {
  return { type: PUT_LIFTER, lifter, meetId, isInitialLoad };
};

export const removeLifter = ({
  lifter,
  meetId,
  isInitialLoad,
}: {
  lifter: any;
  meetId: string;
  isInitialLoad: boolean;
}) => {
  return { type: REMOVE_LIFTER, lifter, meetId, isInitialLoad };
};

export const putRestrictedLifter = ({
  restrictedLifter,
  lifterId,
  meetId,
  isInitialLoad,
}: {
  restrictedLifter: any;
  lifterId: string;
  meetId: string;
  isInitialLoad: boolean;
}) => {
  return {
    type: PUT_RESTRICTED_LIFTER,
    restrictedLifter,
    lifterId,
    meetId,
    isInitialLoad,
  };
};

export const putAttempt = ({
  attempt,
  lifterId,
  meetId,
  isInitialLoad,
}: {
  attempt: any;
  lifterId: string;
  meetId: string;
  isInitialLoad: boolean;
}) => {
  // if this attempt is marked and the currentstore version is not then dispatch attempt timer action
  return (dispatch: any, getState: any) => {
    const state = getState();
    const currentStateOfAttempt = get(state, [
      "meets",
      meetId,
      "lifters",
      lifterId,
      "lifts",
      attempt.liftName,
      attempt.attemptNumber,
    ]) as unknown as Attempt;

    dispatch({ type: PUT_ATTEMPT, attempt, lifterId, meetId, isInitialLoad });

    // start the input clock on this lifters next attempt
    if (
      (attempt.attemptNumber === "1" || attempt.attemptNumber === "2") &&
      currentStateOfAttempt &&
      !currentStateOfAttempt.result &&
      attempt.result
    ) {
      const nextAttemptNumber = toString(toNumber(attempt.attemptNumber) + 1);
      dispatch({
        type: START_ATTEMPT_TIMER,
        startTime: Date.now(),
        liftName: attempt.liftName,
        attemptNumber: nextAttemptNumber,
        lifterId,
        meetId,
        isInitialLoad,
      });
    }
  };
};

export const putDivision = ({
  division,
  meetId,
  isInitialLoad,
}: {
  division: any;
  meetId: string;
  isInitialLoad: boolean;
}) => {
  return { type: PUT_DIVISION, division, meetId, isInitialLoad };
};

export const removeDivision = ({
  division,
  meetId,
  isInitialLoad,
}: {
  division: any;
  meetId: string;
  isInitialLoad: boolean;
}) => {
  return { type: REMOVE_DIVISION, division, meetId, isInitialLoad };
};

export const putWeightClass = ({
  weightClass,
  divisionId,
  meetId,
  isInitialLoad,
}: {
  weightClass: any;
  divisionId: string;
  meetId: string;
  isInitialLoad: boolean;
}) => {
  return {
    type: PUT_WEIGHT_CLASS,
    weightClass,
    divisionId,
    meetId,
    isInitialLoad,
  };
};

export const removeWeightClass = ({
  weightClass,
  divisionId,
  meetId,
  isInitialLoad,
}: {
  weightClass: any;
  divisionId: string;
  meetId: any;
  isInitialLoad: boolean;
}) => {
  return {
    type: REMOVE_WEIGHT_CLASS,
    weightClass,
    divisionId,
    meetId,
    isInitialLoad,
  };
};

export const putRef = ({
  ref,
  platformId,
  meetId,
  isInitialLoad,
}: {
  ref: any;
  platformId: string;
  meetId: string;
  isInitialLoad: boolean;
}) => {
  return { type: PUT_REF, ref, platformId, meetId, isInitialLoad };
};

export const meetIsSyncing = (meetId: string) => {
  return { type: MEET_IS_SYNCING, meetId };
};

export const meetIsNotSyncing = (meetId: string) => {
  return { type: MEET_IS_NOT_SYNCING, meetId };
};

export const meetIsActivelySyncing = (meetId: string) => {
  return { type: MEET_IS_ACTIVELY_SYNCING, meetId };
};

export const meetIsNotActivelySyncing = (meetId: string) => {
  return { type: MEET_IS_NOT_ACTIVELY_SYNCING, meetId };
};

export const startLoadingMeet = (meetId: string) => {
  return { type: MEET_IS_LOADING, meetId };
};

export const doneLoadingMeet = (meetId: string) => {
  return { type: MEET_IS_NOT_LOADING, meetId };
};

export const loggedInToMeet = (meetId: string) => {
  return { type: MEET_IS_LOGGED_IN, meetId };
};

export const loggedOutOfMeet = (meetId: string) => {
  return { type: MEET_IS_LOGGED_OUT, meetId };
};

export const meetIsLocal = (meetId: string) => {
  return { type: MEET_IS_LOCAL, meetId };
};

export const meetIsOnline = (meetId: string) => {
  return { type: MEET_IS_ONLINE, meetId };
};

export const meetNotFound = (meetId: string) => {
  return { type: MEET_NOT_FOUND, meetId };
};

export const setScreenSize = (screenWidth: number) => {
  return { type: SET_SCREEN_SIZE, screenWidth };
};
