import React from "react";
import Select from "components/select/Select";
import FixedHeightWrapper from "app/meets/FixedHeightWrapper";
import LiftersTable from "./LiftersTable";
import FiltersIcon from "icons/FiltersIcon";
import Drawer from "components/drawer/Drawer";
import GenerateLotNumbersModal from "./GenerateLotNumbersModal";
import { exportLifters } from "util/exportData";
import {
  getTeamOptions,
  getPlatformOptions,
  getSessionOptions,
  getFlightOptions,
  getAwardDivisionOptions,
  getGenderOptions,
  getRawOrEquippedOptions,
} from "util/options";
import "./LiftersIndex.scss";
import { useMediaSize } from "util/useMediaSize";
import { useMeet } from "util/useMeet";
import filter from "lodash/filter";
import toLower from "lodash/toLower";
import includes from "lodash/includes";
import some from "lodash/some";
import each from "lodash/each";
import sortBy from "lodash/sortBy";
import { getLifterAwardsWeightClassDoc } from "util/lifterHelper";
import { WeightClass } from "types";
import BulkEditModal from "./BulkEditModal";
import EditIcon from "icons/EditIcon";
import FileIcon from "icons/FileIcon";
import RandomIcon from "icons/RandomIcon";

const initialFilters: {
  name: string;
  team: string | null;
  gender: string | null;
  platformId: string | null;
  session: number | null;
  flight: string | null;
  rawOrEquipped: string | null;
  divisionId: string | null;
  weightClass: string | null;
} = {
  name: "",
  team: null,
  gender: null,
  platformId: null,
  session: null,
  flight: null,
  rawOrEquipped: null,
  divisionId: null,
  weightClass: null,
};

const LiftersIndex = ({
  onFinishBulkCreate,
}: {
  onFinishBulkCreate: () => void;
}) => {
  const media = useMediaSize();
  const meet = useMeet();
  const [showGenerateLotNumbersModal, setShowGenerateLotNumbersModal] =
    React.useState(false);
  const [showBulkEditModal, setShowBulkEditModal] = React.useState(false);
  const [filters, setFilters] = React.useState(initialFilters);

  const getAllWeightClasses = React.useCallback(() => {
    const weightClasseOptions: {
      value: string | undefined;
      label: string | undefined;
    }[] = [];
    each(meet.divisions, (division) => {
      each(division.weightClasses, (weightClass) => {
        const option = { value: weightClass.name, label: weightClass.name };
        if (
          weightClass.name &&
          !weightClasseOptions.find((o) => o.value === option.value)
        ) {
          weightClasseOptions.push(option);
        }
      });
    });
    return sortBy(weightClasseOptions, "name");
  }, [meet.divisions]);

  const openGenerateLotNumbersModal = () => {
    setShowGenerateLotNumbersModal(true);
  };

  const closeGenerateLotNumbersModal = () => {
    setShowGenerateLotNumbersModal(false);
  };

  const openBulkEditModal = () => {
    setShowBulkEditModal(true);
  };

  const closeBulkEditModal = () => {
    setShowBulkEditModal(false);
  };

  const clearFilters = () => {
    setFilters(initialFilters);
  };

  const onChangeNameFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters((prev) => ({ ...prev, name: e.target.value }));
  };

  const onChangeSelectFilter = (
    name: keyof typeof initialFilters,
    value: string | number | undefined | null
  ) => {
    setFilters({ ...filters, [name]: value });
  };

  const filteredLifters = filter(meet.lifters, (l) => {
    const nameSearchMatch = includes(toLower(l.name), toLower(filters.name));
    const teamMatch = !filters.team || l.team === filters.team;
    const platformIdMatch =
      !filters.platformId || l.platformId === filters.platformId;
    const sessionMatch = !filters.session || l.session === filters.session;
    const flightMatch = !filters.flight || l.flight === filters.flight;
    const genderMatch = !filters.gender || l.gender === filters.gender;
    const rawOrEquippedMatch =
      !filters.rawOrEquipped ||
      some(l.divisions, (d) => d.rawOrEquipped === filters.rawOrEquipped);
    const divisionIdMatch =
      !filters.divisionId ||
      some(l.divisions, (d) => d.divisionId === filters.divisionId);
    const weightClassMatch =
      !filters.weightClass ||
      some(l.divisions, (d) => {
        const weightClassDoc = getLifterAwardsWeightClassDoc(
          meet,
          l,
          d
        ) as WeightClass;
        return (
          weightClassDoc &&
          weightClassDoc.name &&
          weightClassDoc.name === filters.weightClass
        );
      });

    // don't show lifters without an id. Either data hasn't fully loaded or something went wrong.
    const validId = l._id;

    return !!(
      validId &&
      nameSearchMatch &&
      teamMatch &&
      platformIdMatch &&
      sessionMatch &&
      flightMatch &&
      genderMatch &&
      rawOrEquippedMatch &&
      divisionIdMatch &&
      weightClassMatch
    );
  });

  const activeFilters = !!(
    filters.name ||
    filters.team ||
    filters.platformId ||
    filters.session ||
    filters.flight ||
    filters.gender ||
    filters.rawOrEquipped ||
    filters.divisionId ||
    filters.weightClass
  );

  return (
    <FixedHeightWrapper>
      <div className="lifters-index">
        <div className="util-row-wrapper">
          <div className="util-row">
            <button onClick={() => exportLifters(meet, meet.lifters)}>
              <FileIcon /> &nbsp; Export Lifters
            </button>
            <button
              className="generate-lot-numbers-button"
              onClick={openGenerateLotNumbersModal}
            >
              <RandomIcon /> &nbsp; Generate Lots
            </button>
            <Drawer
              trigger={
                <>
                  <FiltersIcon active={activeFilters} />
                  &nbsp; Filter
                </>
              }
            >
              <div className="title">Lifter Filters</div>
              <button className="clear-button" onClick={clearFilters}>
                Clear All
              </button>
              <div className="row">
                Name:
                <input
                  type="text"
                  onChange={onChangeNameFilter}
                  value={filters.name}
                />
              </div>
              <div className="row">
                Team:
                <Select
                  name="team"
                  value={filters.team}
                  options={getTeamOptions({}, meet)}
                  onChange={(value) => onChangeSelectFilter("team", value)}
                />
              </div>
              <div className="row">
                Platform:
                <Select
                  name="platformId"
                  value={filters.platformId}
                  options={getPlatformOptions({}, meet)}
                  onChange={(value) =>
                    onChangeSelectFilter("platformId", value)
                  }
                />
              </div>
              <div className="row">
                Session:
                <Select
                  name="session"
                  value={filters.session}
                  options={getSessionOptions()}
                  onChange={(value) => onChangeSelectFilter("session", value)}
                />
              </div>
              <div className="row">
                Flight:
                <Select
                  name="flight"
                  value={filters.flight}
                  options={getFlightOptions()}
                  onChange={(value) => onChangeSelectFilter("flight", value)}
                />
              </div>
              <div className="row">
                Gender:
                <Select
                  name="gender"
                  value={filters.gender}
                  options={getGenderOptions({}, meet)}
                  onChange={(value) => onChangeSelectFilter("gender", value)}
                />
              </div>
              <div className="row">
                Equipment Level:
                <Select
                  name="rawOrEquipped"
                  value={filters.rawOrEquipped}
                  options={getRawOrEquippedOptions({}, meet)}
                  onChange={(value) =>
                    onChangeSelectFilter("rawOrEquipped", value)
                  }
                />
              </div>
              <div className="row">
                Awards Division:
                <Select
                  name="divisionId"
                  value={filters.divisionId}
                  options={getAwardDivisionOptions({}, meet, undefined)}
                  onChange={(value) =>
                    onChangeSelectFilter("divisionId", value)
                  }
                />
              </div>
              <div className="row">
                Weight Class:
                <Select
                  name="weightClass"
                  value={filters.weightClass}
                  options={getAllWeightClasses()}
                  onChange={(value) =>
                    onChangeSelectFilter("weightClass", value)
                  }
                />
              </div>
            </Drawer>
            <button
              className="generate-lot-numbers-button"
              onClick={openBulkEditModal}
            >
              <span style={{ marginTop: -1 }}>
                <EditIcon />
              </span>
              &nbsp; Bulk Edit
            </button>
          </div>
        </div>
        <div className="table-wrapper">
          <LiftersTable meet={meet} lifters={filteredLifters} media={media} />
        </div>
        <GenerateLotNumbersModal
          meet={meet}
          isOpen={showGenerateLotNumbersModal}
          onRequestClose={closeGenerateLotNumbersModal}
          onFinishBulkCreate={onFinishBulkCreate}
        />
        <BulkEditModal
          meet={meet}
          isOpen={showBulkEditModal}
          onRequestClose={closeBulkEditModal}
          filteredLifters={filteredLifters}
          activeFilters={activeFilters}
        />
      </div>
    </FixedHeightWrapper>
  );
};

export default LiftersIndex;
