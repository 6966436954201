import React from "react";
import SortableHeaderCell from "components/SortableHeaderCell";
import NotEqualIcon from "icons/NotEqualIcon";
import { Column } from "components/table/Table";
import get from "lodash/get";

const DefaultCell = ({
  data,
  column,
  style,
}: {
  data: any;
  column: Column;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return <SortableHeaderCell style={style} column={column} />;
  } else {
    const match = get(lifter, ["match", column.key]);
    return (
      <div style={style} className="table-cell-inner ellipsis">
        {get(lifter, column.key)}
        {match === false && (
          <div className="mismatch-icon-wrapper">
            <NotEqualIcon />
          </div>
        )}
      </div>
    );
  }
};

export default DefaultCell;
