import get from "lodash/get";
import map from "lodash/map";
import React from "react";
import { Lifter, Meet } from "types";
import {
  getDivisionDoc,
  getLifterAwardsWeightClassDoc,
  getRealAge,
} from "util/lifterHelper";
import { getBenchLabel } from "util/meetHelper";

const CardVersion2 = ({
  meet,
  lifter,
  index,
}: {
  meet: Meet;
  lifter: Lifter;
  index: number;
}) => {
  const isEven = index % 2 === 0;

  return (
    <div className={`card ${isEven ? "even" : ""}`}>
      <div className="title">{meet.name}</div>
      <div className="rack-heights">
        <div>
          <label className="rack-height-label">Squat Rack Height:</label>
          <div className="rack-height field">{lifter.squatRackHeight}</div>
        </div>
        <div>
          <label className="rack-height-label">Squat Rack In / Out:</label>
          <div className="rack-height field"></div>
        </div>
        <div>
          <label className="rack-height-label">
            {getBenchLabel(meet)} Rack Height:
          </label>
          <div className="rack-height field">{lifter.benchRackHeight}</div>
        </div>
        {meet.federation !== "USSF" && (
          <div>
            <label className="rack-height-label">Bench Safety Height:</label>
            <div className="rack-height field"></div>
          </div>
        )}
        {meet.federation !== "USSF" && (
          <div>
            <label className="rack-height-label">Foot Blocks:</label>
            <div className="rack-height field"></div>
          </div>
        )}
      </div>
      <div className="row">
        <span>
          <label className="name-label">Name:</label>
          <div className="name field">{lifter.name}</div>
        </span>
        <span>
          <label className="lot-label">Lot #:</label>
          <div className="lot field">{lifter.lot}</div>
        </span>
      </div>
      <div className="row">
        <span>
          <label className="platform-label">Platform:</label>
          <div className="platform field">
            {lifter.platformId
              ? get(meet, ["platforms", lifter.platformId, "name"])
              : ""}
          </div>
        </span>
        <span>
          <label className="session-label">Session:</label>
          <div className="session field">{lifter.session}</div>
        </span>
        <span>
          <label className="flight-label">Flight:</label>
          <div className="flight field">{lifter.flight}</div>
        </span>
      </div>

      <div className="row">
        <span>
          <label className="age-label">Age:</label>
          <div className="age field">{getRealAge(lifter, meet)}</div>
        </span>
        <span>
          <label className="birth-date-label">Birth Date:</label>
          <div className="birth-date field">{lifter.birthDate}</div>
        </span>
        <span>
          <label className="body-weight-label">Body Weight:</label>
          <div className="body-weight field">{lifter.bodyWeight}</div>
        </span>
      </div>
      {map(lifter.divisions, (lifterDivision, index) => {
        const division = getDivisionDoc(lifterDivision.divisionId, meet);
        const weightClassDoc = getLifterAwardsWeightClassDoc(
          meet,
          lifter,
          lifterDivision
        );
        return (
          <div className="row" key={`${lifterDivision.divisionId}-${index}`}>
            <span>
              <label className="division-label">Division:</label>
              <div className="division field">{get(division, "name")}</div>
            </span>
            <span>
              <label className="weight-class-label">Weight Class:</label>
              <div className="weight-class field">
                {get(weightClassDoc, "name")}
              </div>
            </span>
          </div>
        );
      })}

      <div>
        <span>
          <label className="lift-label"></label>
          <label className="attempt-label">Attempt 1</label>
          <label className="attempt-label">Attempt 2</label>
          <label className="attempt-label">Attempt 3</label>
          <label className="attempt-label">Best</label>
        </span>
      </div>

      <div>
        <span>
          <label className="lift-label">Squat:</label>
          <div className="attempt field">
            {get(lifter, "lifts.squat.1.weight")} {meet.units}
          </div>
          <div className="attempt field">
            {get(lifter, "lifts.squat.2.weight")} {meet.units}
          </div>
          <div className="attempt field">
            {get(lifter, "lifts.squat.3.weight")} {meet.units}
          </div>
          <div className="attempt field">{meet.units}</div>
        </span>
      </div>
      <div>
        <span>
          <label className="lift-label">{getBenchLabel(meet)}:</label>
          <div className="attempt field">
            {get(lifter, "lifts.bench.1.weight")} {meet.units}
          </div>
          <div className="attempt field">
            {get(lifter, "lifts.bench.2.weight")} {meet.units}
          </div>
          <div className="attempt field">
            {get(lifter, "lifts.bench.3.weight")} {meet.units}
          </div>
          <div className="attempt field">{meet.units}</div>
        </span>
      </div>
      <div>
        <span>
          <label className="lift-label">Deadlift:</label>
          <div className="attempt field">
            {get(lifter, "lifts.dead.1.weight")} {meet.units}
          </div>
          <div className="attempt field">
            {get(lifter, "lifts.dead.2.weight")} {meet.units}
          </div>
          <div className="attempt field">
            {get(lifter, "lifts.dead.3.weight")} {meet.units}
          </div>
          <div className="attempt field">{meet.units}</div>
        </span>
      </div>
      <div>
        <span>
          <label className="total-label">Total:</label>
          <div className="attempt field">{meet.units}</div>
        </span>
      </div>
    </div>
  );
};

export default CardVersion2;
