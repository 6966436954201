import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { getRecordAttempts } from "selectors";
import { ReduxState } from "types";

export const useRecordAttempts = () => {
  const { meetId } = useParams<{ meetId: string }>();

  const props = React.useMemo(() => {
    return { match: { params: { meetId } } };
  }, [meetId]);

  const allRecords = useSelector((state: ReduxState) =>
    getRecordAttempts(state, props)
  );

  return allRecords;
};
