import { Column } from "components/table/Table";
import find from "lodash/find";
import get from "lodash/get";
import map from "lodash/map";
import size from "lodash/size";
import React from "react";
import { Meet } from "types";

const DivisionCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: Column;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    const meetItems = meet.entryConfig.items;
    return (
      <div
        style={{ ...style, whiteSpace: "normal" }}
        className="table-cell-inner"
      >
        {map(lifter.restricted.items, (itemId, index: number) => {
          let item;
          let itemName: string | undefined = "";
          if (typeof itemId === "string") {
            item = find(meetItems, { id: itemId });
            itemName = get(item, "name");
            if (!itemName) {
              itemName = "Not Found";
            }
          } else {
            // new object style
            if (get(itemId, "name")) {
              // take directly from object name
              itemName = get(itemId, "name");
            } else {
              // look up based on id
              item = find(meetItems, { id: get(itemId, "id") });
              itemName = get(item, "name");
            }
          }

          return (
            <span key={index}>
              {itemName}
              {size(lifter.restricted.items) !== index + 1 && ", "}
            </span>
          );
        })}
      </div>
    );
  }
};

export default DivisionCell;
