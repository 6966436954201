import React, { Component } from "react";
import { Link } from "react-router-dom";

import FixedHeightWrapper from "app/meets/FixedHeightWrapper";

class NotFound extends Component {
  render() {
    return (
      <FixedHeightWrapper>
        <div className="not-found">
          This meet was not found.&nbsp;<Link to="/">Go Home</Link>
        </div>
      </FixedHeightWrapper>
    );
  }
}

export default NotFound;
