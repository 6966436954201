import React from "react";
import { Route, Switch } from "react-router-dom";
import ReduxToastr, { toastr } from "react-redux-toastr";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import Meets from "app/meets/Meets";
import Meet from "app/meets/Meet";
import About from "app/about/About";
import Instructions from "app/instructions/Instructions";
import Coach from "app/coach/Coach";
import Contact from "app/contact/Contact";
import RouteNotFound from "app/RouteNotFound";
import { loadMeetsOnStartup } from "util/pouchAdapter";
import ErrorBoundary from "components/ErrorBoundary";
import { useResetScroll } from "util/useResetScroll";
import { useResize } from "util/useResize";
import { useWarnOnOldBrowsers } from "util/useWarnOnOldBrowsers";
import { useMediaSize } from "util/useMediaSize";

// @ts-ignore
window.reloadRequired = function () {
  toastr.info(
    "A new version of LiftingCast is available. Please reload the page.",
    "",
    { timeOut: 300000 }
  );
};

const App = () => {
  useResetScroll();
  useResize();
  useWarnOnOldBrowsers();
  const media = useMediaSize();

  React.useEffect(() => {
    loadMeetsOnStartup();
  }, []);

  return (
    <div className={classNames("app", media)} id="app">
      <ErrorBoundary>
        <Helmet>
          <title>LiftingCast</title>
        </Helmet>
        <Switch>
          <Route path="/" exact component={Meets} />
          <Route path="/index.html" component={Meets} />
          <Route path="/about" exact component={About} />
          <Route path="/instructions" exact component={Instructions} />
          <Route path="/coach" exact component={Coach} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/meets/" exact component={Meets} />
          <Route path="/meets/:meetId/platforms/:platformId" component={Meet} />
          <Route path="/meets/:meetId" component={Meet} />
          <Route component={RouteNotFound} />
        </Switch>
        <ReduxToastr />
      </ErrorBoundary>
    </div>
  );
};

export default App;
