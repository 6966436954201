import map from "lodash/map";
import React from "react";
import { Meet } from "types";
import { getDivisionDoc } from "util/lifterHelper";

const DivisionCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: any;
  meet: Meet;
  style: React.CSSProperties & { fontSize: number };
}) => {
  const lifter = data.lifter;
  if (data.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else if (data.row === "title") {
    return <div style={style} className="table-cell-inner" />;
  } else {
    if (lifter.divisions.length < 2) {
      style = { ...style, fontSize: style.fontSize * 1.3 };
    }
    return (
      <div style={style} className="table-cell-inner">
        {map(lifter.divisions, (lifterDivision, index: number) => {
          let rowStyle: React.CSSProperties = {
            paddingBottom: style.padding,
          };

          if (!lifterDivision.divisionId) {
            return null;
          }

          const division = getDivisionDoc(lifterDivision.divisionId, meet);
          if (!division) {
            return null;
          }

          if (division.hideOnBoard) {
            return null;
          }

          if (index !== 0) {
            rowStyle = {
              ...rowStyle,
              paddingTop: style.padding,
            };
          }

          return (
            <div key={index} style={rowStyle} className="ellipsis cell-row">
              {division.name}
            </div>
          );
        })}
      </div>
    );
  }
};

export default DivisionCell;
