import React from "react";
import round from "lodash/round";
import { wilksCoef } from "util/coefficients";
import { Meet } from "types";

const WilksCoefCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: any;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    const wilksCoefNumber = wilksCoef(lifter, meet);
    return (
      <div style={style} className="table-cell-inner">
        {!!wilksCoefNumber && round(wilksCoefNumber, 4)}
      </div>
    );
  }
};

export default WilksCoefCell;
