import classNames from "classnames";
import React from "react";

import "./Select.scss";

type SelectValue = string | number | undefined | null;

type SelectProps = {
  name: string;
  options: {
    label: string | undefined | null;
    value: SelectValue;
    disabled?: boolean;
  }[];
  value: any;
  onChange: (value: SelectValue) => void;
  disabled?: boolean;
  allowCreate?: boolean;
  allowCreateOptionLabel?: string;
  allowCreatePrompt?: string;
  allowCreateTransform?: (newValue: string) => string | number;
  clearable?: boolean;
  className?: string;
  blankValueLabel?: string;
};

const Select = ({
  name,
  options,
  value,
  onChange,
  disabled,
  allowCreate,
  allowCreateOptionLabel,
  allowCreatePrompt,
  allowCreateTransform,
  clearable = true,
  className,
  blankValueLabel = "",
}: SelectProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let newValue: SelectValue = event.target.value;
    if (newValue === "CREATE_NEW") {
      newValue =
        window.prompt(allowCreatePrompt || `Enter a new ${name ?? ""}`, "") ??
        "";
      if (!newValue) {
        return;
      }
      if (allowCreateTransform) {
        newValue = allowCreateTransform(newValue);
      }
    } else {
      const matchingOption = options.find(
        (o) => newValue === o.value || Number(newValue) === o.value
      );
      newValue = matchingOption?.value ?? "";
    }

    onChange(newValue);
  };

  const includeBlankOption = clearable || !value;

  return (
    <select
      value={value ?? ""}
      onChange={handleChange}
      className={classNames("custom-select", className)}
      disabled={disabled}
    >
      {includeBlankOption && <option value="">{blankValueLabel}</option>}
      {allowCreate && (
        <option value="CREATE_NEW">
          {allowCreateOptionLabel || "CREATE NEW"}
        </option>
      )}
      {options.map((option) => {
        return (
          <option
            key={option.value}
            value={option.value ?? ""}
            disabled={!!option.disabled}
          >
            {option.label ?? "EMPTY"}
          </option>
        );
      })}
    </select>
  );
};

export default Select;
