import React from "react";
import CardVersion1 from "./CardVersion1";
import "./CardsIndex.scss";
import { useMeet } from "util/useMeet";
import sortBy from "lodash/sortBy";
import each from "lodash/each";
import map from "lodash/map";
import toArray from "lodash/toArray";
import { Lifter, LifterAttempts } from "types";

const mockLift: LifterAttempts = {
  "1": { _id: "1" },
  "2": { _id: "2" },
  "3": { _id: "3" },
};
const mockLifts = {
  squat: mockLift,
  bench: mockLift,
  dead: mockLift,
};

const CardsVersion1 = () => {
  const meet = useMeet();

  const lifters: Lifter[] = sortBy(meet.lifters, ["lot"]);
  lifters.push({ _id: "1", divisions: [], lifts: mockLifts });
  lifters.push({ _id: "2", divisions: [], lifts: mockLifts });

  let liftersByFour: any = {};
  each(lifters, (element, index: number) => {
    if (!liftersByFour[Math.floor(index / 4)]) {
      liftersByFour[Math.floor(index / 4)] = [];
    }
    liftersByFour[Math.floor(index / 4)].push(element);
  });

  liftersByFour = toArray(liftersByFour);

  return (
    <div className="cards-version1">
      <div className="print-note">Must be printed in landscape</div>
      {map(liftersByFour, (page, index) => {
        return (
          <div key={index} className="card-page">
            <CardVersion1 meet={meet} lifter={page[0]} />
            <CardVersion1 meet={meet} lifter={page[1]} />
            <CardVersion1 meet={meet} lifter={page[2]} />
            <CardVersion1 meet={meet} lifter={page[3]} />
          </div>
        );
      })}
    </div>
  );
};

export default CardsVersion1;
