import React from "react";
import {
  addDivisionToLifter,
  deleteLifterFromDivision,
} from "util/pouchActions";
import AddIcon from "icons/AddIcon";
import DeleteIcon from "icons/DeleteIcon";
import { Column } from "components/table/Table";
import { Meet } from "types";
import map from "lodash/map";

const AddDivisionCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: Column;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    return (
      <div style={style} className="table-cell-inner button-cell">
        {map(lifter.divisions, (division, index: number) => {
          return (
            <div key={index} className="inner-row">
              {index === 0 && (
                <button onClick={() => addDivisionToLifter(meet._id, lifter)}>
                  <AddIcon />
                </button>
              )}
              {index !== 0 && (
                <button
                  onClick={() =>
                    deleteLifterFromDivision(meet._id, lifter, index)
                  }
                >
                  <DeleteIcon />
                </button>
              )}
            </div>
          );
        })}
      </div>
    );
  }
};

export default AddDivisionCell;
