import React, { Component } from 'react';

import Clock from 'components/clock/Clock';

import DisplayComponent from './DisplayComponent';

class Timer extends Component {

  render() {
    const { platform, id } = this.props;

    return (
      <DisplayComponent style={{top: 12, left: 645}} id={id}>
        {({ sizeMultiplier }) => {

          const style = {
            fontSize: 40 * sizeMultiplier,
            paddingLeft: 24 * sizeMultiplier,
            paddingRight: 24 * sizeMultiplier,
            paddingTop: 12 * sizeMultiplier,
            paddingBottom: 12 * sizeMultiplier
          };

          return (
            <Clock platform={platform} style={style} />
          );
        }}
      </DisplayComponent>
    );
  }
}

export default Timer;
