import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  withRouter
} from 'react-router-dom';

import {
  getCurrentMeet,
  getDivision,
  getWeightClass,
  getScreenMedia
} from 'selectors';
import FixedHeightWrapper from 'app/meets/FixedHeightWrapper';
import DivisionResultsTable from './DivisionResultsTable';


import './DivisionIndex.scss';

class DivisionIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forecasted: false
    };
  }

  setForecasted = () => {
    this.setState({forecasted: !this.state.forecasted});
  }

  render() {
    const {
      division,
      weightClass,
      meet,
      media
    } = this.props;

    if(!weightClass || !division || !meet) {
      return null;
    }

    const divisionsAndWeightClasses = [{...division, weightClasses: [weightClass]}];

    return (
      <FixedHeightWrapper>
        <div className="division-index">
          <DivisionResultsTable
            setForecasted={this.setForecasted}
            forecasted={this.state.forecasted}
            meet={meet}
            divisions={divisionsAndWeightClasses}
            media={media}
          />
        </div>
      </FixedHeightWrapper>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    meet: getCurrentMeet(state, props),
    division: getDivision(state, props),
    weightClass: getWeightClass(state, props),
    media: getScreenMedia(state, props)
  };
};

const actions = {};

export default withRouter(connect(mapStateToProps, actions)(DivisionIndex));
