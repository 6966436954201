import React, { Component } from "react";
import get from "lodash/get";
import classNames from "classnames";

import CheckIcon from "icons/CheckIcon";
import "./Lights.scss";
import { Meet, RefPosition } from "types";

type LightsProps = {
  meet: Meet;
  platformId: string;
  showChecks?: boolean;
  style?: { height: number };
};

class Lights extends Component<LightsProps, {}> {
  getDecision = (position: RefPosition) => {
    const { meet, platformId } = this.props;
    return get(meet, ["platforms", platformId, "refs", position, "decision"]);
  };

  getCard = (position: RefPosition, card: "red" | "blue" | "yellow") => {
    const { meet, platformId } = this.props;
    return get(meet, [
      "platforms",
      platformId,
      "refs",
      position,
      "cards",
      card,
    ]);
  };

  allDecisionsMade = () => {
    return (
      this.getDecision("left") &&
      this.getDecision("head") &&
      this.getDecision("right")
    );
  };

  render() {
    const { showChecks, style } = this.props;
    let wrapperStyle = {};
    let lightStyle = {};
    let iconStyle = {};
    let cardsStyle = {};
    if (style) {
      const lightSize = Math.floor(style.height * 0.85);
      const checkSize = Math.floor(style.height * 0.5);
      wrapperStyle = { height: style.height, width: style.height * 4 };
      lightStyle = {
        height: lightSize,
        width: lightSize,
        borderRadius: Math.floor(lightSize / 2),
      };
      iconStyle = { height: checkSize, width: checkSize };
      cardsStyle = {
        height: Math.floor(style.height * 0.12),
        width: lightSize,
      };
    }

    const showDecisions = this.allDecisionsMade();

    return (
      <div className="lights" style={wrapperStyle}>
        <div className="decision">
          <div
            className={classNames("light", {
              [this.getDecision("left")]: showDecisions,
            })}
            style={lightStyle}
          >
            {this.getDecision("left") && showChecks && !showDecisions && (
              <CheckIcon style={iconStyle} />
            )}
          </div>
          <div className="cards" style={cardsStyle}>
            <div
              className={classNames("card", "red", {
                active: this.getCard("left", "red") && showDecisions,
              })}
            />
            <div
              className={classNames("card", "blue", {
                active: this.getCard("left", "blue") && showDecisions,
              })}
            />
            <div
              className={classNames("card", "yellow", {
                active: this.getCard("left", "yellow") && showDecisions,
              })}
            />
          </div>
        </div>
        <div className="decision">
          <div
            className={classNames("light", {
              [this.getDecision("head")]: showDecisions,
            })}
            style={lightStyle}
          >
            {this.getDecision("head") && showChecks && !showDecisions && (
              <CheckIcon style={iconStyle} />
            )}
          </div>
          <div className="cards" style={cardsStyle}>
            <div
              className={classNames("card", "red", {
                active: this.getCard("head", "red") && showDecisions,
              })}
            />
            <div
              className={classNames("card", "blue", {
                active: this.getCard("head", "blue") && showDecisions,
              })}
            />
            <div
              className={classNames("card", "yellow", {
                active: this.getCard("head", "yellow") && showDecisions,
              })}
            />
          </div>
        </div>
        <div className="decision">
          <div
            className={classNames("light", {
              [this.getDecision("right")]: showDecisions,
            })}
            style={lightStyle}
          >
            {this.getDecision("right") && showChecks && !showDecisions && (
              <CheckIcon style={iconStyle} />
            )}
          </div>
          <div className="cards" style={cardsStyle}>
            <div
              className={classNames("card", "red", {
                active: this.getCard("right", "red") && showDecisions,
              })}
            />
            <div
              className={classNames("card", "blue", {
                active: this.getCard("right", "blue") && showDecisions,
              })}
            />
            <div
              className={classNames("card", "yellow", {
                active: this.getCard("right", "yellow") && showDecisions,
              })}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Lights;
