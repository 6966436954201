import { Division } from "types";

export const sortByLifts = (d: Division) => {
  if (!d.lifts) {
    return 0;
  }

  const squat = d.lifts.squat;
  const bench = d.lifts.bench;
  const dead = d.lifts.dead;
  // full power and bench only are the two most common configurations by far. return early as possible
  if (squat && bench && dead) {
    return 1;
  } else if (!squat && bench && !dead) {
    return 2;
  } else if (bench && dead) {
    return 3;
  } else if (dead) {
    return 4;
  } else if (squat && bench) {
    return 5;
  }

  return 6;
};
