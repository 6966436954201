import React from "react";

const LogoIcon = ({
  className,
  loading,
}: {
  className?: string;
  loading?: boolean;
}) => {
  return (
    <div className={className}>
      <svg
        className="layer loading-name"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="400 700 1200 600"
      >
        {loading && (
          <text
            fontSize="50"
            fontWeight="bold"
            fill="white"
            color="white"
            x="1000"
            y="900"
            textAnchor="middle"
          >
            LOADING
          </text>
        )}
        <polygon
          fill="#CCCCCC"
          points="699.1,937 679.3,937 679.3,1063 731.7,1063 731.7,1045 699.1,1045"
        />
        <rect x="738.5" y={937} fill="#CCCCCC" width="19.8" height="126.1" />
        <polygon
          fill="#CCCCCC"
          points="770.9,1063 790.7,1063 790.7,1011 816.3,1011 816.3,993 790.7,993 790.7,955 823.3,955 823.3,937 770.9,937"
        />
        <polygon
          fill="#CCCCCC"
          points="825.3,955 846,955 846,1063 865.8,1063 865.8,955 886.5,955 886.5,937 825.3,937"
        />
        <rect x="893.4" y={937} fill="#CCCCCC" width="19.8" height="126.1" />
        <polygon
          fill="#CCCCCC"
          points="970.8,1012.4 950.5,937 925.6,937 925.6,1063 943.4,1063 943.4,971.7 968.1,1063 988.5,1063 988.5,937 970.8,937"
        />
        <path
          fill="#CCCCCC"
          d="M1028.8,935.5c-19.5,0-29.5,11.5-29.5,31.7v65.6c0,20.2,10.1,31.7,29.5,31.7c19.5,0,29.5-11.5,29.5-31.7v-40h-27.7v18h9v23.2c0,9-4,12.2-10.3,12.2c-6.3,0-10.3-3.2-10.3-12.2V966c0-9,4-12.4,10.3-12.4c6.3,0,10.3,3.4,10.3,12.4v13.5h18.7v-12.2C1058.3,947,1048.3,935.5,1028.8,935.5z"
        />
        <path
          fill="#EEEEEE"
          d="M1097.2,935.5c-19.5,0-29.5,11.5-29.5,31.7v65.6c0,20.2,10.1,31.7,29.5,31.7c19.5,0,29.5-11.5,29.5-31.7V1016h-18.7v18c0,9-4,12.2-10.3,12.2c-6.3,0-10.3-3.2-10.3-12.2V966c0-9,4-12.4,10.3-12.4c6.3,0,10.3,3.4,10.3,12.4v13.5h18.7v-12.2C1126.8,947,1116.7,935.5,1097.2,935.5z"
        />
        <path
          fill="#EEEEEE"
          d="M1152,937l-20.2,126.1h18.2l3.4-22.9h24.3l3.4,22.9h20L1181,937H1152z M1156,1023.1l9.5-63.8l9.5,63.8H1156z"
        />
        <path
          fill="#EEEEEE"
          d="M1225.8,966c0-9,3.6-12.4,9.9-12.4c6.3,0,9.9,3.4,9.9,12.4v5.2h18.7v-4c0-20.2-9.9-31.7-29.2-31.7S1206,947,1206,967.2c0,36,38.7,40.9,38.7,66.8c0,9-4,12.2-10.3,12.2s-10.3-3.2-10.3-12.2v-9h-18.7v7.7c0,20.2,10.1,31.7,29.5,31.7c19.5,0,29.5-11.5,29.5-31.7C1264.6,996.8,1225.8,991.9,1225.8,966z"
        />
        <polygon
          fill="#EEEEEE"
          points="1268.3,955 1289.1,955 1289.1,1063 1308.9,1063 1308.9,955 1329.6,955 1329.6,937 1268.3,937"
        />
      </svg>
      <svg
        className="layer loading-plate right plate-three"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="400 700 1200 600"
      >
        <path
          fill="#CCCCCC"
          d="M1299,803.8c-16.9,0-30.6,13.7-30.6,30.6v87h61.2v-87C1329.6,817.5,1315.9,803.8,1299,803.8z"
        />
        <path
          fill="#CCCCCC"
          d="M1268.3,1165.6c0,16.9,13.7,30.6,30.6,30.6s30.6-13.7,30.6-30.6v-87h-61.2V1165.6z"
        />
      </svg>
      <svg
        className="layer loading-plate right plate-two"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="400 700 1200 600"
      >
        <path
          fill="#CCCCCC"
          d="M1367.4,836.7c-14.1,0-25.5,11.4-25.5,25.5v275.6c0,14.1,11.4,25.5,25.5,25.5c14.1,0,25.5-11.4,25.5-25.5V862.2C1392.9,848.1,1381.5,836.7,1367.4,836.7z"
        />
      </svg>
      <svg
        className="layer loading-plate right plate-one"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="400 700 1200 600"
      >
        <path
          fill="#CCCCCC"
          d="M1458.1,977.8h-21.3v-46.1c0-7-6.7-13.1-15.3-13.3c-8.9-0.2-16.3,5.6-16.3,12.7v137.3c0,7,6.7,13.1,15.3,13.3c8.9,0.2,16.3-5.6,16.3-12.7v-46.7h21.3c6,0,10.9-4.9,10.9-10.9v-22.6C1469,982.7,1464.1,977.8,1458.1,977.8z"
        />
      </svg>
      <svg
        className="layer loading-plate left plate-three"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="400 700 1200 600"
      >
        <path
          fill="#EEEEEE"
          d="M701,803.8c-16.9,0-30.6,13.7-30.6,30.6v87h61.2v-87C731.7,817.5,718,803.8,701,803.8z"
        />
        <path
          fill="#EEEEEE"
          d="M670.4,1165.6c0,16.9,13.7,30.6,30.6,30.6s30.6-13.7,30.6-30.6v-87h-61.2V1165.6z"
        />
      </svg>
      <svg
        className="layer loading-plate left plate-two"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="400 700 1200 600"
      >
        <path
          fill="#EEEEEE"
          d="M632.6,836.7c-14.1,0-25.5,11.4-25.5,25.5v275.6c0,14.1,11.4,25.5,25.5,25.5c14.1,0,25.5-11.4,25.5-25.5V862.2C658.1,848.1,646.7,836.7,632.6,836.7z"
        />
      </svg>
      <svg
        className="layer loading-plate left plate-one"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="400 700 1200 600"
      >
        <path
          fill="#EEEEEE"
          d="M578.5,918.4c-8.6,0.2-15.3,6.3-15.3,13.3v46.1h-21.3c-6,0-10.9,4.9-10.9,10.9v22.6c0,6,4.9,10.9,10.9,10.9h21.3v46.7c0,7.2,7.3,12.9,16.3,12.7c8.6-0.2,15.3-6.3,15.3-13.3V931.1C594.8,923.9,587.4,918.2,578.5,918.4z"
        />
      </svg>
    </div>
  );
};

export default LogoIcon;
