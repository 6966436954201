import React from "react";

import CheckboxChecked from "icons/CheckboxChecked";
import CheckboxUnChecked from "icons/CheckboxUnChecked";
import "./Checkbox.scss";
import classNames from "classnames";

const Checkbox = ({
  label,
  disabled,
  value,
  onCheck,
}: {
  label?: string | React.ReactNode;
  disabled?: boolean;
  value: boolean;
  onCheck: (v: boolean) => void;
}) => {
  const onCheckInternal = () => {
    if (!disabled) {
      onCheck(!value);
    }
  };

  return (
    <div
      className={classNames("checkbox", { disabled: disabled })}
      onClick={onCheckInternal}
    >
      {value && <CheckboxChecked />}
      {!value && <CheckboxUnChecked />}
      <div>{label}</div>
    </div>
  );
};

export default Checkbox;
