import React from "react";
import get from "lodash/get";
import { Link } from "react-router-dom";

const NameCell = ({ data, column, meet, style }) => {
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  }
  if (data.row === "title") {
    return (
      <div style={style} className="table-cell-inner">
        {get(lifter, column.key)}
      </div>
    );
  }
  return (
    <div style={style} className="ellipsis table-cell-inner">
      <Link to={`/meets/${get(meet, "_id")}/lifter/${get(lifter, "_id")}`}>
        {get(lifter, column.key)}
      </Link>
    </div>
  );
};

export default NameCell;
