import React, { Component } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { RecordsForAttempt } from "components/RecordsForAttempt";
import Menu from "./Menu";
import PouchInput from "components/PouchInput";
import { updateAttributeOnDocument, addAttempt } from "util/pouchActions";

import ThumbsUpIcon from "icons/ThumbsUpIcon";
import ThumbsDownIcon from "icons/ThumbsDownIcon";
import ClockIcon from "icons/ClockIcon";
import ResetIcon from "icons/ResetIcon";
import VideoIcon from "icons/VideoIcon";
import AddIcon from "icons/AddIcon";

import { getKnownLiftingOrder } from "selectors";

import { isCompetingInLift } from "util/lifterHelper";

class ActionButtons extends Component {
  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyPress);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyPress);
  }

  handleKeyPress = (event) => {
    if (event.keyCode === 71) {
      // g key pressed
      this.goodAttemptButton.click();
    } else if (event.keyCode === 66) {
      // b key pressed
      this.badAttemptButton.click();
    } else if (event.keyCode === 83) {
      // s key pressed
      this.startClockButton.click();
    } else if (event.keyCode === 82) {
      // r key pressed
      this.resetClockButton.click();
    }
  };

  markAttempt = (
    result,
    lifter,
    lift,
    attemptNumber,
    meet,
    selectNextAttempt
  ) => {
    const attempt = get(lifter, ["lifts", lift, attemptNumber]);

    updateAttributeOnDocument(meet._id, attempt._id, "result", result);
    selectNextAttempt(this.props.knownLiftingOrder);
  };

  startClock = (lifter, meet) => {
    updateAttributeOnDocument(
      meet._id,
      lifter.platformId,
      "clockState",
      "started"
    );
  };

  resetClock = (lifter, meet) => {
    updateAttributeOnDocument(
      meet._id,
      lifter.platformId,
      "clockState",
      "initial"
    );
  };

  render() {
    const { lifter, meet, column } = this.props;

    const platform = get(meet, ["platforms", lifter.platformId], {});
    const attempt = get(lifter, ["lifts", column.lift, column.attemptNumber]);

    return (
      <div>
        <div className="button-row">
          <button
            ref={(ref) => (this.goodAttemptButton = ref)}
            className="attempt-button"
            onClick={() =>
              this.markAttempt(
                "good",
                lifter,
                column.lift,
                column.attemptNumber,
                meet,
                column.selectNextAttempt
              )
            }
          >
            <ThumbsUpIcon />
          </button>
          <button
            ref={(ref) => (this.badAttemptButton = ref)}
            className="attempt-button"
            onClick={() =>
              this.markAttempt(
                "bad",
                lifter,
                column.lift,
                column.attemptNumber,
                meet,
                column.selectNextAttempt
              )
            }
          >
            <ThumbsDownIcon />
          </button>
        </div>
        {!meet.virtualMeet && (
          <div className="button-row">
            <button
              ref={(ref) => (this.startClockButton = ref)}
              className="attempt-button"
              onClick={() => this.startClock(lifter, meet)}
              disabled={platform.clockState === "started"}
            >
              <ClockIcon />
            </button>
            <button
              ref={(ref) => (this.resetClockButton = ref)}
              className="attempt-button"
              onClick={() => this.resetClock(lifter, meet)}
              disabled={platform.clockState === "initial"}
            >
              <ResetIcon />
            </button>
          </div>
        )}
        {meet.virtualMeet && attempt && attempt.videoUrl && (
          <div className="button-row">
            <a
              href={attempt.videoUrl}
              target="_blank"
              className="attempt-button"
              rel="noreferrer"
            >
              <VideoIcon />
            </a>
          </div>
        )}
      </div>
    );
  }
}

class AttemptCell extends Component {
  componentDidMount() {
    this.startClock(this.props);
  }

  componentDidUpdate(prevProps) {
    if (
      get(prevProps.data, [
        "lifts",
        prevProps.column.lift,
        prevProps.column.attemptNumber,
        "startTime",
      ]) !==
      get(this.props.data, [
        "lifts",
        this.props.column.lift,
        this.props.column.attemptNumber,
        "startTime",
      ])
    ) {
      this.startClock(this.props);
    }
  }

  startClock = (props) => {
    const { data, column } = props;

    const attemptTimer = get(data, [
      "lifts",
      column.lift,
      column.attemptNumber,
      "startTime",
    ]);
    if (attemptTimer && Date.now() - attemptTimer < 60000) {
      this.timer = setInterval(this.updateTimer, 1000);
    }
  };

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  updateTimer = () => {
    const { data, column } = this.props;

    const attemptTimer = get(data, [
      "lifts",
      column.lift,
      column.attemptNumber,
      "startTime",
    ]);
    if (!attemptTimer || Date.now() - attemptTimer > 60000) {
      clearInterval(this.timer);
    }

    this.forceUpdate();
  };

  addFourthAttempt = () => {
    const { data, meet, column } = this.props;
    const lifter = data;

    const lifterId = lifter._id;
    const meetId = meet._id;
    const fourthAttempt = {
      liftName: column.lift,
      attemptNumber: "4",
    };

    addAttempt(fourthAttempt, lifterId, meetId);
  };

  render() {
    const { data, column, meet, match, style, knownLiftingOrder } = this.props;

    const platformId = get(match, ["params", "platformId"]);
    const lifter = data;
    if (lifter.row === "header") {
      return (
        <div style={style} className="table-cell-inner">
          {column.label}
        </div>
      );
    }
    if (data.row === "title") {
      return <div style={style} className="table-cell-inner" />;
    }

    const attempt = get(lifter, ["lifts", column.lift, column.attemptNumber]);

    // Not sure how to handle this. We could be in a situation where we should have a 4th attempt doc but it is missing.
    if ((!attempt || !attempt._id) && column.attemptNumber === "4") {
      return (
        <div style={style} className="table-cell-inner add-fourth-attempt-cell">
          <button onClick={this.addFourthAttempt}>
            <AddIcon />
          </button>
        </div>
      );
    }

    if (!attempt || !attempt._id) {
      return (
        <div style={style} className="table-cell-inner">
          "ERROR"
        </div>
      );
    }

    if (!isCompetingInLift(lifter, get(attempt, "liftName"), meet)) {
      return null;
    }

    const isCurrentAttempt =
      get(meet, ["platforms", platformId, "currentAttemptId"]) ===
      get(attempt, "_id");

    let placeholder = attempt.startTime
      ? 60 - Math.round((Date.now() - attempt.startTime) / 1000)
      : "";
    if (placeholder <= 0 && placeholder !== "") {
      placeholder = "!";
    }

    return (
      <div
        style={style}
        className={classNames(
          "attempt-buttons",
          "table-cell-inner",
          attempt.result || "",
          { "current-attempt": isCurrentAttempt }
        )}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <PouchInput
            style={{ width: "70%" }}
            type="number"
            meetId={meet._id}
            documentId={get(attempt, "_id")}
            name={column.key}
            value={get(attempt, column.key)}
            readOnly={attempt.result}
            placeholder={placeholder}
          />
          {get(attempt, "endOfRound") && <span>&nbsp;*</span>}
          <RecordsForAttempt attempt={attempt} />
          <Menu
            isCurrentAttempt={isCurrentAttempt}
            attempt={attempt}
            meet={meet}
          />
        </div>
        {isCurrentAttempt && (
          <ActionButtons
            lifter={lifter}
            meet={meet}
            column={column}
            knownLiftingOrder={knownLiftingOrder}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    knownLiftingOrder: getKnownLiftingOrder(state, props),
  };
};

const actions = {};

export default withRouter(connect(mapStateToProps, actions)(AttemptCell));
