import each from "lodash/each";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import uniqWith from "lodash/uniqWith";

import { getAttemptDisplay, getBestAttemptDisplay } from "../exportData";
import { schwartzMaloneCoef } from "../coefficients";

import {
  getRealAge,
  getLifterAwardsWeightClassDoc,
  getTotal,
  getSchwartzMalonePoints,
  getSchwartzMaloneAndAgePoints,
} from "../lifterHelper";
import {
  CsvArray,
  FederationDivisionsConfig,
  Lifter,
  LifterDivision,
  Meet,
} from "types";

// prettier-ignore
const apaWeightClasses = {
  'MALE': [
    {name: '44',          lbsName: '97lbs (52kg)',       maxWeight: 44,   lbsMaxWeight: 97},
    {name: '48',          lbsName: '105.75lbs (56kg)',   maxWeight: 48,   lbsMaxWeight: 105.75},
    {name: '52',          lbsName: '114.5lbs (60kg)',    maxWeight: 52,   lbsMaxWeight: 114.5},
    {name: '56',          lbsName: '123.5lbs (67.5kg)',  maxWeight: 56,   lbsMaxWeight: 123.5},
    {name: '60',          lbsName: '132.25lbs (75kg)',   maxWeight: 60,   lbsMaxWeight: 132.25},
    {name: '67.5',        lbsName: '148.75lbs (82.5kg)', maxWeight: 67.5, lbsMaxWeight: 148.75},
    {name: '75',          lbsName: '165.25lbs (90kg)',   maxWeight: 75,   lbsMaxWeight: 165.25},
    {name: '82.5',        lbsName: '181.75lbs (100kg)',  maxWeight: 82.5, lbsMaxWeight: 181.75},
    {name: '90',          lbsName: '198.25lbs (110kg)',  maxWeight: 90,   lbsMaxWeight: 198.25},
    {name: '100',         lbsName: '220.25lbs (125kg)',  maxWeight: 100,  lbsMaxWeight: 220.25},
    {name: '110',         lbsName: '242.5lbs (140kg)',   maxWeight: 110,  lbsMaxWeight: 242.5},
    {name: '125',         lbsName: '275.5lbs (140kg)',   maxWeight: 125,  lbsMaxWeight: 275.5},
    {name: '140',         lbsName: '308.75lbs (140kg)',  maxWeight: 140,  lbsMaxWeight: 308.75},
    {name: 'Super Heavy', lbsName: 'Super Heavy',        maxWeight: 9999, lbsMaxWeight: 9999}
  ],
  'FEMALE': [
    {name: '44',        lbsName: '97lbs (52kg)',       maxWeight: 44,   lbsMaxWeight: 97},
    {name: '48',        lbsName: '105.75lbs (56kg)',   maxWeight: 48,   lbsMaxWeight: 105.75},
    {name: '52',        lbsName: '114.5lbs (60kg)',    maxWeight: 52,   lbsMaxWeight: 114.5},
    {name: '56',        lbsName: '123.5lbs (67.5kg)',  maxWeight: 56,   lbsMaxWeight: 123.5},
    {name: '60',        lbsName: '132.25lbs (75kg)',   maxWeight: 60,   lbsMaxWeight: 132.25},
    {name: '67.5',      lbsName: '148.75lbs (82.5kg)', maxWeight: 67.5, lbsMaxWeight: 148.75},
    {name: '75',        lbsName: '165.25lbs (90kg)',   maxWeight: 75,   lbsMaxWeight: 165.25},
    {name: '82.5',      lbsName: '181.75lbs (100kg)',  maxWeight: 82.5, lbsMaxWeight: 181.75},
    {name: '90',        lbsName: '198.25lbs (110kg)',  maxWeight: 90,   lbsMaxWeight: 198.25},
    {name: '100',       lbsName: '220.25lbs (125kg)',  maxWeight: 100,  lbsMaxWeight: 220.25},
    {name: 'Unlimited', lbsName: 'Unlimited',          maxWeight: 9999, lbsMaxWeight: 9999}
  ]
};

// prettier-ignore
export const apaBaseDivisions: FederationDivisionsConfig = [
  {name: "Teen (13-15)",       code: 'T1',  low: 13,  high: 15,   default: true,  records: true,  weightClasses: apaWeightClasses},
  {name: "Teen (16-17)",       code: 'T2',  low: 16,  high: 17,   default: true,  records: true,  weightClasses: apaWeightClasses},
  {name: "Teen (18-19)",       code: 'T3',  low: 18,  high: 19,   default: true,  records: true,  weightClasses: apaWeightClasses},
  {name: "Junior (20-23)",     code: 'JR',  low: 20,  high: 23,   default: true,  records: true,  weightClasses: apaWeightClasses},
  {name: "Open",               code: 'O',   low: 24,  high: 39,   default: true,  records: true,  weightClasses: apaWeightClasses},
  {name: "Sub-Master (33-39)", code: 'SM',  low: 35,  high: 39,   default: true,  records: true,  weightClasses: apaWeightClasses},
  {name: "Master (40-44)",     code: 'M1',  low: 40,  high: 44,   default: true,  records: true,  weightClasses: apaWeightClasses},
  {name: "Master (45-49)",     code: 'M2',  low: 45,  high: 49,   default: true,  records: true,  weightClasses: apaWeightClasses},
  {name: "Master (50-54)",     code: 'M3',  low: 50,  high: 54,   default: true,  records: true,  weightClasses: apaWeightClasses},
  {name: "Master (55-59)",     code: 'M4',  low: 55,  high: 59,   default: true,  records: true,  weightClasses: apaWeightClasses},
  {name: "Master (60-64)",     code: 'M5',  low: 60,  high: 64,   default: true,  records: true,  weightClasses: apaWeightClasses},
  {name: "Master (65-69)",     code: 'M6',  low: 65,  high: 69,   default: true,  records: true,  weightClasses: apaWeightClasses},
  {name: "Master (70-74)",     code: 'M7',  low: 70,  high: 74,   default: true,  records: true,  weightClasses: apaWeightClasses},
  {name: "Master (75-79)",     code: 'M8',  low: 75,  high: 79,   default: true,  records: true,  weightClasses: apaWeightClasses},
  {name: "Master (80+)",       code: 'M9',  low: 80,  high: 999,  default: true,  records: true,  weightClasses: apaWeightClasses},
  {name: "M/P/F",              code: 'MPF',                       default: false, records: true,  weightClasses: apaWeightClasses},
  {name: "Guest",              code: 'G',                         default: false, records: false, weightClasses: apaWeightClasses}
];

const getLifterAPADivision = function (lifter: Lifter, meet: Meet) {
  const divisionAge = getRealAge(lifter, meet);
  if (divisionAge && divisionAge >= 13) {
    return apaBaseDivisions.find((division) => {
      return (
        division.default &&
        division.low &&
        division.high &&
        divisionAge >= division.low &&
        divisionAge <= division.high
      );
    });
  }

  return null;
};

export const exportAPAResults = function (meet: Meet, dataArray: any) {
  let csvObject: CsvArray = [];
  each(dataArray, (lifter) => {
    if (!lifter || lifter.row === "title" || lifter.row === "header") {
      return;
    }

    const division = lifter.division;
    const lifterDivision = lifter.divisions.find(
      (d: LifterDivision) => d.divisionId === division._id
    );

    const weightClass = getLifterAwardsWeightClassDoc(
      meet,
      lifter,
      lifterDivision
    );

    let divCode = "";
    if (lifter.gender === "MALE") {
      divCode += "M";
    } else {
      divCode += "F";
    }

    if (!division.usaplDivisionCode || division.usaplDivisionCode === "AUTO") {
      const standardDivision = getLifterAPADivision(lifter, meet);
      if (standardDivision?.code) {
        divCode += standardDivision.code;
      }
    } else if (division.usaplDivisionCode === "HIDE") {
      return;
    } else {
      divCode += division.usaplDivisionCode;
    }

    if (lifter.rawOrEquipped === "RAW") {
      divCode += "R";
    } else if (lifter.rawOrEquipped === "UNLIMITED") {
      divCode += "U";
    } else {
      divCode += "E";
    }

    // add division info to determine if cell should be displayed
    lifter = {
      ...lifter,
      division: {
        lifts: division.lifts,
      },
    };

    const row = {
      name: lifter.name,
      age: getRealAge(lifter, meet),
      division: divCode,
      bodyWeight: lifter.bodyWeight,
      weightClass: get(weightClass, "name", ""),
      coef: schwartzMaloneCoef(lifter, meet),
      squat1: getAttemptDisplay(lifter, "squat", "1"),
      squat2: getAttemptDisplay(lifter, "squat", "2"),
      squat3: getAttemptDisplay(lifter, "squat", "3"),
      squat4: "",
      bestSquat: getBestAttemptDisplay(lifter, "squat"),
      bench1: getAttemptDisplay(lifter, "bench", "1"),
      bench2: getAttemptDisplay(lifter, "bench", "2"),
      bench3: getAttemptDisplay(lifter, "bench", "3"),
      bench4: "",
      bestBench: getBestAttemptDisplay(lifter, "bench"),
      subTotal: "",
      dead1: getAttemptDisplay(lifter, "dead", "1"),
      dead2: getAttemptDisplay(lifter, "dead", "2"),
      dead3: getAttemptDisplay(lifter, "dead", "3"),
      dead4: "",
      bestDeadlift: getBestAttemptDisplay(lifter, "dead"),
      total: getTotal(lifter, division._id, meet),
      coefScore: getSchwartzMalonePoints(lifter, division._id, meet, false),
      ageCoefScore: getSchwartzMaloneAndAgePoints(
        lifter,
        division._id,
        meet,
        false
      ),
      place: lifter.place || "",
      plDivWtCl: `${lifter.place || ""}-${divCode}-${get(
        weightClass,
        "name",
        ""
      )}`,
      tmPts: "",
      team: lifter.team,
      state: lifter.state,
      wraps: "",
      sleeves: "",
      belt: "",
    };

    csvObject.push(row);
  });

  csvObject = sortBy(csvObject, [
    "division",
    (row) =>
      row.weightClass && typeof row.weightClass === "string"
        ? parseInt(row.weightClass, 10)
        : 0,
  ]);
  csvObject = uniqWith(csvObject, (a, b) => {
    return (
      a.name === b.name &&
      a.division === b.division &&
      a.bodyWeight === b.bodyWeight &&
      a.birthDate === b.birthDate
    );
  });

  const header = {
    name: "Name",
    age: "Age",
    division: "Div",
    bodyWeight: "BWt",
    weightClass: "WtCls",
    coef: "Schwartz/Malone",
    squat1: "Squat 1",
    squat2: "Squat 2",
    squat3: "Squat 3",
    squat4: "Squat 4",
    bestSquat: "Best Squat",
    bench1: "Bench 1",
    bench2: "Bench 2",
    bench3: "Bench 3",
    bench4: "Bench 4",
    bestBench: "Best Bench",
    subTotal: "Sub Total",
    dead1: "Deadlift 1",
    dead2: "Deadlift 2",
    dead3: "Deadlift 3",
    dead4: "Deadlift 4",
    bestDeadlift: "Best Deadlift",
    total: "Toal",
    coefScore: "Coeff Score",
    ageCoefScore: "Age  & Coeff",
    place: "Pl code",
    plDivWtCl: "Pl-Div-WtCl",
    tmPts: "Tm Pts",
    team: "Team",
    state: "State",
    wraps: "Wraps",
    sleeves: "Sleeves",
    belt: "Belt",
  };

  csvObject.unshift(header);

  return csvObject;
};
