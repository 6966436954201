import each from "lodash/each";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import uniqWith from "lodash/uniqWith";

import { getMeetUnits } from "../meetHelper";
import { competitionCode } from "../standardDivisions";
import { getAttemptDisplay } from "../exportData";
import { CsvArray, FederationDivisionsConfig, Meet } from "types";

// prettier-ignore
const mmpWeightClasses = {
  'MALE': [
    {name: '52',   lbsName: '114.50lbs (52kg)',   maxWeight: 52,   lbsMaxWeight: 114.50},
    {name: '56',   lbsName: '123.25lbs (56kg)',   maxWeight: 56,   lbsMaxWeight: 123.25},
    {name: '60',   lbsName: '132.25lbs (60kg)',   maxWeight: 60,   lbsMaxWeight: 132.25},
    {name: '67.5', lbsName: '148.75lbs (67.5kg)', maxWeight: 67.5, lbsMaxWeight: 148.75},
    {name: '75',   lbsName: '165.25lbs (75kg)',   maxWeight: 75,   lbsMaxWeight: 165.25},
    {name: '82.5', lbsName: '181.75lbs (82.5kg)', maxWeight: 82.5, lbsMaxWeight: 181.75},
    {name: '90',   lbsName: '198.25lbs (90kg)',   maxWeight: 90,   lbsMaxWeight: 198.25},
    {name: '100',  lbsName: '220.25lbs (100kg)',  maxWeight: 100,  lbsMaxWeight: 220.25},
    {name: '110',  lbsName: '242.50lbs (110kg)',  maxWeight: 110,  lbsMaxWeight: 242.50},
    {name: '125',  lbsName: '275.50lbs (125kg)',  maxWeight: 125,  lbsMaxWeight: 275.50},
    {name: '140',  lbsName: '308.5lbs (140kg)',   maxWeight: 140,  lbsMaxWeight: 308.50},
    {name: '140+', lbsName: '308.5lbs+ (140kg+)', maxWeight: 9999, lbsMaxWeight: 9999}
  ],
  'FEMALE': [
    {name: '44',   lbsName: '97.00lbs (44kg)',    maxWeight: 44,   lbsMaxWeight: 97.00},
    {name: '48',   lbsName: '105.75lbs (48kg)',   maxWeight: 48,   lbsMaxWeight: 105.75},
    {name: '52',   lbsName: '114.50lbs (52kg)',   maxWeight: 52,   lbsMaxWeight: 114.50},
    {name: '56',   lbsName: '123.25lbs (56kg)',   maxWeight: 56,   lbsMaxWeight: 123.25},
    {name: '60',   lbsName: '132.25lbs (60kg)',   maxWeight: 60,   lbsMaxWeight: 132.25},
    {name: '67.5', lbsName: '148.75lbs (67.5kg)', maxWeight: 67.5, lbsMaxWeight: 148.75},
    {name: '75',   lbsName: '165.25lbs (75kg)',   maxWeight: 75,   lbsMaxWeight: 165.25},
    {name: '82.5', lbsName: '181.75lbs (82.5kg)', maxWeight: 82.5, lbsMaxWeight: 181.75},
    {name: '90',   lbsName: '198.25lbs (90kg)',   maxWeight: 90,   lbsMaxWeight: 198.25},
    {name: '90+',  lbsName: '198.25lbs+ (90kg+)', maxWeight: 9999, lbsMaxWeight: 9999}
  ]
};

// prettier-ignore
export const mmpBaseDivisions: FederationDivisionsConfig = [
  {name: "Teenage (up to 13)", code: 'Y',   low: 13,  high: 15,   default: true,  records: true,  weightClasses: mmpWeightClasses},
  {name: "Teenage (14-15)",    code: 'T1',  low: 13,  high: 15,   default: true,  records: true,  weightClasses: mmpWeightClasses},
  {name: "Teenage (16-17)",    code: 'T2',  low: 16,  high: 17,   default: true,  records: true,  weightClasses: mmpWeightClasses},
  {name: "Teenage (18-19)",    code: 'T3',  low: 18,  high: 19,   default: true,  records: true,  weightClasses: mmpWeightClasses},
  {name: "Junior (20-23)",     code: 'Jr',  low: 20,  high: 23,   default: true,  records: true,  weightClasses: mmpWeightClasses},
  {name: "Open",               code: 'O',   low: 24,  high: 39,   default: true,  records: true,  weightClasses: mmpWeightClasses},
  {name: "Sub-Master (35-39)", code: 'SM',  low: 35,  high: 39,   default: true,  records: true,  weightClasses: mmpWeightClasses},
  {name: "Master (40-44)",     code: 'M1',  low: 40,  high: 44,   default: true,  records: true,  weightClasses: mmpWeightClasses},
  {name: "Master (45-49)",     code: 'M2',  low: 45,  high: 49,   default: true,  records: true,  weightClasses: mmpWeightClasses},
  {name: "Master (50-54)",     code: 'M3',  low: 50,  high: 54,   default: true,  records: true,  weightClasses: mmpWeightClasses},
  {name: "Master (55-59)",     code: 'M4',  low: 55,  high: 59,   default: true,  records: true,  weightClasses: mmpWeightClasses},
  {name: "Master (60-64)",     code: 'M5',  low: 60,  high: 64,   default: true,  records: true,  weightClasses: mmpWeightClasses},
  {name: "Master (65-69)",     code: 'M6',  low: 65,  high: 69,   default: true,  records: true,  weightClasses: mmpWeightClasses},
  {name: "Master (70-74)",     code: 'M7',  low: 70,  high: 74,   default: true,  records: true,  weightClasses: mmpWeightClasses},
  {name: "Master (75-79)",     code: 'M8',  low: 75,  high: 79,   default: true,  records: true,  weightClasses: mmpWeightClasses},
  {name: "Master (80+)",       code: 'M9',  low: 80,  high: 999,  default: true,  records: true,  weightClasses: mmpWeightClasses},
  {name: "Special Athlete",    code: 'SO',                        default: true,  records: true,  weightClasses: mmpWeightClasses},
  {name: "Guest",              code: 'G',                         default: false, records: false, weightClasses: mmpWeightClasses}
];

export const exportMMPResults = function (meet: Meet, dataArray: any) {
  let csvObject: CsvArray = [];
  each(dataArray, (lifter) => {
    if (!lifter || lifter.row === "title" || lifter.row === "header") {
      return;
    }

    const division = lifter.division;
    const weightClass = lifter.weightClass;
    const gender = lifter.gender === "MALE" ? "M" : "F";
    const rawOrEquipped = lifter.division.rawOrEquipped;

    // const weightClass = getLifterAwardsWeightClassDoc(meet, lifter, lifter.division);

    const compEvents = competitionCode(division);

    // add division info to determine if cell should be displayed
    lifter = {
      ...lifter,
      division: {
        lifts: division.lifts,
      },
    };

    const row = {
      name: lifter.name,
      team: lifter.team,
      gender: gender,
      equipmentLevel: rawOrEquipped,
      division: get(division, "name", ""),
      bodyWeight: lifter.bodyWeight,
      weightClass: get(weightClass, "name", ""),
      birthDate: lifter.birthDate,
      squat1: getAttemptDisplay(lifter, "squat", "1"),
      squat2: getAttemptDisplay(lifter, "squat", "2"),
      squat3: getAttemptDisplay(lifter, "squat", "3"),
      squat4: getAttemptDisplay(lifter, "squat", "4"),
      bench1: getAttemptDisplay(lifter, "bench", "1"),
      bench2: getAttemptDisplay(lifter, "bench", "2"),
      bench3: getAttemptDisplay(lifter, "bench", "3"),
      bench4: getAttemptDisplay(lifter, "bench", "4"),
      dead1: getAttemptDisplay(lifter, "dead", "1"),
      dead2: getAttemptDisplay(lifter, "dead", "2"),
      dead3: getAttemptDisplay(lifter, "dead", "3"),
      dead4: getAttemptDisplay(lifter, "dead", "4"),
      compEvents: compEvents,
      state: lifter.state,
      memberNumber: get(lifter, "restricted.memberNumber"),
      drugTest: lifter.wasDrugTested,
    };

    csvObject.push(row);
  });

  csvObject = sortBy(csvObject, [
    "compEvents",
    (row) =>
      row.weightClass && typeof row.weightClass === "string"
        ? parseInt(row.weightClass, 10)
        : 0,
  ]);
  csvObject = uniqWith(csvObject, (a, b) => {
    return (
      a.name === b.name &&
      a.division === b.division &&
      a.compEvents === b.compEvents &&
      a.bodyWeight === b.bodyWeight &&
      a.birthDate === b.birthDate &&
      a.memberNumber === b.memberNumber
    );
  });

  const header = {
    name: "Name",
    team: "Team",
    gender: "Gender",
    equipmentLevel: "Equipment Level",
    division: "Div",
    bodyWeight: `Bwt - ${getMeetUnits(meet)}`,
    weightClass: "Wt Cls",
    birthDate: "DOB",
    squat1: "Squat 1",
    squat2: "Squat 2",
    squat3: "Squat 3",
    squat4: "Squat 4",
    bench1: "Bench 1",
    bench2: "Bench 2",
    bench3: "Bench 3",
    bench4: "Bench 4",
    dead1: "Deadlift 1",
    dead2: "Deadlift 2",
    dead3: "Deadlift 3",
    dead4: "Deadlift 4",
    compEvents: "Event",
    state: "State",
    memberNumber: "MemberID",
    drugTest: "Drug Test",
  };

  csvObject.unshift(header);

  return csvObject;
};
