import React from "react";
import { parseRecords } from "util/importData";
import ImportRecordsTable from "./ImportRecordsTable";
import {
  convertRecordsCsvToJson,
  exportAllExpandedRecordsForFederation,
} from "util/records";
import isEmpty from "lodash/isEmpty";
import FileUpload from "components/fileUpload/FileUpload";
import { addRecords, updateAttributeOnDocument } from "util/pouchActions";
import InfoModal from "./InfoModal";
import { useMeet } from "util/useMeet";
import { useMediaSize } from "util/useMediaSize";

const ImportRecordsIndex = ({
  onFinishBulkCreate,
}: {
  onFinishBulkCreate: any;
}) => {
  const [infoModalOpen, setInfoModalOpen] = React.useState(true);
  const media = useMediaSize();
  const meet = useMeet();

  const [items, setItems] = React.useState<any[]>([]);

  const load = (csvString: any) => {
    const newItems = parseRecords(csvString, meet);
    setItems(newItems);
  };

  const save = () => {
    try {
      const result = convertRecordsCsvToJson({ meet, records: items });

      if (meet.records?._id) {
        updateAttributeOnDocument(
          meet._id,
          meet.records._id,
          "data",
          result.data
        );
      } else {
        addRecords(meet._id, result.data);
      }

      setItems([]);
      onFinishBulkCreate(10);
    } catch (e: any) {
      alert(e.message);
    }
  };

  const importDisabled = isEmpty(items);

  return (
    <>
      <div className="import">
        <div className="util-row">
          <FileUpload
            accept="csv"
            buttonText={`Select Records file`}
            onChange={load}
          />
          <button disabled={importDisabled} onClick={save}>
            Confirm Import of Records
          </button>
          <button onClick={() => exportAllExpandedRecordsForFederation(meet)}>
            Download Template
          </button>
        </div>
        <div className="table-wrapper">
          <ImportRecordsTable meet={meet} items={items} media={media} />
        </div>
      </div>
      <InfoModal
        isOpen={infoModalOpen}
        onRequestClose={() => setInfoModalOpen(false)}
      />
    </>
  );
};

export default ImportRecordsIndex;
