import React from "react";
import {
  getDivisionDoc,
  getLifterAwardsWeightClassDoc,
} from "util/lifterHelper";
import { isLbsMeet, isKgMeet, getMeetUnits } from "util/meetHelper";
import { toKg, toLbs } from "util/conversions";
import DisplayComponent from "./DisplayComponent";
import { Meet } from "types";
import get from "lodash/get";
import capitalize from "lodash/capitalize";
import map from "lodash/map";
import size from "lodash/size";
import first from "lodash/first";
import { formatEligibleRecord } from "util/records";
import { useRecordAttempts } from "util/useRecordAttempts";

const LiftDetails = ({
  meet,
  currentAttempt,
  currentLifter,
  id,
}: {
  meet: Meet;
  currentAttempt: any;
  currentLifter: any;
  id: string;
}) => {
  const allRecords = useRecordAttempts();

  if (!currentAttempt) {
    return null;
  }

  const recordsForAttempt = allRecords?.[currentAttempt._id];

  return (
    <DisplayComponent style={{ top: 0, left: 0 }} id={id}>
      {({ sizeMultiplier }: { sizeMultiplier: number }) => {
        const style = {
          fontSize: sizeMultiplier * 38,
          padding: sizeMultiplier * 12,
        };

        let recordAttempt: React.ReactNode = "";
        if (currentAttempt.worldRecord) {
          recordAttempt = <span>World Record Attempt</span>;
        } else if (currentAttempt.regionalRecord) {
          recordAttempt = <span>Regional Record Attempt</span>;
        } else if (
          currentAttempt.stateRecord &&
          currentAttempt.americanRecord
        ) {
          recordAttempt = <span>State and American Record Attempt</span>;
        } else if (
          currentAttempt.stateRecord &&
          currentAttempt.nationalRecord
        ) {
          recordAttempt = <span>State and National Record Attempt</span>;
        } else if (currentAttempt.stateRecord) {
          recordAttempt = <span>State Record Attempt</span>;
        } else if (currentAttempt.americanRecord) {
          recordAttempt = <span>American Record Attempt</span>;
        } else if (
          currentAttempt.provincialRecord &&
          currentAttempt.canadianRecord
        ) {
          recordAttempt = <span>Canadian and Provincial Record Attempt</span>;
        } else if (currentAttempt.canadianRecord) {
          recordAttempt = <span>Canadian Record Attempt</span>;
        } else if (
          currentAttempt.nationalRecord &&
          currentAttempt.provincialRecord
        ) {
          recordAttempt = <span>Provincial and National Record Attempt</span>;
        } else if (currentAttempt.provincialRecord) {
          recordAttempt = <span>Provincial Record Attempt</span>;
        } else if (currentAttempt.nationalRecord) {
          recordAttempt = <span>National Record Attempt</span>;
        }

        if (!recordAttempt && recordsForAttempt && recordsForAttempt.length) {
          const firstRecord = first(recordsForAttempt);
          if (firstRecord) {
            recordAttempt = `${formatEligibleRecord({
              record: firstRecord,
            })} Record Attempt`;
            if (recordsForAttempt.length > 1) {
              recordAttempt =
                recordAttempt + ` and ${recordsForAttempt.length - 1} more...`;
            }
          }
        }

        const lifterName = get(currentLifter, "name", "AAAAAAA AAAAAAA");
        const teamName = currentLifter
          ? get(currentLifter, "team", "")
          : "Team Name";
        let liftName = get(currentAttempt, "liftName", "Lift");
        if (liftName === "bench" && meet.federation === "USSF") {
          liftName = "press";
        }
        const attemptNumber = get(currentAttempt, "attemptNumber", "0");
        const currentWeight = get(currentAttempt, "weight", "000");

        return (
          <div style={style} className="lifter-details">
            <div>{lifterName}</div>
            <div>
              {capitalize(liftName)}-{attemptNumber}: {currentWeight}{" "}
              {getMeetUnits(meet)}
              <span style={{ fontSize: style.fontSize * 0.6 }}>
                {isLbsMeet(meet) && (
                  <span> ({toKg(currentWeight).toFixed(2)} kg)</span>
                )}
                {isKgMeet(meet) && (
                  <span> ({toLbs(currentWeight).toFixed(2)} lbs)</span>
                )}
              </span>
            </div>
            <div
              style={{ fontSize: style.fontSize * 0.7 }}
              className="record-attempt"
            >
              {recordAttempt}
            </div>
            <div style={{ fontSize: style.fontSize * 0.7 }} className="team">
              {teamName}
            </div>
            <div
              style={{ fontSize: style.fontSize * 0.7 }}
              className="divisions"
            >
              {currentLifter &&
                map(
                  get(currentLifter, "divisions"),
                  (lifterDivision, index) => {
                    const divisionDoc = getDivisionDoc(
                      lifterDivision.divisionId,
                      meet
                    );
                    if (!divisionDoc || divisionDoc.hideOnBoard) {
                      return null;
                    }
                    const divisionName = get(divisionDoc, "name");

                    // don't display weightclass name if there is only one for this division
                    const weightClassDoc = getLifterAwardsWeightClassDoc(
                      meet,
                      currentLifter,
                      lifterDivision
                    );
                    const weightClassName =
                      size(divisionDoc.weightClasses) > 1
                        ? get(weightClassDoc, "name")
                        : "";
                    return (
                      <div key={`${lifterDivision.divisionId}${index}`}>
                        {divisionName} {weightClassName}
                      </div>
                    );
                  }
                )}
              {!currentLifter && <div>Men's Raw Open 120+</div>}
            </div>
          </div>
        );
      }}
    </DisplayComponent>
  );
};

export default LiftDetails;
