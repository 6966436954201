import {
  Meet,
  RegistrationLineItem,
  RegistrationPayload,
  WeightClass,
} from "types";
import {
  getDivisionDoc,
  getLifterDeclaredAwardsWeightClass,
} from "util/lifterHelper";
import orderBy from "lodash/orderBy";

export const createLineItemsFromRegistration = ({
  meet,
  registration,
}: {
  meet: Meet;
  registration: RegistrationPayload;
}): RegistrationLineItem[] => {
  const divisions = registration.divisions;
  const items = registration.items;
  const lineItems: RegistrationLineItem[] = [];

  // make sure full lift divisions are at the top so that the lifter is charged the max amount
  // this assumes the meet director sets up full power division to be more expensive
  const sortedDivisions = orderBy(
    divisions,
    (division) => {
      const divisionDoc = getDivisionDoc(division.divisionId, meet);

      if (divisionDoc) {
        const lifts = Object.values(divisionDoc.lifts).filter((v) => v);
        return lifts.length;
      } else {
        return 3;
      }
    },
    "desc"
  );

  sortedDivisions.forEach((lifterDivision, index) => {
    const divisionDoc = getDivisionDoc(lifterDivision.divisionId, meet);
    const weightClassDoc = getLifterDeclaredAwardsWeightClass(
      divisionDoc,
      lifterDivision
    ) as WeightClass;
    if (divisionDoc && weightClassDoc?.name) {
      if (index === 0) {
        const lifts = Object.values(divisionDoc.lifts).filter((v) => v);
        const name = `Primary: ${divisionDoc.name} ${weightClassDoc.name}`;
        let unitAmount: number = 0;
        if (lifts.length === 3) {
          unitAmount = meet.entryConfig.fullPowerliftingCost || 0;
        } else if (lifts.length === 2) {
          unitAmount =
            meet.entryConfig.twoLiftCost ||
            meet.entryConfig.fullPowerliftingCost ||
            0;
        } else {
          unitAmount =
            meet.entryConfig.oneLiftCost ||
            meet.entryConfig.fullPowerliftingCost ||
            0;
        }
        lineItems.push({
          name,
          unitAmount,
        });
      } else {
        lineItems.push({
          name: `Additional: ${divisionDoc.name} ${weightClassDoc.name}`,
          unitAmount: meet.entryConfig.additionalDivisionCost || 0,
        });
      }
    }
  });

  items.forEach((itemId) => {
    if (itemId) {
      const itemFromConfig = meet.entryConfig.items?.find(
        (i) => i.id === itemId
      );
      if (itemFromConfig) {
        lineItems.push({
          name: `Item: ${itemFromConfig.name}`,
          unitAmount: itemFromConfig.cost || 0,
        });
      }
    }
  });

  return lineItems;
};
