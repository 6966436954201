import first from "lodash/first";
import toNumber from "lodash/toNumber";
import each from "lodash/each";
import get from "lodash/get";
import reject from "lodash/reject";
import set from "lodash/set";
import takeRight from "lodash/takeRight";
import random from "lodash/random";
import merge from "lodash/merge";

import { del } from "object-path-immutable";
import { formatISO } from "date-fns";
import {
  getMasterDbInstance,
  getMeetDbInstance,
  unWatchMeet,
} from "util/pouchAdapter";
import { meetIsNotSyncing } from "actions";
import { store } from "store";

import {
  powerliftingLifts,
  powerliftingAttempts,
  isLbsMeet,
} from "util/meetHelper";

import { getEmptyPlatesKg, getEmptyPlatesLbs } from "util/barLoad";

import { getEmptyLifterDivision } from "util/lifterHelper";

import {
  MEET_DOC_PREFIX,
  RESTRICTED_MEET_DOC_PREFIX,
  PLATFORM_DOC_PREFIX,
  LIFTER_DOC_PREFIX,
  RESTRICTED_LIFTER_DOC_PREFIX,
  ATTEMPT_DOC_PREFIX,
  DIVISION_DOC_PREFIX,
  WEIGHT_CLASS_DOC_PREFIX,
  REF_DOC_PREFIX,
  ENTRY_DOC_PREFIX,
  generateId,
  DOC_PARENT_SEPARATOR,
  RECORDS_DOC_PREFIX,
} from "util/docHelper";
import {
  Division,
  Lifter,
  Meet,
  Platform,
  RefPosition,
  WeightClass,
  RecordsDocument,
} from "types";

const deleteDocument = function (documentId: string, meetId: string) {
  const db = getMeetDbInstance(meetId);
  return db
    .get(documentId, { conflicts: true })
    .then((doc: any) => {
      const removePromises = [db.remove(doc)];
      // must make sure to remove all leaf versions
      each(doc._conflicts, (rev) => {
        removePromises.push(db.remove(doc._id, rev));
      });
      return Promise.all(removePromises).catch(function (err) {
        // TODO: Try again on conflict
        console.error("ERROR: removing document", err);
      });
    })
    .catch((err: Error) => {
      console.error("ERROR: removing document", err);
    });
};

const addDocument = ({
  document,
  meetId,
  recordChanges,
}: {
  document: any;
  meetId: string;
  recordChanges: boolean;
}) => {
  const db = getMeetDbInstance(meetId);
  if (recordChanges) {
    document.changes = [];
  }
  document.createDate = formatISO(new Date());
  db.put(document).catch((error: Error) => {
    console.error("ERROR Adding document:", document, error);
  });
};

export const addRecords = function (meetId: string, data: any) {
  const records: RecordsDocument = {
    _id: `${RECORDS_DOC_PREFIX}records`,
    data,
  };

  addDocument({ document: records, meetId, recordChanges: false });
};

const addRef = function (
  position: RefPosition,
  platformId: string,
  meetId: string
) {
  const ref = {
    _id: `${REF_DOC_PREFIX}${position}${DOC_PARENT_SEPARATOR}${platformId}`,
    platformId,
    position,
    cards: {
      red: false,
      blue: false,
      yellow: false,
    },
  };

  addDocument({ document: ref, meetId, recordChanges: false });
};

export const addPlatform = function (meet: Meet, name?: string) {
  const platformId = generateId(PLATFORM_DOC_PREFIX);
  const newPlatform = {
    _id: platformId,
    name: name || "",
    clockTimerLength: 60000,
    clockState: "initial",
    barAndCollarsWeight: isLbsMeet(meet) ? 55 : 25,
  };

  addDocument({
    document: newPlatform,
    meetId: meet._id,
    recordChanges: false,
  });

  addRef("left", platformId, meet._id);
  addRef("head", platformId, meet._id);
  addRef("right", platformId, meet._id);
};

export const deletePlatform = function (platform: Platform, meetId: string) {
  const removePromises = [];
  each(platform.refs, (ref) => {
    removePromises.push(deleteDocument(ref._id, meetId));
  });
  removePromises.push(deleteDocument(platform._id, meetId));
  return Promise.all(removePromises);
};

export const addMeet = function (meet: any) {
  if (isLbsMeet(meet)) {
    meet.plates = getEmptyPlatesLbs();
  } else {
    meet.plates = getEmptyPlatesKg();
  }

  meet._id = generateId(MEET_DOC_PREFIX);
  meet.type = "powerlifting";
  meet.payment_status = "FREE";
  const masterDb = getMasterDbInstance();
  masterDb.put({ _id: meet._id, online: false });
  store.dispatch(meetIsNotSyncing(meet._id));
  addDocument({ document: meet, meetId: meet._id, recordChanges: true });
  addDocument({
    document: {
      _id: `${RESTRICTED_MEET_DOC_PREFIX}${meet._id}`,
      private: true,
    },
    meetId: meet._id,
    recordChanges: false,
  });
  addPlatform(meet, "1");
  addDocument({
    document: { _id: generateId(ENTRY_DOC_PREFIX) },
    meetId: meet._id,
    recordChanges: false,
  });

  return meet._id;
};

export const addAttempt = function (
  attempt: any,
  lifterId: string,
  meetId: string
) {
  attempt._id = `${ATTEMPT_DOC_PREFIX}${toNumber(attempt.attemptNumber)}${first(
    attempt.liftName
  )}${DOC_PARENT_SEPARATOR}${lifterId}`;
  attempt.lifterId = lifterId;

  addDocument({ document: attempt, meetId, recordChanges: true });
};

export const deleteMeet = function (meet: { _id: string }) {
  unWatchMeet();
  const db = getMeetDbInstance(meet._id);
  db.destroy().then(() => {
    const masterDb = getMasterDbInstance();
    masterDb
      .get(meet._id)
      .then((doc: any) => {
        masterDb.remove(doc);
      })
      .catch((err: Error) => {
        console.log("ERROR: gettting meet doc in master db", err);
      });
  });
};

export const addLifter = function (meetId: string, lifter?: any) {
  lifter = lifter || {
    divisions: [getEmptyLifterDivision()],
  };
  lifter.wasDrugTested = "N";
  lifter._id = generateId(LIFTER_DOC_PREFIX);

  // memberNumber, email are a restricted attribute
  const memberNumber = lifter.memberNumber;
  const email = lifter.email;
  delete lifter.memberNumber;
  delete lifter.email;

  const lifts = lifter.lifts;
  delete lifter.lifts;

  addDocument({ document: lifter, meetId, recordChanges: true });
  const restrictedDocId = `${generateId(
    RESTRICTED_LIFTER_DOC_PREFIX
  )}${DOC_PARENT_SEPARATOR}${lifter._id}`;
  addDocument({
    document: { _id: restrictedDocId, private: true, memberNumber, email },
    meetId,
    recordChanges: true,
  });

  each(powerliftingLifts, (liftName) => {
    each(powerliftingAttempts, (attemptNumber) => {
      addAttempt(
        {
          liftName,
          attemptNumber,
          weight: get(lifts, [liftName, attemptNumber, "weight"]),
        },
        lifter._id,
        meetId
      );
    });
  });
};

export const addDivisionToLifter = (meetId: string, lifter: Lifter) => {
  updateAttributeOnDocument(
    meetId,
    lifter._id,
    `divisions.${lifter.divisions.length}`,
    getEmptyLifterDivision()
  );
};

export const deleteLifterFromDivision = (
  meetId: string,
  lifter: Lifter,
  divisionIndex: number
) => {
  const divisions = reject(lifter.divisions, (d, index) => {
    return index === divisionIndex;
  });

  updateAttributeOnDocument(meetId, lifter._id, "divisions", divisions);
};

export const deleteLifter = function (lifter: Lifter, meetId: string) {
  const removePromises = [];
  each(lifter.lifts, (lift) => {
    each(lift, (attempt) => {
      if (attempt) {
        removePromises.push(deleteDocument(attempt._id, meetId));
      }
    });
  });
  removePromises.push(deleteDocument(lifter._id, meetId));
  if (lifter.restricted) {
    removePromises.push(deleteDocument(lifter.restricted._id, meetId));
  }

  return Promise.all(removePromises);
};

export const addWeightClassDeprecated = function (
  divisionId: string,
  meetId: string,
  weightClass?: any
) {
  weightClass = weightClass || {};
  weightClass._id = generateId(WEIGHT_CLASS_DOC_PREFIX, divisionId);
  addDocument({ document: weightClass, meetId, recordChanges: true });
};

export const addWeightClass = function (
  divisionId: string,
  meetId: string,
  weightClass?: any
) {
  weightClass = weightClass || {};
  const id = generateId(WEIGHT_CLASS_DOC_PREFIX);
  weightClass._id = id;
  updateAttributeOnDocument(
    meetId,
    divisionId,
    ["weightClasses", id],
    weightClass
  );
};

export const addDivision = function (meetId: string, division: any) {
  division._id = generateId(DIVISION_DOC_PREFIX);
  division.schema = 1;

  if (!division.weightClasses) {
    division.weightClasses = {
      [generateId(WEIGHT_CLASS_DOC_PREFIX)]: { name: "All", maxWeight: 9999 },
    };
  }

  each(division.weightClasses, (wc, id) => {
    wc._id = id;
  });

  addDocument({ document: division, meetId, recordChanges: true });
};

export const deleteDivision = function (division: Division, meetId: string) {
  const removePromises = [];
  each(division.weightClasses, (weightClass) => {
    if (!division.schema) {
      // old division format where weight classes were their own document.
      removePromises.push(deleteDocument(weightClass._id, meetId));
    }
  });
  removePromises.push(deleteDocument(division._id, meetId));
  return Promise.all(removePromises);
};

export const deleteWeightClassDeprecated = function (
  weightClass: WeightClass,
  meetId: string
) {
  return deleteDocument(weightClass._id, meetId);
};

export const deleteWeightClass = function (
  weightClassId: string,
  division: Division,
  meetId: string
) {
  const weightClasses = del(division.weightClasses, weightClassId);

  return updateAttributeOnDocument(
    meetId,
    division._id,
    "weightClasses",
    weightClasses
  );
};

export const updateAttributeOnDocument = function (
  meetId: string,
  docId: string,
  attribute: string | (string | number)[],
  value: any,
  count = 1
) {
  const db = getMeetDbInstance(meetId);
  db.get(docId)
    .then((doc: any) => {
      if (doc.changes) {
        doc.changes.push({
          rev: doc._rev,
          attribute,
          value,
          timeStamp: formatISO(new Date()),
        });

        // don't allow changes to grow more than 25 in length
        doc.changes = takeRight(doc.changes, 25);
      }
      set(doc, attribute, value);
      db.put(doc).catch((error: Error & { status: number }) => {
        if (error.status === 409 && count < 10) {
          console.log(
            "Conflict on updateAttributeOnDocument trying again",
            count
          );
          setTimeout(
            () =>
              updateAttributeOnDocument(
                meetId,
                docId,
                attribute,
                value,
                count + 1
              ),
            random(0, 100 * count)
          );
        }
        console.log("ERROR: putting document for update", error);
      });
    })
    .catch((err: Error) => {
      console.error("ERROR: gettting document for update", err);
    });
};

export const updateAttributesOnDocument = function (
  meetId: string,
  docId: string,
  attributes: any,
  count = 1
) {
  const db = getMeetDbInstance(meetId);
  db.get(docId)
    .then((doc: any) => {
      each(attributes, (value, key) => {
        if (doc.changes) {
          doc.changes.push({
            rev: doc._rev,
            attribute: key,
            value,
            timeStamp: formatISO(new Date()),
          });

          // don't allow changes to grow more than 25 in length
          doc.changes = takeRight(doc.changes, 25);
        }
      });
      doc = merge(doc, attributes);
      db.put(doc).catch((error: Error & { status: number }) => {
        if (error.status === 409 && count < 10) {
          console.log(
            "Conflict on updateAttributesOnDocument trying again",
            count
          );
          setTimeout(
            () =>
              updateAttributesOnDocument(meetId, docId, attributes, count + 1),
            random(0, 100 * count)
          );
        }
        console.log("ERROR: putting document for update", error);
      });
    })
    .catch((err: Error) => {
      console.error("ERROR: gettting document for update", err);
    });
};

export const resolveConflict = function (meetId: string, loserDoc: any) {
  // const db = getMeetDbInstance(meetId);
  // db.remove(loserDoc)
  //   .then(function () {
  //     console.log("Conflict removed");
  //     db.get(loserDoc._id).then((newDoc: any) => {
  //       handleDatabaseChange(newDoc, false, meetId);
  //     });
  //   })
  //   .catch((err: Error) => {
  //     console.error("ERROR: deleting conflict", loserDoc, err);
  //   });
};
