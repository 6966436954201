import { createSelector } from "reselect";
import get from "lodash/get";
import each from "lodash/each";
import sortBy from "lodash/sortBy";
import findIndex from "lodash/findIndex";
import orderBy from "lodash/orderBy";
import filter from "lodash/filter";
import find from "lodash/find";
import { isCompetingInLift } from "./util/lifterHelper";
import { federationConfigs } from "./util/federations";
import {
  Attempt,
  AttemptNumber,
  EligibleRecordWithWeight,
  LiftingOrder,
  Meet,
  ReactRouterWithRouterProps,
  ReduxState,
  RefPosition,
} from "types";

export const getScreenMedia = (state: ReduxState) => state.screenInfo.media;

export const getMeets = (state: ReduxState) => get(state, "meets");
export const getMeetMetaData = (state: ReduxState) =>
  get(state, "meetMetaData");

export const getCurrentMeetId = (
  state: ReduxState,
  props: ReactRouterWithRouterProps
) => props.match.params.meetId;

const fallbackMeet: Meet = {
  _id: "DEFAULT",
  lifters: {},
  divisions: {},
  platforms: {},
  calculatedData: {},
  entryConfig: { _id: "DEFAULT", items: [] },
  restricted: { _id: "DEFAULT" },
};

export const getCurrentMeet = createSelector(
  [getCurrentMeetId, getMeets],
  (meetId, meets) => {
    return get(meets, meetId, fallbackMeet);
  }
);

export const getFederationConfig = createSelector([getCurrentMeet], (meet) => {
  const federationKey = get(meet, ["federation"], "");
  return get(federationConfigs, federationKey, get(federationConfigs, "OTHER"));
});

export const getIsLoading = (
  state: ReduxState,
  props: ReactRouterWithRouterProps
) =>
  get(
    getMeetMetaData(state),
    [getCurrentMeetId(state, props), "isLoading"],
    true
  );
export const getIsSyncing = (
  state: ReduxState,
  props: ReactRouterWithRouterProps
) =>
  get(
    getMeetMetaData(state),
    [getCurrentMeetId(state, props), "isSyncing"],
    false
  );
export const getIsSyncActive = (
  state: ReduxState,
  props: ReactRouterWithRouterProps
) =>
  get(
    getMeetMetaData(state),
    [getCurrentMeetId(state, props), "isSyncActive"],
    false
  );
export const getIsLoggedIn = (
  state: ReduxState,
  props: ReactRouterWithRouterProps
) =>
  get(
    getMeetMetaData(state),
    [getCurrentMeetId(state, props), "isLoggedIn"],
    false
  );
export const getIsLocal = (
  state: ReduxState,
  props: ReactRouterWithRouterProps
) =>
  get(
    getMeetMetaData(state),
    [getCurrentMeetId(state, props), "isLocal"],
    false
  );
export const getIsOnline = (
  state: ReduxState,
  props: ReactRouterWithRouterProps
) =>
  get(
    getMeetMetaData(state),
    [getCurrentMeetId(state, props), "isOnline"],
    false
  );
export const getNotFound = (
  state: ReduxState,
  props: ReactRouterWithRouterProps
) =>
  get(
    getMeetMetaData(state),
    [getCurrentMeetId(state, props), "notFound"],
    false
  );

export const getConflicts = (
  state: ReduxState,
  props: ReactRouterWithRouterProps
) =>
  get(
    getMeetMetaData(state),
    [getCurrentMeetId(state, props), "conflicts"],
    true
  );

export const getLifter = (
  state: ReduxState,
  props: ReactRouterWithRouterProps
) => {
  return get(state, [
    "meets",
    getCurrentMeetId(state, props),
    "lifters",
    props.match.params.lifterId,
  ]);
};

export const getDivision = (
  state: ReduxState,
  props: ReactRouterWithRouterProps
) => {
  return get(state, [
    "meets",
    getCurrentMeetId(state, props),
    "divisions",
    props.match.params.divisionId,
  ]);
};

export const getWeightClass = (
  state: ReduxState,
  props: ReactRouterWithRouterProps
) => {
  return get(state, [
    "meets",
    getCurrentMeetId(state, props),
    "divisions",
    props.match.params.divisionId,
    "weightClasses",
    props.match.params.weightClassId,
  ]);
};

export const getCurrentPlatformId = (
  state: ReduxState,
  props: ReactRouterWithRouterProps
) => get(props, ["match", "params", "platformId"], props.platformId);

export const getCurrentPlatform = createSelector(
  [getCurrentMeet, getCurrentPlatformId],
  (meet, currentPlatformId) => {
    if (!currentPlatformId) {
      return {};
    }
    return get(meet, ["platforms", currentPlatformId], {});
  }
);

export const getMeetLifters = createSelector([getCurrentMeet], (meet) => {
  return get(meet, "lifters", {}) as Meet["lifters"];
});

export const getCurrentAttemptId = createSelector(
  [getCurrentPlatform],
  (platform) => {
    return get(platform, "currentAttemptId");
  }
);

export const getCurrentAttempt = createSelector(
  [getMeetLifters, getCurrentAttemptId],
  (lifters, currentAttemptId) => {
    let currentAttempt:
      | (Attempt & { session?: number; flight?: string })
      | null = null;
    // TODO, should just need to loop through platform lifters
    each(lifters, (lifter) => {
      if (currentAttempt) {
        return;
      }
      each(lifter.lifts, (lift) => {
        each(lift, (attempt) => {
          if (attempt && attempt._id === currentAttemptId) {
            currentAttempt = {
              ...attempt,
              // TODO: probably need to augment records here
              session: lifter.session,
              flight: lifter.flight,
            };
          }
        });
      });
    });

    return (currentAttempt ?? {}) as
      | (Attempt & { session?: number; flight?: string })
      | {};
  }
);

export const getPlatformLifters = createSelector(
  [getMeetLifters, getCurrentPlatformId],
  (meetLifters, currentPlatformId) => {
    return filter(meetLifters, { platformId: currentPlatformId });
  }
);

export const getCurrentLifter = createSelector(
  [getPlatformLifters, getCurrentAttempt],
  (platformLifters, currentAttempt) => {
    return find(platformLifters, {
      _id: (currentAttempt as any).lifterId,
    });
  }
);

export const getCompleteLiftingOrder = createSelector(
  [getCurrentMeet, getPlatformLifters],
  (meet, platformLifters) => {
    let liftingOrder: LiftingOrder = [];
    each(platformLifters, (lifter) => {
      let liftOrder = 0;
      each(["squat", "bench", "dead"] as const, (liftName) => {
        liftOrder++;
        if (isCompetingInLift(lifter, liftName, meet as Meet)) {
          each(get(lifter, ["lifts", liftName]), (attempt) => {
            // we are collecting all attempts that have not been completed yet and are not marked with a 0 to skip
            // const attempt = get(lifter, ["lifts", liftName, attemptNumber]);

            if (
              attempt &&
              attempt.weight !== 0 &&
              lifter.session &&
              lifter.flight
            ) {
              const info = {
                lifter: lifter,
                lot: lifter.lot,
                flight: lifter.flight as string,
                session: lifter.session as number,
                liftOrder: liftOrder as 1 | 2 | 3,
                attemptNumber: attempt.attemptNumber as AttemptNumber,
                weight: attempt.weight as number,
                attemptId: attempt._id,
                attempt: attempt,
                endOfRound: attempt.endOfRound ? (1 as const) : (0 as const),
              };

              liftingOrder.push(info);
            }
          });
        }
      });
    });

    liftingOrder = sortBy(liftingOrder, [
      "session",
      "liftOrder",
      "flight",
      "attemptNumber",
      "endOfRound",
      "weight",
      "lot",
    ]);
    return liftingOrder;
  }
);

// similar to getKnownLiftingOrder but this one includes future lifts that haven't been entered yet
export const getFutureLiftingOrder = createSelector(
  [getCurrentAttemptId, getCompleteLiftingOrder],
  (currentAttemptId, liftingOrder) => {
    const currentIndex = findIndex(
      liftingOrder,
      (a) => a.attemptId === currentAttemptId
    );
    return filter(liftingOrder, (row, i) => {
      const blankPastAttempt = i < currentIndex && !row.attempt.weight;
      return !blankPastAttempt && !row.attempt.result;
    });
  }
);

// Here we want just attempts that have been filled out. Assume 0 or blank means skip.
export const getKnownLiftingOrder = createSelector(
  [getCompleteLiftingOrder],
  (liftingOrder) => {
    return filter(liftingOrder, (row) => row.weight && !row.attempt.result);
  }
);

export const getRecordAttempts = createSelector([getCurrentMeet], (meet) => {
  const recordAttempts: Record<string, EligibleRecordWithWeight[]> = {};
  if (!meet.records) {
    return recordAttempts;
  }

  return meet.calculatedData.recordAttempts;
});

export const getRunningAttemptInputClocks = createSelector(
  [getCompleteLiftingOrder],
  (liftingOrder) => {
    return liftingOrder.filter(
      (row) => row.attempt.startTime && !row.attempt.weight
    );
  }
);

export const getScoreBoardTableData = createSelector(
  [getPlatformLifters, getCurrentAttempt, getKnownLiftingOrder],
  (platformLifters, currentAttempt, knownLiftingOrder) => {
    platformLifters = orderBy(platformLifters, [
      "session",
      "flight",
      (lifter) => {
        return get(lifter, [
          "lifts",
          (currentAttempt as any).liftName,
          (currentAttempt as any).attemptNumber,
          "endOfRound",
        ])
          ? 1
          : 0;
      },
      (lifter) => {
        // for lifters in the current flight sort by weight of current attempt.
        if (
          lifter.session === (currentAttempt as any).session &&
          lifter.flight === (currentAttempt as any).flight
        ) {
          return get(lifter, [
            "lifts",
            (currentAttempt as any).liftName,
            (currentAttempt as any).attemptNumber,
            "weight",
          ]);
        } else {
          // for lifters not in current flight sort by lifting order.
          return findIndex(
            knownLiftingOrder,
            (attempt) => get(attempt, ["lifter", "_id"]) === lifter._id
          );
        }
      },
      "lot",
    ]);

    let lastFlight: string | undefined = "";
    let lastSession: number | undefined = 0;
    const order: any = [{ row: "header" }];
    each(platformLifters, (row) => {
      if (lastSession !== row.session || lastFlight !== row.flight) {
        order.push({
          row: "title",
          name: ` — Session ${row.session ?? "MISSING"} - Flight ${
            row.flight ?? "MISSING"
          }`,
        });
      }

      order.push(row);
      lastFlight = row.flight;
      lastSession = row.session;
    });

    return order;
  }
);

export const getLeftRefDoc = createSelector(
  [getCurrentMeet, getCurrentPlatformId],
  (meet, currentPlatformId) => {
    return get(meet, [
      "platforms",
      currentPlatformId as string,
      "refs",
      "left",
    ]);
  }
);

export const getHeadRefDoc = createSelector(
  [getCurrentMeet, getCurrentPlatformId],
  (meet, currentPlatformId) => {
    return get(meet, [
      "platforms",
      currentPlatformId as string,
      "refs",
      "head",
    ]);
  }
);

export const getRightRefDoc = createSelector(
  [getCurrentMeet, getCurrentPlatformId],
  (meet, currentPlatformId) => {
    return get(meet, [
      "platforms",
      currentPlatformId as string,
      "refs",
      "right",
    ]);
  }
);

export const getCurrentRefPosition = (
  state: ReduxState,
  props: ReactRouterWithRouterProps
) => get(props, ["match", "params", "position"]);

export const getCurrentRefDoc = createSelector(
  [getCurrentMeet, getCurrentPlatformId, getCurrentRefPosition],
  (meet, currentPlatformId, position) => {
    if (!position) {
      return;
    }
    return get(meet, [
      "platforms",
      currentPlatformId as string,
      "refs",
      position as RefPosition,
    ]);
  }
);
