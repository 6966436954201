import React from "react";
import { ArrowContainer, Popover } from "react-tiny-popover";
import "./DropdownMenu.scss";
import CheckIcon from "icons/CheckIcon";

type DropdownMenuProps = {
  items: {
    key?: string;
    label: React.ReactNode;
    onClick?: () => void;
    checked?: boolean;
  }[];
};

const DropdownMenu = ({ items }: DropdownMenuProps) => {
  const [open, setOpen] = React.useState(false);

  const firstButtonRef = React.useRef<HTMLButtonElement>(null);
  const toggleRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (open) {
      firstButtonRef.current?.focus();
    }
  }, [open]);

  const close = React.useCallback(() => {
    toggleRef.current?.focus();
    setOpen(false);
  }, []);

  return (
    <Popover
      ref={toggleRef}
      isOpen={open}
      padding={-12}
      positions={["bottom"]}
      onClickOutside={close}
      containerClassName="dropdown-menu-popover"
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={"white"}
          arrowSize={20}
          arrowStyle={{}}
          arrowClassName="dropdown-menu-popover-arrow"
        >
          <ul className="dropdown-menu-ul">
            <li key="close-top">
              <button
                className="dropdown-menu-close-top"
                type="button"
                onClick={close}
                ref={firstButtonRef}
                aria-label="close"
              ></button>
            </li>
            {items.map(({ label, onClick, checked, key }, index) => {
              if (!onClick) {
                return (
                  <li
                    key={typeof label === "string" ? label : key ?? index}
                    role="separator"
                    className="separator"
                  >
                    {label}
                  </li>
                );
              }
              return (
                <li key={typeof label === "string" ? label : key ?? index}>
                  <button
                    className="menu-check-button"
                    type="button"
                    onClick={() => {
                      onClick();
                      close();
                    }}
                  >
                    {checked && <CheckIcon />}
                    {label}
                  </button>
                </li>
              );
            })}
            <li key="close-bottom">
              <button
                className="dropdown-menu-close-bottom"
                type="button"
                onClick={close}
                aria-label="close"
              >
                CLOSE
              </button>
            </li>
          </ul>
        </ArrowContainer>
      )}
    >
      <button
        className="dropdown-menu-toggle"
        onClick={() => setOpen((prev) => !prev)}
      >
        •••
      </button>
    </Popover>
  );
};

export default DropdownMenu;
