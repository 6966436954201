import React from "react";
import Select from "components/select/Select";
import classNames from "classnames";
import { toastr } from "react-redux-toastr";
import DateInput from "components/dateInput/DateInput";
import { errorReporter } from "util/errorReporter";
import { fetchWrapper } from "util/api";
import { getDateFormat } from "util/meetHelper";
import "./RackHeightsIndex.scss";
import { useMeet } from "util/useMeet";
import isEmpty from "lodash/isEmpty";

type RackHeightsIndexState = {
  complete: boolean;
  processing: boolean;
  apiError: string;
  memberNumber: string;
  birthDate: string;
  squatRackHeight: string;
  squatRackInOut: string;
  benchRackHeight: string;
  benchRackSafety: string;
  benchRackFootBlocks: string;
  errors: Record<string, string>;
};

const getInitialState = (): RackHeightsIndexState => {
  return {
    complete: false,
    processing: false,
    apiError: "",
    memberNumber: "",
    birthDate: "",
    squatRackHeight: "",
    squatRackInOut: "Out",
    benchRackHeight: "",
    benchRackSafety: "",
    benchRackFootBlocks: "NONE",
    errors: {},
  };
};

const RackHeightsIndex = () => {
  const meet = useMeet();
  const [state, setState] = React.useState<RackHeightsIndexState>(
    getInitialState()
  );

  const onClickReset = () => {
    setState(getInitialState());
  };

  const handleChange = (name: string, value: any) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const onSubmit = () => {
    setState((prev) => ({ ...prev, processing: true }));
    window.scrollTo(0, 0);

    const body = {
      memberNumber: state.memberNumber,
      birthDate: state.birthDate,
      squatRackHeight: state.squatRackHeight,
      squatRackInOut: state.squatRackInOut,
      benchRackHeight: state.benchRackHeight,
      benchRackSafety: state.benchRackSafety,
      benchRackFootBlocks: state.benchRackFootBlocks,
    };

    fetchWrapper(`/api/meets/${meet._id}/rack_heights`, "POST", body)
      .then((response) => {
        if (response.ok) {
          setState((prev) => ({
            ...prev,
            processing: false,
            complete: true,
            apiError: "",
          }));
        } else {
          setState((prev) => ({
            ...prev,
            processing: false,
            apiError: response.error,
          }));
        }
      })
      .catch((error) => {
        errorReporter({ error });
        setState((prev) => ({ ...prev, processing: false, apiError: error }));
      });
  };

  const isFormValid = () => {
    const errors: Record<string, string> = {};
    if (!state.memberNumber) {
      errors.memberNumber = "memberNumber is required";
    }

    if (!state.birthDate) {
      errors.birthDate = "birthDate is required";
    }

    if (!state.squatRackHeight) {
      errors.squatRackHeight = "squatRackHeight is required";
    }

    if (!state.benchRackHeight) {
      errors.benchRackHeight = "benchRackHeight is required";
    }

    if (!state.benchRackSafety) {
      errors.benchRackSafety = "benchRackSafety is required";
    }

    setState((prev) => ({ ...prev, errors }));
    if (isEmpty(errors)) {
      return true;
    }

    toastr.error("Please fix issues", "");
    return false;
  };

  const renderMeetInfo = () => {
    return (
      <div className="meet-info">
        <div>Enter your rack heights for</div>
        <div>{meet.name}</div>
      </div>
    );
  };

  const squatRackInOutOptions = () => {
    return [
      { label: "In", value: "In" },
      { label: "Out", value: "Out" },
      { label: "Left In", value: "Left In" },
      { label: "Right In", value: "Right In" },
    ];
  };

  const benchRackFootBlocksOptions = () => {
    return [
      { label: "None", value: "NONE" },
      { label: "5cm", value: "5cm" },
      { label: "10cm", value: "10cm" },
      { label: "20cm", value: "20cm" },
      { label: "30cm", value: "30cm" },
    ];
  };

  if (!meet.lifterRackHeightsEnabled) {
    window.scrollTo(0, 0);
    return (
      <div className="rack-heights-index">
        <div className="message">
          Rack height entry not enabled for this meet.
        </div>
      </div>
    );
  }

  if (state.complete) {
    window.scrollTo(0, 0);
    return (
      <div className="rack-heights-index">
        <div className="message">Rack Heights Accepted</div>
        <button className="submit-button" onClick={onClickReset}>
          RESET FORM
        </button>
      </div>
    );
  }

  return (
    <div className="rack-heights-index">
      {state.processing && (
        <div>
          <div className="processing-overlay" />
          <div className="processing-spinner">
            <div className="loader" />
          </div>
        </div>
      )}
      {state.apiError && (
        <div className="api-error">
          <div>There was an error processing your request.</div>
          <div>{state.apiError}</div>
        </div>
      )}

      {renderMeetInfo()}

      <div className="entry-row">
        <label>Membership Number *</label>
        <div className="input-wrapper">
          <input
            type="text"
            className={classNames({ error: state.errors.memberNumber })}
            value={state.memberNumber}
            onChange={(e) => handleChange("memberNumber", e.target.value)}
          />
          <div className="error-message">{state.errors.memberNumber}</div>
        </div>
      </div>
      <div className="entry-row">
        <label>Birth Date *</label>
        <div className="input-wrapper">
          <DateInput
            dateFormat={getDateFormat(meet)}
            className={classNames({ error: state.errors.birthDate })}
            value={state.birthDate}
            onChange={(value) => handleChange("birthDate", value)}
            placeholder={getDateFormat(meet)}
          />
          <div className="error-message">{state.errors.birthDate}</div>
        </div>
      </div>
      <div className="entry-row">
        <label>Squat Rack Height *</label>
        <div className="input-wrapper">
          <input
            type="text"
            className={classNames({
              error: state.errors.squatRackHeight,
            })}
            value={state.squatRackHeight}
            onChange={(e) => handleChange("squatRackHeight", e.target.value)}
          />
          <div className="error-message">{state.errors.squatRackHeight}</div>
        </div>
      </div>
      <div className="entry-row">
        <label>Squat Rack In Out *</label>
        <div className="input-wrapper">
          <Select
            name="squatRackInOut"
            className={classNames({
              error: state.errors.squatRackInOut,
            })}
            value={state.squatRackInOut}
            options={squatRackInOutOptions()}
            onChange={(value) => handleChange("squatRackInOut", value)}
            clearable={false}
          />
          <div className="error-message">{state.errors.squatRackInOut}</div>
        </div>
      </div>
      <div className="entry-row">
        <label>Bench Rack Height *</label>
        <div className="input-wrapper">
          <input
            type="text"
            className={classNames({
              error: state.errors.benchRackHeight,
            })}
            value={state.benchRackHeight}
            onChange={(e) => handleChange("benchRackHeight", e.target.value)}
          />
          <div className="error-message">{state.errors.benchRackHeight}</div>
        </div>
      </div>
      <div className="entry-row">
        <label>Bench Rack Safety *</label>
        <div className="input-wrapper">
          <input
            type="text"
            className={classNames({
              error: state.errors.benchRackSafety,
            })}
            value={state.benchRackSafety}
            onChange={(e) => handleChange("benchRackSafety", e.target.value)}
          />
          <div className="error-message">{state.errors.benchRackSafety}</div>
        </div>
      </div>
      <div className="entry-row">
        <label>Bench Rack Foot Blocks *</label>
        <div className="input-wrapper">
          <Select
            name="benchRackFootBlocks"
            className={classNames({
              error: state.errors.benchRackFootBlocks,
            })}
            value={state.benchRackFootBlocks}
            options={benchRackFootBlocksOptions()}
            onChange={(value) => handleChange("benchRackFootBlocks", value)}
            clearable={false}
          />
          <div className="error-message">
            {state.errors.benchRackFootBlocks}
          </div>
        </div>
      </div>

      <button
        className="submit-button"
        onClick={() => isFormValid() && onSubmit()}
      >
        SUBMIT
      </button>

      {state.apiError && (
        <div className="api-error">
          <div>There was an error processing your request.</div>
          <div>{state.apiError}</div>
        </div>
      )}
    </div>
  );
};

export default RackHeightsIndex;
