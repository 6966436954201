import React, { Component } from 'react';

import LightsComponent from 'components/lights/Lights';

import DisplayComponent from './DisplayComponent';

class Lights extends Component {

  render() {
    const { platformId, meet, id } = this.props;

    return (
      <DisplayComponent style={{top: 90, left: 580}} id={id}>
        {({ sizeMultiplier }) => {

          const style = {
            height: 80 * sizeMultiplier
          };

          return (
            <LightsComponent meet={meet} platformId={platformId} style={style} />
          );
        }}
      </DisplayComponent>
    );
  }
}

export default Lights;
