import React from "react";
import { errorReporter } from "util/errorReporter";
import { fetchWrapper } from "util/api";
import FixedHeightWrapper from "app/meets/FixedHeightWrapper";
import MembershipCheckerTable from "./MembershipCheckerTable";
import { reformatDateToUsFormat } from "util/dateHelper";
import "./MembershipCheckerIndex.scss";
import { Lifter } from "types";
import { useMeet } from "util/useMeet";
import { useMediaSize } from "util/useMediaSize";
import each from "lodash/each";
import map from "lodash/map";
import get from "lodash/get";

type LifterStatus = {
  membershipStatus?: string;
  nameMatch?: boolean;
  nameSuggestion?: string;
  stateMatch?: boolean;
  birthDateMatch?: boolean;
};

const MembershipCheckerIndex = () => {
  const [inProgress, setInProgress] = React.useState(false);
  const [liftersMeta, setLiftersMeta] = React.useState<
    Record<string, LifterStatus>
  >({});

  const meet = useMeet();
  const media = useMediaSize();

  const checkLifter = (lifter: Lifter) => {
    const memberNumber = lifter.restricted?.memberNumber;
    const name = lifter.name;
    const birthDate = lifter.birthDate;
    const state = lifter.state;
    const formattedBirthDate = birthDate
      ? reformatDateToUsFormat({
          dateString: birthDate,
          meet: meet,
        })
      : "";
    const url = `/api/check_membership?member_number=${memberNumber}&state=${state}&name=${name}&birth_date=${formattedBirthDate}&federation=${meet.federation}`;
    return fetchWrapper(url, "GET")
      .then((response) => {
        let lifterStatus: LifterStatus = {};
        if (response.error) {
          lifterStatus.membershipStatus = response.error;
        } else {
          lifterStatus = {
            membershipStatus: response.member_status,
            nameMatch: response.name_match,
            nameSuggestion: response.name_suggestion,
            stateMatch: response.state_match,
            birthDateMatch: response.birth_date_match,
          };
        }
        setLiftersMeta((prev) => ({
          ...prev,
          [lifter._id]: lifterStatus,
        }));
      })
      .catch((error) => {
        errorReporter({ error });
        setLiftersMeta((prev) => ({
          ...prev,
          [lifter._id]: { membershipStatus: "error" },
        }));
      });
  };

  const checkLifters = () => {
    setInProgress(true);
    setLiftersMeta({});
    let promise = Promise.resolve();
    each(meet.lifters, (lifter) => {
      promise = promise.then(() => checkLifter(lifter));
    });

    promise.then(() => setInProgress(false));
  };

  const lifters = map(meet.lifters, (lifter) => {
    return {
      ...lifter,
      membershipStatus: get(liftersMeta, [lifter._id, "membershipStatus"]),
      nameMatch: get(liftersMeta, [lifter._id, "nameMatch"]),
      nameSuggestion: get(liftersMeta, [lifter._id, "nameSuggestion"]),
      stateMatch: get(liftersMeta, [lifter._id, "stateMatch"]),
      birthDateMatch: get(liftersMeta, [lifter._id, "birthDateMatch"]),
    };
  });

  return (
    <FixedHeightWrapper>
      <div className="membership-checker-index">
        <div className="util-row-wrapper">
          <div className="util-row">
            <button disabled={inProgress} onClick={checkLifters}>
              Check Membership Info
            </button>
            {inProgress && <div>&nbsp; &nbsp; Checking...</div>}
          </div>
        </div>
        <div className="table-wrapper">
          <MembershipCheckerTable meet={meet} lifters={lifters} media={media} />
        </div>
      </div>
    </FixedHeightWrapper>
  );
};

export default MembershipCheckerIndex;
