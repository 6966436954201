import React, { Component } from "react";
import { connect } from "react-redux";

import Lights from "./Lights";
import LiftDetails from "./LiftDetails";
import Timer from "./Timer";
import LoadingChart from "./LoadingChart";
import NextLoad from "./NextLoad";
import OrderTable from "./OrderTable";
import PositionChange from "./PositionChange";
import "./DisplayIndex.scss";

import {
  getCurrentMeet,
  getCurrentPlatform,
  getCurrentAttempt,
  getKnownLiftingOrder,
  getCompleteLiftingOrder,
  getCurrentLifter,
  getFederationConfig,
} from "selectors";

class DisplayIndex extends Component {
  componentDidMount() {
    const { meet } = this.props;

    if (this.props.meet.displayDefaultURL && !window.location.search) {
      window.location.search = this.props.meet.displayDefaultURL;
    }

    let colorStyle = "";
    let backgroundStyle = "";
    let backgroundColor = "";

    if (meet.displayFontColor) {
      colorStyle = `color: ${meet.displayFontColor};`;
    }

    if (meet.displayBackgroundColor) {
      backgroundColor = `background-color: ${meet.displayBackgroundColor};`;
    }

    if (meet.displayBackgroundImageUrl) {
      backgroundStyle = meet.displayBackgroundImageUrl
        ? `background: url(${meet.displayBackgroundImageUrl}) no-repeat fixed; background-size: cover;`
        : "";
    }

    const styleString = `${colorStyle}${backgroundStyle}${backgroundColor}`;
    document.body.setAttribute("style", styleString);
  }

  componentWillUnmount() {
    document.body.setAttribute("style", "");
  }

  render() {
    const {
      meet,
      currentAttempt,
      platform,
      knownLiftingOrder,
      completeLiftingOrder,
      currentLifter,
    } = this.props;
    if (!platform || !meet) {
      return null;
    }

    return (
      <div className="display-index">
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <OrderTable
            meet={meet}
            platform={platform}
            completeLiftingOrder={completeLiftingOrder}
            id="o"
            federationConfig={this.props.federationConfig}
          />
          <LiftDetails
            meet={meet}
            currentLifter={currentLifter}
            currentAttempt={currentAttempt}
            id="d"
          />
          <Timer platform={platform} id="time" />
          <LoadingChart
            meet={meet}
            currentLifter={currentLifter}
            currentAttempt={currentAttempt}
            platform={platform}
            id="c"
          />
          <NextLoad
            meet={meet}
            currentAttempt={currentAttempt}
            liftingOrder={knownLiftingOrder}
            platform={platform}
            id="n"
          />
          <PositionChange
            meet={meet}
            currentAttempt={currentAttempt}
            currentLifter={currentLifter}
            id="p"
          />
          <Lights meet={meet} platformId={platform._id} id="l" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    meet: getCurrentMeet(state, props),
    platform: getCurrentPlatform(state, props),
    knownLiftingOrder: getKnownLiftingOrder(state, props),
    completeLiftingOrder: getCompleteLiftingOrder(state, props),
    currentAttempt: getCurrentAttempt(state, props),
    currentLifter: getCurrentLifter(state, props),
    federationConfig: getFederationConfig(state, props),
  };
};

const actions = {};

export default connect(mapStateToProps, actions)(DisplayIndex);
