import get from "lodash/get";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import toNumber from "lodash/toNumber";
import React from "react";

const WeightClassCell = ({
  data,
  column,
  style,
}: {
  data: any;
  column: any;
  style: any;
}) => {
  const division = data;
  if (division.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    return (
      <div style={style} className="table-cell-inner">
        {map(
          sortBy(division.weightClasses, (weightClass) =>
            toNumber(weightClass.maxWeight)
          ),
          (weightClass, index) => {
            return (
              <div key={index} className="ellipsis cell-row">
                {get(division, ["weightClasses", weightClass._id, column.key])}
              </div>
            );
          }
        )}
      </div>
    );
  }
};

export default WeightClassCell;
