import get from "lodash/get";
import { ScoreBy } from "types";

const scoreByLabels: Record<ScoreBy, string> = {
  BODY_WEIGHT_POINTS: "Wilks",
  AGE_POINTS: "Age Pts",
  BODY_WEIGHT_AND_AGE_POINTS: "Wilks/Age Pts",
  IPF_POINTS: "IPF Pts",
  IPF_AND_AGE_POINTS: "IPF/Age Pts",
  DOTS_POINTS: "DOTS Pts",
  DOTS_AND_AGE_POINTS: "DOTS/Age Pts",
  SCHWARTZ_MALONE: "S/M Pts",
  SCHWARTZ_MALONE_AND_AGE_POINTS: "S/M/Age Pts",
  GLOSSBRENNER_POINTS: "Glossbrenner",
  GLOSSBRENNER_AND_AGE_POINTS: "Gloss/Age Pts",
  RESHEL_POINTS: "Reshel",
  RESHEL_POINTS_AND_AGE_POINTS: "Reshel/Age Pts",
  AH_POINTS: "AH",
  PARA_DOTS_POINTS: "paraDOTS",
  K_POINTS: "K-points",
  PERCENT_OF_RECORD: "% Score",
  TOTAL: "Total",
};

export const getScoreByLabel = (scoreBy: ScoreBy) => {
  return get(scoreByLabels, scoreBy, "ERROR");
};
