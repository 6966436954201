import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { getCurrentMeet } from "selectors";
import { ReduxState } from "types";

export const useMeet = () => {
  const { meetId } = useParams<{ meetId: string }>();

  const props = React.useMemo(() => {
    return { match: { params: { meetId } } };
  }, [meetId]);

  const meet = useSelector((state: ReduxState) => getCurrentMeet(state, props));

  return meet;
};
