import React from "react";
import {
  addWeightClass,
  deleteWeightClass,
  addWeightClassDeprecated,
  deleteWeightClassDeprecated,
} from "util/pouchActions";
import AddIcon from "icons/AddIcon";
import DeleteIcon from "icons/DeleteIcon";
import { Division, Meet, WeightClass } from "types";
import { Column } from "components/table/Table";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import toNumber from "lodash/toNumber";

const add = (division: Division, meet: Meet) => {
  if (division.schema) {
    addWeightClass(division._id, meet._id);
  } else {
    addWeightClassDeprecated(division._id, meet._id);
  }
};

const del = (weightClass: WeightClass, division: Division, meet: Meet) => {
  if (division.schema) {
    deleteWeightClass(weightClass._id, division, meet._id);
  } else {
    deleteWeightClassDeprecated(weightClass, meet._id);
  }
};

const AddWeightClassButtonCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: Column;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const division = data;
  if (division.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    return (
      <div style={style} className="table-cell-inner button-cell">
        {map(
          sortBy(division.weightClasses, (weightClass) =>
            toNumber(weightClass.maxWeight)
          ),
          (weightClass, index) => {
            return (
              <div key={index} className="inner-row">
                {index === 0 && (
                  <button onClick={() => add(division, meet)}>
                    <AddIcon />
                  </button>
                )}
                {index !== 0 && (
                  <button onClick={() => del(weightClass, division, meet)}>
                    <DeleteIcon />
                  </button>
                )}
              </div>
            );
          }
        )}
      </div>
    );
  }
};

export default AddWeightClassButtonCell;
