import React, { Component } from "react";
import Table, { Column } from "components/table/Table";
import { MediaSize, Meet } from "types";
import reject from "lodash/reject";
import orderBy from "lodash/orderBy";
import map from "lodash/map";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import each from "lodash/each";
import take from "lodash/take";

type SortableTableProps = {
  columns: Column[];
  data: any;
  getCellLines: (index: number, data: any) => number;
  meet: Meet;
  numberOfFixedLeftColumns: number;
  media: MediaSize;
};

class SortableTable extends Component<
  SortableTableProps,
  { columns: Column[] }
> {
  constructor(props: SortableTableProps) {
    super(props);
    this.state = {
      columns: this.props.columns,
    };
  }

  createDataArray = (data: any, columns: Column[]) => {
    columns = reject(columns, (column) => !column.sortable);
    columns = orderBy(columns, ["sortOrder"], ["asc"]);
    const sortColumns = map(
      columns,
      (column) => column.sortFunction || column.key
    );
    const sortDirections = map(
      columns,
      (column) => column.sortDirection ?? "asc"
    );

    // regardless of sort order bubble up completely blank rows to the top
    sortColumns.unshift((item) => {
      if (item.name) {
        return 0;
      }
      return 1;
    });
    sortDirections.unshift("desc");

    // only consider first 5 columns in sorting for performance (6 column is the blank sort above)
    data = orderBy(data, take(sortColumns, 6), take(sortDirections, 6));
    data.unshift({ row: "header" });

    return data;
  };

  onHeaderClick = (column: Column) => {
    const columns = cloneDeep(this.state.columns);
    const sortableColumns = reject(columns, (column) => !column.sortable);
    const currentSortedColumn = find(sortableColumns, { sortOrder: 1 }) as
      | Column
      | undefined;
    if (currentSortedColumn?.key === column.key) {
      // reverse sort
      currentSortedColumn.sortDirection =
        currentSortedColumn.sortDirection === "asc" ? "desc" : "asc";
    } else {
      const newSortedColumn = find(columns, { key: column.key }) as
        | Column
        | undefined;
      if (newSortedColumn) {
        newSortedColumn.sortOrder = 0;
      }
      each(orderBy(columns, ["sortOrder"], ["asc"]), (column, index) => {
        column.sortOrder = index + 1;
      });
    }

    this.setState({ columns });
  };

  render() {
    const data = this.createDataArray(this.props.data, this.state.columns);
    const getCellLines = (index: number) =>
      this.props.getCellLines(index, data);
    return (
      <Table
        data={data}
        columns={this.state.columns}
        getCellLines={getCellLines}
        meet={this.props.meet}
        onHeaderClick={this.onHeaderClick}
        numberOfFixedLeftColumns={this.props.numberOfFixedLeftColumns}
        media={this.props.media}
        padBottom
      />
    );
  }
}

export default SortableTable;
