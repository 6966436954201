import React from "react";
import { NavLink, Route, Switch, Redirect } from "react-router-dom";
import FixedHeightWrapper from "app/meets/FixedHeightWrapper";
import LifterInfo from "./LifterInfo";
import AttemptTable from "./AttemptTable";
import LifterOrderTable from "./LifterOrderTable";
import LifterResults from "./LifterResults";
import EligibleRecords from "./EligibleRecords";
import "./LifterIndex.scss";
import { useLifter } from "util/useLifter";
import { useMeet } from "util/useMeet";

const LifterIndex = () => {
  const lifter = useLifter();
  const meet = useMeet();

  if (!lifter || !meet) {
    return null;
  }

  const meetId = meet._id;
  const lifterId = lifter._id;
  const baseUrl = `/meets/${meetId}/lifter/${lifterId}`;

  return (
    <FixedHeightWrapper>
      <div className="lifter-index">
        <div className="tabs">
          <NavLink
            to={`${baseUrl}/info`}
            className="tab"
            activeClassName="active"
          >
            Info
          </NavLink>
          <NavLink
            to={`${baseUrl}/order`}
            className="tab"
            activeClassName="active"
          >
            Order
          </NavLink>
          <NavLink
            to={`${baseUrl}/attempts`}
            className="tab"
            activeClassName="active"
          >
            Attempts
          </NavLink>
          <NavLink
            to={`${baseUrl}/results`}
            className="tab"
            activeClassName="active"
          >
            Results
          </NavLink>
          {!!meet.records?.data && (
            <NavLink
              to={`${baseUrl}/records`}
              className="tab"
              activeClassName="active"
            >
              Records
            </NavLink>
          )}
        </div>

        <Switch>
          <Route
            path="/meets/:meetId/lifter/:lifterId/info"
            component={LifterInfo}
          />
          <Route
            path="/meets/:meetId/lifter/:lifterId/order"
            component={LifterOrderTable}
          />
          <Route
            path="/meets/:meetId/lifter/:lifterId/attempts"
            component={AttemptTable}
          />
          <Route
            path="/meets/:meetId/lifter/:lifterId/results"
            component={LifterResults}
          />
          <Route
            path="/meets/:meetId/lifter/:lifterId/records"
            component={EligibleRecords}
          />
          <Redirect
            from="/meets/:meetId/lifter/:lifterId"
            exact
            to={`${baseUrl}/info`}
          />
          <Redirect
            from="/meets/:meetId/lifter/:lifterId/"
            exact
            to={`${baseUrl}/info`}
          />
        </Switch>
      </div>
    </FixedHeightWrapper>
  );
};

export default LifterIndex;
