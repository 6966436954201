import get from "lodash/get";
import round from "lodash/round";

import { isLbsMeet, isKgMeet } from "./meetHelper";
import { toKg, toLbs } from "./conversions";
import { competitionCode } from "./standardDivisions";
import { getRealAge } from "./lifterHelper";
import { womenGlossCoef, menGlossCoef } from "./glossCoefTable";
import { Division, Lifter, Meet } from "types";
import { getPercentOfRecordEligibleRecord } from "./records";
import { reshelMen, reshelWomen } from "./reshelCoefTable";

export const percentOfRecord = function (
  total: number,
  division: Division | undefined,
  lifter: Lifter,
  meet: Meet
) {
  const records = meet.records;
  const equipmentLevel = division?.rawOrEquipped;
  const gender = lifter.gender;

  if (!records || !equipmentLevel || !gender || !total || !division) {
    return 0;
  }

  const record = getPercentOfRecordEligibleRecord({ division, lifter, meet });

  if (!record || !record.recordWeight) {
    return 0;
  }
  return (total / record.recordWeight) * 100;
};

export const wilksCoef = function (lifter: Lifter, meet: Meet) {
  let bodyWeight = lifter.bodyWeight;

  if (isLbsMeet(meet)) {
    bodyWeight = toKg(bodyWeight);
  }

  const gender = lifter.gender;

  if (!bodyWeight || !gender) {
    return 0;
  }

  if (gender === "MX") {
    return 0;
  }

  let a, b, c, d, e, f;
  if (gender === "MALE") {
    a = -216.0475144;
    b = 16.2606339;
    c = -0.002388645;
    d = -0.00113732;
    e = 0.00000701863;
    f = -0.00000001291;
  } else if (gender === "FEMALE") {
    a = 594.31747775582;
    b = -27.23842536447;
    c = 0.82112226871;
    d = -0.00930733913;
    e = 0.00004731582;
    f = -0.00000009054;
  } else {
    throw new Error("Invalid Gender in wilks coef");
  }

  return (
    500.0 /
    (a +
      b * bodyWeight +
      c * Math.pow(bodyWeight, 2) +
      d * Math.pow(bodyWeight, 3) +
      e * Math.pow(bodyWeight, 4) +
      f * Math.pow(bodyWeight, 5))
  );
};

const mccullochCoefTable = {
  41: 1.01,
  42: 1.02,
  43: 1.031,
  44: 1.043,
  45: 1.055,
  46: 1.068,
  47: 1.082,
  48: 1.097,
  49: 1.113,
  50: 1.13,
  51: 1.147,
  52: 1.165,
  53: 1.184,
  54: 1.204,
  55: 1.225,
  56: 1.246,
  57: 1.268,
  58: 1.291,
  59: 1.315,
  60: 1.34,
  61: 1.366,
  62: 1.393,
  63: 1.421,
  64: 1.45,
  65: 1.48,
  66: 1.511,
  67: 1.543,
  68: 1.576,
  69: 1.61,
  70: 1.645,
  71: 1.681,
  72: 1.718,
  73: 1.756,
  74: 1.795,
  75: 1.835,
  76: 1.876,
  77: 1.918,
  78: 1.961,
  79: 2.005,
  80: 2.05,
  81: 2.096,
  82: 2.143,
  83: 2.19,
  84: 2.238,
  85: 2.287,
  86: 2.337,
  87: 2.388,
  88: 2.44,
  89: 2.494,
  90: 2.549,
};

const mccullochCoef = function (age: number | null | undefined) {
  if (age) {
    // you don't get any extra points for being over 90
    // coef is always 1 for 40 or younger
    if (age > 90) {
      age = 90;
    }

    return get(mccullochCoefTable, age, 1);
  } else {
    return 1.0;
  }
};

const fosterCoefTable = {
  14: 1.23,
  15: 1.18,
  16: 1.13,
  17: 1.08,
  18: 1.06,
  19: 1.04,
  20: 1.03,
  21: 1.02,
  22: 1.01,
};

const fosterCoef = function (age: number | null | undefined) {
  if (age) {
    // you don't get any extra points for being under 14
    if (age < 14) {
      age = 14;
    }

    return get(fosterCoefTable, age, 1);
  } else {
    return 1;
  }
};

const usaplMastersCoefTable = {
  41: 1.003,
  42: 1.008,
  43: 1.014,
  44: 1.02,
  45: 1.026,
  46: 1.033,
  47: 1.04,
  48: 1.048,
  49: 1.057,
  50: 1.066,
  51: 1.075,
  52: 1.086,
  53: 1.096,
  54: 1.108,
  55: 1.12,
  56: 1.134,
  57: 1.147,
  58: 1.162,
  59: 1.178,
  60: 1.194,
  61: 1.211,
  62: 1.23,
  63: 1.249,
  64: 1.269,
  65: 1.29,
  66: 1.312,
  67: 1.335,
  68: 1.36,
  69: 1.385,
  70: 1.411,
  71: 1.439,
  72: 1.468,
  73: 1.498,
  74: 1.529,
  75: 1.562,
  76: 1.596,
  77: 1.631,
  78: 1.668,
  79: 1.706,
  80: 1.745,
  81: 1.786,
  82: 1.829,
  83: 1.873,
  84: 1.918,
  85: 1.965,
  86: 2.013,
  87: 2.064,
  88: 2.115,
  89: 2.169,
  90: 2.224,
  91: 2.281,
  92: 2.34,
  93: 2.4,
  94: 2.462,
  95: 2.526,
};

const usaplMastersCoef = function (age: number | null | undefined) {
  if (age) {
    // you don't get any extra points for being over 95
    // coef is always 1 for 40 or younger
    if (age > 95) {
      age = 95;
    }

    return get(usaplMastersCoefTable, age, 1);
  }

  return 1;
};

export const ageCoef = function (lifter: Lifter, meet: Meet) {
  const age = getRealAge(lifter, meet);

  if (meet.federation === "USAPL") {
    return usaplMastersCoef(age) * fosterCoef(age);
  }

  // one of these will always be 1
  return mccullochCoef(age) * fosterCoef(age);
};

const equipmentLevelConversionMap = {
  RAW: "RAW",
  CLASSIC_RAW: "RAW",
  RAW_WITH_WRAPS: "RAW",
  RAW_CLASSIC: "RAW",
  RAW_MODERN: "RAW",
  RAW_AMATEUR: "RAW",
  RAW_PRO: "RAW",
  EQUIPPED: "EQUIPPED",
  SINGLE_PLY: "EQUIPPED",
  MULTI_PLY: "EQUIPPED",
  UNLIMITED: "EQUIPPED",
  MULTIPLY: "EQUIPPED",
  EQUIPPED_AMATEUR: "EQUIPPED",
  EQUIPPED_PRO: "EQUIPPED",
};

export const ipfFormula = function (
  total: number,
  division: Division | undefined,
  lifter: Lifter,
  meet: Meet
) {
  // Lifters under 14 years of age technically are not valid for this forumla, but this check just confuses people.
  if (meet.federation === "CPU") {
    const lifterAge = getRealAge(lifter, meet);
    if (lifterAge && lifterAge < 14) {
      return 0;
    }
  }

  if (total <= 0 || !division) {
    return 0;
  }

  const parameters = {
    MALE: {
      RAW: {
        PL: { c1: 1199.72839, c2: 1025.18162, c3: 0.00921 },
        BP: { c1: 320.98041, c2: 281.40258, c3: 0.01008 },
      },
      EQUIPPED: {
        PL: { c1: 1236.25115, c2: 1449.21864, c3: 0.01644 },
        BP: { c1: 381.22073, c2: 733.79378, c3: 0.02398 },
      },
    },
    FEMALE: {
      RAW: {
        PL: { c1: 610.32796, c2: 1045.59282, c3: 0.03048 },
        BP: { c1: 142.40398, c2: 442.52671, c3: 0.04724 },
      },
      EQUIPPED: {
        PL: { c1: 758.63878, c2: 949.31382, c3: 0.02435 },
        BP: { c1: 221.82209, c2: 357.00377, c3: 0.02937 },
      },
    },
  };

  const compCode = competitionCode(division);

  let bodyWeight = lifter.bodyWeight;

  if (!bodyWeight) {
    return 0;
  }

  if (!lifter.gender) {
    return 0;
  }

  if (isLbsMeet(meet)) {
    bodyWeight = toKg(bodyWeight);
  }

  const equipmentLevel = equipmentLevelConversionMap[division.rawOrEquipped];
  if (!equipmentLevel) {
    return 0;
  }

  const divisionParameters = get(parameters, [
    lifter.gender,
    equipmentLevel,
    compCode,
  ]);
  if (!divisionParameters) {
    return 0;
  }

  const c1 = divisionParameters.c1;
  const c2 = divisionParameters.c2;
  const c3 = divisionParameters.c3;

  return Math.max(
    0,
    (total * 100.0) / (c1 - c2 * Math.exp(-1.0 * c3 * bodyWeight))
  );
};

export const dotsCoef = function (lifter: Lifter, meet: Meet) {
  const parameters = {
    MALE: {
      a: -0.000001093,
      b: 0.0007391293,
      c: -0.1918759221,
      d: 24.0900756,
      e: -307.75076,
    },
    FEMALE: {
      a: -0.0000010706,
      b: 0.0005158568,
      c: -0.1126655495,
      d: 13.6175032,
      e: -57.96288,
    },
  };

  let bw = lifter.bodyWeight;

  if (!bw) {
    return 0;
  }

  if (!lifter.gender) {
    return 0;
  }

  if (isLbsMeet(meet)) {
    bw = toKg(bw);
  }

  const p = get(parameters, lifter.gender);
  if (!p) {
    return 0;
  }

  return (
    500.0 /
    (p.a * Math.pow(bw, 4) +
      p.b * Math.pow(bw, 3) +
      p.c * Math.pow(bw, 2) +
      p.d * bw +
      p.e)
  );
};

export const dotsFormula = function (
  total: number,
  lifter: Lifter,
  meet: Meet
) {
  if (total <= 0) {
    return 0;
  }

  return total * dotsCoef(lifter, meet);
};

// Men:
// =weight*500/(-0.000000793954283*bodyWeight^4+0.000493457474*bodyWeight^3-0.1231642956*bodyWeight^2+16.0233664*bodyWeight+45.59224)
// Women:
// =weight*500/(-0.00000255986906*bodyWeight^4+0.00116119212*bodyWeight^3-0.205352889*bodyWeight^2+17.3690866*J100+55.4261)
export const paraDotsFormula = function (
  total: number,
  lifter: Lifter,
  meet: Meet
) {
  if (total <= 0) {
    return 0;
  }

  const parameters = {
    MALE: {
      a: -0.000000793954283,
      b: 0.000493457474,
      c: -0.1231642956,
      d: 16.0233664,
      e: 45.59224,
    },
    FEMALE: {
      a: -0.00000255986906,
      b: 0.00116119212,
      c: -0.205352889,
      d: 17.3690866,
      e: 55.4261,
    },
  };

  let bw = lifter.bodyWeight;

  if (!bw) {
    return 0;
  }

  if (!lifter.gender) {
    return 0;
  }

  if (isLbsMeet(meet)) {
    bw = toKg(bw);
  }

  const p = get(parameters, lifter.gender);
  if (!p) {
    return 0;
  }

  const dotsCoef =
    500.0 /
    (p.a * Math.pow(bw, 4) +
      p.b * Math.pow(bw, 3) +
      p.c * Math.pow(bw, 2) +
      p.d * bw +
      p.e);

  return total * dotsCoef;
};

const schwartzCoefTable = {
  90: 1.2803,
  91: 1.2627,
  92: 1.2455,
  93: 1.2287,
  94: 1.2124,
  95: 1.1965,
  96: 1.1809,
  97: 1.1657,
  98: 1.1509,
  99: 1.1365,
  100: 1.1223,
  101: 1.1086,
  102: 1.0952,
  103: 1.0821,
  104: 1.0693,
  105: 1.0569,
  106: 1.0448,
  107: 1.0329,
  108: 1.0214,
  109: 1.0101,
  110: 0.9991,
  111: 0.9884,
  112: 0.9779,
  113: 0.9677,
  114: 0.9578,
  115: 0.9481,
  116: 0.9385,
  117: 0.9293,
  118: 0.9203,
  119: 0.9115,
  120: 0.9029,
  121: 0.8946,
  122: 0.8863,
  123: 0.8783,
  124: 0.8706,
  125: 0.863,
  126: 0.8556,
  127: 0.8483,
  128: 0.8412,
  129: 0.8343,
  130: 0.8276,
  131: 0.821,
  132: 0.8146,
  133: 0.8083,
  134: 0.8022,
  135: 0.7961,
  136: 0.7903,
  137: 0.7846,
  138: 0.779,
  139: 0.7735,
  140: 0.7682,
  141: 0.763,
  142: 0.7579,
  143: 0.7528,
  144: 0.7479,
  145: 0.7432,
  146: 0.7385,
  147: 0.7339,
  148: 0.7294,
  149: 0.725,
  150: 0.7207,
  151: 0.7165,
  152: 0.7124,
  153: 0.7083,
  154: 0.7044,
  155: 0.7004,
  156: 0.6967,
  157: 0.693,
  158: 0.6893,
  159: 0.6857,
  160: 0.6822,
  161: 0.6787,
  162: 0.6753,
  163: 0.672,
  164: 0.6688,
  165: 0.6656,
  166: 0.6624,
  167: 0.6593,
  168: 0.6563,
  169: 0.6533,
  170: 0.6504,
  171: 0.6475,
  172: 0.6447,
  173: 0.642,
  174: 0.6392,
  175: 0.6365,
  176: 0.6339,
  177: 0.6313,
  178: 0.6288,
  179: 0.6262,
  180: 0.6238,
  181: 0.6214,
  182: 0.619,
  183: 0.6167,
  184: 0.6144,
  185: 0.6121,
  186: 0.6099,
  187: 0.6077,
  188: 0.6056,
  189: 0.6036,
  190: 0.6014,
  191: 0.5994,
  192: 0.5878,
  193: 0.5954,
  194: 0.5935,
  195: 0.5916,
  196: 0.5897,
  197: 0.5879,
  198: 0.5861,
  199: 0.5843,
  200: 0.5826,
  201: 0.5809,
  202: 0.5792,
  203: 0.5776,
  204: 0.576,
  205: 0.5744,
  206: 0.5729,
  207: 0.5714,
  208: 0.57,
  209: 0.5685,
  210: 0.567,
  211: 0.5657,
  212: 0.5643,
  213: 0.563,
  214: 0.5617,
  215: 0.5604,
  216: 0.5592,
  217: 0.558,
  218: 0.5568,
  219: 0.5556,
  220: 0.5545,
  221: 0.5535,
  222: 0.5524,
  223: 0.5514,
  224: 0.5504,
  225: 0.5994,
  226: 0.5485,
  227: 0.5476,
  228: 0.5467,
  229: 0.5458,
  230: 0.5449,
  231: 0.5441,
  232: 0.5433,
  233: 0.5426,
  234: 0.5418,
  235: 0.5411,
  236: 0.5405,
  237: 0.5398,
  238: 0.5391,
  239: 0.5385,
  240: 0.5379,
  241: 0.5373,
  242: 0.5367,
  243: 0.5362,
  244: 0.5357,
  245: 0.5352,
  246: 0.5347,
  247: 0.5342,
  248: 0.5337,
  249: 0.5333,
  250: 0.5328,
  251: 0.5325,
  252: 0.532,
  253: 0.5316,
  254: 0.5312,
  255: 0.5308,
  256: 0.5304,
  257: 0.53,
  258: 0.5296,
  259: 0.5292,
  260: 0.5289,
  261: 0.5284,
  262: 0.5281,
  263: 0.5276,
  264: 0.5273,
  265: 0.5268,
  266: 0.5263,
  267: 0.5259,
  268: 0.5254,
  269: 0.5248,
  270: 0.5243,
  271: 0.5239,
  272: 0.5232,
  273: 0.5227,
  274: 0.522,
  275: 0.5214,
  276: 0.5208,
  277: 0.5203,
  278: 0.5197,
  279: 0.5192,
  280: 0.5186,
  281: 0.518,
  282: 0.5175,
  283: 0.5169,
  284: 0.5164,
  285: 0.5158,
  286: 0.5154,
  287: 0.5147,
  288: 0.5142,
  289: 0.5137,
  290: 0.5132,
  291: 0.5126,
  292: 0.5121,
  293: 0.5115,
  294: 0.5109,
  295: 0.5104,
  296: 0.5098,
  297: 0.5094,
  298: 0.5088,
  299: 0.5083,
  300: 0.5077,
  301: 0.5072,
  302: 0.5067,
  303: 0.5062,
  304: 0.5057,
  305: 0.5053,
  306: 0.5047,
  307: 0.5043,
  308: 0.5037,
  309: 0.5032,
  310: 0.5027,
  311: 0.5022,
  312: 0.5017,
  313: 0.5013,
  314: 0.5007,
  315: 0.5002,
  316: 0.4998,
  317: 0.4992,
  318: 0.4988,
  319: 0.4982,
  320: 0.4978,
  321: 0.4973,
  322: 0.4968,
  323: 0.4964,
  324: 0.4959,
  325: 0.4955,
  326: 0.495,
  327: 0.4946,
  328: 0.4941,
  329: 0.4937,
  330: 0.4932,
  331: 0.4928,
  332: 0.4924,
  333: 0.4919,
  334: 0.4913,
  335: 0.4909,
  336: 0.4905,
  337: 0.4901,
  338: 0.4896,
  339: 0.4891,
  340: 0.4887,
  341: 0.4883,
  342: 0.4878,
  343: 0.4874,
  344: 0.487,
  345: 0.4866,
  346: 0.4862,
  347: 0.4858,
  348: 0.4854,
  349: 0.485,
  350: 0.4845,
  351: 0.4841,
  352: 0.4837,
  353: 0.4833,
  354: 0.4829,
  355: 0.4825,
  356: 0.4821,
  357: 0.4817,
  358: 0.4813,
  359: 0.4809,
  360: 0.4805,
  361: 0.4801,
  362: 0.4796,
};

export const schwartzCoef = function (bodyWeight: number | null | undefined) {
  if (!bodyWeight) {
    return 1;
  }

  if (bodyWeight < 90) {
    return 1.2803;
  }

  if (bodyWeight > 362) {
    return 0.4796;
  }

  return get(schwartzCoefTable, round(bodyWeight), 1);
};

const maloneCoefTable = {
  90: 1.1756,
  91: 1.1645,
  92: 1.1557,
  93: 1.145,
  94: 1.1365,
  95: 1.1261,
  96: 1.118,
  97: 1.1079,
  98: 1.098,
  99: 1.0903,
  100: 1.0807,
  101: 1.0732,
  102: 1.0657,
  103: 1.0566,
  104: 1.0494,
  105: 1.0405,
  106: 1.0336,
  107: 1.025,
  108: 1.0165,
  109: 1.0098,
  110: 1.0016,
  111: 0.9952,
  112: 0.9872,
  113: 0.9809,
  114: 0.9731,
  115: 0.967,
  116: 0.9595,
  117: 0.9536,
  118: 0.9462,
  119: 0.939,
  120: 0.9333,
  121: 0.9263,
  122: 0.9208,
  123: 0.911,
  124: 0.9086,
  125: 0.9019,
  126: 0.898,
  127: 0.8902,
  128: 0.8851,
  129: 0.8788,
  130: 0.8738,
  131: 0.8676,
  132: 0.8628,
  133: 0.8568,
  134: 0.8508,
  135: 0.8462,
  136: 0.8101,
  137: 0.8358,
  138: 0.8302,
  139: 0.8257,
  140: 0.8202,
  141: 0.8159,
  142: 0.8105,
  143: 0.8052,
  144: 0.801,
  145: 0.7959,
  146: 0.7918,
  147: 0.7867,
  148: 0.7827,
  149: 0.7769,
  150: 0.7737,
  151: 0.7697,
  152: 0.7666,
  153: 0.7627,
  154: 0.7596,
  155: 0.7565,
  156: 0.752,
  157: 0.752,
  158: 0.7453,
  159: 0.7431,
  160: 0.7387,
  161: 0.7358,
  162: 0.7322,
  163: 0.7293,
  164: 0.7258,
  165: 0.723,
  166: 0.7196,
  167: 0.7168,
  168: 0.7134,
  169: 0.7107,
  170: 0.7074,
  171: 0.704,
  172: 0.7014,
  173: 0.6981,
  174: 0.6956,
  175: 0.6923,
  176: 0.6898,
  177: 0.6866,
  178: 0.6811,
  179: 0.681,
  180: 0.6786,
  181: 0.6755,
  182: 0.6731,
  183: 0.6701,
  184: 0.6671,
  185: 0.6618,
  186: 0.6604,
  187: 0.6595,
  188: 0.6566,
  189: 0.6543,
  190: 0.6521,
  191: 0.6492,
  192: 0.6464,
  193: 0.6442,
  194: 0.6415,
  195: 0.6387,
  196: 0.6366,
  197: 0.6339,
  198: 0.6317,
  199: 0.63,
  200: 0.6286,
  201: 0.6269,
  202: 0.6256,
  203: 0.6239,
  204: 0.6226,
  205: 0.6209,
  206: 0.6196,
  207: 0.618,
  208: 0.6167,
  209: 0.6151,
  210: 0.6134,
  211: 0.6122,
  212: 0.6109,
  213: 0.6093,
  214: 0.6077,
  215: 0.6064,
  216: 0.6049,
  217: 0.6036,
  218: 0.6021,
  219: 0.6008,
  220: 0.5993,
  221: 0.5981,
  222: 0.5965,
  223: 0.5953,
  224: 0.5938,
  225: 0.5926,
  226: 0.5911,
  227: 0.5896,
  228: 0.5884,
  229: 0.5869,
  230: 0.5858,
  231: 0.5843,
  232: 0.5831,
  233: 0.5817,
  234: 0.5805,
  235: 0.5791,
  236: 0.5779,
  237: 0.5765,
  238: 0.5754,
  239: 0.574,
  240: 0.5725,
  241: 0.5714,
  242: 0.57,
  243: 0.5693,
  244: 0.5686,
  245: 0.5681,
  246: 0.5671,
  247: 0.5669,
  248: 0.5662,
  249: 0.5556,
  250: 0.5649,
};

export const maloneCoef = function (bodyWeight: number | null | undefined) {
  if (!bodyWeight) {
    return 1;
  }

  if (bodyWeight < 90) {
    return 1.1756;
  }

  if (bodyWeight > 250) {
    return 0.5649;
  }

  return get(maloneCoefTable, round(bodyWeight), 1);
};

export const schwartzMaloneCoef = function (lifter: Lifter, meet: Meet) {
  let bodyWeight = lifter.bodyWeight;

  if (isKgMeet(meet)) {
    bodyWeight = toLbs(bodyWeight);
  }

  if (lifter.gender === "MALE") {
    return schwartzCoef(bodyWeight);
  } else if (lifter.gender === "FEMALE") {
    return maloneCoef(bodyWeight);
  }

  return 0;
};

export const ahPoints = function (total: number, lifter: Lifter, meet: Meet) {
  let bodyWeight = lifter.bodyWeight;

  if (!bodyWeight) {
    return 0;
  }

  if (!lifter.gender || lifter.gender === "MX") {
    return 0;
  }

  if (total <= 0) {
    return 0;
  }

  const parameters = {
    MALE: {
      a: 3.2695,
      b: 1.95,
    },
    FEMALE: {
      a: 2.7566,
      b: 1.8,
    },
  };

  if (isKgMeet(meet)) {
    bodyWeight = toLbs(bodyWeight);
  }

  const a = parameters[lifter.gender].a;
  const b = parameters[lifter.gender].b;

  return a * (total / Math.pow(b, Math.log(bodyWeight)));
};

export const glossbrennerCoef = function (
  total: number,
  lifter: Lifter,
  meet: Meet
) {
  let bodyWeight = lifter.bodyWeight;

  if (!bodyWeight) {
    return 0;
  }

  if (total <= 0) {
    return 0;
  }

  if (isLbsMeet(meet)) {
    bodyWeight = toKg(bodyWeight);
  }

  const roundedBw = round(Math.round(bodyWeight / 0.05) * 0.05, 2);
  if (lifter.gender === "MALE") {
    if (roundedBw < 40) {
      return menGlossCoef[40];
    }
    if (roundedBw > 231.9) {
      return menGlossCoef[231.9];
    }
    return get(menGlossCoef, roundedBw, 0);
  } else if (lifter.gender === "FEMALE") {
    if (roundedBw < 40) {
      return womenGlossCoef[40];
    }
    if (roundedBw > 170) {
      return 0.630945 - 0.000065 * (roundedBw - 170);
    }
    return get(womenGlossCoef, roundedBw, 0);
  }

  return 0;
};

export const kPointsFormula = function (
  total: number,
  division: Division | undefined,
  lifter: Lifter,
  meet: Meet
) {
  let bodyWeight = lifter.bodyWeight;

  if (!bodyWeight) {
    return 0;
  }

  if (total <= 0) {
    return 0;
  }

  if (isKgMeet(meet)) {
    bodyWeight = toLbs(bodyWeight);
  }

  const genderIndex = lifter.gender === "FEMALE" ? 2 : 1;

  const compCode = competitionCode(division);
  const liftIndexParams = {
    PL: 0,
    SQ: 3, // Would be 4 if you want actual squats. 3 is an override to make this calculate as strict curl
    BP: 1,
    DL: 2,
    PP: 0,
  };

  const liftIndex =
    liftIndexParams[compCode as "PL" | "SQ" | "BP" | "DL" | "PP"] || 0;

  const ind = (genderIndex - 1) * 5 + liftIndex;
  const min_w_array = [
    80.0, 80.0, 80.0, 80.0, 80.0, 80.0, 80.0, 80.0, 80.0, 80.0,
  ];
  const max_w_array = [
    350.0, 350.0, 330.0, 300.0, 330.0, 250.0, 250.0, 250.0, 240.0, 250.0,
  ];
  const cm5_array = [
    -2.807e-9, 4.0e-12, -1.277e-9, -3.25e-10, -9.83e-10, -8.234e-9, 2.92e-10,
    -3.965e-9, -1.486e-9, -5.065e-9,
  ];
  const cm4_array = [
    3.060249e-6, 2.6046e-8, 1.3758e-6, 3.56667e-7, 1.070056e-6, 6.44957e-6,
    -3.1842e-7, 3.259889e-6, 1.113455e-6, 4.021973e-6,
  ];
  const cm3_array = [
    -1.247564847e-3, -2.2096307e-5, -5.53184164e-4, -1.44211677e-4,
    -4.37984879e-4, -1.853092021e-3, 1.35355511e-4, -9.93519656e-4,
    -3.08730794e-4, -1.194351346e-3,
  ];
  const cm2_array = [
    2.252637144e-1, 4.154784778e-3, 9.854582022e-2, 2.540099377e-2,
    7.9973211e-2, 2.290538594e-1, -2.894483536e-2, 1.341518785e-1,
    3.80382033e-2, 1.606593791e-1,
  ];
  const cm1_array = [
    -1.434283973e1, 6.510188629e-1, -6.340092282, -1.564614892, -5.226337856,
    -9.032322597, 3.326277127, -6.856470087, -1.797316824, -8.751469179,
  ];
  const cm0_array = [
    3.60792968e2, -3.43453458e1, 1.697756926e2, 4.026562795e1, 1.308265519e2,
    9.526623811e1, -1.085790099e2, 1.39138642e2, 3.477251117e1, 1.742483554e2,
  ];
  const csln_array = [
    4.3216e1, 1.6233e1, 1.7404e1, 4.4577, 2.0365e1, 2.8813e1, 7.9084, 1.0339e1,
    2.084, 1.4784e1,
  ];
  const cs0_array = [
    -1.3642e2, -5.8206e1, -5.2806e1, -1.3014e1, -7.0573e1, -9.1251e1, -2.6342e1,
    -2.8299e1, -4.4381, -5.1401e1,
  ];

  const min_w = min_w_array[ind];
  const max_w = max_w_array[ind];
  const cm5 = cm5_array[ind];
  const cm4 = cm4_array[ind];
  const cm3 = cm3_array[ind];
  const cm2 = cm2_array[ind];
  const cm1 = cm1_array[ind];
  const cm0 = cm0_array[ind];
  const csln = csln_array[ind];
  const cs0 = cs0_array[ind];
  const w = Math.max(Math.min(max_w, bodyWeight), min_w);
  const w2 = w * w;
  const w3 = w2 * w;
  const w4 = w3 * w;
  const w5 = w4 * w;
  const expected = w5 * cm5 + w4 * cm4 + w3 * cm3 + w2 * cm2 + w * cm1 + cm0;
  const std = Math.log(w) * csln + cs0;
  const mult = 100.0 / std;
  const add = 600.0 - (100.0 * expected) / std;

  const coeffs = [mult, add];

  if (isLbsMeet(meet)) {
    total = toKg(total);
  }

  return total * coeffs[0] + coeffs[1];
};

export const reshelCoef = function ({
  total,
  lifter,
  meet,
}: {
  total: number;
  lifter: Lifter;
  meet: Meet;
}) {
  let bodyWeight = lifter.bodyWeight;

  if (!bodyWeight) {
    return 0;
  }

  if (total <= 0) {
    return 0;
  }

  if (isLbsMeet(meet)) {
    bodyWeight = toKg(bodyWeight);
  }

  const roundedBw = round(Math.round(bodyWeight / 0.25) * 0.25, 2);
  if (lifter.gender === "MALE") {
    if (roundedBw <= 50) {
      return reshelMen[50];
    }
    if (roundedBw >= 175.5) {
      return reshelMen[175.5];
    }
    return get(reshelMen, roundedBw, 0);
  } else if (lifter.gender === "FEMALE") {
    if (roundedBw <= 40) {
      return reshelWomen[40];
    }
    if (roundedBw >= 160.5) {
      return reshelWomen[160.5];
    }
    return get(reshelWomen, roundedBw, 0);
  }

  return 0;
};
