import { FederationDivisionsConfig } from "types";

// prettier-ignore
const otherWeightClasses = {
  'MALE': [
    {name: '52',   lbsName: '114.64lbs (52kg)',    maxWeight: 52,   lbsMaxWeight: 114.64},
    {name: '56',   lbsName: '123.46lbs (56kg)',    maxWeight: 56,   lbsMaxWeight: 123.46},
    {name: '60',   lbsName: '132.28lbs (60kg)',    maxWeight: 60,   lbsMaxWeight: 132.28},
    {name: '67.5', lbsName: '148.81lbs (67.5kg)',  maxWeight: 67.5, lbsMaxWeight: 148.81},
    {name: '75',   lbsName: '165.35lbs (75kg)',    maxWeight: 75,   lbsMaxWeight: 165.35},
    {name: '82.5', lbsName: '181.88lbs (82.5kg)',  maxWeight: 82.5, lbsMaxWeight: 181.88},
    {name: '90',   lbsName: '198.42lbs (90kg)',    maxWeight: 90,   lbsMaxWeight: 198.42},
    {name: '100',  lbsName: '220.46lbs (100kg)',   maxWeight: 100,  lbsMaxWeight: 220.46},
    {name: '110',  lbsName: '242.51lbs (110kg)',   maxWeight: 110,  lbsMaxWeight: 242.51},
    {name: '125',  lbsName: '275.58lbs (125kg)',   maxWeight: 125,  lbsMaxWeight: 275.58},
    {name: '140',  lbsName: '308.65lbs (140kg)',   maxWeight: 140,  lbsMaxWeight: 308.65},
    {name: '140+', lbsName: '308.65lbs+ (140kg+)', maxWeight: 9999, lbsMaxWeight: 9999}
  ],
  'FEMALE': [
    {name: '44',   lbsName: '97.00lbs (44kg)',    maxWeight: 44,   lbsMaxWeight: 97.00},
    {name: '48',   lbsName: '105.82lbs (48kg)',   maxWeight: 48,   lbsMaxWeight: 105.82},
    {name: '52',   lbsName: '114.64lbs (52kg)',   maxWeight: 52,   lbsMaxWeight: 114.64},
    {name: '56',   lbsName: '123.46lbs (56kg)',   maxWeight: 56,   lbsMaxWeight: 123.46},
    {name: '60',   lbsName: '132.28lbs (60kg)',   maxWeight: 60,   lbsMaxWeight: 132.28},
    {name: '67.5', lbsName: '148.81lbs (67.5kg)', maxWeight: 67.5, lbsMaxWeight: 148.81},
    {name: '75',   lbsName: '165.35lbs (75kg)',   maxWeight: 75,   lbsMaxWeight: 165.35},
    {name: '82.5', lbsName: '181.88lbs (82.5kg)', maxWeight: 82.5, lbsMaxWeight: 181.88},
    {name: '90',   lbsName: '198.42lbs (90kg)',   maxWeight: 90,   lbsMaxWeight: 198.42},
    {name: '90+',  lbsName: '198.42lbs+ (90kg+)', maxWeight: 9999, lbsMaxWeight: 9999}
  ],
  'MX': [
    {name: '44',   lbsName: '97.00lbs (44kg)',     maxWeight: 44,   lbsMaxWeight: 97.00},
    {name: '48',   lbsName: '105.82lbs (48kg)',    maxWeight: 48,   lbsMaxWeight: 105.82},
    {name: '52',   lbsName: '114.64lbs (52kg)',    maxWeight: 52,   lbsMaxWeight: 114.64},
    {name: '56',   lbsName: '123.46lbs (56kg)',    maxWeight: 56,   lbsMaxWeight: 123.46},
    {name: '60',   lbsName: '132.28lbs (60kg)',    maxWeight: 60,   lbsMaxWeight: 132.28},
    {name: '67.5', lbsName: '148.81lbs (67.5kg)',  maxWeight: 67.5, lbsMaxWeight: 148.81},
    {name: '75',   lbsName: '165.35lbs (75kg)',    maxWeight: 75,   lbsMaxWeight: 165.35},
    {name: '82.5', lbsName: '181.88lbs (82.5kg)',  maxWeight: 82.5, lbsMaxWeight: 181.88},
    {name: '90',   lbsName: '198.42lbs (90kg)',    maxWeight: 90,   lbsMaxWeight: 198.42},
    {name: '100',  lbsName: '220.46lbs (100kg)',   maxWeight: 100,  lbsMaxWeight: 220.46},
    {name: '110',  lbsName: '242.51lbs (110kg)',   maxWeight: 110,  lbsMaxWeight: 242.51},
    {name: '125',  lbsName: '275.58lbs (125kg)',   maxWeight: 125,  lbsMaxWeight: 275.58},
    {name: '140',  lbsName: '308.65lbs (140kg)',   maxWeight: 140,  lbsMaxWeight: 308.65},
    {name: '140+', lbsName: '308.65lbs+ (140kg+)', maxWeight: 9999, lbsMaxWeight: 9999}
  ]
};

// prettier-ignore
export const otherBaseDivisions: FederationDivisionsConfig = [
  {name: "Junior (13-15)",     code: 'J1',  low: 13,  high: 15,   default: true,  records: true,  weightClasses: otherWeightClasses},
  {name: "Junior (16-17)",     code: 'J2',  low: 16,  high: 17,   default: true,  records: true,  weightClasses: otherWeightClasses},
  {name: "Junior (18-19)",     code: 'J3',  low: 18,  high: 19,   default: true,  records: true,  weightClasses: otherWeightClasses},
  {name: "Junior (20-23)",     code: 'J4',  low: 20,  high: 23,   default: true,  records: true,  weightClasses: otherWeightClasses},
  {name: "Open",               code: 'O',   low: 24,  high: 39,   default: true,  records: true,  weightClasses: otherWeightClasses},
  {name: "Sub-Master (35-39)", code: 'SM',  low: 35,  high: 39,   default: true,  records: true,  weightClasses: otherWeightClasses},
  {name: "Master (40-44)",     code: 'M1',  low: 40,  high: 44,   default: true,  records: true,  weightClasses: otherWeightClasses},
  {name: "Master (45-49)",     code: 'M2',  low: 45,  high: 49,   default: true,  records: true,  weightClasses: otherWeightClasses},
  {name: "Master (50-54)",     code: 'M3',  low: 50,  high: 54,   default: true,  records: true,  weightClasses: otherWeightClasses},
  {name: "Master (55-59)",     code: 'M4',  low: 55,  high: 59,   default: true,  records: true,  weightClasses: otherWeightClasses},
  {name: "Master (60-64)",     code: 'M5',  low: 60,  high: 64,   default: true,  records: true,  weightClasses: otherWeightClasses},
  {name: "Master (65-69)",     code: 'M6',  low: 65,  high: 69,   default: true,  records: true,  weightClasses: otherWeightClasses},
  {name: "Master (70-74)",     code: 'M7',  low: 70,  high: 74,   default: true,  records: true,  weightClasses: otherWeightClasses},
  {name: "Master (75-79)",     code: 'M8',  low: 75,  high: 79,   default: true,  records: true,  weightClasses: otherWeightClasses},
  {name: "Master (80+)",       code: 'M9',  low: 80,  high: 999,  default: true,  records: true,  weightClasses: otherWeightClasses},
  {name: "Guest",              code: 'G',                         default: false, records: false, weightClasses: otherWeightClasses}
];
