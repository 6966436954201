import React from 'react';
import SortableHeaderCell from 'components/SortableHeaderCell';
import { getRealAge } from 'util/lifterHelper';

const AgeCell = ({data, column, meet, style}) => {
  const lifter = data;
  if(lifter.row === 'header') {
    return (<SortableHeaderCell style={style} column={column}/>);
  } else {
    return (
      <div style={style} className="table-cell-inner">
        {getRealAge(lifter, meet)}
      </div>
    );
  }
};

export default AgeCell;
