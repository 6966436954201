import { format, parse, isValid } from "date-fns";

const convertDateFormat = (momentDateFormat: string | null | undefined) => {
  const dateFnsDateFormat = (momentDateFormat || "MM/DD/YYYY")
    .replace("YYYY", "yyyy")
    .replace("DD", "dd");

  return dateFnsDateFormat;
};

export const parseDateString = ({
  dateString,
  meet,
}: {
  dateString: string | undefined | null;
  meet: { dateFormat?: string };
}): Date | null => {
  if (!dateString) {
    return null;
  }
  try {
    const parsedDate = parse(
      dateString,
      convertDateFormat(meet.dateFormat),
      new Date()
    );
    if (isValid(parsedDate)) {
      return parsedDate;
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const formatDate = ({
  date,
  meet,
}: {
  date: Date | null | undefined;
  meet: { dateFormat?: string };
}) => {
  if (!date || !isValid(date)) {
    return "";
  }
  return format(date, convertDateFormat(meet.dateFormat));
};

export const reformatDateToUsFormat = ({
  dateString,
  meet,
}: {
  dateString: string;
  meet: { dateFormat?: string };
}) => {
  const date = parseDateString({ dateString, meet });
  if (!date || !isValid(date)) {
    return "";
  }
  return format(date, "MM/dd/yyyy");
};
