import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { getCurrentLifter } from "selectors";
import { ReduxState } from "types";

export const useCurrentLifter = (platformIdFromProps?: string) => {
  const { meetId, platformId } = useParams<{
    meetId: string;
    platformId: string;
  }>();

  const props = React.useMemo(() => {
    return {
      match: {
        params: {
          meetId,
          platformId: platformIdFromProps ?? platformId,
        },
      },
    };
  }, [meetId, platformId, platformIdFromProps]);

  const currentLifter = useSelector((state: ReduxState) =>
    getCurrentLifter(state, props)
  );

  return currentLifter;
};
