import React from "react";
import { Route, Switch, NavLink } from "react-router-dom";
import ColorPicker from "components/colorPicker/ColorPicker";
import Platform1 from "./Platform1";
import Platform2 from "./Platform2";
import Platform3 from "./Platform3";
import "./LiveFeedIndex.scss";
import { useMeet } from "util/useMeet";
import map from "lodash/map";
import sortBy from "lodash/sortBy";

const LiveFeedIndex = () => {
  const [fontColor, setFontColor] = React.useState<string | null | undefined>(
    "#FFFFFF"
  );
  const [backgroundColor, setBackgroundColor] = React.useState<
    string | null | undefined
  >("#000000");

  const meet = useMeet();
  if (!meet) {
    return null;
  }

  const baseUrl = `/meets/${meet._id}`;

  return (
    <div className="live-feed-index">
      <div className="tabs">
        <NavLink
          to={`${baseUrl}/liveFeed/version1`}
          className="tab"
          activeClassName="active"
        >
          Version 1
        </NavLink>
        <NavLink
          to={`${baseUrl}/liveFeed/version2`}
          className="tab"
          activeClassName="active"
        >
          Version 2
        </NavLink>
        <NavLink
          to={`${baseUrl}/liveFeed/version3`}
          className="tab"
          activeClassName="active"
        >
          Version 3
        </NavLink>
      </div>

      <div
        className="live-feed-content"
        style={{
          color: fontColor ?? undefined,
          backgroundColor: backgroundColor ?? undefined,
        }}
      >
        <div>
          <div className="color-picker-wrapper">
            <label>Font Color: </label>
            <ColorPicker value={fontColor} onChange={setFontColor} />
          </div>
          <div className="color-picker-wrapper">
            <label>Background Color: </label>
            <ColorPicker
              value={backgroundColor}
              onChange={setBackgroundColor}
            />
          </div>
        </div>
        {map(sortBy(meet.platforms, ["name"]), (platform) => {
          return (
            <div key={platform._id}>
              <Switch>
                <Route
                  path="/meets/:meetId/liveFeed/version1"
                  component={() => <Platform1 platformId={platform._id} />}
                />
                <Route
                  path="/meets/:meetId/liveFeed/version2"
                  component={() => <Platform2 platformId={platform._id} />}
                />
                <Route
                  path="/meets/:meetId/liveFeed/version3"
                  component={() => <Platform3 platformId={platform._id} />}
                />
                <Route
                  path="/meets/:meetId/liveFeed"
                  component={() => <Platform3 platformId={platform._id} />}
                />
              </Switch>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LiveFeedIndex;
