import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { getCurrentPlatform } from "selectors";
import { Platform, ReduxState } from "types";

export const usePlatform = (platformIdFromProps?: string) => {
  const { meetId, platformId } = useParams<{
    meetId: string;
    platformId: string;
  }>();

  const props = React.useMemo(() => {
    return {
      match: {
        params: {
          meetId,
          platformId: platformIdFromProps ?? platformId,
        },
      },
    };
  }, [meetId, platformId, platformIdFromProps]);

  const platform = useSelector((state: ReduxState) =>
    getCurrentPlatform(state, props)
  ) as Platform;

  return platform;
};
