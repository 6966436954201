import drop from "lodash/drop";
import get from "lodash/get";
import head from "lodash/head";
import isUndefined from "lodash/isUndefined";
import { LiftingOrder, Meet, Platform } from "types";
import { updateAttributesOnDocument } from "util/pouchActions";

export const selectNextAttempt = function (
  knownLiftingOrder: LiftingOrder,
  meet: Meet,
  platform: Platform
) {
  let nextAttempt = head(knownLiftingOrder);
  let nextAttemptId: string | null | undefined = get(nextAttempt, "attemptId");

  // usually the first attempt is already the current attempt
  // but we check to make sure since the user can override the current attempt.
  if (nextAttemptId === platform.currentAttemptId) {
    const nextLiftingOrder = drop(knownLiftingOrder);
    nextAttempt = head(nextLiftingOrder);
    nextAttemptId = get(nextAttempt, "attemptId");
  }

  if (isUndefined(nextAttemptId)) {
    nextAttemptId = null;
  }

  updateAttributesOnDocument(meet._id, platform._id, {
    currentAttemptId: nextAttemptId,
    clockState: "initial",
  });
};
