import React from "react";
import { useLocation } from "react-router";

export const useResetScroll = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const oldPathname = React.useRef(pathname);
  React.useEffect(() => {
    if (pathname !== oldPathname.current) {
      window.scrollTo(0, 0);
      oldPathname.current = pathname;
    }
  }, [pathname]);
};
