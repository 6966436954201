import React from "react";
import findIndex from "lodash/findIndex";
import { Meet } from "types";
import { useFutureLiftingOrder } from "util/useFutureLiftingOrder";

const OutCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: any;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const futureLiftingOrder = useFutureLiftingOrder();
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    const out = findIndex(
      futureLiftingOrder,
      (row) => row.lifter._id === lifter._id
    );
    return (
      <div style={style} className="table-cell-inner">
        {out === -1 ? null : out ?? null}
      </div>
    );
  }
};

export default OutCell;
