import React from "react";
import {
  getDivisionCodeOptions,
  getGenderOptions,
  getRawOrEquippedOptions,
  getRecordCompetitionTypeOptions,
  getRecordLiftOptions,
  getRecordLocationOptions,
  getTestedOptions,
} from "util/options";
import SortableTable from "components/SortableTable";
import OptionCell from "./cells/OptionCell";
import { MediaSize, Meet } from "types";
import DefaultCell from "./cells/DefaultCell";
import { Column } from "components/table/Table";

// prettier-ignore
const columns: Column[] = [
  { key: "gender",          label: "Gender",          width: 100, options: getGenderOptions,                sortable: true, sortDirection: "asc", sortOrder: 1, renderer: OptionCell,},
  { key: "equipmentLevel",  label: "EquipmentLevel",  width: 140, options: getRawOrEquippedOptions,         sortable: true, sortDirection: "asc", sortOrder: 2, renderer: OptionCell,},
  { key: "drugTested",      label: "drugTested",      width: 120, options: getTestedOptions,                sortable: true, sortDirection: "asc", sortOrder: 3, renderer: OptionCell,},
  { key: "divisionCode",    label: "divisionCode",    width: 120, options: getDivisionCodeOptions,          sortable: true, sortDirection: "asc", sortOrder: 4, renderer: OptionCell,},
  { key: "weightClass",     label: "weightClass",     width: 120,                                           sortable: true, sortDirection: "asc", sortOrder: 5, renderer: DefaultCell,},
  { key: "competitionType", label: "competitionType", width: 180, options: getRecordCompetitionTypeOptions, sortable: true, sortDirection: "asc", sortOrder: 6, renderer: OptionCell,},
  { key: "lift",            label: "lift",            width: 180, options: getRecordLiftOptions,            sortable: true, sortDirection: "asc", sortOrder: 7, renderer: OptionCell,},
  { key: "location",        label: "location",        width: 120, options: getRecordLocationOptions,        sortable: true, sortDirection: "asc", sortOrder: 8, renderer: OptionCell,},
  { key: "recordWeight",    label: "recordWeight",    width: 120,                                           sortable: true, sortDirection: "asc", sortOrder: 9, renderer: DefaultCell,},
];

const getCellLines = (index: number, divisions: any) => {
  return 1;
};
const ImportRecordsTable = ({
  items,
  meet,
  media,
}: {
  items: any;
  meet: Meet;
  media: MediaSize;
}) => {
  return (
    <SortableTable
      data={items}
      columns={columns}
      getCellLines={getCellLines}
      meet={meet}
      media={media}
      numberOfFixedLeftColumns={1}
    />
  );
};

export default ImportRecordsTable;
