import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import size from "lodash/size";
import sum from "lodash/sum";
import React from "react";
import { fetchWrapper } from "util/api";
import { useStatus } from "util/useStatus";

const ConnectionChecker = () => {
  const { isLocal, isOnline, isSyncing } = useStatus();
  const [running, setRunning] = React.useState(false);
  const [pingResults, setPingResults] = React.useState<any[]>([]);

  const checkConnection = async () => {
    setRunning(true);
    setPingResults([]);
    await ping();
    await ping();
    await ping();
    await ping();
    await ping();
    await ping();
    await ping();
    await ping();
    await ping();
    await ping();
    await ping();
    await ping();
    await ping();
    await ping();
    await ping();
    await ping();
    await ping();
    await ping();
    await ping();
    await ping();
    setRunning(false);
  };

  const ping = async () => {
    const startTime = performance.now();
    await fetchWrapper("/api/ping", "HEAD");
    const endTime = performance.now();
    const result = endTime - startTime;

    setPingResults((prev) => [...prev, result]);
  };

  const total = sum(pingResults);
  const average = total / size(pingResults);

  const variation = Math.sqrt(
    sum(map(pingResults, (i) => Math.pow(i - average, 2))) / pingResults.length
  );

  let score: any = 0;

  if (average < 35) {
    score = 7;
  } else if (average < 65) {
    score = 6;
  } else if (average < 100) {
    score = 5;
  } else if (average < 200) {
    score = 4;
  } else if (average < 300) {
    score = 3;
  } else if (average < 400) {
    score = 2;
  } else if (average < 600) {
    score = 1;
  }

  if (variation < 10) {
    score += 3;
  } else if (variation < 20) {
    score += 2;
  } else if (variation < 30) {
    score += 1;
  }

  if (isEmpty(pingResults)) {
    score = "";
  }

  return (
    <div className="connection-checker">
      <br />
      {isLocal && !isOnline && <div>This is an offline only meet.</div>}
      {isLocal && isOnline && isSyncing && (
        <div>
          This is an online meet. You are currently syncing data online.
        </div>
      )}
      {isLocal && isOnline && !isSyncing && (
        <div>
          This is an online meet. You are currently NOT syncing data online.
        </div>
      )}
      <br />
      <hr />
      <br />
      Your connection stability is important for running a meet across multiple
      computers. Especially important if using tablets to control ref lights.
      Run this test a few times to make sure you get consistent results. Keep in
      mind that if you are sharing wifi at the venue with spectators the results
      could change drastically when the venue is full.
      <br />
      <br />
      <button onClick={checkConnection}>Check Connection</button>
      <div>
        <br />
        <label>Score: </label>
        {running ? "checking..." : score}
        <br />
        <ul>
          <li>
            <label>0 - 3</label> Poor: Not suitable for onsite live updates. Not
            suitable for ref light tablets. Recommend running multiple screens
            off one computer per platform.
          </li>
          <li>
            <label>4 - 6</label> OK: Suitable for onsite live updates. Expect
            delays with tablet lights.
          </li>
          <li>
            <label>7 - 8</label> Good: Suitable for onsite live updates.
          </li>
          <li>
            <label>8 - 10</label> Excellent: Suitable for onsite live updates.
            Tablet lights should work well.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ConnectionChecker;
