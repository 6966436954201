import React from "react";
import map from "lodash/map";
import { getDivisionDoc } from "util/lifterHelper";

const DivisionCell = ({ data, column, meet, style }) => {
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    return (
      <div style={style} className="table-cell-inner">
        {map(lifter.divisions, (lifterDivision, index) => {
          if (!lifterDivision.divisionId) {
            return null;
          }

          const division = getDivisionDoc(lifterDivision.divisionId, meet);
          if (!division) {
            return null;
          }

          if (division.hideOnBoard) {
            return null;
          }

          return (
            <div key={index} className="ellipsis cell-row">
              {division.name}
            </div>
          );
        })}
      </div>
    );
  }
};

export default DivisionCell;
