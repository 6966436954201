import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Route,
  Switch,
  NavLink,
  Redirect
} from 'react-router-dom';

import { getCurrentMeet } from 'selectors';

import CardsVersion1 from './CardsVersion1';
import CardsVersion2 from './CardsVersion2';

class CardsIndex extends Component {
  render() {
    const { meet } = this.props;
    const baseUrl = `/meets/${meet._id}`;

    return (
      <div className="cards-index">
        <div className="tabs">
          <NavLink to={`${baseUrl}/paperwork/cards/version1`} className="tab" activeClassName='active'>Version 1</NavLink>
          <NavLink to={`${baseUrl}/paperwork/cards/version2`} className="tab" activeClassName='active'>Version 2</NavLink>
        </div>

        <div className="cards-content">
          <Switch>
            <Route path='/meets/:meetId/paperwork/cards/version1' component={CardsVersion1} />
            <Route path='/meets/:meetId/paperwork/cards/version2' component={CardsVersion2} />
            <Route render={() => <Redirect to={`${baseUrl}/paperwork/cards/version1`} />}/>
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    meet: getCurrentMeet(state, props)
  };
};

const actions = {};

export default connect(mapStateToProps, actions)(CardsIndex);
