import React from "react";

import Checkbox from "components/checkbox/Checkbox";
import { updateAttributeOnDocument } from "util/pouchActions";

const PouchCheckbox = ({
  meetId,
  documentId,
  name,
  value,
  label,
}: {
  meetId: string;
  documentId: string;
  name: string | string[];
  value: boolean | undefined;
  label?: string | React.ReactNode;
}) => {
  const onCheck = (value: boolean) => {
    updateAttributeOnDocument(meetId, documentId, name, value);
  };

  return <Checkbox label={label} onCheck={onCheck} value={!!value} />;
};

export default PouchCheckbox;
