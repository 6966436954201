export const reshelMen = {
  50: 1.955,
  50.25: 1.937,
  50.5: 1.92,
  50.75: 1.902,
  51: 1.885,
  51.25: 1.867,
  51.5: 1.851,
  51.75: 1.835,
  52: 1.818,
  52.25: 1.803,
  52.5: 1.788,
  52.75: 1.773,
  53: 1.758,
  53.25: 1.743,
  53.5: 1.727,
  53.75: 1.712,
  54: 1.696,
  54.25: 1.682,
  54.5: 1.667,
  54.75: 1.655,
  55: 1.642,
  55.25: 1.63,
  55.5: 1.617,
  55.75: 1.605,
  56: 1.593,
  56.25: 1.58,
  56.5: 1.568,
  56.75: 1.555,
  57: 1.543,
  57.25: 1.531,
  57.5: 1.518,
  57.75: 1.506,
  58: 1.497,
  58.25: 1.488,
  58.5: 1.478,
  58.75: 1.469,
  59: 1.46,
  59.25: 1.451,
  59.5: 1.442,
  59.75: 1.432,
  60: 1.423,
  60.25: 1.414,
  60.5: 1.405,
  60.75: 1.398,
  61: 1.391,
  61.25: 1.384,
  61.5: 1.377,
  61.75: 1.371,
  62: 1.364,
  62.25: 1.357,
  62.5: 1.35,
  62.75: 1.343,
  63: 1.336,
  63.25: 1.329,
  63.5: 1.322,
  63.75: 1.317,
  64: 1.311,
  64.25: 1.306,
  64.5: 1.3,
  64.75: 1.295,
  65: 1.29,
  65.25: 1.284,
  65.5: 1.279,
  65.75: 1.273,
  66: 1.268,
  66.25: 1.263,
  66.5: 1.257,
  66.75: 1.252,
  67: 1.246,
  67.25: 1.242,
  67.5: 1.237,
  67.75: 1.233,
  68: 1.228,
  68.25: 1.224,
  68.5: 1.219,
  68.75: 1.215,
  69: 1.21,
  69.25: 1.206,
  69.5: 1.202,
  69.75: 1.198,
  70: 1.194,
  70.25: 1.19,
  70.5: 1.186,
  70.75: 1.182,
  71: 1.178,
  71.25: 1.174,
  71.5: 1.17,
  71.75: 1.166,
  72: 1.162,
  72.25: 1.158,
  72.5: 1.155,
  72.75: 1.151,
  73: 1.147,
  73.25: 1.143,
  73.5: 1.139,
  73.75: 1.136,
  74: 1.132,
  74.25: 1.128,
  74.5: 1.124,
  74.75: 1.12,
  75: 1.117,
  75.25: 1.114,
  75.5: 1.11,
  75.75: 1.107,
  76: 1.103,
  76.25: 1.1,
  76.5: 1.096,
  76.75: 1.093,
  77: 1.09,
  77.25: 1.087,
  77.5: 1.084,
  77.75: 1.081,
  78: 1.078,
  78.25: 1.075,
  78.5: 1.072,
  78.75: 1.069,
  79: 1.066,
  79.25: 1.063,
  79.5: 1.06,
  79.75: 1.057,
  80: 1.054,
  80.25: 1.051,
  80.5: 1.048,
  80.75: 1.046,
  81: 1.044,
  81.25: 1.041,
  81.5: 1.039,
  81.75: 1.036,
  82: 1.034,
  82.25: 1.031,
  82.5: 1.029,
  82.75: 1.026,
  83: 1.024,
  83.25: 1.022,
  83.5: 1.019,
  83.75: 1.017,
  84: 1.015,
  84.25: 1.013,
  84.5: 1.011,
  84.75: 1.008,
  85: 1.006,
  85.25: 1.004,
  85.5: 1.002,
  85.75: 1,
  86: 0.998,
  86.25: 0.996,
  86.5: 0.994,
  86.75: 0.992,
  87: 0.99,
  87.25: 0.988,
  87.5: 0.987,
  87.75: 0.985,
  88: 0.983,
  88.25: 0.981,
  88.5: 0.979,
  88.75: 0.978,
  89: 0.976,
  89.25: 0.974,
  89.5: 0.972,
  89.75: 0.97,
  90: 0.969,
  90.25: 0.967,
  90.5: 0.965,
  90.75: 0.963,
  91: 0.961,
  91.25: 0.96,
  91.5: 0.958,
  91.75: 0.956,
  92: 0.954,
  92.25: 0.952,
  92.5: 0.951,
  92.75: 0.95,
  93: 0.948,
  93.25: 0.947,
  93.5: 0.945,
  93.75: 0.944,
  94: 0.942,
  94.25: 0.941,
  94.5: 0.939,
  94.75: 0.938,
  95: 0.937,
  95.25: 0.935,
  95.5: 0.934,
  95.75: 0.933,
  96: 0.932,
  96.25: 0.931,
  96.5: 0.929,
  96.75: 0.928,
  97: 0.927,
  97.25: 0.926,
  97.5: 0.925,
  97.75: 0.924,
  98: 0.923,
  98.25: 0.922,
  98.5: 0.921,
  98.75: 0.92,
  99: 0.919,
  99.25: 0.918,
  99.5: 0.917,
  99.75: 0.916,
  100: 0.915,
  100.25: 0.914,
  100.5: 0.913,
  100.75: 0.912,
  101: 0.911,
  101.25: 0.91,
  101.5: 0.909,
  101.75: 0.909,
  102: 0.908,
  102.25: 0.907,
  102.5: 0.906,
  102.75: 0.905,
  103: 0.904,
  103.25: 0.904,
  103.5: 0.903,
  103.75: 0.902,
  104: 0.901,
  104.25: 0.9,
  104.5: 0.899,
  104.75: 0.899,
  105: 0.898,
  105.25: 0.898,
  105.5: 0.897,
  105.75: 0.896,
  106: 0.895,
  106.25: 0.894,
  106.5: 0.894,
  106.75: 0.893,
  107: 0.892,
  107.25: 0.892,
  107.5: 0.891,
  107.75: 0.89,
  108: 0.89,
  108.25: 0.889,
  108.5: 0.889,
  108.75: 0.888,
  109: 0.887,
  109.25: 0.887,
  109.5: 0.886,
  109.75: 0.885,
  110: 0.885,
  110.25: 0.884,
  110.5: 0.883,
  110.75: 0.883,
  111: 0.882,
  111.25: 0.881,
  111.5: 0.881,
  111.75: 0.88,
  112: 0.879,
  112.25: 0.879,
  112.5: 0.878,
  112.75: 0.878,
  113: 0.877,
  113.25: 0.876,
  113.5: 0.876,
  113.75: 0.875,
  114: 0.875,
  114.25: 0.874,
  114.5: 0.873,
  114.75: 0.873,
  115: 0.873,
  115.25: 0.872,
  115.5: 0.872,
  115.75: 0.871,
  116: 0.871,
  116.25: 0.87,
  116.5: 0.87,
  116.75: 0.869,
  117: 0.869,
  117.25: 0.868,
  117.5: 0.868,
  117.75: 0.868,
  118: 0.867,
  118.25: 0.867,
  118.5: 0.866,
  118.75: 0.866,
  119: 0.866,
  119.25: 0.865,
  119.5: 0.865,
  119.75: 0.864,
  120: 0.864,
  120.25: 0.864,
  120.5: 0.863,
  120.75: 0.863,
  121: 0.862,
  121.25: 0.862,
  121.5: 0.862,
  121.75: 0.861,
  122: 0.861,
  122.25: 0.861,
  122.5: 0.861,
  122.75: 0.86,
  123: 0.86,
  123.25: 0.86,
  123.5: 0.86,
  123.75: 0.859,
  124: 0.859,
  124.25: 0.859,
  124.5: 0.858,
  124.75: 0.858,
  125: 0.858,
  125.25: 0.857,
  125.5: 0.857,
  125.75: 0.857,
  126: 0.857,
  126.25: 0.856,
  126.5: 0.856,
  126.75: 0.856,
  127: 0.855,
  127.25: 0.855,
  127.5: 0.854,
  127.75: 0.854,
  128: 0.854,
  128.25: 0.854,
  128.5: 0.853,
  128.75: 0.853,
  129: 0.853,
  129.25: 0.852,
  129.5: 0.852,
  129.75: 0.852,
  130: 0.851,
  130.25: 0.851,
  130.5: 0.851,
  130.75: 0.851,
  131: 0.85,
  131.25: 0.85,
  131.5: 0.85,
  131.75: 0.849,
  132: 0.849,
  132.25: 0.849,
  132.5: 0.848,
  132.75: 0.848,
  133: 0.848,
  133.25: 0.848,
  133.5: 0.847,
  133.75: 0.847,
  134: 0.847,
  134.25: 0.847,
  134.5: 0.847,
  134.75: 0.846,
  135: 0.846,
  135.25: 0.846,
  135.5: 0.845,
  135.75: 0.845,
  136: 0.845,
  136.25: 0.845,
  136.5: 0.844,
  136.75: 0.844,
  137: 0.844,
  137.25: 0.843,
  137.5: 0.843,
  137.75: 0.843,
  138: 0.842,
  138.25: 0.842,
  138.5: 0.842,
  138.75: 0.842,
  139: 0.841,
  139.25: 0.841,
  139.5: 0.841,
  139.75: 0.84,
  140: 0.84,
  140.25: 0.84,
  140.5: 0.84,
  140.75: 0.84,
  141: 0.839,
  141.25: 0.839,
  141.5: 0.839,
  141.75: 0.839,
  142: 0.838,
  142.25: 0.838,
  142.5: 0.838,
  142.75: 0.838,
  143: 0.837,
  143.25: 0.837,
  143.5: 0.837,
  143.75: 0.837,
  144: 0.836,
  144.25: 0.836,
  144.5: 0.836,
  144.75: 0.836,
  145: 0.835,
  145.25: 0.835,
  145.5: 0.835,
  145.75: 0.835,
  146: 0.834,
  146.25: 0.834,
  146.5: 0.834,
  146.75: 0.834,
  147: 0.833,
  147.25: 0.833,
  147.5: 0.833,
  147.75: 0.833,
  148: 0.832,
  148.25: 0.8322,
  148.5: 0.832,
  148.75: 0.832,
  149: 0.831,
  149.25: 0.831,
  149.5: 0.831,
  149.75: 0.831,
  150: 0.831,
  150.25: 0.83,
  150.5: 0.83,
  150.75: 0.83,
  151: 0.83,
  151.25: 0.83,
  151.5: 0.829,
  151.75: 0.829,
  152: 0.829,
  152.25: 0.829,
  152.5: 0.829,
  152.75: 0.828,
  153: 0.828,
  153.25: 0.828,
  153.5: 0.828,
  153.75: 0.828,
  154: 0.828,
  154.25: 0.827,
  154.5: 0.827,
  154.75: 0.827,
  155: 0.827,
  155.25: 0.827,
  155.5: 0.827,
  155.75: 0.826,
  156: 0.826,
  156.25: 0.826,
  156.5: 0.826,
  156.75: 0.826,
  157: 0.826,
  157.25: 0.825,
  157.5: 0.825,
  157.75: 0.825,
  158: 0.825,
  158.25: 0.825,
  158.5: 0.825,
  158.75: 0.825,
  159: 0.824,
  159.25: 0.824,
  159.5: 0.824,
  159.75: 0.824,
  160: 0.824,
  160.25: 0.824,
  160.5: 0.824,
  160.75: 0.823,
  161: 0.823,
  161.25: 0.823,
  161.5: 0.823,
  161.75: 0.823,
  162: 0.823,
  162.25: 0.823,
  162.5: 0.822,
  162.75: 0.822,
  163: 0.822,
  163.25: 0.822,
  163.5: 0.822,
  163.75: 0.822,
  164: 0.822,
  164.25: 0.821,
  164.5: 0.821,
  164.75: 0.821,
  165: 0.821,
  165.25: 0.821,
  165.5: 0.821,
  165.75: 0.821,
  166: 0.82,
  166.25: 0.82,
  166.5: 0.82,
  166.75: 0.82,
  167: 0.82,
  167.25: 0.82,
  167.5: 0.82,
  167.75: 0.819,
  168: 0.819,
  168.25: 0.819,
  168.5: 0.819,
  168.75: 0.819,
  169: 0.819,
  169.25: 0.819,
  169.5: 0.818,
  169.75: 0.818,
  170: 0.818,
  170.25: 0.818,
  170.5: 0.818,
  170.75: 0.818,
  171: 0.818,
  171.25: 0.818,
  171.5: 0.817,
  171.75: 0.817,
  172: 0.817,
  172.25: 0.817,
  172.5: 0.817,
  172.75: 0.817,
  173: 0.817,
  173.25: 0.817,
  173.5: 0.816,
  173.75: 0.816,
  174: 0.816,
  174.25: 0.816,
  174.5: 0.816,
  174.75: 0.816,
  175: 0.816,
  175.25: 0.816,
  175.5: 0.816,
};

export const reshelWomen = {
  40: 3.145,
  40.25: 3.105,
  40.5: 3.065,
  40.75: 3.025,
  41: 2.985,
  41.25: 2.95,
  41.5: 2.915,
  41.75: 2.882,
  42: 2.849,
  42.25: 2.817,
  42.5: 2.786,
  42.75: 2.759,
  43: 2.732,
  43.25: 2.706,
  43.5: 2.681,
  43.75: 2.656,
  44: 2.632,
  44.25: 2.61,
  44.5: 2.589,
  44.75: 2.567,
  45: 2.545,
  45.25: 2.523,
  45.5: 2.503,
  45.75: 2.483,
  46: 2.463,
  46.25: 2.443,
  46.5: 2.424,
  46.75: 2.405,
  47: 2.387,
  47.25: 2.369,
  47.5: 2.351,
  47.75: 2.331,
  48: 2.315,
  48.25: 2.297,
  48.5: 2.28,
  48.75: 2.263,
  49: 2.247,
  49.25: 2.231,
  49.5: 2.216,
  49.75: 2.202,
  50: 2.188,
  50.25: 2.174,
  50.5: 2.16,
  50.75: 2.146,
  51: 2.132,
  51.25: 2.119,
  51.5: 2.105,
  51.75: 2.092,
  52: 2.079,
  52.25: 2.066,
  52.5: 2.053,
  52.75: 2.041,
  53: 2.028,
  53.25: 2.016,
  53.5: 2.004,
  53.75: 1.994,
  54: 1.984,
  54.25: 1.974,
  54.5: 1.963,
  54.75: 1.953,
  55: 1.942,
  55.25: 1.932,
  55.5: 1.923,
  55.75: 1.914,
  56: 1.905,
  56.25: 1.896,
  56.5: 1.887,
  56.75: 1.878,
  57: 1.869,
  57.25: 1.86,
  57.5: 1.852,
  57.75: 1.845,
  58: 1.838,
  58.25: 1.83,
  58.5: 1.822,
  58.75: 1.815,
  59: 1.808,
  59.25: 1.802,
  59.5: 1.795,
  59.75: 1.789,
  60: 1.783,
  60.25: 1.776,
  60.5: 1.77,
  60.75: 1.764,
  61: 1.757,
  61.25: 1.751,
  61.5: 1.745,
  61.75: 1.74,
  62: 1.735,
  62.25: 1.73,
  62.5: 1.725,
  62.75: 1.72,
  63: 1.715,
  63.25: 1.711,
  63.5: 1.707,
  63.75: 1.703,
  64: 1.698,
  64.25: 1.694,
  64.5: 1.69,
  64.75: 1.686,
  65: 1.681,
  65.25: 1.676,
  65.5: 1.672,
  65.75: 1.668,
  66: 1.664,
  66.25: 1.66,
  66.5: 1.655,
  66.75: 1.651,
  67: 1.647,
  67.25: 1.643,
  67.5: 1.639,
  67.75: 1.634,
  68: 1.631,
  68.25: 1.627,
  68.5: 1.623,
  68.75: 1.62,
  69: 1.618,
  69.25: 1.615,
  69.5: 1.612,
  69.75: 1.608,
  70: 1.605,
  70.25: 1.601,
  70.5: 1.598,
  70.75: 1.595,
  71: 1.592,
  71.25: 1.589,
  71.5: 1.586,
  71.75: 1.583,
  72: 1.58,
  72.25: 1.577,
  72.5: 1.574,
  72.75: 1.571,
  73: 1.567,
  73.25: 1.564,
  73.5: 1.561,
  73.75: 1.558,
  74: 1.555,
  74.25: 1.552,
  74.5: 1.549,
  74.75: 1.546,
  75: 1.543,
  75.25: 1.541,
  75.5: 1.539,
  75.75: 1.536,
  76: 1.534,
  76.25: 1.531,
  76.5: 1.529,
  76.75: 1.526,
  77: 1.524,
  77.25: 1.521,
  77.5: 1.519,
  77.75: 1.517,
  78: 1.515,
  78.25: 1.512,
  78.5: 1.51,
  78.75: 1.508,
  79: 1.506,
  79.25: 1.503,
  79.5: 1.501,
  79.75: 1.499,
  80: 1.497,
  80.25: 1.494,
  80.5: 1.492,
  80.75: 1.49,
  81: 1.488,
  81.25: 1.485,
  81.5: 1.483,
  81.75: 1.481,
  82: 1.479,
  82.25: 1.477,
  82.5: 1.475,
  82.75: 1.473,
  83: 1.471,
  83.25: 1.469,
  83.5: 1.466,
  83.75: 1.464,
  84: 1.462,
  84.25: 1.46,
  84.5: 1.457,
  84.75: 1.455,
  85: 1.453,
  85.25: 1.451,
  85.5: 1.449,
  85.75: 1.447,
  86: 1.445,
  86.25: 1.443,
  86.5: 1.442,
  86.75: 1.44,
  87: 1.439,
  87.25: 1.437,
  87.5: 1.436,
  87.75: 1.434,
  88: 1.433,
  88.25: 1.431,
  88.5: 1.429,
  88.75: 1.428,
  89: 1.427,
  89.25: 1.425,
  89.5: 1.424,
  89.75: 1.422,
  90: 1.42,
  90.25: 1.418,
  90.5: 1.417,
  90.75: 1.415,
  91: 1.414,
  91.25: 1.412,
  91.5: 1.411,
  91.75: 1.409,
  92: 1.408,
  92.25: 1.407,
  92.5: 1.405,
  92.75: 1.404,
  93: 1.403,
  93.25: 1.401,
  93.5: 1.4,
  93.75: 1.398,
  94: 1.397,
  94.25: 1.395,
  94.5: 1.394,
  94.75: 1.392,
  95: 1.391,
  95.25: 1.389,
  95.5: 1.388,
  95.75: 1.386,
  96: 1.385,
  96.25: 1.383,
  96.5: 1.382,
  96.75: 1.38,
  97: 1.379,
  97.25: 1.377,
  97.5: 1.376,
  97.75: 1.375,
  98: 1.373,
  98.25: 1.373,
  98.5: 1.371,
  98.75: 1.37,
  99: 1.368,
  99.25: 1.366,
  99.5: 1.365,
  99.75: 1.364,
  100: 1.362,
  100.25: 1.361,
  100.5: 1.36,
  100.75: 1.359,
  101: 1.359,
  101.25: 1.358,
  101.5: 1.357,
  101.75: 1.356,
  102: 1.355,
  102.25: 1.354,
  102.5: 1.353,
  102.75: 1.352,
  103: 1.351,
  103.25: 1.35,
  103.5: 1.349,
  103.75: 1.348,
  104: 1.348,
  104.25: 1.347,
  104.5: 1.346,
  104.75: 1.345,
  105: 1.344,
  105.25: 1.343,
  105.5: 1.342,
  105.75: 1.341,
  106: 1.34,
  106.25: 1.339,
  106.5: 1.338,
  106.75: 1.338,
  107: 1.337,
  107.25: 1.336,
  107.5: 1.335,
  107.75: 1.334,
  108: 1.333,
  108.25: 1.332,
  108.5: 1.331,
  108.75: 1.331,
  109: 1.33,
  109.25: 1.329,
  109.5: 1.328,
  109.75: 1.327,
  110: 1.326,
  110.25: 1.325,
  110.5: 1.324,
  110.75: 1.323,
  111: 1.323,
  111.25: 1.322,
  111.5: 1.321,
  111.75: 1.32,
  112: 1.319,
  112.25: 1.319,
  112.5: 1.318,
  112.75: 1.318,
  113: 1.318,
  113.25: 1.318,
  113.5: 1.317,
  113.75: 1.317,
  114: 1.316,
  114.25: 1.316,
  114.5: 1.315,
  114.75: 1.315,
  115: 1.314,
  115.25: 1.314,
  115.5: 1.313,
  115.75: 1.313,
  116: 1.312,
  116.25: 1.312,
  116.5: 1.311,
  116.75: 1.311,
  117: 1.31,
  117.25: 1.31,
  117.5: 1.309,
  117.75: 1.309,
  118: 1.309,
  118.25: 1.308,
  118.5: 1.308,
  118.75: 1.307,
  119: 1.307,
  119.25: 1.307,
  119.5: 1.306,
  119.75: 1.306,
  120: 1.304,
  120.25: 1.303,
  120.5: 1.3,
  120.75: 1.295,
  121: 1.29,
  121.25: 1.284,
  121.5: 1.279,
  121.75: 1.273,
  122: 1.268,
  122.25: 1.263,
  122.5: 1.257,
  122.75: 1.252,
  123: 1.246,
  123.25: 1.242,
  123.5: 1.237,
  123.75: 1.233,
  124: 1.228,
  124.25: 1.224,
  124.5: 1.219,
  124.75: 1.215,
  125: 1.21,
  125.25: 1.206,
  125.5: 1.202,
  125.75: 1.198,
  126: 1.194,
  126.25: 1.19,
  126.5: 1.186,
  126.75: 1.182,
  127: 1.178,
  127.25: 1.174,
  127.5: 1.17,
  127.75: 1.166,
  128: 1.162,
  128.25: 1.158,
  128.5: 1.155,
  128.75: 1.151,
  129: 1.147,
  129.25: 1.143,
  129.5: 1.139,
  129.75: 1.136,
  130: 1.132,
  130.25: 1.128,
  130.5: 1.124,
  130.75: 1.12,
  131: 1.117,
  131.25: 1.114,
  131.5: 1.11,
  131.75: 1.107,
  132: 1.103,
  132.25: 1.1,
  132.5: 1.096,
  132.75: 1.093,
  133: 1.09,
  133.25: 1.087,
  133.5: 1.084,
  133.75: 1.081,
  134: 1.078,
  134.25: 1.075,
  134.5: 1.072,
  134.75: 1.069,
  135: 1.066,
  135.25: 1.063,
  135.5: 1.06,
  135.75: 1.057,
  136: 1.054,
  136.25: 1.051,
  136.5: 1.048,
  136.75: 1.046,
  137: 1.044,
  137.25: 1.041,
  137.5: 1.039,
  137.75: 1.036,
  138: 1.034,
  138.25: 1.031,
  138.5: 1.029,
  138.75: 1.026,
  139: 1.024,
  139.25: 1.022,
  139.5: 1.019,
  139.75: 1.017,
  140: 1.015,
  140.25: 1.013,
  140.5: 1.011,
  140.75: 1.008,
  141: 1.006,
  141.25: 1.004,
  141.5: 1.002,
  141.75: 1,
  142: 0.998,
  142.25: 0.996,
  142.5: 0.994,
  142.75: 0.992,
  143: 0.99,
  143.25: 0.988,
  143.5: 0.987,
  143.75: 0.985,
  144: 0.983,
  144.25: 0.981,
  144.5: 0.979,
  144.75: 0.978,
  145: 0.976,
  145.25: 0.974,
  145.5: 0.972,
  145.75: 0.97,
  146: 0.969,
  146.25: 0.967,
  146.5: 0.965,
  146.75: 0.963,
  147: 0.961,
  147.25: 0.96,
  147.5: 0.958,
  147.75: 0.956,
  148: 0.954,
  148.25: 0.952,
  148.5: 0.951,
  148.75: 0.95,
  149: 0.948,
  149.25: 0.947,
  149.5: 0.945,
  149.75: 0.944,
  150: 0.942,
  150.25: 0.941,
  150.5: 0.939,
  150.75: 0.938,
  151: 0.937,
  151.25: 0.935,
  151.5: 0.934,
  151.75: 0.933,
  152: 0.932,
  152.25: 0.931,
  152.5: 0.929,
  152.75: 0.928,
  153: 0.927,
  153.25: 0.926,
  153.5: 0.925,
  153.75: 0.924,
  154: 0.923,
  154.25: 0.922,
  154.5: 0.921,
  154.75: 0.92,
  155: 0.919,
  155.25: 0.918,
  155.5: 0.917,
  155.75: 0.916,
  156: 0.915,
  156.25: 0.914,
  156.5: 0.913,
  156.75: 0.912,
  157: 0.911,
  157.25: 0.91,
  157.5: 0.909,
  157.75: 0.909,
  158: 0.908,
  158.25: 0.907,
  158.5: 0.906,
  158.75: 0.905,
  159: 0.904,
  159.25: 0.904,
  159.5: 0.903,
  159.75: 0.902,
  160: 0.901,
  160.25: 0.9,
  160.5: 0.899,
};
