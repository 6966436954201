import React from "react";
import get from "lodash/get";
import map from "lodash/map";
import { formatEligibleRecord } from "util/records";
import { EligibleRecordWithWeight } from "types";
import FormattedEligibleRecord from "components/FormattedEligibleRecord";

const RecordsCell = ({
  data,
  column,
  style,
}: {
  data: any;
  column: any;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    return (
      <div style={style} className="ellipsis table-cell-inner">
        {map(get(lifter, column.key), (record: EligibleRecordWithWeight) => {
          const recordText = formatEligibleRecord({ record });

          return (
            <div key={recordText}>
              <FormattedEligibleRecord record={record} />
            </div>
          );
        })}
      </div>
    );
  }
};

export default RecordsCell;
