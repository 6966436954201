import each from "lodash/each";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import find from "lodash/find";
import uniqWith from "lodash/uniqWith";

import { isLbsMeet, getMeetUnits } from "../meetHelper";
import { getAttemptDisplay, getBestAttemptDisplay } from "../exportData";
import { getRealAge, getTotal, getIpfPoints } from "../lifterHelper";
import { CsvArray, FederationDivisionsConfig, Lifter, Meet } from "types";

const male53 = {
  name: "53",
  lbsName: "116.75lbs (53kg)",
  maxWeight: 53,
  lbsMaxWeight: 116.75,
};
const male59 = {
  name: "59",
  lbsName: "130.00lbs (59kg)",
  maxWeight: 59,
  lbsMaxWeight: 130.0,
};
const male66 = {
  name: "66",
  lbsName: "145.50lbs (66kg)",
  maxWeight: 66,
  lbsMaxWeight: 145.5,
};
const male74 = {
  name: "74",
  lbsName: "163.00lbs (74kg)",
  maxWeight: 74,
  lbsMaxWeight: 163.0,
};
const male83 = {
  name: "83",
  lbsName: "182.75lbs (83kg)",
  maxWeight: 83,
  lbsMaxWeight: 182.75,
};
const male93 = {
  name: "93",
  lbsName: "205.00lbs (93kg)",
  maxWeight: 93,
  lbsMaxWeight: 205.0,
};
const male105 = {
  name: "105",
  lbsName: "231.25lbs (105kg)",
  maxWeight: 105,
  lbsMaxWeight: 231.25,
};
const male120 = {
  name: "120",
  lbsName: "264.50lbs (120kg)",
  maxWeight: 120,
  lbsMaxWeight: 264.5,
};
const male120p = {
  name: "120+",
  lbsName: "264.50lbs+ (120kg+)",
  maxWeight: 9999,
  lbsMaxWeight: 9999,
};

const female43 = {
  name: "43",
  lbsName: "94.75lbs (43kg)",
  maxWeight: 43,
  lbsMaxWeight: 94.75,
};
const female47 = {
  name: "47",
  lbsName: "103.50lbs (47kg)",
  maxWeight: 47,
  lbsMaxWeight: 103.5,
};
const female52 = {
  name: "52",
  lbsName: "114.50lbs (52kg)",
  maxWeight: 52,
  lbsMaxWeight: 114.5,
};
const female57 = {
  name: "57",
  lbsName: "125.50lbs (57kg)",
  maxWeight: 57,
  lbsMaxWeight: 125.5,
};
const female63 = {
  name: "63",
  lbsName: "138.75lbs (63kg)",
  maxWeight: 63,
  lbsMaxWeight: 138.75,
};
const female72 = {
  name: "72",
  lbsName: "158.50lbs (72kg)",
  maxWeight: 72,
  lbsMaxWeight: 158.5,
};
const female84 = {
  name: "84",
  lbsName: "185.00lbs (84kg)",
  maxWeight: 84,
  lbsMaxWeight: 185.0,
};
const female84p = {
  name: "84+",
  lbsName: "185.00lbs+ (84kg+)",
  maxWeight: 9999,
  lbsMaxWeight: 9999,
};

const guest = {
  name: "All Guest",
  lbsName: "All Guest",
  maxWeight: 9999,
  lbsMaxWeight: 9999,
};

export const usaplWeightClasses = {
  junior: {
    MALE: [
      male53,
      male59,
      male66,
      male74,
      male83,
      male93,
      male105,
      male120,
      male120p,
    ],
    FEMALE: [
      female43,
      female47,
      female52,
      female57,
      female63,
      female72,
      female84,
      female84p,
    ],
  },
  adult: {
    MALE: [male59, male66, male74, male83, male93, male105, male120, male120p],
    FEMALE: [
      female47,
      female52,
      female57,
      female63,
      female72,
      female84,
      female84p,
    ],
  },
  guest: {
    MALE: [guest],
    FEMALE: [guest],
  },
};

// prettier-ignore
export const ssfBaseDivisions: FederationDivisionsConfig = [
  {name: "Sub-Junior", code: 'SJ',  low: 14,  high: 18,   default: true,  records: true,  weightClasses: usaplWeightClasses.junior},
  {name: "Junior",     code: 'J',   low: 18,  high: 23,   default: true,  records: true,  weightClasses: usaplWeightClasses.junior},
  {name: "Open",       code: 'O',   low: 24,  high: 39,   default: true,  records: true,  weightClasses: usaplWeightClasses.adult},
  {name: "Master I",   code: 'M1',  low: 40,  high: 49,   default: true,  records: true,  weightClasses: usaplWeightClasses.adult},
  {name: "Master II",  code: 'M2',  low: 50,  high: 59,   default: true,  records: true,  weightClasses: usaplWeightClasses.adult},
  {name: "Master III", code: 'M3',  low: 60,  high: 69,   default: true,  records: true,  weightClasses: usaplWeightClasses.adult},
  {name: "Master IV",  code: 'M4',  low: 70,  high: 999,  default: true,  records: true,  weightClasses: usaplWeightClasses.adult},
  {name: "Guest",      code: 'G',   low: 999, high: 9999, default: false, records: false,  weightClasses: usaplWeightClasses.guest}
];

export const getLifterSSFDivision = function (lifter: Lifter, meet: Meet) {
  const divisionAge = getRealAge(lifter, meet);
  if (divisionAge && divisionAge >= 8) {
    return ssfBaseDivisions.find((division) => {
      return (
        division.default &&
        division.low &&
        division.high &&
        divisionAge >= division.low &&
        divisionAge <= division.high
      );
    });
  }

  return null;
};

export const getLifterSSFWeightClass = function (
  lifter: Lifter,
  ipfDivisionCode: string,
  meet: Meet
) {
  const division = find(ssfBaseDivisions, { code: ipfDivisionCode });
  if (lifter.gender && division && division.name) {
    if (lifter.gender === "MX") {
      return;
    }
    const weightClasses = division.weightClasses[lifter.gender];
    const bodyWeight = lifter.bodyWeight;
    if (!bodyWeight) {
      return;
    }

    return find(weightClasses, (wc) => {
      let maxWeight = wc.maxWeight;
      if (isLbsMeet(meet)) {
        maxWeight = wc.lbsMaxWeight;
      }
      return bodyWeight <= maxWeight;
    });
  }
};

export const exportSSFResults = function (meet: Meet, dataArray: any) {
  let csvObject: CsvArray = [];
  each(dataArray, (lifter, index) => {
    if (!lifter || lifter.row === "title" || lifter.row === "header") {
      return;
    }

    const division = lifter.division;
    let divisionCode = division.usaplDivisionCode;
    if (!divisionCode || divisionCode === "AUTO") {
      const standardDivision = getLifterSSFDivision(lifter, meet);
      if (standardDivision?.code) {
        divisionCode = standardDivision.code;
      }
    } else if (divisionCode === "HIDE") {
      return;
    }
    const weightClass = getLifterSSFWeightClass(lifter, divisionCode, meet);

    // add division info to determine if cell should be displayed
    lifter = {
      ...lifter,
      division: {
        lifts: division.lifts,
      },
    };

    const row = {
      memberNumber: get(lifter, "restricted.memberNumber"),
      rawOrEquipped: division.rawOrEquipped,
      birthDate: lifter.birthDate,
      weightClass: get(weightClass, "name", ""),
      bodyWeight: lifter.bodyWeight,
      name: lifter.name,
      team: lifter.team,
      squat1: getAttemptDisplay(lifter, "squat", "1"),
      squat2: getAttemptDisplay(lifter, "squat", "2"),
      squat3: getAttemptDisplay(lifter, "squat", "3"),
      bestSquat: getBestAttemptDisplay(lifter, "squat"),
      bench1: getAttemptDisplay(lifter, "bench", "1"),
      bench2: getAttemptDisplay(lifter, "bench", "2"),
      bench3: getAttemptDisplay(lifter, "bench", "3"),
      bestBench: getBestAttemptDisplay(lifter, "bench"),
      dead1: getAttemptDisplay(lifter, "dead", "1"),
      dead2: getAttemptDisplay(lifter, "dead", "2"),
      dead3: getAttemptDisplay(lifter, "dead", "3"),
      bestDead: getBestAttemptDisplay(lifter, "dead"),
      total: getTotal(lifter, division._id, meet),
      ipfPoints: getIpfPoints(lifter, division._id, meet),
    };

    csvObject.push(row);
  });

  csvObject = sortBy(csvObject, [
    "compEvents",
    "division",
    (row) =>
      row.weightClass && typeof row.weightClass === "string"
        ? parseInt(row.weightClass, 10)
        : 0,
  ]);
  csvObject = uniqWith(csvObject, (a, b) => {
    return (
      a.name === b.name &&
      a.division === b.division &&
      a.compEvents === b.compEvents &&
      a.bodyWeight === b.bodyWeight &&
      a.birthDate === b.birthDate &&
      a.memberNumber === b.memberNumber
    );
  });

  const header = {
    memberNumber: "Grupp",
    rawOrEquipped: "Kl/Utr",
    birthDate: "Licens",
    weightClass: "Klass",
    bodyWeight: `Vikt - ${getMeetUnits(meet)}`,
    name: "Namn",
    team: "Förening",
    squat1: "KB 1",
    squat2: "KB 2",
    squat3: "KB 3",
    bestSquat: "KB",
    bench1: "BP 1",
    bench2: "BP 2",
    bench3: "BP 3",
    bestBench: "BP",
    dead1: "ML 1",
    dead2: "ML 2",
    dead3: "ML 3",
    bestDead: "ML",
    total: "Smlgt",
    ipfPoints: "IPF",
  };

  csvObject.unshift(header);

  return csvObject;
};
