import React from "react";
import { Link } from "react-router-dom";
import kebabCase from "lodash/kebabCase";
import map from "lodash/map";
import get from "lodash/get";
import classNames from "classnames";
import { reformatDateToUsFormat } from "util/dateHelper";

export const OnlineMeetsTable = ({
  title,
  meets,
  isLoadingMeets,
  isLoadingMeetsFailure,
}: {
  title: string;
  meets: {
    name: string;
    showOnHomePage: boolean;
    _id: string;
    date: string;
    dateFormat: string;
  }[];
  isLoadingMeets: boolean;
  isLoadingMeetsFailure: boolean;
}) => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [collapsedSetting, setCollapsedSetting] = React.useState(true);

  const anchor = kebabCase(title);

  const filteredMeets = meets.filter(
    (m) => m.name && m.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const collapsed =
    collapsedSetting &&
    !isLoadingMeetsFailure &&
    (isLoadingMeets || filteredMeets.length > 20);

  return (
    <div>
      <div
        className={classNames(
          "meets-table-table-wrapper",
          collapsed && "meets-table-collapsed"
        )}
      >
        <table className="table">
          <thead>
            <tr>
              <td colSpan={3}>
                <div className="table-title">
                  <a href={`#${anchor}`} id={anchor}>
                    {title}
                  </a>
                  <input
                    placeholder="Search..."
                    type="text"
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      setCollapsedSetting(false);
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th style={{ width: "70%" }}>Name</th>
              <th style={{ width: "30%" }}>Date</th>
            </tr>
          </thead>
          <tbody>
            {!isLoadingMeets &&
              !isLoadingMeetsFailure &&
              map(filteredMeets, (meet) => {
                if (!meet.showOnHomePage) {
                  return null;
                }

                return (
                  <tr key={meet._id}>
                    <td>
                      <Link to={`/meets/${meet._id}/results`}>
                        {get(meet, "name")}
                      </Link>
                    </td>
                    <td>
                      {reformatDateToUsFormat({
                        dateString: meet.date,
                        meet,
                      })}
                    </td>
                  </tr>
                );
              })}
            {isLoadingMeets && (
              <tr>
                <td colSpan={2}>Loading...</td>
              </tr>
            )}
            {isLoadingMeetsFailure && (
              <tr>
                <td colSpan={2}>Failed to load meet data!</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {collapsed && (
        <div className="meet-table-show-more">
          <button onClick={() => setCollapsedSetting(false)}>Show More</button>
        </div>
      )}
    </div>
  );
};
