import React from "react";
import { exportDivisions } from "util/exportData";
import { parseDivisions } from "util/importData";
import { addDivision } from "util/pouchActions";
import Import from "../Import";
import ImportDivisionsTable from "./ImportDivisionsTable";
import { useMeet } from "util/useMeet";
import { useMediaSize } from "util/useMediaSize";

const ImportDivisions = ({
  onFinishBulkCreate,
}: {
  onFinishBulkCreate: any;
}) => {
  const media = useMediaSize();
  const meet = useMeet();

  return (
    <Import
      label={"Divisions"}
      meet={meet}
      compareItems={meet.divisions}
      compareFunction={(item1: { name: string }, item2: { name: string }) =>
        item1.name === item2.name
      }
      addItem={addDivision}
      parseItems={parseDivisions}
      exportItems={exportDivisions}
      itemsTable={ImportDivisionsTable}
      media={media}
      onFinishBulkCreate={onFinishBulkCreate}
    />
  );
};

export default ImportDivisions;
