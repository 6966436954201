import React from 'react';

import { getSubtotal } from 'util/lifterHelper';

const TotalCell = ({data, column, meet, style}) => {
  const lifter = data;
  if(lifter.row === 'header') {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    return (
      <div style={style} className="table-cell-inner">
        { getSubtotal(data, meet) }
      </div>
    );
  }
};

export default TotalCell;
