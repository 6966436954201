import Papa from "papaparse";
import each from "lodash/each";
import every from "lodash/every";
import find from "lodash/find";
import get from "lodash/get";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import isUndefined from "lodash/isUndefined";
import mapValues from "lodash/mapValues";
import replace from "lodash/replace";
import split from "lodash/split";
import toLower from "lodash/toLower";
import toNumber from "lodash/toNumber";
import trim from "lodash/trim";

import {
  getSessionOptions,
  getFlightOptions,
  getStateOptions,
  getGenderOptions,
  getRawOrEquippedOptions,
  getScoreByOptions,
  getDivisionCodeOptions,
  getCountryOptions,
  getTestedOptions,
  getRecordLocationOptions,
  getRecordCompetitionTypeOptions,
  getRecordLiftOptions,
} from "util/options";
import { parseDateString, formatDate } from "util/dateHelper";
import {
  Division,
  EquipmentLevel,
  Gender,
  Lifter,
  Meet,
  RecordLocation,
  ScoreBy,
  RecordTested,
  RecordCompetitionType,
  RecordLift,
} from "types";

const findOptionValue = function (
  options: {
    label: string | number | undefined;
    value: string | number | undefined;
  }[],
  value: string | number | undefined
) {
  return get(
    find(options, (i) => {
      return (
        i.label === value ||
        i.value === value ||
        (typeof i.label === "string" &&
          typeof value === "string" &&
          toLower(i.label) === toLower(value)) ||
        (typeof i.value === "string" &&
          typeof value === "string" &&
          toLower(i.value) === toLower(value))
      );
    }),
    "value"
  );
};

const findSubDocumentByName = function (
  documents: Record<string, { _id: string; name?: string }>,
  value: string | number | boolean | undefined | null
) {
  return find(
    documents,
    (d) =>
      d.name === value ||
      (typeof d.name === "string" &&
        typeof value === "string" &&
        trim(toLower(d.name)) === trim(toLower(value)))
  );
};

const getIsEmptyRow = function (row: Record<string, string>) {
  return every(row, (value) => isNil(value) || value === "");
};

const cleanInput = function (row: Record<string, string>) {
  return mapValues(row, (value) => {
    value = replace(value, "’", "'");
    value = replace(value, "”", '"');
    value = replace(value, "‘", "'");
    value = replace(value, "“", '"');
    value = trim(value);
    return value;
  });
};

const cleanNumber = function (num: string) {
  if (isNaN(toNumber(num)) || isUndefined(num) || num === "") {
    return undefined;
  }

  return toNumber(num);
};

const dataMatch = function (submittedValue: any, convertedValue: any) {
  return !!submittedValue === !!convertedValue;
};

type NewLifter = Omit<Lifter, "restricted" | "lifts"> & {
  match?: any;
  lifts: any;
  memberNumber: any;
  email: any;
};

export const parseLifters = function (csvString: string, meet: Meet) {
  const liftersData = Papa.parse<Record<string, string>>(csvString, {
    header: true,
  });
  const newLifters: NewLifter[] = [];
  let previousLifter: any;
  each(liftersData.data, (lifter, index) => {
    const isEmptyRow = getIsEmptyRow(lifter);
    if (!isEmptyRow) {
      lifter = cleanInput(lifter);
      const newLifter: NewLifter = {
        _id: String(index),
        name: lifter.name,
        team: lifter.team,
        lot: cleanNumber(lifter.lot),
        platformId: get(
          findSubDocumentByName(meet.platforms, lifter.platform),
          "_id"
        ),
        session: findOptionValue(getSessionOptions(), lifter.session) as number,
        flight: findOptionValue(getFlightOptions(), lifter.flight) as string,
        birthDate:
          lifter.birthDate &&
          formatDate({
            date: parseDateString({ dateString: lifter.birthDate, meet }),
            meet,
          }),
        memberNumber: lifter.memberNumber,
        state: findOptionValue(
          getStateOptions(null, meet),
          lifter.state || lifter.province
        ) as string,
        country: findOptionValue(getCountryOptions(), lifter.country) as string,
        gender: findOptionValue(
          getGenderOptions(lifter, meet),
          lifter.gender
        ) as Gender,
        divisions: [],
        bodyWeight: cleanNumber(lifter.bodyWeight),
        squatRackHeight: lifter.squatRackHeight,
        benchRackHeight: lifter.benchRackHeight || lifter.pressRackHeight,
        lifts: {
          squat: {
            "1": {
              weight: cleanNumber(lifter.squat1),
            },
          },
          bench: {
            "1": {
              weight: cleanNumber(lifter.bench1 || lifter.press1),
            },
          },
          dead: {
            "1": {
              weight: cleanNumber(lifter.dead1),
            },
          },
        },
        email: lifter.email,
      };

      newLifter.match = {
        birthDate: dataMatch(lifter.birthDate, newLifter.birthDate),
        lot: dataMatch(lifter.lot, newLifter.lot),
        bodyWeight: dataMatch(lifter.bodyWeight, newLifter.bodyWeight),
        platformId: dataMatch(lifter.platform, newLifter.platformId),
        session: dataMatch(lifter.session, newLifter.session),
        flight: dataMatch(lifter.flight, newLifter.flight),
        state: dataMatch(lifter.state || lifter.province, newLifter.state),
        country: dataMatch(lifter.country, newLifter.country),
        gender: dataMatch(lifter.gender, newLifter.gender),
      };

      // consider rows with empty names to be extra divisions for previous row.
      const divisionRow = isEmpty(lifter.name) && previousLifter;
      const currentLifter = divisionRow ? previousLifter : newLifter;

      const divisionDocument = findSubDocumentByName(
        meet.divisions,
        lifter.division
      );
      const rawOrEquipped = findOptionValue(
        getRawOrEquippedOptions(null, meet),
        lifter.rawOrEquipped
      );
      const divisionId =
        get(divisionDocument, "rawOrEquipped") === rawOrEquipped &&
        get(divisionDocument, "gender") === currentLifter.gender
          ? get(divisionDocument, "_id")
          : null;

      const weightClasses = get(divisionDocument, "weightClasses");
      const declaredAwardsWeightClassId = weightClasses
        ? get(
            findSubDocumentByName(
              weightClasses,
              lifter.declaredAwardsWeightClass
            ),
            "_id"
          )
        : "";

      const newDivision: any = {
        rawOrEquipped,
        divisionId,
        declaredAwardsWeightClassId,
      };

      newDivision.match = {
        rawOrEquipped: dataMatch(lifter.rawOrEquipped, rawOrEquipped),
        divisionId: dataMatch(lifter.division, divisionId),
        declaredAwardsWeightClassId: dataMatch(
          lifter.declaredAwardsWeightClass,
          newDivision.declaredAwardsWeightClassId
        ),
      };

      if (divisionRow) {
        previousLifter.divisions.push(newDivision);
      } else {
        newLifter.divisions.push(newDivision);
        newLifters.push(newLifter);
        previousLifter = newLifter;
      }
    }
  });

  return newLifters;
};

type NewDivision = Division & { match?: any };

export const parseDivisions = function (csvString: string, meet: Meet) {
  const divisionsData = Papa.parse<Record<string, string>>(csvString, {
    header: true,
  });
  const newDivisions: NewDivision[] = [];
  let previousDivision: any;
  each(divisionsData.data, (division, index) => {
    const isEmptyRow = getIsEmptyRow(division);
    if (!isEmptyRow) {
      division = cleanInput(division);
      const lifts = { squat: false, bench: false, dead: false };
      const importedLifts = split(trim(division.lifts, " "), ",");
      each(importedLifts, (l) => {
        if (includes(toLower(l), "squat")) {
          lifts.squat = true;
        }
        if (includes(toLower(l), "bench")) {
          lifts.bench = true;
        }
        if (includes(toLower(l), "dead")) {
          lifts.dead = true;
        }
      });
      const newDivision: NewDivision = {
        _id: String(index),
        name: division.name,
        gender: findOptionValue(
          getGenderOptions(null, meet),
          division.gender
        ) as Gender,
        rawOrEquipped: findOptionValue(
          getRawOrEquippedOptions(null, meet),
          division.rawOrEquipped
        ) as EquipmentLevel,
        lifts,
        scoreBy: findOptionValue(
          getScoreByOptions(),
          division.scoreBy
        ) as ScoreBy,
        weightClasses: {},
        usaplDivisionCode: findOptionValue(
          getDivisionCodeOptions(null, meet),
          division.usaplDivisionCode
        ) as string,
        hideOnBoard: division.hideOnBoard === "true",
      };

      // TODO: handle lifts matching
      newDivision.match = {
        gender: dataMatch(division.gender, newDivision.gender),
        rawOrEquipped: dataMatch(
          division.rawOrEquipped,
          newDivision.rawOrEquipped
        ),
        scoreBy: dataMatch(division.scoreBy, newDivision.scoreBy),
        usaplDivisionCode: dataMatch(
          division.usaplDivisionCode,
          newDivision.usaplDivisionCode
        ),
      };

      const weightClassId = `w-${index}`;
      const newWeightClass = {
        _id: weightClassId,
        name: division.weightClassName,
        maxWeight: cleanNumber(division.maxWeight),
      };

      // consider rows with empty names to be extra weight classes for previous row.
      if (isEmpty(division.name)) {
        if (previousDivision) {
          previousDivision.weightClasses[weightClassId] = newWeightClass;
        }
      } else {
        newDivision.weightClasses[weightClassId] = newWeightClass;
        newDivisions.push(newDivision);
        previousDivision = newDivision;
      }
    }
  });

  return newDivisions;
};

export const parseRecords = function (csvString: string, meet: Meet) {
  const recordsData = Papa.parse<Record<string, string>>(csvString, {
    header: true,
  });

  const newRecords: any[] = [];
  each(recordsData.data, (record) => {
    const isEmptyRow = getIsEmptyRow(record);
    if (isEmptyRow) {
      return;
    }

    record = cleanInput(record);
    const gender = findOptionValue(
      getGenderOptions(null, meet),
      record.gender
    ) as Gender | undefined;
    const equipmentLevel = findOptionValue(
      getRawOrEquippedOptions(null, meet),
      record.equipmentLevel
    ) as EquipmentLevel | undefined;
    const recordWeight = isNaN(Number(record.recordWeight))
      ? undefined
      : Number(record.recordWeight);
    const divisionCode = findOptionValue(
      getDivisionCodeOptions(null, meet),
      record.divisionCode
    );
    let drugTested = findOptionValue(getTestedOptions(), record.drugTested) as
      | RecordTested
      | undefined;
    if (drugTested && drugTested.toLowerCase() !== "tested") {
      drugTested = undefined;
    }
    const location = findOptionValue(
      getRecordLocationOptions(),
      record.location
    ) as RecordLocation | undefined;

    const competitionType = findOptionValue(
      getRecordCompetitionTypeOptions(),
      record.competitionType
    ) as RecordCompetitionType | undefined;

    //
    const lift = findOptionValue(getRecordLiftOptions(), record.lift) as
      | RecordLift
      | undefined;

    const newRecord: any = {
      gender,
      equipmentLevel,
      drugTested,
      divisionCode,
      weightClass: record.weightClass,
      competitionType,
      lift,
      location,
      recordWeight: recordWeight || null,
    };

    // TODO: data matching for lifts and location
    newRecord.match = {
      gender: dataMatch(record.gender, newRecord.gender),
      equipmentLevel: dataMatch(
        record.equipmentLevel,
        newRecord.equipmentLevel
      ),
      drugTested: dataMatch(record.drugTested, newRecord.drugTested),
      divisionCode: dataMatch(record.divisionCode, newRecord.divisionCode),
      competitionType: dataMatch(
        record.competitionType,
        newRecord.competitionType
      ),
      lift: dataMatch(record.lift, newRecord.lift),
      location: dataMatch(record.location, newRecord.location),
    };

    newRecords.push(newRecord);
  });

  return newRecords;
};
