import React from "react";
import PouchCheckbox from "components/PouchCheckbox";
import { Column } from "components/table/Table";
import { Meet } from "types";
import get from "lodash/get";

const CheckboxCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: Column;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const division = data;
  if (division.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    return (
      <div style={style} className="table-cell-inner">
        <PouchCheckbox
          label={column.label}
          value={get(division, `${column.key}`)}
          name={column.key}
          meetId={meet._id}
          documentId={division._id}
        />
      </div>
    );
  }
};

export default CheckboxCell;
