import { Column } from "components/table/Table";
import map from "lodash/map";
import round from "lodash/round";
import startsWith from "lodash/startsWith";
import React from "react";
import { Meet } from "types";
import {
  getTotal,
  getBodyWeightAdjustedTotal,
  getBodyWeightAndAgeAdjustedTotal,
  getIpfPoints,
  getIpfAndAgePoints,
  getDotsPoints,
  getDotsAndAgePoints,
  getSchwartzMalonePoints,
  getSchwartzMaloneAndAgePoints,
  getGlossbrennerPoints,
  getGlossbrennerAndAgePoints,
  getParaDotsPoints,
  getKPoints,
  getPercentOfRecordPoints,
  getDivisionDoc,
  getReshelPoints,
  getReshelAndAgePoints,
} from "util/lifterHelper";

const scoreFunctions = {
  total: getTotal,
  forecastedTotal: getTotal,
  wilks: getBodyWeightAdjustedTotal,
  forecastedWilks: getBodyWeightAdjustedTotal,
  wilksAge: getBodyWeightAndAgeAdjustedTotal,
  forecastedWilksAge: getBodyWeightAndAgeAdjustedTotal,
  ipfPoints: getIpfPoints,
  forecastedIpfPoints: getIpfPoints,
  ipfAgePoints: getIpfAndAgePoints,
  forecastedIpfAgePoints: getIpfAndAgePoints,
  dotsPoints: getDotsPoints,
  forecastedDotsPoints: getDotsPoints,
  dotsAgePoints: getDotsAndAgePoints,
  forecastedDotsAgePoints: getDotsAndAgePoints,
  schwartzMalonePoints: getSchwartzMalonePoints,
  schwartzMaloneAndAgePoints: getSchwartzMaloneAndAgePoints,
  glossbrennerPoints: getGlossbrennerPoints,
  glossbrennerAndAgePoints: getGlossbrennerAndAgePoints,
  reshelPoints: getReshelPoints,
  reshelPointsAndAgePoints: getReshelAndAgePoints,
  paraDotsPoints: getParaDotsPoints,
  forecastedParaDotsPoints: getParaDotsPoints,
  percentOfRecord: getPercentOfRecordPoints,
  forecastedPercentOfRecord: getPercentOfRecordPoints,
  kPoints: getKPoints,
};

const ScoreCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: Column;
  meet: Meet;
  style: React.CSSProperties & { fontSize: number };
}) => {
  const lifter = data.lifter;
  if (data.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else if (data.row === "title") {
    return <div style={style} className="table-cell-inner" />;
  } else {
    if (lifter.divisions.length < 2) {
      style = { ...style, fontSize: style.fontSize * 1.3 };
    }
    return (
      <div style={style} className="table-cell-inner">
        {map(lifter.divisions, (lifterDivision, index: number) => {
          let rowStyle: React.CSSProperties = {
            paddingBottom: style.padding,
          };

          if (!lifterDivision.divisionId) {
            return null;
          }

          const division = getDivisionDoc(lifterDivision.divisionId, meet);
          if (!division) {
            return null;
          }

          if (division.hideOnBoard) {
            return null;
          }

          if (index !== 0) {
            rowStyle = {
              ...rowStyle,
              paddingTop: style.padding,
            };
          }

          const scoreFunc =
            scoreFunctions[column.key as keyof typeof scoreFunctions];

          return (
            <div
              key={`${lifterDivision.divisionId}-${index}`}
              style={rowStyle}
              className="cell-row"
            >
              {round(
                scoreFunc(
                  lifter,
                  lifterDivision.divisionId,
                  meet,
                  startsWith(column.key, "forecasted")
                ),
                3
              )}
            </div>
          );
        })}
      </div>
    );
  }
};

export default ScoreCell;
