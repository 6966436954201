const conversionFactor = 2.20462;

export const toKg = function (weight: number | null | undefined) {
  if (!weight) {
    return 0;
  }

  return weight / conversionFactor;
};

export const toLbs = function (weight: number | null | undefined) {
  if (!weight) {
    return 0;
  }
  return weight * conversionFactor;
};
