import React from "react";

import SortableHeaderCell from "components/SortableHeaderCell";
import NotEqualIcon from "icons/NotEqualIcon";
import { Meet } from "types";
import get from "lodash/get";
import find from "lodash/find";

const OptionCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: any;
  meet: Meet;
  style: any;
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return <SortableHeaderCell style={style} column={column} />;
  } else {
    const option = find(column.options(null, meet), {
      value: get(lifter, column.key),
    });
    const match = get(lifter, ["match", column.key]);
    return (
      <div style={style} className="table-cell-inner">
        {get(option, "label")}
        {!match && (
          <div className="mismatch-icon-wrapper">
            <NotEqualIcon />
          </div>
        )}
      </div>
    );
  }
};

export default OptionCell;
