import React from "react";
import DisplayComponent from "./DisplayComponent";
import { getAvailablePlates, getPlates } from "util/barLoad";
import { getMeetUnits } from "util/meetHelper";
import { Attempt, LiftingOrder, Meet, Platform } from "types";
import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import groupBy from "lodash/groupBy";
import truncate from "lodash/truncate";
import map from "lodash/map";

const NextLoad = ({
  meet,
  liftingOrder,
  currentAttempt,
  id,
  platform,
}: {
  meet: Meet;
  liftingOrder: LiftingOrder;
  currentAttempt: Attempt;
  id: string;
  platform: Platform;
}) => {
  let nextAttempt = get(liftingOrder, [1, "attempt"]);
  const nextLifter = get(liftingOrder, [1, "lifter"]);
  if (!nextAttempt) {
    nextAttempt = { weight: 200 } as Attempt;
  }
  let isError = false;
  let plates;
  const availablePlates = getAvailablePlates(meet.plates);
  try {
    plates = getPlates(nextAttempt.weight as number, availablePlates, platform);
  } catch (e) {
    isError = true;
  }

  const plateCounts = groupBy(plates, (plate) => {
    return plate.weight;
  });

  plates = uniqBy(plates, (plate) => {
    return plate.weight;
  });

  return (
    <DisplayComponent id={id} style={{ top: 535, left: 0 }}>
      {({ sizeMultiplier }: { sizeMultiplier: number }) => {
        if (isError) {
          return (
            <div style={{ color: "red", fontSize: 18 * sizeMultiplier }}>
              Unable to load {nextAttempt.weight} {meet.units} with available
              plates.
            </div>
          );
        }

        const style = {
          padding: sizeMultiplier * 12,
          fontSize: sizeMultiplier * 24,
          width: sizeMultiplier * 800,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          whiteSpace: "nowrap",
        } as const;

        const liftName = get(nextAttempt, "liftName");
        const rackHeight = get(nextLifter, `${liftName}RackHeight`, "");
        const lifterName = truncate(
          get(nextLifter, "name", "XXXXXXX XXXXXXX"),
          { length: 18, omission: "..." }
        );

        return (
          <div style={style}>
            NEXT - {lifterName} - {rackHeight ? `R: ${rackHeight} - ` : ""}{" "}
            {nextAttempt.weight}
            {getMeetUnits(meet)}
            {currentAttempt.weight !== nextAttempt.weight &&
              map(plates, (plate, index) => {
                return (
                  <span key={index}>
                    &nbsp;-&nbsp;{plate.weight}
                    {plateCounts[plate.weight].length > 1 && (
                      <span>({plateCounts[plate.weight].length})</span>
                    )}
                  </span>
                );
              })}
            {currentAttempt.weight === nextAttempt.weight && (
              <span>&nbsp;- SAME WEIGHT</span>
            )}
          </div>
        );
      }}
    </DisplayComponent>
  );
};

export default NextLoad;
