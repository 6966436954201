import React from "react";
import Modal from "react-modal";
import { NavLink, Link } from "react-router-dom";
import sortBy from "lodash/sortBy";
import get from "lodash/get";
import map from "lodash/map";
import { login, logout } from "util/pouchAuth";
import HamburgerIcon from "icons/HamburgerIcon";
import CloseIcon from "icons/CloseIcon";
import { OpenPasswordModal } from "types";
import { useMeet } from "util/useMeet";
import { useStatus } from "util/useStatus";

const Navigation = ({
  openPasswordModal,
}: {
  openPasswordModal: OpenPasswordModal;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const meet = useMeet();
  const { isOnline, isLocal, isLoggedIn } = useStatus();

  const openMenu = (e: any) => {
    setIsOpen(true);
    e.stopPropagation();
  };

  const closeMenu = (e?: any) => {
    setIsOpen(false);
    if (e) {
      e.stopPropagation();
    }
  };

  if (!meet._id || meet._id === "DEFAULT") {
    return null;
  }

  const baseUrl = `/meets/${meet._id}`;

  return (
    <div className="navigation">
      <button className="icon" onClick={openMenu}>
        <HamburgerIcon />
      </button>
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        closeTimeoutMS={400}
        onRequestClose={closeMenu}
        className={{
          base: "navigation-modal",
          afterOpen: "navigation-modal-after-open",
          beforeClose: "navigation-modal-before-close",
        }}
        overlayClassName={{
          base: "navigation-modal-overlay",
          afterOpen: "navigation-modal-overlay-after-open",
          beforeClose: "navigation-modal-overlay-before-close",
        }}
        contentLabel="Navigation Menu"
      >
        <div className="close-button" onClick={closeMenu}>
          <CloseIcon />
        </div>
        <div className="content">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            {isLocal && (
              <li>
                <NavLink to={`${baseUrl}/setup`} onClick={closeMenu}>
                  Setup
                </NavLink>
              </li>
            )}
            {isLocal && (
              <li>
                <NavLink to={`${baseUrl}/import/divisions`} onClick={closeMenu}>
                  Import
                </NavLink>
              </li>
            )}
            {isLocal && (
              <li>
                <NavLink to={`${baseUrl}/divisions`} onClick={closeMenu}>
                  Awards Divisions
                </NavLink>
              </li>
            )}
            {isLocal && meet.records && (
              <li>
                <NavLink to={`${baseUrl}/records`} onClick={closeMenu}>
                  Records
                </NavLink>
              </li>
            )}
            {isLocal && (
              <li>
                <NavLink to={`${baseUrl}/lifters`} onClick={closeMenu}>
                  Lifters
                </NavLink>
              </li>
            )}
            {isLocal && (
              <li>
                <NavLink to={`${baseUrl}/weighInEntry`} onClick={closeMenu}>
                  Weigh In Entry
                </NavLink>
              </li>
            )}
            {isLocal &&
              (meet.federation === "USAPL" ||
                meet.federation === "USSF" ||
                !meet.federation) && (
                <li>
                  <NavLink
                    to={`${baseUrl}/membershipChecker`}
                    onClick={closeMenu}
                  >
                    Membership Checker
                  </NavLink>
                </li>
              )}
            {isLocal && (
              <li>
                <NavLink
                  to={`${baseUrl}/paperwork/checkInSheet`}
                  onClick={closeMenu}
                >
                  Paperwork
                </NavLink>
              </li>
            )}
            {isLocal && (
              <li>
                <NavLink to={`${baseUrl}/stats`} onClick={closeMenu}>
                  Stats
                </NavLink>
              </li>
            )}
            {isLocal && (
              <li>
                <NavLink
                  to={`${baseUrl}/liveFeed/version3`}
                  onClick={closeMenu}
                >
                  Streaming Feed
                </NavLink>
              </li>
            )}
            <li>
              <NavLink to={`${baseUrl}/results`} onClick={closeMenu}>
                Results
              </NavLink>
            </li>
            <li>
              <NavLink to={`${baseUrl}/roster`} onClick={closeMenu}>
                Roster
              </NavLink>
            </li>
            {map(sortBy(meet.platforms, "name"), (platform) => {
              return (
                <li key={platform._id}>
                  Platform - {platform.name}
                  <ul className="platform">
                    {isLocal && (
                      <li>
                        <NavLink
                          to={`${baseUrl}/platforms/${platform._id}/run`}
                          onClick={closeMenu}
                        >
                          Run
                        </NavLink>
                      </li>
                    )}
                    <li>
                      <NavLink
                        to={`${baseUrl}/platforms/${platform._id}/board${
                          meet.boardDefaultURL ? `?${meet.boardDefaultURL}` : ""
                        }`}
                        onClick={closeMenu}
                      >
                        Board
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`${baseUrl}/platforms/${platform._id}/display${
                          meet.displayDefaultURL
                            ? `?${meet.displayDefaultURL}`
                            : ""
                        }`}
                        onClick={closeMenu}
                      >
                        Display
                      </NavLink>
                    </li>
                    {isLocal && (
                      <li>
                        <NavLink
                          to={`${baseUrl}/platforms/${platform._id}/refs/left`}
                          onClick={closeMenu}
                        >
                          Ref - Left
                        </NavLink>
                      </li>
                    )}
                    {isLocal && (
                      <li>
                        <NavLink
                          to={`${baseUrl}/platforms/${platform._id}/refs/head`}
                          onClick={closeMenu}
                        >
                          Ref - Head
                        </NavLink>
                      </li>
                    )}
                    {isLocal && (
                      <li>
                        <NavLink
                          to={`${baseUrl}/platforms/${platform._id}/refs/right`}
                          onClick={closeMenu}
                        >
                          Ref - Right
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
              );
            })}
            {get(meet, "entryConfig.isOpen") && (
              <li>
                <NavLink to={`${baseUrl}/registration`} onClick={closeMenu}>
                  Registration
                </NavLink>
              </li>
            )}
            {meet.lifterRackHeightsEnabled && (
              <li>
                <NavLink to={`${baseUrl}/rackHeights`} onClick={closeMenu}>
                  Enter Rack Heights
                </NavLink>
              </li>
            )}
            {meet.virtualMeet && (
              <li>
                <NavLink
                  to={`${baseUrl}/virtualMeetSubmit`}
                  onClick={closeMenu}
                >
                  Virtual Attempt Form
                </NavLink>
              </li>
            )}
            {isLoggedIn && (
              <li>
                <button
                  onClick={() => {
                    logout(meet._id);
                    closeMenu();
                  }}
                >
                  Logout
                </button>
              </li>
            )}
            {!isLoggedIn && (!isLocal || isOnline) && (
              <li>
                <button
                  onClick={() => {
                    login(meet._id, null, openPasswordModal);
                    closeMenu();
                  }}
                >
                  Meet Director Login
                </button>
              </li>
            )}
          </ul>
        </div>
      </Modal>
    </div>
  );
};

export default Navigation;
