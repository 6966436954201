import React from "react";
import Modal from "react-modal";
import { updateAttributeOnDocument } from "util/pouchActions";
import { getFlightOptions } from "util/options";
import Checkbox from "components/checkbox/Checkbox";
import size from "lodash/size";
import shuffle from "lodash/shuffle";
import { Meet } from "types";
import each from "lodash/each";
import filter from "lodash/filter";

const GenerateLotNumbersModal = ({
  meet,
  onRequestClose,
  isOpen,
  onFinishBulkCreate,
}: {
  meet: Meet;
  onRequestClose: () => void;
  isOpen: boolean;
  onFinishBulkCreate: any;
}) => {
  const [orderByFlight, setOrderByFlight] = React.useState(true);

  const onCheckOrderByFlight = (value: boolean) => {
    setOrderByFlight(value);
  };

  const generateLotNumbers = () => {
    if (orderByFlight) {
      generateLotNumbersByFlight();
    } else {
      generateLotNumbersByAll();
    }
    onFinishBulkCreate(size(meet.lifters));
  };

  const generateLotNumbersByAll = () => {
    const totalLifters = size(meet.lifters);
    const shuffledLifters = shuffle(meet.lifters);
    let currentLotNumber = 10;
    if (totalLifters >= 100) {
      currentLotNumber = 100;
    }
    each(shuffledLifters, (lifter) => {
      updateAttributeOnDocument(meet._id, lifter._id, "lot", currentLotNumber);
      currentLotNumber++;
    });

    onRequestClose();
  };

  const generateLotNumbersByFlight = () => {
    const flightOptions = getFlightOptions();
    let flightCount = 1;
    each(flightOptions, (flightOption) => {
      let currentLotNumber = 10;
      const flightLifters = filter(meet.lifters, {
        flight: flightOption.value,
      });
      const shuffledLifters = shuffle(flightLifters);
      each(shuffledLifters, (lifter) => {
        const newLotNumber = parseInt(
          flightCount.toString() + currentLotNumber.toString(),
          10
        );
        updateAttributeOnDocument(meet._id, lifter._id, "lot", newLotNumber);
        currentLotNumber++;
      });
      flightCount++;
    });

    onRequestClose();
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      contentLabel="Generate Lot Numbers"
      onRequestClose={onRequestClose}
      className={{
        base: "generate-lot-numbers-modal",
        afterOpen: "generate-lot-numbers-modal-after-open",
        beforeClose: "generate-lot-numbers-modal-before-close",
      }}
      overlayClassName={{
        base: "generate-lot-numbers-modal-overlay",
        afterOpen: "generate-lot-numbers-modal-overlay-after-open",
        beforeClose: "generate-lot-numbers-modal-overlay-before-close",
      }}
    >
      <div className="content">
        <h2>Generate Lot Numbers</h2>
        <p>
          WARNING: THIS WILL OVERWRITE ALL LOT NUMBERS. THIS CANNOT BE UNDONE.
        </p>
        <Checkbox
          label={
            <>
              <div>Order Lot Numbers By Flight</div>
              <div>(requires flights to have been assigned)</div>
            </>
          }
          value={orderByFlight}
          onCheck={onCheckOrderByFlight}
        />
      </div>
      <div className="button-row">
        <button onClick={onRequestClose} style={{ marginRight: 12 }}>
          Cancel
        </button>
        <button onClick={generateLotNumbers}>Generate</button>
      </div>
    </Modal>
  );
};

export default GenerateLotNumbersModal;
