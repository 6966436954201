import React from "react";
import { Link } from "react-router-dom";
import {
  getPlatformOptions,
  getSessionOptions,
  getFlightOptions,
} from "util/options";
import "./RosterIndex.scss";
import map from "lodash/map";
import { Lifter, Meet } from "types";
import sortBy from "lodash/sortBy";
import get from "lodash/get";
import { useMeet } from "util/useMeet";
import filter from "lodash/filter";
import size from "lodash/size";

const LiftersList = ({ lifters, meet }: { lifters: Lifter[]; meet: Meet }) => {
  const sortedLifters = sortBy(lifters, ["lot", "name"]);
  return (
    <ul>
      {map(sortedLifters, (lifter) => {
        return (
          <li key={lifter._id}>
            <Link to={`/meets/${get(meet, "_id")}/lifter/${lifter._id}`}>
              {lifter.lot && <span>{lifter.lot} - </span>}
              {lifter.name}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

const SessionList = ({
  lifters,
  meet,
  session,
  platforms,
}: {
  lifters: Record<string, Lifter>;
  meet: Meet;
  session: { label: string; value: number };
  platforms: { label: string | undefined; value: string }[];
}) => {
  const sessionLifters = filter(lifters, { session: session.value });
  const sessionCount = size(sessionLifters);

  if (!sessionCount) {
    return null;
  }

  const platformUnassignedLifters = filter(
    sessionLifters,
    (l) => !l.platformId
  );
  const platformUnassignedCount = size(platformUnassignedLifters);

  return (
    <div key={session.value} className="session-roster">
      <h2>
        Session {session.label} ({sessionCount})
      </h2>
      <div className="session-content">
        {platformUnassignedCount !== 0 && (
          <div className="platform-list-wrapper">
            <h3>Platform Unassigned ({platformUnassignedCount})</h3>
            <LiftersList meet={meet} lifters={platformUnassignedLifters} />
          </div>
        )}
        {map(platforms, (platform) => {
          return (
            <PlatformList
              key={platform.value}
              sessionLifters={sessionLifters}
              platform={platform}
              meet={meet}
            />
          );
        })}
      </div>
    </div>
  );
};

const PlatformList = ({
  sessionLifters,
  platform,
  meet,
}: {
  sessionLifters: Lifter[];
  platform: { label: string | undefined; value: string };
  meet: Meet;
}) => {
  const platformLifters = filter(sessionLifters, {
    platformId: platform.value,
  });
  const platformCount = size(platformLifters);

  if (!platformCount) {
    return null;
  }

  const flightUnassignedLifters = filter(platformLifters, (l) => !l.flight);
  const flightUnassignedCount = size(flightUnassignedLifters);

  return (
    <div className="platform-list-wrapper">
      <h3>
        Platform {platform.label} ({platformCount})
      </h3>
      <ul className="platform-list">
        {flightUnassignedCount !== 0 && (
          <li>
            Flight Unassigned ({flightUnassignedCount})
            <LiftersList meet={meet} lifters={flightUnassignedLifters} />
          </li>
        )}
        {map(getFlightOptions(), (flight) => {
          const flightLifters = filter(platformLifters, {
            flight: flight.value,
          });
          const flightCount = size(flightLifters);

          if (!flightCount) {
            return null;
          }

          return (
            <li key={flight.value}>
              Flight {flight.label} ({flightCount})
              <LiftersList meet={meet} lifters={flightLifters} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const RosterIndex = () => {
  const meet = useMeet();
  const lifters = meet.lifters;
  const platforms = getPlatformOptions({}, meet);

  const sessionUnassignedLifters = filter(lifters, (l) => !l.session);
  const sessionUnassignedCount = size(sessionUnassignedLifters);

  return (
    <div className="roster-index">
      <div className="meet-info-row">
        <label>Total Lifters:</label> ({size(lifters)})
      </div>
      <div className="meet-info-row">
        <label>Date:</label> {meet.date}
      </div>
      <div className="meet-info-row">
        <label>Units:</label> {meet.units}
      </div>
      {sessionUnassignedCount !== 0 && (
        <div className="session-roster">
          <h2>Session Unassigned ({sessionUnassignedCount})</h2>
          <div className="session-content">
            <LiftersList meet={meet} lifters={sessionUnassignedLifters} />
          </div>
        </div>
      )}

      {map(getSessionOptions(), (session) => {
        return (
          <SessionList
            key={session.value}
            lifters={lifters}
            meet={meet}
            platforms={platforms}
            session={session}
          />
        );
      })}
    </div>
  );
};

export default RosterIndex;
