import React from "react";
import CheckIcon from "icons/CheckIcon";

export const FeatureComparison = () => {
  return (
    <div className="meets-table-table-wrapper">
      <table className="table features-table">
        <thead>
          <tr>
            <th colSpan={3}>
              <a href="#features" id="features">
                Features
              </a>
            </th>
          </tr>
          <tr>
            <th></th>
            <th>Free</th>
            <th>Paid</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Auto Calculate Lifting Order</td>
            <td>
              <CheckIcon />
            </td>
            <td>
              <CheckIcon />
            </td>
          </tr>
          <tr>
            <td>Auto Calculate Results</td>
            <td>
              <CheckIcon />
            </td>
            <td>
              <CheckIcon />
            </td>
          </tr>
          <tr>
            <td>Attempt Timer</td>
            <td>
              <CheckIcon />
            </td>
            <td>
              <CheckIcon />
            </td>
          </tr>
          <tr>
            <td>Custom Awards Division Setup</td>
            <td>
              <CheckIcon />
            </td>
            <td>
              <CheckIcon />
            </td>
          </tr>
          <tr>
            <td>Multiple Divisions Per Lifter</td>
            <td>
              <CheckIcon />
            </td>
            <td>
              <CheckIcon />
            </td>
          </tr>
          <tr>
            <td>4th Attempts</td>
            <td>
              <CheckIcon />
            </td>
            <td>
              <CheckIcon />
            </td>
          </tr>
          <tr>
            <td>Print Lifter Attempt Cards</td>
            <td>
              <CheckIcon />
            </td>
            <td>
              <CheckIcon />
            </td>
          </tr>
          <tr>
            <td>Download Backup of Your Meet Data</td>
            <td>
              <CheckIcon />
            </td>
            <td>
              <CheckIcon />
            </td>
          </tr>
          <tr>
            <td>Export Results</td>
            <td>
              <CheckIcon />
            </td>
            <td>
              <CheckIcon />
            </td>
          </tr>
          <tr>
            <td>Export Division Configuration</td>
            <td>
              <CheckIcon />
            </td>
            <td>
              <CheckIcon />
            </td>
          </tr>
          <tr>
            <td>Export Lifter Data</td>
            <td>
              <CheckIcon />
            </td>
            <td>
              <CheckIcon />
            </td>
          </tr>
          <tr>
            <td>Import Division and Lifters</td>
            <td>
              <CheckIcon />
            </td>
            <td>
              <CheckIcon />
            </td>
          </tr>
          <tr>
            <td>Custom Configure Display Page</td>
            <td>
              <CheckIcon />
            </td>
            <td>
              <CheckIcon />
            </td>
          </tr>
          <tr>
            <td>Custom Plate Colors</td>
            <td>
              <CheckIcon />
            </td>
            <td>
              <CheckIcon />
            </td>
          </tr>
          <tr>
            <td>Live Video Feed Overlays</td>
            <td>
              <CheckIcon />
            </td>
            <td>
              <CheckIcon />
            </td>
          </tr>
          <tr>
            <td>Broadcast Live Data Online</td>
            <td></td>
            <td>
              <CheckIcon />
            </td>
          </tr>
          <tr>
            <td>Online Backup of Data</td>
            <td></td>
            <td>
              <CheckIcon />
            </td>
          </tr>
          <tr>
            <td>Sync Data Across Multiple Computers</td>
            <td></td>
            <td>
              <CheckIcon />
            </td>
          </tr>
          <tr>
            <td>Combine Results Across Multiple Plaforms</td>
            <td></td>
            <td>
              <CheckIcon />
            </td>
          </tr>
          <tr>
            <td>Online Lifter Registration</td>
            <td></td>
            <td>
              <CheckIcon />
            </td>
          </tr>
          <tr>
            <td>Run a Virtual Meet</td>
            <td></td>
            <td>
              <CheckIcon />
            </td>
          </tr>
          <tr>
            <td>Ref Lights</td>
            <td></td>
            <td>
              <CheckIcon />
            </td>
          </tr>
          <tr>
            <td>Lifters Enter Their Own Rack Heights</td>
            <td></td>
            <td>
              <CheckIcon />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
