import React from "react";
import SortableHeaderCell from "components/SortableHeaderCell";
import get from "lodash/get";
import { Column } from "components/table/Table";

const DefaultTextCell = ({
  data,
  column,
  style,
}: {
  data: any;
  column: Column;
  style: React.CSSProperties;
}) => {
  if (data.row === "header") {
    if (column.sortable) {
      return <SortableHeaderCell style={style} column={column} />;
    }
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    return (
      <div style={style} className="table-cell-inner">
        {get(data, column.key)}
      </div>
    );
  }
};

export default DefaultTextCell;
