import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { getFutureLiftingOrder } from "selectors";
import { LiftingOrder, ReduxState } from "types";

export const useFutureLiftingOrder = () => {
  const { meetId, platformId } = useParams<{
    meetId: string;
    platformId: string;
  }>();

  const props = React.useMemo(() => {
    return { match: { params: { meetId, platformId } } };
  }, [meetId, platformId]);

  const futureLiftingOrder = useSelector((state: ReduxState) =>
    getFutureLiftingOrder(state, props)
  ) as LiftingOrder;

  return futureLiftingOrder;
};
