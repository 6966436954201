import React, { Component } from "react";
import compact from "lodash/compact";
import get from "lodash/get";
import filter from "lodash/filter";
import size from "lodash/size";
import each from "lodash/each";
import capitalize from "lodash/capitalize";
import findIndex from "lodash/findIndex";

import AdjustableColumnsTable from "components/table/AdjustableColumnsTable";
import NameCell from "./cells/NameCell";
import DisplayComponent from "./DisplayComponent";
import DefaultCell from "./cells/DefaultCell";
import AttemptCell from "./cells/AttemptCell";
import DivisionCell from "./cells/DivisionCell";
import WeightClassCell from "./cells/WeightClassCell";
import SubtotalCell from "./cells/SubtotalCell";
import ScoreCell from "./cells/ScoreCell";
import PlaceCell from "./cells/PlaceCell";
import ForecastedPlaceCell from "./cells/ForecastedPlaceCell";
import FlagCell from "./cells/FlagCell";

import GearIcon from "icons/GearIcon";
import { getBenchLabel } from "util/meetHelper";
import { getDivisionDoc } from "util/lifterHelper";

class OrderTable extends Component {
  state = {
    isSettingsModalOpen: false,
  };

  getAllColumns = () => {
    const federationConfig = this.props.federationConfig;
    const showFourthAttempts = !!federationConfig?.fourthAttempts;

    // prettier-ignore
    return compact([
      {key: 'name', label: 'Name', width: 170, renderer: NameCell, visible: true, locked: true},
      {key: 'country', label: 'Country', width: 65, renderer: DefaultCell, visible: false},
      {key: 'flag', label: 'Flag', width: 60, renderer: FlagCell, visible: false},
      {key: 'team', label: 'Team', width: 100, renderer: DefaultCell, visible: false},
      {key: 'lot', label: 'Lot', width: 55, renderer: DefaultCell, visible: false},
      {key: 'bodyWeight', label: 'Body Wgt', width: 70, renderer: DefaultCell, visible: true},
      {key: 'squat.1.weight', label: 'Squat 1', width: 60, lift: 'squat', attemptNumber: 1, renderer: AttemptCell, visible: true},
      {key: 'squat.2.weight', label: 'Squat 2', width: 60, lift: 'squat', attemptNumber: 2, renderer: AttemptCell, visible: true},
      {key: 'squat.3.weight', label: 'Squat 3', width: 60, lift: 'squat', attemptNumber: 3, renderer: AttemptCell, visible: true},
      showFourthAttempts ? {key: 'squat.4.weight', label: 'Squat 4', width: 60, lift: 'squat', attemptNumber: 4, renderer: AttemptCell, visible: true} : undefined,
      {key: 'bench.1.weight', label: `${getBenchLabel(this.props.meet)} 1`, width: 61, lift: 'bench', attemptNumber: 1, renderer: AttemptCell, visible: true},
      {key: 'bench.2.weight', label: `${getBenchLabel(this.props.meet)} 2`, width: 61, lift: 'bench', attemptNumber: 2, renderer: AttemptCell, visible: true},
      {key: 'bench.3.weight', label: `${getBenchLabel(this.props.meet)} 3`, width: 61, lift: 'bench', attemptNumber: 3, renderer: AttemptCell, visible: true},
      showFourthAttempts ? {key: 'bench.4.weight', label: `${getBenchLabel(this.props.meet)} 4`, width: 61, lift: 'bench', attemptNumber: 4, renderer: AttemptCell, visible: true} : undefined,
      {key: 'subtotal', label: 'Subtotal', width: 65, renderer: SubtotalCell, visible: true},
      {key: 'dead.1.weight', label: 'Dead 1', width: 60, lift: 'dead', attemptNumber: 1, renderer: AttemptCell, visible: true},
      {key: 'dead.2.weight', label: 'Dead 2', width: 60, lift: 'dead', attemptNumber: 2, renderer: AttemptCell, visible: true},
      {key: 'dead.3.weight', label: 'Dead 3', width: 60, lift: 'dead', attemptNumber: 3, renderer: AttemptCell, visible: true},
      showFourthAttempts ? {key: 'dead.4.weight', label: 'Dead 4', width: 60, lift: 'dead', attemptNumber: 4, renderer: AttemptCell, visible: true} : undefined,
      {key: 'divisionId', label: 'Division', width: 170, renderer: DivisionCell, visible: true},
      {key: 'weightClass', label: 'Weight Class', width: 130, renderer: WeightClassCell, visible: true},
      {key: 'total', label: 'Total', width: 60, renderer: ScoreCell, visible: true},
      {key: 'forecastedTotal', label: 'Forecasted Total', width: 90, renderer: ScoreCell, visible: false},
      {key: 'wilks', label: 'Wilks', width: 80, renderer: ScoreCell, visible: false},
      {key: 'forecastedWilks', label: 'Forecasted Wilks', width: 90, renderer: ScoreCell, visible: false},
      {key: 'wilksAge', label: 'Wilks/Age', width: 75, renderer: ScoreCell, visible: false},
      {key: 'forecastedWilksAge', label: 'Forecasted Wilks/Age', width: 90, renderer: ScoreCell, visible: false},
      {key: 'ipfPoints', label: 'IPF Pts', width: 75, renderer: ScoreCell, visible: false},
      {key: 'forecastedIpfPoints', label: 'Forecasted IPF Pts', width: 90, renderer: ScoreCell, visible: false},
      {key: 'ipfAgePoints', label: 'IPF/Age Pts', width: 75, renderer: ScoreCell, visible: false},
      {key: 'forecastedIpfAgePoints', label: 'Forecasted IPF/Age Pts', width: 100, renderer: ScoreCell, visible: false},
      {key: 'dotsPoints', label: 'DOTS Pts', width: 75, renderer: ScoreCell, visible: false},
      {key: 'forecastedDotsPoints', label: 'Forecasted DOTS Pts', width: 90, renderer: ScoreCell, visible: false},
      {key: 'dotsAgePoints', label: 'DOTS/Age Pts', width: 75, renderer: ScoreCell, visible: false},
      {key: 'forecastedDotsAgePoints', label: 'Forecasted DOTS/Age Pts', width: 100, renderer: ScoreCell, visible: false},
      {key: 'schwartzMalonePoints', label: 'SM', width: 75, renderer: ScoreCell, visible: false},
      {key: 'schwartzMaloneAndAgePoints', label: 'S/M/Age', width: 100, renderer: ScoreCell, visible: false},
      {key: 'glossbrennerPoints', label: 'Gloss', width: 75, renderer: ScoreCell, visible: false},
      {key: 'glossbrennerAndAgePoints', label: 'Gloss/Age', width: 100, renderer: ScoreCell, visible: false},
      {key: 'reshelPoints', label: 'Reshel', width: 75, renderer: ScoreCell, visible: false},
      {key: 'reshelPointsAndAgePoints', label: 'Reshel/Age', width: 90, renderer: ScoreCell, visible: false},
      {key: 'paraDotsPoints', label: 'para DOTS', width: 75, renderer: ScoreCell, visible: false},
      {key: 'forecastedParaDotsPoints', label: 'Forecasted paraDOTS', width: 85, renderer: ScoreCell, visible: false},
      {key: 'kPoints', label: 'K-Points', width: 75, renderer: ScoreCell, visible: false},
      {key: 'percentOfRecord', label: '% Score', width: 75, renderer: ScoreCell, visible: false},
      {key: 'forecastedPercentOfRecord', label: 'Forecasted % Score', width: 90, renderer: ScoreCell, visible: false},
      {key: 'place', label: 'Place', width: 65, renderer: PlaceCell, visible: true},
      {key: 'forecastedPlace', label: 'Forecasted Place', width: 85, renderer: ForecastedPlaceCell, visible: false}
    ]);
  };

  getCellLines = (index, sortedAttempts, sizeMultiplier) => {
    const lifter = get(sortedAttempts, index, {});
    if (lifter.row === "title") {
      return 0.5 * sizeMultiplier;
    }
    // adjustment used to shrink row heigh relative to the base height provided by Table compoenent
    const adjustment = 0.65;
    const lifterDivisions = get(
      sortedAttempts,
      [index, "lifter", "divisions"],
      []
    );
    const divisionsToShow = filter(lifterDivisions, (lifterDivision) => {
      const divisionDoc = getDivisionDoc(
        lifterDivision.divisionId,
        this.props.meet
      );
      return !get(divisionDoc, "hideOnBoard");
    });
    const rows = size(divisionsToShow);
    if (rows === 0) {
      return 1 * sizeMultiplier * adjustment;
    }

    return rows * sizeMultiplier * adjustment;
  };

  createDataArray = (sizeMultiplier) => {
    const { completeLiftingOrder } = this.props;
    let lastRound = 0;
    let lastSession = 0;
    const order = [{ row: "header", sizeMultiplier }];
    each(completeLiftingOrder, (row) => {
      if (lastSession !== row.lifter.session) {
        order.push({ row: "title", name: `Session ${row.lifter.session}` });
      } else if (lastRound !== row.attemptNumber) {
        order.push({
          row: "title",
          name: `${capitalize(row.attempt.liftName)} - Flight ${
            row.flight
          } - Round ${row.attemptNumber} `,
        });
      }
      order.push(row);
      lastRound = row.attemptNumber;
      lastSession = row.lifter.session;
    });

    return order;
  };

  openSettingsModal = () => {
    this.setState({ isSettingsModalOpen: true });
  };

  closeSettingsModal = () => {
    this.setState({ isSettingsModalOpen: false });
  };

  render() {
    const { meet, id, platform } = this.props;

    return (
      <DisplayComponent style={{ top: 205, left: 0 }} id={id} canAdjustHeight>
        {({ sizeMultiplier, heightMultiplier }) => {
          const rows = this.createDataArray(sizeMultiplier);
          const hightlightRow = findIndex(
            rows,
            (row) => get(row, "attemptId") === get(platform, "currentAttemptId")
          );
          const scrollToRow = hightlightRow - 1;

          const style = {
            fontSize: sizeMultiplier * 12,
            padding: sizeMultiplier * 12,
            height: sizeMultiplier * (heightMultiplier * 350),
          };

          const cellStyle = {
            fontSize: sizeMultiplier * 12,
            padding: 8 * sizeMultiplier,
          };

          return (
            <div style={style} className="order-table">
              <button
                className="settings-button"
                onClick={this.openSettingsModal}
              >
                <GearIcon />
              </button>

              <AdjustableColumnsTable
                autoWidth
                cellStyle={cellStyle}
                data={rows}
                columns={this.getAllColumns()}
                getCellLines={(index) =>
                  this.getCellLines(index, rows, sizeMultiplier)
                }
                meet={meet}
                scrollToRow={scrollToRow}
                scrollToAlignment="start"
                padBottom
                modalIsOpen={this.state.isSettingsModalOpen}
                closeModal={this.closeSettingsModal}
                media="desktop"
                sizeMultiplier={sizeMultiplier}
              />
            </div>
          );
        }}
      </DisplayComponent>
    );
  }
}

export default OrderTable;
