import React from "react";
import { getDivisionDoc } from "util/lifterHelper";
import NotEqualIcon from "icons/NotEqualIcon";
import { Column } from "components/table/Table";
import { Meet } from "types";
import map from "lodash/map";
import get from "lodash/get";

const DivisionCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: Column;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    return (
      <div style={style} className="table-cell-inner">
        {map(lifter.divisions, (lifterDivision, index) => {
          const division = getDivisionDoc(lifterDivision.divisionId, meet);

          const match = get(lifterDivision, ["match", "divisionId"]);

          return (
            <div key={index} className="ellipsis cell-row">
              {get(division, "name")}
              {!match && (
                <div className="mismatch-icon-wrapper">
                  <NotEqualIcon />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
};

export default DivisionCell;
