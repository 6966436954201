import React from "react";

const ConnectedIcon = ({
  className,
  isSyncing,
}: {
  className?: string;
  isSyncing?: boolean;
}) => {
  if (isSyncing) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        className={className}
      >
        <path d="M508.485 168.48l-96.16 96.16c-7.58 7.58-20.485 2.14-20.485-8.485L391.833 184H12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h379.833l.01-72.162c.001-10.683 12.949-16.022 20.485-8.485l96.156 96.156c4.687 4.686 4.688 12.285.001 16.971zM3.515 360.491l96.156 96.156c7.536 7.536 20.484 2.198 20.485-8.485l.01-72.162H500c6.627 0 12-5.373 12-12v-24c0-6.628-5.373-12-12-12H120.167l-.007-72.154c0-10.625-12.905-16.066-20.485-8.485l-96.16 96.16c-4.687 4.685-4.686 12.284 0 16.97z" />
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      className={className}
    >
      <path d="M248.747 204.705l6.588 112c.373 6.343 5.626 11.295 11.979 11.295h41.37a12 12 0 0 0 11.979-11.295l6.588-112c.405-6.893-5.075-12.705-11.979-12.705h-54.547c-6.903 0-12.383 5.812-11.978 12.705zM330 384c0 23.196-18.804 42-42 42s-42-18.804-42-42 18.804-42 42-42 42 18.804 42 42zm-.423-360.015c-18.433-31.951-64.687-32.009-83.154 0L6.477 440.013C-11.945 471.946 11.118 512 48.054 512H527.94c36.865 0 60.035-39.993 41.577-71.987L329.577 23.985zM53.191 455.002L282.803 57.008c2.309-4.002 8.085-4.002 10.394 0l229.612 397.993c2.308 4-.579 8.998-5.197 8.998H58.388c-4.617.001-7.504-4.997-5.197-8.997z" />
    </svg>
  );
};

export default ConnectedIcon;
