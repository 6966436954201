import React, { Component } from "react";
import RadioChecked from "icons/RadioChecked";
import RadioUnChecked from "icons/RadioUnChecked";
import "./RadioGroup.scss";
import map from "lodash/map";

type RadioGroupProps = {
  onCheck: (value: any) => void;
  items: { label: string; value: string }[];
  value: string;
};

class RadioGroup extends Component<RadioGroupProps, {}> {
  onCheck = (value: string) => {
    this.props.onCheck(value);
  };

  render() {
    return (
      <div className="radio-group">
        {map(this.props.items, (item) => {
          return (
            <div
              className="radio"
              key={item.label}
              onClick={() => this.onCheck(item.value)}
            >
              {this.props.value === item.value && <RadioChecked />}
              {this.props.value !== item.value && <RadioUnChecked />}
              <div>{item.label}</div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default RadioGroup;
