import React from "react";

import {
  getGenderOptions,
  getRawOrEquippedOptions,
  getScoreByOptions,
  getDivisionCodeOptions,
} from "util/options";

import SortableTable from "components/SortableTable";
import DefaultCell from "../cells/DefaultCell";
import OptionCell from "../cells/OptionCell";
import LiftsCell from "./cells/LiftsCell";
import WeightClassCell from "./cells/WeightClassCell";
import CheckboxCell from "./cells/CheckboxCell";
import size from "lodash/size";
import get from "lodash/get";
import { MediaSize, Meet } from "types";
import { Column } from "components/table/Table";

// prettier-ignore
const columns: Column[] = [
  { key: "name",              label: "Name",          width: 260,                                   sortable: true, sortDirection: "asc", sortOrder: 1, renderer: DefaultCell,},
  { key: "gender",            label: "Gender",        width: 90,  options: getGenderOptions,        sortable: true, sortDirection: "asc", sortOrder: 2, renderer: OptionCell,},
  { key: "rawOrEquipped",     label: "R/E",           width: 100, options: getRawOrEquippedOptions, sortable: true, sortDirection: "asc", sortOrder: 3, renderer: OptionCell,},
  { key: "lifts",             label: "Lifts",         width: 200,                                                                                       renderer: LiftsCell,},
  { key: "scoreBy",           label: "Score By",      width: 160, options: getScoreByOptions,       sortable: true, sortDirection: "asc", sortOrder: 4, renderer: OptionCell,},
  { key: "name",              label: "Weight Class",  width: 240,                                   sortable: false,                                    renderer: WeightClassCell,},
  { key: "maxWeight",         label: "Max Weight",    width: 100,                                                                                       renderer: WeightClassCell,},
  { key: "usaplDivisionCode", label: "Division Code", width: 160, options: getDivisionCodeOptions,                                                      renderer: OptionCell,},
  { key: "hideOnBoard",       label: "Hide On Board", width: 160,                                                                                       renderer: CheckboxCell,},
];

const getCellLines = (index: number, divisions: any) => {
  const rows = size(get(divisions, [index, "weightClasses"], 1));
  if (rows === 0) {
    return 1;
  }

  return rows;
};
const ImportDivisionsTable = ({
  items,
  meet,
  media,
}: {
  items: any;
  meet: Meet;
  media: MediaSize;
}) => {
  return (
    <SortableTable
      data={items}
      columns={columns}
      getCellLines={getCellLines}
      meet={meet}
      media={media}
      numberOfFixedLeftColumns={1}
    />
  );
};

export default ImportDivisionsTable;
