import React from "react";
import Select from "components/select/Select";
import classNames from "classnames";
import { toastr } from "react-redux-toastr";
import { set as setImmutable } from "object-path-immutable";
import {
  getGenderOptions,
  getRawOrEquippedOptions,
  getAwardDivisionOptions,
  getWeightClassOptions,
  getStateOptions,
  getTeamOptions,
  getCountryOptions,
  getCanadaProvinceOptions,
} from "util/options";
import { errorReporter } from "util/errorReporter";
import { getStateLabel, getDateFormat } from "util/meetHelper";
import { reformatDateToUsFormat } from "util/dateHelper";
import { fetchWrapper } from "util/api";
import DateInput from "components/dateInput/DateInput";
import DeleteIcon from "icons/DeleteIcon";
import "./RegistrationIndex.scss";
import { useMeet } from "util/useMeet";
import { RegistrationPayload } from "types";
import reject from "lodash/reject";
import each from "lodash/each";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import map from "lodash/map";
import get from "lodash/get";
import set from "lodash/set";
import { createLineItemsFromRegistration } from "util/registration";
import sumBy from "lodash/sumBy";

const RegistrationNew = ({
  processing,
  submit,
  setupIncomplete,
}: {
  processing: boolean;
  submit: ({
    reg,
    customQuestions,
  }: {
    reg: RegistrationPayload;
    customQuestions: Record<string, string>;
  }) => void;
  setupIncomplete: boolean;
}) => {
  const meet = useMeet();
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const [reg, setReg] = React.useState<RegistrationPayload>({
    name: "",
    memberNumber: "",
    phoneNumber: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
    email: "",
    emailConfirm: "",
    birthDate: "",
    gender: null,
    team: null,
    divisions: [
      {
        rawOrEquipped: meet.federation === "USSF" ? "RAW" : null,
        divisionId: null,
        declaredAwardsWeightClassId: null,
      },
    ],
    items: [],
    emergencyContactName: "",
    emergencyContactPhoneNumber: "",
    disclaimerAgreed: "",
  });

  const [customQuestions, setCustomQuestions] = React.useState<
    Record<string, string>
  >({});

  const addDivision = () => {
    const newDivisions = [
      ...reg.divisions,
      {
        rawOrEquipped: meet.federation === "USSF" ? "RAW" : null,
        divisionId: null,
        declaredAwardsWeightClassId: null,
      },
    ];
    setReg({ ...reg, divisions: newDivisions });
  };

  const addItem = () => {
    const newItems = [...reg.items, null];
    setReg({ ...reg, items: newItems });
  };

  const removeDivision = (index: number) => {
    setReg({
      ...reg,
      divisions: reject(reg.divisions, (value, i) => i === index),
    });
  };

  const handleDivisionChange = (index: number, name: string, value: any) => {
    const newDivisions = setImmutable(reg.divisions, [index, name], value);
    setReg({ ...reg, divisions: newDivisions });
  };

  const handleItemChange = (index: number, value: any) => {
    const newItems = setImmutable(reg.items, [index], value);
    setReg({ ...reg, items: newItems });
  };

  const handleItemRemove = (index: number) => {
    const newItems = [...reg.items];
    newItems.splice(index, 1);
    setReg({ ...reg, items: newItems });
  };

  const handleChange = (name: string, value: any) => {
    setReg({ ...reg, [name]: value });
  };

  const isFormValid = async () => {
    const errors: Record<string, string> = {};
    if (!reg.name) {
      errors.name = "Name is required";
    }

    if (!/\d/.test(reg.phoneNumber)) {
      errors.phoneNumber = "This doesn't look like a phone number.";
    }

    if (!reg.streetAddress) {
      errors.streetAddress = "Street Address is required";
    }

    if (!reg.city) {
      errors.city = "City is required";
    }

    if (!reg.state) {
      errors.state = `${getStateLabel(meet)} is required`;
    }

    if (!reg.zipCode) {
      errors.zipCode = "Zip Code is required";
    }

    if (!reg.email) {
      errors.email = "Email is required";
    }

    if (!/\S+@\S+\.\S+/.test(reg.email)) {
      errors.email = "Email is not valid";
    }

    if (reg.emailConfirm !== reg.email) {
      errors.emailConfirm = "Confirmation must match";
    }

    if (!reg.birthDate) {
      errors.birthDate = "Birth Date is required";
    }

    if (!reg.gender) {
      errors.gender = "Gender is required";
    }

    if (!reg.emergencyContactName) {
      errors.emergencyContactName = "Emergency contact is required";
    }

    if (!/\d/.test(reg.emergencyContactPhoneNumber)) {
      errors.emergencyContactPhoneNumber =
        "This doesn't look like a phone number.";
    }

    if (meet.entryConfig.disclaimer && reg.disclaimerAgreed !== "I AGREE") {
      errors.disclaimerAgreed = 'You must type "I AGREE" exactly.';
    }

    each(reg.divisions, (division, index) => {
      if (!division.rawOrEquipped) {
        set(
          errors,
          ["divisions", index, "rawOrEquipped"],
          "Raw or equipped is required."
        );
      }
      if (!division.divisionId) {
        set(
          errors,
          ["divisions", index, "divisionId"],
          "Division name is required."
        );
      }
      if (!division.declaredAwardsWeightClassId) {
        set(
          errors,
          ["divisions", index, "declaredAwardsWeightClassId"],
          "Declared weight class is required."
        );
      }
    });

    each(meet.entryConfig.customQuestions, (question) => {
      if (
        question.required &&
        !question.disabled &&
        !customQuestions[question.id]
      ) {
        errors[question.id] = "Required";
      }
    });

    if (isEmpty(errors) && meet.entryConfig.requireValidMemberNumbers) {
      return checkMembership(errors);
    }

    return Promise.resolve(errors);
  };

  const checkMembership = (errors: Record<string, string>) => {
    const memberNumber = reg.memberNumber;
    const name = reg.name;
    const birthDate = reg.birthDate;
    const formattedBirthDate = reformatDateToUsFormat({
      dateString: birthDate,
      meet,
    });
    const state = reg.state;
    return fetchWrapper(
      `/api/check_membership?member_number=${memberNumber}&state=${state}&name=${name}&birth_date=${formattedBirthDate}`,
      "GET"
    )
      .then((response) => {
        if (response.error === "Not Found") {
          errors.memberNumber = `Membership Number not found in ${meet.federation} Database`;
        } else if (response.error) {
          errors.memberNumber = response.error;
        } else if (!response.name_match && !response.name_suggestion) {
          errors.memberNumber = "Name does not match membership card.";
        } else if (!response.birth_date_match) {
          errors.memberNumber = "Birth date does not match membership card.";
        }
        return errors;
      })
      .catch((error) => {
        errorReporter({ error });
        errors.memberNumber =
          "Unable to verify member number. Please try again later.";
        return errors;
      });
  };

  const lineItems = createLineItemsFromRegistration({
    registration: reg,
    meet,
  });

  const getTotalCost = () => {
    return sumBy(lineItems, "unitAmount");
  };

  const submitForm = async () => {
    const errorsFromForm = await isFormValid();
    setErrors(errorsFromForm);
    if (isEmpty(errorsFromForm)) {
      submit({ reg, customQuestions });
      return true;
    }

    toastr.error("Please fix issues", "");
    return false;
  };

  const limitedGenderOptions = getGenderOptions(null, meet).filter((option) =>
    find(meet.divisions, { gender: option.value })
  );

  const itemOptions = map(meet.entryConfig.items, (i) => {
    return {
      label: `${i.name} - ${i.cost}`,
      value: i.id,
      disabled: !!i.disabled,
    };
  }).filter((i) => !i.disabled);

  const teamOptions = getTeamOptions(reg, meet);
  if (reg.team && !find(teamOptions, { value: reg.team })) {
    teamOptions.push({ value: reg.team, label: reg.team });
  }

  const numberFormatter = React.useMemo(
    () =>
      new Intl.NumberFormat(undefined, {
        style: "currency",
        currency: meet.entryConfig.currency || "USD",
      }),
    [meet.entryConfig.currency]
  );

  return (
    <div>
      {!!meet.entryConfig.requirePassCode && (
        <div className="entry-row">
          <label>Pass Code *</label>
          <div className="input-wrapper">
            <input
              type="text"
              className={classNames({ error: errors.passCode })}
              value={reg.passCode}
              onChange={(e) => handleChange("passCode", e.target.value)}
            />
            <div className="error-message">{errors.passCode}</div>
          </div>
        </div>
      )}
      <div className="entry-row">
        <label>Full Name as it appears on membership card *</label>
        <div className="input-wrapper">
          <input
            type="text"
            className={classNames({ error: errors.name })}
            value={reg.name}
            onChange={(e) => handleChange("name", e.target.value)}
          />
          <div className="error-message">{errors.name}</div>
        </div>
      </div>
      <div className="entry-row">
        <label>
          Membership Number {meet.entryConfig.requireValidMemberNumbers && "*"}
        </label>
        <div className="input-wrapper">
          <input
            type="text"
            className={classNames({ error: errors.memberNumber })}
            value={reg.memberNumber}
            onChange={(e) => handleChange("memberNumber", e.target.value)}
          />
          <div className="error-message">{errors.memberNumber}</div>
        </div>
      </div>
      <div className="entry-row">
        <label>Phone Number *</label>
        <div className="input-wrapper">
          <input
            type="text"
            className={classNames({ error: errors.phoneNumber })}
            value={reg.phoneNumber}
            onChange={(e) => handleChange("phoneNumber", e.target.value)}
          />
          <div className="error-message">{errors.phoneNumber}</div>
        </div>
      </div>
      <div className="entry-row">
        <label>Street Address *</label>
        <div className="input-wrapper">
          <input
            type="text"
            className={classNames({ error: errors.streetAddress })}
            value={reg.streetAddress}
            onChange={(e) => handleChange("streetAddress", e.target.value)}
          />
          <div className="error-message">{errors.streetAddress}</div>
        </div>
      </div>
      <div className="entry-row">
        <label>City *</label>
        <div className="input-wrapper">
          <input
            type="text"
            className={classNames({ error: errors.city })}
            value={reg.city}
            onChange={(e) => handleChange("city", e.target.value)}
          />
          <div className="error-message">{errors.city}</div>
        </div>
      </div>
      <div className="entry-row">
        <label>Country</label>
        <div className="input-wrapper">
          <Select
            name="country"
            className={classNames({ error: errors.country })}
            value={reg.country}
            options={getCountryOptions()}
            onChange={(value) => handleChange("country", value)}
          />
          <div className="error-message">{errors.country}</div>
        </div>
      </div>
      {(!reg.country || reg.country === "US") && (
        <div className="entry-row">
          <label>{getStateLabel(meet)} *</label>
          <div className="input-wrapper">
            <Select
              name="state"
              className={classNames({ error: errors.state })}
              value={reg.state}
              options={getStateOptions(null, meet)}
              onChange={(value) => handleChange("state", value)}
            />
            <div className="error-message">{errors.state}</div>
          </div>
        </div>
      )}
      {reg.country === "CA" && (
        <div className="entry-row">
          <label>Province *</label>
          <div className="input-wrapper">
            <Select
              name="state"
              className={classNames({ error: errors.state })}
              value={reg.state}
              options={getCanadaProvinceOptions()}
              onChange={(value) => handleChange("state", value)}
            />
            <div className="error-message">{errors.state}</div>
          </div>
        </div>
      )}
      {reg.country && reg.country !== "CA" && reg.country !== "US" && (
        <div className="entry-row">
          <label>State/Province *</label>
          <div className="input-wrapper">
            <input
              type="text"
              className={classNames({ error: errors.state })}
              value={reg.state}
              onChange={(e) => handleChange("state", e.target.value)}
            />
            <div className="error-message">{errors.state}</div>
          </div>
        </div>
      )}
      <div className="entry-row">
        <label>Postal Code *</label>
        <div className="input-wrapper">
          <input
            type="text"
            className={classNames({ error: errors.zipCode })}
            value={reg.zipCode}
            onChange={(e) => handleChange("zipCode", e.target.value)}
          />
          <div className="error-message">{errors.zipCode}</div>
        </div>
      </div>
      <div className="entry-row">
        <label>Email Address *</label>
        <div className="input-wrapper">
          <input
            type="email"
            className={classNames({ error: errors.email })}
            value={reg.email}
            onChange={(e) => handleChange("email", e.target.value)}
          />
          <div className="error-message">{errors.email}</div>
        </div>
      </div>
      <div className="entry-row">
        <label>Confirm Email Address *</label>
        <div className="input-wrapper">
          <input
            type="email"
            className={classNames({ error: errors.emailConfirm })}
            value={reg.emailConfirm}
            onChange={(e) => handleChange("emailConfirm", e.target.value)}
          />
          <div className="error-message">{errors.emailConfirm}</div>
        </div>
      </div>
      <div className="entry-row">
        <label>Birth Date *</label>
        <div className="input-wrapper">
          <DateInput
            dateFormat={getDateFormat(meet)}
            className={classNames({ error: errors.birthDate })}
            value={reg.birthDate}
            onChange={(v) => handleChange("birthDate", v)}
            placeholder={getDateFormat(meet)}
          />
          <div className="error-message">{errors.birthDate}</div>
        </div>
      </div>
      <div className="entry-row">
        <label>Gender *</label>
        <div className="input-wrapper">
          <Select
            name="gender"
            className={classNames({ error: errors.gender })}
            value={reg.gender}
            options={limitedGenderOptions}
            onChange={(value) => handleChange("gender", value)}
          />
          <div className="error-message">{errors.gender}</div>
        </div>
      </div>
      <div className="entry-row">
        <label>Emergency Contact Name *</label>
        <div className="input-wrapper">
          <input
            type="text"
            className={classNames({
              error: errors.emergencyContactName,
            })}
            value={reg.emergencyContactName}
            onChange={(e) =>
              handleChange("emergencyContactName", e.target.value)
            }
          />
          <div className="error-message">{errors.emergencyContactName}</div>
        </div>
      </div>
      <div className="entry-row">
        <label>Emergency Contact Phone Number *</label>
        <div className="input-wrapper">
          <input
            type="text"
            className={classNames({
              error: errors.emergencyContactPhoneNumber,
            })}
            value={reg.emergencyContactPhoneNumber}
            onChange={(e) =>
              handleChange("emergencyContactPhoneNumber", e.target.value)
            }
          />
          <div className="error-message">
            {errors.emergencyContactPhoneNumber}
          </div>
        </div>
      </div>
      <div className="entry-row">
        <label>Team</label>
        <div className="input-wrapper">
          <Select
            className={classNames({ error: errors.team })}
            value={reg.team}
            options={teamOptions}
            onChange={(value) => handleChange("team", value)}
            allowCreate
            name="team"
          />
          <div className="error-message">{errors.team}</div>
        </div>
      </div>

      {map(meet.entryConfig.customQuestions, (question) => {
        if (question.disabled) {
          return null;
        }
        return (
          <div className="entry-row" key={question.id}>
            <label>
              {question.text} {question.required && <span>*</span>}
            </label>
            <div className="input-wrapper">
              <input
                type="text"
                className={classNames({
                  error: errors[question.id],
                })}
                value={customQuestions[question.id] || ""}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setCustomQuestions((prev) => ({
                    ...prev,
                    [question.id]: newValue,
                  }));
                }}
              />
              <div className="error-message">{errors[question.id]}</div>
            </div>
          </div>
        );
      })}

      <div className="divisions-title">
        Division{meet.entryConfig.additionalDivisionCost && "s"}
      </div>
      {map(reg.divisions, (division, index) => {
        const limitedRawOrEquippedOptions = getRawOrEquippedOptions(
          null,
          meet
        ).filter((option) => {
          return find(meet.divisions, {
            gender: reg.gender,
            rawOrEquipped: option.value,
          });
        });

        const enabledDivisionOptions = map(
          getAwardDivisionOptions({ ...reg, _id: "anything" }, meet, index), // getAwardDivisionOptions requires an _id to exist
          (divisionOption) => {
            if (
              find(reg.divisions, (d) => d.divisionId === divisionOption.value)
            ) {
              return { ...divisionOption, disabled: true };
            } else {
              return divisionOption;
            }
          }
        );

        return (
          <div className="division-box" key={index}>
            {meet.entryConfig.additionalDivisionCost && (
              <div className="division-count">
                Division {index + 1}
                {index !== 0 && (
                  <button onClick={() => removeDivision(index)}>Remove</button>
                )}
              </div>
            )}
            {meet.federation !== "USSF" && (
              <div className="entry-row">
                <label>Raw or Equipped *</label>
                <div className="input-wrapper">
                  <Select
                    name="rawOrEquipped"
                    className={classNames({
                      error: get(errors, ["divisions", index, "rawOrEquipped"]),
                    })}
                    value={division.rawOrEquipped}
                    options={limitedRawOrEquippedOptions}
                    onChange={(value) =>
                      handleDivisionChange(index, "rawOrEquipped", value)
                    }
                    disabled={!reg.gender}
                  />
                  <div className="error-message">
                    {get(errors, ["divisions", index, "rawOrEquipped"])}
                  </div>
                </div>
              </div>
            )}
            <div className="entry-row">
              <label>Division Name *</label>
              <div className="input-wrapper">
                <Select
                  name="divisionId"
                  className={classNames({
                    error: get(errors, ["divisions", index, "divisionId"]),
                  })}
                  value={division.divisionId}
                  options={enabledDivisionOptions}
                  onChange={(value) =>
                    handleDivisionChange(index, "divisionId", value)
                  }
                  disabled={!reg.gender || !reg.divisions[index].rawOrEquipped}
                />
                <div className="error-message">
                  {get(errors, ["divisions", index, "divisionId"])}
                </div>
              </div>
            </div>
            <div className="entry-row">
              <label>Declared Weight Class *</label>
              <div className="input-wrapper">
                <Select
                  name="declaredAwardsWeightClassId"
                  className={classNames({
                    error: get(errors, [
                      "divisions",
                      index,
                      "declaredAwardsWeightClassId",
                    ]),
                  })}
                  value={division.declaredAwardsWeightClassId}
                  options={getWeightClassOptions(reg, meet, index)}
                  onChange={(value) =>
                    handleDivisionChange(
                      index,
                      "declaredAwardsWeightClassId",
                      value
                    )
                  }
                  disabled={!reg.divisions[index].divisionId}
                />
                <div className="error-message">
                  {get(errors, [
                    "divisions",
                    index,
                    "declaredAwardsWeightClassId",
                  ])}
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {meet.entryConfig.additionalDivisionCost && (
        <div className="add-division">
          <button onClick={addDivision}>Add Division</button>
        </div>
      )}

      {!isEmpty(meet.entryConfig.items) && (
        <div>
          <div className="items-title">Additional Items</div>
          <div className="items-subtitle">
            Click "Add Item" to purchase t-shirts or other items. Then select an
            item from the dropdown.
          </div>
          <table className="item-table">
            <tbody>
              {map(reg.items, (itemId, index: number) => {
                return (
                  <tr key={index} className="item-row">
                    <td>
                      <Select
                        name={`item-${index}`}
                        value={itemId}
                        options={itemOptions}
                        onChange={(value) => handleItemChange(index, value)}
                      />
                    </td>
                    <td>
                      <button onClick={() => handleItemRemove(index)}>
                        <DeleteIcon />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <button onClick={addItem}>Add Item</button>
        </div>
      )}

      {meet.entryConfig.disclaimer && (
        <div>
          <div className="disclaimer-title">Disclaimer</div>
          <div className="disclaimer-text">{meet.entryConfig.disclaimer}</div>
          <div className="entry-row">
            <label>You must type "I AGREE" *</label>
            <div className="input-wrapper">
              <input
                type="text"
                className={classNames({
                  error: errors.disclaimerAgreed,
                })}
                value={reg.disclaimerAgreed}
                onChange={(e) =>
                  handleChange("disclaimerAgreed", e.target.value)
                }
              />
              <div className="error-message">{errors.disclaimerAgreed}</div>
            </div>
          </div>
        </div>
      )}

      <div>
        <table className="line-items-table">
          <tbody>
            {lineItems.map((lineItem, index) => {
              return (
                <tr key={`${lineItem.name}-${lineItem.unitAmount}-${index}-`}>
                  <td>{lineItem.name}:</td>
                  <td>{numberFormatter.format(lineItem.unitAmount)}</td>
                </tr>
              );
            })}
            <tr>
              <td>Total:</td>
              <td>{numberFormatter.format(getTotalCost())}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {setupIncomplete && (
        <button className="pay-button">Registration Setup Incomplete</button>
      )}
      {!setupIncomplete && (
        <button
          className="pay-button"
          onClick={submitForm}
          disabled={processing}
        >
          Continue To Payment Step
        </button>
      )}
    </div>
  );
};

export default RegistrationNew;
