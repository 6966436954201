import React, { Component, PureComponent } from "react";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import MultiGrid from "react-virtualized/dist/commonjs/MultiGrid";
import classNames from "classnames";
import useScrollbarSize from "react-scrollbar-size";
import CellErrorBoundary from "./CellErrorBoundary";
import "./Table.scss";
import { AttemptNumber, LiftName, MediaSize, Meet } from "types";
import { Alignment } from "react-virtualized";
import get from "lodash/get";
import findLastIndex from "lodash/findLastIndex";
import sum from "lodash/sum";
import map from "lodash/map";

export type Column = {
  key: string;
  label: string;
  renderer: any;
  sortable?: boolean;
  width: number;
  attemptNumber?: AttemptNumber;
  lift?: LiftName;
  liftName?: LiftName;
  visible?: boolean;
  locked?: boolean;
  sortFunction?: (data: any) => any;
  sortDirection?: "asc" | "desc";
  sortOrder?: number;
  options?: (
    data: any,
    meet: Meet,
    index?: number | undefined
  ) => { value: any; label: string | null | undefined }[];
  type?: "select" | "date" | "text" | "number" | "button";
};

type CellRendererProps = {
  rowIndex: number;
  wrapperCellStyle?: React.CSSProperties;
  onHeaderClick: (column: Column) => void;
  cellData: any;
  column: Column;
  hightlightRow: any;
  meet: Meet;
};
class CellRenderer extends PureComponent<CellRendererProps> {
  render() {
    const {
      rowIndex,
      wrapperCellStyle,
      onHeaderClick,
      cellData,
      column,
      hightlightRow,
      meet,
    } = this.props;

    if (!column.renderer) {
      return cellData[column.key];
    }

    const cellStyle = { ...wrapperCellStyle };

    if (hightlightRow === rowIndex) {
      cellStyle.fontWeight = "bold";
      cellStyle.textDecoration = "underline";
    }

    if (cellData.row === "header" && column.sortable && onHeaderClick) {
      return (
        <CellErrorBoundary>
          <div
            onClick={() => onHeaderClick(column)}
            className="sortable-header"
          >
            <column.renderer
              data={cellData}
              column={column}
              meet={meet}
              style={cellStyle}
            />
          </div>
        </CellErrorBoundary>
      );
    }

    if (cellData.row === "title") {
      return (
        <CellErrorBoundary>
          <column.renderer
            data={cellData}
            column={column}
            meet={meet}
            index={rowIndex}
            style={cellStyle}
          />
        </CellErrorBoundary>
      );
    }

    return (
      <CellErrorBoundary>
        <column.renderer
          data={cellData}
          column={column}
          meet={meet}
          index={rowIndex}
          style={cellStyle}
        />
      </CellErrorBoundary>
    );
  }
}

type TableProps = {
  data: any;
  columns: Column[];
  media: MediaSize;
  getCellLines: (index: number) => number;
  hightlightRow?: any;
  onHeaderClick?: any;
  meet: Meet;
  cellStyle?: React.CSSProperties;
  padBottom?: boolean;
  numberOfFixedLeftColumns: number;
  scrollToAlignment?: Alignment;
  scrollToRow?: number | undefined;
};
class Table extends Component<
  TableProps,
  { scrollbarWidth: number; scrollToRow: number | undefined }
> {
  ref: any;
  timeout: any;
  constructor(props: TableProps) {
    super(props);
    this.state = {
      scrollbarWidth: 0,
      scrollToRow: undefined,
    };
  }

  componentDidUpdate = (prevProps: TableProps) => {
    if (
      prevProps.data !== this.props.data ||
      prevProps.columns !== this.props.columns ||
      prevProps.media !== this.props.media
    ) {
      this.ref.recomputeGridSize();
    }

    //https://github.com/bvaughn/react-virtualized/issues/1170
    if (prevProps.scrollToRow !== this.props.scrollToRow) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.setState({ scrollToRow: this.props.scrollToRow });
      this.timeout = setTimeout(() => {
        this.setState({ scrollToRow: undefined });
      }, 100);
    }
  };

  componentDidMount = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.setState({ scrollToRow: this.props.scrollToRow });
    this.timeout = setTimeout(() => {
      this.setState({ scrollToRow: undefined });
    }, 100);
  };

  componentWillUnmount = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  };

  getRowHeight = ({ index }: { index: number }) => {
    const { media } = this.props;
    let height = 50;
    if (media === "small") {
      height = 27;
    } else if (media === "medium") {
      height = 35;
    }

    if (index === 0) {
      const sizeMultiplier = get(this.props.data, [index, "sizeMultiplier"]);
      if (sizeMultiplier) {
        return height * sizeMultiplier;
      }

      return height;
    }

    if (!get(this.props.data, index)) {
      return height;
    }

    const rows = this.props.getCellLines(index);
    return Math.floor(height + (rows - 1) * height * 0.84);
  };

  // // TODO consider moving this to its own component
  cellRenderer = ({
    columnIndex,
    key,
    rowIndex,
    style,
  }: {
    columnIndex: number;
    key: string;
    rowIndex: number;
    style: React.CSSProperties;
  }) => {
    const cellData = get(this.props.data, rowIndex);
    if (!cellData) {
      return <div key={key} style={style} />;
    }

    // force rerender so inputs don't retain state from last position after sort
    const documentBasedKey = `${key}-${cellData._id || cellData.id || ""}`;

    let previousTitleRow = findLastIndex(
      this.props.data,
      { row: "title" },
      rowIndex
    );
    if (previousTitleRow === -1) {
      previousTitleRow = 0;
    }
    const even = (rowIndex - previousTitleRow) % 2 === 0;
    const column = this.props.columns[columnIndex];
    const extraLeftborder = column.attemptNumber === "1";

    return (
      <div
        key={documentBasedKey}
        style={style}
        className={classNames("table-cell", this.props.media, {
          "table-cell-extra-left-border": extraLeftborder,
          "table-cell-title": cellData.row === "title",
          "table-header": cellData.row === "header",
          "first-column": columnIndex === 0,
          even: even && cellData.row !== "header" && cellData.row !== "title",
        })}
      >
        <CellRenderer
          rowIndex={rowIndex}
          hightlightRow={this.props.hightlightRow}
          onHeaderClick={this.props.onHeaderClick}
          cellData={cellData}
          column={column}
          meet={this.props.meet}
          wrapperCellStyle={this.props.cellStyle}
        />
      </div>
    );
  };

  getColumnWidth = ({ index }: { index: number }) => {
    const { media, columns } = this.props;

    if (media === "small") {
      return columns[index].width * 0.55;
    } else if (media === "medium") {
      return columns[index].width * 0.75;
    }

    return columns[index].width;
  };

  handleScrollbarSizeChange = (width: number) => {
    this.setState({ scrollbarWidth: width });
  };

  render() {
    return (
      <AutoSizer>
        {({ width, height }) => {
          const totalWidth =
            sum(
              map(this.props.columns, (column, index: number) =>
                this.getColumnWidth({ index })
              )
            ) + this.state.scrollbarWidth;
          if (totalWidth < width) {
            width = totalWidth;
          }

          // Filling the table with blank lines helps make sure you can still side scroll.
          // Only the lower containers can control scroll.
          // blank lines help with keeping bottom row selects usable
          const rowCount = this.props.padBottom
            ? this.props.data.length < 25
              ? 45
              : this.props.data.length + 20
            : this.props.data.length;

          return (
            <div>
              <ScrollbarWidth onChange={this.handleScrollbarSizeChange} />
              <MultiGrid
                {...this.props}
                enableFixedColumnScroll
                enableFixedRowScroll
                cellRenderer={this.cellRenderer}
                columnWidth={this.getColumnWidth}
                columnCount={this.props.columns.length}
                height={height}
                rowHeight={this.getRowHeight}
                rowCount={rowCount}
                fixedColumnCount={this.props.numberOfFixedLeftColumns || 1}
                fixedRowCount={1}
                classNameBottomLeftGrid="grid-bottom-left"
                classNameBottomRightGrid="grid-bottom-right"
                classNameTopLeftGrid="grid-top-left"
                classNameTopRightGrid="grid-top-right"
                width={width}
                scrollToRow={this.state.scrollToRow}
                scrollToAlignment={this.props.scrollToAlignment}
                ref={(ref) => (this.ref = ref)}
              />
            </div>
          );
        }}
      </AutoSizer>
    );
  }
}

const ScrollbarWidth = ({
  onChange,
}: {
  onChange: (width: number) => void;
}) => {
  const { width } = useScrollbarSize();
  React.useEffect(() => onChange(width), [onChange, width]);
  return null;
};

export default Table;
