import React from 'react';

const CloudIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
      <path d="M272 32c-84.587 0-155.5 59.732-172.272 139.774C39.889 196.13 0 254.416 0 320c0 88.374 71.642 160 160 160h336c79.544 0 144-64.487 144-144 0-61.805-39.188-115.805-96.272-135.891C539.718 142.116 491.432 96 432 96c-7.558 0-15.051.767-22.369 2.262C377.723 58.272 328.091 32 272 32zm0 48c53.473 0 99.279 32.794 118.426 79.363C401.611 149.793 416.125 144 432 144c35.346 0 64 28.654 64 64 0 11.829-3.222 22.9-8.817 32.407A96.998 96.998 0 0 1 496 240c53.019 0 96 42.981 96 96s-42.981 96-96 96H160c-61.856 0-112-50.144-112-112 0-56.428 41.732-103.101 96.014-110.859-.003-.381-.014-.76-.014-1.141 0-70.692 57.308-128 128-128m123.515 187.515l-99.029-99.029c-4.686-4.686-12.284-4.686-16.971 0l-99.029 99.029c-7.56 7.56-2.206 20.485 8.485 20.485H256v84c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12v-84h67.029c10.691 0 16.045-12.926 8.486-20.485z"/>
    </svg>
  );
};

export default CloudIcon;
