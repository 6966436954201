import find from "lodash/find";
import each from "lodash/each";
import uniqWith from "lodash/uniqWith";
import get from "lodash/get";
import sortBy from "lodash/sortBy";

import { isLbsMeet, getMeetUnits } from "../meetHelper";
import { getRealAge } from "../lifterHelper";
import { getAttemptDisplay } from "../exportData";
import {
  CsvArray,
  FederationDivisionsConfig,
  FederationWeightClassConfig,
  Gender,
  Lifter,
  Meet,
} from "types";
import { competitionCode } from "../standardDivisions";

// Female
// 30 - Youth Only
// 35 - Youth Only
// 40 - Youth Only
// 44
// 48
// 52
// 56
// 60
// 67.5
// 75
// 82.5
// 90
// 100
// 100+

// Male
// 30 - Youth Only
// 35 - Youth Only
// 40 - Youth Only
// 52
// 56
// 60
// 65 starting 2025
// 67.5 before 2025
// 70 starting 2025
// 75
// 82.5
// 90
// 100
// 110
// 125
// 140
// 140+

const usapl30 = {
  name: "30",
  lbsName: "66.00lbs (30kg)",
  maxWeight: 30,
  lbsMaxWeight: 66.0,
};
const usapl35 = {
  name: "35",
  lbsName: "77.00lbs (35kg)",
  maxWeight: 35,
  lbsMaxWeight: 77.0,
};
const usapl40 = {
  name: "40",
  lbsName: "88.00lbs (40kg)",
  maxWeight: 40,
  lbsMaxWeight: 88.0,
};

const usapl44 = {
  name: "44",
  lbsName: "97.00lbs (44kg)",
  maxWeight: 44,
  lbsMaxWeight: 97.0,
};
const usapl48 = {
  name: "48",
  lbsName: "105.75lbs (48kg)",
  maxWeight: 48,
  lbsMaxWeight: 105.75,
};
const usapl52 = {
  name: "52",
  lbsName: "114.50lbs (52kg)",
  maxWeight: 52,
  lbsMaxWeight: 114.5,
};
const usapl56 = {
  name: "56",
  lbsName: "123.25lbs (56kg)",
  maxWeight: 56,
  lbsMaxWeight: 123.25,
};
const usapl60 = {
  name: "60",
  lbsName: "132.25lbs (60kg)",
  maxWeight: 60,
  lbsMaxWeight: 132.25,
};
const usapl65 = {
  name: "65",
  lbsName: "143.25lbs (60kg)",
  maxWeight: 65,
  lbsMaxWeight: 143.25,
};
const usapl67_5 = {
  name: "67.5",
  lbsName: "148.75lbs (67.5kg)",
  maxWeight: 67.5,
  lbsMaxWeight: 148.75,
};
const usapl70 = {
  name: "70",
  lbsName: "154.25lbs (60kg)",
  maxWeight: 70,
  lbsMaxWeight: 154.25,
};
const usapl75 = {
  name: "75",
  lbsName: "165.25lbs (75kg)",
  maxWeight: 75,
  lbsMaxWeight: 165.25,
};
const usapl82_5 = {
  name: "82.5",
  lbsName: "181.75lbs (82.5kg)",
  maxWeight: 82.5,
  lbsMaxWeight: 181.75,
};
const usapl90 = {
  name: "90",
  lbsName: "198.25lbs (90kg)",
  maxWeight: 90,
  lbsMaxWeight: 198.25,
};
const usapl100 = {
  name: "100",
  lbsName: "220.25lbs (100kg)",
  maxWeight: 100,
  lbsMaxWeight: 220.25,
};
const usapl100p = {
  name: "100+",
  lbsName: "220.25lbs+ (100kg+)",
  maxWeight: 9999,
  lbsMaxWeight: 9999,
};
const usapl110 = {
  name: "110",
  lbsName: "242.50lbs (110kg)",
  maxWeight: 110,
  lbsMaxWeight: 242.5,
};
const usapl125 = {
  name: "125",
  lbsName: "275.50lbs (125kg)",
  maxWeight: 125,
  lbsMaxWeight: 275.5,
};
const usapl140 = {
  name: "140",
  lbsName: "308.50lbs (140kg)",
  maxWeight: 140,
  lbsMaxWeight: 308.5,
};
const usapl140p = {
  name: "140+",
  lbsName: "308.50lbs+ (140kg+)",
  maxWeight: 9999,
  lbsMaxWeight: 9999,
};

const usaplFemaleYouthWeightClasses = [
  usapl30,
  usapl35,
  usapl40,
  usapl44,
  usapl48,
  usapl52,
  usapl56,
  usapl60,
  usapl67_5,
  usapl75,
  usapl82_5,
  usapl90,
  usapl100,
  usapl100p,
];
const usaplFemaleYouthWeightClasses2025 = [
  usapl30,
  usapl35,
  usapl40,
  usapl44,
  usapl48,
  usapl52,
  usapl56,
  usapl60,
  usapl65,
  usapl70,
  usapl75,
  usapl82_5,
  usapl90,
  usapl100,
  usapl100p,
];
const usaplMaleYouthWeightClasses = [
  usapl30,
  usapl35,
  usapl40,
  usapl44,
  usapl48,
  usapl52,
  usapl56,
  usapl60,
  usapl67_5,
  usapl75,
  usapl82_5,
  usapl90,
  usapl100,
  usapl110,
  usapl125,
  usapl140,
  usapl140p,
];
const usaplFemaleWeightClasses = [
  usapl44,
  usapl48,
  usapl52,
  usapl56,
  usapl60,
  usapl67_5,
  usapl75,
  usapl82_5,
  usapl90,
  usapl100,
  usapl100p,
];
const usaplFemaleWeightClasses2025 = [
  usapl44,
  usapl48,
  usapl52,
  usapl56,
  usapl60,
  usapl65,
  usapl70,
  usapl75,
  usapl82_5,
  usapl90,
  usapl100,
  usapl100p,
];
const usaplMaleWeightClasses = [
  usapl52,
  usapl56,
  usapl60,
  usapl67_5,
  usapl75,
  usapl82_5,
  usapl90,
  usapl100,
  usapl110,
  usapl125,
  usapl140,
  usapl140p,
];
const mxWeightClasses = [
  usapl44,
  usapl48,
  usapl52,
  usapl56,
  usapl60,
  usapl67_5,
  usapl75,
  usapl82_5,
  usapl90,
  usapl100,
  usapl110,
  usapl125,
  usapl140,
  usapl140p,
];

const guest = {
  name: "All Guest",
  lbsName: "All Guest",
  maxWeight: 9999,
  lbsMaxWeight: 9999,
};

const usaplWeightClasses = {
  youth: {
    MALE: usaplMaleYouthWeightClasses,
    FEMALE: usaplFemaleYouthWeightClasses,
    MX: mxWeightClasses,
  },
  junior: {
    MALE: usaplMaleWeightClasses,
    FEMALE: usaplFemaleWeightClasses,
    MX: mxWeightClasses,
  },
  adult: {
    MALE: usaplMaleWeightClasses,
    FEMALE: usaplFemaleWeightClasses,
    MX: mxWeightClasses,
  },
  guest: {
    MALE: [guest],
    FEMALE: [guest],
    MX: [guest],
  },
};

const usaplWeightClasses2025 = {
  youth: {
    MALE: usaplMaleYouthWeightClasses,
    FEMALE: usaplFemaleYouthWeightClasses2025,
    MX: mxWeightClasses,
  },
  junior: {
    MALE: usaplMaleWeightClasses,
    FEMALE: usaplFemaleWeightClasses2025,
    MX: mxWeightClasses,
  },
  adult: {
    MALE: usaplMaleWeightClasses,
    FEMALE: usaplFemaleWeightClasses2025,
    MX: mxWeightClasses,
  },
  guest: {
    MALE: [guest],
    FEMALE: [guest],
    MX: [guest],
  },
};

// prettier-ignore
export const usaplBaseDivisions: FederationDivisionsConfig = [
  {name: "Youth (8-9)",          code: 'Y1',  low: 8,   high: 9,    default: true,  records: true,  weightClasses: usaplWeightClasses.youth,  weightClasses2025: usaplWeightClasses2025.youth, },
  {name: "Youth (10-11)",        code: 'Y2',  low: 10,  high: 11,   default: true,  records: true,  weightClasses: usaplWeightClasses.youth,  weightClasses2025: usaplWeightClasses2025.youth, },
  {name: "Youth (12-13)",        code: 'Y3',  low: 12,  high: 13,   default: true,  records: true,  weightClasses: usaplWeightClasses.youth,  weightClasses2025: usaplWeightClasses2025.youth, },
  {name: "Teen I (14-15)",       code: 'T1',  low: 14,  high: 15,   default: true,  records: true,  weightClasses: usaplWeightClasses.junior, weightClasses2025: usaplWeightClasses2025.junior,},
  {name: "Teen II (16-17)",      code: 'T2',  low: 16,  high: 17,   default: true,  records: true,  weightClasses: usaplWeightClasses.junior, weightClasses2025: usaplWeightClasses2025.junior,},
  {name: "Teen III (18-19)",     code: 'T3',  low: 18,  high: 19,   default: true,  records: true,  weightClasses: usaplWeightClasses.junior, weightClasses2025: usaplWeightClasses2025.junior,},
  {name: "Junior (20-23)",       code: 'Jr',  low: 20,  high: 23,   default: true,  records: true,  weightClasses: usaplWeightClasses.junior, weightClasses2025: usaplWeightClasses2025.junior,},
  {name: "Open",                 code: 'O',   low: 24,  high: 39,   default: true,  records: true,  weightClasses: usaplWeightClasses.adult,  weightClasses2025: usaplWeightClasses2025.adult, },
  {name: "Master IA (40-44)",    code: 'M1a', low: 40,  high: 44,   default: true,  records: true,  weightClasses: usaplWeightClasses.adult,  weightClasses2025: usaplWeightClasses2025.adult, },
  {name: "Master IB (45-49)",    code: 'M1b', low: 45,  high: 49,   default: true,  records: true,  weightClasses: usaplWeightClasses.adult,  weightClasses2025: usaplWeightClasses2025.adult, },
  {name: "Master I (40-49)",     code: 'M1',  low: 40,  high: 49,   default: false, records: false, weightClasses: usaplWeightClasses.adult,  weightClasses2025: usaplWeightClasses2025.adult, },
  {name: "Master IIA (50-54)",   code: 'M2a', low: 50,  high: 54,   default: true,  records: true,  weightClasses: usaplWeightClasses.adult,  weightClasses2025: usaplWeightClasses2025.adult, },
  {name: "Master IIB (55-59)",   code: 'M2b', low: 55,  high: 59,   default: true,  records: true,  weightClasses: usaplWeightClasses.adult,  weightClasses2025: usaplWeightClasses2025.adult, },
  {name: "Master II (50-59)",    code: 'M2',  low: 50,  high: 59,   default: false, records: false, weightClasses: usaplWeightClasses.adult,  weightClasses2025: usaplWeightClasses2025.adult, },
  {name: "Master IIIA (60-64)",  code: 'M3a', low: 60,  high: 64,   default: true,  records: true,  weightClasses: usaplWeightClasses.adult,  weightClasses2025: usaplWeightClasses2025.adult, },
  {name: "Master IIIB (65-69)",  code: 'M3b', low: 65,  high: 69,   default: true,  records: true,  weightClasses: usaplWeightClasses.adult,  weightClasses2025: usaplWeightClasses2025.adult, },
  {name: "Master III (60-69)",   code: 'M3',  low: 60,  high: 69,   default: false, records: false, weightClasses: usaplWeightClasses.adult,  weightClasses2025: usaplWeightClasses2025.adult, },
  {name: "Master IVA (70-74)",   code: 'M4a', low: 70,  high: 74,   default: true,  records: true,  weightClasses: usaplWeightClasses.adult,  weightClasses2025: usaplWeightClasses2025.adult, },
  {name: "Master IVB (75-79)",   code: 'M4b', low: 75,  high: 79,   default: true,  records: true,  weightClasses: usaplWeightClasses.adult,  weightClasses2025: usaplWeightClasses2025.adult, },
  {name: "Master IV (70-79)",    code: 'M4',  low: 70,  high: 79,   default: false, records: false, weightClasses: usaplWeightClasses.adult,  weightClasses2025: usaplWeightClasses2025.adult, },
  {name: "Master VA (80-84)",    code: 'M5a', low: 80,  high: 84,   default: true,  records: true,  weightClasses: usaplWeightClasses.adult,  weightClasses2025: usaplWeightClasses2025.adult, },
  {name: "Master VB (85-89)",    code: 'M5b', low: 85,  high: 89,   default: true,  records: true,  weightClasses: usaplWeightClasses.adult,  weightClasses2025: usaplWeightClasses2025.adult, },
  {name: "Master V (80-89)",     code: 'M5',  low: 80,  high: 89,   default: false, records: false, weightClasses: usaplWeightClasses.adult,  weightClasses2025: usaplWeightClasses2025.adult, },
  {name: "Master VI (90+)",      code: 'M6',  low: 90,  high: 999,  default: true,  records: true,  weightClasses: usaplWeightClasses.adult,  weightClasses2025: usaplWeightClasses2025.adult, },
  {name: "Master (40+)",         code: 'M',   low: 40,  high: 999,  default: false, records: true,  weightClasses: usaplWeightClasses.adult,  weightClasses2025: usaplWeightClasses2025.adult, },
  {name: "Guest",                code: 'G',                         default: false, records: false, weightClasses: usaplWeightClasses.guest,  weightClasses2025: usaplWeightClasses2025.guest, },
  {name: "Adaptive Athlete",     code: 'AA',                        default: true,  records: true,  weightClasses: usaplWeightClasses.adult,  weightClasses2025: usaplWeightClasses2025.adult, },
  {name: "Para Bench",           code: 'PB',                        default: false, records: true,  weightClasses: usaplWeightClasses.adult,  weightClasses2025: usaplWeightClasses2025.adult, },
  {name: "Collegiate",           code: 'C',                         default: false, records: true,  weightClasses: usaplWeightClasses.junior, weightClasses2025: usaplWeightClasses2025.junior,},
  {name: "High School JV",       code: 'JV',                        default: false, records: true,  weightClasses: usaplWeightClasses.junior, weightClasses2025: usaplWeightClasses2025.junior,},
  {name: "High School V",        code: 'V',                         default: false, records: true,  weightClasses: usaplWeightClasses.junior, weightClasses2025: usaplWeightClasses2025.junior,},
  {name: "High School",          code: 'H',                         default: false, records: true,  weightClasses: usaplWeightClasses.junior, weightClasses2025: usaplWeightClasses2025.junior,},
  {name: "Military",             code: 'MO',                        default: false, records: true,  weightClasses: usaplWeightClasses.adult,  weightClasses2025: usaplWeightClasses2025.adult, },
  {name: "Police Fire",          code: 'PF',                        default: false, records: true,  weightClasses: usaplWeightClasses.adult,  weightClasses2025: usaplWeightClasses2025.adult, },
  {name: "Police Fire Military", code: 'PFM',                       default: false, records: true,  weightClasses: usaplWeightClasses.adult,  weightClasses2025: usaplWeightClasses2025.adult, },
  {name: "Special Olympic",      code: 'SO',                        default: false, records: true,  weightClasses: usaplWeightClasses.adult,  weightClasses2025: usaplWeightClasses2025.adult, }
];

export const getLifterUSAPLDivision = function (lifter: Lifter, meet: Meet) {
  const divisionAge = usaplStandardDivisionAge(lifter, meet);
  if (divisionAge && divisionAge >= 8) {
    return usaplBaseDivisions.find((division) => {
      return (
        division.default &&
        division.low &&
        division.high &&
        divisionAge >= division.low &&
        divisionAge <= division.high
      );
    });
  }

  return null;
};

export const getLifterUSAPLWeightClass = function (
  lifter: Lifter,
  usaplDivisionCode: string,
  meet: Meet
) {
  const division = find(usaplBaseDivisions, { code: usaplDivisionCode });
  let lifterWeightClass: FederationWeightClassConfig | undefined;
  if (lifter.gender && division && division.name) {
    const weightClasses = division.weightClasses[lifter.gender];
    const bodyWeight = lifter.bodyWeight;

    if (!bodyWeight) {
      return;
    }

    lifterWeightClass = find(weightClasses, (wc) => {
      let maxWeight = wc.maxWeight;
      if (isLbsMeet(meet)) {
        maxWeight = wc.lbsMaxWeight;
      }
      return bodyWeight <= maxWeight;
    });
  }

  return lifterWeightClass;
};

export const usaplStandardDivisionAge = function (lifter: Lifter, meet: Meet) {
  // starting in 2024 USAPL now always uses real age
  return getRealAge(lifter, meet);
};

export const exportUSAPLResults = (meet: Meet, dataArray: any): CsvArray => {
  let csvObject: CsvArray = [];
  each(dataArray, (lifter, index) => {
    if (!lifter || lifter.row === "title" || lifter.row === "header") {
      return;
    }

    const division = lifter.division;

    const genderCodes = {
      MALE: "M",
      FEMALE: "F",
      MX: "X",
    };
    const gender = genderCodes[lifter.gender as Gender];

    let rawOrEquipped = "R";
    if (find(lifter.divisions, (ld) => ld.rawOrEquipped === "EQUIPPED")) {
      rawOrEquipped = "";
    } else if (
      find(lifter.divisions, (ld) => ld.rawOrEquipped === "RAW_WITH_WRAPS")
    ) {
      rawOrEquipped = "RW";
    }
    let usaplDivisionCode = division.usaplDivisionCode;
    if (!usaplDivisionCode || usaplDivisionCode === "AUTO") {
      const standardDivision = getLifterUSAPLDivision(lifter, meet);
      if (standardDivision?.code) {
        usaplDivisionCode = standardDivision.code;
      }
    } else if (usaplDivisionCode === "HIDE") {
      return;
    }
    const weightClass = getLifterUSAPLWeightClass(
      lifter,
      usaplDivisionCode,
      meet
    );
    const combinedDivisionCode = `${gender}${rawOrEquipped}-${
      usaplDivisionCode || ""
    }`;

    const compEvents = competitionCode(division);

    // add division info to determine if cell should be displayed
    lifter = {
      ...lifter,
      division: {
        lifts: division.lifts,
      },
    };

    const row = {
      name: lifter.name,
      team: lifter.team,
      division: combinedDivisionCode,
      bodyWeight: lifter.bodyWeight,
      weightClass: get(weightClass, "name", ""),
      birthDate: lifter.birthDate,
      lot: lifter.lot,
      squat1: getAttemptDisplay(lifter, "squat", "1"),
      squat2: getAttemptDisplay(lifter, "squat", "2"),
      squat3: getAttemptDisplay(lifter, "squat", "3"),
      bench1: getAttemptDisplay(lifter, "bench", "1"),
      bench2: getAttemptDisplay(lifter, "bench", "2"),
      bench3: getAttemptDisplay(lifter, "bench", "3"),
      dead1: getAttemptDisplay(lifter, "dead", "1"),
      dead2: getAttemptDisplay(lifter, "dead", "2"),
      dead3: getAttemptDisplay(lifter, "dead", "3"),
      compEvents: compEvents,
      state: lifter.state,
      memberNumber: get(lifter, "restricted.memberNumber"),
      drugTest: lifter.wasDrugTested,
    };

    csvObject.push(row);
  });

  csvObject = sortBy(csvObject, [
    "compEvents",
    "division",
    (row) =>
      row.weightClass && typeof row.weightClass === "string"
        ? parseInt(row.weightClass, 10)
        : 0,
  ]);
  csvObject = uniqWith(csvObject, (a, b) => {
    return (
      a.name === b.name &&
      a.division === b.division &&
      a.compEvents === b.compEvents &&
      a.bodyWeight === b.bodyWeight &&
      a.birthDate === b.birthDate &&
      a.memberNumber === b.memberNumber
    );
  });

  // ref data
  csvObject.push({});
  csvObject.push({
    name: "Ref Name",
    team: "",
    division: "",
    bodyWeight: "",
    weightClass: "",
    birthDate: "Ref DOB",
    lot: "TECHNICAL SECRETARY",
    squat1: "CHIEF",
    squat2: "SIDE",
    squat3: "WEIGH-IN",
    bench1: "DOPING",
    bench2: "TC",
    bench3: "JURY",
    dead1: "SCORING",
    dead2: "EQUIPMENT CHECK",
    dead3: "MEET DIRECTOR",
    compEvents: "Event (RF for all refs)",
    state: "Ref State",
    memberNumber: "Ref MemberID",
    drugTest: "",
  });

  const header = {
    name: "Name",
    team: "Team",
    division: "Div",
    bodyWeight: `Bwt - ${getMeetUnits(meet)}`,
    weightClass: "USAPL Wt Cls",
    birthDate: "DOB",
    lot: "Lot #",
    squat1: "Squat 1",
    squat2: "Squat 2",
    squat3: "Squat 3",
    bench1: "Bench 1",
    bench2: "Bench 2",
    bench3: "Bench 3",
    dead1: "Deadlift 1",
    dead2: "Deadlift 2",
    dead3: "Deadlift 3",
    compEvents: "Event",
    state: "State",
    memberNumber: "MemberID",
    drugTest: "Drug Test",
  };

  csvObject.unshift(header);

  return csvObject;
};
