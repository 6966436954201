import React, { Component } from "react";
import { set } from "object-path-immutable";
import Modal from "react-modal";
import queryString from "query-string";
import Table, { Column } from "components/table/Table";
import Checkbox from "components/checkbox/Checkbox";
import "./AdjustableColumnsTable.scss";
import { MediaSize, Meet } from "types";
import { Alignment } from "react-virtualized";
import toNumber from "lodash/toNumber";
import map from "lodash/map";
import cloneDeep from "lodash/cloneDeep";
import each from "lodash/each";
import find from "lodash/find";
import filter from "lodash/filter";
import round from "lodash/round";
import sumBy from "lodash/sumBy";

// @ts-ignore
import setQuery from "set-query-string";

type AdjustColumnsModalProps = {
  changeColumnAttribute: (
    index: number,
    prop: "width" | "visible",
    value: any
  ) => void;
  onRequestClose: () => void;
  isOpen: boolean;
  allColumns: Column[];
};
class AdjustColumnsModal extends Component<AdjustColumnsModalProps, {}> {
  changeWidth = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = event.target.value;
    const asNumber = value === "" ? null : toNumber(value);
    this.props.changeColumnAttribute(index, "width", asNumber);
  };

  changeVisibility = (value: boolean, index: number) => {
    this.props.changeColumnAttribute(index, "visible", value);
  };

  render() {
    const { onRequestClose, isOpen, allColumns } = this.props;
    return (
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        contentLabel="Adjust Columns"
        onRequestClose={onRequestClose}
        className={{
          base: "adjust-columns-modal not-draggable",
          afterOpen: "adjust-columns-modal-after-open",
          beforeClose: "adjust-columns-modal-before-close",
        }}
        overlayClassName={{
          base: "adjust-columns-modal-overlay not-draggable",
          afterOpen: "adjust-columns-modal-overlay-after-open",
          beforeClose: "adjust-columns-modal-overlay-before-close",
        }}
      >
        <div className="content">
          <table className="table">
            <thead>
              <tr>
                <th>Column</th>
                <th>Width</th>
                <th>Visible</th>
              </tr>
            </thead>
            <tbody>
              {map(allColumns, (column, index) => {
                if (column.locked) {
                  return null;
                }

                return (
                  <tr key={`${column.key}-${index}`}>
                    <td>
                      <label>{column.label}</label>
                    </td>
                    <td>
                      <input
                        type="number"
                        value={column.width === null ? "" : column.width}
                        onChange={(e) => this.changeWidth(e, index)}
                      />
                    </td>
                    <td>
                      <Checkbox
                        value={!!column.visible}
                        onCheck={(v) => this.changeVisibility(v, index)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="button-row">
          <button onClick={onRequestClose}>Close</button>
        </div>
      </Modal>
    );
  }
}

type AdjustableColumnsTableProps = {
  columns: Column[];
  data: any;
  getCellLines: (index: number) => number;
  meet: Meet;
  numberOfFixedLeftColumns: number;
  media: MediaSize;
  padBottom?: boolean;
  modalIsOpen: boolean;
  closeModal: () => void;
  scrollToRow?: number;
  scrollToAlignment: Alignment;
  cellStyle?: React.CSSProperties;
  autoWidth?: boolean;
  sizeMultiplier?: number;
};
class AdjustableColumnsTable extends Component<
  AdjustableColumnsTableProps,
  { allColumns: Column[]; visibleColumns: Column[] }
> {
  constructor(props: AdjustableColumnsTableProps) {
    super(props);
    this.state = {
      allColumns: props.columns,
      visibleColumns: this.getVisibleColumns(props.columns),
    };
  }

  componentDidMount() {
    try {
      if (window.location.search) {
        const queryData = queryString.parse(window.location.search);
        const encodedData = queryData["t"];
        if (encodedData) {
          const data = JSON.parse(atob(encodedData as any));
          const allColumns = cloneDeep(this.state.allColumns);
          each(data, (cConfig) => {
            const column = find(allColumns, { key: cConfig.k });
            if (column) {
              column.width = cConfig.w;
              column.visible = !!cConfig.v;
            }
          });
          this.setState({
            allColumns,
            visibleColumns: this.getVisibleColumns(allColumns),
          });
        }
      }
    } catch (e) {
      console.log("Failed to parse column config data", e);
    }
  }

  setUrl = () => {
    const storableTableData = map(this.state.allColumns, (column) => {
      return {
        k: column.key,
        w: column.width,
        v: column.visible ? 1 : 0,
      };
    });

    const encodedData = btoa(JSON.stringify(storableTableData));
    setQuery({ t: encodedData });
  };

  changeColumnAttribute = (
    columnIndex: number,
    property: "width" | "visible",
    value: any
  ) => {
    const allColumns = set(
      this.state.allColumns,
      [columnIndex, property],
      value
    );
    this.setState(
      { allColumns, visibleColumns: this.getVisibleColumns(allColumns) },
      this.setUrl
    );
  };

  getVisibleColumns = (columns: Column[]) => {
    return filter(columns, "visible");
  };

  render() {
    const {
      data,
      getCellLines,
      meet,
      numberOfFixedLeftColumns,
      media,
      padBottom,
      modalIsOpen,
      closeModal,
      scrollToRow,
      scrollToAlignment,
      cellStyle,
      autoWidth,
      sizeMultiplier,
    } = this.props;

    let columns = this.state.visibleColumns;
    if (sizeMultiplier) {
      columns = map(columns, (column) => {
        return { ...column, width: round(column.width * sizeMultiplier) };
      });
    }

    const style: React.CSSProperties = { height: "100%" };
    if (autoWidth) {
      // a little extra to account for possible scrollbar width
      style.width = sumBy(columns, "width") + 20;
    }

    return (
      <div style={style}>
        <AdjustColumnsModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          allColumns={this.state.allColumns}
          changeColumnAttribute={this.changeColumnAttribute}
        />
        <Table
          data={data}
          cellStyle={cellStyle}
          columns={columns}
          getCellLines={getCellLines}
          meet={meet}
          numberOfFixedLeftColumns={numberOfFixedLeftColumns}
          media={media}
          padBottom={padBottom}
          scrollToRow={scrollToRow}
          scrollToAlignment={scrollToAlignment}
        />
      </div>
    );
  }
}

export default AdjustableColumnsTable;
