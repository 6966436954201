import { setScreenSize } from "actions";
import React from "react";
import { useDispatch } from "react-redux";

export const useResize = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    const handleResize = () => dispatch(setScreenSize(window.innerWidth));
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch]);
};
