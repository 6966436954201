import React from "react";
import PouchInput from "components/PouchInput";
import { Column } from "components/table/Table";
import { Meet } from "types";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import toNumber from "lodash/toNumber";
import get from "lodash/get";

const WeightClassCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: Column;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const division = data;
  if (division.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    return (
      <div style={style} className="table-cell-inner">
        {map(
          sortBy(division.weightClasses, (weightClass) =>
            toNumber(weightClass.maxWeight)
          ),
          (weightClass) => {
            return (
              <div key={weightClass._id} className="inner-row">
                {/* Old version before schema stored weight classes as individual docs */}
                {division.schema ? (
                  <PouchInput
                    type={column.type === "number" ? "number" : "text"}
                    meetId={meet._id}
                    documentId={division._id}
                    name={["weightClasses", weightClass._id, column.key]}
                    value={get(division, [
                      "weightClasses",
                      weightClass._id,
                      column.key,
                    ])}
                  />
                ) : (
                  <PouchInput
                    type={column.type === "number" ? "number" : "text"}
                    meetId={meet._id}
                    documentId={weightClass._id}
                    name={column.key}
                    value={get(division, [
                      "weightClasses",
                      weightClass._id,
                      column.key,
                    ])}
                  />
                )}
              </div>
            );
          }
        )}
      </div>
    );
  }
};

export default WeightClassCell;
