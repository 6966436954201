import React from "react";
import { addLifter, deleteLifter } from "util/pouchActions";
import AddIcon from "icons/AddIcon";
import DeleteIcon from "icons/DeleteIcon";
import { Lifter, Meet } from "types";
import get from "lodash/get";

const confirmDeleteLifter = (lifter: Lifter, meetId: string) => {
  const message = `Are you sure you want to delete ${get(
    lifter,
    "name",
    "this lifter"
  )}? This cannot be undone.`;
  if (window.confirm(message)) {
    deleteLifter(lifter, meetId);
  }
};

const ButtonCell = ({
  data,
  meet,
  style,
}: {
  data: any;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner button-cell">
        <button onClick={() => addLifter(meet._id)}>
          <AddIcon />
        </button>
      </div>
    );
  } else {
    return (
      <div style={style} className="table-cell-inner button-cell">
        <button onClick={() => confirmDeleteLifter(lifter, meet._id)}>
          <DeleteIcon />
        </button>
      </div>
    );
  }
};

export default ButtonCell;
