import React from "react";
import Modal from "react-modal";
import { updateAttributesOnDocument } from "util/pouchActions";
import { Lifter, Meet } from "types";
import Select from "components/select/Select";
import {
  getFlightOptions,
  getPlatformOptions,
  getSessionOptions,
} from "util/options";
import size from "lodash/size";

const initialFilters: {
  platformId: string | null;
  session: number | null;
  flight: string | null;
} = {
  platformId: null,
  session: null,
  flight: null,
};

const BulkEditModal = ({
  meet,
  onRequestClose,
  isOpen,
  filteredLifters,
  activeFilters,
}: {
  meet: Meet;
  onRequestClose: () => void;
  isOpen: boolean;
  filteredLifters: Lifter[];
  activeFilters: boolean;
}) => {
  const [filters, setFilters] = React.useState(initialFilters);
  const [loading, setLoading] = React.useState(false);

  const close = () => {
    setFilters(initialFilters);
    setLoading(false);
    onRequestClose();
  };

  const updateLiters = () => {
    setLoading(true);
    const updatedData: Partial<Lifter> = {};
    if (filters.platformId) {
      updatedData.platformId = filters.platformId;
    }
    if (filters.session) {
      updatedData.session = filters.session;
    }
    if (filters.flight) {
      updatedData.flight = filters.flight;
    }

    if (size(updatedData)) {
      filteredLifters.forEach((lifter) => {
        updateAttributesOnDocument(meet._id, lifter._id, updatedData);
      });
      // give time for data to loop back into the UI from pouchdb
      // usually we use a full page loader but that would wipe out the filters.
      setTimeout(() => close(), size(filteredLifters) * 150);
    } else {
      close();
    }
  };

  const onChangeSelectFilter = (
    name: keyof typeof initialFilters,
    value: string | number | undefined | null
  ) => {
    setFilters({ ...filters, [name]: value });
  };

  const platformOptions = getPlatformOptions({}, meet);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      contentLabel="Bulk Edit Lifters"
      onRequestClose={onRequestClose}
      className={{
        base: "generate-lot-numbers-modal",
        afterOpen: "generate-lot-numbers-modal-after-open",
        beforeClose: "generate-lot-numbers-modal-before-close",
      }}
      overlayClassName={{
        base: "generate-lot-numbers-modal-overlay",
        afterOpen: "generate-lot-numbers-modal-overlay-after-open",
        beforeClose: "generate-lot-numbers-modal-overlay-before-close",
      }}
    >
      <div className="content">
        <h2>
          Bulk Edit {filteredLifters.length}{" "}
          {activeFilters ? "Filtered" : "(ALL)"} Lifters
        </h2>

        <div className="bulk-edit-row">
          Platform:
          <Select
            name="platformId"
            value={filters.platformId}
            options={platformOptions}
            blankValueLabel="NO CHANGE"
            onChange={(value) => onChangeSelectFilter("platformId", value)}
          />
        </div>
        <div className="bulk-edit-row">
          Session:
          <Select
            name="session"
            value={filters.session}
            options={getSessionOptions()}
            blankValueLabel="NO CHANGE"
            onChange={(value) => onChangeSelectFilter("session", value)}
          />
        </div>
        <div className="bulk-edit-row">
          Flight:
          <Select
            name="flight"
            value={filters.flight}
            options={getFlightOptions()}
            blankValueLabel="NO CHANGE"
            onChange={(value) => onChangeSelectFilter("flight", value)}
          />
        </div>

        <p>
          WARNING: THIS WILL OVERWRITE DATA FOR{" "}
          {activeFilters ? "THE CURRENTLY FILTERED LIFTERS" : "ALL LIFTERS"}.{" "}
          {filteredLifters.length} WILL BE UPDATED. THIS CANNOT BE UNDONE.
        </p>
      </div>
      <div className="button-row">
        <button disabled={loading} onClick={close} style={{ marginRight: 12 }}>
          Cancel
        </button>
        <button disabled={loading} onClick={updateLiters}>
          {loading ? "Saving..." : "Save"}
        </button>
      </div>
    </Modal>
  );
};

export default BulkEditModal;
