import React from "react";
import classNames from "classnames";
import SortableHeaderCell from "components/SortableHeaderCell";
import { Column } from "components/table/Table";
import get from "lodash/get";

const NameCell = ({
  data,
  column,
  style,
}: {
  data: any;
  column: Column;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return <SortableHeaderCell style={style} column={column} />;
  } else {
    const value = get(lifter, "membershipStatus");
    return (
      <div style={style} className="ellipsis table-cell-inner">
        {value && (
          <div>
            <span
              className={classNames({
                error: value !== "OK" && value !== "Active",
              })}
            >
              {value}
            </span>
          </div>
        )}
      </div>
    );
  }
};

export default NameCell;
