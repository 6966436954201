import React from "react";
import "./EquipmentCheckSheet.scss";
import { getSessionOptions } from "util/options";
import { useMeet } from "util/useMeet";
import sortBy from "lodash/sortBy";
import filter from "lodash/filter";
import size from "lodash/size";
import map from "lodash/map";

const EquipmentCheckSheet = () => {
  const meet = useMeet();
  const lifters = sortBy(meet.lifters, ["name"]);

  return (
    <div className="check-in-sheet">
      {map(getSessionOptions(), (session) => {
        const sessionLifters = filter(lifters, { session: session.value });
        const sessionCount = size(sessionLifters);
        if (!sessionCount) {
          return null;
        }

        return (
          <div key={session.value} className="session-pages">
            <div className="meet-name">
              {meet.date} - {meet.name} - Session {session.value} - EQUIPMENT
              CHECK
            </div>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Member #</th>
                  <th>Flight</th>
                  <th>Lot #</th>
                  <th style={{ width: "22%" }}>Remarks</th>
                  <th style={{ width: "25%" }}>Signature</th>
                </tr>
              </thead>
              <tbody>
                {map(sessionLifters, (lifter) => {
                  return (
                    <tr key={lifter._id}>
                      <td>{lifter.name}</td>
                      <td>{lifter.restricted?.memberNumber}</td>
                      <td>{lifter.flight}</td>
                      <td>{lifter.lot}</td>
                      <td></td>
                      <td></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
};

export default EquipmentCheckSheet;
