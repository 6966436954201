import React from "react";
import FixedHeightWrapper from "app/meets/FixedHeightWrapper";
import { getLifterAwardsWeightClassDoc } from "util/lifterHelper";
import TeamResultsTable from "./TeamResultsTable";
import "./TeamIndex.scss";
import { useMeet } from "util/useMeet";
import { Lifter, WeightClass } from "types";
import { useParams } from "react-router";
import filter from "lodash/filter";
import find from "lodash/find";
import map from "lodash/map";

const TeamIndex = () => {
  const meet = useMeet();
  const { teamName } = useParams<{
    teamName: string;
  }>();

  const shouldDisplayRow = (lifter: Lifter) => {
    return lifter.team === teamName;
  };

  const teamLifters = filter(meet.lifters, { team: teamName });
  const divisions = filter(meet.divisions, (division) => {
    return !!find(teamLifters, (lifter) => {
      return !!find(lifter.divisions, { divisionId: division._id });
    });
  });

  const divisionsAndWeightClasses = map(divisions, (division) => {
    const weightClasses = filter(division.weightClasses, (wc) => {
      return !!find(teamLifters, (lifter) => {
        return !!find(lifter.divisions, (ld) => {
          return (
            ld.divisionId === division._id &&
            (getLifterAwardsWeightClassDoc(meet, lifter, ld) as WeightClass)
              ._id === wc._id
          );
        });
      });
    });
    return { ...division, weightClasses };
  });

  return (
    <FixedHeightWrapper>
      <div className="team-index">
        <div className="team-name">{teamName}</div>
        <div className="table-wrapper">
          <TeamResultsTable
            divisions={divisionsAndWeightClasses}
            shouldDisplayRow={shouldDisplayRow}
          />
        </div>
      </div>
    </FixedHeightWrapper>
  );
};

export default TeamIndex;
