import React from "react";
import {
  getGenderOptions,
  getRawOrEquippedOptions,
  getSessionOptions,
  getFlightOptions,
  getPlatformOptions,
  getStateOptions,
  getCountryOptions,
} from "util/options";

import SortableTable from "components/SortableTable";
import DefaultCell from "../cells/DefaultCell";
import RawOrEquippedCell from "./cells/RawOrEquippedCell";
import OptionCell from "../cells/OptionCell";
import DivisionCell from "./cells/DivisionCell";
import WeightClassCell from "./cells/WeightClassCell";
import { getBenchLabel } from "util/meetHelper";
import size from "lodash/size";
import get from "lodash/get";
import { MediaSize, Meet } from "types";
import { Column } from "components/table/Table";

const ImportLiftersTable = ({
  items,
  meet,
  media,
}: {
  items: any;
  meet: Meet;
  media: MediaSize;
}) => {
  const getAllColumns = (): Column[] => {
    return [
      {
        key: "name",
        label: "Name",
        width: 200,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 1,
        renderer: DefaultCell,
      },
      {
        key: "team",
        label: "Team",
        width: 200,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 2,
        renderer: DefaultCell,
      },
      {
        key: "lot",
        label: "Lot",
        width: 100,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 3,
        renderer: DefaultCell,
      },
      {
        key: "platformId",
        label: "Platform",
        width: 200,
        options: getPlatformOptions,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 4,
        renderer: OptionCell,
      },
      {
        key: "session",
        label: "Session",
        width: 100,
        options: getSessionOptions,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 5,
        renderer: OptionCell,
      },
      {
        key: "flight",
        label: "Flight",
        width: 100,
        options: getFlightOptions,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 6,
        renderer: OptionCell,
      },
      {
        key: "birthDate",
        label: "Birth Date",
        width: 110,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 7,
        renderer: DefaultCell,
      },
      {
        key: "memberNumber",
        label: "Member #",
        width: 110,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 8,
        renderer: DefaultCell,
      },
      {
        key: "state",
        label: "State",
        width: 100,
        options: getStateOptions,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 9,
        renderer: OptionCell,
      },
      {
        key: "country",
        label: "Country",
        width: 100,
        options: getCountryOptions,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 10,
        renderer: OptionCell,
      },
      {
        key: "gender",
        label: "Gender",
        width: 100,
        options: getGenderOptions,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 11,
        renderer: OptionCell,
      },
      {
        key: "rawOrEquipped",
        label: "R/E",
        width: 125,
        options: getRawOrEquippedOptions,
        sortable: false,
        renderer: RawOrEquippedCell,
      },
      {
        key: "name",
        label: "Awards Divisions",
        width: 220,
        renderer: DivisionCell,
      },
      {
        key: "declaredAwardsWeightClassId",
        label: "Declared Awards Weight Class",
        width: 230,
        renderer: WeightClassCell,
      },
      {
        key: "bodyWeight",
        label: "Body Weight",
        width: 130,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 12,
        renderer: DefaultCell,
      },
      {
        key: "squatRackHeight",
        label: "Squat Rack Height",
        width: 170,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 13,
        renderer: DefaultCell,
      },
      {
        key: "benchRackHeight",
        label: `${getBenchLabel(meet)} Rack Height`,
        width: 170,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 14,
        renderer: DefaultCell,
      },
      {
        key: "lifts.squat.1.weight",
        label: "Squat 1",
        width: 100,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 15,
        renderer: DefaultCell,
      },
      {
        key: "lifts.bench.1.weight",
        label: `${getBenchLabel(meet)} 1`,
        width: 100,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 16,
        renderer: DefaultCell,
      },
      {
        key: "lifts.dead.1.weight",
        label: "Dead 1",
        width: 100,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 17,
        renderer: DefaultCell,
      },
      {
        key: "email",
        label: "Email",
        width: 180,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 18,
        renderer: DefaultCell,
      },
    ];
  };

  const getCellLines = (index: number, lifters: any) => {
    const rows = size(get(lifters, [index, "divisions"], 1));
    if (rows === 0) {
      return 1;
    }

    return rows;
  };

  return (
    <SortableTable
      data={items}
      columns={getAllColumns()}
      getCellLines={getCellLines}
      meet={meet}
      media={media}
      numberOfFixedLeftColumns={1}
    />
  );
};

export default ImportLiftersTable;
