import React from "react";
import { addDivision, deleteDivision } from "util/pouchActions";
import AddIcon from "icons/AddIcon";
import DeleteIcon from "icons/DeleteIcon";
import { Division, Meet } from "types";
import get from "lodash/get";

const confirmDeleteDivision = (division: Division, meetId: string) => {
  const message = `Are you sure you want to delete ${get(
    division,
    "name",
    "this division"
  )}? This cannot be undone.`;
  if (window.confirm(message)) {
    deleteDivision(division, meetId);
  }
};

const ButtonCell = ({
  data,
  meet,
  style,
}: {
  data: any;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const division = data;
  if (division.row === "header") {
    const division = {
      lifts: {
        squat: true,
        bench: true,
        dead: true,
      },
      rawOrEquipped: meet.federation === "USSF" ? "RAW" : null,
      usaplDivisionCode: "AUTO",
    };
    return (
      <div style={style} className="table-cell-inner button-cell">
        <button onClick={() => addDivision(meet._id, division)}>
          <AddIcon />
        </button>
      </div>
    );
  } else {
    return (
      <div style={style} className="table-cell-inner button-cell">
        <button onClick={() => confirmDeleteDivision(division, meet._id)}>
          <DeleteIcon />
        </button>
      </div>
    );
  }
};

export default ButtonCell;
