import React from "react";
import get from "lodash/get";
import { Link } from "react-router-dom";

const TeamCell = ({ data, column, meet, style }) => {
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    return (
      <div style={style} className="ellipsis table-cell-inner">
        <Link
          to={`/meets/${get(meet, "_id")}/team/${encodeURIComponent(
            get(lifter, "team")
          )}`}
        >
          {get(lifter, "team")}
        </Link>
      </div>
    );
  }
};

export default TeamCell;
