import React from "react";
import SortableTable from "components/SortableTable";
import {
  getPlatformOptions,
  getSessionOptions,
  getFlightOptions,
} from "util/options";
import { getBenchLabel } from "util/meetHelper";
import DefaultTextCell from "components/cells/DefaultTextCell";
import DefaultInputCell from "components/cells/DefaultInputCell";
import PlatformCell from "./cells/PlatformCell";
import RackHeightCell from "./cells/RackHeightCell";
import AttemptCell from "./cells/AttemptCell";
import { Lifter, MediaSize, Meet } from "types";
import toLower from "lodash/toLower";
import get from "lodash/get";
import toNumber from "lodash/toNumber";
import { Column } from "components/table/Table";

const getAllColumns = (meet: Meet): Column[] => {
  const columns: Column[] = [
    {
      key: "name",
      label: "Name",
      width: 190,
      type: "text",
      sortable: true,
      sortFunction: (row: any) => toLower(row.name),
      sortDirection: "asc",
      sortOrder: 2,
      renderer: DefaultTextCell,
    },
    {
      key: "lot",
      label: "Lot",
      width: 80,
      type: "number",
      sortable: true,
      sortDirection: "asc",
      sortOrder: 1,
      renderer: DefaultTextCell,
    },
    {
      key: "platformId",
      label: "Platform",
      width: 90,
      type: "select",
      options: getPlatformOptions,
      sortable: true,
      sortFunction: (row: any) =>
        get(meet, ["platforms", row.platformId, "name"]),
      sortDirection: "asc",
      sortOrder: 5,
      renderer: PlatformCell,
    },
    {
      key: "session",
      label: "Session",
      width: 90,
      type: "select",
      options: getSessionOptions,
      sortable: true,
      sortDirection: "asc",
      sortOrder: 4,
      renderer: DefaultTextCell,
    },
    {
      key: "flight",
      label: "Flight",
      width: 90,
      type: "select",
      options: getFlightOptions,
      sortable: true,
      sortDirection: "asc",
      sortOrder: 3,
      renderer: DefaultTextCell,
    },
    {
      key: "bodyWeight",
      label: "Body Weight",
      width: 120,
      type: "number",
      sortable: true,
      sortFunction: (row: any) => toNumber(row.bodyWeight),
      sortDirection: "asc",
      sortOrder: 9,
      renderer: DefaultInputCell,
    },
    {
      key: "squatRackHeight",
      liftName: "squat",
      label: "Squat Rack Height",
      width: 160,
      type: "text",
      sortable: true,
      sortDirection: "asc",
      sortOrder: 16,
      renderer: RackHeightCell,
    },
    {
      key: "benchRackHeight",
      liftName: "bench",
      label: `${getBenchLabel(meet)} Rack Height`,
      width: 170,
      type: "text",
      sortable: true,
      sortDirection: "asc",
      sortOrder: 17,
      renderer: RackHeightCell,
    },
    {
      key: "lifts.squat.1",
      label: "Squat 1",
      width: 90,
      liftName: "squat",
      attemptNumber: "1",
      sortable: true,
      sortFunction: (row: any) =>
        toNumber(get(row, ["lifts", "squat", 1, "weight"])),
      sortDirection: "asc",
      sortOrder: 10,
      renderer: AttemptCell,
    },
    {
      key: "lifts.bench.1",
      label: `${getBenchLabel(meet)} 1`,
      width: 90,
      liftName: "bench",
      attemptNumber: "1",
      sortable: true,
      sortFunction: (row: any) =>
        toNumber(get(row, ["lifts", "bench", 1, "weight"])),
      sortDirection: "asc",
      sortOrder: 11,
      renderer: AttemptCell,
    },
    {
      key: "lifts.dead.1",
      label: "Dead 1",
      width: 90,
      liftName: "dead",
      attemptNumber: "1",
      sortable: true,
      sortFunction: (row: any) =>
        toNumber(get(row, ["lifts", "dead", 1, "weight"])),
      sortDirection: "asc",
      sortOrder: 12,
      renderer: AttemptCell,
    },
  ];

  return columns;
};

const getCellLines = () => {
  return 1;
};

const WeighInEntryTable = ({
  meet,
  media,
  lifters,
}: {
  meet: Meet;
  media: MediaSize;
  lifters: Lifter[];
}) => {
  return (
    <SortableTable
      data={lifters}
      columns={getAllColumns(meet)}
      getCellLines={getCellLines}
      meet={meet}
      numberOfFixedLeftColumns={1}
      media={media}
    />
  );
};

export default WeighInEntryTable;
