export const womenGlossCoef = {
  40: 1.3437,
  40.05: 1.3426,
  40.1: 1.3415,
  40.15: 1.3404,
  40.2: 1.3393,
  40.25: 1.3381,
  40.3: 1.337,
  40.35: 1.3359,
  40.4: 1.3348,
  40.45: 1.3337,
  40.5: 1.3326,
  40.55: 1.3315,
  40.6: 1.3305,
  40.65: 1.3294,
  40.7: 1.3283,
  40.75: 1.3272,
  40.8: 1.3261,
  40.85: 1.325,
  40.9: 1.3239,
  40.95: 1.3228,
  41: 1.3217,
  41.05: 1.3206,
  41.1: 1.3195,
  41.15: 1.3184,
  41.2: 1.3174,
  41.25: 1.3163,
  41.3: 1.3152,
  41.35: 1.3141,
  41.4: 1.313,
  41.45: 1.3119,
  41.5: 1.3109,
  41.55: 1.3098,
  41.6: 1.3087,
  41.65: 1.3076,
  41.7: 1.3065,
  41.75: 1.3054,
  41.8: 1.3043,
  41.85: 1.3033,
  41.9: 1.3022,
  41.95: 1.3011,
  42: 1.3001,
  42.05: 1.299,
  42.1: 1.2979,
  42.15: 1.2969,
  42.2: 1.2958,
  42.25: 1.2947,
  42.3: 1.2936,
  42.35: 1.2926,
  42.4: 1.2915,
  42.45: 1.2904,
  42.5: 1.2894,
  42.55: 1.2883,
  42.6: 1.2873,
  42.65: 1.2862,
  42.7: 1.2851,
  42.75: 1.2841,
  42.8: 1.283,
  42.85: 1.282,
  42.9: 1.2809,
  42.95: 1.2799,
  43: 1.2788,
  43.05: 1.2777,
  43.1: 1.2767,
  43.15: 1.2756,
  43.2: 1.2746,
  43.25: 1.2736,
  43.3: 1.2725,
  43.35: 1.2715,
  43.4: 1.2704,
  43.45: 1.2694,
  43.5: 1.2684,
  43.55: 1.2673,
  43.6: 1.2662,
  43.65: 1.2652,
  43.7: 1.2642,
  43.75: 1.2631,
  43.8: 1.2621,
  43.85: 1.261,
  43.9: 1.26,
  43.95: 1.259,
  44: 1.258,
  44.05: 1.2569,
  44.1: 1.2559,
  44.15: 1.2548,
  44.2: 1.2538,
  44.25: 1.2528,
  44.3: 1.2518,
  44.35: 1.2508,
  44.4: 1.2497,
  44.45: 1.2487,
  44.5: 1.2477,
  44.55: 1.2467,
  44.6: 1.2457,
  44.65: 1.2446,
  44.7: 1.2436,
  44.75: 1.2426,
  44.8: 1.2416,
  44.85: 1.2406,
  44.9: 1.2396,
  44.95: 1.2384,
  45: 1.2373,
  45.05: 1.2364,
  45.1: 1.2355,
  45.15: 1.2345,
  45.2: 1.2335,
  45.25: 1.2325,
  45.3: 1.2315,
  45.35: 1.2305,
  45.4: 1.2295,
  45.45: 1.2285,
  45.5: 1.2275,
  45.55: 1.2265,
  45.6: 1.2255,
  45.65: 1.2245,
  45.7: 1.2235,
  45.75: 1.2225,
  45.8: 1.2215,
  45.85: 1.2205,
  45.9: 1.2195,
  45.95: 1.2185,
  46: 1.2175,
  46.05: 1.2165,
  46.1: 1.2156,
  46.15: 1.2146,
  46.2: 1.2136,
  46.25: 1.2126,
  46.3: 1.2116,
  46.35: 1.2106,
  46.4: 1.2097,
  46.45: 1.2087,
  46.5: 1.2077,
  46.55: 1.2067,
  46.6: 1.2058,
  46.65: 1.2048,
  46.7: 1.2038,
  46.75: 1.2028,
  46.8: 1.2019,
  46.85: 1.2009,
  46.9: 1.2,
  46.95: 1.199,
  47: 1.198,
  47.05: 1.197,
  47.1: 1.1961,
  47.15: 1.1951,
  47.2: 1.1942,
  47.25: 1.1932,
  47.3: 1.1922,
  47.35: 1.1913,
  47.4: 1.1904,
  47.45: 1.1894,
  47.5: 1.1884,
  47.55: 1.1875,
  47.6: 1.1865,
  47.65: 1.1856,
  47.7: 1.1846,
  47.75: 1.1837,
  47.8: 1.1827,
  47.85: 1.1818,
  47.9: 1.1809,
  47.95: 1.1799,
  48: 1.179,
  48.05: 1.178,
  48.1: 1.1771,
  48.15: 1.1761,
  48.2: 1.1752,
  48.25: 1.1743,
  48.3: 1.1733,
  48.35: 1.1724,
  48.4: 1.1715,
  48.45: 1.1705,
  48.5: 1.1696,
  48.55: 1.1687,
  48.6: 1.1678,
  48.65: 1.1668,
  48.7: 1.1659,
  48.75: 1.165,
  48.8: 1.1641,
  48.85: 1.1631,
  48.9: 1.1622,
  48.95: 1.1613,
  49: 1.1604,
  49.05: 1.1594,
  49.1: 1.1585,
  49.15: 1.1576,
  49.2: 1.1568,
  49.25: 1.1558,
  49.3: 1.1549,
  49.35: 1.154,
  49.4: 1.1531,
  49.45: 1.1522,
  49.5: 1.1513,
  49.55: 1.1504,
  49.6: 1.1495,
  49.65: 1.1486,
  49.7: 1.1477,
  49.75: 1.1468,
  49.8: 1.1459,
  49.85: 1.145,
  49.9: 1.1441,
  49.95: 1.1432,
  50: 1.1423,
  50.05: 1.1414,
  50.1: 1.1405,
  50.15: 1.1396,
  50.2: 1.1388,
  50.25: 1.1379,
  50.3: 1.137,
  50.35: 1.1361,
  50.4: 1.1352,
  50.45: 1.1343,
  50.5: 1.1334,
  50.55: 1.1325,
  50.6: 1.1317,
  50.65: 1.1308,
  50.7: 1.1299,
  50.75: 1.129,
  50.8: 1.1282,
  50.85: 1.1273,
  50.9: 1.1264,
  50.95: 1.1255,
  51: 1.1247,
  51.05: 1.1238,
  51.1: 1.123,
  51.15: 1.1221,
  51.2: 1.1212,
  51.25: 1.1203,
  51.3: 1.1195,
  51.35: 1.1186,
  51.4: 1.1178,
  51.45: 1.1169,
  51.5: 1.1161,
  51.55: 1.1152,
  51.6: 1.1144,
  51.65: 1.1135,
  51.7: 1.1126,
  51.75: 1.1118,
  51.8: 1.111,
  51.85: 1.1101,
  51.9: 1.1093,
  51.95: 1.1084,
  52: 1.1076,
  52.05: 1.1067,
  52.1: 1.1059,
  52.15: 1.105,
  52.2: 1.1042,
  52.25: 1.1033,
  52.3: 1.1025,
  52.35: 1.1017,
  52.4: 1.1009,
  52.45: 1.1,
  52.5: 1.0992,
  52.55: 1.0983,
  52.6: 1.0975,
  52.65: 1.0967,
  52.7: 1.0959,
  52.75: 1.0951,
  52.8: 1.0942,
  52.85: 1.0934,
  52.9: 1.0926,
  52.95: 1.0918,
  53: 1.091,
  53.05: 1.0901,
  53.1: 1.0893,
  53.15: 1.0885,
  53.2: 1.0877,
  53.25: 1.0869,
  53.3: 1.0861,
  53.35: 1.0852,
  53.4: 1.0844,
  53.45: 1.0836,
  53.5: 1.0828,
  53.55: 1.082,
  53.6: 1.0812,
  53.65: 1.0804,
  53.7: 1.0796,
  53.75: 1.0788,
  53.8: 1.078,
  53.85: 1.0772,
  53.9: 1.0764,
  53.95: 1.0756,
  54: 1.0748,
  54.05: 1.074,
  54.1: 1.0732,
  54.15: 1.0724,
  54.2: 1.0716,
  54.25: 1.0708,
  54.3: 1.0701,
  54.35: 1.0692,
  54.4: 1.0684,
  54.45: 1.0676,
  54.5: 1.0669,
  54.55: 1.0661,
  54.6: 1.0653,
  54.65: 1.0645,
  54.7: 1.0638,
  54.75: 1.063,
  54.8: 1.0622,
  54.85: 1.0614,
  54.9: 1.0606,
  54.95: 1.0598,
  55: 1.0591,
  55.05: 1.0583,
  55.1: 1.0575,
  55.15: 1.0568,
  55.2: 1.0561,
  55.25: 1.0553,
  55.3: 1.0545,
  55.35: 1.0537,
  55.4: 1.053,
  55.45: 1.0522,
  55.5: 1.0514,
  55.55: 1.0507,
  55.6: 1.05,
  55.65: 1.0492,
  55.7: 1.0484,
  55.75: 1.0477,
  55.8: 1.0469,
  55.85: 1.0462,
  55.9: 1.0454,
  55.95: 1.0446,
  56: 1.0439,
  56.05: 1.0431,
  56.1: 1.0424,
  56.15: 1.0417,
  56.2: 1.041,
  56.25: 1.0402,
  56.3: 1.0394,
  56.35: 1.0387,
  56.4: 1.038,
  56.45: 1.0372,
  56.5: 1.0365,
  56.55: 1.0357,
  56.6: 1.035,
  56.65: 1.0342,
  56.7: 1.0335,
  56.75: 1.0326,
  56.8: 1.0321,
  56.85: 1.0313,
  56.9: 1.0306,
  56.95: 1.0299,
  57: 1.0292,
  57.05: 1.0284,
  57.1: 1.0277,
  57.15: 1.027,
  57.2: 1.0263,
  57.25: 1.0255,
  57.3: 1.0248,
  57.35: 1.0241,
  57.4: 1.0234,
  57.45: 1.0227,
  57.5: 1.022,
  57.55: 1.0212,
  57.6: 1.0205,
  57.65: 1.0198,
  57.7: 1.0191,
  57.75: 1.0184,
  57.8: 1.0177,
  57.85: 1.017,
  57.9: 1.0163,
  57.95: 1.0156,
  58: 1.0149,
  58.05: 1.0142,
  58.1: 1.0135,
  58.15: 1.0127,
  58.2: 1.012,
  58.25: 1.0113,
  58.3: 1.0107,
  58.35: 1.01,
  58.4: 1.0093,
  58.45: 1.0086,
  58.5: 1.0079,
  58.55: 1.0072,
  58.6: 1.0065,
  58.65: 1.0058,
  58.7: 1.0051,
  58.75: 1.0044,
  58.8: 1.0037,
  58.85: 1.003,
  58.9: 1.0024,
  58.95: 1.0017,
  59: 1.001,
  59.05: 1.0003,
  59.1: 0.9997,
  59.15: 0.99902,
  59.2: 0.99835,
  59.25: 0.99765,
  59.3: 0.99695,
  59.35: 0.99627,
  59.4: 0.9956,
  59.45: 0.99492,
  59.5: 0.99425,
  59.55: 0.99332,
  59.6: 0.99295,
  59.65: 0.99227,
  59.7: 0.9916,
  59.75: 0.99095,
  59.8: 0.9903,
  59.85: 0.9896,
  59.9: 0.9889,
  59.95: 0.98825,
  60: 0.9876,
  60.05: 0.98692,
  60.1: 0.98625,
  60.15: 0.9856,
  60.2: 0.98495,
  60.25: 0.98432,
  60.3: 0.9837,
  60.35: 0.98305,
  60.4: 0.9824,
  60.45: 0.98175,
  60.5: 0.9811,
  60.55: 0.98042,
  60.6: 0.97975,
  60.65: 0.9791,
  60.7: 0.97845,
  60.75: 0.9778,
  60.8: 0.97715,
  60.85: 0.97652,
  60.9: 0.9759,
  60.95: 0.97525,
  61: 0.9746,
  61.05: 0.97397,
  61.1: 0.97335,
  61.15: 0.97272,
  61.2: 0.9721,
  61.25: 0.97145,
  61.3: 0.9708,
  61.35: 0.97015,
  61.4: 0.9695,
  61.45: 0.9689,
  61.5: 0.9683,
  61.55: 0.96765,
  61.6: 0.967,
  61.65: 0.96637,
  61.7: 0.96575,
  61.75: 0.96512,
  61.8: 0.9645,
  61.85: 0.96387,
  61.9: 0.96325,
  61.95: 0.96265,
  62: 0.96205,
  62.05: 0.96142,
  62.1: 0.9608,
  62.15: 0.96017,
  62.2: 0.95955,
  62.25: 0.95895,
  62.3: 0.95835,
  62.35: 0.95772,
  62.4: 0.9571,
  62.45: 0.9565,
  62.5: 0.9559,
  62.55: 0.9553,
  62.6: 0.9547,
  62.65: 0.9541,
  62.7: 0.9535,
  62.75: 0.95287,
  62.8: 0.95225,
  62.85: 0.95167,
  62.9: 0.9511,
  62.95: 0.95047,
  63: 0.94985,
  63.05: 0.94925,
  63.1: 0.94865,
  63.15: 0.94805,
  63.2: 0.94745,
  63.25: 0.94685,
  63.3: 0.94625,
  63.35: 0.94565,
  63.4: 0.94505,
  63.45: 0.94447,
  63.5: 0.9439,
  63.55: 0.94332,
  63.6: 0.94275,
  63.65: 0.94215,
  63.7: 0.94155,
  63.75: 0.94097,
  63.8: 0.9404,
  63.85: 0.9398,
  63.9: 0.9392,
  63.95: 0.93862,
  64: 0.93805,
  64.05: 0.9377,
  64.1: 0.93735,
  64.15: 0.93655,
  64.2: 0.93575,
  64.25: 0.93517,
  64.3: 0.9346,
  64.35: 0.93402,
  64.4: 0.93345,
  64.45: 0.93287,
  64.5: 0.9323,
  64.55: 0.93172,
  64.6: 0.93115,
  64.65: 0.93057,
  64.7: 0.93,
  64.75: 0.92945,
  64.8: 0.9289,
  64.85: 0.92832,
  64.9: 0.92775,
  64.95: 0.9272,
  65: 0.92665,
  65.05: 0.92607,
  65.1: 0.9255,
  65.15: 0.92495,
  65.2: 0.9244,
  65.25: 0.92382,
  65.3: 0.92325,
  65.35: 0.92272,
  65.4: 0.9222,
  65.45: 0.92165,
  65.5: 0.9211,
  65.55: 0.92052,
  65.6: 0.91995,
  65.65: 0.9194,
  65.7: 0.91885,
  65.75: 0.91832,
  65.8: 0.9178,
  65.85: 0.91722,
  65.9: 0.91665,
  65.95: 0.91612,
  66: 0.9156,
  66.05: 0.91505,
  66.1: 0.9145,
  66.15: 0.91397,
  66.2: 0.91345,
  66.25: 0.9129,
  66.3: 0.91235,
  66.35: 0.91182,
  66.4: 0.9113,
  66.45: 0.91075,
  66.5: 0.9102,
  66.55: 0.90967,
  66.6: 0.90915,
  66.65: 0.9086,
  66.7: 0.90805,
  66.75: 0.90752,
  66.8: 0.907,
  66.85: 0.90647,
  66.9: 0.90595,
  66.95: 0.90542,
  67: 0.9049,
  67.05: 0.90437,
  67.1: 0.90385,
  67.15: 0.90332,
  67.2: 0.9028,
  67.25: 0.90227,
  67.3: 0.90175,
  67.35: 0.90125,
  67.4: 0.90075,
  67.45: 0.89995,
  67.5: 0.89995,
  67.55: 0.89867,
  67.6: 0.8982,
  67.65: 0.89775,
  67.7: 0.8973,
  67.75: 0.89682,
  67.8: 0.89635,
  67.85: 0.89587,
  67.9: 0.8954,
  67.95: 0.89495,
  68: 0.8945,
  68.05: 0.89402,
  68.1: 0.89355,
  68.15: 0.89307,
  68.2: 0.8926,
  68.25: 0.89215,
  68.3: 0.8917,
  68.35: 0.89122,
  68.4: 0.89075,
  68.45: 0.8903,
  68.5: 0.88985,
  68.55: 0.8894,
  68.6: 0.88895,
  68.65: 0.8885,
  68.7: 0.88805,
  68.75: 0.8876,
  68.8: 0.88715,
  68.85: 0.8867,
  68.9: 0.88625,
  68.95: 0.88577,
  69: 0.8853,
  69.05: 0.88485,
  69.1: 0.8844,
  69.15: 0.88395,
  69.2: 0.8835,
  69.25: 0.88307,
  69.3: 0.88265,
  69.35: 0.8822,
  69.4: 0.88175,
  69.45: 0.8813,
  69.5: 0.88085,
  69.55: 0.8804,
  69.6: 0.87995,
  69.65: 0.87952,
  69.7: 0.8791,
  69.75: 0.87865,
  69.8: 0.8782,
  69.85: 0.87775,
  69.9: 0.8773,
  69.95: 0.87687,
  70: 0.87645,
  70.05: 0.87602,
  70.1: 0.8756,
  70.15: 0.87515,
  70.2: 0.8747,
  70.25: 0.87427,
  70.3: 0.87385,
  70.35: 0.87342,
  70.4: 0.873,
  70.45: 0.87257,
  70.5: 0.87215,
  70.55: 0.8717,
  70.6: 0.87125,
  70.65: 0.87082,
  70.7: 0.8704,
  70.75: 0.86997,
  70.8: 0.86955,
  70.85: 0.86912,
  70.9: 0.8687,
  70.95: 0.86827,
  71: 0.86785,
  71.05: 0.86742,
  71.1: 0.867,
  71.15: 0.8666,
  71.2: 0.8662,
  71.25: 0.86575,
  71.3: 0.8653,
  71.35: 0.8649,
  71.4: 0.8645,
  71.45: 0.8641,
  71.5: 0.8637,
  71.55: 0.86327,
  71.6: 0.86285,
  71.65: 0.86245,
  71.7: 0.86205,
  71.75: 0.86162,
  71.8: 0.8612,
  71.85: 0.86077,
  71.9: 0.86035,
  71.95: 0.85995,
  72: 0.85955,
  72.05: 0.85912,
  72.1: 0.8587,
  72.15: 0.8583,
  72.2: 0.8579,
  72.25: 0.85752,
  72.3: 0.85715,
  72.35: 0.85672,
  72.4: 0.8563,
  72.45: 0.8559,
  72.5: 0.8555,
  72.55: 0.8551,
  72.6: 0.8547,
  72.65: 0.85427,
  72.7: 0.85385,
  72.75: 0.85345,
  72.8: 0.85305,
  72.85: 0.85267,
  72.9: 0.8523,
  72.95: 0.8519,
  73: 0.8515,
  73.05: 0.8511,
  73.1: 0.8507,
  73.15: 0.8503,
  73.2: 0.8499,
  73.25: 0.8495,
  73.3: 0.8491,
  73.35: 0.84872,
  73.4: 0.84835,
  73.45: 0.84795,
  73.5: 0.84755,
  73.55: 0.84717,
  73.6: 0.8468,
  73.65: 0.84642,
  73.7: 0.84605,
  73.75: 0.84565,
  73.8: 0.84525,
  73.85: 0.84485,
  73.9: 0.84445,
  73.95: 0.84405,
  74: 0.84365,
  74.05: 0.84327,
  74.1: 0.8429,
  74.15: 0.84252,
  74.2: 0.84215,
  74.25: 0.84177,
  74.3: 0.8414,
  74.35: 0.84102,
  74.4: 0.84065,
  74.45: 0.84027,
  74.5: 0.8399,
  74.55: 0.8395,
  74.6: 0.8391,
  74.65: 0.83872,
  74.7: 0.83835,
  74.75: 0.83797,
  74.8: 0.8376,
  74.85: 0.83722,
  74.9: 0.83685,
  74.95: 0.83647,
  75: 0.8361,
  75.05: 0.83572,
  75.1: 0.83535,
  75.15: 0.835,
  75.2: 0.83465,
  75.25: 0.8343,
  75.3: 0.83395,
  75.35: 0.83357,
  75.4: 0.8332,
  75.45: 0.83282,
  75.5: 0.83245,
  75.55: 0.83207,
  75.6: 0.8317,
  75.65: 0.83135,
  75.7: 0.831,
  75.75: 0.83062,
  75.8: 0.83025,
  75.85: 0.82987,
  75.9: 0.8295,
  75.95: 0.82917,
  76: 0.82885,
  76.05: 0.82847,
  76.1: 0.8281,
  76.15: 0.82775,
  76.2: 0.8274,
  76.25: 0.82702,
  76.3: 0.82665,
  76.35: 0.8263,
  76.4: 0.82595,
  76.45: 0.8256,
  76.5: 0.82525,
  76.55: 0.8249,
  76.6: 0.82455,
  76.65: 0.82417,
  76.7: 0.8238,
  76.75: 0.82347,
  76.8: 0.82315,
  76.85: 0.8228,
  76.9: 0.82245,
  76.95: 0.82207,
  77: 0.8217,
  77.05: 0.82137,
  77.1: 0.82105,
  77.15: 0.8207,
  77.2: 0.82035,
  77.25: 0.82,
  77.3: 0.81965,
  77.35: 0.81932,
  77.4: 0.819,
  77.45: 0.81862,
  77.5: 0.81825,
  77.55: 0.8179,
  77.6: 0.81755,
  77.65: 0.81722,
  77.7: 0.8169,
  77.75: 0.81655,
  77.8: 0.8162,
  77.85: 0.81587,
  77.9: 0.81555,
  77.95: 0.8152,
  78: 0.81485,
  78.05: 0.8145,
  78.1: 0.81415,
  78.15: 0.81382,
  78.2: 0.8135,
  78.25: 0.81317,
  78.3: 0.81285,
  78.35: 0.81252,
  78.4: 0.8122,
  78.45: 0.81155,
  78.5: 0.8115,
  78.55: 0.81117,
  78.6: 0.81085,
  78.65: 0.8105,
  78.7: 0.81015,
  78.75: 0.80985,
  78.8: 0.80955,
  78.85: 0.80922,
  78.9: 0.8089,
  78.95: 0.80855,
  79: 0.8082,
  79.05: 0.8079,
  79.1: 0.8076,
  79.15: 0.80725,
  79.2: 0.8069,
  79.25: 0.80657,
  79.3: 0.80625,
  79.35: 0.80592,
  79.4: 0.8056,
  79.45: 0.80527,
  79.5: 0.80495,
  79.55: 0.80465,
  79.6: 0.80435,
  79.65: 0.804,
  79.7: 0.80365,
  79.75: 0.80335,
  79.8: 0.80305,
  79.85: 0.80272,
  79.9: 0.8024,
  79.95: 0.80207,
  80: 0.80175,
  80.05: 0.80145,
  80.1: 0.80115,
  80.15: 0.80082,
  80.2: 0.8005,
  80.25: 0.80017,
  80.3: 0.79985,
  80.35: 0.79955,
  80.4: 0.79925,
  80.45: 0.79895,
  80.5: 0.79865,
  80.55: 0.79832,
  80.6: 0.798,
  80.65: 0.79767,
  80.7: 0.79735,
  80.75: 0.79705,
  80.8: 0.79675,
  80.85: 0.79645,
  80.9: 0.79615,
  80.95: 0.79585,
  81: 0.79555,
  81.05: 0.79525,
  81.1: 0.79495,
  81.15: 0.79462,
  81.2: 0.7943,
  81.25: 0.794,
  81.3: 0.7937,
  81.35: 0.7934,
  81.4: 0.7931,
  81.45: 0.7928,
  81.5: 0.7925,
  81.55: 0.7922,
  81.6: 0.7919,
  81.65: 0.7916,
  81.7: 0.7913,
  81.75: 0.791,
  81.8: 0.7907,
  81.85: 0.79037,
  81.9: 0.79005,
  81.95: 0.78975,
  82: 0.78945,
  82.05: 0.78917,
  82.1: 0.7889,
  82.15: 0.7886,
  82.2: 0.7883,
  82.25: 0.788,
  82.3: 0.7877,
  82.35: 0.7874,
  82.4: 0.7871,
  82.45: 0.78682,
  82.5: 0.78655,
  82.55: 0.78625,
  82.6: 0.78595,
  82.65: 0.78565,
  82.7: 0.78535,
  82.75: 0.78507,
  82.8: 0.7848,
  82.85: 0.7845,
  82.9: 0.7842,
  82.95: 0.78392,
  83: 0.78365,
  83.05: 0.78335,
  83.1: 0.78305,
  83.15: 0.78277,
  83.2: 0.7825,
  83.25: 0.7822,
  83.3: 0.7819,
  83.35: 0.78162,
  83.4: 0.78135,
  83.45: 0.78105,
  83.5: 0.78075,
  83.55: 0.78047,
  83.6: 0.7802,
  83.65: 0.7799,
  83.7: 0.7796,
  83.75: 0.77932,
  83.8: 0.77905,
  83.85: 0.7788,
  83.9: 0.77855,
  83.95: 0.77825,
  84: 0.77795,
  84.05: 0.77767,
  84.1: 0.7774,
  84.15: 0.77712,
  84.2: 0.77685,
  84.25: 0.77657,
  84.3: 0.7763,
  84.35: 0.776,
  84.4: 0.7757,
  84.45: 0.77542,
  84.5: 0.77515,
  84.55: 0.7749,
  84.6: 0.77465,
  84.65: 0.77437,
  84.7: 0.7741,
  84.75: 0.77382,
  84.8: 0.77355,
  84.85: 0.77327,
  84.9: 0.773,
  84.95: 0.77272,
  85: 0.77245,
  85.05: 0.7722,
  85.1: 0.77195,
  85.15: 0.77167,
  85.2: 0.7714,
  85.25: 0.77112,
  85.3: 0.77085,
  85.35: 0.77057,
  85.4: 0.7703,
  85.45: 0.77005,
  85.5: 0.7698,
  85.55: 0.76952,
  85.6: 0.76925,
  85.65: 0.76897,
  85.7: 0.7687,
  85.75: 0.76845,
  85.8: 0.7682,
  85.85: 0.76792,
  85.9: 0.76765,
  85.95: 0.76737,
  86: 0.7671,
  86.05: 0.76685,
  86.1: 0.7666,
  86.15: 0.76635,
  86.2: 0.7661,
  86.25: 0.76582,
  86.3: 0.76555,
  86.35: 0.7653,
  86.4: 0.76505,
  86.45: 0.76477,
  86.5: 0.7645,
  86.55: 0.76425,
  86.6: 0.764,
  86.65: 0.76375,
  86.7: 0.7635,
  86.75: 0.76322,
  86.8: 0.76295,
  86.85: 0.76272,
  86.9: 0.7625,
  86.95: 0.76222,
  87: 0.76195,
  87.05: 0.7617,
  87.1: 0.76145,
  87.15: 0.7612,
  87.2: 0.76095,
  87.25: 0.76067,
  87.3: 0.7604,
  87.35: 0.76017,
  87.4: 0.75995,
  87.45: 0.75967,
  87.5: 0.7594,
  87.55: 0.75917,
  87.6: 0.75895,
  87.65: 0.75867,
  87.7: 0.7584,
  87.75: 0.75817,
  87.8: 0.75795,
  87.85: 0.7577,
  87.9: 0.75745,
  87.95: 0.7572,
  88: 0.75695,
  88.05: 0.7567,
  88.1: 0.75645,
  88.15: 0.75622,
  88.2: 0.756,
  88.25: 0.75572,
  88.3: 0.75545,
  88.35: 0.75522,
  88.4: 0.755,
  88.45: 0.75475,
  88.5: 0.7545,
  88.55: 0.75425,
  88.6: 0.754,
  88.65: 0.75375,
  88.7: 0.7535,
  88.75: 0.75327,
  88.8: 0.75305,
  88.85: 0.75282,
  88.9: 0.7526,
  88.95: 0.75232,
  89: 0.75205,
  89.05: 0.75182,
  89.1: 0.7516,
  89.15: 0.75137,
  89.2: 0.75115,
  89.25: 0.7509,
  89.3: 0.75065,
  89.35: 0.75042,
  89.4: 0.7502,
  89.45: 0.74995,
  89.5: 0.7497,
  89.55: 0.74947,
  89.6: 0.74925,
  89.65: 0.74897,
  89.7: 0.7487,
  89.75: 0.7485,
  89.8: 0.7483,
  89.85: 0.74807,
  89.9: 0.74785,
  89.95: 0.7477,
  90: 0.74755,
  90.05: 0.74737,
  90.1: 0.7472,
  90.15: 0.747,
  90.2: 0.7468,
  90.25: 0.74662,
  90.3: 0.74645,
  90.35: 0.74625,
  90.4: 0.74605,
  90.45: 0.74587,
  90.5: 0.7457,
  90.55: 0.74552,
  90.6: 0.74535,
  90.65: 0.74515,
  90.7: 0.74495,
  90.75: 0.74477,
  90.8: 0.7446,
  90.85: 0.74445,
  90.9: 0.7443,
  90.95: 0.7441,
  91: 0.7439,
  91.05: 0.74372,
  91.1: 0.74355,
  91.15: 0.74335,
  91.2: 0.74315,
  91.25: 0.74297,
  91.3: 0.7428,
  91.35: 0.74265,
  91.4: 0.7425,
  91.45: 0.7423,
  91.5: 0.7421,
  91.55: 0.74192,
  91.6: 0.74175,
  91.65: 0.7416,
  91.7: 0.74145,
  91.75: 0.74125,
  91.8: 0.74105,
  91.85: 0.74087,
  91.9: 0.7407,
  91.95: 0.74055,
  92: 0.7404,
  92.05: 0.7402,
  92.1: 0.74,
  92.15: 0.73985,
  92.2: 0.7397,
  92.25: 0.73952,
  92.3: 0.73935,
  92.35: 0.73915,
  92.4: 0.73895,
  92.45: 0.7388,
  92.5: 0.73865,
  92.55: 0.73847,
  92.6: 0.7383,
  92.65: 0.73812,
  92.7: 0.73795,
  92.75: 0.73777,
  92.8: 0.7376,
  92.85: 0.73745,
  92.9: 0.7373,
  92.95: 0.73712,
  93: 0.73695,
  93.05: 0.73677,
  93.1: 0.7366,
  93.15: 0.73645,
  93.2: 0.7363,
  93.25: 0.73612,
  93.3: 0.73595,
  93.35: 0.73577,
  93.4: 0.7356,
  93.45: 0.73545,
  93.5: 0.7353,
  93.55: 0.73512,
  93.6: 0.73495,
  93.65: 0.73477,
  93.7: 0.7346,
  93.75: 0.73445,
  93.8: 0.7343,
  93.85: 0.73412,
  93.9: 0.73395,
  93.95: 0.7338,
  94: 0.73365,
  94.05: 0.73347,
  94.1: 0.7333,
  94.15: 0.73312,
  94.2: 0.73295,
  94.25: 0.7328,
  94.3: 0.73265,
  94.35: 0.7325,
  94.4: 0.73235,
  94.45: 0.73217,
  94.5: 0.732,
  94.55: 0.73185,
  94.6: 0.7317,
  94.65: 0.73152,
  94.7: 0.73135,
  94.75: 0.7312,
  94.8: 0.73105,
  94.85: 0.73087,
  94.9: 0.7307,
  94.95: 0.73055,
  95: 0.7304,
  95.05: 0.73025,
  95.1: 0.7301,
  95.15: 0.72995,
  95.2: 0.7298,
  95.25: 0.72962,
  95.3: 0.72945,
  95.35: 0.7293,
  95.4: 0.72915,
  95.45: 0.729,
  95.5: 0.72885,
  95.55: 0.7287,
  95.6: 0.72855,
  95.65: 0.7284,
  95.7: 0.72825,
  95.75: 0.72807,
  95.8: 0.7279,
  95.85: 0.72775,
  95.9: 0.7276,
  95.95: 0.72745,
  96: 0.7273,
  96.05: 0.72715,
  96.1: 0.727,
  96.15: 0.72685,
  96.2: 0.7267,
  96.25: 0.72652,
  96.3: 0.72635,
  96.35: 0.7262,
  96.4: 0.72605,
  96.45: 0.7259,
  96.5: 0.72575,
  96.55: 0.7256,
  96.6: 0.72545,
  96.65: 0.7253,
  96.7: 0.72515,
  96.75: 0.725,
  96.8: 0.72485,
  96.85: 0.72467,
  96.9: 0.7245,
  96.95: 0.72437,
  97: 0.72425,
  97.05: 0.7241,
  97.1: 0.72395,
  97.15: 0.7238,
  97.2: 0.72365,
  97.25: 0.7235,
  97.3: 0.72335,
  97.35: 0.7232,
  97.4: 0.72305,
  97.45: 0.7229,
  97.5: 0.72275,
  97.55: 0.7226,
  97.6: 0.72245,
  97.65: 0.7223,
  97.7: 0.72215,
  97.75: 0.722,
  97.8: 0.72185,
  97.85: 0.72172,
  97.9: 0.7216,
  97.95: 0.72145,
  98: 0.7213,
  98.05: 0.72115,
  98.1: 0.721,
  98.15: 0.72085,
  98.2: 0.7207,
  98.25: 0.72055,
  98.3: 0.7204,
  98.35: 0.72025,
  98.4: 0.7201,
  98.45: 0.71997,
  98.5: 0.71985,
  98.55: 0.7197,
  98.6: 0.71955,
  98.65: 0.71942,
  98.7: 0.7193,
  98.75: 0.71915,
  98.8: 0.719,
  98.85: 0.71885,
  98.9: 0.7187,
  98.95: 0.71857,
  99: 0.71845,
  99.05: 0.71827,
  99.1: 0.7181,
  99.15: 0.71797,
  99.2: 0.71785,
  99.25: 0.7177,
  99.3: 0.71755,
  99.35: 0.71742,
  99.4: 0.7173,
  99.45: 0.71715,
  99.5: 0.717,
  99.55: 0.71687,
  99.6: 0.71675,
  99.65: 0.7166,
  99.7: 0.71645,
  99.75: 0.71632,
  99.8: 0.7162,
  99.85: 0.71605,
  99.9: 0.7159,
  99.95: 0.71577,
  100: 0.71565,
  100.05: 0.7155,
  100.1: 0.71535,
  100.15: 0.71522,
  100.2: 0.7151,
  100.25: 0.71495,
  100.3: 0.7148,
  100.35: 0.71465,
  100.4: 0.7145,
  100.45: 0.71437,
  100.5: 0.71425,
  100.55: 0.7141,
  100.6: 0.71395,
  100.65: 0.71382,
  100.7: 0.7137,
  100.75: 0.71357,
  100.8: 0.71345,
  100.85: 0.7133,
  100.9: 0.71315,
  100.95: 0.71302,
  101: 0.7129,
  101.05: 0.71277,
  101.1: 0.71265,
  101.15: 0.7125,
  101.2: 0.71235,
  101.25: 0.71222,
  101.3: 0.7121,
  101.35: 0.71197,
  101.4: 0.71185,
  101.45: 0.71172,
  101.5: 0.7116,
  101.55: 0.71145,
  101.6: 0.7113,
  101.65: 0.71117,
  101.7: 0.71105,
  101.75: 0.71092,
  101.8: 0.7108,
  101.85: 0.71067,
  101.9: 0.71055,
  101.95: 0.7104,
  102: 0.71025,
  102.05: 0.71012,
  102.1: 0.71,
  102.15: 0.70987,
  102.2: 0.70975,
  102.25: 0.70962,
  102.3: 0.7095,
  102.35: 0.70937,
  102.4: 0.70925,
  102.45: 0.7091,
  102.5: 0.70895,
  102.55: 0.70882,
  102.6: 0.7087,
  102.65: 0.70857,
  102.7: 0.70845,
  102.75: 0.70832,
  102.8: 0.7082,
  102.85: 0.70807,
  102.9: 0.70795,
  102.95: 0.70782,
  103: 0.7077,
  103.05: 0.70757,
  103.1: 0.70745,
  103.15: 0.7073,
  103.2: 0.70715,
  103.25: 0.70702,
  103.3: 0.7069,
  103.35: 0.70677,
  103.4: 0.70665,
  103.45: 0.70652,
  103.5: 0.7064,
  103.55: 0.70627,
  103.6: 0.70615,
  103.65: 0.70602,
  103.7: 0.7059,
  103.75: 0.70577,
  103.8: 0.70565,
  103.85: 0.70552,
  103.9: 0.7054,
  103.95: 0.70527,
  104: 0.70515,
  104.05: 0.70502,
  104.1: 0.7049,
  104.15: 0.7048,
  104.2: 0.7047,
  104.25: 0.70457,
  104.3: 0.70445,
  104.35: 0.70432,
  104.4: 0.7042,
  104.45: 0.70407,
  104.5: 0.70395,
  104.55: 0.70382,
  104.6: 0.7037,
  104.65: 0.70357,
  104.7: 0.70345,
  104.75: 0.70332,
  104.8: 0.7032,
  104.85: 0.70307,
  104.9: 0.70295,
  104.95: 0.70282,
  105: 0.7027,
  105.05: 0.70257,
  105.1: 0.70245,
  105.15: 0.70235,
  105.2: 0.70225,
  105.25: 0.70212,
  105.3: 0.702,
  105.35: 0.70187,
  105.4: 0.70175,
  105.45: 0.70165,
  105.5: 0.70155,
  105.55: 0.70142,
  105.6: 0.7013,
  105.65: 0.70117,
  105.7: 0.70105,
  105.75: 0.70092,
  105.8: 0.7008,
  105.85: 0.70067,
  105.9: 0.70055,
  105.95: 0.70042,
  106: 0.7003,
  106.05: 0.7002,
  106.1: 0.7001,
  106.15: 0.69997,
  106.2: 0.69985,
  106.25: 0.69972,
  106.3: 0.6996,
  106.35: 0.6995,
  106.4: 0.6994,
  106.45: 0.69927,
  106.5: 0.69915,
  106.55: 0.69905,
  106.6: 0.69895,
  106.65: 0.69882,
  106.7: 0.6987,
  106.75: 0.69857,
  106.8: 0.69845,
  106.85: 0.69832,
  106.9: 0.6982,
  106.95: 0.69807,
  107: 0.69795,
  107.05: 0.69785,
  107.1: 0.69775,
  107.15: 0.69765,
  107.2: 0.69755,
  107.25: 0.69742,
  107.3: 0.6973,
  107.35: 0.69717,
  107.4: 0.69705,
  107.45: 0.69695,
  107.5: 0.69685,
  107.55: 0.69672,
  107.6: 0.6966,
  107.65: 0.69647,
  107.7: 0.69635,
  107.75: 0.69627,
  107.8: 0.6962,
  107.85: 0.69607,
  107.9: 0.69595,
  107.95: 0.69582,
  108: 0.6957,
  108.05: 0.69557,
  108.1: 0.69545,
  108.15: 0.69535,
  108.2: 0.69525,
  108.25: 0.69512,
  108.3: 0.695,
  108.35: 0.6949,
  108.4: 0.6948,
  108.45: 0.6947,
  108.5: 0.6946,
  108.55: 0.69447,
  108.6: 0.69435,
  108.65: 0.69425,
  108.7: 0.69415,
  108.75: 0.69402,
  108.8: 0.6939,
  108.85: 0.69377,
  108.9: 0.69365,
  108.95: 0.69357,
  109: 0.6935,
  109.05: 0.69337,
  109.1: 0.69325,
  109.15: 0.69312,
  109.2: 0.693,
  109.25: 0.6929,
  109.3: 0.6928,
  109.35: 0.69267,
  109.4: 0.69255,
  109.45: 0.69247,
  109.5: 0.6924,
  109.55: 0.69227,
  109.6: 0.69215,
  109.65: 0.69202,
  109.7: 0.6919,
  109.75: 0.6918,
  109.8: 0.6917,
  109.85: 0.6916,
  109.9: 0.6915,
  109.95: 0.69142,
  110: 0.69135,
  110.05: 0.69127,
  110.1: 0.6912,
  110.15: 0.69112,
  110.2: 0.69105,
  110.25: 0.69097,
  110.3: 0.6909,
  110.35: 0.69082,
  110.4: 0.69075,
  110.45: 0.69067,
  110.5: 0.6906,
  110.55: 0.69052,
  110.6: 0.69045,
  110.65: 0.69037,
  110.7: 0.6903,
  110.75: 0.69022,
  110.8: 0.69015,
  110.85: 0.6901,
  110.9: 0.69005,
  110.95: 0.68995,
  111: 0.68985,
  111.05: 0.6898,
  111.1: 0.68975,
  111.15: 0.68967,
  111.2: 0.6896,
  111.25: 0.68952,
  111.3: 0.68945,
  111.35: 0.68937,
  111.4: 0.6893,
  111.45: 0.68922,
  111.5: 0.68915,
  111.55: 0.68907,
  111.6: 0.689,
  111.65: 0.68895,
  111.7: 0.6889,
  111.75: 0.6888,
  111.8: 0.6887,
  111.85: 0.68865,
  111.9: 0.6886,
  111.95: 0.68855,
  112: 0.6885,
  112.05: 0.6884,
  112.1: 0.6883,
  112.15: 0.68825,
  112.2: 0.6882,
  112.25: 0.6881,
  112.3: 0.688,
  112.35: 0.68795,
  112.4: 0.6879,
  112.45: 0.68782,
  112.5: 0.68775,
  112.55: 0.68767,
  112.6: 0.6876,
  112.65: 0.68752,
  112.7: 0.68745,
  112.75: 0.6874,
  112.8: 0.68735,
  112.85: 0.68727,
  112.9: 0.6872,
  112.95: 0.68712,
  113: 0.68705,
  113.05: 0.68697,
  113.1: 0.6869,
  113.15: 0.68682,
  113.2: 0.68675,
  113.25: 0.6867,
  113.3: 0.68665,
  113.35: 0.68657,
  113.4: 0.6865,
  113.45: 0.68642,
  113.5: 0.68635,
  113.55: 0.6863,
  113.6: 0.68625,
  113.65: 0.68617,
  113.7: 0.6861,
  113.75: 0.68602,
  113.8: 0.68595,
  113.85: 0.6859,
  113.9: 0.68585,
  113.95: 0.68575,
  114: 0.68565,
  114.05: 0.6856,
  114.1: 0.68555,
  114.15: 0.68547,
  114.2: 0.6854,
  114.25: 0.68532,
  114.3: 0.68525,
  114.35: 0.6852,
  114.4: 0.68515,
  114.45: 0.68507,
  114.5: 0.685,
  114.55: 0.68492,
  114.6: 0.68485,
  114.65: 0.6848,
  114.7: 0.68475,
  114.75: 0.68467,
  114.8: 0.6846,
  114.85: 0.68452,
  114.9: 0.68445,
  114.95: 0.6844,
  115: 0.68435,
  115.05: 0.68427,
  115.1: 0.6842,
  115.15: 0.68412,
  115.2: 0.68405,
  115.25: 0.68397,
  115.3: 0.6839,
  115.35: 0.68385,
  115.4: 0.6838,
  115.45: 0.68372,
  115.5: 0.68365,
  115.55: 0.68357,
  115.6: 0.6835,
  115.65: 0.68345,
  115.7: 0.6834,
  115.75: 0.68335,
  115.8: 0.6833,
  115.85: 0.6832,
  115.9: 0.6831,
  115.95: 0.68305,
  116: 0.683,
  116.05: 0.68295,
  116.1: 0.6829,
  116.15: 0.6828,
  116.2: 0.6827,
  116.25: 0.68265,
  116.3: 0.6826,
  116.35: 0.68255,
  116.4: 0.6825,
  116.45: 0.68242,
  116.5: 0.68235,
  116.55: 0.68227,
  116.6: 0.6822,
  116.65: 0.68215,
  116.7: 0.6821,
  116.75: 0.68202,
  116.8: 0.68195,
  116.85: 0.68187,
  116.9: 0.6818,
  116.95: 0.68175,
  117: 0.6817,
  117.05: 0.68162,
  117.1: 0.68155,
  117.15: 0.6815,
  117.2: 0.68145,
  117.25: 0.68137,
  117.3: 0.6813,
  117.35: 0.68122,
  117.4: 0.68115,
  117.45: 0.6811,
  117.5: 0.68105,
  117.55: 0.681,
  117.6: 0.68095,
  117.65: 0.68085,
  117.7: 0.68075,
  117.75: 0.6807,
  117.8: 0.68065,
  117.85: 0.6806,
  117.9: 0.68055,
  117.95: 0.68047,
  118: 0.6804,
  118.05: 0.68035,
  118.1: 0.6803,
  118.15: 0.68022,
  118.2: 0.68015,
  118.25: 0.68007,
  118.3: 0.68,
  118.35: 0.67995,
  118.4: 0.6799,
  118.45: 0.67985,
  118.5: 0.6798,
  118.55: 0.6797,
  118.6: 0.6796,
  118.65: 0.67955,
  118.7: 0.6795,
  118.75: 0.67945,
  118.8: 0.6794,
  118.85: 0.67932,
  118.9: 0.67925,
  118.95: 0.6792,
  119: 0.67915,
  119.05: 0.67907,
  119.1: 0.679,
  119.15: 0.67892,
  119.2: 0.67885,
  119.25: 0.6788,
  119.3: 0.67875,
  119.35: 0.6787,
  119.4: 0.67865,
  119.45: 0.67857,
  119.5: 0.6785,
  119.55: 0.67842,
  119.6: 0.67835,
  119.65: 0.6783,
  119.7: 0.67825,
  119.75: 0.67817,
  119.8: 0.6781,
  119.85: 0.67805,
  119.9: 0.678,
  119.95: 0.67795,
  120: 0.6779,
  120.05: 0.6778,
  120.1: 0.6777,
  120.15: 0.67765,
  120.2: 0.6776,
  120.25: 0.67755,
  120.3: 0.6775,
  120.35: 0.67742,
  120.4: 0.67735,
  120.45: 0.6773,
  120.5: 0.67725,
  120.55: 0.67717,
  120.6: 0.6771,
  120.65: 0.67702,
  120.7: 0.67695,
  120.75: 0.6769,
  120.8: 0.67685,
  120.85: 0.6768,
  120.9: 0.67675,
  120.95: 0.67667,
  121: 0.6766,
  121.05: 0.67655,
  121.1: 0.6765,
  121.15: 0.67642,
  121.2: 0.67635,
  121.25: 0.67627,
  121.3: 0.6762,
  121.35: 0.67615,
  121.4: 0.6761,
  121.45: 0.67605,
  121.5: 0.676,
  121.55: 0.67592,
  121.6: 0.67585,
  121.65: 0.6758,
  121.7: 0.67575,
  121.75: 0.67567,
  121.8: 0.6756,
  121.85: 0.67555,
  121.9: 0.6755,
  121.95: 0.67542,
  122: 0.67535,
  122.05: 0.6753,
  122.1: 0.67525,
  122.15: 0.6752,
  122.2: 0.67515,
  122.25: 0.67507,
  122.3: 0.675,
  122.35: 0.67495,
  122.4: 0.6749,
  122.45: 0.67482,
  122.5: 0.67475,
  122.55: 0.67467,
  122.6: 0.6746,
  122.65: 0.67455,
  122.7: 0.6745,
  122.75: 0.67445,
  122.8: 0.6744,
  122.85: 0.67432,
  122.9: 0.67425,
  122.95: 0.6742,
  123: 0.67415,
  123.05: 0.67407,
  123.1: 0.674,
  123.15: 0.67395,
  123.2: 0.6739,
  123.25: 0.67382,
  123.3: 0.67375,
  123.35: 0.6737,
  123.4: 0.67365,
  123.45: 0.6736,
  123.5: 0.67355,
  123.55: 0.67347,
  123.6: 0.6734,
  123.65: 0.67335,
  123.7: 0.6733,
  123.75: 0.67325,
  123.8: 0.6732,
  123.85: 0.6731,
  123.9: 0.673,
  123.95: 0.67295,
  124: 0.6729,
  124.05: 0.67285,
  124.1: 0.6728,
  124.15: 0.67275,
  124.2: 0.6727,
  124.25: 0.67262,
  124.3: 0.67255,
  124.35: 0.6725,
  124.4: 0.67245,
  124.45: 0.6724,
  124.5: 0.67235,
  124.55: 0.67225,
  124.6: 0.67215,
  124.65: 0.6721,
  124.7: 0.67205,
  124.75: 0.672,
  124.8: 0.67195,
  124.85: 0.67187,
  124.9: 0.6718,
  124.95: 0.67175,
  125: 0.6717,
  125.05: 0.67165,
  125.1: 0.6716,
  125.15: 0.67155,
  125.2: 0.6715,
  125.25: 0.67142,
  125.3: 0.67135,
  125.35: 0.67127,
  125.4: 0.6712,
  125.45: 0.67115,
  125.5: 0.6711,
  125.55: 0.67102,
  125.6: 0.67095,
  125.65: 0.6709,
  125.7: 0.67085,
  125.75: 0.6708,
  125.8: 0.67075,
  125.85: 0.6707,
  125.9: 0.67065,
  125.95: 0.67057,
  126: 0.6705,
  126.05: 0.67045,
  126.1: 0.6704,
  126.15: 0.67035,
  126.2: 0.6703,
  126.25: 0.6702,
  126.3: 0.6701,
  126.35: 0.67005,
  126.4: 0.67,
  126.45: 0.66995,
  126.5: 0.6699,
  126.55: 0.66985,
  126.6: 0.6698,
  126.65: 0.66972,
  126.7: 0.66965,
  126.75: 0.6696,
  126.8: 0.66955,
  126.85: 0.6695,
  126.9: 0.66945,
  126.95: 0.66937,
  127: 0.6693,
  127.05: 0.66922,
  127.1: 0.66915,
  127.15: 0.6691,
  127.2: 0.66905,
  127.25: 0.669,
  127.3: 0.66895,
  127.35: 0.66887,
  127.4: 0.6688,
  127.45: 0.66875,
  127.5: 0.6687,
  127.55: 0.66865,
  127.6: 0.6686,
  127.65: 0.66852,
  127.7: 0.66845,
  127.75: 0.6684,
  127.8: 0.66835,
  127.85: 0.6683,
  127.9: 0.66825,
  127.95: 0.66817,
  128: 0.6681,
  128.05: 0.66802,
  128.1: 0.66795,
  128.15: 0.6679,
  128.2: 0.66785,
  128.25: 0.6678,
  128.3: 0.66775,
  128.35: 0.6677,
  128.4: 0.66765,
  128.45: 0.66757,
  128.5: 0.6675,
  128.55: 0.66745,
  128.6: 0.6674,
  128.65: 0.66735,
  128.7: 0.6673,
  128.75: 0.66722,
  128.8: 0.66715,
  128.85: 0.66707,
  128.9: 0.667,
  128.95: 0.66695,
  129: 0.6669,
  129.05: 0.66685,
  129.1: 0.6668,
  129.15: 0.66672,
  129.2: 0.66665,
  129.25: 0.6666,
  129.3: 0.66655,
  129.35: 0.6665,
  129.4: 0.66645,
  129.45: 0.6664,
  129.5: 0.66635,
  129.55: 0.66627,
  129.6: 0.6662,
  129.65: 0.66615,
  129.7: 0.6661,
  129.75: 0.66605,
  129.8: 0.666,
  129.85: 0.66592,
  129.9: 0.66585,
  129.95: 0.66577,
  130: 0.6657,
  130.05: 0.66565,
  130.1: 0.6656,
  130.15: 0.66555,
  130.2: 0.6655,
  130.25: 0.66545,
  130.3: 0.6654,
  130.35: 0.66532,
  130.4: 0.66525,
  130.45: 0.6652,
  130.5: 0.66515,
  130.55: 0.6651,
  130.6: 0.66505,
  130.65: 0.66497,
  130.7: 0.6649,
  130.75: 0.66485,
  130.8: 0.6648,
  130.85: 0.66472,
  130.9: 0.66465,
  130.95: 0.6646,
  131: 0.66455,
  131.05: 0.66447,
  131.1: 0.6644,
  131.15: 0.66435,
  131.2: 0.6643,
  131.25: 0.66425,
  131.3: 0.6642,
  131.35: 0.66415,
  131.4: 0.6641,
  131.45: 0.66402,
  131.5: 0.66395,
  131.55: 0.6639,
  131.6: 0.66385,
  131.65: 0.6638,
  131.7: 0.66375,
  131.75: 0.6637,
  131.8: 0.66365,
  131.85: 0.66355,
  131.9: 0.66345,
  131.95: 0.6634,
  132: 0.66335,
  132.05: 0.6633,
  132.1: 0.66325,
  132.15: 0.6632,
  132.2: 0.66315,
  132.25: 0.66307,
  132.3: 0.663,
  132.35: 0.66295,
  132.4: 0.6629,
  132.45: 0.66285,
  132.5: 0.6628,
  132.55: 0.66275,
  132.6: 0.6627,
  132.65: 0.66262,
  132.7: 0.66255,
  132.75: 0.6625,
  132.8: 0.66245,
  132.85: 0.6624,
  132.9: 0.66235,
  132.95: 0.66227,
  133: 0.6622,
  133.05: 0.66212,
  133.1: 0.66205,
  133.15: 0.662,
  133.2: 0.66195,
  133.25: 0.6619,
  133.3: 0.68185,
  133.35: 0.6618,
  133.4: 0.66175,
  133.45: 0.66167,
  133.5: 0.6616,
  133.55: 0.66155,
  133.6: 0.6615,
  133.65: 0.66145,
  133.7: 0.6614,
  133.75: 0.66135,
  133.8: 0.6613,
  133.85: 0.66122,
  133.9: 0.66115,
  133.95: 0.6611,
  134: 0.66105,
  134.05: 0.66097,
  134.1: 0.6609,
  134.15: 0.66085,
  134.2: 0.6608,
  134.25: 0.66075,
  134.3: 0.6607,
  134.35: 0.66062,
  134.4: 0.66055,
  134.45: 0.6605,
  134.5: 0.66045,
  134.55: 0.6604,
  134.6: 0.66035,
  134.65: 0.6603,
  134.7: 0.66025,
  134.75: 0.66017,
  134.8: 0.6601,
  134.85: 0.66005,
  134.9: 0.66,
  134.95: 0.65995,
  135: 0.6599,
  135.05: 0.65985,
  135.1: 0.6598,
  135.15: 0.65972,
  135.2: 0.65965,
  135.25: 0.65957,
  135.3: 0.6595,
  135.35: 0.65945,
  135.4: 0.6594,
  135.45: 0.65935,
  135.5: 0.6593,
  135.55: 0.65922,
  135.6: 0.65915,
  135.65: 0.6591,
  135.7: 0.65905,
  135.75: 0.659,
  135.8: 0.65895,
  135.85: 0.6589,
  135.9: 0.65885,
  135.95: 0.6588,
  136: 0.65875,
  136.05: 0.65867,
  136.1: 0.6586,
  136.15: 0.65855,
  136.2: 0.6585,
  136.25: 0.65845,
  136.3: 0.6584,
  136.35: 0.65835,
  136.4: 0.6583,
  136.45: 0.65822,
  136.5: 0.65815,
  136.55: 0.6581,
  136.6: 0.65805,
  136.65: 0.658,
  136.7: 0.65795,
  136.75: 0.65787,
  136.8: 0.6578,
  136.85: 0.65775,
  136.9: 0.6577,
  136.95: 0.65762,
  137: 0.65755,
  137.05: 0.6575,
  137.1: 0.65745,
  137.15: 0.6574,
  137.2: 0.65735,
  137.25: 0.6573,
  137.3: 0.65725,
  137.35: 0.65717,
  137.4: 0.6571,
  137.45: 0.65705,
  137.5: 0.657,
  137.55: 0.65965,
  137.6: 0.6569,
  137.65: 0.65685,
  137.7: 0.6568,
  137.75: 0.65675,
  137.8: 0.6567,
  137.85: 0.65662,
  137.9: 0.65655,
  137.95: 0.6565,
  138: 0.65645,
  138.05: 0.6564,
  138.1: 0.65635,
  138.15: 0.6563,
  138.2: 0.65625,
  138.25: 0.65617,
  138.3: 0.6561,
  138.35: 0.65605,
  138.4: 0.656,
  138.45: 0.65595,
  138.5: 0.6559,
  138.55: 0.65585,
  138.6: 0.6558,
  138.65: 0.65575,
  138.7: 0.6557,
  138.75: 0.65562,
  138.8: 0.65555,
  138.85: 0.65547,
  138.9: 0.6554,
  138.95: 0.65535,
  139: 0.6553,
  139.05: 0.65525,
  139.1: 0.6552,
  139.15: 0.65515,
  139.2: 0.6551,
  139.25: 0.65502,
  139.3: 0.65495,
  139.35: 0.6549,
  139.4: 0.65485,
  139.45: 0.6548,
  139.5: 0.65475,
  139.55: 0.6547,
  139.6: 0.65465,
  139.65: 0.6546,
  139.7: 0.65455,
  139.75: 0.65447,
  139.8: 0.6544,
  139.85: 0.65435,
  139.9: 0.6543,
  139.95: 0.65425,
  140: 0.6542,
  140.05: 0.65417,
  140.1: 0.65414,
  140.15: 0.65409,
  140.2: 0.65404,
  140.25: 0.65398,
  140.3: 0.65393,
  140.35: 0.65388,
  140.4: 0.65383,
  140.45: 0.65377,
  140.5: 0.65372,
  140.55: 0.65367,
  140.6: 0.65362,
  140.65: 0.65356,
  140.7: 0.65351,
  140.75: 0.65346,
  140.8: 0.65341,
  140.85: 0.65335,
  140.9: 0.6533,
  140.95: 0.65325,
  141: 0.6532,
  141.05: 0.65314,
  141.1: 0.65309,
  141.15: 0.65304,
  141.2: 0.65299,
  141.25: 0.65293,
  141.3: 0.65288,
  141.35: 0.65283,
  141.4: 0.65278,
  141.45: 0.65272,
  141.5: 0.65267,
  141.55: 0.65262,
  141.6: 0.65257,
  141.65: 0.65251,
  141.7: 0.65246,
  141.75: 0.65243,
  141.8: 0.65241,
  141.85: 0.65235,
  141.9: 0.6523,
  141.95: 0.65225,
  142: 0.6522,
  142.05: 0.65214,
  142.1: 0.65209,
  142.15: 0.65204,
  142.2: 0.65199,
  142.25: 0.65193,
  142.3: 0.65188,
  142.35: 0.65183,
  142.4: 0.65179,
  142.45: 0.65173,
  142.5: 0.65168,
  142.55: 0.65163,
  142.6: 0.65158,
  142.65: 0.65152,
  142.7: 0.65147,
  142.75: 0.65142,
  142.8: 0.65137,
  142.85: 0.65131,
  142.9: 0.65126,
  142.95: 0.65121,
  143: 0.65116,
  143.05: 0.6511,
  143.1: 0.65105,
  143.15: 0.651,
  143.2: 0.65095,
  143.25: 0.65089,
  143.3: 0.65084,
  143.35: 0.65081,
  143.4: 0.65076,
  143.45: 0.65071,
  143.5: 0.65068,
  143.55: 0.65063,
  143.6: 0.65058,
  143.65: 0.65053,
  143.7: 0.65047,
  143.75: 0.65042,
  143.8: 0.65037,
  143.85: 0.65031,
  143.9: 0.65026,
  143.95: 0.65021,
  144: 0.65016,
  144.05: 0.6501,
  144.1: 0.65005,
  144.15: 0.65,
  144.2: 0.64995,
  144.25: 0.64992,
  144.3: 0.64989,
  144.35: 0.64984,
  144.4: 0.64979,
  144.45: 0.64973,
  144.5: 0.64968,
  144.55: 0.64963,
  144.6: 0.64958,
  144.65: 0.64952,
  144.7: 0.64947,
  144.75: 0.64942,
  144.8: 0.64937,
  144.85: 0.64931,
  144.9: 0.64926,
  144.95: 0.64923,
  145: 0.64921,
  145.05: 0.64916,
  145.1: 0.64911,
  145.15: 0.64906,
  145.2: 0.64901,
  145.25: 0.64896,
  145.3: 0.64891,
  145.35: 0.64886,
  145.4: 0.64881,
  145.45: 0.64876,
  145.5: 0.64871,
  145.55: 0.64868,
  145.6: 0.64866,
  145.65: 0.64861,
  145.7: 0.64856,
  145.75: 0.64851,
  145.8: 0.64846,
  145.85: 0.64841,
  145.9: 0.64826,
  145.95: 0.64831,
  146: 0.64826,
  146.05: 0.64823,
  146.1: 0.64821,
  146.15: 0.64816,
  146.2: 0.64811,
  146.25: 0.64806,
  146.3: 0.64801,
  146.35: 0.64796,
  146.4: 0.64791,
  146.45: 0.64786,
  146.5: 0.64781,
  146.55: 0.64778,
  146.6: 0.64776,
  146.65: 0.64771,
  146.7: 0.64766,
  146.75: 0.64761,
  146.8: 0.64756,
  146.85: 0.64751,
  146.9: 0.64746,
  146.95: 0.64743,
  147: 0.64741,
  147.05: 0.64736,
  147.1: 0.64731,
  147.15: 0.64726,
  147.2: 0.64721,
  147.25: 0.64718,
  147.3: 0.64716,
  147.35: 0.64711,
  147.4: 0.64706,
  147.45: 0.64701,
  147.5: 0.64696,
  147.55: 0.64691,
  147.6: 0.64686,
  147.65: 0.64683,
  147.7: 0.64681,
  147.75: 0.64676,
  147.8: 0.64671,
  147.85: 0.64666,
  147.9: 0.64661,
  147.95: 0.64658,
  148: 0.64656,
  148.05: 0.64651,
  148.1: 0.64646,
  148.15: 0.64641,
  148.2: 0.64636,
  148.25: 0.64633,
  148.3: 0.64631,
  148.35: 0.64626,
  148.4: 0.64621,
  148.45: 0.64616,
  148.5: 0.64611,
  148.55: 0.64608,
  148.6: 0.64606,
  148.65: 0.64601,
  148.7: 0.64596,
  148.75: 0.64593,
  148.8: 0.64591,
  148.85: 0.64586,
  148.9: 0.64581,
  148.95: 0.64576,
  149: 0.64571,
  149.05: 0.64568,
  149.1: 0.64566,
  149.15: 0.64561,
  149.2: 0.64556,
  149.25: 0.64553,
  149.3: 0.64551,
  149.35: 0.64546,
  149.4: 0.64541,
  149.45: 0.64538,
  149.5: 0.64586,
  149.55: 0.64531,
  149.6: 0.64526,
  149.65: 0.64521,
  149.7: 0.64516,
  149.75: 0.64513,
  149.8: 0.64511,
  149.85: 0.64506,
  149.9: 0.64501,
  149.95: 0.64498,
  150: 0.64496,
  150.05: 0.64491,
  150.1: 0.64486,
  150.15: 0.64483,
  150.2: 0.64481,
  150.25: 0.64476,
  150.3: 0.64471,
  150.35: 0.64468,
  150.4: 0.64466,
  150.45: 0.64461,
  150.5: 0.64456,
  150.55: 0.64453,
  150.6: 0.64451,
  150.65: 0.64446,
  150.7: 0.64441,
  150.75: 0.64438,
  150.8: 0.64436,
  150.85: 0.64433,
  150.9: 0.64431,
  150.95: 0.64426,
  151: 0.64421,
  151.05: 0.64417,
  151.1: 0.64413,
  151.15: 0.64409,
  151.2: 0.64406,
  151.25: 0.64402,
  151.3: 0.64398,
  151.35: 0.64394,
  151.4: 0.64391,
  151.45: 0.64387,
  151.5: 0.64383,
  151.55: 0.64379,
  151.6: 0.64376,
  151.65: 0.64372,
  151.7: 0.64368,
  151.75: 0.64364,
  151.8: 0.64361,
  151.85: 0.64357,
  151.9: 0.64353,
  151.95: 0.64349,
  152: 0.64346,
  152.05: 0.64342,
  152.1: 0.64338,
  152.15: 0.64334,
  152.2: 0.64331,
  152.25: 0.64327,
  152.3: 0.64323,
  152.35: 0.64319,
  152.4: 0.64316,
  152.45: 0.64312,
  152.5: 0.64308,
  152.55: 0.64304,
  152.6: 0.64301,
  152.65: 0.64297,
  152.7: 0.64293,
  152.75: 0.64289,
  152.8: 0.64286,
  152.85: 0.64282,
  152.9: 0.64278,
  152.95: 0.64274,
  153: 0.64271,
  153.05: 0.64267,
  153.1: 0.64263,
  153.15: 0.64259,
  153.2: 0.64256,
  153.25: 0.64252,
  153.3: 0.64248,
  153.35: 0.64244,
  153.4: 0.64241,
  153.45: 0.64237,
  153.5: 0.64233,
  153.55: 0.64229,
  153.6: 0.64226,
  153.65: 0.64222,
  153.7: 0.64218,
  153.75: 0.64214,
  153.8: 0.64211,
  153.85: 0.64207,
  153.9: 0.64203,
  153.95: 0.64199,
  154: 0.64196,
  154.05: 0.64192,
  154.1: 0.64188,
  154.15: 0.64184,
  154.2: 0.64181,
  154.25: 0.64177,
  154.3: 0.64173,
  154.35: 0.64169,
  154.4: 0.64166,
  154.45: 0.64162,
  154.5: 0.64158,
  154.55: 0.64154,
  154.6: 0.64151,
  154.65: 0.64147,
  154.7: 0.64143,
  154.75: 0.64139,
  154.8: 0.64136,
  154.85: 0.64132,
  154.9: 0.64128,
  154.95: 0.64124,
  155: 0.64121,
  155.05: 0.64117,
  155.1: 0.64114,
  155.15: 0.6411,
  155.2: 0.64107,
  155.25: 0.64103,
  155.3: 0.641,
  155.35: 0.64096,
  155.4: 0.64093,
  155.45: 0.64089,
  155.5: 0.64086,
  155.55: 0.64082,
  155.6: 0.64079,
  155.65: 0.64076,
  155.7: 0.64071,
  155.75: 0.64068,
  155.8: 0.64065,
  155.85: 0.64061,
  155.9: 0.64058,
  155.95: 0.64054,
  156: 0.64051,
  156.05: 0.64047,
  156.1: 0.64044,
  156.15: 0.6404,
  156.2: 0.64037,
  156.25: 0.64033,
  156.3: 0.6403,
  156.35: 0.64026,
  156.4: 0.64023,
  156.45: 0.64019,
  156.5: 0.64016,
  156.55: 0.64012,
  156.6: 0.64009,
  156.65: 0.64005,
  156.7: 0.64002,
  156.75: 0.63998,
  156.8: 0.63995,
  156.85: 0.63991,
  156.9: 0.63988,
  156.95: 0.63984,
  157: 0.63981,
  157.05: 0.63977,
  157.1: 0.63974,
  157.15: 0.6397,
  157.2: 0.63967,
  157.25: 0.63963,
  157.3: 0.6396,
  157.35: 0.63956,
  157.4: 0.63953,
  157.45: 0.63949,
  157.5: 0.63946,
  157.55: 0.63942,
  157.6: 0.63939,
  157.65: 0.63935,
  157.7: 0.63932,
  157.75: 0.63928,
  157.8: 0.63925,
  157.85: 0.63921,
  157.9: 0.63918,
  157.95: 0.63914,
  158: 0.63911,
  158.05: 0.63907,
  158.1: 0.63904,
  158.15: 0.639,
  158.2: 0.63897,
  158.25: 0.63893,
  158.3: 0.6389,
  158.35: 0.63886,
  158.4: 0.63883,
  158.45: 0.63879,
  158.5: 0.63876,
  158.55: 0.63872,
  158.6: 0.63869,
  158.65: 0.63865,
  158.7: 0.63862,
  158.75: 0.63858,
  158.8: 0.63855,
  158.85: 0.63851,
  158.9: 0.63848,
  158.95: 0.63844,
  159: 0.63841,
  159.05: 0.63837,
  159.1: 0.63834,
  159.15: 0.6383,
  159.2: 0.63827,
  159.25: 0.63823,
  159.3: 0.6382,
  159.35: 0.63816,
  159.4: 0.63813,
  159.45: 0.63809,
  159.5: 0.63806,
  159.55: 0.63802,
  159.6: 0.63799,
  159.65: 0.63795,
  159.7: 0.63792,
  159.75: 0.63788,
  159.8: 0.63785,
  159.85: 0.63781,
  159.9: 0.63778,
  159.95: 0.63774,
  160: 0.63771,
  160.05: 0.63767,
  160.1: 0.63764,
  160.15: 0.6376,
  160.2: 0.63757,
  160.25: 0.63753,
  160.3: 0.6375,
  160.35: 0.63746,
  160.4: 0.63743,
  160.45: 0.63739,
  160.5: 0.63736,
  160.55: 0.63732,
  160.6: 0.63729,
  160.65: 0.63725,
  160.7: 0.63722,
  160.75: 0.63718,
  160.8: 0.63715,
  160.85: 0.63711,
  160.9: 0.63708,
  160.95: 0.63704,
  161: 0.63701,
  161.05: 0.63697,
  161.1: 0.63694,
  161.15: 0.6369,
  161.2: 0.63687,
  161.25: 0.63683,
  161.3: 0.6368,
  161.35: 0.63676,
  161.4: 0.63673,
  161.45: 0.63669,
  161.5: 0.63666,
  161.55: 0.63662,
  161.6: 0.63659,
  161.65: 0.63655,
  161.7: 0.63652,
  161.75: 0.63648,
  161.8: 0.63645,
  161.85: 0.6364,
  161.9: 0.63635,
  161.95: 0.63632,
  162: 0.63628,
  162.05: 0.63625,
  162.1: 0.63621,
  162.15: 0.63618,
  162.2: 0.63614,
  162.25: 0.63611,
  162.3: 0.63607,
  162.35: 0.63604,
  162.4: 0.636,
  162.45: 0.63597,
  162.5: 0.63593,
  162.55: 0.6359,
  162.6: 0.63586,
  162.65: 0.63583,
  162.7: 0.63579,
  162.75: 0.63576,
  162.8: 0.63572,
  162.85: 0.63569,
  162.9: 0.63565,
  162.95: 0.63562,
  163: 0.63558,
  163.05: 0.63555,
  163.1: 0.63551,
  163.15: 0.63548,
  163.2: 0.63544,
  163.25: 0.63541,
  163.3: 0.63537,
  163.35: 0.63534,
  163.4: 0.6353,
  163.45: 0.63527,
  163.5: 0.63523,
  163.55: 0.6352,
  163.6: 0.63516,
  163.65: 0.63513,
  163.7: 0.63509,
  163.75: 0.63506,
  163.8: 0.63502,
  163.85: 0.63499,
  163.9: 0.63495,
  163.95: 0.63492,
  164: 0.63488,
  164.05: 0.63485,
  164.1: 0.63481,
  164.15: 0.63478,
  164.2: 0.63474,
  164.25: 0.63471,
  164.3: 0.63467,
  164.35: 0.63464,
  164.4: 0.6346,
  164.45: 0.63457,
  164.5: 0.63453,
  164.55: 0.6345,
  164.6: 0.63446,
  164.65: 0.63443,
  164.7: 0.63439,
  164.75: 0.63436,
  164.8: 0.63432,
  164.85: 0.63429,
  164.9: 0.63425,
  164.95: 0.63422,
  165: 0.63419,
  165.05: 0.63415,
  165.1: 0.63412,
  165.15: 0.63409,
  165.2: 0.63406,
  165.25: 0.63402,
  165.3: 0.63399,
  165.35: 0.63396,
  165.4: 0.63393,
  165.45: 0.63389,
  165.5: 0.63386,
  165.55: 0.63383,
  165.6: 0.6338,
  165.65: 0.63376,
  165.7: 0.63373,
  165.75: 0.6337,
  165.8: 0.63367,
  165.85: 0.63363,
  165.9: 0.6336,
  165.95: 0.63357,
  166: 0.63354,
  166.05: 0.6335,
  166.1: 0.63347,
  166.15: 0.63344,
  166.2: 0.63341,
  166.25: 0.63337,
  166.3: 0.63334,
  166.35: 0.63331,
  166.4: 0.63328,
  166.45: 0.63324,
  166.5: 0.63321,
  166.55: 0.63318,
  166.6: 0.63315,
  166.65: 0.63311,
  166.7: 0.63308,
  166.75: 0.63305,
  166.8: 0.63302,
  166.85: 0.63298,
  166.9: 0.63295,
  166.95: 0.63292,
  167: 0.63289,
  167.05: 0.63285,
  167.1: 0.63282,
  167.15: 0.63279,
  167.2: 0.63276,
  167.25: 0.63272,
  167.3: 0.63269,
  167.35: 0.63266,
  167.4: 0.63263,
  167.45: 0.63259,
  167.5: 0.63256,
  167.55: 0.63253,
  167.6: 0.6325,
  167.65: 0.63246,
  167.7: 0.63243,
  167.75: 0.6324,
  167.8: 0.63237,
  167.85: 0.63233,
  167.9: 0.6323,
  167.95: 0.63227,
  168: 0.63224,
  168.05: 0.6322,
  168.1: 0.63217,
  168.15: 0.63214,
  168.2: 0.63211,
  168.25: 0.63207,
  168.3: 0.63204,
  168.35: 0.63201,
  168.4: 0.63198,
  168.45: 0.63195,
  168.5: 0.63192,
  168.55: 0.63188,
  168.6: 0.63185,
  168.65: 0.63182,
  168.7: 0.63179,
  168.75: 0.63175,
  168.8: 0.63172,
  168.85: 0.63169,
  168.9: 0.63166,
  168.95: 0.63162,
  169: 0.63159,
  169.05: 0.63156,
  169.1: 0.63153,
  169.15: 0.63149,
  169.2: 0.63146,
  169.25: 0.63143,
  169.3: 0.6314,
  169.35: 0.63136,
  169.4: 0.63133,
  169.45: 0.6313,
  169.5: 0.63127,
  169.55: 0.63123,
  169.6: 0.6312,
  169.65: 0.63117,
  169.7: 0.63114,
  169.75: 0.6311,
  169.8: 0.63107,
  169.9: 0.63101,
  169.85: 0.63104,
  169.95: 0.63097,
  170: 0.63094,
};

export const menGlossCoef = {
  40: 1.32435,
  40.05: 1.32225,
  40.1: 1.32015,
  40.15: 1.31807,
  40.2: 1.316,
  40.25: 1.3139,
  40.3: 1.3118,
  40.35: 1.30972,
  40.4: 1.30765,
  40.45: 1.3056,
  40.5: 1.30355,
  40.55: 1.3015,
  40.6: 1.29945,
  40.65: 1.29742,
  40.7: 1.2954,
  40.75: 1.2934,
  40.8: 1.2914,
  40.85: 1.2894,
  40.9: 1.2874,
  40.95: 1.2854,
  41: 1.2834,
  41.05: 1.28142,
  41.1: 1.27945,
  41.15: 1.27747,
  41.2: 1.2755,
  41.25: 1.27355,
  41.3: 1.2716,
  41.35: 1.26967,
  41.4: 1.26775,
  41.45: 1.26582,
  41.5: 1.2639,
  41.55: 1.26197,
  41.6: 1.26005,
  41.65: 1.25815,
  41.7: 1.25625,
  41.75: 1.25435,
  41.8: 1.25245,
  41.85: 1.25057,
  41.9: 1.2487,
  41.95: 1.24685,
  42: 1.245,
  42.05: 1.24312,
  42.1: 1.24125,
  42.15: 1.23942,
  42.2: 1.2376,
  42.25: 1.23575,
  42.3: 1.2339,
  42.35: 1.2321,
  42.4: 1.2303,
  42.45: 1.22847,
  42.5: 1.22665,
  42.55: 1.22487,
  42.6: 1.2231,
  42.65: 1.22132,
  42.7: 1.21955,
  42.75: 1.21777,
  42.8: 1.216,
  42.85: 1.21425,
  42.9: 1.2125,
  42.95: 1.21075,
  43: 1.209,
  43.05: 1.20725,
  43.1: 1.2055,
  43.15: 1.20375,
  43.2: 1.202,
  43.25: 1.2003,
  43.3: 1.1986,
  43.35: 1.19687,
  43.4: 1.19515,
  43.45: 1.19345,
  43.5: 1.19175,
  43.55: 1.1901,
  43.6: 1.18845,
  43.65: 1.18675,
  43.7: 1.18505,
  43.75: 1.18337,
  43.8: 1.1817,
  43.85: 1.18005,
  43.9: 1.1784,
  43.95: 1.17677,
  44: 1.17515,
  44.05: 1.1735,
  44.1: 1.17185,
  44.15: 1.17022,
  44.2: 1.1686,
  44.25: 1.16697,
  44.3: 1.16535,
  44.35: 1.16375,
  44.4: 1.16215,
  44.45: 1.16057,
  44.5: 1.159,
  44.55: 1.1574,
  44.6: 1.1558,
  44.65: 1.15422,
  44.7: 1.15265,
  44.75: 1.15107,
  44.8: 1.1495,
  44.85: 1.14797,
  44.9: 1.14645,
  44.95: 1.14487,
  45: 1.1433,
  45.05: 1.14177,
  45.1: 1.14025,
  45.15: 1.13872,
  45.2: 1.1372,
  45.25: 1.13567,
  45.3: 1.13415,
  45.35: 1.13262,
  45.4: 1.1311,
  45.45: 1.12962,
  45.5: 1.12815,
  45.55: 1.12662,
  45.6: 1.1251,
  45.65: 1.12362,
  45.7: 1.12215,
  45.75: 1.12067,
  45.8: 1.1192,
  45.85: 1.11775,
  45.9: 1.1163,
  45.95: 1.11485,
  46: 1.1134,
  46.05: 1.11195,
  46.1: 1.1105,
  46.15: 1.10907,
  46.2: 1.10765,
  46.25: 1.1062,
  46.3: 1.10475,
  46.35: 1.10332,
  46.4: 1.1019,
  46.45: 1.10047,
  46.5: 1.09905,
  46.55: 1.09767,
  46.6: 1.0963,
  46.65: 1.09487,
  46.7: 1.09345,
  46.75: 1.09207,
  46.8: 1.0907,
  46.85: 1.0893,
  46.9: 1.0879,
  46.95: 1.08655,
  47: 1.0852,
  47.05: 1.08382,
  47.1: 1.08245,
  47.15: 1.0811,
  47.2: 1.07975,
  47.25: 1.0784,
  47.3: 1.07705,
  47.35: 1.0757,
  47.4: 1.07435,
  47.45: 1.07302,
  47.5: 1.0717,
  47.55: 1.0704,
  47.6: 1.0691,
  47.65: 1.06777,
  47.7: 1.06645,
  47.75: 1.06515,
  47.8: 1.06385,
  47.85: 1.06252,
  47.9: 1.0612,
  47.95: 1.0599,
  48: 1.0586,
  48.05: 1.05732,
  48.1: 1.05605,
  48.15: 1.05475,
  48.2: 1.05345,
  48.25: 1.0522,
  48.3: 1.05095,
  48.35: 1.04967,
  48.4: 1.0484,
  48.45: 1.04715,
  48.5: 1.0459,
  48.55: 1.04465,
  48.6: 1.0434,
  48.65: 1.04217,
  48.7: 1.04095,
  48.75: 1.0397,
  48.8: 1.03845,
  48.85: 1.03722,
  48.9: 1.036,
  48.95: 1.03477,
  49: 1.03355,
  49.05: 1.03235,
  49.1: 1.03115,
  49.15: 1.02992,
  49.2: 1.0287,
  49.25: 1.0275,
  49.3: 1.0263,
  49.35: 1.0251,
  49.4: 1.0239,
  49.45: 1.02272,
  49.5: 1.02155,
  49.55: 1.02035,
  49.6: 1.01915,
  49.65: 1.018,
  49.7: 1.01685,
  49.75: 1.01567,
  49.8: 1.0145,
  49.85: 1.01335,
  49.9: 1.0122,
  49.95: 1.01105,
  50: 1.0099,
  50.05: 1.00875,
  50.1: 1.0076,
  50.15: 1.00647,
  50.2: 1.00535,
  50.25: 1.0042,
  50.3: 1.00305,
  50.35: 1.00192,
  50.4: 1.0008,
  50.45: 0.99967,
  50.5: 0.99855,
  50.55: 0.99745,
  50.6: 0.99635,
  50.65: 0.99522,
  50.7: 0.9941,
  50.75: 0.993,
  50.8: 0.9919,
  50.85: 0.9908,
  50.9: 0.9897,
  50.95: 0.9886,
  51: 0.9875,
  51.05: 0.98642,
  51.1: 0.98535,
  51.15: 0.9843,
  51.2: 0.98325,
  51.25: 0.98215,
  51.3: 0.98105,
  51.35: 0.97997,
  51.4: 0.9789,
  51.45: 0.97785,
  51.5: 0.9768,
  51.55: 0.97575,
  51.6: 0.9747,
  51.65: 0.97367,
  51.7: 0.97265,
  51.75: 0.9716,
  51.8: 0.97055,
  51.85: 0.9695,
  51.9: 0.96845,
  51.95: 0.96742,
  52: 0.9664,
  52.05: 0.96537,
  52.1: 0.96435,
  52.15: 0.96332,
  52.2: 0.9623,
  52.25: 0.9613,
  52.3: 0.9603,
  52.35: 0.9593,
  52.4: 0.9583,
  52.45: 0.95727,
  52.5: 0.95625,
  52.55: 0.95525,
  52.6: 0.95425,
  52.65: 0.95325,
  52.7: 0.95225,
  52.75: 0.95127,
  52.8: 0.9503,
  52.85: 0.94932,
  52.9: 0.94835,
  52.95: 0.94737,
  53: 0.9464,
  53.05: 0.94542,
  53.1: 0.94445,
  53.15: 0.94327,
  53.2: 0.9425,
  53.25: 0.94155,
  53.3: 0.9406,
  53.35: 0.93965,
  53.4: 0.9387,
  53.45: 0.938,
  53.5: 0.9373,
  53.55: 0.9361,
  53.6: 0.9349,
  53.65: 0.93397,
  53.7: 0.93305,
  53.75: 0.9321,
  53.8: 0.93115,
  53.85: 0.93025,
  53.9: 0.92935,
  53.95: 0.92842,
  54: 0.9275,
  54.05: 0.92657,
  54.1: 0.92565,
  54.15: 0.92475,
  54.2: 0.92385,
  54.25: 0.92292,
  54.3: 0.922,
  54.35: 0.9211,
  54.4: 0.9202,
  54.45: 0.9193,
  54.5: 0.9184,
  54.55: 0.9175,
  54.6: 0.9166,
  54.65: 0.9157,
  54.7: 0.9148,
  54.75: 0.91392,
  54.8: 0.91305,
  54.85: 0.91217,
  54.9: 0.9113,
  54.95: 0.91042,
  55: 0.90955,
  55.05: 0.90867,
  55.1: 0.9078,
  55.15: 0.90692,
  55.2: 0.90605,
  55.25: 0.9052,
  55.3: 0.90435,
  55.35: 0.9035,
  55.4: 0.90265,
  55.45: 0.9018,
  55.5: 0.90095,
  55.55: 0.9001,
  55.6: 0.89925,
  55.65: 0.89842,
  55.7: 0.8976,
  55.75: 0.89672,
  55.8: 0.89585,
  55.85: 0.89502,
  55.9: 0.8942,
  55.95: 0.89337,
  56: 0.89255,
  56.05: 0.89175,
  56.1: 0.89095,
  56.15: 0.89012,
  56.2: 0.8893,
  56.25: 0.88847,
  56.3: 0.88765,
  56.35: 0.88685,
  56.4: 0.88605,
  56.45: 0.88522,
  56.5: 0.8844,
  56.55: 0.8836,
  56.6: 0.8828,
  56.65: 0.882,
  56.7: 0.8812,
  56.75: 0.8804,
  56.8: 0.8796,
  56.85: 0.8788,
  56.9: 0.87805,
  56.95: 0.87725,
  57: 0.87645,
  57.05: 0.87567,
  57.1: 0.8749,
  57.15: 0.87412,
  57.2: 0.87335,
  57.25: 0.87257,
  57.3: 0.8718,
  57.35: 0.87102,
  57.4: 0.87025,
  57.45: 0.86947,
  57.5: 0.8687,
  57.55: 0.86792,
  57.6: 0.86715,
  57.65: 0.8664,
  57.7: 0.86565,
  57.75: 0.8649,
  57.8: 0.86415,
  57.85: 0.8634,
  57.9: 0.86265,
  57.95: 0.86192,
  58: 0.8612,
  58.05: 0.86042,
  58.1: 0.85965,
  58.15: 0.85892,
  58.2: 0.8582,
  58.25: 0.85747,
  58.3: 0.85675,
  58.35: 0.85602,
  58.4: 0.8553,
  58.45: 0.85455,
  58.5: 0.8538,
  58.55: 0.85307,
  58.6: 0.85235,
  58.65: 0.85165,
  58.7: 0.85095,
  58.75: 0.85022,
  58.8: 0.8495,
  58.85: 0.84877,
  58.9: 0.84805,
  58.95: 0.84735,
  59: 0.84665,
  59.05: 0.84595,
  59.1: 0.84525,
  59.15: 0.84455,
  59.2: 0.84385,
  59.25: 0.84315,
  59.3: 0.84245,
  59.35: 0.84175,
  59.4: 0.84105,
  59.45: 0.84035,
  59.5: 0.83965,
  59.55: 0.83897,
  59.6: 0.8383,
  59.65: 0.8376,
  59.7: 0.8369,
  59.75: 0.83622,
  59.8: 0.83555,
  59.85: 0.83487,
  59.9: 0.8342,
  59.95: 0.83352,
  60: 0.83285,
  60.05: 0.83217,
  60.1: 0.8315,
  60.15: 0.83085,
  60.2: 0.8302,
  60.25: 0.82952,
  60.3: 0.82885,
  60.35: 0.82817,
  60.4: 0.8275,
  60.45: 0.82687,
  60.5: 0.82625,
  60.55: 0.82557,
  60.6: 0.8249,
  60.65: 0.82425,
  60.7: 0.8236,
  60.75: 0.82295,
  60.8: 0.8223,
  60.85: 0.82167,
  60.9: 0.82105,
  60.95: 0.8204,
  61: 0.81975,
  61.05: 0.8191,
  61.1: 0.81845,
  61.15: 0.81782,
  61.2: 0.8172,
  61.25: 0.81655,
  61.3: 0.8159,
  61.35: 0.81527,
  61.4: 0.81465,
  61.45: 0.81402,
  61.5: 0.8134,
  61.55: 0.8128,
  61.6: 0.8122,
  61.65: 0.81157,
  61.7: 0.81095,
  61.75: 0.81032,
  61.8: 0.8097,
  61.85: 0.80907,
  61.9: 0.80845,
  61.95: 0.80785,
  62: 0.80725,
  62.05: 0.80665,
  62.1: 0.80605,
  62.15: 0.80545,
  62.2: 0.80485,
  62.25: 0.80422,
  62.3: 0.8036,
  62.35: 0.80302,
  62.4: 0.80245,
  62.45: 0.80185,
  62.5: 0.80125,
  62.55: 0.80065,
  62.6: 0.80005,
  62.65: 0.79945,
  62.7: 0.79885,
  62.75: 0.79827,
  62.8: 0.7977,
  62.85: 0.79712,
  62.9: 0.79655,
  62.95: 0.79595,
  63: 0.79535,
  63.05: 0.79477,
  63.1: 0.7942,
  63.15: 0.79362,
  63.2: 0.79305,
  63.25: 0.7925,
  63.3: 0.79195,
  63.35: 0.79137,
  63.4: 0.7908,
  63.45: 0.79022,
  63.5: 0.78965,
  63.55: 0.7891,
  63.6: 0.78855,
  63.65: 0.78797,
  63.7: 0.7874,
  63.75: 0.78682,
  63.8: 0.78625,
  63.85: 0.7857,
  63.9: 0.78515,
  63.95: 0.78462,
  64: 0.7841,
  64.05: 0.78352,
  64.1: 0.78295,
  64.15: 0.7824,
  64.2: 0.78185,
  64.25: 0.78132,
  64.3: 0.7808,
  64.35: 0.78025,
  64.4: 0.7797,
  64.45: 0.77915,
  64.5: 0.7786,
  64.55: 0.77805,
  64.6: 0.7775,
  64.65: 0.77697,
  64.7: 0.77645,
  64.75: 0.77502,
  64.8: 0.7754,
  64.85: 0.77485,
  64.9: 0.7743,
  64.95: 0.7738,
  65: 0.7733,
  65.05: 0.77277,
  65.1: 0.77225,
  65.15: 0.77172,
  65.2: 0.7712,
  65.25: 0.77067,
  65.3: 0.77015,
  65.35: 0.76962,
  65.4: 0.7691,
  65.45: 0.76857,
  65.5: 0.76805,
  65.55: 0.76755,
  65.6: 0.76705,
  65.65: 0.76652,
  65.7: 0.766,
  65.75: 0.76552,
  65.8: 0.76505,
  65.85: 0.76452,
  65.9: 0.764,
  65.95: 0.7635,
  66: 0.763,
  66.05: 0.7625,
  66.1: 0.762,
  66.15: 0.76147,
  66.2: 0.76095,
  66.25: 0.76047,
  66.3: 0.76,
  66.35: 0.7595,
  66.4: 0.759,
  66.45: 0.75852,
  66.5: 0.75805,
  66.55: 0.75755,
  66.6: 0.75705,
  66.65: 0.75657,
  66.7: 0.7561,
  66.75: 0.7556,
  66.8: 0.7551,
  66.85: 0.75462,
  66.9: 0.75415,
  66.95: 0.75365,
  67: 0.75315,
  67.05: 0.75267,
  67.1: 0.7522,
  67.15: 0.75172,
  67.2: 0.75125,
  67.25: 0.7508,
  67.3: 0.75035,
  67.35: 0.74985,
  67.4: 0.74935,
  67.45: 0.74887,
  67.5: 0.7484,
  67.55: 0.74795,
  67.6: 0.7475,
  67.65: 0.74702,
  67.7: 0.74655,
  67.75: 0.7461,
  67.8: 0.74565,
  67.85: 0.74517,
  67.9: 0.7447,
  67.95: 0.74425,
  68: 0.7438,
  68.05: 0.74332,
  68.1: 0.74285,
  68.15: 0.7424,
  68.2: 0.74195,
  68.25: 0.7415,
  68.3: 0.74105,
  68.35: 0.74062,
  68.4: 0.7402,
  68.45: 0.73972,
  68.5: 0.73925,
  68.55: 0.7388,
  68.6: 0.73835,
  68.65: 0.7379,
  68.7: 0.73745,
  68.75: 0.73702,
  68.8: 0.7366,
  68.85: 0.73615,
  68.9: 0.7357,
  68.95: 0.73527,
  69: 0.73485,
  69.05: 0.7344,
  69.1: 0.73395,
  69.15: 0.73352,
  69.2: 0.7331,
  69.25: 0.73265,
  69.3: 0.7322,
  69.35: 0.73177,
  69.4: 0.73135,
  69.45: 0.7309,
  69.5: 0.73045,
  69.55: 0.73005,
  69.6: 0.72965,
  69.65: 0.72922,
  69.7: 0.7288,
  69.75: 0.72835,
  69.8: 0.7279,
  69.85: 0.7275,
  69.9: 0.7271,
  69.95: 0.72667,
  70: 0.72625,
  70.05: 0.72582,
  70.1: 0.7254,
  70.15: 0.725,
  70.2: 0.7246,
  70.25: 0.72415,
  70.3: 0.7237,
  70.35: 0.7233,
  70.4: 0.7229,
  70.45: 0.7225,
  70.5: 0.7221,
  70.55: 0.72167,
  70.6: 0.72125,
  70.65: 0.72085,
  70.7: 0.72045,
  70.75: 0.72007,
  70.8: 0.7197,
  70.85: 0.71927,
  70.9: 0.71885,
  70.95: 0.71845,
  71: 0.71805,
  71.05: 0.71765,
  71.1: 0.71725,
  71.15: 0.71685,
  71.2: 0.71645,
  71.25: 0.71605,
  71.3: 0.71565,
  71.35: 0.71525,
  71.4: 0.71485,
  71.45: 0.71445,
  71.5: 0.71405,
  71.55: 0.71365,
  71.6: 0.71325,
  71.65: 0.71287,
  71.7: 0.7125,
  71.75: 0.7121,
  71.8: 0.7117,
  71.85: 0.71132,
  71.9: 0.71095,
  71.95: 0.71057,
  72: 0.7102,
  72.05: 0.70982,
  72.1: 0.70945,
  72.15: 0.70905,
  72.2: 0.70865,
  72.25: 0.70827,
  72.3: 0.7079,
  72.35: 0.7075,
  72.4: 0.7071,
  72.45: 0.70675,
  72.5: 0.7064,
  72.55: 0.70602,
  72.6: 0.70565,
  72.65: 0.70525,
  72.7: 0.70485,
  72.75: 0.7045,
  72.8: 0.70415,
  72.85: 0.70377,
  72.9: 0.7034,
  72.95: 0.70302,
  73: 0.70265,
  73.05: 0.702275,
  73.1: 0.7019,
  73.15: 0.701525,
  73.2: 0.70115,
  73.25: 0.7008,
  73.3: 0.70045,
  73.35: 0.7001,
  73.4: 0.69975,
  73.45: 0.699375,
  73.5: 0.699,
  73.55: 0.69865,
  73.6: 0.6983,
  73.65: 0.697925,
  73.7: 0.69755,
  73.75: 0.6972,
  73.8: 0.69685,
  73.85: 0.6965,
  73.9: 0.69615,
  73.95: 0.6958,
  74: 0.69545,
  74.05: 0.695075,
  74.1: 0.6947,
  74.15: 0.69435,
  74.2: 0.694,
  74.25: 0.693675,
  74.3: 0.69335,
  74.35: 0.693,
  74.4: 0.69265,
  74.45: 0.6923,
  74.5: 0.69195,
  74.55: 0.6916,
  74.6: 0.69125,
  74.65: 0.690925,
  74.7: 0.6906,
  74.75: 0.69025,
  74.8: 0.6899,
  74.85: 0.68955,
  74.9: 0.6892,
  74.95: 0.688875,
  75: 0.68855,
  75.05: 0.6882,
  75.1: 0.68785,
  75.15: 0.6875,
  75.2: 0.68715,
  75.25: 0.686825,
  75.3: 0.6865,
  75.35: 0.68615,
  75.4: 0.6858,
  75.45: 0.685475,
  75.5: 0.68515,
  75.55: 0.6848,
  75.6: 0.68445,
  75.65: 0.68415,
  75.7: 0.68385,
  75.75: 0.683525,
  75.8: 0.6832,
  75.85: 0.68285,
  75.9: 0.6825,
  75.95: 0.6822,
  76: 0.6819,
  76.05: 0.681575,
  76.1: 0.68125,
  76.15: 0.6809,
  76.2: 0.68055,
  76.25: 0.68025,
  76.3: 0.67995,
  76.35: 0.679625,
  76.4: 0.6793,
  76.45: 0.67895,
  76.5: 0.6786,
  76.55: 0.6783,
  76.6: 0.678,
  76.65: 0.677675,
  76.7: 0.67735,
  76.75: 0.67705,
  76.8: 0.67675,
  76.85: 0.676425,
  76.9: 0.6761,
  76.95: 0.6758,
  77: 0.6755,
  77.05: 0.6752,
  77.1: 0.6749,
  77.15: 0.674575,
  77.2: 0.67425,
  77.25: 0.67395,
  77.3: 0.67365,
  77.35: 0.67335,
  77.4: 0.67305,
  77.45: 0.672725,
  77.5: 0.6724,
  77.55: 0.6721,
  77.6: 0.6718,
  77.65: 0.6715,
  77.7: 0.6712,
  77.75: 0.6709,
  77.8: 0.6706,
  77.85: 0.670275,
  77.9: 0.66995,
  77.95: 0.66965,
  78: 0.66935,
  78.05: 0.66905,
  78.1: 0.66875,
  78.15: 0.66845,
  78.2: 0.66815,
  78.25: 0.667875,
  78.3: 0.6676,
  78.35: 0.6673,
  78.4: 0.667,
  78.45: 0.6667,
  78.5: 0.6664,
  78.55: 0.666125,
  78.6: 0.66585,
  78.65: 0.665525,
  78.7: 0.6652,
  78.75: 0.6649,
  78.8: 0.6646,
  78.85: 0.66435,
  78.9: 0.6641,
  78.95: 0.6638,
  79: 0.6635,
  79.05: 0.6632,
  79.1: 0.6629,
  79.15: 0.662625,
  79.2: 0.66235,
  79.25: 0.66205,
  79.3: 0.66175,
  79.35: 0.661475,
  79.4: 0.6612,
  79.45: 0.6609,
  79.5: 0.6606,
  79.55: 0.660325,
  79.6: 0.66005,
  79.65: 0.659775,
  79.7: 0.6595,
  79.75: 0.659225,
  79.8: 0.65895,
  79.85: 0.65865,
  79.9: 0.65835,
  79.95: 0.658075,
  80: 0.6578,
  80.05: 0.65755,
  80.1: 0.6573,
  80.15: 0.657,
  80.2: 0.6567,
  80.25: 0.656425,
  80.3: 0.65615,
  80.35: 0.6559,
  80.4: 0.65565,
  80.45: 0.65535,
  80.5: 0.65505,
  80.55: 0.654775,
  80.6: 0.6545,
  80.65: 0.65425,
  80.7: 0.654,
  80.75: 0.653725,
  80.8: 0.65345,
  80.85: 0.653175,
  80.9: 0.6529,
  80.95: 0.652625,
  81: 0.65235,
  81.05: 0.6521,
  81.1: 0.65185,
  81.15: 0.651575,
  81.2: 0.6513,
  81.25: 0.65105,
  81.3: 0.6508,
  81.35: 0.650525,
  81.4: 0.65025,
  81.45: 0.65,
  81.5: 0.64975,
  81.55: 0.6495,
  81.6: 0.64925,
  81.65: 0.648975,
  81.7: 0.6487,
  81.75: 0.64845,
  81.8: 0.6482,
  81.85: 0.647925,
  81.9: 0.64765,
  81.95: 0.6474,
  82: 0.64715,
  82.05: 0.6469,
  82.1: 0.64665,
  82.15: 0.6464,
  82.2: 0.64615,
  82.25: 0.645875,
  82.3: 0.6456,
  82.35: 0.64535,
  82.4: 0.6451,
  82.45: 0.64485,
  82.5: 0.6446,
  82.55: 0.64435,
  82.6: 0.6441,
  82.65: 0.64385,
  82.7: 0.6436,
  82.75: 0.64355,
  82.8: 0.6431,
  82.85: 0.64285,
  82.9: 0.6426,
  82.95: 0.64235,
  83: 0.6421,
  83.05: 0.64185,
  83.1: 0.6416,
  83.15: 0.64135,
  83.2: 0.6411,
  83.25: 0.640875,
  83.3: 0.64065,
  83.35: 0.6404,
  83.4: 0.64015,
  83.45: 0.6399,
  83.5: 0.63965,
  83.55: 0.639425,
  83.6: 0.6392,
  83.65: 0.63895,
  83.7: 0.6387,
  83.75: 0.63845,
  83.8: 0.6382,
  83.85: 0.637975,
  83.9: 0.63775,
  83.95: 0.6375,
  84: 0.63725,
  84.05: 0.637025,
  84.1: 0.6368,
  84.15: 0.63655,
  84.2: 0.6363,
  84.25: 0.636075,
  84.3: 0.63585,
  84.35: 0.635625,
  84.4: 0.6354,
  84.45: 0.635175,
  84.5: 0.63495,
  84.55: 0.6347,
  84.6: 0.63445,
  84.65: 0.634225,
  84.7: 0.634,
  84.75: 0.63375,
  84.8: 0.6335,
  84.85: 0.6333,
  84.9: 0.6331,
  84.95: 0.63285,
  85: 0.6326,
  85.05: 0.632375,
  85.1: 0.63215,
  85.15: 0.631925,
  85.2: 0.6317,
  85.25: 0.631475,
  85.3: 0.63125,
  85.35: 0.631025,
  85.4: 0.6308,
  85.45: 0.630575,
  85.5: 0.63035,
  85.55: 0.630125,
  85.6: 0.6299,
  85.65: 0.629675,
  85.7: 0.62945,
  85.75: 0.629225,
  85.8: 0.629,
  85.85: 0.6288,
  85.9: 0.6286,
  85.95: 0.62835,
  86: 0.6281,
  86.05: 0.6279,
  86.1: 0.6277,
  86.15: 0.627475,
  86.2: 0.62725,
  86.25: 0.62705,
  86.3: 0.62685,
  86.35: 0.6266,
  86.4: 0.62635,
  86.45: 0.62615,
  86.5: 0.62595,
  86.55: 0.625725,
  86.6: 0.6255,
  86.65: 0.6253,
  86.7: 0.6251,
  86.75: 0.624875,
  86.8: 0.62465,
  86.85: 0.62445,
  86.9: 0.62425,
  86.95: 0.62405,
  87: 0.62385,
  87.05: 0.623625,
  87.1: 0.6234,
  87.15: 0.6232,
  87.2: 0.623,
  87.25: 0.6228,
  87.3: 0.6226,
  87.35: 0.622375,
  87.4: 0.62215,
  87.45: 0.62195,
  87.5: 0.62175,
  87.55: 0.62155,
  87.6: 0.62135,
  87.65: 0.621125,
  87.7: 0.6209,
  87.75: 0.6207,
  87.8: 0.6205,
  87.85: 0.6203,
  87.9: 0.6201,
  87.95: 0.6199,
  88: 0.6197,
  88.05: 0.619475,
  88.1: 0.61925,
  88.15: 0.61905,
  88.2: 0.61885,
  88.25: 0.61865,
  88.3: 0.61845,
  88.35: 0.618275,
  88.4: 0.6181,
  88.45: 0.6179,
  88.5: 0.6177,
  88.55: 0.6175,
  88.6: 0.6173,
  88.65: 0.617075,
  88.7: 0.61685,
  88.75: 0.61665,
  88.8: 0.61645,
  88.85: 0.61625,
  88.9: 0.61605,
  88.95: 0.615875,
  89: 0.6157,
  89.05: 0.6155,
  89.1: 0.6153,
  89.15: 0.6151,
  89.2: 0.6149,
  89.25: 0.614725,
  89.3: 0.61455,
  89.35: 0.61435,
  89.4: 0.61415,
  89.45: 0.61395,
  89.5: 0.61375,
  89.55: 0.61355,
  89.6: 0.61335,
  89.65: 0.613175,
  89.7: 0.613,
  89.75: 0.6128,
  89.8: 0.6126,
  89.85: 0.612425,
  89.9: 0.61225,
  89.95: 0.61205,
  90: 0.61185,
  90.05: 0.611675,
  90.1: 0.6115,
  90.15: 0.611325,
  90.2: 0.61115,
  90.25: 0.61095,
  90.3: 0.61075,
  90.35: 0.610575,
  90.4: 0.6104,
  90.45: 0.6102,
  90.5: 0.61,
  90.55: 0.609825,
  90.6: 0.60965,
  90.65: 0.609475,
  90.7: 0.6093,
  90.75: 0.609125,
  90.8: 0.60895,
  90.85: 0.60875,
  90.9: 0.60855,
  90.95: 0.608375,
  91: 0.6082,
  91.05: 0.608025,
  91.1: 0.60785,
  91.15: 0.607675,
  91.2: 0.6075,
  91.25: 0.6073,
  91.3: 0.6071,
  91.35: 0.60695,
  91.4: 0.6068,
  91.45: 0.6066,
  91.5: 0.6064,
  91.55: 0.606225,
  91.6: 0.60605,
  91.65: 0.6059,
  91.7: 0.60575,
  91.75: 0.60555,
  91.8: 0.60535,
  91.85: 0.605175,
  91.9: 0.605,
  91.95: 0.60485,
  92: 0.6047,
  92.05: 0.6045,
  92.1: 0.6043,
  92.15: 0.60415,
  92.2: 0.604,
  92.25: 0.603825,
  92.3: 0.60365,
  92.35: 0.603475,
  92.4: 0.6033,
  92.45: 0.603125,
  92.5: 0.60295,
  92.55: 0.6028,
  92.6: 0.60265,
  92.65: 0.602475,
  92.7: 0.6023,
  92.75: 0.602125,
  92.8: 0.60195,
  92.85: 0.601775,
  92.9: 0.6016,
  92.95: 0.60145,
  93: 0.6013,
  93.05: 0.601125,
  93.1: 0.60095,
  93.15: 0.6008,
  93.2: 0.60065,
  93.25: 0.600475,
  93.3: 0.6003,
  93.35: 0.600125,
  93.4: 0.59995,
  93.45: 0.5998,
  93.5: 0.59965,
  93.55: 0.599475,
  93.6: 0.5993,
  93.65: 0.59915,
  93.7: 0.599,
  93.75: 0.59885,
  93.8: 0.5987,
  93.85: 0.59855,
  93.9: 0.5984,
  93.95: 0.5982,
  94: 0.598,
  94.05: 0.59785,
  94.1: 0.5977,
  94.15: 0.59755,
  94.2: 0.5974,
  94.25: 0.59725,
  94.3: 0.5971,
  94.35: 0.596925,
  94.4: 0.59675,
  94.45: 0.5966,
  94.5: 0.59645,
  94.55: 0.5963,
  94.6: 0.59615,
  94.65: 0.596,
  94.7: 0.59585,
  94.75: 0.5957,
  94.8: 0.5955,
  94.85: 0.595375,
  94.9: 0.5952,
  94.95: 0.59505,
  95: 0.5949,
  95.05: 0.59475,
  95.1: 0.5946,
  95.15: 0.59445,
  95.2: 0.5943,
  95.25: 0.59415,
  95.3: 0.594,
  95.35: 0.593875,
  95.4: 0.59375,
  95.45: 0.5936,
  95.5: 0.59345,
  95.55: 0.5933,
  95.6: 0.59315,
  95.65: 0.593,
  95.7: 0.59285,
  95.75: 0.5927,
  95.8: 0.59255,
  95.85: 0.5924,
  95.9: 0.59225,
  95.95: 0.5921,
  96: 0.59195,
  96.05: 0.5918,
  96.1: 0.59165,
  96.15: 0.591525,
  96.2: 0.5914,
  96.25: 0.59125,
  96.3: 0.5911,
  96.35: 0.59095,
  96.4: 0.5908,
  96.45: 0.59065,
  96.5: 0.5905,
  96.55: 0.59035,
  96.6: 0.5902,
  96.65: 0.590075,
  96.7: 0.58995,
  96.75: 0.5898,
  96.8: 0.58965,
  96.85: 0.589525,
  96.9: 0.5894,
  96.95: 0.58925,
  97: 0.5891,
  97.05: 0.588975,
  97.1: 0.58885,
  97.15: 0.5887,
  97.2: 0.58855,
  97.25: 0.5884,
  97.3: 0.58825,
  97.35: 0.588125,
  97.4: 0.588,
  97.45: 0.587875,
  97.5: 0.58775,
  97.55: 0.5876,
  97.6: 0.58745,
  97.65: 0.5873,
  97.7: 0.58715,
  97.75: 0.58705,
  97.8: 0.58695,
  97.85: 0.5868,
  97.9: 0.58665,
  97.95: 0.5865,
  98: 0.58635,
  98.05: 0.58625,
  98.1: 0.58615,
  98.15: 0.586,
  98.2: 0.58585,
  98.25: 0.585725,
  98.3: 0.5856,
  98.35: 0.585475,
  98.4: 0.58535,
  98.45: 0.5852,
  98.5: 0.58505,
  98.55: 0.584925,
  98.6: 0.5848,
  98.65: 0.584675,
  98.7: 0.58455,
  98.75: 0.584425,
  98.8: 0.5843,
  98.85: 0.584175,
  98.9: 0.58405,
  98.95: 0.583925,
  99: 0.5838,
  99.05: 0.583675,
  99.1: 0.58355,
  99.15: 0.583425,
  99.2: 0.5833,
  99.25: 0.583175,
  99.3: 0.58305,
  99.35: 0.582925,
  99.4: 0.5828,
  99.45: 0.582675,
  99.5: 0.58255,
  99.55: 0.582425,
  99.6: 0.5823,
  99.65: 0.582175,
  99.7: 0.58205,
  99.75: 0.581925,
  99.8: 0.5818,
  99.85: 0.581675,
  99.9: 0.58155,
  99.95: 0.581425,
  100: 0.5813,
  100.05: 0.581175,
  100.1: 0.58105,
  100.15: 0.58095,
  100.2: 0.58085,
  100.25: 0.580725,
  100.3: 0.5806,
  100.35: 0.580475,
  100.4: 0.58035,
  100.45: 0.58025,
  100.5: 0.58015,
  100.55: 0.58,
  100.6: 0.57985,
  100.65: 0.57975,
  100.7: 0.57965,
  100.75: 0.57955,
  100.8: 0.57945,
  100.85: 0.5793,
  100.9: 0.57915,
  100.95: 0.57905,
  101: 0.57895,
  101.05: 0.57885,
  101.1: 0.57875,
  101.15: 0.578625,
  101.2: 0.5785,
  101.25: 0.578375,
  101.3: 0.57825,
  101.35: 0.57815,
  101.4: 0.57805,
  101.45: 0.577925,
  101.5: 0.5778,
  101.55: 0.5777,
  101.6: 0.5776,
  101.65: 0.5775,
  101.7: 0.5774,
  101.75: 0.5773,
  101.8: 0.5772,
  101.85: 0.57705,
  101.9: 0.5769,
  101.95: 0.5768,
  102: 0.5767,
  102.05: 0.5766,
  102.1: 0.5765,
  102.15: 0.5764,
  102.2: 0.5763,
  102.25: 0.576175,
  102.3: 0.57605,
  102.35: 0.57595,
  102.4: 0.57585,
  102.45: 0.57575,
  102.5: 0.57565,
  102.55: 0.57555,
  102.6: 0.57545,
  102.65: 0.57535,
  102.7: 0.57525,
  102.75: 0.575125,
  102.8: 0.575,
  102.85: 0.5749,
  102.9: 0.5748,
  102.95: 0.5747,
  103: 0.5746,
  103.05: 0.5745,
  103.1: 0.5744,
  103.15: 0.5743,
  103.2: 0.5742,
  103.25: 0.5741,
  103.3: 0.574,
  103.35: 0.5739,
  103.4: 0.5738,
  103.45: 0.573675,
  103.5: 0.57355,
  103.55: 0.57345,
  103.6: 0.57335,
  103.65: 0.57325,
  103.7: 0.57315,
  103.75: 0.57305,
  103.8: 0.57295,
  103.85: 0.57285,
  103.9: 0.57275,
  103.95: 0.57265,
  104: 0.57255,
  104.05: 0.572475,
  104.1: 0.5724,
  104.15: 0.5723,
  104.2: 0.5722,
  104.25: 0.5721,
  104.3: 0.572,
  104.35: 0.5719,
  104.4: 0.5718,
  104.45: 0.5717,
  104.5: 0.5716,
  104.55: 0.5715,
  104.6: 0.5714,
  104.65: 0.571325,
  104.7: 0.57125,
  104.75: 0.57115,
  104.8: 0.57105,
  104.85: 0.57095,
  104.9: 0.57085,
  104.95: 0.57075,
  105: 0.57065,
  105.05: 0.570575,
  105.1: 0.5705,
  105.15: 0.5704,
  105.2: 0.5703,
  105.25: 0.5702,
  105.3: 0.5701,
  105.35: 0.570025,
  105.4: 0.56995,
  105.45: 0.56985,
  105.5: 0.56975,
  105.55: 0.56965,
  105.6: 0.56955,
  105.65: 0.569475,
  105.7: 0.5694,
  105.75: 0.5693,
  105.8: 0.5692,
  105.85: 0.5691,
  105.9: 0.569,
  105.95: 0.568925,
  106: 0.56885,
  106.05: 0.56875,
  106.1: 0.56865,
  106.15: 0.56855,
  106.2: 0.56845,
  106.25: 0.568375,
  106.3: 0.5683,
  106.35: 0.5682,
  106.4: 0.5681,
  106.45: 0.568025,
  106.5: 0.56795,
  106.55: 0.567875,
  106.6: 0.5678,
  106.65: 0.567725,
  106.7: 0.56765,
  106.75: 0.56755,
  106.8: 0.56745,
  106.85: 0.567375,
  106.9: 0.5673,
  106.95: 0.5672,
  107: 0.5671,
  107.05: 0.567025,
  107.1: 0.56695,
  107.15: 0.56685,
  107.2: 0.56675,
  107.25: 0.5667,
  107.3: 0.56665,
  107.35: 0.56655,
  107.4: 0.56645,
  107.45: 0.566375,
  107.5: 0.5663,
  107.55: 0.5662,
  107.6: 0.5661,
  107.65: 0.566025,
  107.7: 0.56595,
  107.75: 0.565875,
  107.8: 0.5658,
  107.85: 0.565725,
  107.9: 0.56565,
  107.95: 0.565575,
  108: 0.5655,
  108.05: 0.5654,
  108.1: 0.5353,
  108.15: 0.56525,
  108.2: 0.5652,
  108.25: 0.5651,
  108.3: 0.565,
  108.35: 0.564925,
  108.4: 0.56485,
  108.45: 0.564775,
  108.5: 0.5647,
  108.55: 0.564625,
  108.6: 0.56455,
  108.65: 0.564475,
  108.7: 0.5644,
  108.75: 0.564325,
  108.8: 0.56425,
  108.85: 0.56415,
  108.9: 0.56405,
  108.95: 0.564,
  109: 0.56395,
  109.05: 0.563875,
  109.1: 0.5638,
  109.15: 0.563725,
  109.2: 0.56365,
  109.25: 0.563575,
  109.3: 0.5635,
  109.35: 0.563425,
  109.4: 0.56335,
  109.45: 0.563275,
  109.5: 0.5632,
  109.55: 0.56315,
  109.6: 0.5631,
  109.65: 0.563,
  109.7: 0.5629,
  109.75: 0.562825,
  109.8: 0.56275,
  109.85: 0.5627,
  109.9: 0.56265,
  109.95: 0.562575,
  110: 0.5625,
  110.05: 0.562425,
  110.1: 0.56235,
  110.15: 0.562275,
  110.2: 0.5622,
  110.25: 0.562125,
  110.3: 0.56205,
  110.35: 0.561975,
  110.4: 0.5619,
  110.45: 0.56185,
  110.5: 0.5618,
  110.55: 0.561725,
  110.6: 0.56165,
  110.65: 0.5616,
  110.7: 0.56155,
  110.75: 0.56145,
  110.8: 0.56135,
  110.85: 0.561275,
  110.9: 0.5612,
  110.95: 0.56115,
  111: 0.5611,
  111.05: 0.561025,
  111.1: 0.56095,
  111.15: 0.5609,
  111.2: 0.56085,
  111.25: 0.560775,
  111.3: 0.5607,
  111.35: 0.56065,
  111.4: 0.5606,
  111.45: 0.560525,
  111.5: 0.56045,
  111.55: 0.5604,
  111.6: 0.56035,
  111.65: 0.560275,
  111.7: 0.5602,
  111.75: 0.560125,
  111.8: 0.56005,
  111.85: 0.559975,
  111.9: 0.5599,
  111.95: 0.559825,
  112: 0.55975,
  112.05: 0.5597,
  112.1: 0.55965,
  112.15: 0.559575,
  112.2: 0.5595,
  112.25: 0.55945,
  112.3: 0.5594,
  112.35: 0.559325,
  112.4: 0.55925,
  112.45: 0.5592,
  112.5: 0.55915,
  112.55: 0.559075,
  112.6: 0.559,
  112.65: 0.55895,
  112.7: 0.5589,
  112.75: 0.558825,
  112.8: 0.55875,
  112.85: 0.5587,
  112.9: 0.55865,
  112.95: 0.5586,
  113: 0.55855,
  113.05: 0.558475,
  113.1: 0.5584,
  113.15: 0.55835,
  113.2: 0.5583,
  113.25: 0.558225,
  113.3: 0.55815,
  113.35: 0.5581,
  113.4: 0.55805,
  113.45: 0.558,
  113.5: 0.55795,
  113.55: 0.5579,
  113.6: 0.55785,
  113.65: 0.557775,
  113.7: 0.5577,
  113.75: 0.55765,
  113.8: 0.5576,
  113.85: 0.55755,
  113.9: 0.5575,
  113.95: 0.557425,
  114: 0.55735,
  114.05: 0.5573,
  114.1: 0.55725,
  114.15: 0.557175,
  114.2: 0.5571,
  114.25: 0.55705,
  114.3: 0.557,
  114.35: 0.55695,
  114.4: 0.5569,
  114.45: 0.556825,
  114.5: 0.55675,
  114.55: 0.5567,
  114.6: 0.55665,
  114.65: 0.5566,
  114.7: 0.55655,
  114.75: 0.5565,
  114.8: 0.55645,
  114.85: 0.5564,
  114.9: 0.55635,
  114.95: 0.5563,
  115: 0.55625,
  115.05: 0.556175,
  115.1: 0.5561,
  115.15: 0.55605,
  115.2: 0.556,
  115.25: 0.555925,
  115.3: 0.55585,
  115.35: 0.5558,
  115.4: 0.55575,
  115.45: 0.5557,
  115.5: 0.55565,
  115.55: 0.555625,
  115.6: 0.5556,
  115.65: 0.555525,
  115.7: 0.55545,
  115.75: 0.5554,
  115.8: 0.55535,
  115.85: 0.5553,
  115.9: 0.55525,
  115.95: 0.555175,
  116: 0.5551,
  116.05: 0.55505,
  116.1: 0.555,
  116.15: 0.55495,
  116.2: 0.5549,
  116.25: 0.554825,
  116.3: 0.55475,
  116.35: 0.554725,
  116.4: 0.5547,
  116.45: 0.55465,
  116.5: 0.5546,
  116.55: 0.55455,
  116.6: 0.5545,
  116.65: 0.554425,
  116.7: 0.55435,
  116.75: 0.5543,
  116.8: 0.55425,
  116.85: 0.5542,
  116.9: 0.55415,
  116.95: 0.5541,
  117: 0.55405,
  117.05: 0.554,
  117.1: 0.55395,
  117.15: 0.5539,
  117.2: 0.55385,
  117.25: 0.5538,
  117.3: 0.55375,
  117.35: 0.5537,
  117.4: 0.55365,
  117.45: 0.553575,
  117.5: 0.5535,
  117.55: 0.55345,
  117.6: 0.5534,
  117.65: 0.55335,
  117.7: 0.5533,
  117.75: 0.553275,
  117.8: 0.55325,
  117.85: 0.5532,
  117.9: 0.55315,
  117.95: 0.553075,
  118: 0.553,
  118.05: 0.55295,
  118.1: 0.5529,
  118.15: 0.55285,
  118.2: 0.5528,
  118.25: 0.55275,
  118.3: 0.5527,
  118.35: 0.55265,
  118.4: 0.5526,
  118.45: 0.552525,
  118.5: 0.55245,
  118.55: 0.552425,
  118.6: 0.5524,
  118.65: 0.55235,
  118.7: 0.5523,
  118.75: 0.55225,
  118.8: 0.5522,
  118.85: 0.55215,
  118.9: 0.5521,
  118.95: 0.55205,
  119: 0.552,
  119.05: 0.551925,
  119.1: 0.55185,
  119.15: 0.5518,
  119.2: 0.55175,
  119.25: 0.5517,
  119.3: 0.51165,
  119.35: 0.5516,
  119.4: 0.55155,
  119.45: 0.5515,
  119.5: 0.55145,
  119.55: 0.551425,
  119.6: 0.5514,
  119.65: 0.55135,
  119.7: 0.5513,
  119.75: 0.551225,
  119.8: 0.55115,
  119.85: 0.5511,
  119.9: 0.55105,
  119.95: 0.551,
  120: 0.55095,
  120.05: 0.5509,
  120.1: 0.55085,
  120.15: 0.5508,
  120.2: 0.55075,
  120.25: 0.5507,
  120.3: 0.55065,
  120.35: 0.5506,
  120.4: 0.55055,
  120.45: 0.5505,
  120.5: 0.55045,
  120.55: 0.5504,
  120.6: 0.55035,
  120.65: 0.5503,
  120.7: 0.55025,
  120.75: 0.550175,
  120.8: 0.5501,
  120.85: 0.55005,
  120.9: 0.55,
  120.95: 0.54995,
  121: 0.5499,
  121.05: 0.54985,
  121.1: 0.5498,
  121.15: 0.54975,
  121.2: 0.5497,
  121.25: 0.54965,
  121.3: 0.5496,
  121.35: 0.54955,
  121.4: 0.5495,
  121.45: 0.54945,
  121.5: 0.5494,
  121.55: 0.54935,
  121.6: 0.5493,
  121.65: 0.54925,
  121.7: 0.5492,
  121.75: 0.549125,
  121.8: 0.54905,
  121.85: 0.549,
  121.9: 0.54895,
  121.95: 0.5489,
  122: 0.54885,
  122.05: 0.5488,
  122.1: 0.54875,
  122.15: 0.5487,
  122.2: 0.54865,
  122.25: 0.5486,
  122.3: 0.54855,
  122.35: 0.5485,
  122.4: 0.54845,
  122.45: 0.548375,
  122.5: 0.5483,
  122.55: 0.54825,
  122.6: 0.5482,
  122.65: 0.54815,
  122.7: 0.5481,
  122.75: 0.54805,
  122.8: 0.548,
  122.85: 0.54795,
  122.9: 0.5479,
  122.95: 0.547825,
  123: 0.54775,
  123.05: 0.5477,
  123.1: 0.54765,
  123.15: 0.5476,
  123.2: 0.54755,
  123.25: 0.5475,
  123.3: 0.54745,
  123.35: 0.547375,
  123.4: 0.5473,
  123.45: 0.54725,
  123.5: 0.5472,
  123.55: 0.54715,
  123.6: 0.5471,
  123.65: 0.547025,
  123.7: 0.54695,
  123.75: 0.5469,
  123.8: 0.54685,
  123.85: 0.5468,
  123.9: 0.54675,
  123.95: 0.546675,
  124: 0.5466,
  124.05: 0.54655,
  124.1: 0.5465,
  124.15: 0.546425,
  124.2: 0.54635,
  124.25: 0.5463,
  124.3: 0.54625,
  124.35: 0.5462,
  124.4: 0.54615,
  124.45: 0.546075,
  124.5: 0.546,
  124.55: 0.54595,
  124.6: 0.5459,
  124.65: 0.545825,
  124.7: 0.54575,
  124.75: 0.5457,
  124.8: 0.54565,
  124.85: 0.545575,
  124.9: 0.5455,
  124.95: 0.54545,
  125: 0.5454,
  125.05: 0.54538,
  125.1: 0.54536,
  125.15: 0.545305,
  125.2: 0.54525,
  125.25: 0.545195,
  125.3: 0.54514,
  125.35: 0.545085,
  125.4: 0.54503,
  125.45: 0.544975,
  125.5: 0.54492,
  125.55: 0.544865,
  125.6: 0.54481,
  125.65: 0.544755,
  125.7: 0.5447,
  125.75: 0.544645,
  125.8: 0.54459,
  125.85: 0.544535,
  125.9: 0.54448,
  125.95: 0.544425,
  126: 0.54437,
  126.05: 0.544315,
  126.1: 0.54426,
  126.15: 0.54423,
  126.2: 0.5442,
  126.25: 0.544145,
  126.3: 0.54409,
  126.35: 0.544035,
  126.4: 0.54398,
  126.45: 0.543925,
  126.5: 0.54387,
  126.55: 0.543815,
  126.6: 0.54376,
  126.65: 0.543705,
  126.7: 0.54365,
  126.75: 0.543595,
  126.8: 0.54354,
  126.85: 0.543485,
  126.9: 0.54343,
  126.95: 0.5434,
  127: 0.54337,
  127.05: 0.543315,
  127.1: 0.54326,
  127.15: 0.543205,
  127.2: 0.54315,
  127.25: 0.543095,
  127.3: 0.54304,
  127.35: 0.542985,
  127.4: 0.54293,
  127.45: 0.542875,
  127.5: 0.54282,
  127.55: 0.542765,
  127.6: 0.54271,
  127.65: 0.54268,
  127.7: 0.54265,
  127.75: 0.542595,
  127.8: 0.54254,
  127.85: 0.542485,
  127.9: 0.54243,
  127.95: 0.542375,
  128: 0.54232,
  128.05: 0.542265,
  128.1: 0.54221,
  128.15: 0.542155,
  128.2: 0.5421,
  128.25: 0.54207,
  128.3: 0.54204,
  128.35: 0.541985,
  128.4: 0.54193,
  128.45: 0.591875,
  128.5: 0.54182,
  128.55: 0.541765,
  128.6: 0.54171,
  128.65: 0.541655,
  128.7: 0.5416,
  128.75: 0.541545,
  128.8: 0.54149,
  128.85: 0.54146,
  128.9: 0.54143,
  128.95: 0.541375,
  129: 0.54132,
  129.05: 0.541265,
  129.1: 0.54121,
  129.15: 0.541155,
  129.2: 0.5411,
  129.25: 0.541045,
  129.3: 0.54099,
  129.35: 0.54096,
  129.4: 0.54093,
  129.45: 0.540875,
  129.5: 0.54082,
  129.55: 0.540765,
  129.6: 0.54071,
  129.65: 0.540655,
  129.7: 0.5406,
  129.75: 0.54057,
  129.8: 0.54054,
  129.85: 0.540485,
  129.9: 0.54043,
  129.95: 0.540375,
  130: 0.54032,
  130.05: 0.540265,
  130.1: 0.54021,
  130.15: 0.540155,
  130.2: 0.5401,
  130.25: 0.54007,
  130.3: 0.54004,
  130.35: 0.539985,
  130.4: 0.53993,
  130.45: 0.539875,
  130.5: 0.53982,
  130.55: 0.539765,
  130.6: 0.53971,
  130.65: 0.53968,
  130.7: 0.53965,
  130.75: 0.539595,
  130.8: 0.53954,
  130.85: 0.539485,
  130.9: 0.53943,
  130.95: 0.539375,
  131: 0.53932,
  131.05: 0.539265,
  131.1: 0.53921,
  131.15: 0.53918,
  131.2: 0.53915,
  131.25: 0.539095,
  131.3: 0.53904,
  131.35: 0.538985,
  131.4: 0.53893,
  131.45: 0.538875,
  131.5: 0.53882,
  131.55: 0.53879,
  131.6: 0.53876,
  131.65: 0.538705,
  131.7: 0.53865,
  131.75: 0.538595,
  131.8: 0.53854,
  131.85: 0.53851,
  131.9: 0.53848,
  131.95: 0.538425,
  132: 0.53837,
  132.05: 0.538315,
  132.1: 0.53826,
  132.15: 0.538205,
  132.2: 0.53815,
  132.25: 0.53812,
  132.3: 0.53809,
  132.35: 0.538035,
  132.4: 0.53798,
  132.45: 0.537925,
  132.5: 0.53787,
  132.55: 0.537815,
  132.6: 0.53776,
  132.65: 0.53773,
  132.7: 0.5377,
  132.75: 0.437645,
  132.8: 0.53759,
  132.85: 0.537535,
  132.9: 0.53748,
  132.95: 0.53745,
  133: 0.53742,
  133.05: 0.537365,
  133.1: 0.53731,
  133.15: 0.537255,
  133.2: 0.5372,
  133.25: 0.537145,
  133.3: 0.53709,
  133.35: 0.53706,
  133.4: 0.53703,
  133.45: 0.536975,
  133.5: 0.53692,
  133.55: 0.536865,
  133.6: 0.53681,
  133.65: 0.53678,
  133.7: 0.53675,
  133.75: 0.536695,
  133.8: 0.53664,
  133.85: 0.536585,
  133.9: 0.53653,
  133.95: 0.5365,
  134: 0.53647,
  134.05: 0.536415,
  134.1: 0.53636,
  134.15: 0.536305,
  134.2: 0.53625,
  134.25: 0.536195,
  134.3: 0.53614,
  134.35: 0.53611,
  134.4: 0.53608,
  134.45: 0.536025,
  134.5: 0.53597,
  134.55: 0.535915,
  134.6: 0.53586,
  134.65: 0.53583,
  134.7: 0.5358,
  134.75: 0.535745,
  134.8: 0.53569,
  134.85: 0.535635,
  134.9: 0.53558,
  134.95: 0.53555,
  135: 0.53552,
  135.05: 0.535467,
  135.1: 0.535415,
  135.15: 0.535362,
  135.2: 0.53531,
  135.25: 0.535282,
  135.3: 0.535255,
  135.35: 0.535202,
  135.4: 0.53515,
  135.45: 0.535097,
  135.5: 0.535045,
  135.55: 0.535017,
  135.6: 0.53499,
  135.65: 0.534937,
  135.7: 0.534885,
  135.75: 0.534832,
  135.8: 0.53478,
  135.85: 0.534752,
  135.9: 0.534725,
  135.95: 0.534672,
  136: 0.53462,
  136.05: 0.534567,
  136.1: 0.534515,
  136.15: 0.534487,
  136.2: 0.53446,
  136.25: 0.534407,
  136.3: 0.534355,
  136.35: 0.534302,
  136.4: 0.53425,
  136.45: 0.534222,
  136.5: 0.534195,
  136.55: 0.534142,
  136.6: 0.53409,
  136.65: 0.534062,
  136.7: 0.534035,
  136.75: 0.533982,
  136.8: 0.53393,
  136.85: 0.533877,
  136.9: 0.533825,
  136.95: 0.533797,
  137: 0.53377,
  137.05: 0.533722,
  137.1: 0.533675,
  137.15: 0.533617,
  137.2: 0.53356,
  137.25: 0.533532,
  137.3: 0.533505,
  137.35: 0.533452,
  137.4: 0.5334,
  137.45: 0.533347,
  137.5: 0.533295,
  137.55: 0.533267,
  137.6: 0.53324,
  137.65: 0.533187,
  137.7: 0.533135,
  137.75: 0.533107,
  137.8: 0.53308,
  137.85: 0.533027,
  137.9: 0.532975,
  137.95: 0.532922,
  138: 0.53287,
  138.05: 0.532842,
  138.1: 0.532815,
  138.15: 0.532762,
  138.2: 0.53271,
  138.25: 0.532657,
  138.3: 0.532605,
  138.35: 0.532577,
  138.4: 0.53255,
  138.45: 0.532497,
  138.5: 0.532445,
  138.55: 0.532417,
  138.6: 0.53239,
  138.65: 0.532337,
  138.7: 0.532285,
  138.75: 0.532232,
  138.8: 0.53218,
  138.85: 0.532152,
  138.9: 0.532125,
  138.95: 0.532072,
  139: 0.53202,
  139.05: 0.531057,
  139.1: 0.531915,
  139.15: 0.531887,
  139.2: 0.53186,
  139.25: 0.531807,
  139.3: 0.531755,
  139.35: 0.531727,
  139.4: 0.5317,
  139.45: 0.531647,
  139.5: 0.531595,
  139.55: 0.531542,
  139.6: 0.53149,
  139.65: 0.531462,
  139.7: 0.531435,
  139.75: 0.531382,
  139.8: 0.53133,
  139.85: 0.531302,
  139.9: 0.531275,
  139.95: 0.531222,
  140: 0.53117,
  140.05: 0.531117,
  140.1: 0.531065,
  140.15: 0.531037,
  140.2: 0.53101,
  140.25: 0.530957,
  140.3: 0.530905,
  140.35: 0.530877,
  140.4: 0.53085,
  140.45: 0.530797,
  140.5: 0.530745,
  140.55: 0.530692,
  140.6: 0.53064,
  140.65: 0.530612,
  140.7: 0.530585,
  140.75: 0.530532,
  140.8: 0.53048,
  140.85: 0.530452,
  140.9: 0.530425,
  140.95: 0.530372,
  141: 0.53032,
  141.05: 0.530292,
  141.1: 0.530265,
  141.15: 0.530212,
  141.2: 0.53016,
  141.25: 0.530107,
  141.3: 0.530055,
  141.35: 0.530027,
  141.4: 0.53,
  141.45: 0.529947,
  141.5: 0.529895,
  141.55: 0.529867,
  141.6: 0.52984,
  141.65: 0.529787,
  141.7: 0.529735,
  141.75: 0.529707,
  141.8: 0.52968,
  141.85: 0.529627,
  141.9: 0.529575,
  141.95: 0.529522,
  142: 0.52947,
  142.05: 0.529442,
  142.1: 0.529415,
  142.15: 0.529362,
  142.2: 0.52931,
  142.25: 0.529282,
  142.3: 0.529255,
  142.35: 0.529202,
  142.4: 0.52915,
  142.45: 0.529097,
  142.5: 0.529045,
  142.55: 0.529017,
  142.6: 0.52899,
  142.65: 0.528937,
  142.7: 0.528885,
  142.75: 0.528857,
  142.8: 0.52883,
  142.85: 0.528777,
  142.9: 0.528725,
  142.95: 0.528697,
  143: 0.52867,
  143.05: 0.528617,
  143.1: 0.528565,
  143.15: 0.528537,
  143.2: 0.52851,
  143.25: 0.528457,
  143.3: 0.528405,
  143.35: 0.528352,
  143.4: 0.5283,
  143.45: 0.528272,
  143.5: 0.528245,
  143.55: 0.528192,
  143.6: 0.52814,
  143.65: 0.528112,
  143.7: 0.52808,
  143.75: 0.528032,
  143.8: 0.52798,
  143.85: 0.527952,
  143.9: 0.527925,
  143.95: 0.527872,
  144: 0.52782,
  144.05: 0.527767,
  144.1: 0.527715,
  144.15: 0.527687,
  144.2: 0.52766,
  144.25: 0.527607,
  144.3: 0.527555,
  144.35: 0.527527,
  144.4: 0.5275,
  144.45: 0.527447,
  144.5: 0.527395,
  144.55: 0.527367,
  144.6: 0.52734,
  144.65: 0.527287,
  144.7: 0.527235,
  144.75: 0.527207,
  144.8: 0.52718,
  144.85: 0.527127,
  144.9: 0.527075,
  144.95: 0.52705,
  145: 0.527025,
  145.05: 0.526975,
  145.1: 0.526925,
  145.15: 0.526875,
  145.2: 0.526825,
  145.25: 0.5268,
  145.3: 0.526775,
  145.35: 0.526725,
  145.4: 0.526675,
  145.45: 0.52665,
  145.5: 0.526625,
  145.55: 0.526575,
  145.6: 0.526525,
  145.65: 0.5265,
  145.7: 0.526475,
  145.75: 0.526425,
  145.8: 0.526375,
  145.85: 0.52635,
  145.9: 0.526325,
  145.95: 0.526275,
  146: 0.526225,
  146.05: 0.5262,
  146.1: 0.526175,
  146.15: 0.526125,
  146.2: 0.526075,
  146.25: 0.526025,
  146.3: 0.525975,
  146.35: 0.52595,
  146.4: 0.525925,
  146.45: 0.525875,
  146.5: 0.525825,
  146.55: 0.5258,
  146.6: 0.525775,
  146.65: 0.525725,
  146.7: 0.525675,
  146.75: 0.52565,
  146.8: 0.525625,
  146.85: 0.525575,
  146.9: 0.525525,
  146.95: 0.5255,
  147: 0.525475,
  147.05: 0.525425,
  147.1: 0.525375,
  147.15: 0.52535,
  147.2: 0.525325,
  147.25: 0.525275,
  147.3: 0.525225,
  147.35: 0.5252,
  147.4: 0.525175,
  147.45: 0.525125,
  147.5: 0.525075,
  147.55: 0.52505,
  147.6: 0.525025,
  147.65: 0.524975,
  147.7: 0.524925,
  147.75: 0.524875,
  147.8: 0.524825,
  147.85: 0.5248,
  147.9: 0.524775,
  147.95: 0.524725,
  148: 0.524675,
  148.05: 0.52465,
  148.1: 0.524625,
  148.15: 0.524575,
  148.2: 0.524525,
  148.25: 0.5245,
  148.3: 0.524475,
  148.35: 0.524425,
  148.4: 0.524375,
  148.45: 0.52435,
  148.5: 0.524325,
  148.55: 0.524275,
  148.6: 0.524225,
  148.65: 0.5242,
  148.7: 0.524175,
  148.75: 0.524125,
  148.8: 0.524075,
  148.85: 0.52405,
  148.9: 0.524025,
  148.95: 0.523975,
  149: 0.523925,
  149.05: 0.5239,
  149.1: 0.523875,
  149.15: 0.523825,
  149.2: 0.523775,
  149.25: 0.52375,
  149.3: 0.523725,
  149.35: 0.523675,
  149.4: 0.523625,
  149.45: 0.5236,
  149.5: 0.523575,
  149.55: 0.523525,
  149.6: 0.523475,
  149.65: 0.52345,
  149.7: 0.523425,
  149.75: 0.523375,
  149.8: 0.523325,
  149.85: 0.523275,
  149.9: 0.523225,
  149.95: 0.5232,
  150: 0.523175,
  150.05: 0.523125,
  150.1: 0.523075,
  150.15: 0.52305,
  150.2: 0.523025,
  150.25: 0.522975,
  150.3: 0.522925,
  150.35: 0.5229,
  150.4: 0.522875,
  150.45: 0.522825,
  150.5: 0.522775,
  150.55: 0.52275,
  150.6: 0.522725,
  150.65: 0.522675,
  150.7: 0.522625,
  150.75: 0.5226,
  150.8: 0.522575,
  150.85: 0.522525,
  150.9: 0.522475,
  150.95: 0.52245,
  151: 0.522425,
  151.05: 0.522375,
  151.1: 0.522325,
  151.15: 0.5223,
  151.2: 0.522275,
  151.25: 0.522225,
  151.3: 0.522175,
  151.35: 0.52215,
  151.4: 0.522125,
  151.45: 0.522075,
  151.5: 0.522025,
  151.55: 0.522,
  151.6: 0.521975,
  151.65: 0.521925,
  151.7: 0.521875,
  151.75: 0.52185,
  151.8: 0.521825,
  151.85: 0.521775,
  151.9: 0.521725,
  151.95: 0.5217,
  152: 0.521675,
  152.05: 0.521625,
  152.1: 0.521575,
  152.15: 0.52155,
  152.2: 0.521525,
  152.25: 0.521475,
  152.3: 0.521425,
  152.35: 0.5214,
  152.4: 0.521375,
  152.45: 0.521325,
  152.5: 0.521275,
  152.55: 0.52125,
  152.6: 0.521225,
  152.65: 0.521175,
  152.7: 0.521125,
  152.75: 0.5211,
  152.8: 0.521075,
  152.85: 0.521025,
  152.9: 0.520975,
  152.95: 0.52095,
  153: 0.520925,
  153.05: 0.520875,
  153.1: 0.520825,
  153.15: 0.520775,
  153.2: 0.520725,
  153.25: 0.5207,
  153.3: 0.520675,
  153.35: 0.520625,
  153.4: 0.520575,
  153.45: 0.52055,
  153.5: 0.520525,
  153.55: 0.520475,
  153.6: 0.520425,
  153.65: 0.5204,
  153.7: 0.520375,
  153.75: 0.520325,
  153.8: 0.520275,
  153.85: 0.52025,
  153.9: 0.520225,
  153.95: 0.520175,
  154: 0.520125,
  154.05: 0.5201,
  154.1: 0.520075,
  154.15: 0.520025,
  154.2: 0.519975,
  154.25: 0.51995,
  154.3: 0.519925,
  154.35: 0.519875,
  154.4: 0.519825,
  154.45: 0.5198,
  154.5: 0.519775,
  154.55: 0.519725,
  154.6: 0.519675,
  154.65: 0.51965,
  154.7: 0.519625,
  154.75: 0.519575,
  154.8: 0.519525,
  154.85: 0.5195,
  154.9: 0.519475,
  154.95: 0.519425,
  155: 0.519375,
  155.05: 0.519352,
  155.1: 0.51933,
  155.15: 0.519282,
  155.2: 0.519235,
  155.25: 0.519212,
  155.3: 0.51919,
  155.35: 0.519142,
  155.4: 0.519095,
  155.45: 0.519072,
  155.5: 0.51905,
  155.55: 0.519002,
  155.6: 0.518955,
  155.65: 0.518932,
  155.7: 0.51891,
  155.75: 0.518862,
  155.8: 0.518815,
  155.85: 0.518792,
  155.9: 0.51877,
  155.95: 0.518722,
  156: 0.518675,
  156.05: 0.518652,
  156.1: 0.51863,
  156.15: 0.518582,
  156.2: 0.518535,
  156.25: 0.518512,
  156.3: 0.51849,
  156.35: 0.518442,
  156.4: 0.518395,
  156.45: 0.518372,
  156.5: 0.51835,
  156.55: 0.518302,
  156.6: 0.518255,
  156.65: 0.518232,
  156.7: 0.51821,
  156.75: 0.518162,
  156.8: 0.518115,
  156.85: 0.518092,
  156.9: 0.51807,
  156.95: 0.518022,
  157: 0.517975,
  157.05: 0.517952,
  157.1: 0.51793,
  157.15: 0.517882,
  157.2: 0.517835,
  157.25: 0.517812,
  157.3: 0.51779,
  157.35: 0.517742,
  157.4: 0.517695,
  157.45: 0.517672,
  157.5: 0.51765,
  157.55: 0.517602,
  157.6: 0.517555,
  157.65: 0.517532,
  157.7: 0.51751,
  157.75: 0.517462,
  157.8: 0.517415,
  157.85: 0.517392,
  157.9: 0.51737,
  157.95: 0.517322,
  158: 0.517275,
  158.05: 0.517252,
  158.1: 0.51723,
  158.15: 0.517182,
  158.2: 0.517135,
  158.25: 0.517112,
  158.3: 0.51709,
  158.35: 0.517042,
  158.4: 0.516995,
  158.45: 0.516972,
  158.5: 0.51695,
  158.55: 0.516902,
  158.6: 0.516855,
  158.65: 0.516832,
  158.7: 0.51681,
  158.75: 0.516762,
  158.8: 0.516715,
  158.85: 0.516692,
  158.9: 0.51667,
  158.95: 0.516622,
  159: 0.516575,
  159.05: 0.516552,
  159.1: 0.51653,
  159.15: 0.516482,
  159.2: 0.516435,
  159.25: 0.516412,
  159.3: 0.51639,
  159.35: 0.516342,
  159.4: 0.516295,
  159.45: 0.516272,
  159.5: 0.51625,
  159.55: 0.516202,
  159.6: 0.516155,
  159.65: 0.516132,
  159.7: 0.51611,
  159.75: 0.516062,
  159.8: 0.516015,
  159.85: 0.515992,
  159.9: 0.51597,
  159.95: 0.515922,
  160: 0.515875,
  160.05: 0.515852,
  160.1: 0.51583,
  160.15: 0.515782,
  160.2: 0.515735,
  160.25: 0.515712,
  160.3: 0.51569,
  160.35: 0.515642,
  160.4: 0.515595,
  160.45: 0.515572,
  160.5: 0.51555,
  160.55: 0.515502,
  160.6: 0.515455,
  160.65: 0.515432,
  160.7: 0.51541,
  160.75: 0.515362,
  160.8: 0.515315,
  160.85: 0.515292,
  160.9: 0.51527,
  160.95: 0.515222,
  161: 0.515175,
  161.05: 0.515152,
  161.1: 0.51513,
  161.15: 0.515082,
  161.2: 0.515035,
  161.25: 0.515012,
  161.3: 0.51499,
  161.35: 0.514942,
  161.4: 0.514895,
  161.45: 0.514872,
  161.5: 0.51485,
  161.55: 0.514802,
  161.6: 0.514755,
  161.65: 0.514732,
  161.7: 0.51471,
  161.75: 0.514662,
  161.8: 0.514615,
  161.85: 0.514567,
  161.9: 0.51452,
  161.95: 0.514497,
  162: 0.514475,
  162.05: 0.514427,
  162.1: 0.51438,
  162.15: 0.514357,
  162.2: 0.514335,
  162.25: 0.514287,
  162.3: 0.51424,
  162.35: 0.514217,
  162.4: 0.514195,
  162.45: 0.514147,
  162.5: 0.5141,
  162.55: 0.514077,
  162.6: 0.514055,
  162.65: 0.514007,
  162.7: 0.51396,
  162.75: 0.513937,
  162.8: 0.513915,
  162.85: 0.513867,
  162.9: 0.51382,
  162.95: 0.513797,
  163: 0.513775,
  163.05: 0.513727,
  163.1: 0.51368,
  163.15: 0.513657,
  163.2: 0.513635,
  163.25: 0.513587,
  163.3: 0.51354,
  163.35: 0.513517,
  163.4: 0.513495,
  163.45: 0.513447,
  163.5: 0.5134,
  163.55: 0.513377,
  163.6: 0.513355,
  163.65: 0.513307,
  163.7: 0.51326,
  163.75: 0.513237,
  163.8: 0.513215,
  163.85: 0.513167,
  163.9: 0.51312,
  163.95: 0.513097,
  164: 0.513075,
  164.05: 0.513027,
  164.1: 0.51298,
  164.15: 0.512957,
  164.2: 0.512935,
  164.25: 0.512887,
  164.3: 0.51284,
  164.35: 0.512817,
  164.4: 0.512795,
  164.45: 0.512747,
  164.5: 0.5127,
  164.55: 0.512677,
  164.6: 0.512655,
  164.65: 0.512607,
  164.7: 0.51256,
  164.75: 0.512537,
  164.8: 0.512515,
  164.85: 0.512467,
  164.9: 0.51242,
  164.95: 0.512397,
  165: 0.512375,
  165.05: 0.51233,
  165.1: 0.512285,
  165.15: 0.512265,
  165.2: 0.512245,
  165.25: 0.5122,
  165.3: 0.512155,
  165.35: 0.512135,
  165.4: 0.512115,
  165.45: 0.51207,
  165.5: 0.512025,
  165.55: 0.512005,
  165.6: 0.511985,
  165.65: 0.51194,
  165.7: 0.511895,
  165.75: 0.511875,
  165.8: 0.511855,
  165.85: 0.51181,
  165.9: 0.511765,
  165.95: 0.511745,
  166: 0.511725,
  166.05: 0.51168,
  166.1: 0.511635,
  166.15: 0.511615,
  166.2: 0.511595,
  166.25: 0.51155,
  166.3: 0.511505,
  166.35: 0.511485,
  166.4: 0.511465,
  166.45: 0.51142,
  166.5: 0.511375,
  166.55: 0.511355,
  166.6: 0.511335,
  166.65: 0.51129,
  166.7: 0.511245,
  166.75: 0.511225,
  166.8: 0.511205,
  166.85: 0.51116,
  166.9: 0.511115,
  166.95: 0.511095,
  167: 0.511075,
  167.05: 0.51103,
  167.1: 0.510985,
  167.15: 0.510965,
  167.2: 0.510945,
  167.25: 0.5109,
  167.3: 0.510855,
  167.35: 0.510835,
  167.4: 0.510815,
  167.45: 0.51077,
  167.5: 0.510725,
  167.55: 0.510705,
  167.6: 0.510685,
  167.65: 0.51064,
  167.7: 0.510595,
  167.75: 0.510575,
  167.8: 0.510555,
  167.85: 0.51051,
  167.9: 0.510465,
  167.95: 0.510445,
  168: 0.510425,
  168.05: 0.51038,
  168.1: 0.510335,
  168.15: 0.510315,
  168.2: 0.510295,
  168.25: 0.51025,
  168.3: 0.510205,
  168.35: 0.510185,
  168.4: 0.510165,
  168.45: 0.51012,
  168.5: 0.510075,
  168.55: 0.510055,
  168.6: 0.510035,
  168.65: 0.50999,
  168.7: 0.509945,
  168.75: 0.509925,
  168.8: 0.509905,
  168.85: 0.50986,
  168.9: 0.509815,
  168.95: 0.50977,
  169: 0.509725,
  169.05: 0.509705,
  169.1: 0.509685,
  169.15: 0.50964,
  169.2: 0.509595,
  169.25: 0.509575,
  169.3: 0.509555,
  169.35: 0.50951,
  169.4: 0.509465,
  169.45: 0.509445,
  169.5: 0.509425,
  169.55: 0.50938,
  169.6: 0.509335,
  169.65: 0.509315,
  169.7: 0.509295,
  169.75: 0.50925,
  169.8: 0.509205,
  169.85: 0.509185,
  169.9: 0.509165,
  169.95: 0.50912,
  170: 0.509075,
  170.05: 0.509055,
  170.1: 0.509035,
  170.15: 0.50899,
  170.2: 0.508945,
  170.25: 0.508925,
  170.3: 0.508905,
  170.35: 0.50886,
  170.4: 0.508815,
  170.45: 0.508795,
  170.5: 0.508775,
  170.55: 0.50873,
  170.6: 0.508685,
  170.65: 0.508665,
  170.7: 0.508645,
  170.75: 0.5086,
  170.8: 0.508555,
  170.85: 0.508535,
  170.9: 0.508515,
  170.95: 0.50847,
  171: 0.508425,
  171.05: 0.508405,
  171.1: 0.508385,
  171.15: 0.50834,
  171.2: 0.508295,
  171.25: 0.508275,
  171.3: 0.508255,
  171.35: 0.50821,
  171.4: 0.508165,
  171.45: 0.508145,
  171.5: 0.508125,
  171.55: 0.50808,
  171.6: 0.508035,
  171.65: 0.508015,
  171.7: 0.507995,
  171.75: 0.50795,
  171.8: 0.507905,
  171.85: 0.507885,
  171.9: 0.507865,
  171.95: 0.50782,
  172: 0.507775,
  172.05: 0.507755,
  172.1: 0.507735,
  172.15: 0.50769,
  172.2: 0.507645,
  172.25: 0.507625,
  172.3: 0.507605,
  172.35: 0.50756,
  172.4: 0.507515,
  172.45: 0.507495,
  172.5: 0.507475,
  172.55: 0.50743,
  172.6: 0.507385,
  172.65: 0.507365,
  172.7: 0.507345,
  172.75: 0.5073,
  172.8: 0.507255,
  172.85: 0.507235,
  172.9: 0.507215,
  172.95: 0.50717,
  173: 0.507125,
  173.05: 0.507105,
  173.1: 0.507085,
  173.15: 0.50704,
  173.2: 0.506995,
  173.25: 0.506975,
  173.3: 0.506955,
  173.35: 0.50691,
  173.4: 0.506865,
  173.45: 0.506845,
  173.5: 0.506825,
  173.55: 0.50678,
  173.6: 0.506735,
  173.65: 0.506715,
  173.7: 0.506695,
  173.75: 0.50665,
  173.8: 0.506605,
  173.85: 0.506585,
  173.9: 0.506565,
  173.95: 0.50652,
  174: 0.506475,
  174.05: 0.506455,
  174.1: 0.506435,
  174.15: 0.50639,
  174.2: 0.506345,
  174.25: 0.506325,
  174.3: 0.506305,
  174.35: 0.50626,
  174.4: 0.506215,
  174.45: 0.506195,
  174.5: 0.506175,
  174.55: 0.50613,
  174.6: 0.506085,
  174.65: 0.506065,
  174.7: 0.506045,
  174.75: 0.506,
  174.8: 0.505955,
  174.85: 0.505935,
  174.9: 0.505915,
  174.95: 0.50587,
  175: 0.505835,
  175.05: 0.5058,
  175.1: 0.505765,
  175.15: 0.50573,
  175.2: 0.505695,
  175.25: 0.50566,
  175.3: 0.505625,
  175.35: 0.50559,
  175.4: 0.505555,
  175.45: 0.50552,
  175.5: 0.505485,
  175.55: 0.50545,
  175.6: 0.505415,
  175.65: 0.50538,
  175.7: 0.505345,
  175.75: 0.50531,
  175.8: 0.505275,
  175.85: 0.50524,
  175.9: 0.505205,
  175.95: 0.50517,
  176: 0.505135,
  176.05: 0.5051,
  176.1: 0.505065,
  176.15: 0.50503,
  176.2: 0.504995,
  176.25: 0.50496,
  176.3: 0.504925,
  176.35: 0.50489,
  176.4: 0.504855,
  176.45: 0.50482,
  176.5: 0.504785,
  176.55: 0.50475,
  176.6: 0.504715,
  176.65: 0.50468,
  176.7: 0.504645,
  176.75: 0.50461,
  176.8: 0.504575,
  176.85: 0.50454,
  176.9: 0.504505,
  176.95: 0.50447,
  177: 0.504435,
  177.05: 0.5044,
  177.1: 0.504365,
  177.15: 0.50433,
  177.2: 0.504295,
  177.25: 0.50426,
  177.3: 0.504225,
  177.35: 0.50419,
  177.4: 0.504155,
  177.45: 0.50412,
  177.5: 0.504085,
  177.55: 0.50405,
  177.6: 0.504015,
  177.65: 0.50398,
  177.7: 0.503945,
  177.75: 0.50391,
  177.8: 0.503875,
  177.85: 0.50384,
  177.9: 0.503805,
  177.95: 0.50377,
  178: 0.503735,
  178.05: 0.5037,
  178.1: 0.503665,
  178.15: 0.50363,
  178.2: 0.503595,
  178.25: 0.50356,
  178.3: 0.503525,
  178.35: 0.50349,
  178.4: 0.503455,
  178.45: 0.50342,
  178.5: 0.503385,
  178.55: 0.50335,
  178.6: 0.50328,
  178.65: 0.503245,
  178.7: 0.50321,
  178.75: 0.503175,
  178.8: 0.50314,
  178.85: 0.503105,
  178.9: 0.50307,
  178.95: 0.503035,
  179: 0.503,
  179.05: 0.502965,
  179.1: 0.50293,
  179.15: 0.502895,
  179.2: 0.50286,
  179.25: 0.502825,
  179.3: 0.50279,
  179.35: 0.502755,
  179.4: 0.50272,
  179.45: 0.502685,
  179.5: 0.50265,
  179.55: 0.502615,
  179.6: 0.50258,
  179.65: 0.502545,
  179.7: 0.50251,
  179.75: 0.502475,
  179.8: 0.50244,
  179.85: 0.502405,
  179.9: 0.50237,
  179.95: 0.502335,
  180: 0.5023,
  180.05: 0.502265,
  180.1: 0.50223,
  180.15: 0.502195,
  180.2: 0.50216,
  180.25: 0.502125,
  180.3: 0.50209,
  180.35: 0.502055,
  180.4: 0.50202,
  180.45: 0.501985,
  180.5: 0.50195,
  180.55: 0.501915,
  180.6: 0.50188,
  180.65: 0.501845,
  180.7: 0.50181,
  180.75: 0.501775,
  180.8: 0.50174,
  180.85: 0.501705,
  180.9: 0.50167,
  180.95: 0.501635,
  181: 0.5016,
  181.05: 0.501565,
  181.1: 0.50153,
  181.15: 0.501495,
  181.2: 0.50146,
  181.25: 0.501425,
  181.3: 0.50139,
  181.35: 0.501355,
  181.4: 0.50132,
  181.45: 0.501285,
  181.5: 0.50125,
  181.55: 0.501215,
  181.6: 0.50118,
  181.65: 0.501145,
  181.7: 0.50111,
  181.75: 0.501075,
  181.8: 0.50104,
  181.85: 0.501005,
  181.9: 0.50097,
  181.95: 0.500935,
  182: 0.5009,
  182.05: 0.500865,
  182.1: 0.50083,
  182.15: 0.500795,
  182.2: 0.50076,
  182.25: 0.500725,
  182.3: 0.50069,
  182.35: 0.500655,
  182.4: 0.50062,
  182.45: 0.500585,
  182.5: 0.50055,
  182.55: 0.500515,
  182.6: 0.500485,
  182.65: 0.500455,
  182.7: 0.50042,
  182.75: 0.500385,
  182.8: 0.50035,
  182.85: 0.500315,
  182.9: 0.50028,
  182.95: 0.500245,
  183: 0.50021,
  183.05: 0.500175,
  183.1: 0.50014,
  183.15: 0.500105,
  183.2: 0.50007,
  183.25: 0.500035,
  183.3: 0.5,
  183.35: 0.499965,
  183.4: 0.49993,
  183.45: 0.499895,
  183.5: 0.49986,
  183.55: 0.499825,
  183.6: 0.49979,
  183.65: 0.499755,
  183.7: 0.49972,
  183.75: 0.499685,
  183.8: 0.49965,
  183.85: 0.499615,
  183.9: 0.49958,
  183.95: 0.499545,
  184: 0.49951,
  184.05: 0.499475,
  184.1: 0.49944,
  184.15: 0.499405,
  184.2: 0.49937,
  184.25: 0.499335,
  184.3: 0.4993,
  184.35: 0.499265,
  184.4: 0.49923,
  184.45: 0.499195,
  184.5: 0.49916,
  184.55: 0.499125,
  184.6: 0.49909,
  184.65: 0.499055,
  184.7: 0.49902,
  184.75: 0.498985,
  184.8: 0.49895,
  184.85: 0.498915,
  184.9: 0.4989,
  184.95: 0.498865,
  185: 0.498835,
  185.05: 0.498805,
  185.1: 0.498775,
  185.15: 0.498745,
  185.2: 0.498715,
  185.25: 0.498685,
  185.3: 0.498655,
  185.35: 0.498625,
  185.4: 0.498595,
  185.45: 0.498565,
  185.5: 0.498535,
  185.55: 0.498505,
  185.6: 0.498475,
  185.65: 0.498445,
  185.7: 0.498415,
  185.75: 0.498385,
  185.8: 0.498355,
  185.85: 0.498325,
  185.9: 0.498295,
  185.95: 0.498265,
  186: 0.498235,
  186.05: 0.498205,
  186.1: 0.498175,
  186.15: 0.498145,
  186.2: 0.498115,
  186.25: 0.498085,
  186.3: 0.498055,
  186.35: 0.498025,
  186.4: 0.497995,
  186.45: 0.497965,
  186.5: 0.497935,
  186.55: 0.497905,
  186.6: 0.497875,
  186.65: 0.497845,
  186.7: 0.497815,
  186.75: 0.497785,
  186.8: 0.497755,
  186.85: 0.497725,
  186.9: 0.497695,
  186.95: 0.497665,
  187: 0.497635,
  187.05: 0.497605,
  187.1: 0.497575,
  187.15: 0.497545,
  187.2: 0.497515,
  187.25: 0.497485,
  187.3: 0.497455,
  187.35: 0.497425,
  187.4: 0.497395,
  187.45: 0.497365,
  187.5: 0.497335,
  187.55: 0.497305,
  187.6: 0.497275,
  187.65: 0.497245,
  187.7: 0.497215,
  187.75: 0.497185,
  187.8: 0.497155,
  187.85: 0.497125,
  187.9: 0.497095,
  187.95: 0.497065,
  188: 0.497035,
  188.05: 0.497005,
  188.1: 0.496975,
  188.15: 0.496945,
  188.2: 0.496915,
  188.25: 0.496885,
  188.3: 0.496855,
  188.35: 0.496825,
  188.4: 0.496795,
  188.45: 0.496765,
  188.5: 0.496735,
  188.55: 0.496705,
  188.6: 0.496675,
  188.65: 0.496645,
  188.7: 0.496615,
  188.75: 0.496585,
  188.8: 0.496555,
  188.85: 0.496525,
  188.9: 0.496495,
  188.95: 0.496465,
  189: 0.496415,
  189.05: 0.496385,
  189.1: 0.496355,
  189.15: 0.496325,
  189.2: 0.496295,
  189.25: 0.496265,
  189.3: 0.496235,
  189.35: 0.496205,
  189.4: 0.496175,
  189.45: 0.496145,
  189.5: 0.496115,
  189.55: 0.496085,
  189.6: 0.496055,
  189.65: 0.496025,
  189.7: 0.495995,
  189.75: 0.495965,
  189.8: 0.495935,
  189.85: 0.495905,
  189.9: 0.495875,
  189.95: 0.495845,
  190: 0.495815,
  190.05: 0.495785,
  190.1: 0.495755,
  190.15: 0.495725,
  190.2: 0.495695,
  190.25: 0.495665,
  190.3: 0.495635,
  190.35: 0.495605,
  190.4: 0.495575,
  190.45: 0.495545,
  190.5: 0.495515,
  190.55: 0.495485,
  190.6: 0.495455,
  190.65: 0.495425,
  190.7: 0.495395,
  190.75: 0.495365,
  190.8: 0.495335,
  190.85: 0.495305,
  190.9: 0.495275,
  190.95: 0.495245,
  191: 0.495215,
  191.05: 0.495185,
  191.1: 0.495155,
  191.15: 0.495125,
  191.2: 0.495095,
  191.25: 0.495065,
  191.3: 0.495035,
  191.35: 0.495005,
  191.4: 0.494975,
  191.45: 0.494945,
  191.5: 0.494915,
  191.55: 0.494885,
  191.6: 0.494855,
  191.65: 0.494825,
  191.7: 0.494795,
  191.75: 0.494765,
  191.8: 0.494735,
  191.85: 0.494705,
  191.9: 0.494675,
  191.95: 0.494645,
  192: 0.494615,
  192.05: 0.494585,
  192.1: 0.494555,
  192.15: 0.494525,
  192.2: 0.494495,
  192.25: 0.494465,
  192.3: 0.494415,
  192.35: 0.494385,
  192.4: 0.494355,
  192.45: 0.494325,
  192.5: 0.494295,
  192.55: 0.494265,
  192.6: 0.494235,
  192.65: 0.494205,
  192.7: 0.494175,
  192.75: 0.494145,
  192.8: 0.494115,
  192.85: 0.494085,
  192.9: 0.494055,
  192.95: 0.494025,
  193: 0.493995,
  193.05: 0.493965,
  193.1: 0.493935,
  193.15: 0.493905,
  193.2: 0.493875,
  193.25: 0.493845,
  193.3: 0.493815,
  193.35: 0.493785,
  193.4: 0.493755,
  193.45: 0.493725,
  193.5: 0.493695,
  193.55: 0.493665,
  193.6: 0.493635,
  193.65: 0.493605,
  193.7: 0.493575,
  193.75: 0.493545,
  193.8: 0.493515,
  193.85: 0.493485,
  193.9: 0.493455,
  193.95: 0.493425,
  194: 0.493395,
  194.05: 0.493365,
  194.1: 0.493335,
  194.15: 0.493305,
  194.2: 0.493275,
  194.25: 0.493245,
  194.3: 0.493215,
  194.35: 0.493185,
  194.4: 0.493155,
  194.45: 0.493125,
  194.5: 0.493095,
  194.55: 0.493065,
  194.6: 0.493035,
  194.65: 0.493005,
  194.7: 0.492975,
  194.75: 0.492945,
  194.8: 0.492915,
  194.85: 0.492885,
  194.9: 0.492855,
  194.95: 0.492825,
  195: 0.4928,
  195.05: 0.492775,
  195.1: 0.49275,
  195.15: 0.492725,
  195.2: 0.4927,
  195.25: 0.492675,
  195.3: 0.49265,
  195.35: 0.492625,
  195.4: 0.4926,
  195.45: 0.492575,
  195.5: 0.49255,
  195.55: 0.492525,
  195.6: 0.4925,
  195.65: 0.492475,
  195.7: 0.49245,
  195.75: 0.492425,
  195.8: 0.4924,
  195.85: 0.492375,
  195.9: 0.49235,
  195.95: 0.492325,
  196: 0.4923,
  196.05: 0.492275,
  196.1: 0.49225,
  196.15: 0.492225,
  196.2: 0.4922,
  196.25: 0.492175,
  196.3: 0.49215,
  196.35: 0.492125,
  196.4: 0.4921,
  196.45: 0.492075,
  196.5: 0.49205,
  196.55: 0.492025,
  196.6: 0.492,
  196.65: 0.491975,
  196.7: 0.49195,
  196.75: 0.491925,
  196.8: 0.4919,
  196.85: 0.491875,
  196.9: 0.49185,
  196.95: 0.491825,
  197: 0.4918,
  197.05: 0.491775,
  197.1: 0.49175,
  197.15: 0.491725,
  197.2: 0.4917,
  197.25: 0.491675,
  197.3: 0.49165,
  197.35: 0.491625,
  197.4: 0.4916,
  197.45: 0.491575,
  197.5: 0.49155,
  197.55: 0.491525,
  197.6: 0.4915,
  197.65: 0.491475,
  197.7: 0.49145,
  197.75: 0.491425,
  197.8: 0.4914,
  197.85: 0.491375,
  197.9: 0.49135,
  197.95: 0.491325,
  198: 0.4913,
  198.05: 0.491275,
  198.1: 0.49125,
  198.15: 0.491225,
  198.2: 0.4912,
  198.25: 0.491175,
  198.3: 0.49115,
  198.35: 0.491125,
  198.4: 0.4911,
  198.45: 0.491075,
  198.5: 0.49105,
  198.55: 0.491025,
  198.6: 0.491,
  198.65: 0.490975,
  198.7: 0.49095,
  198.75: 0.490925,
  198.8: 0.4909,
  198.85: 0.490875,
  198.9: 0.49085,
  198.95: 0.490825,
  199: 0.4908,
  199.05: 0.490775,
  199.1: 0.49075,
  199.15: 0.490725,
  199.2: 0.4907,
  199.25: 0.490675,
  199.3: 0.49065,
  199.35: 0.490625,
  199.4: 0.4906,
  199.45: 0.490575,
  199.5: 0.49055,
  199.55: 0.490525,
  199.6: 0.4905,
  199.65: 0.490475,
  199.7: 0.49045,
  199.75: 0.490425,
  199.8: 0.4904,
  199.85: 0.490375,
  199.9: 0.49035,
  199.95: 0.490325,
  200: 0.4903,
  200.05: 0.490275,
  200.1: 0.49025,
  200.15: 0.490225,
  200.2: 0.4902,
  200.25: 0.490175,
  200.3: 0.49015,
  200.35: 0.490125,
  200.4: 0.4901,
  200.45: 0.490075,
  200.5: 0.49005,
  200.55: 0.490025,
  200.6: 0.49,
  200.65: 0.489975,
  200.7: 0.48995,
  200.75: 0.489925,
  200.8: 0.4899,
  200.85: 0.489875,
  200.9: 0.48985,
  200.95: 0.489825,
  201: 0.4898,
  201.05: 0.489775,
  201.1: 0.48975,
  201.15: 0.489725,
  201.2: 0.4897,
  201.25: 0.489675,
  201.3: 0.48965,
  201.35: 0.489625,
  201.4: 0.4896,
  201.45: 0.489575,
  201.5: 0.48955,
  201.55: 0.489525,
  201.6: 0.4895,
  201.65: 0.489475,
  201.7: 0.48945,
  201.75: 0.489425,
  201.8: 0.4894,
  201.85: 0.489375,
  201.9: 0.48935,
  201.95: 0.489325,
  202: 0.4893,
  202.05: 0.489275,
  202.1: 0.48925,
  202.15: 0.489225,
  202.2: 0.4892,
  202.25: 0.489175,
  202.3: 0.48915,
  202.35: 0.489125,
  202.4: 0.4891,
  202.45: 0.489075,
  202.5: 0.48905,
  202.55: 0.489025,
  202.6: 0.489,
  202.65: 0.488975,
  202.7: 0.48895,
  202.75: 0.488925,
  202.8: 0.4889,
  202.85: 0.488875,
  202.9: 0.48885,
  202.95: 0.488825,
  203: 0.4888,
  203.05: 0.488775,
  203.1: 0.48875,
  203.15: 0.488725,
  203.2: 0.4887,
  203.25: 0.488675,
  203.3: 0.48865,
  203.35: 0.488625,
  203.4: 0.4886,
  203.45: 0.488575,
  203.5: 0.48855,
  203.55: 0.488525,
  203.6: 0.4885,
  203.65: 0.488475,
  203.7: 0.48845,
  203.75: 0.488425,
  203.8: 0.4884,
  203.85: 0.488375,
  203.9: 0.48835,
  203.95: 0.488325,
  204: 0.4883,
  204.05: 0.488275,
  204.1: 0.48825,
  204.15: 0.488225,
  204.2: 0.4882,
  204.25: 0.488175,
  204.3: 0.48815,
  204.35: 0.488125,
  204.4: 0.4881,
  204.45: 0.488075,
  204.5: 0.48805,
  204.55: 0.488025,
  204.6: 0.488,
  204.65: 0.487975,
  204.7: 0.48795,
  204.75: 0.487925,
  204.8: 0.4879,
  204.85: 0.487875,
  204.9: 0.48785,
  204.95: 0.487825,
  205: 0.487805,
  205.05: 0.487785,
  205.1: 0.487765,
  205.15: 0.487745,
  205.2: 0.487725,
  205.25: 0.487705,
  205.3: 0.487685,
  205.35: 0.487665,
  205.4: 0.487645,
  205.45: 0.487625,
  205.5: 0.487605,
  205.55: 0.487585,
  205.6: 0.487565,
  205.65: 0.487545,
  205.7: 0.487525,
  205.75: 0.487505,
  205.8: 0.487485,
  205.85: 0.487465,
  205.9: 0.487445,
  205.95: 0.487425,
  206: 0.487405,
  206.05: 0.487385,
  206.1: 0.487365,
  206.15: 0.487345,
  206.2: 0.487325,
  206.25: 0.487305,
  206.3: 0.487285,
  206.35: 0.487265,
  206.4: 0.487245,
  206.45: 0.487225,
  206.5: 0.487205,
  206.55: 0.487185,
  206.6: 0.487165,
  206.65: 0.487145,
  206.7: 0.487125,
  206.75: 0.487105,
  206.8: 0.487085,
  206.85: 0.487065,
  206.9: 0.487045,
  206.95: 0.487025,
  207: 0.487005,
  207.05: 0.486985,
  207.1: 0.486965,
  207.15: 0.486945,
  207.2: 0.486925,
  207.25: 0.486905,
  207.3: 0.486885,
  207.35: 0.486865,
  207.4: 0.486845,
  207.45: 0.486825,
  207.5: 0.486805,
  207.55: 0.486785,
  207.6: 0.486765,
  207.65: 0.486745,
  207.7: 0.486725,
  207.75: 0.486705,
  207.8: 0.486685,
  207.85: 0.486665,
  207.9: 0.486645,
  207.95: 0.486625,
  208: 0.486605,
  208.05: 0.486585,
  208.1: 0.486565,
  208.15: 0.486545,
  208.2: 0.486525,
  208.25: 0.486505,
  208.3: 0.486485,
  208.35: 0.486465,
  208.4: 0.486445,
  208.45: 0.486425,
  208.5: 0.486405,
  208.55: 0.486385,
  208.6: 0.486365,
  208.65: 0.486345,
  208.7: 0.486325,
  208.75: 0.486305,
  208.8: 0.486285,
  208.85: 0.486265,
  208.9: 0.486245,
  208.95: 0.486225,
  209: 0.486205,
  209.05: 0.486185,
  209.1: 0.486165,
  209.15: 0.486145,
  209.2: 0.486125,
  209.25: 0.486105,
  209.3: 0.486085,
  209.35: 0.486065,
  209.4: 0.486045,
  209.45: 0.486025,
  209.5: 0.486005,
  209.55: 0.485985,
  209.6: 0.485965,
  209.65: 0.485945,
  209.7: 0.485925,
  209.75: 0.485905,
  209.8: 0.485885,
  209.85: 0.485865,
  209.9: 0.485845,
  209.95: 0.485825,
  210: 0.485805,
  210.05: 0.485785,
  210.1: 0.485765,
  210.15: 0.485745,
  210.2: 0.485725,
  210.25: 0.485705,
  210.3: 0.485685,
  210.35: 0.485665,
  210.4: 0.485645,
  210.45: 0.485625,
  210.5: 0.485605,
  210.55: 0.485585,
  210.6: 0.485565,
  210.65: 0.485545,
  210.7: 0.485525,
  210.75: 0.485505,
  210.8: 0.485485,
  210.85: 0.485465,
  210.9: 0.485445,
  210.95: 0.485425,
  211: 0.485405,
  211.05: 0.485385,
  211.1: 0.485365,
  211.15: 0.485345,
  211.2: 0.485325,
  211.25: 0.485305,
  211.3: 0.485285,
  211.35: 0.485265,
  211.4: 0.485245,
  211.45: 0.485225,
  211.5: 0.485205,
  211.55: 0.485185,
  211.6: 0.485165,
  211.65: 0.485145,
  211.7: 0.485125,
  211.75: 0.485105,
  211.8: 0.485085,
  211.85: 0.485065,
  211.9: 0.485045,
  211.95: 0.485025,
  212: 0.485005,
  212.05: 0.484985,
  212.1: 0.484965,
  212.15: 0.484945,
  212.2: 0.484925,
  212.25: 0.484905,
  212.3: 0.484885,
  212.35: 0.484865,
  212.4: 0.484845,
  212.45: 0.484825,
  212.5: 0.484805,
  212.55: 0.484785,
  212.6: 0.484765,
  212.65: 0.484745,
  212.7: 0.484725,
  212.75: 0.484705,
  212.8: 0.484685,
  212.85: 0.484665,
  212.9: 0.484645,
  212.95: 0.484625,
  213: 0.484605,
  213.05: 0.484585,
  213.1: 0.484565,
  213.15: 0.484545,
  213.2: 0.484525,
  213.25: 0.484505,
  213.3: 0.484485,
  213.35: 0.484465,
  213.4: 0.484445,
  213.45: 0.484425,
  213.5: 0.484405,
  213.55: 0.484385,
  213.6: 0.484365,
  213.65: 0.484345,
  213.7: 0.484325,
  213.75: 0.484305,
  213.8: 0.484285,
  213.85: 0.484265,
  213.9: 0.484245,
  213.95: 0.484225,
  214: 0.484205,
  214.05: 0.484185,
  214.1: 0.484165,
  214.15: 0.484145,
  214.2: 0.484125,
  214.25: 0.484105,
  214.3: 0.484085,
  214.35: 0.484065,
  214.4: 0.484045,
  214.45: 0.484025,
  214.5: 0.484005,
  214.55: 0.483985,
  214.6: 0.483965,
  214.65: 0.483945,
  214.7: 0.483925,
  214.75: 0.483905,
  214.8: 0.483885,
  214.85: 0.483865,
  214.9: 0.483845,
  214.95: 0.483825,
  215: 0.48381,
  215.05: 0.483795,
  215.1: 0.48378,
  215.15: 0.483765,
  215.2: 0.48375,
  215.25: 0.483735,
  215.3: 0.48372,
  215.35: 0.483705,
  215.4: 0.48369,
  215.45: 0.483675,
  215.5: 0.48366,
  215.55: 0.483645,
  215.6: 0.48363,
  215.65: 0.483615,
  215.7: 0.4836,
  215.75: 0.483585,
  215.8: 0.48357,
  215.85: 0.483555,
  215.9: 0.48354,
  215.95: 0.483525,
  216: 0.48351,
  216.05: 0.483495,
  216.1: 0.48348,
  216.15: 0.483465,
  216.2: 0.48345,
  216.25: 0.483435,
  216.3: 0.48342,
  216.35: 0.483405,
  216.4: 0.48339,
  216.45: 0.483375,
  216.5: 0.48336,
  216.55: 0.483345,
  216.6: 0.48333,
  216.65: 0.483315,
  216.7: 0.4833,
  216.75: 0.483285,
  216.8: 0.48327,
  216.85: 0.483255,
  216.9: 0.48324,
  216.95: 0.483225,
  217: 0.48321,
  217.05: 0.483195,
  217.1: 0.48318,
  217.15: 0.483165,
  217.2: 0.48315,
  217.25: 0.483135,
  217.3: 0.48312,
  217.35: 0.483105,
  217.4: 0.48309,
  217.45: 0.483075,
  217.5: 0.48306,
  217.55: 0.483045,
  217.6: 0.48303,
  217.65: 0.483015,
  217.7: 0.483,
  217.75: 0.482985,
  217.8: 0.48297,
  217.85: 0.482955,
  217.9: 0.48294,
  217.95: 0.482925,
  218: 0.48291,
  218.05: 0.482895,
  218.1: 0.48288,
  218.15: 0.482865,
  218.2: 0.48285,
  218.25: 0.482835,
  218.3: 0.48282,
  218.35: 0.482805,
  218.4: 0.48279,
  218.45: 0.482775,
  218.5: 0.48276,
  218.55: 0.482745,
  218.6: 0.48273,
  218.65: 0.482715,
  218.7: 0.4827,
  218.75: 0.482685,
  218.8: 0.48267,
  218.85: 0.482655,
  218.9: 0.48264,
  218.95: 0.482625,
  219: 0.482605,
  219.05: 0.48259,
  219.1: 0.482575,
  219.15: 0.48256,
  219.2: 0.482545,
  219.25: 0.48253,
  219.3: 0.482515,
  219.35: 0.4825,
  219.4: 0.482485,
  219.45: 0.48247,
  219.5: 0.482455,
  219.55: 0.48244,
  219.6: 0.482425,
  219.65: 0.48241,
  219.7: 0.482395,
  219.75: 0.48238,
  219.8: 0.482365,
  219.85: 0.48235,
  219.9: 0.482335,
  219.95: 0.48232,
  220: 0.482305,
  220.05: 0.48229,
  220.1: 0.482275,
  220.15: 0.48226,
  220.2: 0.482245,
  220.25: 0.48223,
  220.3: 0.482215,
  220.35: 0.4822,
  220.4: 0.482185,
  220.45: 0.48217,
  220.5: 0.482155,
  220.55: 0.48214,
  220.6: 0.482125,
  220.65: 0.48211,
  220.7: 0.482095,
  220.75: 0.48208,
  220.8: 0.482065,
  220.85: 0.48205,
  220.9: 0.482035,
  220.95: 0.48202,
  221: 0.482005,
  221.05: 0.48199,
  221.1: 0.481975,
  221.15: 0.48196,
  221.2: 0.481945,
  221.25: 0.48193,
  221.3: 0.481915,
  221.35: 0.4819,
  221.4: 0.481885,
  221.45: 0.48187,
  221.5: 0.481855,
  221.55: 0.48184,
  221.6: 0.481825,
  221.65: 0.48181,
  221.7: 0.481795,
  221.75: 0.48178,
  221.8: 0.481765,
  221.85: 0.48175,
  221.9: 0.481735,
  221.95: 0.48172,
  222: 0.481705,
  222.05: 0.48169,
  222.1: 0.481675,
  222.15: 0.48166,
  222.2: 0.481645,
  222.25: 0.48163,
  222.3: 0.481615,
  222.35: 0.4816,
  222.4: 0.481585,
  222.45: 0.48157,
  222.5: 0.481555,
  222.55: 0.48154,
  222.6: 0.481525,
  222.65: 0.48151,
  222.7: 0.481495,
  222.75: 0.48148,
  222.8: 0.481465,
  222.85: 0.48145,
  222.9: 0.481435,
  222.95: 0.48142,
  223: 0.481405,
  223.05: 0.48139,
  223.1: 0.481375,
  223.15: 0.48136,
  223.2: 0.481345,
  223.25: 0.48133,
  223.3: 0.481315,
  223.35: 0.4813,
  223.4: 0.481285,
  223.45: 0.48127,
  223.5: 0.481255,
  223.55: 0.48124,
  223.6: 0.481225,
  223.65: 0.48121,
  223.7: 0.481195,
  223.75: 0.48118,
  223.8: 0.481165,
  223.85: 0.48115,
  223.9: 0.481135,
  223.95: 0.48112,
  224: 0.481105,
  224.05: 0.48109,
  224.1: 0.481075,
  224.15: 0.48106,
  224.2: 0.481045,
  224.25: 0.48103,
  224.3: 0.481015,
  224.35: 0.481,
  224.4: 0.480985,
  224.45: 0.48097,
  224.5: 0.480955,
  224.55: 0.48094,
  224.6: 0.480925,
  224.65: 0.480905,
  224.7: 0.48089,
  224.75: 0.480875,
  224.8: 0.48086,
  224.85: 0.480845,
  224.9: 0.48083,
  224.95: 0.480815,
  225: 0.480805,
  225.05: 0.480795,
  225.1: 0.480785,
  225.15: 0.480775,
  225.2: 0.480765,
  225.25: 0.480755,
  225.3: 0.480745,
  225.35: 0.480735,
  225.4: 0.480725,
  225.45: 0.480715,
  225.5: 0.480705,
  225.55: 0.480695,
  225.6: 0.480685,
  225.65: 0.480675,
  225.7: 0.480665,
  225.75: 0.480655,
  225.8: 0.480645,
  225.85: 0.480635,
  225.9: 0.480625,
  225.95: 0.480615,
  226: 0.480605,
  226.05: 0.480595,
  226.1: 0.480585,
  226.15: 0.480575,
  226.2: 0.480565,
  226.25: 0.480555,
  226.3: 0.480545,
  226.35: 0.480535,
  226.4: 0.480525,
  226.45: 0.480515,
  226.5: 0.480505,
  226.55: 0.480495,
  226.6: 0.480485,
  226.65: 0.480475,
  226.7: 0.480465,
  226.75: 0.480455,
  226.8: 0.480445,
  226.85: 0.480435,
  226.9: 0.480425,
  226.95: 0.480415,
  227: 0.480405,
  227.05: 0.480395,
  227.1: 0.480385,
  227.15: 0.480375,
  227.2: 0.480365,
  227.25: 0.480355,
  227.3: 0.480345,
  227.35: 0.480335,
  227.4: 0.480325,
  227.45: 0.480315,
  227.5: 0.480305,
  227.55: 0.480295,
  227.6: 0.480285,
  227.65: 0.480275,
  227.7: 0.480265,
  227.75: 0.480255,
  227.8: 0.480245,
  227.85: 0.480235,
  227.9: 0.480225,
  227.95: 0.480215,
  228: 0.480205,
  228.05: 0.480195,
  228.1: 0.480185,
  228.15: 0.480175,
  228.2: 0.480165,
  228.25: 0.480155,
  228.3: 0.480145,
  228.35: 0.480135,
  228.4: 0.480125,
  228.45: 0.480115,
  228.5: 0.480105,
  228.55: 0.480095,
  228.6: 0.480085,
  228.65: 0.480075,
  228.7: 0.480065,
  228.75: 0.480055,
  228.8: 0.480045,
  228.85: 0.480035,
  228.9: 0.480025,
  228.95: 0.480015,
  229: 0.480005,
  229.05: 0.479095,
  229.1: 0.479085,
  229.15: 0.479075,
  229.2: 0.479065,
  229.25: 0.479055,
  229.3: 0.479045,
  229.35: 0.479035,
  229.4: 0.479025,
  229.45: 0.479015,
  229.5: 0.479005,
  229.55: 0.478995,
  229.6: 0.478985,
  229.65: 0.478975,
  229.7: 0.478965,
  229.75: 0.478955,
  229.8: 0.478945,
  229.85: 0.478935,
  229.9: 0.478925,
  229.95: 0.478915,
  230: 0.478905,
  230.05: 0.478895,
  230.1: 0.478885,
  230.15: 0.478875,
  230.2: 0.478865,
  230.25: 0.478855,
  230.3: 0.478845,
  230.35: 0.478835,
  230.4: 0.478825,
  230.45: 0.478815,
  230.5: 0.478805,
  230.55: 0.478795,
  230.6: 0.478785,
  230.65: 0.478775,
  230.7: 0.478765,
  230.75: 0.478755,
  230.8: 0.478745,
  230.85: 0.478735,
  230.9: 0.478725,
  230.95: 0.478715,
  231: 0.478705,
  231.05: 0.478695,
  231.1: 0.478685,
  231.15: 0.478675,
  231.2: 0.478665,
  231.25: 0.478655,
  231.3: 0.478645,
  231.35: 0.478635,
  231.4: 0.478625,
  231.45: 0.478615,
  231.5: 0.478605,
  231.55: 0.478595,
  231.6: 0.478585,
  231.65: 0.478575,
  231.7: 0.478565,
  231.75: 0.478555,
  231.8: 0.478545,
  231.85: 0.478535,
  231.9: 0.478525,
};
