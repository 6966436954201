import get from "lodash/get";
import includes from "lodash/includes";
import { Attempt, AttemptNumber, LiftName, Meet } from "types";

export const powerliftingLifts: LiftName[] = ["squat", "bench", "dead"];
export const powerliftingAttempts: AttemptNumber[] = ["1", "2", "3"]; // doesn't include 4th attempts, these are what we create initially and what count towards total

export const isLbsMeet = function (meet: Meet) {
  return get(meet, "units") === "LBS";
};

export const isKgMeet = function (meet: Meet) {
  return get(meet, "units") !== "LBS";
};

export const getMeetUnits = function (meet: Meet) {
  return isKgMeet(meet) ? "kg" : "lbs";
};

export const unitedStatesMeet = function (meet: Meet) {
  if (!meet.federation) {
    return true;
  }

  if (
    includes(
      [
        "POWERLIFTING_AMERICA",
        "USAPL",
        "USPA",
        "USSF",
        "APA",
        "WRPF",
        "PLU",
        "USSF",
        "100RAW",
        "OTHER",
      ],
      meet.federation
    )
  ) {
    return true;
  }

  return false;
};

export const canadaMeet = function (meet: Meet) {
  if (includes(["CPU"], meet.federation)) {
    return true;
  }

  return false;
};

export const getBenchLabel = function (meet: Meet) {
  if (meet.federation === "USSF") {
    return "Press";
  }

  return "Bench";
};

export const getStateLabel = function (meet: Meet) {
  if (canadaMeet(meet)) {
    return "Province";
  }

  return "State";
};

export const isPaid = function (meet: Meet) {
  return (
    !meet.payment_status ||
    meet.payment_status === "FREE" ||
    meet.payment_status === "PAID"
  );
};

export const getDateFormat = function (meet: Meet) {
  return get(meet, "dateFormat", "MM/DD/YYYY") || "MM/DD/YYYY";
};

export const getAllAttemptsForMeet = (meet: Meet) => {
  const allAttempts: Attempt[] = [];
  for (const lifter of Object.values(meet.lifters)) {
    for (const liftName of powerliftingLifts) {
      const attempts = get(lifter, ["lifts", liftName]);
      for (const attempt of Object.values(attempts)) {
        allAttempts.push(attempt);
      }
    }
  }

  return allAttempts;
};
