import React from "react";
import classNames from "classnames";
import SortableHeaderCell from "components/SortableHeaderCell";
import { Column } from "components/table/Table";
import get from "lodash/get";

const DefaultCell = ({
  data,
  column,
  style,
}: {
  data: any;
  column: Column;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return <SortableHeaderCell style={style} column={column} />;
  } else {
    const value = get(lifter, column.key);
    return (
      <div style={style} className="ellipsis table-cell-inner">
        {value && (
          <div
            className={classNames({
              error: get(lifter, `${column.key}Match`) === false,
            })}
          >
            {value}
          </div>
        )}
        {!value && <span className="error">MISSING</span>}
      </div>
    );
  }
};

export default DefaultCell;
