import React from "react";
import { useMeet } from "util/useMeet";
import RegistrationNew from "./RegistrationNew";
import RegistrationOld from "./RegistrationOld";

const RegistrationIndex = () => {
  const meet = useMeet();
  if (meet.entryConfig.newPaymentFlow) {
    return <RegistrationNew />;
  }

  return <RegistrationOld />;
};

export default RegistrationIndex;
