import React from "react";

import PouchInput from "components/PouchInput";
import PouchColorPicker from "components/PouchColorPicker";
import { Meet } from "types";
import split from "lodash/split";
import last from "lodash/last";
import get from "lodash/get";

const DisplayConfig = ({ meet }: { meet: Meet }) => {
  const cleanURLString = (url: string | number | undefined) => {
    const parts = split(url as string, "?");
    return last(parts);
  };

  return (
    <div className="entry-config">
      <div className="registration-title">View Configuration</div>
      <div className="entry-row">
        <label>Display Image URL:</label>
        <div className="input-wrapper">
          <PouchInput
            type="text"
            meetId={meet._id}
            documentId={meet._id}
            name="displayBackgroundImageUrl"
            value={get(meet, "displayBackgroundImageUrl")}
            placeholder="https://example.com/image.png"
          />
        </div>
      </div>
      <div className="entry-row">
        <label>Display Font Color:</label>
        <div className="input-wrapper">
          <PouchColorPicker
            meet={meet}
            document={meet}
            name="displayFontColor"
            value={get(meet, "displayFontColor")}
          />
        </div>
      </div>
      <div className="entry-row">
        <label>Display Background Color:</label>
        <div className="input-wrapper">
          <PouchColorPicker
            meet={meet}
            document={meet}
            name="displayBackgroundColor"
            value={get(meet, "displayBackgroundColor")}
          />
        </div>
      </div>
      <div className="entry-row">
        <label>Display Default View URL:</label>
        <div className="input-wrapper">
          <PouchInput
            type="text"
            meetId={meet._id}
            documentId={meet._id}
            name="displayDefaultURL"
            value={get(meet, "displayDefaultURL")}
            beforeSave={cleanURLString}
          />
        </div>
      </div>
      <div className="entry-row">
        <label>Board Default View URL:</label>
        <div className="input-wrapper">
          <PouchInput
            type="text"
            meetId={meet._id}
            documentId={meet._id}
            name="boardDefaultURL"
            value={get(meet, "boardDefaultURL")}
            beforeSave={cleanURLString}
          />
        </div>
      </div>
    </div>
  );
};

export default DisplayConfig;
