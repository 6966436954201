import get from "lodash/get";
import React from "react";

const CheckboxCell = ({
  data,
  column,
  style,
}: {
  data: any;
  column: any;
  style: any;
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    const value = get(lifter, column.key);

    return (
      <div style={style} className="table-cell-inner">
        {value ? "true" : "false"}
      </div>
    );
  }
};

export default CheckboxCell;
