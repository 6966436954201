import Papa from "papaparse";
import get from "lodash/get";
import snakeCase from "lodash/snakeCase";
import each from "lodash/each";
import find from "lodash/find";
import cloneDeep from "lodash/cloneDeep";
import join from "lodash/join";
import map from "lodash/map";
import toLower from "lodash/toLower";
import sortBy from "lodash/sortBy";
import download from "downloadjs";
import {
  getBodyWeightAdjustedTotal,
  getAgeAdjustedTotal,
  getBodyWeightAndAgeAdjustedTotal,
  getTotal,
  getSubtotal,
  getBestLift,
  getDivisionDoc,
  getLifterDeclaredAwardsWeightClass,
  getRealAge,
  getIpfPoints,
  getIpfAndAgePoints,
  getDotsPoints,
  getDotsAndAgePoints,
  getGlossbrennerPoints,
  getGlossbrennerAndAgePoints,
} from "./lifterHelper";
import { federationConfigs } from "./federations";
import { getMeetUnits, getBenchLabel, getStateLabel } from "./meetHelper";
import { wilksCoef, ageCoef } from "./coefficients";
import { standardDivisionAge } from "./standardDivisions";
import { exportUSAPLResults } from "./federations/usapl";
import { exportUSSFLResults } from "./federations/ussf";
import { exportUSPAResults } from "./federations/uspa";
import { exportIPFResults } from "./federations/ipf";
import { exportCPUResults } from "./federations/cpu";
import { exportAPAResults } from "./federations/apa";
import { exportSSFResults } from "./federations/ssf";
import { exportMMPResults } from "./federations/mmp";
import { exportRPSResults } from "./federations/rps";
import { export100RawResults } from "./federations/oneHundredRaw";
import { exportIPAResults } from "./federations/ipa";
import { exportWrpfResults } from "./federations/wrpf";

import {
  getPlatformOptions,
  getRawOrEquippedOptions,
  getGenderOptions,
  getStateOptions,
  getFlightOptions,
  getSessionOptions,
  getScoreByOptions,
} from "./options";
import {
  AttemptNumber,
  Division,
  LiftName,
  Lifter,
  Meet,
  RefPosition,
} from "types";
import { exportPowerliftingAmericaResults } from "./federations/powerliftingAmerica";

export const presentStringAsDownload = function (
  csvString: string,
  meet: Meet,
  type: string
) {
  let fileName = `${type}.csv`;
  fileName = snakeCase(meet.name) + "_" + fileName;

  download(
    new Blob([csvString], { type: "text/csv;charset=utf-8" }),
    fileName,
    "text/csv"
  );
};

export const getAttemptDisplay = function (
  lifter: Lifter,
  liftName: LiftName,
  attemptNumber: AttemptNumber
) {
  const weight = get(lifter, ["lifts", liftName, attemptNumber, "weight"]);
  const result = get(lifter, ["lifts", liftName, attemptNumber, "result"]);

  if (get(lifter, ["division", "lifts", liftName])) {
    if (result === "good" || result === "bad") {
      if (result === "bad") {
        return `-${weight}`;
      }

      return String(weight);
    }
  }

  return "";
};

export const getBestAttemptDisplay = function (
  lifter: Lifter,
  liftName: LiftName
) {
  if (get(lifter, ["division", "lifts", liftName])) {
    return get(getBestLift(liftName, lifter), "weight");
  }
};

const getSubtotalDisplay = function (lifter: Lifter, meet: Meet) {
  if (
    get(lifter, ["division", "lifts", "squat"]) &&
    get(lifter, ["division", "lifts", "bench"])
  ) {
    return getSubtotal(lifter, meet);
  }

  return "";
};

const getRefDecision = function (
  lifter: Lifter,
  liftName: LiftName,
  attemptNumber: AttemptNumber,
  refPosition: RefPosition
) {
  const decision = get(lifter, [
    "lifts",
    liftName,
    attemptNumber,
    "decisions",
    refPosition,
    "decision",
  ]);
  const cards = get(lifter, [
    "lifts",
    liftName,
    attemptNumber,
    "decisions",
    refPosition,
    "cards",
  ]);
  const redCard = get(cards, "red");
  const blueCard = get(cards, "blue");
  const yellowCard = get(cards, "yellow");

  let formattedDecision = decision;
  if (redCard) {
    formattedDecision += ", red";
  }
  if (blueCard) {
    formattedDecision += ", blue";
  }
  if (yellowCard) {
    formattedDecision += ", yellow";
  }

  return formattedDecision;
};

export const exportAwardsResults = function (
  meet: Meet,
  dataArray: any,
  isLocal: boolean
) {
  if (!meet.federation) {
    return;
  }

  const federationConfig = get(
    federationConfigs,
    meet.federation,
    get(federationConfigs, "OTHER")
  );
  const fourthAttempts = federationConfig.fourthAttempts;
  const csvObject = [];
  each(dataArray, (lifter, index) => {
    if (!lifter || lifter.row === "title" || lifter.row === "header") {
      return;
    }

    const division = lifter.division;
    const weightClass = lifter.weightClass;
    const place = lifter.place;
    const row: Record<string, string | number | boolean | null | undefined> = {
      name: lifter.name,
      gender: lifter.gender,
      rawOrEquipped: division.rawOrEquipped,
      team: lifter.team,
      lot: lifter.lot,
      state: lifter.state,
      country: lifter.country,
      platform: findOptionLabel(
        getPlatformOptions(lifter, meet),
        lifter.platformId
      ),
      session: findOptionLabel(getSessionOptions(), lifter.session),
      flight: findOptionLabel(getFlightOptions(), lifter.flight),
      awardsDivision: division.name,
      bodyWeight: lifter.bodyWeight,
      weightClass: weightClass.name,
      wilksCoef: wilksCoef(lifter, meet),
      exactAge: getRealAge(lifter, meet),
      divisionBasedAge: standardDivisionAge(lifter, meet),
      ageCoef: ageCoef(lifter, meet),
      squat1: getAttemptDisplay(lifter, "squat", "1"),
      squat2: getAttemptDisplay(lifter, "squat", "2"),
      squat3: getAttemptDisplay(lifter, "squat", "3"),
      squat4: getAttemptDisplay(lifter, "squat", "4"),
      bestSquat: getBestAttemptDisplay(lifter, "squat"),
      bench1: getAttemptDisplay(lifter, "bench", "1"),
      bench2: getAttemptDisplay(lifter, "bench", "2"),
      bench3: getAttemptDisplay(lifter, "bench", "3"),
      bench4: getAttemptDisplay(lifter, "bench", "4"),
      bestBench: getBestAttemptDisplay(lifter, "bench"),
      subtotal: getSubtotalDisplay(lifter, meet),
      dead1: getAttemptDisplay(lifter, "dead", "1"),
      dead2: getAttemptDisplay(lifter, "dead", "2"),
      dead3: getAttemptDisplay(lifter, "dead", "3"),
      dead4: getAttemptDisplay(lifter, "dead", "4"),
      bestDead: getBestAttemptDisplay(lifter, "dead"),
      total: getTotal(lifter, division._id, meet),
      dotsPoints: getDotsPoints(lifter, division._id, meet),
      dotsAgePoints: getDotsAndAgePoints(lifter, division._id, meet),
      wilksPoints: getBodyWeightAdjustedTotal(lifter, division._id, meet),
      agePoints: getAgeAdjustedTotal(lifter, division._id, meet),
      wilksAgePoints: getBodyWeightAndAgeAdjustedTotal(
        lifter,
        division._id,
        meet
      ),
      ipfPoints: getIpfPoints(lifter, division._id, meet),
      ipfAgePoints: getIpfAndAgePoints(lifter, division._id, meet),
      glossbrennerPoints: getGlossbrennerPoints(lifter, division._id, meet),
      glossbrennerAgePoints: getGlossbrennerAndAgePoints(
        lifter,
        division._id,
        meet
      ),
      place: place,
      squat1LeftRefDecision: getRefDecision(lifter, "squat", "1", "left"),
      squat1HeadRefDecision: getRefDecision(lifter, "squat", "1", "head"),
      squat1RightRefDecision: getRefDecision(lifter, "squat", "1", "right"),
      squat2LeftRefDecision: getRefDecision(lifter, "squat", "2", "left"),
      squat2HeadRefDecision: getRefDecision(lifter, "squat", "2", "head"),
      squat2RightRefDecision: getRefDecision(lifter, "squat", "2", "right"),
      squat3LeftRefDecision: getRefDecision(lifter, "squat", "3", "left"),
      squat3HeadRefDecision: getRefDecision(lifter, "squat", "3", "head"),
      squat3RightRefDecision: getRefDecision(lifter, "squat", "3", "right"),
      squat4LeftRefDecision: getRefDecision(lifter, "squat", "4", "left"),
      squat4HeadRefDecision: getRefDecision(lifter, "squat", "4", "head"),
      squat4RightRefDecision: getRefDecision(lifter, "squat", "4", "right"),
      bench1LeftRefDecision: getRefDecision(lifter, "bench", "1", "left"),
      bench1HeadRefDecision: getRefDecision(lifter, "bench", "1", "head"),
      bench1RightRefDecision: getRefDecision(lifter, "bench", "1", "right"),
      bench2LeftRefDecision: getRefDecision(lifter, "bench", "2", "left"),
      bench2HeadRefDecision: getRefDecision(lifter, "bench", "2", "head"),
      bench2RightRefDecision: getRefDecision(lifter, "bench", "2", "right"),
      bench3LeftRefDecision: getRefDecision(lifter, "bench", "3", "left"),
      bench3HeadRefDecision: getRefDecision(lifter, "bench", "3", "head"),
      bench3RightRefDecision: getRefDecision(lifter, "bench", "3", "right"),
      bench4LeftRefDecision: getRefDecision(lifter, "bench", "4", "left"),
      bench4HeadRefDecision: getRefDecision(lifter, "bench", "4", "head"),
      bench4RightRefDecision: getRefDecision(lifter, "bench", "4", "right"),
      dead1LeftRefDecision: getRefDecision(lifter, "dead", "1", "left"),
      dead1HeadRefDecision: getRefDecision(lifter, "dead", "1", "head"),
      dead1RightRefDecision: getRefDecision(lifter, "dead", "1", "right"),
      dead2LeftRefDecision: getRefDecision(lifter, "dead", "2", "left"),
      dead2HeadRefDecision: getRefDecision(lifter, "dead", "2", "head"),
      dead2RightRefDecision: getRefDecision(lifter, "dead", "2", "right"),
      dead3LeftRefDecision: getRefDecision(lifter, "dead", "3", "left"),
      dead3HeadRefDecision: getRefDecision(lifter, "dead", "3", "head"),
      dead3RightRefDecision: getRefDecision(lifter, "dead", "3", "right"),
      dead4LeftRefDecision: getRefDecision(lifter, "dead", "4", "left"),
      dead4HeadRefDecision: getRefDecision(lifter, "dead", "4", "head"),
      dead4RightRefDecision: getRefDecision(lifter, "dead", "4", "right"),
    };

    if (isLocal) {
      row.memberNumber = get(lifter, "restricted.memberNumber");
      row.birthDate = lifter.birthDate;
    }

    csvObject.push(row);
  });

  const header: Record<string, string> = {
    name: "Name",
    gender: "Gender",
    rawOrEquipped: "Raw/Equipped",
    team: "Team",
    lot: "Lot",
    state: "State/Province",
    country: "Country",
    platform: "Platform",
    session: "Session",
    flight: "Flight",
    awardsDivision: "Awards Division",
    bodyWeight: `Body Weight (${getMeetUnits(meet)})`,
    weightClass: "Weight Class",
    wilksCoef: "Wilks Coef",
    exactAge: "Exact Age",
    divisionBasedAge: "Division Based Age",
    ageCoef: "Age Coef",
    squat1: "Squat 1",
    squat2: "Squat 2",
    squat3: "Squat 3",
    squat4: "Squat 4",
    bestSquat: "Best Squat",
    bench1: `${getBenchLabel(meet)} 1`,
    bench2: `${getBenchLabel(meet)} 2`,
    bench3: `${getBenchLabel(meet)} 3`,
    bench4: `${getBenchLabel(meet)} 4`,
    bestBench: `Best ${getBenchLabel(meet)}`,
    subtotal: "Subtotal",
    dead1: "Deadlift 1",
    dead2: "Deadlift 2",
    dead3: "Deadlift 3",
    dead4: "Deadlift 4",
    bestDead: "Best Deadlift",
    total: "Total",
    dotsPoints: "Dots Points",
    dotsAgePoints: "Dots & Age Points",
    wilksPoints: "Wilks Points",
    agePoints: "Age Points",
    wilksAgePoints: "Wilks & Age Points",
    ipfPoints: "IPF Points",
    ipfAgePoints: "IPF & Age Points",
    glossbrennerPoints: "Glossbrenner Points",
    glossbrennerAgePoints: "Glossbrenner & Age Points",
    place: "Place",
    squat1LeftRefDecision: "S1LRef",
    squat1HeadRefDecision: "S1HRef",
    squat1RightRefDecision: "S1RRef",
    squat2LeftRefDecision: "S2LRef",
    squat2HeadRefDecision: "S2HRef",
    squat2RightRefDecision: "S2RRef",
    squat3LeftRefDecision: "S3LRef",
    squat3HeadRefDecision: "S3HRef",
    squat3RightRefDecision: "S3RRef",
    squat4LeftRefDecision: "S4LRef",
    squat4HeadRefDecision: "S4HRef",
    squat4RightRefDecision: "S4RRef",
    bench1LeftRefDecision: "B1LRef",
    bench1HeadRefDecision: "B1HRef",
    bench1RightRefDecision: "B1RRef",
    bench2LeftRefDecision: "B2LRef",
    bench2HeadRefDecision: "B2HRef",
    bench2RightRefDecision: "B2RRef",
    bench3LeftRefDecision: "B3LRef",
    bench3HeadRefDecision: "B3HRef",
    bench3RightRefDecision: "B3RRef",
    bench4LeftRefDecision: "B4LRef",
    bench4HeadRefDecision: "B4HRef",
    bench4RightRefDecision: "B4RRef",
    dead1LeftRefDecision: "D1LRef",
    dead1HeadRefDecision: "D1HRef",
    dead1RightRefDecision: "D1RRef",
    dead2LeftRefDecision: "D2LRef",
    dead2HeadRefDecision: "D2HRef",
    dead2RightRefDecision: "D2RRef",
    dead3LeftRefDecision: "D3LRef",
    dead3HeadRefDecision: "D3HRef",
    dead3RightRefDecision: "D3RRef",
    dead4LeftRefDecision: "D4LRef",
    dead4HeadRefDecision: "D4HRef",
    dead4RightRefDecision: "D4RRef",
  };

  if (!fourthAttempts) {
    delete header.squat4;
    delete header.bench4;
    delete header.dead4;
    delete header.squat4LeftRefDecision;
    delete header.squat4HeadRefDecision;
    delete header.squat4RightRefDecision;

    delete header.bench4LeftRefDecision;
    delete header.bench4HeadRefDecision;
    delete header.bench4RightRefDecision;

    delete header.dead4LeftRefDecision;
    delete header.dead4HeadRefDecision;
    delete header.dead4RightRefDecision;
  }

  if (isLocal) {
    header.memberNumber = "Member #";
    header.birthDate = "Birth Date";
  }

  csvObject.unshift(header);

  const csvString = Papa.unparse(csvObject, { quotes: true, header: false });

  presentStringAsDownload(csvString, meet, "awards_results");
};

const getFederationExportFunction = function (meet: Meet) {
  switch (meet.federation) {
    case "RPS":
      return exportRPSResults;
    case "APA":
      return exportAPAResults;
    case "USAPL":
      return exportUSAPLResults;
    case "CPU":
      return exportCPUResults;
    case "IPF":
      return exportIPFResults;
    case "POWERLIFTING_AMERICA":
      return exportPowerliftingAmericaResults;
    case "USSF":
      return exportUSSFLResults;
    case "USPA":
      return exportUSPAResults;
    case "SSF":
      return exportSSFResults;
    case "MMP":
      return exportMMPResults;
    case "100RAW":
      return export100RawResults;
    case "IPA":
      return exportIPAResults;
    case "WRPF":
      return exportWrpfResults;
    case "PLU":
      return exportWrpfResults;
    default:
      return exportUSAPLResults;
  }
};

export const exportFederationResults = function (meet: Meet, dataArray: any) {
  const csvObject = getFederationExportFunction(meet)(meet, dataArray);

  const csvString = Papa.unparse(csvObject, { quotes: true, header: false });
  presentStringAsDownload(csvString, meet, `${meet.federation || ""}_results`);
};

const findOptionLabel = function (
  options: { value: string | number; label: string | number | undefined }[],
  id: string | number | undefined | null
) {
  return get(
    find(options, (i) => i.value === id),
    "label"
  );
};

export const exportLifters = function (
  meet: Meet,
  lifters: Record<string, Lifter>,
  fileOverride?: "import"
) {
  const csvObject = [];

  each(lifters, (lifter, index) => {
    try {
      const meetItems = meet.entryConfig.items;

      const additionalItems = join(
        map(lifter.restricted?.items, (itemId, index) => {
          const item = find(meetItems, { id: itemId });
          return get(item, "name");
        }),
        ", "
      );

      const divisions = cloneDeep(lifter.divisions);
      const firstLifterDivision = divisions.pop();
      const divisionDoc = getDivisionDoc(firstLifterDivision?.divisionId, meet);
      const weightClassDoc = getLifterDeclaredAwardsWeightClass(
        divisionDoc,
        firstLifterDivision
      );

      const lifterRow: Record<
        string,
        string | number | boolean | undefined | null
      > = {
        name: lifter.name,
        team: lifter.team,
        lot: lifter.lot,
        platform: findOptionLabel(
          getPlatformOptions(lifter, meet),
          lifter.platformId
        ),
        session: findOptionLabel(getSessionOptions(), lifter.session),
        flight: findOptionLabel(getFlightOptions(), lifter.flight),
        birthDate: lifter.birthDate,
        memberNumber: get(lifter, "restricted.memberNumber"),
        gender: findOptionLabel(getGenderOptions(lifter, meet), lifter.gender),
        rawOrEquipped: findOptionLabel(
          getRawOrEquippedOptions(null, meet),
          firstLifterDivision?.rawOrEquipped
        ),
        division: get(divisionDoc, "name"),
        declaredAwardsWeightClass: get(weightClassDoc, "name"),
        bodyWeight: lifter.bodyWeight,
        squatRackHeight: lifter.squatRackHeight,
        benchRackHeight: lifter.benchRackHeight,
        squat1: get(lifter, ["lifts", "squat", "1", "weight"]),
        bench1: get(lifter, ["lifts", "bench", "1", "weight"]),
        dead1: get(lifter, ["lifts", "dead", "1", "weight"]),
        wasDrugTested: lifter.wasDrugTested,
        phoneNumber: get(lifter, "restricted.phoneNumber"),
        country: get(lifter, "country"),
        streetAddress: get(lifter, "restricted.streetAddress"),
        city: get(lifter, "restricted.city"),
        state: findOptionLabel(getStateOptions(null, meet), lifter.state),
        zipCode: get(lifter, "restricted.zipCode"),
        email: get(lifter, "restricted.email"),
        emergencyContactName: get(lifter, "restricted.emergencyContactName"),
        emergencyContactPhoneNumber: get(
          lifter,
          "restricted.emergencyContactPhoneNumber"
        ),
        additionalItems: additionalItems,
      };

      each(meet.entryConfig.customQuestions, (question) => {
        lifterRow[question.id] = get(lifter, ["restricted", question.id]);
      });

      csvObject.push(lifterRow);

      each(divisions, (lifterDivision) => {
        const divisionDoc = getDivisionDoc(lifterDivision.divisionId, meet);
        const weightClassDoc = getLifterDeclaredAwardsWeightClass(
          divisionDoc,
          lifterDivision
        );
        csvObject.push({
          name: "",
          team: "",
          lot: "",
          platform: "",
          session: "",
          flight: "",
          birthDate: "",
          memberNumber: "",
          gender: "",
          rawOrEquipped: findOptionLabel(
            getRawOrEquippedOptions(null, meet),
            lifterDivision.rawOrEquipped
          ),
          division: divisionDoc?.name,
          declaredAwardsWeightClass:
            "name" in weightClassDoc ? weightClassDoc.name : "",
          bodyWeight: "",
          squatRackHeight: "",
          benchRackHeight: "",
          squat1: "",
          bench1: "",
          dead1: "",
          wasDrugTested: "",
          phoneNumber: "",
          country: "",
          streetAddress: "",
          city: "",
          state: "",
          zipCode: "",
          email: "",
          emergencyContactName: "",
          emergencyContactPhoneNumber: "",
          additionalItems: "",
        });
      });
    } catch (e) {
      console.log(e);
      console.log("Failed to export lifter", lifter);
    }
  });

  const headerRow: Record<string, string> = {
    name: "name",
    team: "team",
    lot: "lot",
    platform: "platform",
    session: "session",
    flight: "flight",
    birthDate: "birthDate",
    memberNumber: "memberNumber",
    gender: "gender",
    rawOrEquipped: "rawOrEquipped",
    division: "division",
    declaredAwardsWeightClass: "declaredAwardsWeightClass",
    bodyWeight: "bodyWeight",
    squatRackHeight: "squatRackHeight",
    benchRackHeight: `${toLower(getBenchLabel(meet))}RackHeight`,
    squat1: "squat1",
    bench1: `${toLower(getBenchLabel(meet))}1`,
    dead1: "dead1",
    wasDrugTested: "wasDrugTested",
    phoneNumber: "phoneNumber",
    country: "country",
    streetAddress: "streetAddress",
    city: "city",
    state: toLower(getStateLabel(meet)),
    zipCode: "zipCode",
    email: "email",
    emergencyContactName: "emergencyContactName",
    emergencyContactPhoneNumber: "emergencyContactPhoneNumber",
    additionalItems: "additionalItems",
  };

  each(meet.entryConfig.customQuestions, (question) => {
    headerRow[question.id] = question.text;
  });

  csvObject.unshift(headerRow);

  const csvString = Papa.unparse(csvObject, { quotes: true, header: false });
  const fileName =
    fileOverride === "import" ? "lifters_import_template" : "lifters_export";
  presentStringAsDownload(csvString, meet, fileName);
};

export const exportDivisions = function (
  meet: Meet,
  divisions: Record<string, Division>,
  fileOverride?: "import"
) {
  const csvObject = [];

  each(divisions, (division) => {
    const weightClasses = sortBy(division.weightClasses, "maxWeight");
    const firstWeightClass = weightClasses.shift();

    const lifts = [];
    if (division.lifts.squat) {
      lifts.push("squat");
    }
    if (division.lifts.bench) {
      lifts.push("bench");
    }
    if (division.lifts.dead) {
      lifts.push("dead");
    }

    csvObject.push({
      name: division.name,
      gender: findOptionLabel(getGenderOptions(null, meet), division.gender),
      rawOrEquipped: findOptionLabel(
        getRawOrEquippedOptions(null, meet),
        division.rawOrEquipped
      ),
      lifts: join(lifts, ","),
      scoreBy: findOptionLabel(getScoreByOptions(), division.scoreBy),
      weightClassName: firstWeightClass?.name,
      maxWeight: firstWeightClass?.maxWeight,
      usaplDivisionCode: division.usaplDivisionCode,
      hideOnBoard: division.hideOnBoard,
    });

    each(weightClasses, (weightClass) => {
      csvObject.push({
        name: "",
        gender: "",
        rawOrEquipped: "",
        lifts: "",
        scoreBy: "",
        weightClassName: weightClass.name,
        maxWeight: weightClass.maxWeight,
      });
    });
  });

  csvObject.unshift({
    name: "name",
    gender: "gender",
    rawOrEquipped: "rawOrEquipped",
    lifts: "lifts",
    scoreBy: "scoreBy",
    weightClassName: "weightClassName",
    maxWeight: "maxWeight",
    usaplDivisionCode: "usaplDivisionCode",
    hideOnBoard: "hideOnBoard",
  });

  const csvString = Papa.unparse(csvObject, { quotes: true, header: false });
  const fileName =
    fileOverride === "import"
      ? "divisions_import_template"
      : "divisions_export";
  presentStringAsDownload(csvString, meet, fileName);
};
