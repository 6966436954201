import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './Instructions.scss';

class Instructions extends Component {
  render() {
    return (
      <div className="instructions">
        <div><Link to="/">Go Home</Link></div>
        <h2>Instructions</h2>

        <div className="frame-wrapper">
          <iframe title="instructions" src="https://docs.google.com/document/d/e/2PACX-1vREstkvvx9q2nk9AX_DcoiGC5grXB56Ge5dVsEPvbgGGoEKxE70sT3JGtPdXNA5bkYDYOUeTy2175yG/pub?embedded=true"></iframe>
        </div>
      </div>
    );
  }
}

export default Instructions;
