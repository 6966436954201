import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getCurrentRefDoc,
  getHeadRefDoc,
  getLeftRefDoc,
  getRightRefDoc,
} from "selectors";
import { ReduxState, RefPosition } from "types";

export const useRefs = () => {
  const { meetId, platformId, position } = useParams<{
    meetId: string;
    platformId: string;
    position: RefPosition;
  }>();

  const props = React.useMemo(() => {
    return { match: { params: { meetId, platformId, position } } };
  }, [meetId, platformId, position]);

  const leftRef = useSelector((state: ReduxState) =>
    getLeftRefDoc(state, props)
  );

  const headRef = useSelector((state: ReduxState) =>
    getHeadRefDoc(state, props)
  );

  const rightRef = useSelector((state: ReduxState) =>
    getRightRefDoc(state, props)
  );

  const currentRef = useSelector((state: ReduxState) =>
    getCurrentRefDoc(state, props)
  );

  return { leftRef, headRef, rightRef, currentRef };
};
