import { Column } from "components/table/Table";
import get from "lodash/get";
import React from "react";
import { Link } from "react-router-dom";
import { Meet } from "types";

const NameCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: Column;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else if (lifter.row === "title") {
    return (
      <div style={style} className="table-cell-inner">
        {get(lifter, column.key)}
      </div>
    );
  } else {
    return (
      <div style={style} className="ellipsis table-cell-inner">
        <Link to={`/meets/${get(meet, "_id")}/lifter/${get(lifter, "_id")}`}>
          {get(lifter, column.key)}
        </Link>
      </div>
    );
  }
};

export default NameCell;
