import each from "lodash/each";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import uniqWith from "lodash/uniqWith";

import { getAttemptDisplay } from "../exportData";
import {
  getRealAge,
  getLifterAwardsWeightClassDoc,
  getTotal,
} from "../lifterHelper";
import {
  CsvArray,
  FederationDivisionsConfig,
  LifterDivision,
  Meet,
} from "types";

// prettier-ignore
const rpsWeightClasses = {
  'MALE': [
    {name: '52',          lbsName: '114.5lbs (60kg)',    maxWeight: 52,   lbsMaxWeight: 114.5},
    {name: '56',          lbsName: '123.5lbs (67.5kg)',  maxWeight: 56,   lbsMaxWeight: 123.5},
    {name: '60',          lbsName: '132.25lbs (75kg)',   maxWeight: 60,   lbsMaxWeight: 132.25},
    {name: '67.5',        lbsName: '148.75lbs (82.5kg)', maxWeight: 67.5, lbsMaxWeight: 148.75},
    {name: '75',          lbsName: '165.25lbs (90kg)',   maxWeight: 75,   lbsMaxWeight: 165.25},
    {name: '82.5',        lbsName: '181.75lbs (100kg)',  maxWeight: 82.5, lbsMaxWeight: 181.75},
    {name: '90',          lbsName: '198.25lbs (110kg)',  maxWeight: 90,   lbsMaxWeight: 198.25},
    {name: '100',         lbsName: '220.25lbs (125kg)',  maxWeight: 100,  lbsMaxWeight: 220.25},
    {name: '110',         lbsName: '242.5lbs (140kg)',   maxWeight: 110,  lbsMaxWeight: 242.5},
    {name: '125',         lbsName: '275.5lbs (140kg)',   maxWeight: 125,  lbsMaxWeight: 275.5},
    {name: '140',         lbsName: '308.75lbs (140kg)',  maxWeight: 140,  lbsMaxWeight: 308.75},
    {name: 'SHW',         lbsName: 'SHW',                maxWeight: 9999, lbsMaxWeight: 9999}
  ],
  'FEMALE': [
    {name: '44',        lbsName: '97lbs (52kg)',       maxWeight: 44,   lbsMaxWeight: 97},
    {name: '48',        lbsName: '105.75lbs (56kg)',   maxWeight: 48,   lbsMaxWeight: 105.75},
    {name: '52',        lbsName: '114.5lbs (60kg)',    maxWeight: 52,   lbsMaxWeight: 114.5},
    {name: '56',        lbsName: '123.5lbs (67.5kg)',  maxWeight: 56,   lbsMaxWeight: 123.5},
    {name: '60',        lbsName: '132.25lbs (75kg)',   maxWeight: 60,   lbsMaxWeight: 132.25},
    {name: '67.5',      lbsName: '148.75lbs (82.5kg)', maxWeight: 67.5, lbsMaxWeight: 148.75},
    {name: '75',        lbsName: '165.25lbs (90kg)',   maxWeight: 75,   lbsMaxWeight: 165.25},
    {name: '82.5',      lbsName: '181.75lbs (100kg)',  maxWeight: 82.5, lbsMaxWeight: 181.75},
    {name: '90',        lbsName: '198.25lbs (110kg)',  maxWeight: 90,   lbsMaxWeight: 198.25},
    {name: '100',       lbsName: '220.25lbs (125kg)',  maxWeight: 100,  lbsMaxWeight: 220.25},
    {name: '110',       lbsName: '242.5lbs (125kg)',   maxWeight: 110,  lbsMaxWeight: 242.5},
    {name: 'SHW',       lbsName: 'SHW',                maxWeight: 9999, lbsMaxWeight: 9999}
  ]
};

// prettier-ignore
export const rpsBaseDivisions: FederationDivisionsConfig = [
  {name: "AM Teen (14-15)",       code: 'AM-T1',  low: 14,  high: 15,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "AM Teen (16-17)",       code: 'AM-T2',  low: 16,  high: 17,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "AM Teen (18-19)",       code: 'AM-T3',  low: 18,  high: 19,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "AM Junior (20-23)",     code: 'AM-JR',  low: 20,  high: 23,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "AM Open",               code: 'AM-O',   low: 24,  high: 39,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "AM Sub-Master (33-39)", code: 'AM-SM',  low: 35,  high: 39,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "AM Master (40-44)",     code: 'AM-M1',  low: 40,  high: 44,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "AM Master (45-49)",     code: 'AM-M2',  low: 45,  high: 49,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "AM Master (50-54)",     code: 'AM-M3',  low: 50,  high: 54,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "AM Master (55-59)",     code: 'AM-M4',  low: 55,  high: 59,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "AM Master (60-64)",     code: 'AM-M5',  low: 60,  high: 64,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "AM Master (65-69)",     code: 'AM-M6',  low: 65,  high: 69,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "AM Master (70-74)",     code: 'AM-M7',  low: 70,  high: 74,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "AM Master (75-79)",     code: 'AM-M8',  low: 75,  high: 79,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "AM Master (80+)",       code: 'AM-M9',  low: 80,  high: 999,  default: true,  records: true,  weightClasses: rpsWeightClasses},

  {name: "PRO Teen (14-15)",       code: 'PRO-T1',  low: 14,  high: 15,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "PRO Teen (16-17)",       code: 'PRO-T2',  low: 16,  high: 17,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "PRO Teen (18-19)",       code: 'PRO-T3',  low: 18,  high: 19,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "PRO Junior (20-23)",     code: 'PRO-JR',  low: 20,  high: 23,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "PRO Open",               code: 'PRO-O',   low: 24,  high: 39,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "PRO Sub-Master (33-39)", code: 'PRO-SM',  low: 35,  high: 39,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "PRO Master (40-44)",     code: 'PRO-M1',  low: 40,  high: 44,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "PRO Master (45-49)",     code: 'PRO-M2',  low: 45,  high: 49,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "PRO Master (50-54)",     code: 'PRO-M3',  low: 50,  high: 54,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "PRO Master (55-59)",     code: 'PRO-M4',  low: 55,  high: 59,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "PRO Master (60-64)",     code: 'PRO-M5',  low: 60,  high: 64,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "PRO Master (65-69)",     code: 'PRO-M6',  low: 65,  high: 69,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "PRO Master (70-74)",     code: 'PRO-M7',  low: 70,  high: 74,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "PRO Master (75-79)",     code: 'PRO-M8',  low: 75,  high: 79,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "PRO Master (80+)",       code: 'PRO-M9',  low: 80,  high: 999,  default: true,  records: true,  weightClasses: rpsWeightClasses},

  {name: "ELITE Teen (14-15)",       code: 'E-T1',  low: 14,  high: 15,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "ELITE Teen (16-17)",       code: 'E-T2',  low: 16,  high: 17,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "ELITE Teen (18-19)",       code: 'E-T3',  low: 18,  high: 19,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "ELITE Junior (20-23)",     code: 'E-JR',  low: 20,  high: 23,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "ELITE Open",               code: 'E-O',   low: 24,  high: 39,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "ELITE Sub-Master (33-39)", code: 'E-SM',  low: 35,  high: 39,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "ELITE Master (40-44)",     code: 'E-M1',  low: 40,  high: 44,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "ELITE Master (45-49)",     code: 'E-M2',  low: 45,  high: 49,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "ELITE Master (50-54)",     code: 'E-M3',  low: 50,  high: 54,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "ELITE Master (55-59)",     code: 'E-M4',  low: 55,  high: 59,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "ELITE Master (60-64)",     code: 'E-M5',  low: 60,  high: 64,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "ELITE Master (65-69)",     code: 'E-M6',  low: 65,  high: 69,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "ELITE Master (70-74)",     code: 'E-M7',  low: 70,  high: 74,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "ELITE Master (75-79)",     code: 'E-M8',  low: 75,  high: 79,   default: true,  records: true,  weightClasses: rpsWeightClasses},
  {name: "ELITE Master (80+)",       code: 'E-M9',  low: 80,  high: 999,  default: true,  records: true,  weightClasses: rpsWeightClasses},

  {name: "Police/Fire AM",     code: 'PF-AM',                       default: true, records: true,  weightClasses: rpsWeightClasses},
  {name: "Police/Fire PRO",    code: 'PF-PRO',                      default: true, records: true,  weightClasses: rpsWeightClasses},
  {name: "Armed Forces AM",    code: 'AF-AM',                       default: true, records: true,  weightClasses: rpsWeightClasses},
  {name: "Armed Forces PRO",   code: 'AF-PRO',                      default: true, records: true,  weightClasses: rpsWeightClasses},

  {name: "Police/Fire AM Master",     code: 'PF-AM-M',              default: true, records: true,  weightClasses: rpsWeightClasses},
  {name: "Police/Fire PRO Master",    code: 'PF-PRO-M',             default: true, records: true,  weightClasses: rpsWeightClasses},
  {name: "Armed Forces AM Master",    code: 'AF-AM-M',              default: true, records: true,  weightClasses: rpsWeightClasses},
  {name: "Armed Forces PRO Master",   code: 'AF-PRO-M',             default: true, records: true,  weightClasses: rpsWeightClasses},

  {name: "Guest",              code: 'G',                           default: true, records: false,  weightClasses: rpsWeightClasses}
];

export const exportRPSResults = function (meet: Meet, dataArray: any) {
  let csvObject: CsvArray = [];
  each(dataArray, (lifter) => {
    if (!lifter || lifter.row === "title" || lifter.row === "header") {
      return;
    }

    const division = lifter.division;
    const lifterDivision = lifter.divisions.find(
      (d: LifterDivision) => d.divisionId === division._id
    );

    const weightClass = getLifterAwardsWeightClassDoc(
      meet,
      lifter,
      lifterDivision
    );

    // add division info to determine if cell should be displayed
    lifter = {
      ...lifter,
      division: {
        lifts: division.lifts,
      },
    };

    const row = {
      name: lifter.name,
      age: getRealAge(lifter, meet),
      division: division.name,
      equipment: division.rawOrEquipped,
      bodyWeight: lifter.bodyWeight,
      weightClass: get(weightClass, "name", ""),
      squat1: getAttemptDisplay(lifter, "squat", "1"),
      squat2: getAttemptDisplay(lifter, "squat", "2"),
      squat3: getAttemptDisplay(lifter, "squat", "3"),
      squat4: getAttemptDisplay(lifter, "squat", "4"),
      bench1: getAttemptDisplay(lifter, "bench", "1"),
      bench2: getAttemptDisplay(lifter, "bench", "2"),
      bench3: getAttemptDisplay(lifter, "bench", "3"),
      bench4: getAttemptDisplay(lifter, "bench", "4"),
      dead1: getAttemptDisplay(lifter, "dead", "1"),
      dead2: getAttemptDisplay(lifter, "dead", "2"),
      dead3: getAttemptDisplay(lifter, "dead", "3"),
      dead4: getAttemptDisplay(lifter, "dead", "4"),
      total: getTotal(lifter, division._id, meet),
      place: lifter.place || "",
      team: lifter.team,
      state: lifter.state,
    };

    csvObject.push(row);
  });

  csvObject = sortBy(csvObject, [
    "division",
    (row) =>
      row.weightClass && typeof row.weightClass === "string"
        ? parseInt(row.weightClass, 10)
        : 0,
  ]);
  csvObject = uniqWith(csvObject, (a, b) => {
    return (
      a.name === b.name &&
      a.division === b.division &&
      a.bodyWeight === b.bodyWeight &&
      a.birthDate === b.birthDate
    );
  });

  const header = {
    name: "Name",
    age: "Age",
    division: "Div",
    equipment: "Equipment",
    bodyWeight: "BWt",
    weightClass: "WtCls",
    squat1: "Squat 1",
    squat2: "Squat 2",
    squat3: "Squat 3",
    squat4: "Squat 4",
    bench1: "Bench 1",
    bench2: "Bench 2",
    bench3: "Bench 3",
    bench4: "Bench 4",
    dead1: "Deadlift 1",
    dead2: "Deadlift 2",
    dead3: "Deadlift 3",
    dead4: "Deadlift 4",
    total: "Toal",
    place: "Pl code",
    team: "Team",
    state: "State",
  };

  csvObject.unshift(header);

  return csvObject;
};
