import React from "react";
import { getGenderOptions } from "util/options";
import { errorReporter } from "util/errorReporter";
import { fetchWrapper } from "util/api";
import "./RegistrationIndex.scss";
import { useStatus } from "util/useStatus";
import { useMeet } from "util/useMeet";
import { Meet, RegistrationPayload } from "types";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import size from "lodash/size";
import RegForm from "./RegForm";

const MeetInfo = ({ meet }: { meet: Meet }) => {
  return (
    <div className="meet-info">
      <div>{meet.entryConfig.description}</div>
      <div className="website-link">
        <a href={meet.entryConfig.website}>{meet.entryConfig.website}</a>
      </div>
    </div>
  );
};

const RegistrationNew = () => {
  const meet = useMeet();
  const { isLocal, isOnline } = useStatus();
  const [processing, setProcessing] = React.useState(false);
  const [apiError, setApiError] = React.useState("");

  const submit = async ({
    reg,
    customQuestions,
  }: {
    reg: RegistrationPayload;
    customQuestions: Record<string, string>;
  }) => {
    setProcessing(true);
    window.scrollTo(0, 0);

    const body = {
      registration: { ...reg, customQuestions },
    };
    try {
      const response = await fetchWrapper(
        `/apiv2/meets/${meet._id}/registration`,
        "POST",
        body
      );
      if (response.ok && response.url) {
        setApiError("");
        window.location.assign(response.url);
      } else {
        setProcessing(false);
        setApiError(
          response.error ||
            "Something went wrong. Unable to redirect to payment page."
        );
      }
    } catch (error: any) {
      console.log(error);
      errorReporter({ error });
      setProcessing(false);
      setApiError(error.toString());
    }
  };

  const limitedGenderOptions = getGenderOptions(null, meet).filter((option) =>
    find(meet.divisions, { gender: option.value })
  );

  const missingDivisions = isEmpty(limitedGenderOptions);

  const setupIncomplete =
    (isLocal && !isOnline) ||
    !meet.entryConfig.fullPowerliftingCost ||
    !meet.entryConfig.maxEntries ||
    missingDivisions;

  if (!meet.entryConfig.isOpen) {
    window.scrollTo(0, 0);
    return (
      <div className="registration-index">
        <MeetInfo meet={meet} />
        <div className="message">Registration is currently closed.</div>
      </div>
    );
  }

  if (
    meet.entryConfig.maxEntries &&
    size(meet.lifters) >= meet.entryConfig.maxEntries
  ) {
    window.scrollTo(0, 0);
    return (
      <div className="registration-index">
        <MeetInfo meet={meet} />
        <div className="message">
          This meet has reached capacity. Registration is now closed.
        </div>
      </div>
    );
  }

  return (
    <div className="registration-index">
      {processing && (
        <div>
          <div className="processing-overlay" />
          <div className="processing-spinner">
            <div className="loader" />
          </div>
        </div>
      )}
      {apiError && (
        <div className="api-error">
          <div>There was an error processing your request.</div>
          <div>{apiError}</div>
        </div>
      )}

      {setupIncomplete && isLocal && (
        <div className="api-error">
          <div>Registration Setup Incomplete.</div>
          {!isOnline && <div>Must be uploaded to cloud.</div>}
          {!meet.entryConfig.fullPowerliftingCost && (
            <div>Missing full powerlifting cost.</div>
          )}
          {!meet.entryConfig.maxEntries && <div>Missing max entries.</div>}
          {missingDivisions && <div>Missing awards divisions.</div>}
        </div>
      )}

      <MeetInfo meet={meet} />

      <RegForm
        processing={processing}
        setupIncomplete={setupIncomplete}
        submit={submit}
      />

      {apiError && (
        <div className="api-error">
          <div>There was an error processing your request.</div>
          <div>{apiError}</div>
        </div>
      )}
    </div>
  );
};

export default RegistrationNew;
