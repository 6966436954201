import each from "lodash/each";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import uniqWith from "lodash/uniqWith";

import {
  getRealAge,
  getLifterAwardsWeightClassDoc,
  getTotal,
} from "../lifterHelper";

import { getAttemptDisplay } from "../exportData";
import {
  CsvArray,
  FederationDivisionsConfig,
  LifterDivision,
  Meet,
} from "types";

const raw30 = {
  name: "30",
  lbsName: "66.14lbs (30kg)",
  maxWeight: 30,
  lbsMaxWeight: 66.14,
};
const raw35 = {
  name: "35",
  lbsName: "77.16lbs (35kg)",
  maxWeight: 35,
  lbsMaxWeight: 77.16,
};
const raw40 = {
  name: "40",
  lbsName: "88.18lbs (40kg)",
  maxWeight: 40,
  lbsMaxWeight: 88.18,
};
const raw44 = {
  name: "44",
  lbsName: "97.00lbs (44kg)",
  maxWeight: 44,
  lbsMaxWeight: 97.0,
};
const raw50 = {
  name: "50",
  lbsName: "110.23lbs (50kg)",
  maxWeight: 50,
  lbsMaxWeight: 110.23,
};
const raw56 = {
  name: "56",
  lbsName: "123.46lbs (56kg)",
  maxWeight: 56,
  lbsMaxWeight: 123.46,
};

const raw60 = {
  name: "60",
  lbsName: "132.28lbs (60kg)",
  maxWeight: 60,
  lbsMaxWeight: 132.28,
};
const raw675 = {
  name: "67.5",
  lbsName: "148.81lbs (67.5kg)",
  maxWeight: 67.5,
  lbsMaxWeight: 148.81,
};
const raw75 = {
  name: "75",
  lbsName: "165.35lbs (75kg)",
  maxWeight: 75,
  lbsMaxWeight: 165.35,
};
const raw825 = {
  name: "82.5",
  lbsName: "181.88lbs (82.5kg)",
  maxWeight: 82.5,
  lbsMaxWeight: 181.88,
};
const raw90 = {
  name: "90",
  lbsName: "198.42lbs (90kg)",
  maxWeight: 90,
  lbsMaxWeight: 198.42,
};
const raw100 = {
  name: "100",
  lbsName: "220.46lbs (100kg)",
  maxWeight: 100,
  lbsMaxWeight: 220.46,
};
const raw110 = {
  name: "110",
  lbsName: "242.51lbs (110kg)",
  maxWeight: 110,
  lbsMaxWeight: 242.51,
};
const raw125 = {
  name: "125",
  lbsName: "275.58lbs (125kg)",
  maxWeight: 125,
  lbsMaxWeight: 275.58,
};
const raw140 = {
  name: "140",
  lbsName: "308.65lbs (140kg)",
  maxWeight: 140,
  lbsMaxWeight: 308.65,
};
const raw140p = {
  name: "140+",
  lbsName: "308.65lbs+ (140kg+)",
  maxWeight: 9999,
  lbsMaxWeight: 9999,
};

const raw48 = {
  name: "48",
  lbsName: "105.82lbs (48kg)",
  maxWeight: 48,
  lbsMaxWeight: 105.82,
};
const raw52 = {
  name: "52",
  lbsName: "114.64lbs (52kg)",
  maxWeight: 52,
  lbsMaxWeight: 114.64,
};
const raw90p = {
  name: "90+",
  lbsName: "198.42lbs+ (90kg+)",
  maxWeight: 9999,
  lbsMaxWeight: 9999,
};

const oneHundredWeightClasses = {
  MALE: [
    raw60,
    raw675,
    raw75,
    raw825,
    raw90,
    raw100,
    raw110,
    raw125,
    raw140,
    raw140p,
  ],
  FEMALE: [raw44, raw48, raw52, raw60, raw675, raw75, raw825, raw90, raw90p],
};

const oneHundredYouthWeightClasses = {
  MALE: [
    raw30,
    raw35,
    raw40,
    raw44,
    raw50,
    raw50,
    raw56,
    raw60,
    raw675,
    raw75,
    raw825,
    raw90,
    raw100,
    raw110,
    raw125,
    raw140,
    raw140p,
  ],
  FEMALE: [
    raw30,
    raw35,
    raw40,
    raw44,
    raw48,
    raw52,
    raw60,
    raw675,
    raw75,
    raw825,
    raw90,
    raw90p,
  ],
};

// prettier-ignore
export const oneHundredRawBaseDivisions: FederationDivisionsConfig = [
  {name: "Youth (under 7)",      code: 'Y1',  low: 0,   high: 7,    default: true,  records: true,  weightClasses: oneHundredYouthWeightClasses },
  {name: "Youth (8-9)",          code: 'Y2',  low: 8,   high: 9,    default: true,  records: true,  weightClasses: oneHundredYouthWeightClasses },
  {name: "Youth (10-11)",        code: 'Y3',  low: 10,  high: 11,   default: true,  records: true,  weightClasses: oneHundredYouthWeightClasses },
  {name: "Teen (12-13)",         code: 'T1',  low: 12,  high: 13,   default: true,  records: true,  weightClasses: oneHundredYouthWeightClasses },
  {name: "Teen (14-15)",         code: 'T2',  low: 14,  high: 15,   default: true,  records: true,  weightClasses: oneHundredYouthWeightClasses },
  {name: "Teen (16-17)",         code: 'T3',  low: 16,  high: 17,   default: true,  records: true,  weightClasses: oneHundredYouthWeightClasses },
  {name: "Teen (18-19)",         code: 'T4',  low: 18,  high: 19,   default: true,  records: true,  weightClasses: oneHundredYouthWeightClasses },
  {name: "Junior (20-24)",       code: 'Jr',  low: 20,  high: 24,   default: true,  records: true,  weightClasses: oneHundredWeightClasses},
  {name: "Open",                 code: 'O',   low: 24,  high: 34,   default: true,  records: true,  weightClasses: oneHundredWeightClasses},
  {name: "Sub-Master (35-39)",   code: 'SM',  low: 35,  high: 39,   default: true,  records: true,  weightClasses: oneHundredWeightClasses},
  {name: "Master (40-44)",       code: 'M1',  low: 40,  high: 44,   default: true,  records: true,  weightClasses: oneHundredWeightClasses},
  {name: "Master (45-49)",       code: 'M2',  low: 45,  high: 49,   default: true,  records: true,  weightClasses: oneHundredWeightClasses},
  {name: "Master (50-54)",       code: 'M3',  low: 50,  high: 54,   default: true,  records: true,  weightClasses: oneHundredWeightClasses},
  {name: "Master (55-59)",       code: 'M4',  low: 55,  high: 59,   default: true,  records: true,  weightClasses: oneHundredWeightClasses},
  {name: "Master (60-64)",       code: 'M5',  low: 60,  high: 64,   default: true,  records: true,  weightClasses: oneHundredWeightClasses},
  {name: "Master (65-69)",       code: 'M6',  low: 65,  high: 69,   default: true,  records: true,  weightClasses: oneHundredWeightClasses},
  {name: "Master (70-74)",       code: 'M7',  low: 70,  high: 74,   default: true,  records: true,  weightClasses: oneHundredWeightClasses},
  {name: "Master (75-79)",       code: 'M8',  low: 75,  high: 79,   default: true,  records: true,  weightClasses: oneHundredWeightClasses},
  {name: "Master (80+)",         code: 'M9',  low: 80,  high: 999,  default: true,  records: true,  weightClasses: oneHundredWeightClasses},
  {name: "Police/Fire/Military", code: 'PFM',                       default: true,  records: true,  weightClasses: oneHundredWeightClasses },
  {name: "Handicap",             code: 'H',                         default: true,  records: true,  weightClasses: oneHundredWeightClasses },
  {name: "Guest",                code: 'G',                         default: false, records: false, weightClasses: oneHundredWeightClasses}
];

export const export100RawResults = function (meet: Meet, dataArray: any) {
  let csvObject: CsvArray = [];
  each(dataArray, (lifter) => {
    if (!lifter || lifter.row === "title" || lifter.row === "header") {
      return;
    }

    const division = lifter.division;
    const lifterDivision = lifter.divisions.find(
      (d: LifterDivision) => d.divisionId === division._id
    );

    const weightClass = getLifterAwardsWeightClassDoc(
      meet,
      lifter,
      lifterDivision
    );

    // add division info to determine if cell should be displayed
    lifter = {
      ...lifter,
      division: {
        lifts: division.lifts,
      },
    };

    const row = {
      name: lifter.name,
      age: getRealAge(lifter, meet),
      division: division.name,
      equipment: division.rawOrEquipped,
      bodyWeight: lifter.bodyWeight,
      weightClass: get(weightClass, "name", ""),
      squat1: getAttemptDisplay(lifter, "squat", "1"),
      squat2: getAttemptDisplay(lifter, "squat", "2"),
      squat3: getAttemptDisplay(lifter, "squat", "3"),
      bench1: getAttemptDisplay(lifter, "bench", "1"),
      bench2: getAttemptDisplay(lifter, "bench", "2"),
      bench3: getAttemptDisplay(lifter, "bench", "3"),
      dead1: getAttemptDisplay(lifter, "dead", "1"),
      dead2: getAttemptDisplay(lifter, "dead", "2"),
      dead3: getAttemptDisplay(lifter, "dead", "3"),
      total: getTotal(lifter, division._id, meet),
      place: lifter.place || "",
      team: lifter.team,
      state: lifter.state,
    };

    csvObject.push(row);
  });

  csvObject = sortBy(csvObject, [
    "division",
    (row) =>
      row.weightClass && typeof row.weightClass === "string"
        ? parseInt(row.weightClass, 10)
        : 0,
  ]);
  csvObject = uniqWith(csvObject, (a, b) => {
    return (
      a.name === b.name &&
      a.division === b.division &&
      a.bodyWeight === b.bodyWeight &&
      a.birthDate === b.birthDate
    );
  });

  const header = {
    name: "Name",
    age: "Age",
    division: "Div",
    equipment: "Equipment",
    bodyWeight: "BWt",
    weightClass: "WtCls",
    squat1: "Squat 1",
    squat2: "Squat 2",
    squat3: "Squat 3",
    bench1: "Bench 1",
    bench2: "Bench 2",
    bench3: "Bench 3",
    dead1: "Deadlift 1",
    dead2: "Deadlift 2",
    dead3: "Deadlift 3",
    total: "Toal",
    place: "Pl code",
    team: "Team",
    state: "State",
  };

  csvObject.unshift(header);

  return csvObject;
};
