import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { getFederationConfig } from "selectors";
import { ReduxState } from "types";

export const useFedConfig = () => {
  const { meetId } = useParams<{ meetId: string }>();

  const props = React.useMemo(() => {
    return { match: { params: { meetId } } };
  }, [meetId]);

  const fedConfig = useSelector((state: ReduxState) =>
    getFederationConfig(state, props)
  );

  return fedConfig;
};
