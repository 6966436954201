import React from "react";
import { HexColorPicker } from "react-colorful";
import { ArrowContainer, Popover } from "react-tiny-popover";
import "./ColorPicker.scss";

type ColorPickerProps = {
  value: string | null | undefined;
  onChange: (value: string | null | undefined) => void;
};

const ColorPicker = ({ onChange, value }: ColorPickerProps) => {
  const [open, setOpen] = React.useState(false);
  const [internalColor, setInternalColor] = React.useState(value);
  const toggleRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (open) {
      setInternalColor(value);
    }
  }, [open, value]);

  return (
    <div className="color-picker">
      <Popover
        ref={toggleRef}
        isOpen={open}
        padding={-12}
        positions={["bottom"]}
        onClickOutside={() => setOpen(false)}
        containerClassName="color-picker-popover"
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={"white"}
            arrowSize={20}
            arrowStyle={{}}
            arrowClassName="color-picker-popover-arrow"
          >
            <div className="color-picker-popover-inner">
              <HexColorPicker color={value ?? ""} onChange={setInternalColor} />
              <div
                className="color-picker-preview"
                style={{
                  backgroundColor: internalColor ?? "white",
                }}
              ></div>
              <div className="color-picker-input-row">
                <input
                  value={internalColor ?? ""}
                  onChange={(event) => setInternalColor(event.target.value)}
                />
              </div>
              <div className="color-picker-bottom-row">
                <button onClick={() => setOpen(false)}>Cancel</button>
                <button
                  onClick={() => {
                    onChange(internalColor);
                    setOpen(false);
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </ArrowContainer>
        )}
      >
        <button
          className="color-picker-swatch"
          onClick={() => setOpen((prev) => !prev)}
        >
          <div
            className="swatch-inner"
            style={{
              backgroundColor: value ?? "white",
            }}
          />
        </button>
      </Popover>
    </div>
  );
};

export default ColorPicker;
