import React from "react";
import { getExpandedEligibleRecordsForLifter } from "util/records";
import { useMeet } from "util/useMeet";
import { useLifter } from "util/useLifter";
import SortableTable from "components/SortableTable";
import { useMediaSize } from "util/useMediaSize";
import DefaultTextCell from "components/cells/DefaultTextCell";
import { Column } from "components/table/Table";

const weightClassSortBy = (row: any) => {
  if (row.weightClass.includes("+")) {
    return Number(row.weightClass) + 0.1;
  }
  return Number(row.weightClass);
};

// prettier-ignore
const allColumns: Column[] = [
  {key: "gender",          label: "Gender",          width: 120, sortable: true, sortDirection: "asc", sortOrder: 1, renderer: DefaultTextCell,},
  {key: "equipmentLevel",  label: "Equipment Level", width: 150, sortable: true, sortDirection: "asc", sortOrder: 2, renderer: DefaultTextCell,},
  {key: "drugTested",      label: "Drug Tested",     width: 120, sortable: true, sortDirection: "asc", sortOrder: 3, renderer: DefaultTextCell,},
  {key: "divisionCode",    label: "Division Code",   width: 120, sortable: true, sortDirection: "asc", sortOrder: 4, renderer: DefaultTextCell,},
  {key: "weightClass",     label: "Weight Class",    width: 130, sortable: true, sortDirection: "asc", sortOrder: 5, renderer: DefaultTextCell,  sortFunction: weightClassSortBy},
  {key: "competitionType", label: "Comp Type",       width: 160, sortable: true, sortDirection: "asc", sortOrder: 6, renderer: DefaultTextCell,},
  {key: "lift",            label: "Lift",            width: 160, sortable: true, sortDirection: "asc", sortOrder: 7, renderer: DefaultTextCell,},
  {key: "location",        label: "Location",        width: 120, sortable: true, sortDirection: "asc", sortOrder: 8, renderer: DefaultTextCell,},
  {key: "recordWeight",    label: "Record",          width: 120, sortable: true, sortDirection: "asc", sortOrder: 9, renderer: DefaultTextCell,},
  {key: "updatedRecordWeight", label: "Updated Record", width: 140, sortable: true, sortDirection: "asc", sortOrder: 10, renderer: DefaultTextCell,},
];

const EligibleRecords = () => {
  const meet = useMeet();
  const lifter = useLifter();

  const expandedEligibleRecords = getExpandedEligibleRecordsForLifter({
    lifter,
    meet,
  });

  const filteredExpandedRecords = expandedEligibleRecords.filter(
    (expandedRecord) => expandedRecord.recordWeight
  );
  const media = useMediaSize();
  const getCellLines = (index: number, divisions: any) => {
    return 1;
  };

  return (
    <div className="eligible-records">
      <div>
        Possible eligible records. Only records meet director has loaded will
        show up here. Check official federation records database to confirm.
      </div>
      <br />
      <SortableTable
        data={filteredExpandedRecords}
        columns={allColumns}
        getCellLines={getCellLines}
        meet={meet}
        numberOfFixedLeftColumns={1}
        media={media}
      />
    </div>
  );
};

export default EligibleRecords;
