import React from "react";
import PouchInput from "components/PouchInput";
import { isCompetingInLift } from "util/lifterHelper";
import SortableHeaderCell from "components/SortableHeaderCell";
import { Column } from "components/table/Table";
import { Meet } from "types";
import get from "lodash/get";

const AttemptCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: Column;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return <SortableHeaderCell style={style} column={column} />;
  } else {
    if (!column.liftName || !column.attemptNumber) {
      return null;
    }
    if (!isCompetingInLift(data, column.liftName, meet)) {
      return null;
    }
    const attempt = get(lifter, [
      "lifts",
      column.liftName,
      column.attemptNumber,
    ]);
    return (
      <div style={style} className="table-cell-inner">
        <PouchInput
          type="number"
          meetId={meet._id}
          documentId={get(attempt, "_id")}
          name="weight"
          value={get(attempt, "weight")}
        />
      </div>
    );
  }
};

export default AttemptCell;
