import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { getKnownLiftingOrder } from "selectors";
import { LiftingOrder, ReduxState } from "types";

export const useKnownLiftingOrder = () => {
  const { meetId, platformId } = useParams<{
    meetId: string;
    platformId: string;
  }>();

  const props = React.useMemo(() => {
    return { match: { params: { meetId, platformId } } };
  }, [meetId, platformId]);

  const knownLiftingOrder = useSelector((state: ReduxState) =>
    getKnownLiftingOrder(state, props)
  ) as LiftingOrder;

  return knownLiftingOrder;
};
