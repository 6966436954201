import filter from "lodash/filter";
import size from "lodash/size";
import React from "react";
import { LiftingOrder } from "types";

const AttemptsCount = ({
  completeLiftingOrder,
}: {
  completeLiftingOrder: LiftingOrder;
}) => {
  return (
    <div className="attempts-count">
      {size(filter(completeLiftingOrder, (o) => o.attempt.result))}-
      {size(filter(completeLiftingOrder, (o) => !o.attempt.result))}/
      {size(completeLiftingOrder)}
    </div>
  );
};

export default AttemptsCount;
