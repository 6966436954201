import each from "lodash/each";
import get from "lodash/get";
import uniqWith from "lodash/uniqWith";

import { getAttemptDisplay } from "../exportData";
import { getRealAge } from "../lifterHelper";
import { CsvArray, FederationDivisionsConfig, Meet } from "types";

// prettier-ignore
const ussfWeightClasses = {
  'MALE': [
    {name: '52',   lbsName: '114.64lbs (52kg)',    maxWeight: 52,   lbsMaxWeight: 114.64},
    {name: '56',   lbsName: '123.46lbs (56kg)',    maxWeight: 56,   lbsMaxWeight: 123.46},
    {name: '60',   lbsName: '132.28lbs (60kg)',    maxWeight: 60,   lbsMaxWeight: 132.28},
    {name: '67.5', lbsName: '148.81lbs (67.5kg)',  maxWeight: 67.5, lbsMaxWeight: 148.81},
    {name: '75',   lbsName: '165.35lbs (75kg)',    maxWeight: 75,   lbsMaxWeight: 165.35},
    {name: '82.5', lbsName: '181.88lbs (82.5kg)',  maxWeight: 82.5, lbsMaxWeight: 181.88},
    {name: '90',   lbsName: '198.42lbs (90kg)',    maxWeight: 90,   lbsMaxWeight: 198.42},
    {name: '100',  lbsName: '220.46lbs (100kg)',   maxWeight: 100,  lbsMaxWeight: 220.46},
    {name: '110',  lbsName: '242.51lbs (110kg)',   maxWeight: 110,  lbsMaxWeight: 242.51},
    {name: '125',  lbsName: '275.58lbs (125kg)',   maxWeight: 125,  lbsMaxWeight: 275.58},
    {name: '125+', lbsName: '275.58lbs+ (125kg+)', maxWeight: 9999, lbsMaxWeight: 9999}
  ],
  'FEMALE': [
    {name: '44',   lbsName: '97.00lbs (44kg)',    maxWeight: 44,   lbsMaxWeight: 97.00},
    {name: '48',   lbsName: '105.82lbs (48kg)',   maxWeight: 48,   lbsMaxWeight: 105.82},
    {name: '52',   lbsName: '114.64lbs (52kg)',   maxWeight: 52,   lbsMaxWeight: 114.64},
    {name: '56',   lbsName: '123.46lbs (56kg)',   maxWeight: 56,   lbsMaxWeight: 123.46},
    {name: '60',   lbsName: '132.28lbs (60kg)',   maxWeight: 60,   lbsMaxWeight: 132.28},
    {name: '67.5', lbsName: '148.81lbs (67.5kg)', maxWeight: 67.5, lbsMaxWeight: 148.81},
    {name: '75',   lbsName: '165.35lbs (75kg)',   maxWeight: 75,   lbsMaxWeight: 165.35},
    {name: '82.5', lbsName: '181.88lbs (82.5kg)', maxWeight: 82.5, lbsMaxWeight: 181.88},
    {name: '90',   lbsName: '198.42lbs (90kg)',   maxWeight: 90,   lbsMaxWeight: 198.42},
    {name: '90+',  lbsName: '198.42lbs (90kg+)',  maxWeight: 9999, lbsMaxWeight: 9999}
  ]
};

// prettier-ignore
const singleWeightClass = {
  'MALE': [
    {name: 'All', lbsName: 'All', maxWeight: 9999, lbsMaxWeight: 9999}
  ],
  'FEMALE': [
    {name: 'All', lbsName: 'All', maxWeight: 9999, lbsMaxWeight: 9999}
  ]
};

// prettier-ignore
export const ussfBaseDivisions: FederationDivisionsConfig = [
  {name: "Open",   code: 'O',   low: 21,  high: 39,   default: true,  records: true,  weightClasses: ussfWeightClasses                                        },
  {name: "Master", code: 'M',   low: 40,  high: 999,  default: true,  records: true,  weightClasses: singleWeightClass, scoreBy: 'BODY_WEIGHT_AND_AGE_POINTS' },
  {name: "Junior", code: 'M',   low: 14,  high: 20,   default: true,  records: true,  weightClasses: singleWeightClass, scoreBy: 'BODY_WEIGHT_AND_AGE_POINTS' },
  {name: "Guest",  code: 'G',   low: 999, high: 9999, default: false, records: false, weightClasses: ussfWeightClasses                                        }
];

export const exportUSSFLResults = function (meet: Meet, dataArray: any) {
  let csvObject: CsvArray = [];
  each(dataArray, (lifter, index) => {
    if (!lifter || lifter.row === "title" || lifter.row === "header") {
      return;
    }

    const division = lifter.gender === "MALE" ? "Open" : "Women";

    const row = {
      memberNumber: get(lifter, "restricted.memberNumber"),
      meet: meet.name,
      age: getRealAge(lifter, meet),
      division: division,
      bodyWeight: lifter.bodyWeight,
      squat1: getAttemptDisplay(lifter, "squat", "1"),
      squat2: getAttemptDisplay(lifter, "squat", "2"),
      squat3: getAttemptDisplay(lifter, "squat", "3"),
      bench1: getAttemptDisplay(lifter, "bench", "1"),
      bench2: getAttemptDisplay(lifter, "bench", "2"),
      bench3: getAttemptDisplay(lifter, "bench", "3"),
      dead1: getAttemptDisplay(lifter, "dead", "1"),
      dead2: getAttemptDisplay(lifter, "dead", "2"),
      dead3: getAttemptDisplay(lifter, "dead", "3"),
    };

    csvObject.push(row);
  });

  csvObject = uniqWith(csvObject, (a, b) => {
    return a.memberNumber === b.memberNumber;
  });

  const header = {
    memberNumber: "lifter_id",
    meet: "meet",
    age: "age",
    division: "division",
    bodyWeight: "bodyWeight",
    squat1: "squat_1",
    squat2: "squat_2",
    squat3: "squat_3",
    bench1: "press_1",
    bench2: "press_2",
    bench3: "press_3",
    dead1: "dead_1",
    dead2: "dead_2",
    dead3: "dead_3",
  };

  csvObject.unshift(header);

  return csvObject;
};
