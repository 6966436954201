import React from "react";
import first from "lodash/first";
import get from "lodash/get";
import map from "lodash/map";
import reject from "lodash/reject";
import { getResultsTableData } from "util/getDivisionResultsData";
import Table, { Column } from "components/table/Table";
import DefaultTextCell from "components/cells/DefaultTextCell";
import NameCell from "./cells/NameCell";
import TeamCell from "./cells/TeamCell";
import PlaceCell from "./cells/PlaceCell";
import AttemptCell from "./cells/AttemptCell";
import ScoreCell from "./cells/ScoreCell";
import CheckIcon from "icons/CheckIcon";

import { getBenchLabel } from "util/meetHelper";
import { MediaSize, Meet, ReduxState } from "types";
import { useSelector } from "react-redux";

const getAllColumns = (meet: Meet): Column[] => {
  // prettier-ignore
  return [
    {key: 'name', label: 'Name', width: 175, renderer: NameCell},
    {key: 'team', label: 'Team', width: 115, renderer: TeamCell},
    {key: 'place', label: 'Place', width: 55, renderer: PlaceCell},
    {key: 'bodyWeight', label: 'Body Wgt', width: 75, renderer: DefaultTextCell},
    {key: 'weight', label: 'Squat 1', width: 70, lift: 'squat', attemptNumber: '1', renderer: AttemptCell},
    {key: 'weight', label: 'Squat 2', width: 70, lift: 'squat', attemptNumber: '2', renderer: AttemptCell},
    {key: 'weight', label: 'Squat 3', width: 70, lift: 'squat', attemptNumber: '3', renderer: AttemptCell},
    {key: 'weight', label: `${getBenchLabel(meet)} 1`, width: 70, lift: 'bench', attemptNumber: '1', renderer: AttemptCell},
    {key: 'weight', label: `${getBenchLabel(meet)} 2`, width: 70, lift: 'bench', attemptNumber: '2', renderer: AttemptCell},
    {key: 'weight', label: `${getBenchLabel(meet)} 3`, width: 70, lift: 'bench', attemptNumber: '3', renderer: AttemptCell},
    {key: 'weight', label: 'Dead 1', width: 70, lift: 'dead', attemptNumber: '1', renderer: AttemptCell},
    {key: 'weight', label: 'Dead 2', width: 70, lift: 'dead', attemptNumber: '2', renderer: AttemptCell},
    {key: 'weight', label: 'Dead 3', width: 70, lift: 'dead', attemptNumber: '3', renderer: AttemptCell},
    {key: 'score',  label: "Score", width: 110, renderer: ScoreCell}
  ];
};

const getCellLines = () => {
  return 1;
};

const DivisionResultsTable = ({
  setForecasted,
  forecasted,
  divisions,
  meet,
  media,
}: {
  setForecasted: () => void;
  forecasted: boolean;
  divisions: any;
  meet: Meet;
  media: MediaSize;
}) => {
  const data = useSelector((state: ReduxState) =>
    getResultsTableData(state, {
      divisions,
      forecasted,
      match: {
        params: { meetId: meet._id },
      },
    })
  );

  const division = first(divisions);
  const weightClass = get(division, ["weightClasses", 0]);

  const dataWithDivisionInHeader = map(data, (row) => {
    if (row && row.row === "header") {
      return { ...row, division: division };
    }

    return row;
  });
  const dataWithTitleRowRemoved = reject(
    dataWithDivisionInHeader,
    (row) => row && row.row === "title"
  );

  const columns = getAllColumns(meet);

  return (
    <div style={{ height: "100%" }}>
      <div className="util-row">
        <button className="forecasted" onClick={() => setForecasted()}>
          Forecasted&nbsp;{forecasted && <CheckIcon />}
        </button>
      </div>
      <div className="division-title">
        {get(division, "name")} - {get(weightClass, "name")}
      </div>
      <div className="table-wrapper">
        <Table
          data={dataWithTitleRowRemoved}
          numberOfFixedLeftColumns={0}
          columns={columns}
          getCellLines={getCellLines}
          meet={meet}
          scrollToAlignment="center"
          media={media}
        />
      </div>
    </div>
  );
};

export default DivisionResultsTable;
