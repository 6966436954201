import React from "react";
import classNames from "classnames";
import { selectNextAttempt } from "util/selectNextAttempt";
import {
  updateAttributeOnDocument,
  updateAttributesOnDocument,
} from "util/pouchActions";
import Lights from "components/lights/Lights";
import ThumbsDownIcon from "icons/ThumbsDownIcon";
import ThumbsUpIcon from "icons/ThumbsUpIcon";
import ClockIcon from "icons/ClockIcon";
import ResetIcon from "icons/ResetIcon";
import CheckIcon from "icons/CheckIcon";
import NextIcon from "icons/NextIcon";
import "./RefIndex.scss";
import { useMeet } from "util/useMeet";
import { LiftingOrder, Meet, RefCard, RefDecision } from "types";
import { usePlatform } from "util/usePlatform";
import { useCurrentAttempt } from "util/useCurrentAttempt";
import { useCurrentLifter } from "util/useCurrentLifter";
import { useKnownLiftingOrder } from "util/useKnownLiftingOrder";
import { useRefs } from "util/useRefs";
import get from "lodash/get";

const startClock = function (platformId: string, meet: Meet) {
  updateAttributeOnDocument(meet._id, platformId, "clockState", "started");
};

const resetClock = function (platformId: string, meet: Meet) {
  updateAttributeOnDocument(meet._id, platformId, "clockState", "initial");
};

const RefIndex = () => {
  const meet = useMeet();
  const platform = usePlatform();
  const currentAttempt = useCurrentAttempt();
  const currentLifter = useCurrentLifter();
  const knownLiftingOrder = useKnownLiftingOrder();
  const { leftRef, headRef, rightRef, currentRef } = useRefs();

  const getIsBadLift = () => {
    return currentRef?.decision === "bad";
  };

  const getIsGoodLift = () => {
    return currentRef?.decision === "good";
  };

  const toggleCard = (card: RefCard) => {
    if (currentRef) {
      const currentCardState = !currentRef.cards?.[card];
      const changes: RefDecision = {
        cards: { ...currentRef.cards, [card]: currentCardState },
      };

      // if toggling a card and a white light is selected remove white light
      if (!currentCardState && currentRef.decision) {
      } else {
        changes.decision = null;
      }
      updateAttributesOnDocument(meet._id, currentRef._id, changes);
    }
  };

  const selectGoodLift = () => {
    confirmSelection("good");
  };

  const selectBadLift = () => {
    confirmSelection("bad");
  };

  const confirmSelection = (decision: "good" | "bad" | null) => {
    if (currentRef) {
      if (currentRef.decision === decision) {
        // toggle off
        updateAttributeOnDocument(meet._id, currentRef._id, "decision", null);
      } else {
        const changes: RefDecision = {
          decision,
        };

        if (decision === "good") {
          changes.cards = {
            red: false,
            blue: false,
            yellow: false,
          };
        }

        updateAttributesOnDocument(meet._id, currentRef._id, changes);
      }
    }
  };

  const canConfirmLift = () => {
    return leftRef.decision && headRef.decision && rightRef.decision;
  };

  const willBeGoodLift = () => {
    return (
      (get(leftRef, "decision") === "good" &&
        get(headRef, "decision") === "good") ||
      (get(rightRef, "decision") === "good" &&
        get(headRef, "decision") === "good") ||
      (get(leftRef, "decision") === "good" &&
        get(rightRef, "decision") === "good")
    );
  };

  const selectNextAttemptInternal = (nextLiftingOrder: LiftingOrder) => {
    updateAttributesOnDocument(meet._id, leftRef._id, {
      decision: null,
      cards: null,
    });
    updateAttributesOnDocument(meet._id, headRef._id, {
      decision: null,
      cards: null,
    });
    updateAttributesOnDocument(meet._id, rightRef._id, {
      decision: null,
      cards: null,
    });
    selectNextAttempt(nextLiftingOrder, meet, platform);
  };

  const markAttempt = () => {
    const result = willBeGoodLift() ? "good" : "bad";

    const changes = {
      result,
      decisions: {
        left: {
          decision: leftRef.decision,
          cards: leftRef.cards,
        },
        head: {
          decision: headRef.decision,
          cards: headRef.cards,
        },
        right: {
          decision: rightRef.decision,
          cards: rightRef.cards,
        },
      },
    };

    // allows the sytem to be used for lights only
    if (currentAttempt._id) {
      updateAttributesOnDocument(meet._id, currentAttempt._id, changes);
    }

    selectNextAttemptInternal(knownLiftingOrder);
  };

  return (
    <div className="ref">
      <div className="position">{currentRef?.position} Referee</div>
      <div className="current">
        <div className="current-lifter">{currentLifter?.name ?? ""}</div>
        <div className="current-attempt">
          {currentAttempt?.liftName ?? ""} {currentAttempt?.attemptNumber ?? ""}{" "}
          - {currentAttempt?.weight ?? "000"}
          {meet.units}
        </div>
      </div>
      {currentRef?.position === "head" && (
        <div className="clock">
          <button
            disabled={platform.clockState === "started"}
            onClick={() => startClock(platform._id, meet)}
          >
            <ClockIcon />
          </button>
          <button
            disabled={platform.clockState === "initial"}
            onClick={() => resetClock(platform._id, meet)}
          >
            <ResetIcon />
          </button>
        </div>
      )}
      <div className="fault-cards">
        <button className="fault-card red" onClick={() => toggleCard("red")}>
          <div>RED</div>
          <div className="icon-wrapper">
            {get(currentRef, ["cards", "red"]) && <ThumbsDownIcon />}
          </div>
        </button>
        <button className="fault-card blue" onClick={() => toggleCard("blue")}>
          <div>BLUE</div>
          <div className="icon-wrapper">
            {get(currentRef, ["cards", "blue"]) && <ThumbsDownIcon />}
          </div>
        </button>
        <button
          className="fault-card yellow"
          onClick={() => toggleCard("yellow")}
        >
          <div>YELLOW</div>
          <div className="icon-wrapper">
            {get(currentRef, ["cards", "yellow"]) && <ThumbsDownIcon />}
          </div>
        </button>
      </div>
      <button
        className={classNames("bad", "button", {
          selected: getIsBadLift(),
        })}
        onClick={selectBadLift}
      >
        <div className="icon-wrapper">
          <ThumbsDownIcon />
        </div>
        <div className="check-icon-wrapper">
          {getIsBadLift() && <CheckIcon />}
        </div>
      </button>
      <div>
        <button
          className={classNames("good", "button", {
            selected: getIsGoodLift(),
          })}
          onClick={selectGoodLift}
        >
          <div className="icon-wrapper">
            <ThumbsUpIcon />
          </div>
          <div className="check-icon-wrapper">
            {getIsGoodLift() && <CheckIcon />}
          </div>
        </button>
      </div>
      {currentRef?.position === "head" && (
        <div>
          <button
            className="button record"
            disabled={!canConfirmLift()}
            onClick={markAttempt}
          >
            <div>
              RECORD
              {canConfirmLift() && (
                <span>
                  {willBeGoodLift() && <span> GOOD LIFT </span>}
                  {!willBeGoodLift() && <span> NO LIFT </span>}
                </span>
              )}
            </div>
            <div>
              {" "}
              AND SELECT NEXT LIFTER{" "}
              <span className="icon-wrapper">
                <NextIcon />
              </span>
            </div>
          </button>
        </div>
      )}
      <Lights meet={meet} platformId={platform._id} showChecks />
    </div>
  );
};

export default RefIndex;
