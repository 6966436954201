import React from "react";
import classNames from "classnames";
import ClockIcon from "icons/ClockIcon";
import { getRunningAttemptInputClocks } from "selectors";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { ReduxState } from "types";

const NextAttemptInputTimers = () => {
  const [clocks, setClocks] = React.useState<
    { name: string; timeRemaining: number }[]
  >([]);
  const { meetId, platformId } = useParams<{
    meetId: string;
    platformId: string;
  }>();
  const props = React.useMemo(() => {
    return { match: { params: { meetId, platformId } } };
  }, [meetId, platformId]);

  const runningAttemptInputClocks = useSelector((state: ReduxState) =>
    getRunningAttemptInputClocks(state, props)
  );

  React.useEffect(() => {
    const updateClocks = () => {
      const newClocks = runningAttemptInputClocks.map((a) => {
        return {
          name: a.lifter?.name ?? "",
          timeRemaining:
            60 - Math.round((Date.now() - (a.attempt?.startTime ?? 0)) / 1000),
        };
      });
      setClocks(newClocks);
    };

    updateClocks();

    const interval = setInterval(updateClocks, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [runningAttemptInputClocks]);

  return (
    <>
      {clocks.map((c) => {
        const pastDue = c.timeRemaining <= 0;
        return (
          <div
            key={c.name + c.timeRemaining}
            className={classNames(
              pastDue && "past-due-attempt-input",
              "next-input-timer"
            )}
          >
            <ClockIcon />
            {c.name} : {pastDue ? 0 : c.timeRemaining}
          </div>
        );
      })}
    </>
  );
};

export default NextAttemptInputTimers;
