import React from "react";
import { Meet } from "types";
import size from "lodash/size";
import map from "lodash/map";
import first from "lodash/first";
import { getDotsPoints } from "util/lifterHelper";
import orderBy from "lodash/orderBy";
import take from "lodash/take";
import takeRight from "lodash/takeRight";
import shuffle from "lodash/shuffle";

type DrugTestSelectionHelperProps = {
  meet: Meet;
};

const DrugTestSelectionHelper = ({ meet }: DrugTestSelectionHelperProps) => {
  const numberOfLifters = size(meet.lifters);
  const numberToSelect = Math.ceil(numberOfLifters / 10);

  const liftersWithDotsScore = map(meet.lifters, (lifter) => {
    const firstLifterDivision = first(lifter.divisions);
    let score = 0;
    if (firstLifterDivision && firstLifterDivision.divisionId) {
      score = getDotsPoints(lifter, firstLifterDivision.divisionId, meet);
      return { score, ...lifter };
    }
  });

  const sortedLiftersWithDotsScore = orderBy(
    liftersWithDotsScore,
    ["score"],
    ["desc"]
  );

  const topLifters = take(sortedLiftersWithDotsScore, numberToSelect);
  const otherLifters = takeRight(
    sortedLiftersWithDotsScore,
    sortedLiftersWithDotsScore.length - numberToSelect
  );
  const randomOrderOtherLifters = shuffle(otherLifters);
  const selectedRandomOtherLifters = take(
    randomOrderOtherLifters,
    numberToSelect
  );

  if (meet.federation === "USAPL") {
    // USAPL doesn't like this feature.
    return null;
  }

  return (
    <div className="drug-test-selection-helper">
      <h2>Drug Test Selection Suggestion Helper</h2>
      <h3>Top 10% by DOTS</h3>
      {topLifters.map((lifter) => {
        return <div key={lifter?._id}>{lifter?.name}</div>;
      })}
      <h3>Random 10% not in the top 10%</h3>
      {selectedRandomOtherLifters.map((lifter) => {
        return <div key={lifter?._id}>{lifter?.name}</div>;
      })}
    </div>
  );
};

export default DrugTestSelectionHelper;
