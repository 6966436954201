import React from "react";
import { getDateFormat } from "util/meetHelper";
import DateInput from "components/dateInput/DateInput";
import { updateAttributeOnDocument } from "util/pouchActions";
import { Meet } from "types";

type PouchDateInputProps = {
  meet: Meet;
  name: string;
  value: string | undefined;
  documentId: string;
  style?: React.CSSProperties;
  readOnly?: boolean;
};

const PouchDateInput = ({
  meet,
  name,
  value,
  documentId,
  style,
  readOnly,
}: PouchDateInputProps) => {
  const onChange = (value: string) => {
    updateAttributeOnDocument(meet._id, documentId, name, value);
  };

  return (
    <DateInput
      dateFormat={getDateFormat(meet)}
      placeholder={getDateFormat(meet)}
      value={value}
      style={style}
      onChange={onChange}
      readOnly={readOnly}
      documentId={documentId}
    />
  );
};

export default PouchDateInput;
