import React, { Component } from "react";
import { Link } from "react-router-dom";

import FixedHeightWrapper from "app/meets/FixedHeightWrapper";

class RouteNotFound extends Component {
  render() {
    return (
      <FixedHeightWrapper>
        <div className="not-found">
          404 Not Found.&nbsp;<Link to="/">Go Home</Link>
        </div>
      </FixedHeightWrapper>
    );
  }
}

export default RouteNotFound;
