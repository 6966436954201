import React, { Component } from "react";
import Draggable from "react-draggable";
import queryString from "query-string";
import setQuery from "set-query-string";

import ArrowUpIcon from "icons/ArrowUpIcon";
import ArrowDownIcon from "icons/ArrowDownIcon";

class LiftDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sizeMultiplier: 1,
      heightMultiplier: 1,
      defaultPosition: { x: 0, y: 0 },
      currentPosition: { x: 0, y: 0 },
    };
  }

  UNSAFE_componentWillMount() {
    try {
      if (window.location.search) {
        const queryData = queryString.parse(window.location.search);
        const encodedData = queryData[this.props.id];
        if (encodedData) {
          const data = JSON.parse(atob(encodedData));
          this.setState({
            sizeMultiplier: data.s,
            heightMultiplier: data.h,
            defaultPosition: data.p,
            currentPosition: data.p,
          });
        }
      }
    } catch (e) {
      console.log("Failed to parse position data", e);
    }
  }

  setUrl = () => {
    const dataToStore = {
      s: this.state.sizeMultiplier,
      h: this.state.heightMultiplier,
      p: this.state.currentPosition,
    };

    const encodedData = btoa(JSON.stringify(dataToStore));
    setQuery({ [this.props.id]: encodedData });
  };

  onDragStop = (event, position) => {
    this.setState(
      { currentPosition: { x: position.x, y: position.y } },
      this.setUrl
    );
  };

  grow = () => {
    this.setState(
      { sizeMultiplier: this.state.sizeMultiplier + 0.1 },
      this.setUrl
    );
  };

  shrink = () => {
    this.setState(
      { sizeMultiplier: this.state.sizeMultiplier - 0.1 },
      this.setUrl
    );
  };

  increaseHeight = () => {
    this.setState(
      { heightMultiplier: this.state.heightMultiplier + 0.1 },
      this.setUrl
    );
  };

  decreaseHeight = () => {
    this.setState(
      { heightMultiplier: this.state.heightMultiplier - 0.1 },
      this.setUrl
    );
  };

  render() {
    const { sizeMultiplier, heightMultiplier, defaultPosition } = this.state;

    return (
      <Draggable
        defaultPosition={defaultPosition}
        onStop={this.onDragStop}
        cancel=".not-draggable"
      >
        <div
          className="display-component"
          style={{ ...this.props.style, position: "absolute" }}
        >
          <div className="resizer">
            <div
              onClick={this.grow}
              style={{
                display: "flex",
                width: 30,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              +
            </div>
            <div
              onClick={this.shrink}
              style={{
                display: "flex",
                borderLeft: "1px solid black",
                width: 30,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              -
            </div>
          </div>
          {this.props.canAdjustHeight && (
            <div className="height-sizer">
              <div className="resize-button" onClick={this.increaseHeight}>
                <ArrowUpIcon />
              </div>
              <div className="resize-button" onClick={this.decreaseHeight}>
                <ArrowDownIcon />
              </div>
            </div>
          )}
          {this.props.children({ sizeMultiplier, heightMultiplier })}
        </div>
      </Draggable>
    );
  }
}

export default LiftDetails;
