import React from "react";
import { useMediaSize } from "util/useMediaSize";

const FixedHeightWrapper = ({ children }: { children: React.ReactNode }) => {
  const media = useMediaSize();

  const getHeight = React.useCallback(() => {
    const headerHeight = 50;
    let padding = 0;
    if (media === "small") {
      padding = 10;
    } else if (media === "medium") {
      padding = 15;
    } else if (media === "large") {
      padding = 20;
    }

    return window.innerHeight - headerHeight - padding;
  }, [media]);

  const [height, setHeight] = React.useState(getHeight());

  React.useEffect(() => {
    const handleResize = () => setHeight(getHeight());
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [getHeight]);

  return (
    <div className="fixed-height-wrapper" style={{ height: height }}>
      {children}
    </div>
  );
};

export default FixedHeightWrapper;
