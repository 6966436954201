import React from "react";
import { getSessionOptions } from "util/options";
import Stat from "./Stat";
import "./StatsIndex.scss";
import { useMeet } from "util/useMeet";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import size from "lodash/size";
import filter from "lodash/filter";

const StatsIndex = () => {
  const meet = useMeet();

  return (
    <div className="stats-index">
      {map(getSessionOptions(), (session) => {
        const sessionLifters = filter(meet.lifters, {
          session: session.value,
        });
        const sessionCount = size(sessionLifters);
        if (!sessionCount) {
          return null;
        }

        return (
          <div className="session-block" key={session.value}>
            {map(sortBy(meet.platforms, "name"), (platform) => {
              const platformLifters = filter(sessionLifters, {
                platformId: platform._id,
              });
              const platformCount = size(platformLifters);
              if (!platformCount) {
                return null;
              }

              return (
                <Stat
                  key={platform._id}
                  platformId={platform._id}
                  session={session.value}
                  title={`Session ${session.value} - Platform ${platform.name}`}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default StatsIndex;
