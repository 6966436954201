import React, { Component } from "react";
import {
  addYears,
  subYears,
  isValid,
  isBefore,
  isAfter,
  isSameDay,
} from "date-fns";

import { parseDateString, formatDate } from "util/dateHelper";
import "./DateInput.scss";

const KEYCODE_RETURN = 13;

type DateInputProps = {
  value: string | undefined;
  dateFormat: string;
  documentId?: string;
  onChange: (newDate: string) => void;
  style?: React.CSSProperties;
  readOnly?: boolean;
  placeholder?: string;
  className?: string;
};

class DateInput extends Component<
  DateInputProps,
  { value: string | undefined; isValid: boolean }
> {
  constructor(props: DateInputProps) {
    super(props);
    this.state = {
      value: props.value,
      isValid: true,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: DateInputProps) {
    if (
      nextProps.value !== this.props.value ||
      this.props.documentId !== nextProps.documentId
    ) {
      this.setState({ value: nextProps.value });
    }
  }

  // componentDidUpdate(prevProps) {
  //   if(prevProps.value !== this.props.value || this.props.documentId !== prevProps.documentId) {
  //     this.setState({value: this.props.value});
  //   }
  // }

  componentWillUnmount() {
    this.onBlur();
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({ value });
  };

  onBlur = () => {
    if (this.state.value) {
      const oldDate = parseDateString({
        dateString: this.props.value,
        meet: { dateFormat: this.props.dateFormat },
      });
      const newDate = parseDateString({
        dateString: this.state.value,
        meet: { dateFormat: this.props.dateFormat },
      });

      // make sure the dates are in some obvious range to prevent typos
      const futureLimit = addYears(new Date(), 100);
      const pastLimit = subYears(new Date(), 140);

      // There is a bug in isValid where if you enter a valid date, then an invalid date, then enter the same valid date again it doesn't remove the invalid message
      if (
        newDate &&
        isValid(newDate) &&
        isBefore(newDate, futureLimit) &&
        isAfter(newDate, pastLimit)
      ) {
        if (!oldDate || !isSameDay(newDate, oldDate)) {
          this.props.onChange(
            formatDate({
              date: newDate,
              meet: { dateFormat: this.props.dateFormat },
            })
          );
          this.setState({ isValid: true });
        }
      } else {
        this.setState({ isValid: false });
      }
    } else {
      if (this.state.value !== this.props.value) {
        this.props.onChange("");
      }
      this.setState({ isValid: true });
    }
  };

  onKeyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === KEYCODE_RETURN) {
      this.onBlur();
    }
  };

  render() {
    return (
      <div className="date-input">
        <input
          style={{ width: "100%", ...this.props.style }}
          type="text"
          value={this.state.value === undefined ? "" : this.state.value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          readOnly={this.props.readOnly}
          onKeyDown={this.onKeyDown}
          placeholder={this.props.placeholder}
          className={this.props.className}
        />
        {!this.state.isValid && <div className="error">Invalid Date</div>}
      </div>
    );
  }
}

export default DateInput;
