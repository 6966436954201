import React from "react";

import PouchInput from "components/PouchInput";
import PouchColorPicker from "components/PouchColorPicker";
import { Meet } from "types";
import get from "lodash/get";
import map from "lodash/map";

const Plates = ({ meet }: { meet: Meet }) => {
  return (
    <div className="plates">
      <table>
        <thead>
          <tr>
            <th>Plate</th>
            <th># of pairs</th>
            <th>Color</th>
          </tr>
        </thead>
        <tbody>
          {map(meet.plates, (plate, key) => {
            return (
              <tr key={key}>
                <th>
                  {plate.weight} {meet.units}
                </th>
                <th>
                  <PouchInput
                    style={{ width: 100 }}
                    type="number"
                    meetId={meet._id}
                    documentId={meet._id}
                    name={`plates.${key}.pairsCount`}
                    value={get(meet, ["plates", key, "pairsCount"])}
                  />
                </th>
                <th>
                  <PouchColorPicker
                    meet={meet}
                    document={meet}
                    name={`plates.${key}.color`}
                    value={get(meet, ["plates", key, "color"])}
                  />
                </th>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Plates;
