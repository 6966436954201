import React from "react";
import forEach from "lodash/forEach";
import get from "lodash/get";
import set from "lodash/set";
import { Meet } from "types";

type MedalCountProps = {
  meet: Meet;
};

const MedalCount = ({ meet }: MedalCountProps) => {
  const medalCounts = React.useMemo(() => {
    const medalCountsTemplate = [
      { label: "1st", place: 1, count: 0 },
      { label: "2nd", place: 2, count: 0 },
      { label: "3rd", place: 3, count: 0 },
      { label: "4th", place: 4, count: 0 },
      { label: "5th", place: 5, count: 0 },
    ];

    const divisionCounts: { [key: string]: { [key: string]: number } } = {};

    forEach(meet.lifters, (lifter) => {
      if (!lifter.divisions) {
        return;
      }
      lifter.divisions.forEach((lifterDivision) => {
        if (
          !lifterDivision ||
          !lifterDivision.divisionId ||
          !lifterDivision.declaredAwardsWeightClassId
        ) {
          return;
        }
        set(
          divisionCounts,
          [
            lifterDivision.divisionId,
            lifterDivision.declaredAwardsWeightClassId,
          ],
          get(
            divisionCounts,
            [
              lifterDivision.divisionId,
              lifterDivision.declaredAwardsWeightClassId,
            ],
            0
          ) + 1
        );
      });
    });

    Object.keys(divisionCounts).forEach((divisionCode) => {
      Object.keys(divisionCounts[divisionCode]).forEach((weightClass) => {
        const awardsCategoryParticipants =
          divisionCounts[divisionCode][weightClass];
        medalCountsTemplate.forEach((mc) => {
          if (awardsCategoryParticipants >= mc.place) {
            mc.count = mc.count + 1;
          }
        });
      });
    });
    return medalCountsTemplate;
  }, [meet.lifters]);

  return (
    <div className="medal-counts">
      <h2>Medal Counts</h2>
      <div className="medal-counts-message">
        This is the number of medals you could potentially have to give out
        based on your divison config and registered lifters
      </div>
      <table>
        <tbody>
          {medalCounts.map((m) => {
            return (
              <tr key={m.label}>
                <td>{m.label}:</td>
                <td> {m.count}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MedalCount;
