import get from "lodash/get";
import React from "react";

const DefaultCell = ({
  data,
  column,
  style,
}: {
  data: any;
  column: any;
  style: React.CSSProperties & { fontSize: number };
}) => {
  const lifter = data.lifter;
  if (data.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else if (data.row === "title") {
    return <div style={style} className="table-cell-inner" />;
  } else {
    return (
      <div
        style={{ ...style, fontSize: style.fontSize * 1.3 }}
        className="ellipsis table-cell-inner"
      >
        {get(lifter, column.key)}
      </div>
    );
  }
};

export default DefaultCell;
