import React, { Component } from "react";
import trim from "lodash/trim";
import Textarea from "react-textarea-autosize";
import { updateAttributeOnDocument } from "util/pouchActions";

type PouchTextAreaProps = {
  meetId: string;
  name: string;
  value: string | undefined;
  documentId: string;
  style?: React.CSSProperties & {
    height?: number;
  };
  readOnly?: boolean;
};

class PouchTextArea extends Component<
  PouchTextAreaProps,
  { value: string | undefined }
> {
  constructor(props: PouchTextAreaProps) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: PouchTextAreaProps) {
    if (
      nextProps.value !== this.props.value ||
      this.props.documentId !== nextProps.documentId
    ) {
      this.setState({ value: nextProps.value });
    }
  }

  // componentDidUpdate(prevProps) {
  //   if (
  //     prevProps.value !== this.props.value ||
  //     this.props.documentId !== prevProps.documentId
  //   ) {
  //     this.setState({ value: this.props.value });
  //   }
  // }

  componentWillUnmount() {
    this.onBlur();
  }

  onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    let value = event.target.value;

    this.setState({ value });
  };

  onBlur = () => {
    const newValue = trim(this.state.value);
    if (
      this.props.value !== newValue &&
      !(this.props.value === undefined && newValue === "")
    ) {
      updateAttributeOnDocument(
        this.props.meetId,
        this.props.documentId,
        this.props.name,
        newValue
      );
    }
  };

  render() {
    return (
      <Textarea
        style={{ width: "100%", ...this.props.style }}
        minRows={2}
        value={this.state.value === undefined ? "" : this.state.value}
        onChange={this.onChange}
        onBlur={this.onBlur}
        readOnly={this.props.readOnly}
      />
    );
  }
}

export default PouchTextArea;
