import React from "react";
import Select from "./select/Select";

import { updateAttributeOnDocument } from "util/pouchActions";

type SelectValue = string | number | undefined | null;

type PouchSelectProps = {
  meet: any;
  document: { _id: string };
  name: string | (string | number)[];
  value: SelectValue;
  options: (
    document: { _id: string },
    meet: any,
    index: any
  ) => { label: string | null | undefined; value: SelectValue }[];
  index?: number;
  disabled?: boolean;
  allowCreate?: boolean;
  allowCreateOptionLabel?: string;
  allowCreatePrompt?: string;
  allowCreateTransform?: (newValue: string) => string | number;
  clearable?: boolean;
};

const PouchSelect = ({
  meet,
  document,
  name,
  value,
  options,
  index,
  disabled,
  allowCreate,
  allowCreateOptionLabel,
  allowCreatePrompt,
  allowCreateTransform,
  clearable,
}: PouchSelectProps) => {
  const onChange = (newValue: SelectValue) => {
    if (value !== newValue) {
      updateAttributeOnDocument(meet._id, document._id, name, newValue);
    }
  };

  const optionsArray = options(document, meet, index);

  return (
    <Select
      name={typeof name === "string" ? name : ""}
      disabled={disabled}
      value={value ?? ""}
      options={optionsArray}
      onChange={onChange}
      allowCreate={allowCreate}
      allowCreateOptionLabel={allowCreateOptionLabel}
      allowCreatePrompt={allowCreatePrompt}
      allowCreateTransform={allowCreateTransform}
      clearable={clearable}
    />
  );
};

export default PouchSelect;
