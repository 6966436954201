import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Route } from "react-router-dom";

import { getIsLoggedIn, getIsLocal, getIsOnline } from "selectors";
import { login } from "util/pouchAuth";

class AuthRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeoutPassed: false,
    };
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({ timeoutPassed: true });
    }, 500);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    const {
      isLoggedIn,
      isLocal,
      match,
      component: Component,
      onFinishBulkCreate,
      openPasswordModal,
      openDonateModal,
      blockLocalAccess,
      ...rest
    } = this.props;

    return (
      <Route
        {...rest}
        render={(matchProps) => {
          if (!isLoggedIn && (!isLocal || blockLocalAccess)) {
            return (
              <div className="auth-required">
                {this.state.timeoutPassed && (
                  <div>You must be logged in to access this page</div>
                )}
                {this.state.timeoutPassed && (
                  <button
                    onClick={() =>
                      login(match.params.meetId, null, openPasswordModal)
                    }
                  >
                    LOGIN
                  </button>
                )}
              </div>
            );
          } else {
            return (
              <Component
                {...matchProps}
                onFinishBulkCreate={onFinishBulkCreate}
                openPasswordModal={openPasswordModal}
                openDonateModal={openDonateModal}
              />
            );
          }
        }}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    isLoggedIn: getIsLoggedIn(state, props),
    isLocal: getIsLocal(state, props),
    isOnline: getIsOnline(state, props),
  };
};

const actions = {};

export default withRouter(connect(mapStateToProps, actions)(AuthRoute));
