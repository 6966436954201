import React from "react";
import ArrowUpIcon from "icons/ArrowUpIcon";
import ArrowDownIcon from "icons/ArrowDownIcon";
import { Column } from "./table/Table";

type SortableHeaderCellProps = { column: Column; style: React.CSSProperties };

const SortableHeaderCell = ({ column, style }: SortableHeaderCellProps) => {
  return (
    <div style={style} className="table-cell-inner">
      {column.label}
      {column.sortable && column.sortDirection === "asc" && (
        <ArrowUpIcon solid={column.sortOrder === 1} />
      )}
      {column.sortable && column.sortDirection === "desc" && (
        <ArrowDownIcon solid={column.sortOrder === 1} />
      )}
    </div>
  );
};

export default SortableHeaderCell;
