import React, { ErrorInfo } from "react";
import { errorReporter } from "util/errorReporter";

type ErrorBoundaryProps = { children: React.ReactNode };

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { hasError: boolean }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.log(info);
    console.log(error);
    try {
      errorReporter({ message: info.componentStack, error });
    } catch (e) {
      console.log(e);
    }
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="app-error">
          SOMETHING WENT WRONG. PLEASE CONTACT SUPPORT.
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
