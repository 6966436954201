import each from "lodash/each";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import React from "react";
import { useMeet } from "util/useMeet";

const DatabaseChecker = () => {
  const meet = useMeet();

  let foundError = false;

  const errors: any = {};

  each(meet.lifters, (lifter) => {
    if (!errors[lifter._id]) {
      errors[lifter._id] = [];
    }

    each(["squat", "bench", "dead"], (lift) => {
      each(["1", "2", "3"], (attemptNumber) => {
        if (isEmpty(get(lifter, ["lifts", lift, attemptNumber]))) {
          console.log(lifter);
          errors[lifter._id].push(
            `Corrupt lifter data for ${lift} ${attemptNumber} - ${lifter._id}`
          );
          foundError = true;
        }
      });
    });
  });
  console.log(errors);

  return (
    <div className="database-checker">
      {!foundError && <div>Everything looks good.</div>}
      <ul>
        {map(errors, (lifterErrors, lifterId) => {
          if (isEmpty(lifterErrors)) {
            return null;
          }

          return (
            <li key={lifterId}>
              <ul>
                {map(lifterErrors, (error) => {
                  return <li key={error}>{error}</li>;
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DatabaseChecker;
