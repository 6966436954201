import {
  putMeet,
  putEntryConfig,
  putRestrictedMeet,
  putPlatform,
  removePlatform,
  putLifter,
  putRestrictedLifter,
  removeLifter,
  putAttempt,
  putDivision,
  removeDivision,
  putWeightClass,
  removeWeightClass,
  putRef,
  putRecords,
} from "../actions";

import {
  MEET_DOC_PREFIX,
  ENTRY_DOC_PREFIX,
  RESTRICTED_MEET_DOC_PREFIX,
  PLATFORM_DOC_PREFIX,
  LIFTER_DOC_PREFIX,
  RESTRICTED_LIFTER_DOC_PREFIX,
  ATTEMPT_DOC_PREFIX,
  DIVISION_DOC_PREFIX,
  WEIGHT_CLASS_DOC_PREFIX,
  REF_DOC_PREFIX,
  RECORDS_DOC_PREFIX,
  getDocType,
  getParentId,
} from "util/docHelper";

import { GenericDocument } from "types";
import { ReduxStore } from "../store";

export const handleDatabaseChange = ({
  doc,
  isDeleted,
  meetId,
  isInitialLoad,
  store,
}: {
  doc: GenericDocument;
  isDeleted: boolean | undefined;
  meetId: string;
  isInitialLoad: boolean; // some data won't be calculated during this stage.
  store: ReduxStore;
}) => {
  const docType = getDocType(doc);
  switch (docType) {
    case MEET_DOC_PREFIX:
      store.dispatch(putMeet({ meet: doc, isInitialLoad }));
      break;
    case RESTRICTED_MEET_DOC_PREFIX:
      store.dispatch(
        putRestrictedMeet({ restrictedMeet: doc, meetId, isInitialLoad })
      );
      break;
    case ENTRY_DOC_PREFIX:
      store.dispatch(
        putEntryConfig({ entryConfig: doc, meetId, isInitialLoad })
      );
      break;
    case PLATFORM_DOC_PREFIX:
      if (isDeleted) {
        store.dispatch(
          removePlatform({ platform: doc, meetId, isInitialLoad })
        );
      } else {
        store.dispatch(putPlatform({ platform: doc, meetId, isInitialLoad }));
      }
      break;
    case LIFTER_DOC_PREFIX:
      if (isDeleted) {
        store.dispatch(removeLifter({ lifter: doc, meetId, isInitialLoad }));
      } else {
        store.dispatch(putLifter({ lifter: doc, meetId, isInitialLoad }));
      }
      break;
    case RESTRICTED_LIFTER_DOC_PREFIX:
      if (!isDeleted) {
        store.dispatch(
          putRestrictedLifter({
            restrictedLifter: doc,
            lifterId: getParentId(doc),
            meetId,
            isInitialLoad,
          })
        );
      }
      break;
    case ATTEMPT_DOC_PREFIX:
      if (!isDeleted) {
        store.dispatch(
          putAttempt({
            attempt: doc,
            lifterId: getParentId(doc),
            meetId,
            isInitialLoad,
          })
        );
      }
      break;
    case DIVISION_DOC_PREFIX:
      if (isDeleted) {
        store.dispatch(
          removeDivision({ division: doc, meetId, isInitialLoad })
        );
      } else {
        store.dispatch(putDivision({ division: doc, meetId, isInitialLoad }));
      }
      break;
    case WEIGHT_CLASS_DOC_PREFIX:
      if (isDeleted) {
        store.dispatch(
          removeWeightClass({
            weightClass: doc,
            divisionId: getParentId(doc),
            meetId,
            isInitialLoad,
          })
        );
      } else {
        store.dispatch(
          putWeightClass({
            weightClass: doc,
            divisionId: getParentId(doc),
            meetId,
            isInitialLoad,
          })
        );
      }
      break;
    case REF_DOC_PREFIX:
      if (!isDeleted) {
        store.dispatch(
          putRef({
            ref: doc,
            platformId: getParentId(doc),
            meetId,
            isInitialLoad,
          })
        );
      }
      break;
    case RECORDS_DOC_PREFIX:
      if (!isDeleted) {
        store.dispatch(putRecords({ records: doc, meetId, isInitialLoad }));
      }
      break;
  }
};
