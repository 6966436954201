import React from "react";
import { getResultsTableData } from "util/getDivisionResultsData";
import { getBenchLabel } from "util/meetHelper";
import Table, { Column } from "components/table/Table";
import NameCell from "./cells/NameCell";
import PlaceCell from "./cells/PlaceCell";
import AttemptCell from "./cells/AttemptCell";
import TotalCell from "./cells/TotalCell";
import BodyWeightAdjustedTotalCell from "./cells/BodyWeightAdjustedTotalCell";
import AgeAdjustedTotalCell from "./cells/AgeAdjustedTotalCell";
import BodyWeightAndAgeAdjustedTotalCell from "./cells/BodyWeightAndAgeAdjustedTotalCell";
import { Division, Lifter, ReduxState, WeightClass } from "types";
import { useMeet } from "util/useMeet";
import { useMediaSize } from "util/useMediaSize";
import filter from "lodash/filter";
import { useSelector } from "react-redux";

const getCellLines = () => {
  return 1;
};

const TeamResultsTable = ({
  shouldDisplayRow,
  divisions,
}: {
  shouldDisplayRow: (lifter: Lifter) => boolean;
  divisions: (Omit<Division, "weightClasses"> & {
    weightClasses: WeightClass[];
  })[];
}) => {
  const meet = useMeet();
  const media = useMediaSize();

  const data = useSelector((state: ReduxState) =>
    getResultsTableData(state, {
      divisions,
      forecasted: false,
      match: {
        params: { meetId: meet._id },
      },
    })
  );

  const getAllColumns = (): Column[] => {
    // prettier-ignore
    return [
      {key: 'name', label: 'Name', width: 220, renderer: NameCell},
      {key: 'place', label: 'Place', width: 55, renderer: PlaceCell},
      {key: 'weight', label: 'Squat 1', width: 70, lift: 'squat', attemptNumber: '1', renderer: AttemptCell},
      {key: 'weight', label: 'Squat 2', width: 70, lift: 'squat', attemptNumber: '2', renderer: AttemptCell},
      {key: 'weight', label: 'Squat 3', width: 70, lift: 'squat', attemptNumber: '3', renderer: AttemptCell},
      {key: 'weight', label: `${getBenchLabel(meet)} 1`, width: 70, lift: 'bench', attemptNumber: '1', renderer: AttemptCell},
      {key: 'weight', label: `${getBenchLabel(meet)} 2`, width: 70, lift: 'bench', attemptNumber: '2', renderer: AttemptCell},
      {key: 'weight', label: `${getBenchLabel(meet)} 3`, width: 70, lift: 'bench', attemptNumber: '3', renderer: AttemptCell},
      {key: 'weight', label: 'Dead 1', width: 70, lift: 'dead', attemptNumber: '1', renderer: AttemptCell},
      {key: 'weight', label: 'Dead 2', width: 70, lift: 'dead', attemptNumber: '2', renderer: AttemptCell},
      {key: 'weight', label: 'Dead 3', width: 70, lift: 'dead', attemptNumber: '3', renderer: AttemptCell},
      {key: 'total', label: 'Total', width: 80, renderer: TotalCell},
      {key: 'total', label: 'Wilks Adjusted Total', width: 150, renderer: BodyWeightAdjustedTotalCell},
      {key: 'total', label: 'Age Adjusted Total', width: 145, renderer: AgeAdjustedTotalCell},
      {key: 'total', label: 'Wilks & Age Adjusted Total', width: 195, renderer: BodyWeightAndAgeAdjustedTotalCell}
    ];
  };

  const filteredData = filter(data, (lifter) => {
    return (
      !lifter ||
      lifter.row === "title" ||
      lifter.row === "header" ||
      shouldDisplayRow(lifter)
    );
  });

  const columns = getAllColumns();

  return (
    <Table
      data={filteredData}
      numberOfFixedLeftColumns={2}
      columns={columns}
      getCellLines={getCellLines}
      meet={meet}
      scrollToAlignment="center"
      media={media}
      padBottom
    />
  );
};

export default TeamResultsTable;
