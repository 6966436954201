import React, { Component } from "react";
import AdjustableColumnsTable from "components/table/AdjustableColumnsTable";
import DefaultTextCell from "components/cells/DefaultTextCell";
import NameCell from "./cells/NameCell";
import TeamCell from "./cells/TeamCell";
import AttemptCell from "./cells/AttemptCell";
import DivisionCell from "./cells/DivisionCell";
import WeightClassCell from "./cells/WeightClassCell";
import SubtotalCell from "./cells/SubtotalCell";
import ScoreCell from "./cells/ScoreCell";
import WilksCoefCell from "./cells/WilksCoefCell";
import PlaceCell from "./cells/PlaceCell";
import ForecastedPlaceCell from "./cells/ForecastedPlaceCell";
import FlagCell from "./cells/FlagCell";
import OutCell from "./cells/OutCell";
import { getBenchLabel } from "util/meetHelper";
import { getDivisionDoc } from "util/lifterHelper";
import { Attempt, FedConfig, Lifter, MediaSize, Meet } from "types";
import compact from "lodash/compact";
import get from "lodash/get";
import filter from "lodash/filter";
import size from "lodash/size";
import findIndex from "lodash/findIndex";
import { Column } from "components/table/Table";

type BoardTableProps = {
  meet: Meet;
  federationConfig: FedConfig;
  sortedLifters: (Lifter | { row: string; name: string })[];
  currentLifter: Lifter;
  currentAttempt: Attempt;
  scrollToCurrentFlight: boolean;
  isSettingsModalOpen: boolean;
  closeSettingsModal: () => void;
  media: MediaSize;
};

class BoardTable extends Component<BoardTableProps> {
  getAllColumns = (): Column[] => {
    const federationConfig = this.props.federationConfig;
    const showFourthAttempts = !!federationConfig?.fourthAttempts;

    // prettier-ignore
    return compact([
      {key: 'name', label: 'Name', width: 210, renderer: NameCell, visible: true, locked: true},
      {key: 'out', label: 'Out', width: 45, renderer: OutCell, visible: false},
      {key: 'country', label: 'Country', width: 65, renderer: DefaultTextCell, visible: false},
      {key: 'flag', label: 'Flag', width: 60, renderer: FlagCell, visible: false},
      {key: 'team', label: 'Team', width: 100, renderer: TeamCell, visible: false},
      {key: 'lot', label: 'Lot', width: 45, renderer: DefaultTextCell, visible: true},
      {key: 'bodyWeight', label: 'Body Wgt', width: 75, renderer: DefaultTextCell, visible: true},
      {key: 'wilksCoef', label: 'Wilks Coef', width: 65, renderer: WilksCoefCell, visible: false},
      {key: 'session', label: 'Session', width: 65, renderer: DefaultTextCell, visible: true},
      {key: 'flight', label: 'Flight', width: 50, renderer: DefaultTextCell, visible: true},
      {key: 'squat.1.weight', label: 'Squat 1', width: 65, lift: 'squat', attemptNumber: '1', renderer: AttemptCell, visible: true},
      {key: 'squat.2.weight', label: 'Squat 2', width: 65, lift: 'squat', attemptNumber: '2', renderer: AttemptCell, visible: true},
      {key: 'squat.3.weight', label: 'Squat 3', width: 65, lift: 'squat', attemptNumber: '3', renderer: AttemptCell, visible: true},
      showFourthAttempts ? {key: 'squat.4.weight', label: 'Squat 4', width: 65, lift: 'squat', attemptNumber: '4', renderer: AttemptCell, visible: true} : undefined,
      {key: 'bench.1.weight', label: `${getBenchLabel(this.props.meet)} 1`, width: 65, lift: 'bench', attemptNumber: '1', renderer: AttemptCell, visible: true},
      {key: 'bench.2.weight', label: `${getBenchLabel(this.props.meet)} 2`, width: 65, lift: 'bench', attemptNumber: '2', renderer: AttemptCell, visible: true},
      {key: 'bench.3.weight', label: `${getBenchLabel(this.props.meet)} 3`, width: 65, lift: 'bench', attemptNumber: '3', renderer: AttemptCell, visible: true},
      showFourthAttempts ? {key: 'bench.4.weight', label: `${getBenchLabel(this.props.meet)} 4`, width: 65, lift: 'bench', attemptNumber: '4', renderer: AttemptCell, visible: true} : undefined,
      {key: 'subtotal', label: 'Subtotal', width: 65, renderer: SubtotalCell, visible: true},
      {key: 'dead.1.weight', label: 'Dead 1', width: 65, lift: 'dead', attemptNumber: '1', renderer: AttemptCell, visible: true},
      {key: 'dead.2.weight', label: 'Dead 2', width: 65, lift: 'dead', attemptNumber: '2', renderer: AttemptCell, visible: true},
      {key: 'dead.3.weight', label: 'Dead 3', width: 65, lift: 'dead', attemptNumber: '3', renderer: AttemptCell, visible: true},
      showFourthAttempts ? {key: 'dead.4.weight', label: 'Dead 4', width: 65, lift: 'dead', attemptNumber: '4', renderer: AttemptCell, visible: true} : undefined,
      {key: 'divisionId', label: 'Division', width: 200, renderer: DivisionCell, visible: true},
      {key: 'weightClass', label: 'Weight Class', width: 120, renderer: WeightClassCell, visible: true},
      {key: 'total', label: 'Total', width: 65, renderer: ScoreCell, visible: true},
      {key: 'forecastedTotal', label: 'Forecasted Total', width: 90, renderer: ScoreCell, visible: true},
      {key: 'wilks', label: 'Wilks', width: 65, renderer: ScoreCell, visible: false},
      {key: 'forecastedWilks', label: 'Forecasted Wilks', width: 90, renderer: ScoreCell, visible: false},
      {key: 'wilksAge', label: 'Wilks/Age', width: 75, renderer: ScoreCell, visible: false},
      {key: 'forecastedWilksAge', label: 'Forecasted Wilks/Age', width: 90, renderer: ScoreCell, visible: false},
      {key: 'ipfPoints', label: 'IPF Pts', width: 75, renderer: ScoreCell, visible: false},
      {key: 'forecastedIpfPoints', label: 'Forecasted IPF Pts', width: 90, renderer: ScoreCell, visible: false},
      {key: 'ipfAgePoints', label: 'IPF/Age Pts', width: 75, renderer: ScoreCell, visible: false},
      {key: 'forecastedIpfAgePoints', label: 'Forecasted IPF/Age Pts', width: 100, renderer: ScoreCell, visible: false},
      {key: 'dotsPoints', label: 'DOTS Pts', width: 75, renderer: ScoreCell, visible: false},
      {key: 'forecastedDotsPoints', label: 'Forecasted DOTS Pts', width: 90, renderer: ScoreCell, visible: false},
      {key: 'dotsAgePoints', label: 'DOTS/Age Pts', width: 75, renderer: ScoreCell, visible: false},
      {key: 'forecastedDotsAgePoints', label: 'Forecasted DOTS/Age Pts', width: 100, renderer: ScoreCell, visible: false},
      {key: 'schwartzMalonePoints', label: 'SM', width: 75, renderer: ScoreCell, visible: false},
      {key: 'schwartzMaloneAndAgePoints', label: 'SM/Age', width: 100, renderer: ScoreCell, visible: false},
      {key: 'glossbrennerPoints', label: 'Gloss', width: 75, renderer: ScoreCell, visible: false},
      {key: 'glossbrennerAndAgePoints', label: 'Gloss/Age', width: 100, renderer: ScoreCell, visible: false},
      {key: 'reshelPoints', label: 'Reshel', width: 75, renderer: ScoreCell, visible: false},
      {key: 'reshelPointsAndAgePoints', label: 'Reshel/Age', width: 90, renderer: ScoreCell, visible: false},
      {key: 'paraDotsPoints', label: 'paraDOTS', width: 75, renderer: ScoreCell, visible: false},
      {key: 'forecastedParaDotsPoints', label: 'Forecasted paraDOTS', width: 85, renderer: ScoreCell, visible: false},
      {key: 'kPoints', label: 'K-Points', width: 75, renderer: ScoreCell, visible: false},
      {key: 'percentOfRecord', label: '% Score', width: 75, renderer: ScoreCell, visible: false},
      {key: 'forecastedPercentOfRecord', label: 'Forecasted % Score', width: 90, renderer: ScoreCell, visible: false},
      {key: 'place', label: 'Place', width: 65, renderer: PlaceCell, visible: true},
      {key: 'forecastedPlace', label: 'Forecasted Place', width: 85, renderer: ForecastedPlaceCell, visible: true}
    ]);
  };

  getCellLines = (index: number) => {
    const lifter = get(this.props.sortedLifters, index, {});
    if ("row" in lifter && lifter.row === "title") {
      return 0.5;
    }

    const lifterDivisions = get(
      this.props.sortedLifters,
      [index, "divisions"],
      []
    );
    const divisionsToShow = filter(lifterDivisions, (lifterDivision) => {
      const divisionDoc = getDivisionDoc(
        lifterDivision.divisionId,
        this.props.meet
      );
      return !get(divisionDoc, "hideOnBoard");
    });

    const rows = size(divisionsToShow);
    if (rows === 0 || rows === 1) {
      return 1 * 0.6;
    }

    return rows * 0.65;
  };

  render() {
    const {
      sortedLifters,
      currentLifter,
      currentAttempt,
      scrollToCurrentFlight,
      isSettingsModalOpen,
      closeSettingsModal,
    } = this.props;

    const columns = this.getAllColumns();
    let scrollToRow;

    if (scrollToCurrentFlight && currentLifter && currentAttempt) {
      scrollToRow = findIndex(sortedLifters, (lifter) => {
        return (
          "flight" in lifter &&
          currentLifter.flight === lifter.flight &&
          currentLifter.session === lifter.session
        );
      });
    }

    return (
      <AdjustableColumnsTable
        data={sortedLifters}
        columns={columns}
        getCellLines={this.getCellLines}
        meet={this.props.meet}
        scrollToRow={scrollToRow}
        scrollToAlignment="start"
        media={this.props.media}
        modalIsOpen={isSettingsModalOpen}
        closeModal={closeSettingsModal}
        numberOfFixedLeftColumns={1}
      />
    );
  }
}

export default BoardTable;
