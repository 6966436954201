import React, { Component } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import setQuery from "set-query-string";
import queryString from "query-string";

import FixedHeightWrapper from "app/meets/FixedHeightWrapper";
import Lights from "components/lights/Lights";
import BoardTable from "./BoardTable";
import Clock from "components/clock/Clock";
import CheckIcon from "icons/CheckIcon";
import GearIcon from "icons/GearIcon";
import NextAttemptInputTimers from "./NextAttemptInputTimers";

import {
  getCurrentMeet,
  getCurrentAttempt,
  getCurrentLifter,
  getCurrentPlatform,
  getScoreBoardTableData,
  getScreenMedia,
  getFederationConfig,
} from "selectors";

import "./BoardIndex.scss";

class BoardIndex extends Component {
  state = {
    scrollToCurrentFlight: false,
    isSettingsModalOpen: false,
  };

  componentDidMount() {
    if (this.props.meet.boardDefaultURL && !window.location.search) {
      window.location.search = this.props.meet.boardDefaultURL;
    }

    if (window.location.search) {
      const queryData = queryString.parse(window.location.search);
      const scrollToCurrentFlight = queryData["scroll"];
      if (scrollToCurrentFlight === "true") {
        this.setState({ scrollToCurrentFlight: true });
      }
    }
  }

  setUrl = () => {
    setQuery({ scroll: this.state.scrollToCurrentFlight });
  };

  toggleScrollToCurrentFlight = () => {
    this.setState(
      { scrollToCurrentFlight: !this.state.scrollToCurrentFlight },
      this.setUrl
    );
  };

  openSettingsModal = () => {
    this.setState({ isSettingsModalOpen: true });
  };

  closeSettingsModal = () => {
    this.setState({ isSettingsModalOpen: false });
  };

  render() {
    const { platform, meet, tableData, currentAttempt, currentLifter, media } =
      this.props;

    return (
      <FixedHeightWrapper>
        <div className="board-index">
          <div className="util-row">
            <div className="clock">
              <Clock platform={platform} />
            </div>
            <Lights
              meet={meet}
              platformId={get(platform, "_id")}
              style={{ height: 35 }}
            />
            <button
              className="auto-scroll"
              onClick={this.toggleScrollToCurrentFlight}
            >
              Auto Scroll &nbsp;
              {this.state.scrollToCurrentFlight && <CheckIcon />}
            </button>
            <button onClick={this.openSettingsModal}>
              <GearIcon />
            </button>
            <NextAttemptInputTimers />
          </div>
          <div className="table-wrapper">
            <BoardTable
              meet={meet}
              sortedLifters={tableData}
              currentAttempt={currentAttempt}
              currentLifter={currentLifter}
              scrollToCurrentFlight={this.state.scrollToCurrentFlight}
              media={media}
              isSettingsModalOpen={this.state.isSettingsModalOpen}
              closeSettingsModal={this.closeSettingsModal}
              federationConfig={this.props.federationConfig}
            />
          </div>
        </div>
      </FixedHeightWrapper>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    meet: getCurrentMeet(state, props),
    currentAttempt: getCurrentAttempt(state, props),
    currentLifter: getCurrentLifter(state, props),
    platform: getCurrentPlatform(state, props),
    tableData: getScoreBoardTableData(state, props),
    media: getScreenMedia(state, props),
    federationConfig: getFederationConfig(state, props),
  };
};

const actions = {};

export default connect(mapStateToProps, actions)(BoardIndex);
