import React from "react";
import SortableHeaderCell from "components/SortableHeaderCell";
import { Column } from "components/table/Table";
import { Meet } from "types";
import get from "lodash/get";

const ReadOnlyCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: Column;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return <SortableHeaderCell style={style} column={column} />;
  } else {
    return (
      <div style={style} className="table-cell-inner">
        {get(meet, ["platforms", lifter.platformId, "name"])}
      </div>
    );
  }
};

export default ReadOnlyCell;
