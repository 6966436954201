import { createStore, combineReducers, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import get from "lodash/get";
import each from "lodash/each";
import { set, del } from "object-path-immutable";
import { reducer as toastrReducer } from "react-redux-toastr";
import {
  PUT_MEET,
  REMOVE_MEET,
  PUT_RESTRICTED_MEET,
  PUT_ENTRY_CONFIG,
  PUT_RECORDS,
  PUT_PLATFORM,
  REMOVE_PLATFORM,
  SET_CURRENT_MEET_ID,
  PUT_LIFTER,
  PUT_RESTRICTED_LIFTER,
  REMOVE_LIFTER,
  PUT_ATTEMPT,
  START_ATTEMPT_TIMER,
  PUT_DIVISION,
  REMOVE_DIVISION,
  PUT_WEIGHT_CLASS,
  REMOVE_WEIGHT_CLASS,
  PUT_REF,
  MEET_IS_SYNCING,
  MEET_IS_NOT_SYNCING,
  MEET_IS_ACTIVELY_SYNCING,
  MEET_IS_NOT_ACTIVELY_SYNCING,
  MEET_IS_LOADING,
  MEET_IS_NOT_LOADING,
  MEET_IS_LOGGED_IN,
  MEET_IS_LOGGED_OUT,
  MEET_IS_LOCAL,
  MEET_IS_ONLINE,
  MEET_NOT_FOUND,
  SET_SCREEN_SIZE,
  UPDATE_CALCULATED_DATA,
} from "./actionTypes";
import { MeetMetaDataState, MeetsState, ScreenInfoState } from "types";
import { getEligibleRecordsByLifter, getRecordAttempts } from "util/records";

const stateWithCalculatedData = ({
  state,
  isInitialLoad,
  meetId,
}: {
  state: MeetsState;
  isInitialLoad: boolean;
  meetId: string;
}) => {
  if (isInitialLoad) {
    return state;
  }
  if (!state[meetId] || !state[meetId].records) {
    return state;
  }

  let newState = state;

  // const start = Date.now();

  const eligibleRecordMap = getEligibleRecordsByLifter({
    meet: state[meetId],
  });

  newState = set(
    newState,
    [meetId, "calculatedData", "eligibleRecordsByLifter"],
    eligibleRecordMap.eligibleRecordsByLifter
  );
  newState = set(
    newState,
    [meetId, "calculatedData", "liftersForEligibleRecord"],
    eligibleRecordMap.liftersForEligibleRecord
  );

  const recordAttempts = getRecordAttempts({
    meet: newState[meetId],
  });

  newState = set(
    newState,
    [meetId, "calculatedData", "recordAttempts"],
    recordAttempts
  );

  // console.log("newState", newState);
  // console.log("calcing", Date.now() - start);
  return newState;
};

const initialSyncState: MeetMetaDataState = {};

const meetMetaData = (
  state = initialSyncState,
  action: {
    meetId: string;
    type:
      | typeof MEET_IS_LOADING
      | typeof MEET_IS_NOT_LOADING
      | typeof MEET_IS_SYNCING
      | typeof MEET_IS_NOT_SYNCING
      | typeof MEET_IS_ACTIVELY_SYNCING
      | typeof MEET_IS_NOT_ACTIVELY_SYNCING
      | typeof MEET_IS_LOGGED_IN
      | typeof MEET_IS_LOGGED_OUT
      | typeof MEET_IS_LOCAL
      | typeof MEET_IS_ONLINE
      | typeof MEET_NOT_FOUND;
  }
) => {
  const handlers = {
    [MEET_IS_LOADING]: ({ meetId }: { meetId: string }) => {
      return set(state, [meetId, "isLoading"], true);
    },
    [MEET_IS_NOT_LOADING]: ({ meetId }: { meetId: string }) => {
      return set(state, [meetId, "isLoading"], false);
    },
    [MEET_IS_SYNCING]: ({ meetId }: { meetId: string }) => {
      return set(state, [meetId, "isSyncing"], true);
    },
    [MEET_IS_NOT_SYNCING]: ({ meetId }: { meetId: string }) => {
      return set(state, [meetId, "isSyncing"], false);
    },
    [MEET_IS_ACTIVELY_SYNCING]: ({ meetId }: { meetId: string }) => {
      return set(state, [meetId, "isSyncActive"], true);
    },
    [MEET_IS_NOT_ACTIVELY_SYNCING]: ({ meetId }: { meetId: string }) => {
      return set(state, [meetId, "isSyncActive"], false);
    },
    [MEET_IS_LOGGED_IN]: ({ meetId }: { meetId: string }) => {
      let newState = state;
      each(newState, (status, id) => {
        newState = set(newState, [id, "isLoggedIn"], false);
      });
      newState = set(newState, [meetId, "isLoggedIn"], true);
      return newState;
    },
    [MEET_IS_LOGGED_OUT]: ({ meetId }: { meetId: string }) => {
      return set(state, [meetId, "isLoggedIn"], false);
    },
    [MEET_IS_LOCAL]: ({ meetId }: { meetId: string }) => {
      return set(state, [meetId, "isLocal"], true);
    },
    [MEET_IS_ONLINE]: ({ meetId }: { meetId: string }) => {
      return set(state, [meetId, "isOnline"], true);
    },
    [MEET_NOT_FOUND]: ({ meetId }: { meetId: string }) => {
      return set(state, [meetId, "notFound"], true);
    },
  };
  return handlers[action.type] ? handlers[action.type](action) : state;
};

//-----------------------------------------------------------------------------
// meet reducer
//-----------------------------------------------------------------------------
const initialMeetsState = {};

const meets = (
  state: MeetsState = initialMeetsState,
  action: {
    meetId: string;
    isInitialLoad: boolean;
    meet?: any;
    restrictedMeet?: any;
    entryConfig?: any;
    records?: any;
    platform?: any;
    platformId?: string;
    ref?: any;
    lifter?: any;
    restrictedLifter?: any;
    lifterId?: string;
    attempt?: any;
    startTime?: string;
    liftName?: string;
    attemptNumber?: string;
    division?: any;
    weightClass?: any;
    divisionId?: string;

    type:
      | typeof PUT_MEET
      | typeof REMOVE_MEET
      | typeof PUT_RESTRICTED_MEET
      | typeof PUT_ENTRY_CONFIG
      | typeof PUT_RECORDS
      | typeof PUT_PLATFORM
      | typeof REMOVE_PLATFORM
      | typeof PUT_REF
      | typeof SET_CURRENT_MEET_ID
      | typeof PUT_LIFTER
      | typeof REMOVE_LIFTER
      | typeof PUT_RESTRICTED_LIFTER
      | typeof PUT_ATTEMPT
      | typeof START_ATTEMPT_TIMER
      | typeof PUT_DIVISION
      | typeof REMOVE_DIVISION
      | typeof PUT_WEIGHT_CLASS
      | typeof REMOVE_WEIGHT_CLASS;
  }
) => {
  const handlers = {
    [PUT_MEET]: ({ meet }: { meet?: any }) => {
      // Need to save elements not in the meet pouchdb document.
      const platforms = get(state, [meet._id, "platforms"], {});
      const lifters = get(state, [meet._id, "lifters"], {});
      const divisions = get(state, [meet._id, "divisions"], {});
      const entryConfig = get(state, [meet._id, "entryConfig"], {});
      const restricted = get(state, [meet._id, "restricted"], {});
      const records = get(state, [meet._id, "records"]);
      const calculatedData = get(state, [meet._id, "calculatedData"], {});

      return set(state, [meet._id], {
        ...meet,
        lifters,
        divisions,
        platforms,
        entryConfig,
        restricted,
        records,
        calculatedData,
      });
    },
    [REMOVE_MEET]: ({ meetId }: { meetId: string }) => {
      return del(state, [meetId]);
    },
    [PUT_RESTRICTED_MEET]: ({
      restrictedMeet,
      meetId,
    }: {
      meetId: string;
      restrictedMeet?: any;
    }) => {
      return set(state, [meetId, "restricted"], restrictedMeet);
    },
    [PUT_ENTRY_CONFIG]: ({
      entryConfig,
      meetId,
    }: {
      meetId: string;
      entryConfig?: any;
    }) => {
      return set(state, [meetId, "entryConfig"], entryConfig);
    },
    [PUT_RECORDS]: ({ records, meetId }: { meetId: string; records?: any }) => {
      let newState = set(state, [meetId, "records"], records);
      newState = stateWithCalculatedData({
        state: newState,
        meetId,
        isInitialLoad: action.isInitialLoad,
      });

      return newState;
    },
    [PUT_PLATFORM]: ({
      platform,
      meetId,
    }: {
      meetId: string;
      platform?: any;
    }) => {
      const refs = get(state, [meetId, "platforms", platform._id, "refs"], {});
      return set(state, [meetId, "platforms", platform._id], {
        ...platform,
        refs,
      });
    },
    [REMOVE_PLATFORM]: ({
      platform,
      meetId,
    }: {
      meetId: string;
      platform?: any;
    }) => {
      return del(state, [meetId, "platforms", platform._id]);
    },
    [PUT_REF]: ({
      ref,
      platformId,
      meetId,
    }: {
      meetId: string;
      platformId?: string;
      ref?: any;
    }) => {
      return set(
        state,
        [meetId, "platforms", platformId, "refs", ref.position],
        ref
      );
    },
    [SET_CURRENT_MEET_ID]: ({ meetId }: { meetId: string }) => {
      return set(state, "currentMeetId", meetId);
    },
    [PUT_LIFTER]: ({ lifter, meetId }: { meetId: string; lifter?: any }) => {
      // don't lose track of lifts data from other docs
      const lifts = get(state, [meetId, "lifters", lifter._id, "lifts"], {});
      const restricted = get(
        state,
        [meetId, "lifters", lifter._id, "restricted"],
        {}
      );
      const newLifter = { ...lifter, lifts, restricted };

      let newState = set(state, [meetId, "lifters", lifter._id], newLifter);

      newState = stateWithCalculatedData({
        state: newState,
        meetId,
        isInitialLoad: action.isInitialLoad,
      });

      return newState;
    },
    [REMOVE_LIFTER]: ({ lifter, meetId }: { meetId: string; lifter?: any }) => {
      return del(state, [meetId, "lifters", lifter._id]);
    },
    [PUT_RESTRICTED_LIFTER]: ({
      restrictedLifter,
      lifterId,
      meetId,
    }: {
      meetId: string;
      restrictedLifter?: any;
      lifterId?: string;
    }) => {
      if (!lifterId) {
        return state;
      }
      return set(
        state,
        [meetId, "lifters", lifterId, "restricted"],
        restrictedLifter
      );
    },
    [PUT_ATTEMPT]: ({
      attempt,
      lifterId,
      meetId,
    }: {
      meetId: string;
      attempt?: any;
      lifterId?: string;
    }) => {
      // preserve start timer which doesn't exist in database.
      const startTime = get(state, [
        meetId,
        "lifters",
        lifterId,
        "lifts",
        attempt.liftName,
        attempt.attemptNumber,
        "startTime",
      ]);
      let newState = set(
        state,
        [
          meetId,
          "lifters",
          lifterId,
          "lifts",
          attempt.liftName,
          attempt.attemptNumber,
        ],
        { ...attempt, startTime }
      );
      newState = stateWithCalculatedData({
        state: newState,
        meetId,
        isInitialLoad: action.isInitialLoad,
      });

      return newState;
    },
    [START_ATTEMPT_TIMER]: ({
      startTime,
      liftName,
      attemptNumber,
      lifterId,
      meetId,
    }: {
      meetId: string;
      startTime?: string;
      liftName?: string;
      attemptNumber?: string;
      lifterId?: string;
    }) => {
      if (!lifterId || !liftName || !attemptNumber) {
        return state;
      }
      return set(
        state,
        [
          meetId,
          "lifters",
          lifterId,
          "lifts",
          liftName,
          attemptNumber,
          "startTime",
        ],
        startTime
      );
    },
    [PUT_DIVISION]: ({
      division,
      meetId,
    }: {
      meetId: string;
      division?: any;
    }) => {
      const meet = state[meetId] || {};
      // old schema stored weightClasses in their own documents.
      if (division.schema) {
        return set(state, [meetId, "divisions", division._id], division);
      } else {
        const weightClasses = get(
          meet,
          ["divisions", division._id, "weightClasses"],
          {}
        );
        const newDivision = { ...division, weightClasses };

        let newState = set(
          state,
          [meetId, "divisions", division._id],
          newDivision
        );
        newState = stateWithCalculatedData({
          state: newState,
          meetId,
          isInitialLoad: action.isInitialLoad,
        });

        return newState;
      }
    },
    [REMOVE_DIVISION]: ({
      division,
      meetId,
    }: {
      meetId: string;
      division?: any;
    }) => {
      return del(state, [meetId, "divisions", division._id]);
    },
    [PUT_WEIGHT_CLASS]: ({
      weightClass,
      divisionId,
      meetId,
    }: {
      meetId: string;
      weightClass?: any;
      divisionId?: string;
    }) => {
      return set(
        state,
        [meetId, "divisions", divisionId, "weightClasses", weightClass._id],
        weightClass
      );
    },
    [REMOVE_WEIGHT_CLASS]: ({
      weightClass,
      divisionId,
      meetId,
    }: {
      meetId: string;
      weightClass?: any;
      divisionId?: string;
    }) => {
      return del(state, [
        meetId,
        "divisions",
        divisionId,
        "weightClasses",
        weightClass._id,
      ]);
    },
    [UPDATE_CALCULATED_DATA]: ({ meetId }: { meetId: string }) => {
      return stateWithCalculatedData({
        state,
        meetId,
        isInitialLoad: false,
      });
    },
  };
  return handlers[action.type] ? handlers[action.type](action) : state;
};

const screenInfoInitialState = {
  media: "large" as const,
};

const screenInfo = (
  state: ScreenInfoState = screenInfoInitialState,
  action: {
    screenWidth: number;
    type: typeof SET_SCREEN_SIZE;
  }
) => {
  const handlers = {
    [SET_SCREEN_SIZE]: ({ screenWidth }: { screenWidth: number }) => {
      let media: ScreenInfoState["media"] = "small";
      if (screenWidth > 500 && screenWidth <= 900) {
        media = "medium";
      } else if (screenWidth > 900) {
        media = "large";
      }

      return { media };
    },
  };
  return handlers[action.type] ? handlers[action.type](action) : state;
};

//-----------------------------------------------------------------------------
// main reducer
//-----------------------------------------------------------------------------
const applicationReducers = combineReducers({
  meets,
  meetMetaData,
  toastr: toastrReducer,
  screenInfo,
});
const initialState = {};
const rootReducer = (state = initialState, action: any) => {
  const newState = applicationReducers(state, action);
  // console.log("new state:", newState);
  return newState;
};

export const getStore = () => createStore(rootReducer, applyMiddleware(thunk));

export const store = createStore(rootReducer, applyMiddleware(thunk));
export type ReduxStore = typeof store;
