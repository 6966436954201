import { FedConfig, Federation } from "types";

export const federationConfigs: Record<Federation, FedConfig> = {
  "100RAW": {
    fullName: "100% Raw Powerlifting",
    fourthAttempts: true,
    recordOptions: {
      "FULL-POWER": ["SQUAT", "BENCH-PRESS", "DEADLIFT", "TOTAL"],
      "SINGLE-LIFT": ["BENCH-PRESS", "DEADLIFT"],
    },
  },

  APA: {
    fullName: "APA",
    fourthAttempts: false,
    recordOptions: {
      "FULL-POWER": ["SQUAT", "BENCH-PRESS", "DEADLIFT", "TOTAL"],
      "SINGLE-LIFT": ["BENCH-PRESS", "DEADLIFT"],
    },
  },
  CPU: {
    fullName: "Canadian Powerlifting Union",
    fourthAttempts: false,
    recordOptions: {
      "FULL-POWER": ["SQUAT", "BENCH-PRESS", "DEADLIFT", "TOTAL"],
      "SINGLE-LIFT": ["BENCH-PRESS", "DEADLIFT"],
    },
  },

  IPA: {
    fullName: "International Powerlifting Association",
    fourthAttempts: true,
    recordOptions: {
      "FULL-POWER": ["SQUAT", "BENCH-PRESS", "DEADLIFT", "TOTAL"],
      "SINGLE-LIFT": ["BENCH-PRESS", "DEADLIFT"],
    },
  },
  IPF: {
    fullName: "International Powerlifting Federation",
    fourthAttempts: false,
    recordOptions: {
      "FULL-POWER": ["SQUAT", "BENCH-PRESS", "DEADLIFT", "TOTAL"],
      "SINGLE-LIFT": ["BENCH-PRESS"],
    },
  },
  MMP: {
    fullName: "Metal Militia Powerlifting",
    fourthAttempts: true,
    recordOptions: {
      "FULL-POWER": ["SQUAT", "BENCH-PRESS", "DEADLIFT", "TOTAL"],
      "SINGLE-LIFT": ["BENCH-PRESS", "DEADLIFT"],
    },
  },
  POWERLIFTING_AMERICA: {
    fullName: "Powerlifting America",
    fourthAttempts: false,
    recordOptions: {
      "FULL-POWER": ["SQUAT", "BENCH-PRESS", "DEADLIFT", "TOTAL"],
      "SINGLE-LIFT": ["BENCH-PRESS"],
    },
  },
  RPS: {
    fullName: "Revolution Powerlifting Syndicate",
    fourthAttempts: true,
    recordOptions: {
      "FULL-POWER": ["SQUAT", "BENCH-PRESS", "DEADLIFT", "TOTAL"],
      "SINGLE-LIFT": ["BENCH-PRESS", "DEADLIFT"],
    },
  },
  SSF: {
    fullName: "Svenska Styrkelyftförbundet",
    fourthAttempts: false,
    recordOptions: {
      "FULL-POWER": ["SQUAT", "BENCH-PRESS", "DEADLIFT", "TOTAL"],
      "SINGLE-LIFT": ["BENCH-PRESS", "DEADLIFT"],
    },
  },
  USAPL: {
    fullName: "USA Powerlifting",
    fourthAttempts: false,
    recordOptions: {
      "FULL-POWER": ["SQUAT", "BENCH-PRESS", "DEADLIFT", "TOTAL"],
      "SINGLE-LIFT": ["BENCH-PRESS", "DEADLIFT"],
    },
  },
  USPA: {
    fullName: "United States Powerlifting Association",
    fourthAttempts: true,
    recordOptions: {
      "FULL-POWER": ["SQUAT", "BENCH-PRESS", "DEADLIFT", "TOTAL"],
      "SINGLE-LIFT": ["BENCH-PRESS", "DEADLIFT"],
    },
  },
  USSF: {
    fullName: "US Strengthlifting",
    fourthAttempts: false,
    recordOptions: {
      "FULL-POWER": ["SQUAT", "BENCH-PRESS", "DEADLIFT", "TOTAL"],
      "SINGLE-LIFT": ["BENCH-PRESS", "DEADLIFT"],
    },
  },
  WPP: {
    fullName: "World Para Powerlifting",
    fourthAttempts: false,
    recordOptions: {
      "FULL-POWER": ["SQUAT", "BENCH-PRESS", "DEADLIFT", "TOTAL"],
      "SINGLE-LIFT": ["BENCH-PRESS", "DEADLIFT"],
    },
  },
  WRPF: {
    fullName: "World Raw Powerlifting Federation",
    fourthAttempts: true,
    recordOptions: {
      "FULL-POWER": ["SQUAT", "BENCH-PRESS", "DEADLIFT", "TOTAL"],
      "SINGLE-LIFT": ["BENCH-PRESS", "DEADLIFT"],
      "PUSH-PULL": ["BENCH-PRESS", "DEADLIFT", "TOTAL"],
    },
  },
  PLU: {
    fullName: "Powerlifting United",
    fourthAttempts: false,
    recordOptions: {
      "FULL-POWER": ["SQUAT", "BENCH-PRESS", "DEADLIFT", "TOTAL"],
      "SINGLE-LIFT": ["BENCH-PRESS", "DEADLIFT"],
      "PUSH-PULL": ["BENCH-PRESS", "DEADLIFT", "TOTAL"],
    },
  },
  OTHER: {
    fullName: "Other",
    fourthAttempts: true,
    recordOptions: {
      "FULL-POWER": ["SQUAT", "BENCH-PRESS", "DEADLIFT", "TOTAL"],
      "SINGLE-LIFT": ["SQUAT", "BENCH-PRESS", "DEADLIFT"],
      "PUSH-PULL": ["BENCH-PRESS", "DEADLIFT", "TOTAL"],
    },
  },
};
