import React from "react";
import get from "lodash/get";
import map from "lodash/map";
import Table from "components/table/Table";
import DefaultTextCell from "components/cells/DefaultTextCell";
import RecordsCell from "./cells/RecordsCell";
import ThumbsUpIcon from "icons/ThumbsUpIcon";
import ThumbsDownIcon from "icons/ThumbsDownIcon";
import { Attempt, RefPosition } from "types";
import { useMeet } from "util/useMeet";
import { useLifter } from "util/useLifter";
import { useMediaSize } from "util/useMediaSize";
import { useRecordAttempts } from "util/useRecordAttempts";

const Result = ({ result }: { result: "good" | "bad" }) => {
  if (result === "good") {
    return <ThumbsUpIcon className="good" />;
  } else if (result === "bad") {
    return <ThumbsDownIcon className="bad" />;
  } else {
    return null;
  }
};

const FaultCards = ({
  attempt,
  position,
}: {
  attempt: Attempt;
  position: RefPosition;
}) => {
  return (
    <div className="fault-cards">
      <Result result={get(attempt, ["decisions", position, "decision"])} />
      {get(attempt, ["decisions", position, "cards", "red"]) && (
        <div className="fault-card red" />
      )}
      {get(attempt, ["decisions", position, "cards", "blue"]) && (
        <div className="fault-card blue" />
      )}
      {get(attempt, ["decisions", position, "cards", "yellow"]) && (
        <div className="fault-card yellow" />
      )}
    </div>
  );
};

const AttemptTable = () => {
  const meet = useMeet();
  const media = useMediaSize();
  const lifter = useLifter();

  const getAllColumns = () => {
    const columns = [
      { key: "name", label: "Lift", width: 80, renderer: DefaultTextCell },
      { key: "weight", label: "Weight", width: 65, renderer: DefaultTextCell },
      { key: "result", label: "Result", width: 65, renderer: DefaultTextCell },
      { key: "left", label: "Left", width: 170, renderer: DefaultTextCell },
      { key: "head", label: "Head", width: 170, renderer: DefaultTextCell },
      { key: "right", label: "Right", width: 170, renderer: DefaultTextCell },
    ];
    if (meet.records) {
      columns.push({
        key: "possibleRecords",
        label: "Possible Records",
        width: 400,
        renderer: RecordsCell,
      });
    }
    return columns;
  };

  const lifts = get(lifter, "lifts");
  const attempts = [
    get(lifts, ["squat", 1]),
    get(lifts, ["squat", 2]),
    get(lifts, ["squat", 3]),
    get(lifts, ["bench", 1]),
    get(lifts, ["bench", 2]),
    get(lifts, ["bench", 3]),
    get(lifts, ["dead", 1]),
    get(lifts, ["dead", 2]),
    get(lifts, ["dead", 3]),
  ];

  const allRecords = useRecordAttempts();

  const dataArray = map(attempts, (attempt) => {
    if (!attempt) {
      return { name: "ERROR" };
    }
    return {
      ...attempt,
      name: `${attempt.liftName} ${attempt.attemptNumber}`,
      weight: attempt.weight,
      result: <Result result={attempt.result} />,
      left: <FaultCards attempt={attempt} position="left" />,
      head: <FaultCards attempt={attempt} position="head" />,
      right: <FaultCards attempt={attempt} position="right" />,
      possibleRecords: allRecords?.[attempt._id],
    };
  });

  dataArray.unshift({ row: "header" });

  const getCellLines = (index: number) => {
    const data = dataArray[index];
    if (data.possibleRecords) {
      return data.possibleRecords.length === 1
        ? 1
        : data.possibleRecords.length * 0.5;
    }
    return 1;
  };

  return (
    <div className="attempt-table">
      <Table
        numberOfFixedLeftColumns={1}
        data={dataArray}
        columns={getAllColumns()}
        getCellLines={getCellLines}
        meet={meet}
        media={media}
      />
    </div>
  );
};

export default AttemptTable;
