import React from "react";
import "./JurySheet.scss";
import {
  getSessionOptions,
  getFlightOptions,
  getPlatformOptions,
} from "util/options";
import { getLifterAwardsWeightClassDoc } from "util/lifterHelper";
import { useMeet } from "util/useMeet";
import sortBy from "lodash/sortBy";
import map from "lodash/map";
import filter from "lodash/filter";
import size from "lodash/size";
import assign from "lodash/assign";
import fill from "lodash/fill";
import { Lifter, Meet, WeightClass } from "types";
import find from "lodash/find";
import get from "lodash/get";
import first from "lodash/first";

const LiftCell = ({ lift }: { lift?: { weight?: number } }) => {
  return (
    <table className="lift-cell">
      <tbody>
        <tr>
          <td className="infraction-card"></td>
          <td className="infraction-card"></td>
          <td className="infraction-card"></td>
        </tr>
        <tr>
          <td colSpan={3}>{lift && lift.weight}</td>
        </tr>
      </tbody>
    </table>
  );
};

const SheetBody = ({
  meet,
  platformId,
  session,
  flight,
  lifters,
}: {
  meet: Meet;
  platformId: string;
  session: number;
  flight: string;
  lifters: Lifter[];
}) => {
  // ensure we have at least 15 rows
  if (size(lifters) < 15) {
    lifters = assign(fill(new Array(15), {}), lifters);
  }

  lifters = sortBy(lifters, ["name", "lot"]);

  const platform = find(meet.platforms, { _id: platformId });
  const platformName = get(platform, "name");

  return (
    <div className="flight-pages">
      <table>
        <thead>
          <tr>
            <th colSpan={16}>
              {meet.date} - {meet.name} - Session {session} - Platform{" "}
              {platformName} - Flight {flight}
            </th>
          </tr>
          <tr>
            <th className="name-cell">NAME</th>
            <th>LOT #</th>
            <th>BWT</th>
            <th className="weight-class-cell">WT CLS</th>
            <th className="rack-cell">S RACK</th>
            <th className="rack-cell">B RACK</th>
            <th className="lift-cell">SQ-1</th>
            <th className="lift-cell">SQ-2</th>
            <th className="lift-cell">SQ-3</th>
            <th className="lift-cell">BP-1</th>
            <th className="lift-cell">BP-2</th>
            <th className="lift-cell">BP-3</th>
            <th className="lift-cell">DL-1</th>
            <th className="lift-cell">DL-2</th>
            <th className="lift-cell">DL-3</th>
          </tr>
        </thead>
        <tbody>
          {map(lifters, (lifter, index: number) => {
            const lifterDivision = first(lifter.divisions);
            let weightClass: string | undefined = "";
            if (lifterDivision) {
              const weightClassDoc = getLifterAwardsWeightClassDoc(
                meet,
                lifter,
                lifterDivision
              ) as WeightClass;
              if (weightClassDoc) {
                weightClass = weightClassDoc.name;
              }
            }
            return (
              <tr key={lifter._id || index}>
                <td className="name-cell">{lifter.name}</td>
                <td>{lifter.lot}</td>
                <td>{lifter.bodyWeight}</td>
                <td className="weight-class-cell">{weightClass}</td>
                <td className="rack-cell">{lifter.squatRackHeight}</td>
                <td className="rack-cell">{lifter.benchRackHeight}</td>
                <td className="lift-cell-wrapper">
                  <LiftCell lift={get(lifter, ["lifts", "squat", "1"])} />
                </td>
                <td className="lift-cell-wrapper">
                  <LiftCell lift={get(lifter, ["lifts", "squat", "2"])} />
                </td>
                <td className="lift-cell-wrapper">
                  <LiftCell lift={get(lifter, ["lifts", "squat", "3"])} />
                </td>
                <td className="lift-cell-wrapper">
                  <LiftCell lift={get(lifter, ["lifts", "bench", "1"])} />
                </td>
                <td className="lift-cell-wrapper">
                  <LiftCell lift={get(lifter, ["lifts", "bench", "2"])} />
                </td>
                <td className="lift-cell-wrapper">
                  <LiftCell lift={get(lifter, ["lifts", "bench", "3"])} />
                </td>
                <td className="lift-cell-wrapper">
                  <LiftCell lift={get(lifter, ["lifts", "dead", "1"])} />
                </td>
                <td className="lift-cell-wrapper">
                  <LiftCell lift={get(lifter, ["lifts", "dead", "2"])} />
                </td>
                <td className="lift-cell-wrapper">
                  <LiftCell lift={get(lifter, ["lifts", "dead", "3"])} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const JurySheet = () => {
  const meet = useMeet();
  const lifters = sortBy(meet.lifters, ["name"]);

  return (
    <div className="jury-sheet">
      {map(getSessionOptions(), (session) => {
        const sessionLifters = filter(lifters, { session: session.value });
        const sessionCount = size(sessionLifters);
        if (!sessionCount) {
          return null;
        }

        return (
          <div key={session.value}>
            {map(getPlatformOptions({}, meet), (platform) => {
              const platformLifters = filter(sessionLifters, {
                platformId: platform.value,
              });
              const platformCount = size(platformLifters);

              if (!platformCount) {
                return null;
              }

              return (
                <div key={platform.value}>
                  {map(getFlightOptions(), (flight) => {
                    const flightLifters = filter(platformLifters, {
                      flight: flight.value,
                    });
                    const flightCount = size(flightLifters);
                    if (!flightCount) {
                      return null;
                    }

                    return (
                      <SheetBody
                        key={flight.value}
                        meet={meet}
                        platformId={platform.value}
                        session={session.value}
                        flight={flight.value}
                        lifters={flightLifters}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default JurySheet;
