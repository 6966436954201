import React from "react";
import Modal from "react-modal";
import { NavLink } from "react-router-dom";

import HamburgerIcon from "icons/HamburgerIcon";
import CloseIcon from "icons/CloseIcon";
import LogoIcon from "icons/LogoIcon";
import classNames from "classnames";

const HomeNavigation = ({ scrolled }: { scrolled: boolean }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleOpen = (e?: { stopPropagation: () => void }) => {
    setIsOpen((prev) => !prev);
    if (e) {
      e.stopPropagation();
    }
  };

  const NavItem = ({ href, text }: { href: string; text: string }) => {
    return (
      <li>
        {href.startsWith("#") || href.endsWith(".html") ? (
          <a href={href} onClick={toggleOpen}>
            {text}
          </a>
        ) : (
          <NavLink to={href} onClick={toggleOpen}>
            {text}
          </NavLink>
        )}
      </li>
    );
  };

  return (
    <div className={classNames("home-nav-wrapper", scrolled && "scrolled")}>
      <div className="navigation">
        <div className="homepage-nav-bar">
          <button className="icon" onClick={toggleOpen}>
            <HamburgerIcon />
          </button>
          <LogoIcon className="homepage-nav-logo" />{" "}
        </div>
        <Modal
          ariaHideApp={false}
          isOpen={isOpen}
          closeTimeoutMS={400}
          onRequestClose={toggleOpen}
          className={{
            base: "navigation-modal",
            afterOpen: "navigation-modal-after-open",
            beforeClose: "navigation-modal-before-close",
          }}
          overlayClassName={{
            base: "navigation-modal-overlay",
            afterOpen: "navigation-modal-overlay-after-open",
            beforeClose: "navigation-modal-overlay-before-close",
          }}
          contentLabel="Navigation Menu"
        >
          <div className="close-button" onClick={toggleOpen}>
            <CloseIcon />
          </div>
          <div className="content">
            <ul>
              <NavItem href="#upcoming-meets" text="Upcoming Meets" />
              <NavItem href="#recent-meets" text="Recent Meets" />
              <NavItem href="#your-meets" text="Your Meets" />
              <NavItem href="#features" text="Features" />
              <NavItem href="/about" text="FAQ" />
              <NavItem href="/instructions" text="Meet Director Instructions" />
              <NavItem href="/coach" text="Coach / Lifter Instructions" />
              <NavItem href="/changes.html" text="Changelog" />
              <NavItem href="/contact" text="Contact" />
            </ul>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default HomeNavigation;
