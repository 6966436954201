import React, { Component } from 'react';
import { Route } from 'react-router-dom';

class RouteWithProps extends Component {
  render() {
    const {
      isLoggedIn,
      isLocal,
      match,
      component: Component,
      onFinishBulkCreate,
      openPasswordModal,
      openDonateModal,
      blockLocalAccess,
      ...rest
    } = this.props;

    return (
      <Route {...rest} render={(matchProps) => {
        return (<Component {...matchProps} onFinishBulkCreate={onFinishBulkCreate} openPasswordModal={openPasswordModal} openDonateModal={openDonateModal} />);
      }} />
    );
  }
}

export default RouteWithProps;
