import React from "react";
import classNames from "classnames";
import { isCompetingInLift } from "util/lifterHelper";
import { Column } from "components/table/Table";
import { Meet } from "types";
import get from "lodash/get";

const AttemptCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: Column;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else if (lifter.row === "title") {
    return <div style={style} className="table-cell-inner" />;
  } else {
    if (!column.lift || !column.attemptNumber) {
      return null;
    }
    const attempt = get(lifter, ["lifts", column.lift, column.attemptNumber]);
    if (!attempt || !isCompetingInLift(lifter, attempt.liftName, meet)) {
      return null;
    }

    return (
      <div
        style={style}
        className={classNames("table-cell-inner", attempt.result || "")}
      >
        {get(attempt, column.key)}
      </div>
    );
  }
};

export default AttemptCell;
