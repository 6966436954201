import React from "react";
import PouchSelect from "components/PouchSelect";
import SortableHeaderCell from "components/SortableHeaderCell";
import { Column } from "components/table/Table";
import { Meet } from "types";
import get from "lodash/get";

const TeamCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: Column;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return <SortableHeaderCell style={style} column={column} />;
  } else {
    if (!column.options) {
      return null;
    }
    return (
      <div style={style} className="table-cell-inner">
        <PouchSelect
          meet={meet}
          document={lifter}
          name={column.key}
          value={get(lifter, column.key)}
          options={column.options}
          allowCreate
        />
      </div>
    );
  }
};

export default TeamCell;
