import React from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import findIndex from "lodash/findIndex";
import reject from "lodash/reject";
import map from "lodash/map";
import round from "lodash/round";
import { Link } from "react-router-dom";
import {
  getRealAge,
  getDivisionDoc,
  getLifterAwardsWeightClassDoc,
} from "util/lifterHelper";
import { wilksCoef, ageCoef, dotsCoef } from "util/coefficients";
import { getCompleteLiftingOrder } from "selectors";
import { getBenchLabel } from "util/meetHelper";
import "./LifterIndex.scss";
import { ReduxState, WeightClass } from "types";
import {
  formatEligibleRecord,
  getPercentOfRecordEligibleRecord,
} from "util/records";
import { useLifter } from "util/useLifter";
import { useMeet } from "util/useMeet";

const LifterInfo = () => {
  const meet = useMeet();
  const lifter = useLifter();

  const completeLiftingOrder = useSelector((state: ReduxState) =>
    getCompleteLiftingOrder(state, {
      match: {
        params: { meetId: meet._id, platformId: lifter.platformId ?? "" },
      },
    })
  );

  if (!lifter || !meet) {
    return null;
  }

  const remainingAttempts = reject(completeLiftingOrder, (a) =>
    get(a, ["attempt", "result"])
  );
  let out: number | null = findIndex(
    remainingAttempts,
    (a) => get(a, ["lifter", "_id"]) === lifter._id
  );
  if (out === -1) {
    out = null;
  }

  const divisions = map(lifter.divisions, (lifterDivision) => {
    const divisionDoc = getDivisionDoc(lifterDivision.divisionId, meet);

    const weightClassDoc: WeightClass | undefined =
      getLifterAwardsWeightClassDoc(meet, lifter, lifterDivision) as
        | WeightClass
        | undefined;

    return { division: divisionDoc, weightClass: weightClassDoc };
  });

  const team = get(lifter, "team", "");

  return (
    <div className="lifter-info">
      <div className="info-row">
        <label>Name:</label>
        <div>{lifter.name}</div>
      </div>
      <div className="info-row">
        <label>Team:</label>
        <div>
          {team && (
            <Link
              to={`/meets/${get(meet, "_id")}/team/${encodeURIComponent(team)}`}
            >
              {team}
            </Link>
          )}
        </div>
      </div>
      <div className="info-row">
        <label>Divisions:</label>
        <div>
          {map(divisions, (d) => {
            const percentScoring = d.division?.scoreBy === "PERCENT_OF_RECORD";
            const record = percentScoring
              ? getPercentOfRecordEligibleRecord({
                  meet,
                  lifter,
                  division: d.division,
                })
              : null;
            return (
              <div key={d.division?._id}>
                {d.division?.name} - {d.weightClass?.name}
                {percentScoring && (
                  <div style={{ fontSize: 14, marginBottom: 8 }}>
                    % of Record Scoring:{" "}
                    {record
                      ? formatEligibleRecord({ record })
                      : "No record found"}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="info-row">
        <label>Platform:</label>
        <div>
          <Link
            to={`/meets/${get(meet, "_id")}/platforms/${get(
              lifter,
              "platformId"
            )}/board`}
          >
            {
              get(
                meet,
                ["platforms", lifter.platformId ?? "", "name"],
                ""
              ) as string
            }
          </Link>
        </div>
      </div>
      <div className="info-row">
        <label>Session:</label>
        <div>{lifter.session}</div>
      </div>
      <div className="info-row">
        <label>Flight:</label>
        <div>{lifter.flight}</div>
      </div>
      <div className="info-row">
        <label>Age:</label>
        <div>{getRealAge(lifter, meet)}</div>
      </div>
      <div className="info-row">
        <label>Age Coef:</label>
        <div>{ageCoef(lifter, meet)}</div>
      </div>
      <div className="info-row">
        <label>Body Weight:</label>
        <div>
          {lifter.bodyWeight} {lifter.bodyWeight && <span> {meet.units}</span>}
        </div>
      </div>
      <div className="info-row">
        <label>Wilks Coef:</label>
        <div>{lifter.bodyWeight && round(wilksCoef(lifter, meet), 6)}</div>
      </div>
      <div className="info-row">
        <label>DOTS Coef:</label>
        <div>{lifter.bodyWeight && round(dotsCoef(lifter, meet), 6)}</div>
      </div>
      <div className="info-row">
        <label>Lot #:</label>
        <div>{lifter.lot}</div>
      </div>
      <div className="info-row">
        <label>Squat Rack Height:</label>
        <div>{lifter.squatRackHeight}</div>
      </div>
      <div className="info-row">
        <label>{getBenchLabel(meet)} Rack Height:</label>
        <div>{lifter.benchRackHeight}</div>
      </div>
      <div className="info-row">
        <label>Attempts Out:</label>
        <div>{out}</div>
      </div>
    </div>
  );
};

export default LifterInfo;
