import React from "react";
import Select from "components/select/Select";
import { exportDivisions } from "util/exportData";
import { getGenderOptions, getRawOrEquippedOptions } from "util/options";
import FixedHeightWrapper from "app/meets/FixedHeightWrapper";
import DivisionsTable from "./DivisionsTable";
import GenerateModal from "./GenerateModal";
import FiltersIcon from "icons/FiltersIcon";
import Drawer from "components/drawer/Drawer";

import "./DivisionsIndex.scss";
import { useMediaSize } from "util/useMediaSize";
import { useMeet } from "util/useMeet";
import filter from "lodash/filter";
import includes from "lodash/includes";
import toLower from "lodash/toLower";
import FileIcon from "icons/FileIcon";

const initialFilters: {
  name: string;
  gender: string | null;
  rawOrEquipped: string | null;
} = {
  name: "",
  gender: null,
  rawOrEquipped: null,
};

const DivisionsIndex = ({
  onFinishBulkCreate,
}: {
  onFinishBulkCreate: () => void;
}) => {
  const media = useMediaSize();
  const meet = useMeet();
  const [showModal, setShowModal] = React.useState(false);
  const [filters, setFilters] = React.useState(initialFilters);

  const openGenerateDivisionsModal = () => {
    setShowModal(true);
  };

  const closeGenerateDivisionsModal = () => {
    setShowModal(false);
  };

  const clearFilters = () => {
    setFilters(initialFilters);
  };

  const onChangeNameFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters((prev) => ({ ...prev, name: e.target.value }));
  };

  const onChangeSelectFilter = (
    name: keyof typeof initialFilters,
    value: string | number | undefined | null
  ) => {
    if (!value || typeof value !== "string") {
      setFilters({ ...filters, [name]: null });
    } else {
      setFilters({ ...filters, [name]: value });
    }
  };

  const filteredDivisions = filter(meet.divisions, (d) => {
    const nameSearchMatch = includes(toLower(d.name), toLower(filters.name));
    const genderMatch = !filters.gender || d.gender === filters.gender;
    const rawOrEquippedMatch =
      !filters.rawOrEquipped || d.rawOrEquipped === filters.rawOrEquipped;

    // don't show division without an id. Either data hasn't fully loaded or something went wrong.
    const validId = !!d._id;

    return validId && nameSearchMatch && genderMatch && rawOrEquippedMatch;
  });

  const activeFilters = !!(
    filters.name ||
    filters.gender ||
    filters.rawOrEquipped
  );

  return (
    <FixedHeightWrapper>
      <div className="divisions-index">
        <div className="util-row">
          <button
            onClick={() => exportDivisions(meet, meet.divisions)}
            style={{ marginRight: 12 }}
          >
            <FileIcon /> &nbsp; Export Divisions
          </button>
          <button onClick={openGenerateDivisionsModal}>
            Generate Divisions
          </button>
          <Drawer
            trigger={
              <>
                <FiltersIcon active={activeFilters} />
                &nbsp; Filter
              </>
            }
          >
            <div className="title">Divison Filters</div>
            <button className="clear-button" onClick={clearFilters}>
              Clear All
            </button>
            <div className="row">
              Name:
              <input
                type="text"
                onChange={onChangeNameFilter}
                value={filters.name ?? ""}
              />
            </div>
            <div className="row">
              Gender:&nbsp;
              <Select
                name="gender"
                value={filters.gender}
                options={getGenderOptions({}, meet)}
                onChange={(value) => onChangeSelectFilter("gender", value)}
              />
            </div>
            <div className="row">
              Raw or Equipped:&nbsp;
              <Select
                name="rawOrEquipped"
                value={filters.rawOrEquipped}
                options={getRawOrEquippedOptions({}, meet)}
                onChange={(value) =>
                  onChangeSelectFilter("rawOrEquipped", value)
                }
              />
            </div>
          </Drawer>
        </div>
        <div className="table-wrapper">
          <DivisionsTable
            meet={meet}
            divisions={filteredDivisions}
            media={media}
          />
        </div>
        <GenerateModal
          meet={meet}
          isOpen={showModal}
          onRequestClose={closeGenerateDivisionsModal}
          onFinishBulkCreate={onFinishBulkCreate}
        />
      </div>
    </FixedHeightWrapper>
  );
};

export default DivisionsIndex;
