import React, { Component } from "react";
import Modal from "react-modal";
import StripeCheckout from "react-stripe-checkout";
import { errorReporter } from "util/errorReporter";
import { fetchWrapper } from "util/api";
import { updateAttributeOnDocument } from "util/pouchActions";
import { isPaid } from "util/meetHelper";
import "./DonateModal.scss";
import { Meet } from "types";
import round from "lodash/round";
import toNumber from "lodash/toNumber";
import size from "lodash/size";

type DonateModalProps = {
  meet: Meet;
  onRequestClose: () => void;
  isOpen: boolean;
};
class DonateModal extends Component<
  DonateModalProps,
  {
    email: string;
    message: string;
    complete: boolean;
    processing: boolean;
    apiError: string;
    cardCharged: boolean;
    amount: number;
  }
> {
  constructor(props: DonateModalProps) {
    super(props);
    this.state = {
      email: this.props.meet.contactEmail || "",
      message: "",
      complete: false,
      processing: false,
      apiError: "",
      cardCharged: false,
      amount: this.calcuateCost(),
    };
  }

  handleChange = (name: string, value: string) => {
    if (name === "amount") {
      const numberValue = round(toNumber(value), 2);
      this.setState({ [name]: numberValue });
      return;
    }
    this.setState({ ...this.state, [name]: value });
  };

  calcuateCost = () => {
    return size(this.props.meet.lifters) * 1.25;
  };

  onToken = (token: any) => {
    this.setState({ processing: true });
    window.scrollTo(0, 0);

    const body = {
      stripeToken: token,
      email: this.state.email,
      amountInCents: toNumber(this.state.amount) * 100,
      meetId: this.props.meet._id,
      meetName: this.props.meet.name,
      message: this.state.message,
    };
    fetchWrapper(`/api/donation`, "POST", body)
      .then((response) => {
        if (response.ok) {
          this.setState({
            processing: false,
            complete: true,
            apiError: "",
            cardCharged: response.cardCharged,
          });
          updateAttributeOnDocument(
            this.props.meet._id,
            this.props.meet._id,
            "payment_status",
            "PAID"
          );
        } else {
          this.setState({
            processing: false,
            apiError: response.error,
            cardCharged: response.cardCharged,
          });
        }
      })
      .catch((error) => {
        errorReporter({ error });
        this.setState({
          processing: false,
          apiError:
            "Something went wrong. Please contact support before trying to make another payment.",
        });
      });
  };

  render() {
    const { onRequestClose, isOpen, meet } = this.props;
    const paid = isPaid(meet);

    return (
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        contentLabel="Donate"
        onRequestClose={onRequestClose}
        className={{
          base: "donate-modal",
          afterOpen: "donate-modal-after-open",
          beforeClose: "donate-modal-before-close",
        }}
        overlayClassName={{
          base: "donate-modal-overlay",
          afterOpen: "donate-modal-overlay-after-open",
          beforeClose: "donate-modal-overlay-before-close",
        }}
      >
        <div className="content">
          {this.state.complete && (
            <div className="donate">
              <div className="message">Thank you for your payment.</div>
            </div>
          )}
          {!this.state.complete && (
            <div className="donate">
              {this.state.processing && (
                <div>
                  <div className="processing-overlay" />
                  <div className="processing-spinner">
                    <div className="loader" />
                  </div>
                </div>
              )}
              {this.state.apiError && (
                <div className="api-error">
                  <div>There was an error processing your request.</div>
                  {!this.state.cardCharged && (
                    <div>Your card was not charged.</div>
                  )}
                  <div>{this.state.apiError}</div>
                </div>
              )}

              <div className="info">
                {!paid && (
                  <div>
                    Your meet has {size(meet.lifters)} lifters.
                    <br />
                    At $1.25 per lifter you owe $
                    {this.calcuateCost().toFixed(2)}.
                  </div>
                )}
                {!meet.payment_status && <div>Thanks for payment.</div>}
              </div>

              <div className="row">
                <label>Email *</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    value={this.state.email}
                    onChange={(e) => this.handleChange("email", e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <label>Payment Amount in USD*</label>
                <div className="input-wrapper">
                  <input
                    type="number"
                    value={this.state.amount}
                    onChange={(e) =>
                      this.handleChange("amount", e.target.value)
                    }
                  />
                </div>
              </div>

              <div className="row">
                <label>Message</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    value={this.state.message}
                    onChange={(e) =>
                      this.handleChange("message", e.target.value)
                    }
                  />
                </div>
              </div>

              <StripeCheckout
                token={this.onToken}
                amount={toNumber(this.state.amount) * 100}
                ComponentClass="div"
                stripeKey="pk_live_nDELpIQeJEFmz5LCGu9v9otN"
                // stripeKey='pk_test_C0jAFLZPEPLoYS26Yyt5cIeG'
                name="LiftingCast"
                description="LiftingCast Payment"
                currency="USD"
                email={this.state.email}
                allowRememberMe={false}
              >
                <button className="pay-button">Pay</button>
              </StripeCheckout>
            </div>
          )}
        </div>
        <div className="button-row">
          <button onClick={onRequestClose} style={{ marginRight: 12 }}>
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

export default DonateModal;
