import React from "react";
import ColorPicker from "components/colorPicker/ColorPicker";
import { updateAttributeOnDocument } from "util/pouchActions";
import { GenericDocument, Meet } from "types";

const PouchColorPicker = ({
  value,
  document,
  name,
  meet,
}: {
  value: string | undefined;
  document: GenericDocument;
  name: string;
  meet: Meet;
}) => {
  const handleChange = (hex: string | null | undefined) => {
    updateAttributeOnDocument(meet._id, document._id, name, hex);
  };

  return <ColorPicker value={value} onChange={handleChange} />;
};

export default PouchColorPicker;
