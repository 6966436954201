import reject from "lodash/reject";
import join from "lodash/join";
import React from "react";
import map from "lodash/map";
import get from "lodash/get";

const LiftsCell = ({
  data,
  column,
  style,
}: {
  data: any;
  column: any;
  style: any;
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    let lifts: any = map(get(lifter, column.key), (value, liftName) => {
      if (value) {
        return liftName;
      }

      return null;
    });

    lifts = reject(lifts, (l) => !l);

    lifts = join(lifts, ", ");

    return (
      <div style={style} className="table-cell-inner">
        {lifts}
      </div>
    );
  }
};

export default LiftsCell;
