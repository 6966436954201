import React from "react";
import classNames from "classnames";
import {
  getDivisionDoc,
  getLifterAwardsWeightClassDoc,
} from "util/lifterHelper";
import { useMeet } from "util/useMeet";
import { useCurrentAttempt } from "util/useCurrentAttempt";
import { usePlatform } from "util/usePlatform";
import { useCurrentLifter } from "util/useCurrentLifter";
import { AttemptNumber, LiftName, Lifter } from "types";
import get from "lodash/get";
import size from "lodash/size";
import capitalize from "lodash/capitalize";

const AttemptCell = ({
  currentLifter,
  liftName,
  attemptNumber,
  currentAttemptNumber,
}: {
  currentLifter: Lifter | undefined;
  liftName: LiftName | undefined;
  attemptNumber: AttemptNumber;
  currentAttemptNumber: AttemptNumber;
}) => {
  let weight: string | number = "";
  let result = "";
  if (liftName) {
    const attempt = get(currentLifter, ["lifts", liftName, attemptNumber]);
    weight = get(attempt, "weight", "");
    result = get(attempt, "result", "");
  }

  return (
    <div
      className={classNames("weight-cell", result, {
        "current-attempt": currentAttemptNumber === attemptNumber,
      })}
    >
      {weight}
    </div>
  );
};

const Platform2 = ({ platformId }: { platformId: string }) => {
  const meet = useMeet();
  const currentAttempt = useCurrentAttempt(platformId);
  const platform = usePlatform(platformId);
  const currentLifter = useCurrentLifter(platformId);

  if (!platform || !meet) {
    return null;
  }

  const lifterName = get(currentLifter, "name", "-------- ------------");
  const teamName = currentLifter
    ? get(currentLifter, "team", "")
    : "-----------";
  const liftName: LiftName | undefined = get(currentAttempt, "liftName");
  let liftDisplayName = capitalize(liftName);
  if (liftName === "bench" && meet.federation === "USSF") {
    liftDisplayName = "Press";
  }
  const currentAttemptNumber = get(currentAttempt, "attemptNumber", "1");

  let divisionName = "---------------";
  let weightClassName = "---";

  if (currentLifter) {
    const firstLifterDivision = get(currentLifter, "divisions.0", null);
    if (firstLifterDivision) {
      const divisionDoc = getDivisionDoc(firstLifterDivision.divisionId, meet);
      if (firstLifterDivision.divisionId && divisionDoc) {
        divisionName = get(divisionDoc, "name", "");
        // don't display weightclass name if there is only one for this division
        const weightClassDoc = getLifterAwardsWeightClassDoc(
          meet,
          currentLifter,
          firstLifterDivision
        );
        weightClassName =
          size(divisionDoc.weightClasses) > 1
            ? get(weightClassDoc, "name", "")
            : "";
      }
    }
  }

  return (
    <div className="platform2">
      <div className="platform-name">{platform.name}</div>
      <div className="platform-table">
        <div className="platform-table-row">
          <div>{lifterName}</div>
          {teamName && <div>{teamName}</div>}
          <div>
            {divisionName} - {weightClassName}
          </div>
        </div>
        <div className="platform-table-row">
          <div>{liftDisplayName}</div>
          <AttemptCell
            currentLifter={currentLifter}
            liftName={liftName}
            attemptNumber="1"
            currentAttemptNumber={currentAttemptNumber}
          />
          <AttemptCell
            currentLifter={currentLifter}
            liftName={liftName}
            attemptNumber="2"
            currentAttemptNumber={currentAttemptNumber}
          />
          <AttemptCell
            currentLifter={currentLifter}
            liftName={liftName}
            attemptNumber="3"
            currentAttemptNumber={currentAttemptNumber}
          />
        </div>
      </div>
    </div>
  );
};

export default Platform2;
