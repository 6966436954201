import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getIsLocal,
  getIsLoggedIn,
  getIsOnline,
  getIsSyncing,
} from "selectors";
import { ReduxState } from "types";

export const useStatus = () => {
  const { meetId } = useParams<{ meetId: string }>();

  const isOnline = useSelector((state: ReduxState) =>
    getIsOnline(state, { match: { params: { meetId } } })
  );
  const isLocal = useSelector((state: ReduxState) =>
    getIsLocal(state, { match: { params: { meetId } } })
  );

  const isLoggedIn = useSelector((state: ReduxState) =>
    getIsLoggedIn(state, { match: { params: { meetId } } })
  );

  const isSyncing = useSelector((state: ReduxState) =>
    getIsSyncing(state, { match: { params: { meetId } } })
  );

  return { isOnline, isLocal, isLoggedIn, isSyncing };
};
