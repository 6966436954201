import React from "react";
import { Route, NavLink, Switch } from "react-router-dom";
import type { match } from "react-router-dom";

import FixedHeightWrapper from "app/meets/FixedHeightWrapper";
import ImportLifters from "./lifters/ImportLifters";
import ImportDivisions from "./divisions/ImportDivisions";

import "./ImportIndex.scss";
import ImportRecords from "./records/ImportRecords";

const ImportIndex = ({
  onFinishBulkCreate,
  match,
}: {
  onFinishBulkCreate: any;
  match: match;
}) => {
  return (
    <FixedHeightWrapper>
      <div className="import-index">
        <div className="tabs">
          <NavLink
            to={`${match.url}/divisions`}
            className="tab"
            activeClassName="active"
          >
            Import Divisions
          </NavLink>
          <NavLink
            to={`${match.url}/lifters`}
            className="tab"
            activeClassName="active"
          >
            Import Lifters
          </NavLink>
          <NavLink
            to={`${match.url}/records`}
            className="tab"
            activeClassName="active"
          >
            Import Records
          </NavLink>
        </div>
        <Switch>
          <Route
            path="/meets/:meetId/import/lifters"
            component={(props: any) => (
              <ImportLifters
                {...props}
                onFinishBulkCreate={onFinishBulkCreate}
              />
            )}
          />
          <Route
            path="/meets/:meetId/import/divisions"
            component={(props: any) => (
              <ImportDivisions
                {...props}
                onFinishBulkCreate={onFinishBulkCreate}
              />
            )}
          />
          <Route
            path="/meets/:meetId/import/records"
            component={(props: any) => (
              <ImportRecords
                {...props}
                onFinishBulkCreate={onFinishBulkCreate}
              />
            )}
          />
        </Switch>
      </div>
    </FixedHeightWrapper>
  );
};

export default ImportIndex;
