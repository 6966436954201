import React from "react";
import Select from "components/select/Select";
import "./ResultsSheet.scss";
import { sortByLifts } from "util/sortByLifts";
import { getSessionOptions } from "util/options";
import { getDivisionResultsData } from "util/getDivisionResultsData";
import {
  getDivisionDoc,
  getLifterAwardsWeightClassDoc,
  getTotal,
} from "util/lifterHelper";
import { useMeet } from "util/useMeet";
import filter from "lodash/filter";
import find from "lodash/find";
import map from "lodash/map";
import { Division, Meet, WeightClass } from "types";
import sortBy from "lodash/sortBy";
import get from "lodash/get";

const LifterName = ({
  place,
  meet,
  divisionResults,
  division,
}: {
  place: number;
  meet: Meet;
  divisionResults: any;
  division: Division;
}) => {
  const lifter = get(divisionResults, place);
  if (!lifter) {
    return "";
  }

  const total = getTotal(lifter, division._id, meet, false);

  if (!total) {
    return "";
  }

  return lifter.name;
};

const ResultsSheet = () => {
  const meet = useMeet();
  const [top, setTop] = React.useState(3);
  const [session, setSession] = React.useState<"ALL" | number>("ALL");

  const onChangeTop = (value: string | number | undefined | null) => {
    if (typeof value === "number") {
      setTop(value);
    } else {
      setTop(3);
    }
  };

  const onChangeSession = (value: string | number | undefined | null) => {
    if (typeof value !== "number") {
      setSession("ALL");
    } else {
      setSession(value);
    }
  };

  const divisions = filter(meet.divisions, (division) => {
    return !!find(meet.lifters, (lifter) => {
      return !!find(lifter.divisions, { divisionId: division._id });
    });
  });

  // TODO: extract sorting logic to it is the same everywhere we sort divisions.
  const sortedDivisions = sortBy(divisions, [
    "gender",
    "rawOrEquipped",
    sortByLifts,
    "name",
  ]);
  const divisionsWithWeightClasses = map(sortedDivisions, (division) => {
    const weightClasses = filter(division.weightClasses, (wc) => {
      return !!find(meet.lifters, (lifter) => {
        return (
          (session === "ALL" || session === lifter.session) &&
          !!find(lifter.divisions, (ld) => {
            return (
              (getLifterAwardsWeightClassDoc(meet, lifter, ld) as WeightClass)
                ._id === wc._id && ld.divisionId === division._id
            );
          })
        );
      });
    });
    // TODO: extract sorting logic to it is the same everywhere we sort weight classes.
    const sortedWeightClasses = sortBy(weightClasses, "maxWeight");
    return { ...division, weightClasses: sortedWeightClasses };
  });

  return (
    <div className="results-sheet">
      <div className="options-row">
        <div className="select-wrapper" style={{ paddingRight: 40 }}>
          Show Top: &nbsp;
          <Select
            name="top"
            value={top}
            options={[
              { label: "1", value: 1 },
              { label: "2", value: 2 },
              { label: "3", value: 3 },
              { label: "4", value: 4 },
              { label: "5", value: 5 },
            ]}
            onChange={onChangeTop}
            clearable={false}
          />
        </div>
        <div className="select-wrapper">
          Session: &nbsp;
          <Select
            name="session"
            value={session}
            options={[{ value: "ALL", label: "All" }, ...getSessionOptions()]}
            onChange={onChangeSession}
            clearable={false}
          />
        </div>
      </div>
      <div className="results-sheet-content">
        <div className="meet-name">{meet.name}</div>
        {map(divisionsWithWeightClasses, (division) => {
          return (
            <div className="division-section" key={division._id}>
              {map(division.weightClasses, (weightClass) => {
                const divisionDoc = getDivisionDoc(division._id, meet);
                if (!divisionDoc) {
                  return null;
                }
                const divisionResults = getDivisionResultsData(
                  meet,
                  meet.lifters,
                  divisionDoc,
                  weightClass,
                  false
                );

                return (
                  <div className="weight-class-section" key={weightClass._id}>
                    <div className="awards-class-title">
                      {division.name} - {weightClass.name}
                    </div>
                    <ol>
                      {top >= 1 && (
                        <li>
                          <LifterName
                            place={1}
                            meet={meet}
                            divisionResults={divisionResults}
                            division={divisionDoc}
                          />
                        </li>
                      )}
                      {top >= 2 && (
                        <li>
                          <LifterName
                            place={2}
                            meet={meet}
                            divisionResults={divisionResults}
                            division={divisionDoc}
                          />
                        </li>
                      )}
                      {top >= 3 && (
                        <li>
                          <LifterName
                            place={3}
                            meet={meet}
                            divisionResults={divisionResults}
                            division={divisionDoc}
                          />
                        </li>
                      )}
                      {top >= 4 && (
                        <li>
                          <LifterName
                            place={4}
                            meet={meet}
                            divisionResults={divisionResults}
                            division={divisionDoc}
                          />
                        </li>
                      )}
                      {top >= 5 && (
                        <li>
                          <LifterName
                            place={5}
                            meet={meet}
                            divisionResults={divisionResults}
                            division={divisionDoc}
                          />
                        </li>
                      )}
                    </ol>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ResultsSheet;
