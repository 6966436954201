import React from "react";
import { parseDateString } from "util/dateHelper";
import SortableTable from "components/SortableTable";
import DefaultCell from "./cells/DefaultCell";
import StatusCell from "./cells/StatusCell";
import NameCell from "./cells/NameCell";
import AgeCell from "./cells/AgeCell";
import RestrictedCell from "./cells/RestrictedCell";
import { MediaSize, Meet } from "types";
import toLower from "lodash/toLower";
import get from "lodash/get";
import { Column } from "components/table/Table";

const getCellLines = () => {
  return 1.8;
};

const MembershipCheckerTable = ({
  meet,
  lifters,
  media,
}: {
  meet: Meet;
  lifters: any;
  media: MediaSize;
}) => {
  const getAllColumns = (): Column[] => {
    const columns: Column[] = [
      {
        key: "membershipStatus",
        label: "Membership Status",
        width: 160,
        type: "text",
        sortable: true,
        sortDirection: "asc",
        sortOrder: 10,
        renderer: StatusCell,
      },
      {
        key: "name",
        label: "Name",
        width: 180,
        sortable: true,
        sortFunction: (row: any) => toLower(row.name),
        sortDirection: "asc",
        sortOrder: 1,
        renderer: NameCell,
      },
      {
        key: "birthDate",
        label: "Birth Date",
        width: 110,
        sortable: true,
        sortFunction: (row: any) =>
          parseDateString({ dateString: row.birthDate, meet }),
        sortDirection: "asc",
        sortOrder: 3,
        renderer: DefaultCell,
      },
      {
        key: "age",
        label: "Age",
        width: 60,
        renderer: AgeCell,
      },
      {
        key: "memberNumber",
        label: "Member #",
        width: 110,
        sortable: true,
        sortFunction: (row: any) =>
          parseInt(get(row, "restricted.memberNumber"), 10),
        sortDirection: "asc",
        sortOrder: 4,
        renderer: RestrictedCell,
      },
      {
        key: "state",
        label: "State",
        width: 80,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 6,
        renderer: DefaultCell,
      },
    ];

    return columns;
  };

  return (
    <SortableTable
      data={lifters}
      columns={getAllColumns()}
      getCellLines={getCellLines}
      meet={meet}
      numberOfFixedLeftColumns={2}
      media={media}
    />
  );
};

export default MembershipCheckerTable;
