import React from "react";
import map from "lodash/map";
import { getPlace, getDivisionDoc } from "util/lifterHelper";

const PlaceCell = ({ data, column, meet, style }) => {
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    return (
      <div style={style} className="table-cell-inner">
        {map(data.divisions, (lifterDivision, index) => {
          if (!lifterDivision.divisionId) {
            return null;
          }

          const division = getDivisionDoc(lifterDivision.divisionId, meet);
          if (!division) {
            return null;
          }

          if (division.hideOnBoard) {
            return null;
          }

          return (
            <div
              key={`${lifterDivision.divisionId}-${index}`}
              className="cell-row"
            >
              {getPlace(data, lifterDivision, meet) || <span>&nbsp;</span>}
            </div>
          );
        })}
      </div>
    );
  }
};

export default PlaceCell;
