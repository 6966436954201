import { Column } from "components/table/Table";
import get from "lodash/get";
import React from "react";

const DefaultCell = ({
  data,
  column,
  style,
}: {
  data: any;
  column: Column;
  style: React.CSSProperties & { fontSize: number };
}) => {
  const lifter = data.lifter;
  if (data.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else if (data.row === "title") {
    return (
      <div style={style} className="table-cell-inner">
        {data.name}
      </div>
    );
  } else {
    const name = get(lifter, column.key);
    if (name.length < 20) {
      style = { ...style, fontSize: style.fontSize * 1.3 };
    }
    return (
      <div style={style} className="table-cell-inner ellipsis">
        {name}
      </div>
    );
  }
};

export default DefaultCell;
