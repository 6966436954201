import React from "react";
import PouchCheckbox from "components/PouchCheckbox";
import { getBenchLabel } from "util/meetHelper";
import { Column } from "components/table/Table";
import { Meet } from "types";
import get from "lodash/get";

const LiftsCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: Column;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const division = data;
  if (division.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    return (
      <div
        className="table-cell-inner"
        style={{
          ...style,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <PouchCheckbox
          label="Squat"
          value={get(division, `${column.key}.squat`)}
          name={[column.key, "squat"]}
          meetId={meet._id}
          documentId={division._id}
        />
        <PouchCheckbox
          label={`${getBenchLabel(meet)}`}
          value={get(division, `${column.key}.bench`)}
          name={[column.key, "bench"]}
          meetId={meet._id}
          documentId={division._id}
        />
        <PouchCheckbox
          label="Deadlift"
          value={get(division, `${column.key}.dead`)}
          name={[column.key, "dead"]}
          meetId={meet._id}
          documentId={division._id}
        />
      </div>
    );
  }
};

export default LiftsCell;
