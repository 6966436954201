import React from 'react';

const NotEqualIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
      <path d="M368 208c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16h-42.32l55.03-66.81c5.37-7.02 4.04-17.06-2.97-22.43L352.32 35.3c-7.02-5.37-17.06-4.04-22.43 2.97L242.81 144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h174.1l-79.07 96H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h42.32L3.3 434.81c-5.37 7.01-4.04 17.05 2.97 22.43l25.41 19.46c7.02 5.38 17.06 4.04 22.43-2.97L141.19 368H368c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16H193.9l79.07-96H368z"></path>
    </svg>
  );
};

export default NotEqualIcon;
