import React from "react";
import { Route, Switch, NavLink } from "react-router-dom";
import "./ValidatorIndex.scss";
import ConnectionChecker from "./ConnectionChecker";
import DatabaseChecker from "./DatabaseChecker";
import SetupChecker from "./SetupChecker";

import { useMeet } from "util/useMeet";

const ValidatorIndex = () => {
  const meet = useMeet();

  if (!meet) {
    return null;
  }

  const baseUrl = `/meets/${meet._id}`;

  return (
    <div className="validator-index">
      <div className="tabs">
        <NavLink
          to={`${baseUrl}/validator/connectionChecker`}
          className="tab"
          activeClassName="active"
        >
          Connection
        </NavLink>
        <NavLink
          to={`${baseUrl}/validator/databaseChecker`}
          className="tab"
          activeClassName="active"
        >
          Database Integrity
        </NavLink>
        <NavLink
          to={`${baseUrl}/validator/setupChecker`}
          className="tab"
          activeClassName="active"
        >
          Data Setup
        </NavLink>
      </div>

      <Switch>
        <Route
          path="/meets/:meetId/validator/connectionChecker"
          component={ConnectionChecker}
        />
        <Route
          path="/meets/:meetId/validator/databaseChecker"
          component={DatabaseChecker}
        />
        <Route
          path="/meets/:meetId/validator/setupChecker"
          component={SetupChecker}
        />
      </Switch>
    </div>
  );
};

export default ValidatorIndex;
