import React from "react";

const ToolboxIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M512 237.25c0-8.49-3.37-16.62-9.37-22.63l-45.26-45.26c-6-6-14.14-9.37-22.63-9.37H384V80c0-26.51-21.49-48-48-48H176c-26.51 0-48 21.49-48 48v80H77.26c-8.49 0-16.63 3.37-22.63 9.37L9.37 214.63c-6 6-9.37 14.14-9.37 22.63L.01 304 0 448c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32V237.25zM176 80h160v80H176V80zM48 243.88L83.88 208h344.23L464 243.88l.01 60.12H376v-16c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v16H184v-16c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v16H48.01L48 243.88zM464 432H48v-80h88v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16h144v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16h88v80z" />
    </svg>
  );
};

export default ToolboxIcon;
