import React from "react";
import map from "lodash/map";
import get from "lodash/get";
import classNames from "classnames";
import { Column } from "components/table/Table";
import { Meet, WeightClass } from "types";
import { getLifterAwardsWeightClassDoc } from "util/lifterHelper";
import SortableHeaderCell from "components/SortableHeaderCell";

const WeightClassCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: Column;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return <SortableHeaderCell style={style} column={column} />;
  } else {
    return (
      <div style={style} className="table-cell-inner">
        {map(lifter.divisions, (division, index) => {
          const weightClassDoc = getLifterAwardsWeightClassDoc(
            meet,
            lifter,
            division
          ) as WeightClass;
          const weightClassId = weightClassDoc._id;
          const isAWeightClassChange =
            division.declaredAwardsWeightClassId &&
            division.declaredAwardsWeightClassId !== weightClassId;
          return (
            <div
              key={index}
              className={classNames("inner-row", {
                "weight-class-change": isAWeightClassChange,
              })}
            >
              {get(weightClassDoc, "name")}
            </div>
          );
        })}
      </div>
    );
  }
};

export default WeightClassCell;
