import React from "react";
import Barcode from "react-barcode";
import classNames from "classnames";
import {
  getDivisionDoc,
  getLifterAwardsWeightClassDoc,
} from "util/lifterHelper";
import { getBenchLabel } from "util/meetHelper";
import { Lifter, Meet, WeightClass } from "types";
import get from "lodash/get";
import size from "lodash/size";

const Cell = ({
  colSpan,
  rowSpan,
  label,
  content,
  shrinkSize,
  changeCell,
  barcodeCell,
  rightCell,
  bottomCell,
}: {
  colSpan: number;
  rowSpan?: number;
  label?: string;
  content?: string | React.ReactNode;
  shrinkSize?: number;
  changeCell?: boolean;
  barcodeCell?: boolean;
  rightCell?: boolean;
  bottomCell?: boolean;
}) => {
  const className = classNames("card-cell", {
    shrink:
      shrinkSize && typeof content === "string" && size(content) > shrinkSize,
    "change-cell": changeCell,
    "barcode-cell": barcodeCell,
    "right-cell": rightCell,
    "bottom-cell": bottomCell,
  });

  return (
    <div
      className={className}
      style={{
        gridColumnStart: `span ${colSpan}`,
        gridRowStart: `span ${rowSpan}`,
      }}
    >
      {label && <label>{label}</label>}
      &nbsp;
      {content}
    </div>
  );
};

const CardVersion1 = ({
  meet,
  lifter,
}: {
  meet: Meet;
  lifter: Lifter | undefined;
}) => {
  if (!lifter) {
    return null;
  }
  const platformName = lifter.platformId
    ? get(meet, ["platforms", lifter.platformId, "name"])
    : "";
  const title = `Session ${lifter.session || " "} - Platform ${
    platformName || " "
  } - ${meet.name}`;
  const division1Doc = getDivisionDoc(
    get(lifter, "divisions[0].divisionId"),
    meet
  );
  const division2Doc = getDivisionDoc(
    get(lifter, "divisions[1].divisionId"),
    meet
  );
  const weight1ClassDoc = getLifterAwardsWeightClassDoc(
    meet,
    lifter,
    get(lifter, "divisions[0]")
  ) as WeightClass;
  const weight2ClassDoc = getLifterAwardsWeightClassDoc(
    meet,
    lifter,
    get(lifter, "divisions[1]")
  ) as WeightClass;
  const div1Name = `${division1Doc?.name || ""} ${weight1ClassDoc?.name || ""}`;
  const div2Name = `${division2Doc?.name || ""} ${weight2ClassDoc?.name || ""}`;
  const memberNumber = get(lifter, "restricted.memberNumber");
  const barcode = memberNumber ? (
    <Barcode value={memberNumber} height={24} fontSize={12} />
  ) : (
    ""
  );

  return (
    <div className="card">
      <Cell rightCell content={title} shrinkSize={70} colSpan={10} />

      <Cell label="Name:" content={lifter.name} shrinkSize={30} colSpan={5} />
      <Cell
        label="Member #:"
        content={get(lifter, "restricted.memberNumber")}
        shrinkSize={12}
        colSpan={3}
      />
      <Cell
        rightCell
        label="Lot #:"
        content={lifter.lot}
        shrinkSize={8}
        colSpan={2}
      />

      <Cell label="Flight:" content={lifter.flight} colSpan={2} />
      <Cell label="DOB:" content={lifter.birthDate} colSpan={3} />
      <Cell
        rightCell
        label="Team:"
        content={lifter.team}
        shrinkSize={30}
        colSpan={5}
      />

      <Cell
        label="Squat Rack Height:"
        content={lifter.squatRackHeight}
        shrinkSize={20}
        colSpan={5}
      />
      <Cell
        rightCell
        label={`${getBenchLabel(meet)} Rack Height:`}
        content={lifter.benchRackHeight}
        shrinkSize={20}
        colSpan={5}
      />

      <Cell label="BW:" content={lifter.bodyWeight} colSpan={4} />
      <Cell rightCell label="Lifter/Coach Initials:" colSpan={6} />

      <Cell label="Div1:" content={div1Name} shrinkSize={29} colSpan={5} />
      <Cell
        rightCell
        label="Div2:"
        content={div2Name}
        shrinkSize={29}
        colSpan={5}
      />

      <Cell colSpan={2} />
      <Cell label="1st Attempt" colSpan={2} />
      <Cell label="1st Change" colSpan={2} />
      <Cell label="2nd Attempt" colSpan={2} />
      <Cell rightCell label="3rd Attempt" colSpan={2} />

      <Cell label="Squat" colSpan={2} />
      <Cell content={get(lifter, "lifts.squat.1.weight")} colSpan={2} />
      <Cell changeCell colSpan={2} />
      <Cell colSpan={2} />
      <Cell rightCell colSpan={2} />

      <Cell label={getBenchLabel(meet)} colSpan={2} />
      <Cell content={get(lifter, "lifts.bench.1.weight")} colSpan={2} />
      <Cell changeCell colSpan={2} />
      <Cell colSpan={2} />
      <Cell rightCell colSpan={2} />

      <Cell label="Deadlift" colSpan={2} />
      <Cell content={get(lifter, "lifts.dead.1.weight")} colSpan={2} />
      <Cell changeCell colSpan={2} />
      <Cell colSpan={2} />
      <Cell rightCell colSpan={2} />

      <Cell barcodeCell content={barcode} bottomCell colSpan={8} rowSpan={2} />
      <Cell changeCell rightCell colSpan={2} />
      <Cell changeCell bottomCell rightCell colSpan={2} />
    </div>
  );
};

export default CardVersion1;
