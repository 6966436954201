import React from "react";
import classNames from "classnames";
import SortableHeaderCell from "components/SortableHeaderCell";
import { Column } from "components/table/Table";
import get from "lodash/get";

const NameCell = ({
  data,
  column,
  style,
}: {
  data: any;
  column: Column;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return <SortableHeaderCell style={style} column={column} />;
  } else {
    const value = get(lifter, "name");
    return (
      <div style={style} className="ellipsis table-cell-inner">
        {value && (
          <div>
            <span className={classNames({ error: lifter.nameMatch === false })}>
              {value}
            </span>
            {lifter.nameSuggestion && (
              <div style={{ paddingTop: 5 }}>({lifter.nameSuggestion})</div>
            )}
          </div>
        )}
        {!value && <div className="error">MISSING</div>}
      </div>
    );
  }
};

export default NameCell;
