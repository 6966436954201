import { FederationDivisionsConfig } from "types";

// prettier-ignore
const wppWeightClasses = {
  'MALE': [
    {name: '49',   lbsName: '1108.03lbs (49kg)',   maxWeight: 49,   lbsMaxWeight: 108.03},
    {name: '54',   lbsName: '119.05lbs (54kg)',    maxWeight: 54,   lbsMaxWeight: 119.05},
    {name: '65',   lbsName: '143.30lbs (65kg)',    maxWeight: 65,   lbsMaxWeight: 143.30},
    {name: '72',   lbsName: '158.73lbs (72kg)',    maxWeight: 72,   lbsMaxWeight: 158.73},
    {name: '80',   lbsName: '176.37lbs (80kg)',    maxWeight: 80,   lbsMaxWeight: 176.37},
    {name: '88',   lbsName: '194.01lbs (88kg)',    maxWeight: 88,   lbsMaxWeight: 194.01},
    {name: '97',   lbsName: '213.85lbs (97kg)',    maxWeight: 97,   lbsMaxWeight: 213.85},
    {name: '107',  lbsName: '235.90lbs (107kg)',   maxWeight: 107,  lbsMaxWeight: 235.90},
    {name: '107+', lbsName: '235.90lbs+ (107kg+)', maxWeight: 9999, lbsMaxWeight: 9999  }
  ],
  'FEMALE': [
    {name: '41',   lbsName: '90.39lbs (41kg)',     maxWeight: 41,   lbsMaxWeight: 90.39 },
    {name: '45',   lbsName: '99.21lbs (45kg)',     maxWeight: 45,   lbsMaxWeight: 99.21 },
    {name: '50',   lbsName: '110.23lbs (50kg)',    maxWeight: 50,   lbsMaxWeight: 110.23},
    {name: '55',   lbsName: '121.25lbs (55kg)',    maxWeight: 55,   lbsMaxWeight: 121.25},
    {name: '61',   lbsName: '132.28lbs (61kg)',    maxWeight: 61,   lbsMaxWeight: 132.28},
    {name: '67',   lbsName: '147.71lbs (67kg)',    maxWeight: 67,   lbsMaxWeight: 147.71},
    {name: '73',   lbsName: '160.94lbs (73kg)',    maxWeight: 73,   lbsMaxWeight: 160.94},
    {name: '79',   lbsName: '174.17lbs (79kg)',    maxWeight: 79,   lbsMaxWeight: 174.17},
    {name: '86',   lbsName: '189.60lbs (86kg)',    maxWeight: 86,   lbsMaxWeight: 189.60},
    {name: '86+',  lbsName: '189.60lbs+ (90kg+)',  maxWeight: 9999, lbsMaxWeight: 9999  }
  ]
};

// prettier-ignore
export const wppBaseDivisions: FederationDivisionsConfig = [
  {name: "Junior", code: 'J',  low: 15,  high: 20,   default: true,  records: true,  weightClasses: wppWeightClasses},
  {name: "Senior", code: 'O',  low: 21,  high: 999,  default: true,  records: true,  weightClasses: wppWeightClasses},
  {name: "Guest",  code: 'G',                        default: false, records: false, weightClasses: wppWeightClasses}
];
