import React from "react";
import CardVersion2 from "./CardVersion2";
import { useMeet } from "util/useMeet";
import sortBy from "lodash/sortBy";
import map from "lodash/map";
import size from "lodash/size";
import { Lifter, LifterAttempts } from "types";

const mockLift: LifterAttempts = {
  "1": { _id: "1" },
  "2": { _id: "2" },
  "3": { _id: "3" },
};
const mockLifts = {
  squat: mockLift,
  bench: mockLift,
  dead: mockLift,
};

const CardsVersion2 = () => {
  const meet = useMeet();
  const lifters: Lifter[] = sortBy(meet.lifters, ["lot"]);
  lifters.push({ _id: "1", divisions: [], lifts: mockLifts });
  lifters.push({ _id: "2", divisions: [], lifts: mockLifts });
  if (size(lifters) % 2 !== 0) {
    lifters.push({ _id: "3", divisions: [], lifts: mockLifts });
  }

  return (
    <div className="cards-version2">
      <div className="print-note">Must be printed in portrait</div>
      {map(lifters, (lifter, index: number) => {
        return (
          <CardVersion2
            meet={meet}
            lifter={lifter}
            index={index + 1}
            key={lifter._id}
          />
        );
      })}
    </div>
  );
};

export default CardsVersion2;
