import React from "react";
import PouchInput from "components/PouchInput";
import PouchDateInput from "components/PouchDateInput";
import PouchSelect from "components/PouchSelect";
import SortableHeaderCell from "components/SortableHeaderCell";
import { Column } from "components/table/Table";
import { Meet } from "types";
import get from "lodash/get";

const DefaultInputCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: Column;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  if (data.row === "header") {
    if (column.sortable) {
      return <SortableHeaderCell style={style} column={column} />;
    }
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    if (column.type === "select") {
      if (!column.options) {
        return null;
      }
      return (
        <div style={style} className="table-cell-inner">
          <PouchSelect
            meet={meet}
            document={data}
            name={column.key}
            value={get(data, column.key)}
            options={column.options}
          />
        </div>
      );
    } else if (column.type === "date") {
      return (
        <div style={style} className="table-cell-inner">
          <PouchDateInput
            meet={meet}
            documentId={data._id}
            name={column.key}
            value={get(data, column.key)}
          />
        </div>
      );
    } else {
      return (
        <div style={style} className="table-cell-inner">
          <PouchInput
            type={column.type === "number" ? "number" : "text"}
            meetId={meet._id}
            documentId={data._id}
            name={column.key}
            value={get(data, column.key)}
          />
        </div>
      );
    }
  }
};

export default DefaultInputCell;
