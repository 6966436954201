import React from "react";
import Checkbox from "components/checkbox/Checkbox";
import "./WeighInSheet.scss";
import { getSessionOptions, getPlatformOptions } from "util/options";
import { useMeet } from "util/useMeet";
import sortBy from "lodash/sortBy";
import map from "lodash/map";
import filter from "lodash/filter";
import size from "lodash/size";
import { Lifter, Meet } from "types";
import get from "lodash/get";

const WeighInTable = ({
  session,
  meet,
  lifters,
  platform,
}: {
  session: { value: number };
  meet: Meet;
  lifters: Lifter[];
  platform?: { label: string | undefined };
}) => {
  return (
    <div className="session-pages">
      <div className="meet-name">
        {meet.date} - {meet.name} - Session {session.value}{" "}
        {platform ? `- Platform ${platform.label} ` : ""}- WEIGH IN ORDER
      </div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Platform</th>
            <th>Flight</th>
            <th>Lot #</th>
            <th>Name</th>
            <th>Team</th>
          </tr>
        </thead>
        <tbody>
          {map(lifters, (lifter, index) => {
            return (
              <tr key={lifter._id}>
                <td>{index + 1}</td>
                <td>
                  {lifter.platformId
                    ? get(meet, ["platforms", lifter.platformId, "name"])
                    : ""}
                </td>
                <td>{lifter.flight}</td>
                <td>{lifter.lot}</td>
                <td>{lifter.name}</td>
                <td>{lifter.team}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const WeighInSheet = () => {
  const meet = useMeet();
  const [groupByPlatform, setGroupByPlatform] = React.useState(false);

  const lifters = sortBy(meet.lifters, ["lot"]);

  return (
    <div className="weigh-in-sheet">
      <div className="weigh-in-sheet-options">
        <Checkbox
          label="Group By Platform"
          value={groupByPlatform}
          onCheck={setGroupByPlatform}
        />
      </div>
      {map(getSessionOptions(), (session) => {
        const sessionLifters = filter(lifters, { session: session.value });
        const sessionCount = size(sessionLifters);
        if (!sessionCount) {
          return null;
        }

        if (groupByPlatform) {
          return map(getPlatformOptions({}, meet), (platform) => {
            const platformLifters = filter(sessionLifters, {
              platformId: platform.value,
            });
            const platformCount = size(platformLifters);
            if (!platformCount) {
              return null;
            }
            return (
              <WeighInTable
                key={platform.value}
                session={session}
                meet={meet}
                lifters={platformLifters}
                platform={platform}
              />
            );
          });
        }

        return (
          <WeighInTable
            key={session.value}
            session={session}
            meet={meet}
            lifters={sessionLifters}
          />
        );
      })}
    </div>
  );
};

export default WeighInSheet;
