import React from "react";
import Select from "components/select/Select";
import FixedHeightWrapper from "app/meets/FixedHeightWrapper";
import WeighInEntryTable from "./WeighInEntryTable";
import FiltersIcon from "icons/FiltersIcon";
import Drawer from "components/drawer/Drawer";
import {
  getPlatformOptions,
  getSessionOptions,
  getFlightOptions,
} from "util/options";
import "./WeighInEntryIndex.scss";
import filter from "lodash/filter";
import toLower from "lodash/toLower";
import { useMeet } from "util/useMeet";
import { useMediaSize } from "util/useMediaSize";
import includes from "lodash/includes";

const initialFilters: {
  name: string;
  gender: string | null;
  platformId: string | null;
  session: number | null;
  flight: string | null;
} = {
  name: "",
  gender: null,
  platformId: null,
  session: null,
  flight: null,
};

const WeighInEntryIndex = () => {
  const meet = useMeet();
  const media = useMediaSize();
  const [filters, setFilters] = React.useState(initialFilters);

  const clearFilters = () => {
    setFilters(initialFilters);
  };

  const onChangeNameFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters((prev) => ({ ...prev, name: e.target.value }));
  };

  const onChangeSelectFilter = (
    name: keyof typeof initialFilters,
    value: string | number | undefined | null
  ) => {
    setFilters({ ...filters, [name]: value });
  };

  const filteredLifters = filter(meet.lifters, (l) => {
    const nameSearchMatch = includes(toLower(l.name), toLower(filters.name));
    const platformIdMatch =
      !filters.platformId || l.platformId === filters.platformId;
    const sessionMatch = !filters.session || l.session === filters.session;
    const flightMatch = !filters.flight || l.flight === filters.flight;

    // don't show lifters without an id. Either data hasn't fully loaded or something went wrong.
    const validId = l._id;

    return !!(
      validId &&
      nameSearchMatch &&
      platformIdMatch &&
      sessionMatch &&
      flightMatch
    );
  });

  const activeFilters = !!(
    filters.name ||
    filters.platformId ||
    filters.session ||
    filters.flight
  );

  return (
    <FixedHeightWrapper>
      <div className="lifters-index">
        <div className="util-row-wrapper">
          <div className="util-row">
            <Drawer
              trigger={
                <>
                  <FiltersIcon active={activeFilters} />
                  &nbsp; Filter
                </>
              }
            >
              <div className="title">Lifter Filters</div>
              <button className="clear-button" onClick={clearFilters}>
                Clear All
              </button>
              <div className="row">
                Name:
                <input
                  type="text"
                  onChange={onChangeNameFilter}
                  value={filters.name}
                />
              </div>
              <div className="row">
                Platform:
                <Select
                  name="platformId"
                  value={filters.platformId}
                  options={getPlatformOptions({}, meet)}
                  onChange={(value) =>
                    onChangeSelectFilter("platformId", value)
                  }
                />
              </div>
              <div className="row">
                Session:
                <Select
                  name="session"
                  value={filters.session}
                  options={getSessionOptions()}
                  onChange={(value) => onChangeSelectFilter("session", value)}
                />
              </div>
              <div className="row">
                Flight:
                <Select
                  name="flight"
                  value={filters.flight}
                  options={getFlightOptions()}
                  onChange={(value) => onChangeSelectFilter("flight", value)}
                />
              </div>
            </Drawer>
          </div>
        </div>
        <div className="table-wrapper">
          <WeighInEntryTable
            meet={meet}
            lifters={filteredLifters}
            media={media}
          />
        </div>
      </div>
    </FixedHeightWrapper>
  );
};

export default WeighInEntryIndex;
