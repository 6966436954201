import React from "react";
import PouchInput from "components/PouchInput";
import { isCompetingInLift } from "util/lifterHelper";
import SortableHeaderCell from "components/SortableHeaderCell";
import { Column } from "components/table/Table";
import { Meet } from "types";
import get from "lodash/get";

const AttemptCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: Column;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return <SortableHeaderCell style={style} column={column} />;
  }
  if (data.row === "title") {
    return <div style={style} className="table-cell-inner" />;
  }

  if (!column.liftName) {
    return null;
  }

  if (!isCompetingInLift(lifter, column.liftName, meet)) {
    return null;
  }

  return (
    <div style={style} className="table-cell-inner">
      <PouchInput
        type="text"
        meetId={meet._id}
        documentId={lifter._id}
        name={column.key}
        value={get(lifter, column.key)}
      />
    </div>
  );
};

export default AttemptCell;
